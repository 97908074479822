/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import './LogoutIllustration.scss';

interface LogoutIllustrationProps {
    className?: string;
}

const LogoutIllustration: FC<LogoutIllustrationProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 954.2 1000" className={`logout-illustration ${className}`}>
        <circle className="logout-illustration__background" cx="477.1" cy="522.9" r="477.1" />

        <g className="logout-illustration__person">
            <path className="logout-illustration__door-board" d="M605.5,123.3c-8.5-11.1-17.7-21.7-27.6-31.5c-12.3-12.3-25.7-23.6-39.8-33.7c-1.4-1-2.9-2-4.3-3c-21-14.4-43.6-26.2-67.4-35.1c-1.4-0.5-2.9-1.1-4.4-1.6c-21.8-7.8-44.4-13.1-67.4-15.9c-1.4-0.2-2.9-0.3-4.4-0.5c-22.4-2.4-45-2.4-67.4,0c-1.5,0.2-2.9,0.3-4.4,0.5c-23,2.8-45.6,8.1-67.4,15.9c-1.5,0.5-2.9,1.1-4.3,1.6c-24.1,9-47,21-68.2,35.7c-1.5,1-2.9,2-4.3,3.1c-25.3,18.1-47.8,39.9-66.7,64.7c-1.5,1.9-2.9,3.8-4.4,5.8c-39,53.4-60,117.9-59.8,184.1v408.6C118.7,886,284.6,1000,477.1,1000c68.5,0,133.7-14.4,192.5-40.5V313.2c0.2-66.2-20.8-130.7-59.8-184.1C608.4,127.1,607,125.2,605.5,123.3z" />
            <path className="logout-illustration__door-knob" d="M618.7,761.8c19.2,0,34.7-15.5,34.7-34.7s-15.5-34.7-34.7-34.7c-19.2,0-34.7,15.5-34.7,34.7S599.6,761.8,618.7,761.8z" />
            <rect className="logout-illustration__pane" x="303.4" y="233" width="104" height="203.5" />
            <rect className="logout-illustration__pane" x="303.4" y="688.1" width="104" height="201.6" />
            <rect className="logout-illustration__pane" x="201.5" y="440.8" width="97.5" height="243" />
            <rect className="logout-illustration__pane" x="303.4" y="440.8" width="104" height="243" />
            <rect className="logout-illustration__pane" x="411.7" y="440.8" width="99.7" height="243" />
            <path className="logout-illustration__pane" d="M299.1,688.1h-97.5v187.7c0,3.7,1.5,7.3,4.2,9.9c2.6,2.6,6.2,4.1,9.9,4h83.4V688.1z" />
            <path className="logout-illustration__pane" d="M411.7,688.1v201.6h85.6c3.7,0,7.3-1.4,9.9-4c2.6-2.6,4.1-6.2,4.2-9.9V688.1H411.7z" />
            <path className="logout-illustration__pane" d="M299.1,436.5V233h-83.4c-3.7,0-7.3,1.4-9.9,4c-2.6,2.6-4.1,6.2-4.2,9.9v189.6H299.1z" />
            <path className="logout-illustration__pane" d="M411.7,436.5h99.7V246.9c0-3.7-1.5-7.3-4.2-9.9c-2.6-2.6-6.2-4.1-9.9-4h-85.6V436.5z" />
        </g>

        <g className="logout-illustration__person">
            <path className="logout-illustration__skin" d="M636.3,734c-1.9-2.8-3.2-6-3.7-9.4c-0.5-3.4-0.3-6.8,0.6-10.1c0.9-3.3,2.6-6.3,4.8-8.9c2.2-2.6,5-4.6,8.1-6l90.3-248.9l44.4,24.3L677.7,712.9c2,5.4,1.9,11.3-0.2,16.6c-2.1,5.3-6.1,9.7-11.2,12.3c-5.1,2.6-11,3.2-16.5,1.8C644.3,742.2,639.5,738.7,636.3,734z" />
            <path className="logout-illustration__skin" d="M904.5,484.6l-50.6-2.3l28,263.3c-2.2,2.6-3.8,5.7-4.6,9s-1,6.7-0.4,10.1c0.6,3.4,1.9,6.5,3.9,9.3c0.4,0.5,0.8,1,1.2,1.5c12.4-19.9,23.4-40.7,32.9-62.4L904.5,484.6z" />
            <path className="logout-illustration__shirt" d="M922.5,570l-16.3-93c-2.6-14.6-12.8-25.6-24.9-28.6c-47.8-39.1-103.5-22.2-132.9-8.4c-1.5,0.7-2.9,1.6-4.2,2.5c-3.7,0.6-7.2,1.9-10.5,3.8c-6.9,4.1-12,10.7-14,18.5L685.9,545l56.8,11.5c-1.7,74.4-15,161.8-15.2,163l-0.2,1.3h160.5l11-145.3L922.5,570z" />
            <path className="logout-illustration__skin" d="M807.5,404.6c29.4,0,53.2-23.8,53.2-53.2c0-29.4-23.8-53.2-53.2-53.2c-29.4,0-53.2,23.8-53.2,53.2C754.3,380.8,778.1,404.6,807.5,404.6z" />
            <path className="logout-illustration__hair" d="M842.6,392.5c-9.9,10.6-28.4,4.9-29.7-9.6c-0.1-1.1-0.1-2.3,0-3.4c0.7-6.4,4.4-12.2,3.5-19c-0.2-1.7-0.8-3.3-1.8-4.7c-7.9-10.6-26.5,4.7-34-4.9c-4.6-5.9,0.8-15.1-2.7-21.7c-4.6-8.7-18.4-4.4-27-9.1c-9.6-5.3-9-20-2.7-28.9c7.7-10.9,21.2-16.7,34.5-17.6c13.3-0.8,26.6,2.8,39,7.6c14.1,5.5,28.2,13.1,36.8,25.5c10.6,15.1,11.6,35.4,6.3,53.1C861.7,370.7,850.7,383.9,842.6,392.5z" />
            <path className="logout-illustration__pants" d="M896.7,750.2c-3.6-24.9-8.3-48.6-14.2-70.9l-153.9,10.8c-13.9,60-11.2,143.3-4.1,240.8c24.2-14.7,47-31.6,68.2-50.2l13.9-106l18.1,75C852.8,819.8,877,786.4,896.7,750.2z" />
        </g>
    </svg>
);

export default LogoutIllustration;
/* eslint-enable max-len */
