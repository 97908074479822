import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedPage, ConnectedTakeAnalysis } from '../../connectors';
import trans from '../../helpers/trans';

import './TakeAnalysis.scss';

const TakeAnalysis: FC = (): ReactElement => (
    <ConnectedPage
        hasNavigation
        title={trans('pages.takeAnalysis.title')}
        className="take-analysis-page"
    >
        <Helmet>
            <title>{trans('pages.takeAnalysis.title')}</title>
        </Helmet>

        <div className="take-analysis-page__content-wrapper">
            <ConnectedTakeAnalysis />
        </div>
    </ConnectedPage>
);

export default TakeAnalysis;
