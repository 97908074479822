import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { debounceDelay } from '../../constants';
import { CounselorDashboard } from '../../containers';
import useTimeout from '../../hooks/useTimeout';
import { CandidateInviteFormData } from '../../models/Candidate/Candidate';
import { defaultCandidateRelationsConfig } from '../../models/CandidateRelations/CandidateRelations';
import { User } from '../../models/User/User';
import { createCandidate, finishCreateCandidate } from '../../redux/candidateInvite/candidateInviteActions';
import { fetchCandidateRelations } from '../../redux/candidateRelations/candidateRelationsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { SortConfig, SortDirection, SortField } from '../../types';

interface ConnectedCounselorDashboardProps {
    onUserResponse?: (user: User) => void;
    className?: string;
}

const ConnectedCounselorDashboard: FC<ConnectedCounselorDashboardProps> = ({
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const [sortConfig, setSortConfig] = useState<SortConfig>({ field: SortField.createdAt, direction: SortDirection.desc });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);

    const user = useTypedSelector(state => state.userReducer.user);

    const candidateRelationsList = useTypedSelector(state => state.candidateRelationsReducer.list);
    const candidateRelationsTotalPages = useTypedSelector(state => state.candidateRelationsReducer.totalPages);
    const candidateRelationsIsLoading = useTypedSelector(state => state.candidateRelationsReducer.isLoading);
    const candidateRelationsError = useTypedSelector(state => state.candidateRelationsReducer.error);

    const candidateInviteIsLoading = useTypedSelector(state => state.candidateInviteReducer.isLoading);
    const candidateInviteIsSuccessful = useTypedSelector(state => state.candidateInviteReducer.isSuccessful);
    const candidateInviteError = useTypedSelector(state => state.candidateInviteReducer.error);

    const refreshDelay = 5000;
    const [refresh, setRefresh] = useState<boolean>(false);

    const refreshCandidateRelations = (): void => {
        setRefresh(true);
        setCurrentPage(1);
        setSortConfig({
            field: SortField.createdAt,
            direction: SortDirection.desc,
        });
        setSearchQuery('');
    };

    useTimeout((): void => {
        if (refresh) {
            setRefresh(false);
            refreshCandidateRelations();
        }
    }, refreshDelay, [refresh]);

    useEffect((): () => void => {
        if (!refresh) return () => {};

        const timer = setTimeout((): void => {
            dispatch(fetchCandidateRelations({
                ...defaultCandidateRelationsConfig(),
                pageNumber: currentPage,
                sortConfig,
                searchQuery,
            }));
        }, debounceDelay);

        return (): void => clearTimeout(timer);
    }, [refresh, currentPage, sortConfig, searchQuery]);

    useEffect((): void => {
        if (onUserResponse && user) {
            onUserResponse(user);
        }
    }, [onUserResponse, user]);

    const handleCandidateInvite = (candidateInviteFormData: CandidateInviteFormData): void => {
        dispatch(createCandidate(candidateInviteFormData));
    };

    const handleTableSort = (field: SortField, direction = SortDirection.desc): void => {
        setSortConfig({ field, direction });
        setRefresh(true);
    };

    const handleTableSearch = (query: string): void => {
        setCurrentPage(1);
        setSearchQuery(query);
        setRefresh(true);
    };

    const handleCloseInviteCandidateSuccessMessage = (): void => {
        if (candidateInviteIsSuccessful) {
            refreshCandidateRelations();
            dispatch(finishCreateCandidate());
            setRefresh(true);
        }
    };

    return (
        <CounselorDashboard
            candidateInviteIsLoading={candidateInviteIsLoading}
            candidateInviteIsSuccessful={candidateInviteIsSuccessful}
            candidateInviteError={candidateInviteError}
            onCandidateInvite={handleCandidateInvite}
            onCloseInviteCandidateSuccessMessage={handleCloseInviteCandidateSuccessMessage}
            //
            tableIsLoading={candidateRelationsIsLoading}
            tableError={candidateRelationsError}
            tableContent={candidateRelationsList}
            tableSortConfig={sortConfig}
            tableSearchQuery={searchQuery}
            tableTotalPages={candidateRelationsTotalPages}
            tableCurrentPage={currentPage}
            onTableSort={handleTableSort}
            onTableSearch={handleTableSearch}
            onTableRefresh={refreshCandidateRelations}
            onTablePaginationChange={setCurrentPage}
            //
            className={className}
        />
    );
};

export default ConnectedCounselorDashboard;
