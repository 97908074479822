import {
    AddDevelopmentPlanCompetencyEvaluationRequest,
    DevelopmentPlanCompetencyEvaluation,
    DevelopmentPlanCompetencyEvaluationFormData,
    DevelopmentPlanCompetencyEvaluationResource,
    EditDevelopmentPlanCompetencyEvaluationRequest,
} from './DevelopmentPlanCompetencyEvaluation';

export const transformDevelopmentPlanCompetencyEvaluation = (resource: DevelopmentPlanCompetencyEvaluationResource): DevelopmentPlanCompetencyEvaluation => ({
    id: resource.id,
    feedback: resource.feedback,
    grade: resource.grade,
    developmentPlanCompetencyId: resource.developmentPlanCompetencyId,
});

export const transformAddDevelopmentPlanCompetencyEvaluationRequest = (formData: DevelopmentPlanCompetencyEvaluationFormData): AddDevelopmentPlanCompetencyEvaluationRequest => ({
    feedback: formData.feedback,
    grade: formData.grade,
    developmentPlanCompetencyId: formData.developmentPlanCompetencyId,
});

export const transformEditDevelopmentPlanCompetencyEvaluationRequest = (formData: DevelopmentPlanCompetencyEvaluationFormData): EditDevelopmentPlanCompetencyEvaluationRequest => ({
    id: formData.id || '',
    feedback: formData.feedback,
    grade: formData.grade,
});
