import { FC, useEffect, useState } from 'react';

import { LoadingSpinner } from '../../components';
import { userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { userMainNavigationRoutes } from '../../helpers/user';
import { NavigationRoute, navigationRoutes } from '../../Routes';
import { FormOption, UserRole } from '../../types';
import { IconButton, Navigation, SelectInput } from '../index';

import './CounselorNavigation.scss';

interface CounselorNavigationProps {
    isLoading: boolean;
    candidateNavigationItems: NavigationRoute[];
    candidateOptions: FormOption[];
    candidateUuid?: string;
    counselorNavigationItems: NavigationRoute[];
    navigationLabel: string;
    selectedCandidateOption?: FormOption;
    userUuid?: string;
    userRoles?: UserRole[];
    organisationUuid?: string;
    onCandidateSelect: (candidateId: string) => void;
    className?: string;
}

const CounselorNavigation: FC<CounselorNavigationProps> = ({
    isLoading,
    candidateNavigationItems,
    candidateOptions,
    candidateUuid,
    counselorNavigationItems,
    navigationLabel,
    selectedCandidateOption,
    userUuid,
    userRoles,
    organisationUuid,
    onCandidateSelect,
    className = '',
}) => {
    const [showCandidateNavigation, setShowCandidateNavigation] = useState<boolean>(!!candidateUuid);
    const [disableCandidateNavigation, setDisableCandidateNavigation] = useState<boolean>(!!candidateUuid);

    const hasSelectedCandidateOption = !!selectedCandidateOption?.value && !!selectedCandidateOption?.label;

    const lloAdminAllowedRoutes = userMainNavigationRoutes[UserRole.lloAdmin];
    const lloAdminNavigationRoutes = navigationRoutes.filter(route => lloAdminAllowedRoutes.includes(route.key));

    useEffect((): void => {
        setShowCandidateNavigation(!!candidateUuid);
        setDisableCandidateNavigation(!!candidateUuid);
    }, [candidateUuid]);

    const onExpandCounselorNavigationClick = (): void => {
        setShowCandidateNavigation(false);
    };

    const onExpandCandidateNavigationClick = (): void => {
        setShowCandidateNavigation(!!candidateUuid);
    };

    return (
        <nav aria-label={navigationLabel} className={`counselor-navigation ${className}`}>
            {userHasRole(UserRole.lloAdmin, userRoles) && (
                <>
                    <IconButton
                        icon="menu"
                        text={trans('common.lloAdmin')}
                        onClick={onExpandCounselorNavigationClick}
                        className="counselor-navigation__expand-button"
                        iconClassName="counselor-navigation__expand-button-icon"
                    />

                    {!showCandidateNavigation && (
                        <Navigation
                            organisationUuid={organisationUuid}
                            navigationLabel={trans('containers.menu.navigationAriaLabel')}
                            navigationItems={lloAdminNavigationRoutes}
                            className="counselor-navigation__navigation"
                        />
                    )}
                </>
            )}

            {userHasRole(UserRole.counselor, userRoles) && (
                <>
                    <IconButton
                        icon="menu"
                        text={trans('common.counselor')}
                        onClick={onExpandCounselorNavigationClick}
                        className="counselor-navigation__expand-button"
                        iconClassName="counselor-navigation__expand-button-icon"
                    />
                    {!showCandidateNavigation && (
                        <Navigation
                            organisationUuid={organisationUuid}
                            navigationLabel={trans('containers.menu.navigationAriaLabel')}
                            navigationItems={counselorNavigationItems}
                            className="counselor-navigation__navigation"
                        />
                    )}
                </>
            )}

            <IconButton
                disabled={!disableCandidateNavigation}
                icon="menu"
                text={trans('common.candidate')}
                onClick={onExpandCandidateNavigationClick}
                className="counselor-navigation__expand-button"
                iconClassName="counselor-navigation__expand-button-icon"
            />

            {(showCandidateNavigation && hasSelectedCandidateOption) && (
                <SelectInput
                    hideLabel
                    hasAutoFocus={!candidateUuid}
                    defaultMenuIsOpen={(!candidateUuid && !isLoading)}
                    isDisabled={isLoading}
                    label={trans('common.candidate')}
                    name={trans('common.candidate')}
                    options={candidateOptions}
                    value={selectedCandidateOption}
                    onChange={onCandidateSelect}
                    className="counselor-navigation__candidate-select"
                />
            )}

            {(showCandidateNavigation && isLoading) && (
                <LoadingSpinner className="counselor-navigation__loader" />
            )}

            {(showCandidateNavigation && !isLoading) && (
                <Navigation
                    candidateUuid={userUuid}
                    navigationLabel={trans('containers.menu.navigationAriaLabel')}
                    navigationItems={candidateNavigationItems}
                    className="counselor-navigation__navigation"
                />
            )}
        </nav>
    );
};

export default CounselorNavigation;
