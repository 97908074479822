import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { debounceDelay } from '../../../constants';
import { OrganisationDetailCandidates } from '../../../containers';
import { userHasRole } from '../../../helpers/role';
import useTimeout from '../../../hooks/useTimeout';
import useUnmount from '../../../hooks/useUnmount';
import { CandidateExchangeFormData } from '../../../models/CandidateExchange/CandidateExchange';
import { defaultCandidateOverview } from '../../../models/CandidateOverview/CandidateOverview';
import { Organisation } from '../../../models/Organisation/Organisation';
import { clearCandidateExchange, setError } from '../../../redux/candidateExchange/candidateExchange';
import {
    archiveCandidate,
    fetchExchangeCounselorOptions,
    requestCandidateExchange,
    transferCandidate,
    updateCandidateExchangeSuccess,
} from '../../../redux/candidateExchange/candidateExchangeActions';
import { setCandidateOverview } from '../../../redux/candidateOverview/candidateOverview';
import { fetchCandidatesForOrganisation, setCandidateFilterValues } from '../../../redux/candidateOverview/candidateOverviewActions';
import { fetchCounselorOptions } from '../../../redux/counselorOptions/counselorOptionsActions';
import { fetchOrganisationOptions } from '../../../redux/organisation/organisationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { SortDirection, SortField, UserRole } from '../../../types';

interface ConnectedOrganisationDetailCandidatesProps {
    className?: string;
}

const ConnectedOrganisationDetailCandidates: FC<ConnectedOrganisationDetailCandidatesProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const [counselorSearchQuery, setCounselorSearchQuery] = useState<string>('');
    const [organisationSearchQuery, setOrganisationSearchQuery] = useState<string>('');

    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);
    const user = useTypedSelector(state => state.userReducer.user);

    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);
    const organisation = useTypedSelector(state => state.organisationReducer.organisation);
    const organisationOptions = useTypedSelector(state => state.organisationReducer.options);
    const organisationOptionsError = useTypedSelector(state => state.organisationReducer.optionsError);

    const candidateOverviewIsLoading = useTypedSelector(state => state.candidateOverviewReducer.isLoading);
    const error = useTypedSelector(state => state.candidateOverviewReducer.error);
    const candidateOverview = useTypedSelector(state => state.candidateOverviewReducer.candidateOverview);
    const activeFilterValues = useTypedSelector(state => state.candidateOverviewReducer.activeFilterValues);
    const pagination = useTypedSelector(state => state.candidateOverviewReducer.pagination);

    const counselorOptionsIsLoading = useTypedSelector(state => state.counselorOptionsReducer.isLoading);
    const counselorOptionsError = useTypedSelector(state => state.counselorOptionsReducer.error);
    const counselorOptions = useTypedSelector(state => state.counselorOptionsReducer.options);

    const exchangeIsLoading = useTypedSelector(state => state.candidateExchangeReducer.isLoading);
    const exchangeIsSuccessful = useTypedSelector(state => state.candidateExchangeReducer.isSuccessful);
    const exchangeError = useTypedSelector(state => state.candidateExchangeReducer.error);
    const exchangeCounselorOptions = useTypedSelector(state => state.candidateExchangeReducer.counselorOptions);

    const isLoading = [
        userIsLoading,
        organisationIsLoading,
        candidateOverviewIsLoading,
        counselorOptionsIsLoading,
    ].some(Boolean);

    const isLloAdmin = userHasRole(UserRole.lloAdmin, user?.roles);

    const handleTableSort = (field: SortField, direction = SortDirection.desc): void => {
        dispatch(setCandidateFilterValues({
            ...activeFilterValues,
            pageNumber: 1,
            sortConfig: {
                field,
                direction,
            },
        }));
    };

    const handleTableSearch = (searchQuery: string): void => {
        dispatch(setCandidateFilterValues({
            ...activeFilterValues,
            pageNumber: 1,
            searchQuery,
        }));
    };

    const handleCounselorFilter = (counselorId: string): void => {
        dispatch(setCandidateFilterValues({
            ...activeFilterValues,
            pageNumber: 1,
            counselorId,
        }));
    };

    const handlePaginationChange = (currentPage: number): void => {
        dispatch(setCandidateFilterValues({
            ...activeFilterValues,
            pageNumber: currentPage,
        }));
    };

    const handleOpenExchangeDialog = (): void => {
        dispatch(clearCandidateExchange());

        if (organisation) {
            dispatch(fetchExchangeCounselorOptions(organisation.id, counselorSearchQuery));
        }
    };

    const handleOpenArchiveDialog = (): void => {
        dispatch(setError(''));
    };

    const handleModalOrganisationSelect = (selectedOrganisation: Organisation): void => {
        dispatch(fetchExchangeCounselorOptions(selectedOrganisation.id, counselorSearchQuery));
    };

    const handleSubmitExchangeRequest = (exchangeFormData: CandidateExchangeFormData): void => {
        if (isLloAdmin) {
            dispatch(transferCandidate(exchangeFormData));
        }

        if (!isLloAdmin && organisation) {
            dispatch(requestCandidateExchange(organisation.id, exchangeFormData));
        }
    };

    const handleFinishCandidateExchange = (): void => {
        dispatch(updateCandidateExchangeSuccess(false));

        if (organisation) dispatch(fetchCandidatesForOrganisation(organisation.id));
    };

    const handleSubmitArchiveCandidate = (candidateUuid: string): void => {
        dispatch(archiveCandidate(candidateUuid));
    };

    useTimeout((): void => {
        if (organisation) {
            dispatch(fetchCounselorOptions(organisation.id, counselorSearchQuery));
            dispatch(fetchExchangeCounselorOptions(organisation.id, counselorSearchQuery));
        }
    }, debounceDelay, [organisation, counselorSearchQuery]);

    useTimeout((): void => {
        if (isLloAdmin) {
            dispatch(fetchOrganisationOptions(organisationSearchQuery));
        }
    }, debounceDelay, [isLloAdmin, organisationSearchQuery]);

    useEffect((): void => {
        if (organisation) {
            dispatch(fetchCandidatesForOrganisation(organisation.id));
        }
    }, [organisation, activeFilterValues]);

    useUnmount((): void => {
        dispatch(setCandidateOverview(defaultCandidateOverview()));
    });

    return (
        <OrganisationDetailCandidates
            isLoading={isLoading}
            exchangeIsLoading={exchangeIsLoading}
            counselorOptionsIsLoading={counselorOptionsIsLoading}
            organisationOptionsIsLoading={organisationIsLoading}
            exchangeIsSuccessful={exchangeIsSuccessful}
            user={user}
            organisation={organisation}
            candidateOverview={candidateOverview}
            activeFilterValues={activeFilterValues}
            pagination={pagination}
            error={error}
            exchangeError={exchangeError}
            counselorOptionsError={counselorOptionsError}
            organisationOptionsError={organisationOptionsError}
            counselorOptions={counselorOptions}
            exchangeCounselorOptions={exchangeCounselorOptions}
            organisationOptions={organisationOptions}
            counselorSearchQuery={counselorSearchQuery}
            organisationSearchQuery={organisationSearchQuery}
            onTableSort={handleTableSort}
            onTableSearch={handleTableSearch}
            onCounselorFilter={handleCounselorFilter}
            onPaginationChange={handlePaginationChange}
            onOpenExchangeDialog={handleOpenExchangeDialog}
            onOpenArchiveDialog={handleOpenArchiveDialog}
            onSearchCounselorOptions={setCounselorSearchQuery}
            onSearchOrganisationOptions={setOrganisationSearchQuery}
            onOrganisationSelect={handleModalOrganisationSelect}
            onSubmitExchangeRequest={handleSubmitExchangeRequest}
            onSubmitArchiveCandidate={handleSubmitArchiveCandidate}
            onFinishExchange={handleFinishCandidateExchange}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailCandidates;
