import { NavLinkProps, Route, RouteProps } from 'react-router-dom';

import { IconName } from './components/Icon/Icon';
import { DevelopmentPlanRouteTab } from './containers/@development/DevelopmentPlanHeader/DevelopmentPlanHeader';
import { OccupationRouteTab } from './containers/@occupation/OccupationTabs/OccupationTabs';
import { OrganisationDetailRouteTab } from './containers/@organisation/OrganisationDetailTabs/OrganisationDetailTabs';
import { SkillsPassportRouteTab } from './containers/@skillsPassport/SkillsPassportTabs/SkillsPassportTabs';
import { SettingsRouteTab } from './containers/SettingsTabs/SettingsTabs';
import trans from './helpers/trans';
import {
    CandidateExchange,
    Dashboard,
    Development,
    DevelopmentPlan,
    DevelopmentPlanEvaluation,
    InternalOccupationDetail,
    Logout,
    ManageAnalyses,
    MyActions,
    NotFound,
    OccupationDetail,
    OrganisationDetail,
    OrganisationOverview,
    Registration,
    Root,
    SeekEducation,
    SeekOccupation,
    SeekVacancies,
    Settings,
    SkillsPassport,
    TakeAnalysis,
    VacancyDetail,
} from './pages';
import { ModuleType, RouteKey } from './types';

export type Route = RouteProps & {
    key: string;
    label: string;
    icon?: IconName;
    module?: ModuleType;
}

export interface NavigationItemProps extends Omit<NavLinkProps, 'children'> {
    key: RouteKey;
    label: string;
    icon?: IconName;
    module?: ModuleType;
}

export type RouteParams = Record<string, string | undefined>;

export type NavigationRoute = NavigationItemProps;

export const RoutePaths = {
    root: (): string => '/',
    registration: (): string => '/registreren',
    dashboard: (): string => '/dashboard',
    candidateDashboard: (candidateUuid = ''): string => `/dashboard/${candidateUuid}`,
    candidateExchange: (counselorUuid: string): string => `/overdragen/${counselorUuid}`,
    development: (candidateUuid = ''): string => `/ontwikkeling/${candidateUuid}`,
    developmentPlan: (candidateUuid: string, planUuid: string, tab = DevelopmentPlanRouteTab.default): string => `/ontwikkeling/${candidateUuid}/ontwikkelplan/${planUuid}/${tab}`,
    developmentPlanEvaluation: (candidateUuid: string, planUuid: string, evaluationUuid: string): string => `/ontwikkeling/${candidateUuid}/ontwikkelplan/${planUuid}/evaluatie/${evaluationUuid}`,
    skillsPassport: (candidateUuid = ''): string => `/skills-paspoort/${candidateUuid}`,
    skillsPassportTab: (candidateUuid = '', tab = SkillsPassportRouteTab.default): string => `/skills-paspoort/${candidateUuid}/${tab}`,
    takeAnalysis: (candidateUuid = ''): string => `/analyse-maken/${candidateUuid}`,
    manageAnalyses: (): string => '/analyses-beheren',
    seekEducation: (candidateUuid = ''): string => `/educatie-zoeken/${candidateUuid}`,
    seekOccupation: (candidateUuid = ''): string => `/beroepen-zoeken/${candidateUuid}`,
    seekOccupationTab: (candidateUuid = '', tab = OccupationRouteTab.default): string => `/beroepen-zoeken/${candidateUuid}/${tab}`,
    occupationDetail: (candidateUuid = '', occupationUuid = ''): string => `/beroepen-zoeken/${candidateUuid}/beroep/${occupationUuid}`,
    internalOccupationDetail: (candidateUuid = '', occupationUuid = ''): string => `/beroepen-zoeken/${candidateUuid}/intern-beroep/${occupationUuid}`,
    seekVacancies: (candidateUuid = ''): string => `/vacatures-zoeken/${candidateUuid}`,
    vacancyDetail: (vacancyUuid = ':vacancyUuid', candidateUuid = ''): string => `/vacature/${vacancyUuid}/${candidateUuid}`,
    organisationDetail: (organisationUuid = ':organisationUuid'): string => `/organisaties/${organisationUuid}`,
    organisationDetailTab: (organisationUuid = ':organisationUuid', tab = OrganisationDetailRouteTab.default): string => `/organisaties/${organisationUuid}/${tab}`,
    organisationDetailOccupation: (
        organisationUuid = ':organisationUuid',
        occupationUuid = ':occupationUuid',
    ): string => `/organisaties/${organisationUuid}/${OrganisationDetailRouteTab.occupationOverview}/${occupationUuid}`,
    organisationOverview: (): string => '/organisaties',
    settings: (): string => '/instellingen',
    settingsTab: (tab = SettingsRouteTab.default): string => `/instellingen/${tab}`,
    logout: (): string => '/uitloggen',
    notFound: (): string => '/404',
};

const Routes: Route[] = [
    {
        key: RouteKey.home,
        path: RoutePaths.root(),
        element: <Root />,
        label: trans('routes.home'),
    },
    {
        key: RouteKey.registration,
        path: RoutePaths.registration(),
        element: <Registration />,
        label: trans('routes.registration'),
    },
    {
        key: RouteKey.candidateDashboard,
        path: RoutePaths.candidateDashboard(':candidateUuid'),
        element: <MyActions />,
        label: trans('routes.candidateDashboard'),
        icon: 'dashboard',
    },
    {
        key: RouteKey.candidateExchange,
        path: RoutePaths.candidateExchange(':counselorUuid'),
        element: <CandidateExchange />,
        label: trans('routes.candidateExchange'),
    },
    {
        key: RouteKey.dashboard,
        path: RoutePaths.dashboard(),
        element: <Dashboard />,
        label: trans('routes.dashboard'),
        icon: 'dashboard',
    },
    {
        key: RouteKey.skillsPassport,
        path: RoutePaths.skillsPassport(':candidateUuid'),
        element: <SkillsPassport />,
        label: trans('routes.skillsPassport'),
        icon: 'skills-passport',
        children: (
            <Route path={SkillsPassportRouteTab.default} element={<SkillsPassport />} />
        ),
    },
    {
        key: RouteKey.takeAnalysis,
        path: RoutePaths.takeAnalysis(':candidateUuid'),
        element: <TakeAnalysis />,
        label: trans('routes.takeAnalysis'),
        icon: 'analysis',
    },
    {
        key: RouteKey.organisationDetail,
        path: RoutePaths.organisationDetail(':organisationUuid'),
        element: <OrganisationDetail />,
        label: trans('routes.organisationDetail'),
        icon: 'organisation',
        children: (
            <Route path={OrganisationDetailRouteTab.default} element={<OrganisationDetail />}>
                <Route path={OrganisationDetailRouteTab.occupation} element={<OrganisationDetail />} />
            </Route>
        ),
    },
    {
        key: RouteKey.organisationOverview,
        path: RoutePaths.organisationOverview(),
        element: <OrganisationOverview />,
        label: trans('routes.organisationOverview'),
        icon: 'organisation',
    },
    {
        key: RouteKey.manageAnalyses,
        path: RoutePaths.manageAnalyses(),
        element: <ManageAnalyses />,
        label: trans('routes.manageAnalyses'),
        icon: 'analysis',
    },
    {
        key: RouteKey.developmentPlan,
        path: RoutePaths.developmentPlan(':candidateUuid', ':planUuid', DevelopmentPlanRouteTab.default),
        element: <DevelopmentPlan />,
        label: trans('routes.developmentPlan'),
        children: (
            <Route path={DevelopmentPlanRouteTab.default} element={<DevelopmentPlan />} />
        ),
    },
    {
        key: RouteKey.developmentPlanEvaluation,
        path: RoutePaths.developmentPlanEvaluation(':candidateUuid', ':planUuid', ':evaluationUuid'),
        element: <DevelopmentPlanEvaluation />,
        label: trans('routes.developmentPlan'),
    },
    {
        key: RouteKey.development,
        path: RoutePaths.development(':candidateUuid'),
        element: <Development />,
        label: trans('routes.development'),
        icon: 'development',
    },
    {
        key: RouteKey.seekEducation,
        path: RoutePaths.seekEducation(':candidateUuid'),
        element: <SeekEducation />,
        label: trans('routes.seekEducation'),
        icon: 'education',
        module: ModuleType.education,
    },
    {
        key: RouteKey.seekOccupation,
        path: RoutePaths.seekOccupation(':candidateUuid'),
        element: <SeekOccupation />,
        label: trans('routes.seekOccupation'),
        icon: 'occupation',
        children: (
            <Route path={OccupationRouteTab.default} element={<SeekOccupation />} />
        ),
    },
    {
        key: RouteKey.occupationDetail,
        path: RoutePaths.occupationDetail(':candidateUuid', ':occupationUuid'),
        element: <OccupationDetail />,
        label: trans('routes.occupationDetail'),
    },
    {
        key: RouteKey.internalOccupationDetail,
        path: RoutePaths.internalOccupationDetail(':candidateUuid', ':occupationUuid'),
        element: <InternalOccupationDetail />,
        label: trans('routes.internalOccupationDetail'),
        module: ModuleType.internalOccupations,
    },
    {
        key: RouteKey.seekVacancies,
        path: RoutePaths.seekVacancies(':candidateUuid'),
        element: <SeekVacancies />,
        label: trans('routes.seekVacancies'),
        icon: 'vacancy-search',
        module: ModuleType.matching,
    },
    {
        key: RouteKey.vacancyDetail,
        path: RoutePaths.vacancyDetail(':vacancyUuid', ':candidateUuid'),
        element: <VacancyDetail />,
        label: trans('routes.vacancyDetail'),
        icon: 'vacancy-search',
        module: ModuleType.matching,
    },
    {
        key: RouteKey.settings,
        path: RoutePaths.settings(),
        element: <Settings />,
        label: trans('routes.settings'),
        children: (
            <Route path={SettingsRouteTab.default} element={<Settings />} />
        ),
    },
    {
        key: RouteKey.logout,
        path: RoutePaths.logout(),
        element: <Logout />,
        label: trans('routes.logout'),
    },
    {
        key: RouteKey.notFound,
        path: RoutePaths.notFound(),
        element: <NotFound />,
        label: trans('routes.404'),
    },
    {
        key: RouteKey.notFound,
        path: '/*',
        element: <NotFound />,
        label: trans('routes.404'),
    },
];

export const navigationRoutes = Routes.map(route => ({
    key: route.key as RouteKey,
    label: route.label,
    icon: route?.icon,
    to: route.path as string,
    module: route.module,
}));

export default Routes;
