import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './EducationOverviewSkeletons.scss';

interface EducationOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const EducationOverviewSkeletons: FC<EducationOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`education-overview-skeletons ${className}`}>
            {skeletons.map(skeleton => {
                const propertyAmount = randomInBetweenValue(2, 5);
                const propertySkeletons = generateIdArray(propertyAmount);

                return (
                    <li key={skeleton} className="education-overview-skeletons__item">
                        <Card className="education-overview-skeletons__card">
                            <Skeleton className="education-overview-skeletons__logo" />

                            <div className="education-overview-skeletons__content-wrapper">
                                <div className="education-overview-skeletons__header">
                                    <Skeleton className="education-overview-skeletons__title" />
                                    <Skeleton className="education-overview-skeletons__educator" />
                                </div>

                                <ul className="education-overview-skeletons__property-list">
                                    {propertySkeletons.map(property => (
                                        <li key={property} className="education-overview-skeletons__property" />
                                    ))}
                                </ul>

                                <div className="education-overview-skeletons__description">
                                    <Skeleton className="education-overview-skeletons__description-line" />
                                    <Skeleton className="education-overview-skeletons__description-line" />
                                </div>
                            </div>
                        </Card>
                    </li>
                );
            })}
        </ul>
    );
};

export default EducationOverviewSkeletons;
