import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import {
    CounselorFilterValues,
    CounselorOverview,
    defaultCounselorFilterValues,
    defaultCounselorOverview,
} from '../../models/CounselorOverview/CounselorOverview';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState } from '../../types';

export type CounselorOverviewState = AsyncReduxState<{
    counselorOverview: CounselorOverview;
    activeFilterValues: CounselorFilterValues;
    pagination?: Pagination;
}>;

const initialState: CounselorOverviewState = {
    ...initialAsyncReduxState,
    counselorOverview: defaultCounselorOverview(),
    activeFilterValues: defaultCounselorFilterValues(),
};

export const counselorOverviewSlice = createSlice({
    name: 'counselorOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CounselorOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CounselorOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCounselorOverview(state, action: PayloadAction<CounselorOverview>): CounselorOverviewState {
            return {
                ...state,
                counselorOverview: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<CounselorFilterValues>): CounselorOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): CounselorOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCounselorOverview,
    setActiveFilterValues,
    setPagination,
} = counselorOverviewSlice.actions;

export default counselorOverviewSlice.reducer;
