import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { IconButton, SkillsPassportCard } from '../../../compositions';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { LearningStyle } from '../../../models/SkillsPassport/SkillsPassport';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';
import { LearningStyleGraph } from './subcomponents';

import './LearningStyleCard.scss';

interface LearningStyleCardProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    candidateUuid?: string;
    userRoles?: UserRole[];
    learningStyle: LearningStyle;
    handleRefresh: () => void;
    className?: string;
}

const LearningStyleCard: FC<LearningStyleCardProps> = ({
    isLoading,
    isUpToDate,
    candidateUuid,
    userRoles = [],
    learningStyle,
    handleRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const [isExpanded, toggleIsExpanded] = useToggle(false);

    const hasLearningStyles = learningStyle.styles.length > 0;

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidateUuid));
    };

    const collapseButtonLabel = isExpanded
        ? trans('containers.learningStyleCard.hideFullDescription')
        : trans('containers.learningStyleCard.showFullDescription');

    const descriptionWrapperClassNames = classNames('learning-style-card__description', {
        'learning-style-card__description--is-expanded': isExpanded,
    });

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={!hasLearningStyles}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            title={trans('containers.learningStyleCard.title')}
            description={userRoles.length > 0 ? trans(`containers.learningStyleCard.description.${getRoleForTranslation(userRoles)}`) : ''}
            nullStateText={trans('containers.learningStyleCard.nullState')}
            callToActionButtonLabel={trans('containers.learningStyleCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={handleRefresh}
            className={`learning-style-card ${className}`}
        >
            {(!isLoading && hasLearningStyles) && (
                <>
                    <LearningStyleGraph
                        learningStyle={learningStyle}
                        className="learning-style-card__graph"
                    />

                    <div className={descriptionWrapperClassNames}>
                        {learningStyle.styles.map(style => (
                            <p key={style} className="learning-style-card__paragraph">
                                {style}
                            </p>
                        ))}
                    </div>

                    {learningStyle.styles.length > 2 && (
                        <IconButton
                            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                            iconPos="right"
                            text={collapseButtonLabel}
                            onClick={toggleIsExpanded}
                            className="learning-style-card__collapse-button"
                        />
                    )}
                </>
            )}
        </SkillsPassportCard>
    );
};

export default LearningStyleCard;
