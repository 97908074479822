import { FC, ReactElement } from 'react';

import { SettingsTabs } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedSettingsTabsProps {
    className?: string;
}

const ConnectedSettingsTabs: FC<ConnectedSettingsTabsProps> = ({
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);

    return (
        <SettingsTabs
            user={user}
            className={className}
        />
    );
};

export default ConnectedSettingsTabs;
