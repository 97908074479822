import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type SchoolExperienceState = AsyncReduxState;

const initialState: SchoolExperienceState = {
    ...initialAsyncReduxState,
};

export const schoolExperienceSlice = createSlice({
    name: 'schoolExperienceReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SchoolExperienceState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SchoolExperienceState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SchoolExperienceState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = schoolExperienceSlice.actions;

export default schoolExperienceSlice.reducer;
