import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { CandidateExchange } from '../../containers';
import { clearCandidateExchange, setIsLoading } from '../../redux/candidateExchange/candidateExchange';
import { confirmCandidateExchange } from '../../redux/candidateExchange/candidateExchangeActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { RoutePaths } from '../../Routes';

interface ConnectedCandidateExchangeProps {
    className?: string;
}

const ConnectedCandidateExchange: FC<ConnectedCandidateExchangeProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { counselorUuid } = useParams<{ counselorUuid: string }>();

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isLoading = useTypedSelector(state => state.candidateExchangeReducer.isLoading);
    const isSuccessful = useTypedSelector(state => state.candidateExchangeReducer.isSuccessful);
    const error = useTypedSelector(state => state.candidateExchangeReducer.error);
    const newCounselorFullName = useTypedSelector(state => state.candidateExchangeReducer.newCounselorFullName);

    const handleFinishExchangeClick = (): void => {
        dispatch(clearCandidateExchange());

        navigate(RoutePaths.candidateDashboard(candidate?.uuid || ''));
    };

    useEffect((): void => {
        if (candidate && counselorUuid) {
            dispatch(confirmCandidateExchange(candidate.uuid, counselorUuid));
        }
    }, [candidate, counselorUuid]);

    useEffectOnce((): void => {
        dispatch(setIsLoading(true));
    });

    return (
        <CandidateExchange
            isLoading={isLoading || candidateIsLoading}
            isSuccessful={isSuccessful}
            error={error}
            newCounselorFullName={newCounselorFullName}
            onFinishExchangeClick={handleFinishExchangeClick}
            className={className}
        />
    );
};

export default ConnectedCandidateExchange;
