import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getOrganisationOverviewApiCall } from '../../models/OrganisationOverview/OrganisationOverviewService';
import { transformToPagination } from '../../models/Pagination';
import { setIsSuccessful } from '../organisation/organisation';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setOrganisationOverview,
    setPagination,
} from './organisationOverview';

export const fetchOrganisationOverview = () => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const state = getState();
        const { activeFilterValues } = state.organisationOverviewReducer;

        const organisationOverviewResponse = await getOrganisationOverviewApiCall(activeFilterValues);

        if (!isFetchResultSuccessful(organisationOverviewResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const pagination = transformToPagination(organisationOverviewResponse.data.totalAmount, activeFilterValues.pageNumber);

        dispatch(setOrganisationOverview(organisationOverviewResponse.data));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchOrganisationOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
