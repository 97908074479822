import { ChangeEvent, FC, ReactElement } from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';

import './TextInput.scss';

export interface TextInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
}

const TextInput: FC<TextInputProps> = ({
    label,
    hideLabel = false,
    type = 'text',
    required = false,
    error = '',
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`text-input ${className}`}
        >
            {!hideLabel && <InputLabel text={label} required={required} className="text-input__label" />}
            <Input
                {...inputProps}
                type={type}
                required={required}
                error={error}
                onChange={handleChange}
            />
            {error && <ErrorLabel text={error} className="text-input__error-label" />}
        </label>
    );
};

export default TextInput;
