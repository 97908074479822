import { SortConfig, SortDirection, SortField } from '../../types';
import { defaultPageSize } from '../Pagination';

export interface OrganisationCandidateResource {
    id: string;
    firstName: string;
    lastName: string;
    lastNamePrefix?: string;
    email: string;
    createdAt: string;
    counselor: {
        firstName: string;
        lastName: string;
        lastNamePrefix?: string;
    };
}

export interface CandidateOverviewResource {
    meta: {
        totalAmount: number;
    };
    organisationCandidates: OrganisationCandidateResource[];
}

export interface OrganisationCandidate {
    id: string;
    fullName: string;
    counselorFullName: string;
    email: string;
    createdAt: Date;
}

export interface CandidateOverview {
    totalAmount: number;
    candidates: OrganisationCandidate[];
}

export interface CandidateFilterValues {
    pageNumber: number;
    pageSize: number;
    sortConfig: SortConfig;
    searchQuery: string;
    counselorId: string;
}

export const defaultCandidateOverview = (): CandidateOverview => ({
    totalAmount: 0,
    candidates: [],
});

export const defaultCandidateFilterValues = (): CandidateFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
    sortConfig: {
        field: SortField.createdAt,
        direction: SortDirection.desc,
    },
    searchQuery: '',
    counselorId: '',
});
