import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import {
    ConnectedDevelopmentHeader,
    ConnectedDevelopmentOverview,
    ConnectedDevelopmentOverviewBreadcrumbs,
    ConnectedPage,
} from '../../connectors';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { User } from '../../models/User/User';
import { getDevelopmentTitle } from './helpers';

import './Development.scss';

const Development: FC = (): ReactElement => {
    const [candidate, setCandidate] = useState<Candidate>();
    const [user, setUser] = useState<User>();

    const candidateFullName = candidate?.fullName;
    const pageTitle = getDevelopmentTitle(user?.roles, candidateFullName);

    const helmetTitle = candidateFullName
        ? trans('pages.development.helmetTitle', { name: candidateFullName })
        : '';

    return (
        <ConnectedPage
            hasNavigation
            title={pageTitle}
            className="development-page"
        >
            <Helmet>
                <title>{helmetTitle}</title>
            </Helmet>

            <ConnectedDevelopmentOverviewBreadcrumbs />

            <ConnectedDevelopmentHeader
                onCandidateResponse={setCandidate}
                onUserResponse={setUser}
                className="development-page__header"
            />

            <ConnectedDevelopmentOverview className="development-page__overview" />
        </ConnectedPage>
    );
};

export default Development;
