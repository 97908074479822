import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Button,
    Card,
    LoadingSpinner,
    TableCell,
    TableRow,
} from '../../../components';
import {
    Dialog,
    DialogContent,
    IconButton,
    LinkIconButton,
    NonSortTable,
    Notice,
} from '../../../compositions';
import ConfirmDialog, { ConfirmType } from '../../../compositions/ConfirmDialog/ConfirmDialog';
import { lloOrganisationName } from '../../../constants';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { OrganisationFormData } from '../../../models/Organisation/Organisation';
import { OrganisationOverviewItem } from '../../../models/OrganisationOverviewItem/OrganisationOverviewItem';
import { RoutePaths } from '../../../Routes';
import { NoticeType } from '../../../types';
import { OrganisationForm } from '../..';

import './OrganisationTable.scss';

interface OrganisationTableProps {
    organisationOverviewIsLoading: boolean;
    organisationIsUpdating: boolean;
    organisationIsSuccessful: boolean;
    organisationOverviewError: string;
    organisationError: string;
    totalAmount: number;
    organisations: OrganisationOverviewItem[];
    onAddOrganisationSubmit: (formData: OrganisationFormData) => void;
    onEditOrganisationSubmit: (formData: OrganisationFormData) => void;
    onDeleteOrganisationSubmit: (organisationId: string) => void;
    className?: string;
}

const OrganisationTable: FC<OrganisationTableProps> = ({
    organisationOverviewIsLoading,
    organisationIsUpdating,
    organisationIsSuccessful,
    organisationOverviewError,
    organisationError,
    totalAmount,
    organisations,
    onAddOrganisationSubmit,
    onEditOrganisationSubmit,
    onDeleteOrganisationSubmit,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const addDialogRef = useRef<HTMLDialogElement>(null);
    const editDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDialogRef = useRef<HTMLDialogElement>(null);

    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationOverviewItem>();
    const [organisationFormKey, setOrganisationFormKey] = useState<number>(0);

    const handleOpenAddDialog = (): void => openDialog(addDialogRef);
    const handleCloseAddDialog = (): void => {
        closeDialog(addDialogRef);

        setOrganisationFormKey(organisationFormKey + 1);
    };

    const handleOpenEditDialog = (): void => openDialog(editDialogRef);
    const handleCloseEditDialog = (): void => {
        closeDialog(editDialogRef);
        setSelectedOrganisation(undefined);
    };

    const handleOpenDeleteDialog = (): void => openDialog(deleteDialogRef);
    const handleCloseDeleteDialog = (): void => {
        closeDialog(deleteDialogRef);
        setSelectedOrganisation(undefined);
    };

    const tableHeaders: string[] = [
        trans('tables.organisation.labels.name'),
        trans('tables.organisation.labels.location'),
        trans('tables.organisation.labels.amountOfCounselors'),
        trans('tables.organisation.labels.amountOfCandidates'),
        trans('tables.organisation.labels.organisationId'),
    ];

    const handleRowClick = (organisationId: string): void => {
        navigate(RoutePaths.organisationDetail(organisationId));
    };

    const handleDeleteOrganisationSubmit = (): void => {
        if (selectedOrganisation) onDeleteOrganisationSubmit(selectedOrganisation.id);
    };

    useEffect((): void => {
        if (organisationIsSuccessful) {
            handleCloseAddDialog();
            handleCloseEditDialog();
            handleCloseDeleteDialog();
        }
    }, [organisationIsSuccessful]);

    return (
        <Card className={`organisation-table ${className}`}>
            <header className="organisation-table__header">
                <h1 className="organisation-table__title">
                    {trans('tables.organisation.title', {
                        totalAmount: String(totalAmount),
                    })}
                </h1>

                {organisationOverviewIsLoading && <LoadingSpinner className="organisation-table__loader" />}
            </header>

            {!organisationOverviewIsLoading && !organisationOverviewError && organisations.length === 0 && (
                <div className="organisation-table__null-state-wrapper">
                    <p>{trans('tables.organisation.nullState.description')}</p>

                    <IconButton
                        icon="plus"
                        text={trans('tables.organisation.nullState.buttonLabel')}
                        onClick={handleOpenAddDialog}
                        className="organisation-table__null-state-button"
                    />
                </div>
            )}

            {organisationOverviewError && organisations.length === 0 && (
                <div className="organisation-table__error-wrapper">
                    <Notice
                        type={NoticeType.error}
                        text={organisationOverviewError}
                        className="organisation-table__error-message"
                    />
                </div>
            )}

            {organisations.length > 0 && (
                <NonSortTable
                    hasStickyColumn
                    tableHeaders={tableHeaders}
                    rows={organisations}
                    headerRowClassName="organisation-table__header-row"
                    cellClassName="organisation-table__cell"
                    className="organisation-table__table"
                >
                    {(rows): JSX.Element[] => rows.map(organisation => {
                        const onRowClick = (): void => handleRowClick(organisation.id);

                        const handleEditOrganisationClick = (): void => {
                            setSelectedOrganisation(organisation);
                            handleOpenEditDialog();
                        };

                        const handleDeleteOrganisationClick = (): void => {
                            setSelectedOrganisation(organisation);
                            handleOpenDeleteDialog();
                        };

                        return (
                            <TableRow key={organisation.id} className="organisation-table__row">
                                <TableCell className="organisation-table__cell organisation-table__cell--is-name">
                                    {organisation.name}
                                </TableCell>
                                <TableCell className="organisation-table__cell">
                                    {organisation.location}
                                </TableCell>
                                <TableCell className="organisation-table__cell">
                                    {trans('tables.organisation.amountOfCounselors', {
                                        amountOfCounselors: String(organisation.amountOfCounselors),
                                    })}
                                </TableCell>
                                <TableCell className="organisation-table__cell">
                                    {trans('tables.organisation.amountOfCandidates', {
                                        amountOfCandidates: String(organisation.amountOfCandidates),
                                    })}
                                </TableCell>
                                <TableCell className="organisation-table__cell">
                                    {organisation.id}
                                </TableCell>
                                <TableCell contentClassName="organisation-table__fixed-cell-content" className="organisation-table__fixed-cell">
                                    <LinkIconButton
                                        hideLabel
                                        to={RoutePaths.organisationDetail(organisation.id)}
                                        icon="eye"
                                        text={trans('common.goToDetail')}
                                        className="organisation-table__action-button"
                                    />
                                    <IconButton
                                        hideLabel
                                        icon="edit"
                                        text={trans('tables.organisation.editButtonLabel')}
                                        onClick={handleEditOrganisationClick}
                                        className="organisation-table__action-button"
                                    />
                                    <IconButton
                                        hideLabel
                                        disabled={organisation.name === lloOrganisationName}
                                        icon="bin"
                                        text={trans('tables.organisation.deleteButtonLabel')}
                                        onClick={handleDeleteOrganisationClick}
                                        className="organisation-table__action-button organisation-table__action-button--is-delete"
                                    />
                                </TableCell>
                                <TableCell className="organisation-table__hidden-cell">
                                    <Button
                                        hideLabel
                                        text={trans('common.goToDetail')}
                                        onClick={onRowClick}
                                        className="organisation-table__redirect-button"
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </NonSortTable>
            )}

            <Dialog ref={addDialogRef} onClose={handleCloseAddDialog}>
                <DialogContent title={trans('tables.organisation.addDialog.title')}>
                    <OrganisationForm
                        key={organisationFormKey}
                        isLoading={organisationIsUpdating}
                        error={organisationError}
                        onSubmit={onAddOrganisationSubmit}
                        onCancel={handleCloseAddDialog}
                        className="organisation-overview__form"
                    />
                </DialogContent>
            </Dialog>

            <Dialog ref={editDialogRef} onClose={handleCloseEditDialog}>
                <DialogContent title={trans('tables.organisation.editDialog.title')}>
                    <OrganisationForm
                        key={organisationFormKey}
                        isLoading={organisationIsUpdating}
                        error={organisationError}
                        organisation={selectedOrganisation}
                        onSubmit={onEditOrganisationSubmit}
                        onCancel={handleCloseEditDialog}
                        className="organisation-overview__form"
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDialogRef}
                onClose={handleCloseDeleteDialog}
            >
                <ConfirmDialog
                    isLoading={organisationIsUpdating}
                    title={trans('tables.organisation.deleteDialog.title')}
                    description={trans('tables.organisation.deleteDialog.paragraph', {
                        organisationName: selectedOrganisation?.name || '',
                    })}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onConfirm={handleDeleteOrganisationSubmit}
                    onCancel={handleCloseDeleteDialog}
                />
            </Dialog>
        </Card>
    );
};

export default OrganisationTable;
