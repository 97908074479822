/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconChevronDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 61.7" className={`icon ${className}`}>
        <path d="M11.8,0L50,38.2L88.2,0L100,11.8l-50,50l-50-50L11.8,0z" />
    </svg>
);

export default IconChevronDown;
/* eslint-enable max-len */
