import { FC, ReactElement } from 'react';

import { Card, Icon } from '../../../../../components';
import trans from '../../../../../helpers/trans';

import './DevelopmentOverviewNullState.scss';

interface DevelopmentOverviewNullStateProps {
    className?: string;
}

const DevelopmentOverviewNullState: FC<DevelopmentOverviewNullStateProps> = ({
    className = '',
}): ReactElement => (
    <Card className={`development-overview-null-state ${className}`}>
        <div className="development-overview-null-state__icon-wrapper">
            <Icon name="development" className="development-overview-null-state__icon" />
        </div>

        <h1 className="development-overview-null-state__title">
            {trans('containers.developmentOverview.nullState.title')}
        </h1>
        <p className="development-overview-null-state__description">
            {trans('containers.developmentOverview.nullState.description')}
        </p>
    </Card>
);

export default DevelopmentOverviewNullState;
