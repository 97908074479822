import { AddDevelopmentPlanCompetencyFormData, EditDevelopmentPlanCompetencyFormData } from '../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import {
    deleteDevelopmentPlanCompetencyApiCall,
    patchDevelopmentPlanCompetencyApiCall,
    postDevelopmentPlanCompetencyApiCall,
} from '../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetencyService';
import { transformToAddDevelopmentPlanCompetencyRequest, transformToEditDevelopmentPlanCompetencyRequest } from '../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetencyTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { setError } from '../app/app';
import { TypedDispatch } from '../store';
import { setIsLoading, setIsSuccessful, setUpdatingCompetencyUuid } from './developmentPlanCompetency';

export const addDevelopmentPlanCompetency = (formData: AddDevelopmentPlanCompetencyFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToAddDevelopmentPlanCompetencyRequest(formData);
        const developmentPlanCompetencyResponse = await postDevelopmentPlanCompetencyApiCall(requestData, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanCompetencyResponse)) {
            dispatch(setError(developmentPlanCompetencyResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlanCompetency]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDevelopmentPlanCompetency = (formData: EditDevelopmentPlanCompetencyFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setUpdatingCompetencyUuid(formData.id));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToEditDevelopmentPlanCompetencyRequest(formData);
        const developmentPlanCompetencyResponse = await patchDevelopmentPlanCompetencyApiCall(requestData, formData.id, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanCompetencyResponse)) {
            dispatch(setError(developmentPlanCompetencyResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDevelopmentPlanCompetency]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setUpdatingCompetencyUuid(''));
    }
};

export const deleteDevelopmentPlanCompetency = (id: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentPlanCompetencyResponse = await deleteDevelopmentPlanCompetencyApiCall(id, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanCompetencyResponse)) {
            dispatch(setError(developmentPlanCompetencyResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDevelopmentPlanCompetency]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
