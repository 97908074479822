/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconVerticalEllipsis: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 25 100" className={`icon ${className}`}>
        <circle cx="12.5" cy="87.5" r="12.5" />
        <circle cx="12.5" cy="50" r="12.5" />
        <circle cx="12.5" cy="12.5" r="12.5" />
    </svg>
);

export default IconVerticalEllipsis;
/* eslint-enable max-len */
