import { CompetencyFormData } from '../../../../models/Competencies/Competencies';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMinimumArrayLength,
} from '../../../../services/ValidationService';

export type CompetenciesFormErrors = FormErrors<CompetencyFormData>;

export const validateCompetenciesFormData = (formData: CompetencyFormData): FormValidation<CompetenciesFormErrors> => {
    const errors: CompetenciesFormErrors = {
        competencies: validateMinimumArrayLength('competency', formData.competencies, 1),
    };

    return validateForm<CompetenciesFormErrors>(errors);
};
