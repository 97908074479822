import { FC, ReactElement } from 'react';

import { Card } from '../../components';
import { Loading } from '../../compositions';
import trans from '../../helpers/trans';
import { User } from '../../models/User/User';
import { TroubleshootDetailItem } from './subcomponents';
import { TroubleshootDetail } from './subcomponents/TroubleshootDetailItem/TroubleshootDetailItem';

import './TroubleshootDetailsCard.scss';

interface TroubleshootDetailsCardProps {
    isLoading: boolean;
    user?: User;
    className?: string;
}

const TroubleshootDetailsCard: FC<TroubleshootDetailsCardProps> = ({
    isLoading,
    user,
    className = '',
}): ReactElement => {
    const detailList: TroubleshootDetail[] = [
        {
            title: trans('containers.troubleshootDetailsCard.email.label'),
            description: trans('containers.troubleshootDetailsCard.email.description'),
            value: user?.userName,
        },
        {
            title: trans('containers.troubleshootDetailsCard.accountId.label'),
            description: trans('containers.troubleshootDetailsCard.accountId.description'),
            value: user?.uuid,
        },
    ];

    return (
        <Card className={`troubleshoot-details-card ${className}`}>
            {isLoading && <Loading />}

            {!isLoading && (
                <div className="troubleshoot-details-card__wrapper">
                    {detailList.map(detail => (
                        <TroubleshootDetailItem
                            key={detail.title}
                            title={detail.title}
                            description={detail.description}
                            value={detail.value}
                            className="troubleshoot-details-card__item"
                        />
                    ))}
                </div>
            )}
        </Card>
    );
};

export default TroubleshootDetailsCard;
