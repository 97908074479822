import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import {
    CandidateFilterValues,
    CandidateOverview,
    defaultCandidateFilterValues,
    defaultCandidateOverview,
} from '../../models/CandidateOverview/CandidateOverview';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState } from '../../types';

export type CandidateOverviewState = AsyncReduxState<{
    candidateOverview: CandidateOverview;
    activeFilterValues: CandidateFilterValues;
    pagination?: Pagination;
}>;

const initialState: CandidateOverviewState = {
    ...initialAsyncReduxState,
    candidateOverview: defaultCandidateOverview(),
    activeFilterValues: defaultCandidateFilterValues(),
};

export const candidateOverviewSlice = createSlice({
    name: 'candidateOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CandidateOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CandidateOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCandidateOverview(state, action: PayloadAction<CandidateOverview>): CandidateOverviewState {
            return {
                ...state,
                candidateOverview: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<CandidateFilterValues>): CandidateOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): CandidateOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCandidateOverview,
    setActiveFilterValues,
    setPagination,
} = candidateOverviewSlice.actions;

export default candidateOverviewSlice.reducer;
