import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import trans from '../../../../../helpers/trans';

import './VacancyOverviewNullState.scss';

interface VacancyOverviewNullStateProps {
    onResetClick: () => void;
    className?: string;
}

const VacancyOverviewNullState: FC<VacancyOverviewNullStateProps> = ({
    onResetClick,
    className = '',
}): ReactElement => (
    <div className={`vacancy-overview-null-state ${className}`}>
        <p className="vacancy-overview-null-state__paragraph">
            {trans('containers.vacancyOverview.nullState.noResults')}
        </p>
        <p className="vacancy-overview-null-state__paragraph">
            {trans('containers.vacancyOverview.nullState.adjustYourPreferences')}
        </p>

        <Button
            text={trans('containers.vacancyOverview.nullState.buttonLabel')}
            onClick={onResetClick}
            className="vacancy-overview-null-state__button"
        />
    </div>
);

export default VacancyOverviewNullState;
