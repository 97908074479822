import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { OccupationOverview } from '../../../containers';
import { arrayIndexesForPagination } from '../../../helpers/pagination';
import { scrollToTop } from '../../../helpers/scroll';
import useTimeout from '../../../hooks/useTimeout';
import { Occupation } from '../../../models/Occupations/Occupations';
import { setActiveFilterValues } from '../../../redux/occupationOverview/occupationOverview';
import { fetchOccupationOverview, setOccupationOverviewIsLoading, setOccupationOverviewPagination } from '../../../redux/occupationOverview/occupationOverviewActions';
import { fetchSkillsPassport } from '../../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOccupationOverviewProps {
    className?: string;
}

const ConnectedOccupationOverview: FC<ConnectedOccupationOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const stateDelay = 400;

    const [pageIsLoading, setPageIsLoading] = useState<boolean>(false);

    const user = useTypedSelector(state => state.userReducer.user);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skills = useTypedSelector(state => state.skillsPassportReducer.skills);

    const overviewIsLoading = useTypedSelector(state => state.occupationOverviewReducer.isLoading);
    const error = useTypedSelector(state => state.occupationOverviewReducer.error);
    const activeFilterValues = useTypedSelector(state => state.occupationOverviewReducer.activeFilterValues);
    const occupationOverview = useTypedSelector(state => state.occupationOverviewReducer.occupationOverview);
    const pagination = useTypedSelector(state => state.occupationOverviewReducer.pagination);

    const isLoading = [
        overviewIsLoading,
        candidateIsLoading,
        pageIsLoading,
        skillsPassportIsLoading,
    ].some(Boolean);

    const occupations = useMemo((): Occupation[] => {
        const { start, end } = arrayIndexesForPagination(pagination?.currentPage || 1);

        return occupationOverview.occupations.slice(start, end);
    }, [occupationOverview.occupations, pagination?.currentPage]);

    const handlePaginationChange = (pageNumber: number): void => {
        setPageIsLoading(true);

        const newFilterValues = {
            ...activeFilterValues,
            pageNumber,
        };

        dispatch(setOccupationOverviewPagination(pageNumber));
        dispatch(setActiveFilterValues(newFilterValues));

        scrollToTop();
    };

    useEffect((): void => {
        if (!candidate || skillsPassportIsLoading) return;

        if (candidate.uuid) {
            const skillLabels = skills.map(skill => skill.name);

            dispatch(fetchOccupationOverview(candidate.uuid, skillLabels, activeFilterValues));
        } else {
            dispatch(setOccupationOverviewIsLoading(false));
        }
    }, [candidate, skills, activeFilterValues]);

    useEffect((): void => {
        if (candidate && skills.length === 0) {
            dispatch(fetchSkillsPassport(candidate.uuid));
        }
    }, [candidate]);

    useTimeout((): void => {
        setPageIsLoading(false);
    }, stateDelay, [occupationOverview.occupations, pagination?.currentPage]);

    return (
        <OccupationOverview
            isLoading={isLoading}
            showViewOptions
            error={error}
            user={user}
            candidate={candidate}
            skills={skills}
            activeFilterValues={activeFilterValues}
            totalAmount={occupationOverview.totalAmount}
            occupations={occupations}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            className={className}
        />
    );
};

export default ConnectedOccupationOverview;
