import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import DevelopmentOverview from '../../../containers/@development/DevelopmentOverview/DevelopmentOverview';
import useUnmount from '../../../hooks/useUnmount';
import { DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { fetchDevelopmentOverview } from '../../../redux/developmentOverview/developmentOverviewActions';
import { deleteDevelopmentPlan, editDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlanActions';
import { addDevelopmentPlanEvaluation } from '../../../redux/developmentPlanEvaluation/developmentPlanEvaluationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';

interface ConnectedDevelopmentOverviewProps {
    className?: string;
}

const ConnectedDevelopmentOverview: FC<ConnectedDevelopmentOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const [activeDevelopmentPlanUuid, setActiveDevelopmentPlanUuid] = useState<string>('');

    const user = useTypedSelector(state => state.userReducer.user);

    const developmentOverviewIsLoading = useTypedSelector(state => state.developmentOverviewReducer.isLoading);
    const developments = useTypedSelector(state => state.developmentOverviewReducer.developments);

    const isLoadingDevelopmentPlanEvaluation = useTypedSelector(state => state.developmentPlanEvaluationReducer.isLoading);
    const developmentPlanEvaluation = useTypedSelector(state => state.developmentPlanEvaluationReducer.developmentPlanEvaluation);
    const developmentPlanEvaluationIsSuccessful = useTypedSelector(state => state.developmentPlanEvaluationReducer.isSuccessful);

    const developmentPlanIsSuccessful = useTypedSelector(state => state.developmentPlanReducer.isSuccessful);
    const developmentPlanIsDeleting = useTypedSelector(state => state.developmentPlanReducer.isDeleting);
    const developmentPlanError = useTypedSelector(state => state.developmentPlanReducer.error);

    const handleSubmitDevelopmentPlan = (formData: DevelopmentPlanFormData): void => {
        if (candidateUuid) dispatch(editDevelopmentPlan(formData, candidateUuid));
    };

    const handleDeleteDevelopmentPlan = (developmentPlanUuid: string): void => {
        if (candidateUuid) dispatch(deleteDevelopmentPlan(developmentPlanUuid, candidateUuid));
    };

    const handleAddEvaluation = (developmentUuid: string): void => {
        if (candidateUuid) {
            dispatch(addDevelopmentPlanEvaluation(developmentUuid, candidateUuid));
            setActiveDevelopmentPlanUuid(developmentUuid);
        }
    };

    useEffect((): void => {
        if (candidateUuid && developmentPlanIsSuccessful) {
            dispatch(fetchDevelopmentOverview(candidateUuid));
        }
    }, [developmentPlanIsSuccessful]);

    useEffect((): void => {
        if (
            developmentPlanEvaluationIsSuccessful
            && candidateUuid
            && developmentPlanEvaluation
            && activeDevelopmentPlanUuid
        ) {
            navigate(RoutePaths.developmentPlanEvaluation(
                candidateUuid,
                activeDevelopmentPlanUuid,
                developmentPlanEvaluation.id,
            ));
        }
    }, [developmentPlanEvaluationIsSuccessful, candidateUuid]);

    useUnmount((): void => setActiveDevelopmentPlanUuid(''));

    return (
        <DevelopmentOverview
            isDeleting={developmentPlanIsDeleting}
            candidateUuid={candidateUuid || ''}
            userRoles={user?.roles}
            developmentOverviewIsLoading={developmentOverviewIsLoading}
            developmentPlanEvaluationIsSuccessful={developmentPlanEvaluationIsSuccessful}
            developmentPlanEvaluationIsLoading={isLoadingDevelopmentPlanEvaluation}
            developments={developments}
            developmentPlanIsSuccessful={developmentPlanIsSuccessful}
            developmentPlanError={developmentPlanError}
            onAddEvaluation={handleAddEvaluation}
            onDeleteDevelopmentPlan={handleDeleteDevelopmentPlan}
            onSubmitDevelopmentPlan={handleSubmitDevelopmentPlan}
            className={className}
        />
    );
};

export default ConnectedDevelopmentOverview;
