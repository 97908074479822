import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { DevelopmentPlanEvaluation } from '../../../containers';
import { Candidate } from '../../../models/Candidate/Candidate';
import { DevelopmentPlanWithEvaluation } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { DevelopmentPlanCompetencyEvaluationFormData } from '../../../models/DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluation';
import { DevelopmentPlanSkillEvaluationFormData } from '../../../models/DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluation';
import { User } from '../../../models/User/User';
import { setDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlan';
import { fetchDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlanActions';
import { setIsSuccessful } from '../../../redux/developmentPlanEvaluation/developmentPlanEvaluation';
import { deleteDevelopmentPlanEvaluation, editDevelopmentPlanEvaluation, fetchDevelopmentPlanEvaluation } from '../../../redux/developmentPlanEvaluation/developmentPlanEvaluationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';

interface ConnectedDevelopmentPlanEvaluationProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onUserResponse: (user: User) => void;
    className?: string;
}

const ConnectedDevelopmentPlanEvaluation: FC<ConnectedDevelopmentPlanEvaluationProps> = ({
    onCandidateResponse,
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { candidateUuid, planUuid: developmentPlanUuid, evaluationUuid: developmentPlanEvaluationUuid } = useParams<{ 'candidateUuid': string; planUuid: string; evaluationUuid: string }>();

    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const developmentPlanIsLoading = useTypedSelector(state => state.developmentPlanReducer.isLoading);
    const developmentPlan = useTypedSelector(state => state.developmentPlanReducer.developmentPlan);

    const evaluationIsLoading = useTypedSelector(state => state.developmentPlanEvaluationReducer.isLoading);
    const evaluationIsSuccessful = useTypedSelector(state => state.developmentPlanEvaluationReducer.isSuccessful);
    const developmentPlanEvaluation = useTypedSelector(state => state.developmentPlanEvaluationReducer.developmentPlanEvaluation);

    useEffectOnce((): () => void => {
        if (developmentPlanUuid && developmentPlanEvaluationUuid && candidateUuid) {
            dispatch(fetchDevelopmentPlanEvaluation(developmentPlanEvaluationUuid, candidateUuid));
            dispatch(fetchDevelopmentPlan(developmentPlanUuid, candidateUuid));
        }

        return () => {
            setDevelopmentPlan(undefined);
        };
    });

    useEffect((): void => {
        if (evaluationIsSuccessful && developmentPlanUuid && candidateUuid) {
            dispatch(fetchDevelopmentPlan(developmentPlanUuid, candidateUuid));
        }
    }, [evaluationIsSuccessful]);

    const handleSubmit = (
        competencyEvaluations: DevelopmentPlanCompetencyEvaluationFormData[],
        skillEvaluations: DevelopmentPlanSkillEvaluationFormData[],
    ): void => {
        if (developmentPlanEvaluation && candidate?.uuid) {
            dispatch(editDevelopmentPlanEvaluation(
                developmentPlanEvaluation,
                competencyEvaluations,
                skillEvaluations,
                candidate.uuid,
            ));
        }
    };

    const handleDeleteEvaluation = async (): Promise<void> => {
        if (developmentPlanEvaluationUuid && candidateUuid) {
            await dispatch(deleteDevelopmentPlanEvaluation(developmentPlanEvaluationUuid, candidateUuid));
            navigate(RoutePaths.development(candidateUuid));
        }
    };

    useEffect((): void => {
        if (evaluationIsSuccessful) dispatch(setIsSuccessful(false));
    }, [evaluationIsSuccessful]);

    useEffect((): void => {
        if (user) onUserResponse(user);
    }, [user]);

    useEffect((): void => {
        if (candidate) onCandidateResponse(candidate);
    }, [candidate]);

    return (
        <DevelopmentPlanEvaluation
            isLoading={developmentPlanIsLoading || evaluationIsLoading}
            isSuccessful={evaluationIsSuccessful}
            user={user}
            candidate={candidate}
            developmentPlan={developmentPlan as DevelopmentPlanWithEvaluation}
            onSubmit={handleSubmit}
            onDeleteEvaluation={handleDeleteEvaluation}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanEvaluation;
