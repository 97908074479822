import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getSectorsApiCall } from '../../services/SectorService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSectors } from './sectors';

export const fetchSectors = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const sectorsResponse = await getSectorsApiCall();

        if (!isFetchResultSuccessful(sectorsResponse)) {
            dispatch(setError(sectorsResponse.error));
            return;
        }

        dispatch(setSectors(sectorsResponse.data));
    } catch (error) {
        console.error('[fetchSectors]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
