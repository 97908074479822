import { FC, ReactElement } from 'react';

import {
    ConnectedCompetenciesCard,
    ConnectedJobExperiencesCard,
    ConnectedSchoolExperiencesCard,
    ConnectedSkillsCard,
} from '../../../../../connectors';

import './Competencies.scss';

interface CompetenciesProps {
    onRefresh: () => void;
}

const Competencies: FC<CompetenciesProps> = ({ onRefresh }): ReactElement => (
    <>
        <ConnectedSkillsCard className="competencies-tab__card" />

        <ConnectedCompetenciesCard
            onRefresh={onRefresh}
            className="competencies-tab__card"
        />

        <ConnectedSchoolExperiencesCard className="competencies-tab__card" />
        <ConnectedJobExperiencesCard className="competencies-tab__card" />
    </>
);

export default Competencies;
