import { FC, ReactElement } from 'react';

import { JobExperiencesCard } from '../../../containers';
import { JobExperience, JobExperienceFormData } from '../../../models/JobExperience/JobExperience';
import { setIsSuccessful } from '../../../redux/jobExperience/jobExperience';
import { addJobExperience, deleteJobExperience, editJobExperience } from '../../../redux/jobExperience/jobExperienceActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedJobExperiencesCardProps {
    className?: string;
}

const ConnectedJobExperiencesCard: FC<ConnectedJobExperiencesCardProps> = ({ className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skillsPassportError = useTypedSelector(state => state.skillsPassportReducer.error);
    const jobExperiences = useTypedSelector(state => state.skillsPassportReducer.jobExperiences);

    const jobExperienceIsLoading = useTypedSelector(state => state.jobExperienceReducer.isLoading);
    const jobExperienceIsSuccessful = useTypedSelector(state => state.jobExperienceReducer.isSuccessful);
    const jobExperienceError = useTypedSelector(state => state.jobExperienceReducer.error);

    const handleAddJobExperience = (jobExperienceFormData: JobExperienceFormData): void => {
        if (candidate?.uuid) dispatch(addJobExperience(jobExperienceFormData, candidate.uuid));
    };

    const handleEditJobExperience = (jobExperienceFormData: JobExperienceFormData): void => {
        if (candidate?.uuid) dispatch(editJobExperience(jobExperienceFormData, candidate.uuid));
    };

    const handleDeleteJobExperience = (jobExperience: JobExperience): void => {
        if (candidate?.uuid) dispatch(deleteJobExperience(jobExperience, candidate.uuid));
    };

    const handleIsSuccessfulReset = (): void => {
        dispatch(setIsSuccessful(false));
    };

    return (
        <JobExperiencesCard
            isLoading={skillsPassportIsLoading}
            jobExperienceIsLoading={jobExperienceIsLoading}
            jobExperienceIsSuccessful={jobExperienceIsSuccessful}
            hasError={!!skillsPassportError}
            jobExperienceError={jobExperienceError}
            userRoles={user?.roles}
            jobExperiences={jobExperiences}
            onAddJobExperience={handleAddJobExperience}
            onEditJobExperience={handleEditJobExperience}
            onDeleteJobExperience={handleDeleteJobExperience}
            onIsSuccessfulReset={handleIsSuccessfulReset}
            className={className}
        />
    );
};

export default ConnectedJobExperiencesCard;
