import { FC, ReactElement } from 'react';

import { Card, Logo } from '../../../components';

import './LogoCard.scss';

interface LogoCardProps {
    logo: string;
    className?: string;
}

const LogoCard: FC<LogoCardProps> = ({
    logo,
    className = '',
}): ReactElement => (
    <Card className={`logo-card ${className}`}>
        <Logo name={logo} className="logo-card__logo" />
    </Card>
);

export default LogoCard;
