import { RefObject } from 'react';

export const openDialog = (dialogRef: RefObject<HTMLDialogElement>): void => {
    if (!dialogRef.current) return;

    dialogRef.current.showModal();
    document.body.classList.add('has-active-modal');
};

export const closeDialog = (dialogRef: RefObject<HTMLDialogElement>): void => {
    if (!dialogRef.current) return;

    dialogRef.current.close();
    document.body.classList.remove('has-active-modal');
};
