export const sortById = <Interface extends { id: number }>(entry: Interface, comparisonEntry: Interface): number => (
    entry.id - comparisonEntry.id
);

export const sortByStartDateAsc = <Interface extends { startDate: Date }>(entry: Interface, comparisonEntry: Interface): number => (
    comparisonEntry.startDate.getTime() - entry.startDate.getTime()
);

export const sortByStartDateDesc = <Interface extends { startDate: Date }>(entry: Interface, comparisonEntry: Interface): number => (
    entry.startDate.getTime() - comparisonEntry.startDate.getTime()
);
