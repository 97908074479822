import { getEscoOccupationsApiCall, getSuggestedSkillsApiCall } from '../../models/EscoOccupations/EscoOccupationsService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import {
    setError,
    setEscoOccupations,
    setIsLoading,
    setSuggestedSkills,
} from './escoOccupations';

export const fetchEscoOccupations = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const escoOccupationsResponse = await getEscoOccupationsApiCall();

        if (!isFetchResultSuccessful(escoOccupationsResponse)) {
            dispatch(setError(escoOccupationsResponse.error));
            return;
        }

        dispatch(setEscoOccupations(escoOccupationsResponse.data));
    } catch (error) {
        console.error('[fetchEscoOccupations]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const clearEscoSuggestions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setSuggestedSkills([]));
};

export const fetchSuggestedSkills = (escoOccupationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const suggestedSkillsResponse = await getSuggestedSkillsApiCall(escoOccupationUuid);

        if (!isFetchResultSuccessful(suggestedSkillsResponse)) {
            dispatch(setError(suggestedSkillsResponse.error));
            return;
        }

        const suggestedSkills = suggestedSkillsResponse.data;

        dispatch(setSuggestedSkills(suggestedSkills));
    } catch (error) {
        console.error('[fetchSuggestedSkills]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
