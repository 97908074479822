import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Skill, SkillResource } from '../Skills/Skills';
import { transformToSkill } from '../Skills/SkillsTransformers';
import { EscoOccupation, EscoOccupationResource } from './EscoOccupations';
import { transformToEscoOccupation } from './EscoOccupationsTransformers';

export const getEscoOccupationsApiCall = async (): Promise<FetchResult<EscoOccupation[], string>> => {
    try {
        const response = await lloFetch('/api/escoOccupation');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const escoOccupationsResponse: EscoOccupationResource[] = await response.json();

        const escoOccupations: EscoOccupation[] = escoOccupationsResponse.map(transformToEscoOccupation);
        const sortedEscoOccupations = escoOccupations
            .sort((occupation, comparisonOccupation) => occupation.name.localeCompare(comparisonOccupation.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedEscoOccupations,
        };
    } catch (error) {
        console.error('[getEscoOccupationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getSuggestedSkillsApiCall = async (escoOccupationUuid: string): Promise<FetchResult<Skill[], string>> => {
    try {
        const response = await lloFetch(`/api/escoOccupation/${escoOccupationUuid}/skills`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const skillsResponse: SkillResource[] = await response.json();

        const skills = skillsResponse.map(transformToSkill);
        const sortedSkills = skills.sort((skill, comparisonSkill) => skill.name.localeCompare(comparisonSkill.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedSkills,
        };
    } catch (error) {
        console.error('[getSuggestedSkillsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
