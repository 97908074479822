import { transformToInternalOccupation } from '../InternalOccupations/InternalOccupationsTransformers';
import { OccupationOverview } from '../OccupationOverview/OccupationOverview';
import { InternalOccupationOverviewResource } from './InternalOccupationOverview';

export const transformToInternalOccupationOverview = (
    internalOccupationOverviewResource: InternalOccupationOverviewResource,
): OccupationOverview => ({
    totalAmount: internalOccupationOverviewResource.meta.totalAmount,
    occupations: internalOccupationOverviewResource.internalOccupations.map(transformToInternalOccupation),
});
