import { FC, PropsWithChildren, ReactElement } from 'react';

import { Card } from '../../../components';

import './FloatingCard.scss';

interface FloatingCardProps {
    className?: string;
    cardClassName?: string;
}

const FloatingCard: FC<PropsWithChildren<FloatingCardProps>> = ({
    className = '',
    cardClassName = '',
    children,
}): ReactElement => (
    <div className={`floating-card ${className}`}>
        <Card className={`floating-card__card ${cardClassName}`}>
            {children}
        </Card>
    </div>
);

export default FloatingCard;
