import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { CoachingAdvice } from '../../models/CoachingAdvice/CoachingAdvice';
import { AsyncReduxState } from '../../types';

export interface CoachingAdviceState extends AsyncReduxState {
    coachingAdvice?: CoachingAdvice;
}

const initialState: CoachingAdviceState = {
    ...initialAsyncReduxState,
};

export const coachingAdviceSlice = createSlice({
    name: 'coachingAdviceReducer',
    initialState,
    reducers: {
        clearCoachingAdvice(): CoachingAdviceState {
            return initialState;
        },
        setIsLoading(state, action: PayloadAction<boolean>): CoachingAdviceState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CoachingAdviceState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCoachingAdvice(state, action: PayloadAction<CoachingAdvice>): CoachingAdviceState {
            return {
                ...state,
                coachingAdvice: action.payload,
            };
        },
    },
});

export const {
    clearCoachingAdvice,
    setIsLoading,
    setError,
    setCoachingAdvice,
} = coachingAdviceSlice.actions;

export default coachingAdviceSlice.reducer;
