import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getCurrentVersion = async (): Promise<FetchResult<string, string>> => {
    try {
        const currentVersionResponse = await fetch('/version-current.json');
        const currentVersionJson = await currentVersionResponse.json();

        const currentVersion = currentVersionJson.version;

        return {
            status: 200,
            type: FetchResultType.Success,
            data: currentVersion,
        };
    } catch (error) {
        console.error('[getCurrentVersion]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getLatestVersion = async (): Promise<FetchResult<string, string>> => {
    try {
        const latestVersionResponse = await fetch('/version-latest.json');
        const latestVersionJson = await latestVersionResponse.json();

        const latestVersion = latestVersionJson.version;

        return {
            status: 200,
            type: FetchResultType.Success,
            data: latestVersion,
        };
    } catch (error) {
        console.error('[getLatestVersion]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
