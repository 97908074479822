import { getAddressApiCall } from '../../models/Address/AddressService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import { setAddress, setError, setIsLoading } from './address';

export const fetchAddress = (postalCode: string, houseNumber: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const addressResponse = await getAddressApiCall(postalCode, houseNumber);

        if (!isFetchResultSuccessful(addressResponse)) {
            dispatch(setError(addressResponse.error));
            return;
        }

        dispatch(setAddress(addressResponse.data));
    } catch (error) {
        console.error('[fetchAddress]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const resetAddressError = () => (dispatch: TypedDispatch): void => {
    dispatch(setError(''));
};
