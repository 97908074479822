import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../components';
import { Competency } from '../../models/Competencies/Competencies';

import './CompetencyOption.scss';

interface CompetencyOptionProps {
    isSelected?: boolean;
    option: Competency;
    selectedOptions: Competency[];
    onSelectOption: (selectedOption: Competency) => void;
    className?: string;
}

const CompetencyOption: FC<CompetencyOptionProps> = ({
    isSelected,
    option,
    selectedOptions,
    onSelectOption,
    className = '',
}): ReactElement => {
    const skillIsSelected = isSelected && selectedOptions.find(existingOption => existingOption.id === option.id);

    const handleClick = (): void => onSelectOption(option);

    const buttonClassNames = classNames('competency-option__button', {
        'competency-option__button--is-selected': skillIsSelected,
    });

    return (
        <li className={`competency-option ${className}`}>
            <Button
                text={option.label}
                onClick={handleClick}
                className={buttonClassNames}
            >
                <span className="competency-option__label">
                    {option.label}
                </span>
                <div className="competency-option__icon-wrapper">
                    <Icon
                        name={skillIsSelected ? 'cross' : 'plus'}
                        className="competency-option__icon"
                    />
                </div>
            </Button>
        </li>
    );
};

export default CompetencyOption;
