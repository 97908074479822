import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { RootPortal } from '../../components';
import trans from '../../helpers/trans';
import { IconButton, YouTubeEmbed } from '..';

import './Theatre.scss';

interface TheatreProps {
    autoPlay?: boolean;
    embedId: string;
    transitionDuration?: number;
    onClose: () => void;
    className?: string;
}

const Theatre: FC<TheatreProps> = ({
    autoPlay,
    embedId,
    transitionDuration = 200,
    onClose,
    className = '',
}): ReactElement => {
    const [isRevealed, setIsRevealed] = useState<boolean>(false);

    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const hideTheatre = (): void => {
        setIsRevealed(false);
        setTimeout(onClose, transitionDuration);
    };

    useEffect((): void => {
        setIsRevealed(true);
    }, []);

    useEffect((): () => void => {
        if (isRevealed) document.body.classList.add('has-active-modal');

        if (isRevealed && closeButtonRef.current) {
            closeButtonRef.current.focus();
            closeButtonRef.current.blur();
        }

        return (): void => document.body.classList.remove('has-active-modal');
    }, [isRevealed]);

    const cssVariables = {
        '--theatre-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    const coverDetailClassNames = classNames('theatre', {
        'theatre--is-revealed': isRevealed,
    }, className);

    return (
        <RootPortal>
            <div style={cssVariables} className={coverDetailClassNames}>
                <IconButton
                    ref={closeButtonRef}
                    icon="cross"
                    text={trans('compositions.theatre.closeTheatre')}
                    hideLabel
                    onClick={hideTheatre}
                    className="theatre__close-button"
                    iconClassName="theatre__close-icon"
                />

                <div className="theatre__video-wrapper">
                    <YouTubeEmbed
                        autoPlay={autoPlay}
                        embedId={embedId}
                        className="theatre__video"
                    />
                </div>
            </div>
        </RootPortal>
    );
};

export default Theatre;
