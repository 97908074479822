import trans from '../../helpers/trans';
import { CandidateFilterValues } from '../../models/CandidateOverview/CandidateOverview';
import { getCandidatesForOrganisationApiCall } from '../../models/CandidateOverview/CandidateOverviewService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { transformToPagination } from '../../models/Pagination';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setActiveFilterValues,
    setCandidateOverview,
    setError,
    setIsLoading,
    setPagination,
} from './candidateOverview';

export const setCandidateFilterValues = (filterValues: CandidateFilterValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setActiveFilterValues(filterValues));
};

export const fetchCandidatesForOrganisation = (organisationId: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const state = getState();
        const { activeFilterValues } = state.candidateOverviewReducer;

        const candidateOverviewResponse = await getCandidatesForOrganisationApiCall(organisationId, activeFilterValues);

        if (!isFetchResultSuccessful(candidateOverviewResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const { totalAmount, candidates } = candidateOverviewResponse.data;

        if (candidates.length === 0) {
            const { searchQuery, counselorId } = activeFilterValues;

            if (searchQuery) {
                dispatch(setError(trans('errors.noResultsFoundFor', { searchQuery })));
            } else if (counselorId) {
                dispatch(setError(trans('errors.noResultsFoundForCounselor')));
            } else {
                dispatch(setError(trans('errors.noResultsFound')));
            }

            return;
        }

        const pagination = transformToPagination(totalAmount, activeFilterValues.pageNumber);

        dispatch(setCandidateOverview(candidateOverviewResponse.data));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchCandidatesForOrganisation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
