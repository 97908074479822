import {
    AddDevelopmentPlanCompetencyFormData,
    AddDevelopmentPlanCompetencyRequest,
    DevelopmentPlanCompetency,
    DevelopmentPlanCompetencyResource,
    EditDevelopmentPlanCompetencyFormData,
    EditDevelopmentPlanCompetencyRequest,
} from './DevelopmentPlanCompetency';

export const transformToDevelopmentPlanCompetency = (resource: DevelopmentPlanCompetencyResource): DevelopmentPlanCompetency => ({
    id: resource.id,
    name: resource.name,
    what: resource.what,
    why: resource.why,
    when: resource.when,
    how: resource.how,
    goals: resource.goals,
    remarks: resource.remarks,
    isCompleted: resource.isCompleted,
});

export const transformToAddDevelopmentPlanCompetencyRequest = (formData: AddDevelopmentPlanCompetencyFormData): AddDevelopmentPlanCompetencyRequest => ({
    name: formData.competency,
    developmentPlanId: formData.developmentPlanId,
});

export const transformToEditDevelopmentPlanCompetencyRequest = (formData: EditDevelopmentPlanCompetencyFormData): EditDevelopmentPlanCompetencyRequest => ({
    id: formData.id,
    what: formData.what,
    why: formData.why,
    when: formData.when,
    how: formData.how,
    goals: formData.goals,
    remarks: formData.remarks,
    isCompleted: formData.isCompleted,
});
