/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconExchange: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 77.8" className={`icon ${className}`}>
        <polygon points="100,22.2 77.8,0 77.8,16.7 38.9,16.7 38.9,27.8 77.8,27.8 77.8,44.4" />
        <polygon points="0,55.6 22.2,77.8 22.2,61.1 61.1,61.1 61.1,50 22.2,50 22.2,33.3" />
    </svg>
);

export default IconExchange;
/* eslint-enable max-len */
