import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getFieldsOfStudyApiCall } from '../../services/FieldsOfStudyService';
import { TypedDispatch } from '../store';
import { setError, setFieldsOfStudy, setIsLoading } from './fieldsOfStudy';

export const fetchFieldsOfStudy = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const fieldsOfStudyResponse = await getFieldsOfStudyApiCall();

        if (!isFetchResultSuccessful(fieldsOfStudyResponse)) {
            dispatch(setError(fieldsOfStudyResponse.error));
            return;
        }

        dispatch(setFieldsOfStudy(fieldsOfStudyResponse.data));
    } catch (error) {
        console.error('[fetchFieldsOfStudy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
