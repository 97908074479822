/* eslint-disable prefer-promise-reject-errors */
import { configureRefreshFetch } from 'refresh-fetch';

import { AuthenticationAccessToken } from '../models/AccessToken';
import { isFetchResultSuccessful } from '../models/FetchResult';
import { RoutePaths } from '../Routes';
import { fetchAuthenticationRefreshToken } from '../services/AuthenticationService';
import { AuthToken } from '../types';

interface RequestInitExtraOptions extends RequestInit {
    disableContentType?: boolean;
}

const fetchWithToken = async (url: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithToken: RequestInit = {
        ...options,
        headers: {
            Accept: 'application/json',
            ...options?.headers,
        },
    };

    if (!options?.disableContentType) {
        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            'Content-Type': 'application/json',
        };
    }

    const token = localStorage.getItem(AuthToken.auth);

    if (token) {
        const parsedToken: AuthenticationAccessToken = JSON.parse(token);

        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            Authorization: `Bearer ${parsedToken.jwtToken}`,
        };
    }

    const response = await fetch(url, optionsWithToken);

    if (response.status === 401) {
        return Promise.reject({
            response,
        });
    }

    return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefreshToken = (error: any): boolean => error?.response?.status === 401;

const updateRefreshToken = async (): Promise<void> => {
    const token = localStorage.getItem(AuthToken.auth);

    if (!token) {
        throw new Error('No token found');
    }

    const refreshResponse = await fetchAuthenticationRefreshToken();

    if (!isFetchResultSuccessful(refreshResponse)) {
        localStorage.removeItem(AuthToken.auth);
        window.location.href = RoutePaths.root();

        throw new Error('Refresh token was invalid');
    }

    localStorage.setItem(AuthToken.auth, JSON.stringify(refreshResponse.data));
};

export const authorisedFetch = configureRefreshFetch({
    fetch: fetchWithToken,
    refreshToken: updateRefreshToken,
    shouldRefreshToken,
});
