import { isFetchResultSuccessful } from '../../models/FetchResult';
import { fetchTalentzAuthToken } from '../../services/TalentzAuthService';
import { AuthToken } from '../../types';
import { TypedDispatch } from '../store';
import { setError, setIsLoading } from './talentzAuth';

export const setTalentzAuthToken = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const authResponse = await fetchTalentzAuthToken();

        if (!isFetchResultSuccessful(authResponse)) {
            dispatch(setError(authResponse.error));
            return;
        }

        localStorage.setItem(AuthToken.talentz, JSON.stringify(authResponse.data));
    } catch (error) {
        console.error('[setTalentzAuthToken]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
