import { FC, ReactElement } from 'react';

import { Card } from '../../../components';
import { InternalOccupationFilterValues } from '../../../models/InternalOccupationOverview/InternalOccupationOverview';
import { OccupationSearchForm } from '../..';

import './InternalOccupationFilters.scss';

interface InternalOccupationFiltersProps {
    isLoading: boolean;
    activeFilterValues: InternalOccupationFilterValues;
    onSearchSubmit: (value: string) => void;
    className?: string;
}

const InternalOccupationFilters: FC<InternalOccupationFiltersProps> = ({
    isLoading,
    activeFilterValues,
    onSearchSubmit,
    className = '',
}): ReactElement => (
    <header className={`internal-occupation-filters ${className}`}>
        <Card className="internal-occupation-filters__card">
            <OccupationSearchForm
                isLoading={isLoading}
                value={activeFilterValues.searchQuery}
                onSubmit={onSearchSubmit}
                className="internal-occupation-filters__search-form"
            />
        </Card>
    </header>
);

export default InternalOccupationFilters;
