import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { transformResourceToWorkRemoteOption, WorkRemoteOption, WorkRemoteResource } from '../models/WorkRemote';

export const getWorkRemoteOptionsApiCall = async (): Promise<FetchResult<WorkRemoteOption[], string>> => {
    try {
        const response = await talentzFetch('/api/remote-working');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<WorkRemoteResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const workRemoteOptions = doc.data.map(transformResourceToWorkRemoteOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: workRemoteOptions,
        };
    } catch (error) {
        console.error('[getWorkRemoteOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
