import { FC, ReactElement, useRef } from 'react';

import { useToggle } from 'react-use';

import { LoadingSpinner } from '../../components';
import { ExpandableNavigation, IconButton } from '../../compositions';
import { ExpandableNavigationItem } from '../../compositions/ExpandableNavigation/ExpandableNavigation';
import trans from '../../helpers/trans';
import useHandleClickOutside from '../../hooks/useHandleClickOutside';

import './ProfileNavigation.scss';

interface ProfileNavigationProps {
    userName?: string;
    navigationItems?: ExpandableNavigationItem[];
    className?: string;
}

const ProfileNavigation: FC<ProfileNavigationProps> = ({
    userName,
    navigationItems = [],
    className = '',
}): ReactElement => {
    const [navigationIsOpen, toggleNavigationIsOpen] = useToggle(false);

    const profileNavigationRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(profileNavigationRef, () => toggleNavigationIsOpen(false));

    return (
        <div ref={profileNavigationRef} className={`profile-navigation ${className}`}>
            {!userName ? (
                <LoadingSpinner className="profile-navigation__loading-spinner" />
            ) : (
                <span className="profile-navigation__user-name">
                    {userName}
                </span>
            )}

            <IconButton
                icon="vertical-ellipsis"
                text={trans(`containers.profileNavigation.${navigationIsOpen ? 'closeNav' : 'openNav'}`)}
                hideLabel
                onClick={toggleNavigationIsOpen}
                disabled={navigationItems.length === 0}
                className="profile-navigation__toggle-button"
            />

            <ExpandableNavigation
                isActive={navigationIsOpen}
                name={trans('containers.profileNavigation.navigationAriaLabel')}
                navigationItems={navigationItems}
                className="profile-navigation__nav"
            />
        </div>
    );
};

export default ProfileNavigation;
