import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultDevelopmentPlanEvaluation, DevelopmentPlanEvaluation } from '../../models/DevelopmentPlanEvaluation/DevelopmentPlanEvaluation';
import { AsyncReduxState } from '../../types';

export type DevelopmentPlanEvaluationState = AsyncReduxState<{
    developmentPlanEvaluation: DevelopmentPlanEvaluation;
}>;

const initialState: DevelopmentPlanEvaluationState = {
    ...initialAsyncReduxState,
    developmentPlanEvaluation: defaultDevelopmentPlanEvaluation(),
};

export const developmentPlanEvaluationSlice = createSlice({
    name: 'developmentPlanEvaluationReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DevelopmentPlanEvaluationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DevelopmentPlanEvaluationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DevelopmentPlanEvaluationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDevelopmentPlanEvaluation(state, action: PayloadAction<DevelopmentPlanEvaluation>): DevelopmentPlanEvaluationState {
            return {
                ...state,
                developmentPlanEvaluation: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setIsSuccessful,
    setDevelopmentPlanEvaluation,
} = developmentPlanEvaluationSlice.actions;

export default developmentPlanEvaluationSlice.reducer;
