import { FC, ReactElement, useEffect } from 'react';

import { SchoolExperiencesCard } from '../../../containers';
import { userHasRole } from '../../../helpers/role';
import { transformEducationLevelToFormOption } from '../../../models/EducationLevels';
import { SchoolExperience, SchoolExperienceFormData } from '../../../models/SchoolExperience/SchoolExperience';
import { fetchEducationLevels } from '../../../redux/educationLevels/educationLevelsActions';
import { fetchFieldsOfStudy } from '../../../redux/fieldsOfStudy/fieldsOfStudyActions';
import { fetchLearningPaths } from '../../../redux/learningPaths/learningPathsActions';
import { setIsSuccessful } from '../../../redux/schoolExperience/schoolExperience';
import { addSchoolExperience, deleteSchoolExperience, editSchoolExperience } from '../../../redux/schoolExperience/schoolExperienceActions';
import { fetchSchools } from '../../../redux/schools/schoolsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { UserRole } from '../../../types';

interface ConnectedSchoolExperiencesCardProps {
    className?: string;
}

const ConnectedSchoolExperiencesCard: FC<ConnectedSchoolExperiencesCardProps> = ({ className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skillsPassportError = useTypedSelector(state => state.skillsPassportReducer.error);
    const schoolExperiences = useTypedSelector(state => state.skillsPassportReducer.schoolExperiences);

    const schoolExperienceIsLoading = useTypedSelector(state => state.schoolExperienceReducer.isLoading);
    const schoolExperienceIsSuccessful = useTypedSelector(state => state.schoolExperienceReducer.isSuccessful);
    const schoolExperienceError = useTypedSelector(state => state.schoolExperienceReducer.error);

    const educationLevels = useTypedSelector(state => state.educationLevelsReducer.educationLevels);
    const educationSubLevels = useTypedSelector(state => state.educationLevelsReducer.educationSubLevels);
    const learningPaths = useTypedSelector(state => state.learningPathsReducer.learningPaths);
    const fieldsOfStudy = useTypedSelector(state => state.fieldsOfStudyReducer.fieldsOfStudy);
    const schools = useTypedSelector(state => state.schoolsReducer.schools);

    useEffect((): void => {
        if (!userHasRole(UserRole.candidate, user?.roles)) return;

        if (educationLevels.length === 0) dispatch(fetchEducationLevels());
        if (learningPaths.length === 0) dispatch(fetchLearningPaths());
        if (fieldsOfStudy.length === 0) dispatch(fetchFieldsOfStudy());
        if (schools.length === 0) dispatch(fetchSchools());
    }, [user]);

    const educationLevelOptions = educationLevels.map(transformEducationLevelToFormOption);

    const handleAddSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData): void => {
        if (candidate?.uuid) dispatch(addSchoolExperience(schoolExperienceFormData, candidate.uuid));
    };

    const handleEditSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData): void => {
        if (candidate?.uuid) dispatch(editSchoolExperience(schoolExperienceFormData, candidate.uuid));
    };

    const handleDeleteSchoolExperience = (schoolExperience: SchoolExperience): void => {
        if (candidate?.uuid) dispatch(deleteSchoolExperience(schoolExperience, candidate.uuid));
    };

    const handleIsSuccessfulReset = (): void => {
        dispatch(setIsSuccessful(false));
    };

    return (
        <SchoolExperiencesCard
            isLoading={skillsPassportIsLoading}
            schoolExperienceIsLoading={schoolExperienceIsLoading}
            schoolExperienceIsSuccessful={schoolExperienceIsSuccessful}
            hasError={!!skillsPassportError}
            schoolExperienceError={schoolExperienceError}
            userRoles={user?.roles}
            schoolExperiences={schoolExperiences}
            educationLevelOptions={educationLevelOptions}
            educationSubLevelOptions={educationSubLevels}
            learningPathOptions={learningPaths}
            fieldOfStudyOptions={fieldsOfStudy}
            schoolOptions={schools}
            onAddSchoolExperience={handleAddSchoolExperience}
            onEditSchoolExperience={handleEditSchoolExperience}
            onDeleteSchoolExperience={handleDeleteSchoolExperience}
            onIsSuccessfulReset={handleIsSuccessfulReset}
            className={className}
        />
    );
};

export default ConnectedSchoolExperiencesCard;
