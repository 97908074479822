import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Card } from '../../../../../components';
import {
    ConfirmDialog,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../../compositions';
import { ConfirmType } from '../../../../../compositions/ConfirmDialog/ConfirmDialog';
import { formatDate } from '../../../../../helpers/date';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { DevelopmentPlanNote, DevelopmentPlanNoteFormData, DevelopmentPlanNoteType } from '../../../../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import { DevelopmentPlanNoteForm } from '../../../..';

import './DevelopmentPlanNoteCard.scss';

interface DevelopmentPlanNoteCardProps {
    isUpdating: boolean;
    isSuccessful: boolean;
    isAuthor: boolean;
    noteType: DevelopmentPlanNoteType;
    note: DevelopmentPlanNote;
    onEditNoteSubmit: (formData: DevelopmentPlanNoteFormData) => void;
    onDeleteNoteSubmit: (noteId: string) => void;
    className?: string;
}

const DevelopmentPlanNoteCard: FC<DevelopmentPlanNoteCardProps> = ({
    isUpdating,
    isSuccessful,
    isAuthor,
    noteType,
    note,
    onEditNoteSubmit,
    onDeleteNoteSubmit,
    className = '',
}): ReactElement => {
    const editDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDialogRef = useRef<HTMLDialogElement>(null);

    const [developmentPlanNoteFormKey, setDevelopmentPlanNoteFormKey] = useState<number>(0);

    const handleOpenEditDialog = (): void => openDialog(editDialogRef);
    const handleCloseEditDialog = (): void => {
        closeDialog(editDialogRef);

        setDevelopmentPlanNoteFormKey(developmentPlanNoteFormKey + 1);
    };

    const handleOpenDeleteDialog = (): void => openDialog(deleteDialogRef);
    const handleCloseDeleteDialog = (): void => closeDialog(deleteDialogRef);

    const handleDeleteNoteSubmit = (): void => {
        onDeleteNoteSubmit(note.id);
    };

    useEffect((): void => {
        if (isSuccessful) {
            handleCloseEditDialog();
            handleCloseDeleteDialog();
        }
    }, [isSuccessful]);

    return (
        <li className={`development-plan-note-card ${className}`}>
            <Card className="development-plan-note-card__card">
                <div className="development-plan-note-card__header">
                    <div className="development-plan-note-card__meta-wrapper">
                        {(noteType === DevelopmentPlanNoteType.public && note.author) && (
                            <h2 className="development-plan-note-card__author">
                                {note.author.fullName}
                            </h2>
                        )}

                        <p className="development-plan-note-card__last-updated">
                            {trans('containers.developmentPlanNotes.lastUpdated', {
                                date: formatDate(note.updatedAt, 'dd MMMM yyyy'),
                                time: formatDate(note.updatedAt, 'HH:mm'),
                            })}
                        </p>
                    </div>

                    <div className="development-plan-note-card__controls-wrapper">
                        <IconButton
                            hideLabel
                            icon="edit"
                            text={trans('common.edit')}
                            onClick={handleOpenEditDialog}
                            className="development-plan-note-card__control-button"
                        />

                        {isAuthor && (
                            <IconButton
                                hideLabel
                                icon="bin"
                                text={trans('common.delete')}
                                onClick={handleOpenDeleteDialog}
                                className="development-plan-note-card__control-button"
                            />
                        )}
                    </div>
                </div>

                <p className="development-plan-note-card__content">
                    {note.content}
                </p>
            </Card>

            <Dialog ref={editDialogRef} onClose={handleCloseEditDialog}>
                <DialogContent title={trans('containers.developmentPlanNotes.editDialog.title')}>
                    <DevelopmentPlanNoteForm
                        key={developmentPlanNoteFormKey}
                        isLoading={isUpdating}
                        note={note}
                        onSubmit={onEditNoteSubmit}
                        onCancel={handleCloseEditDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDialogRef}
                onClose={handleCloseDeleteDialog}
            >
                <ConfirmDialog
                    isLoading={isUpdating}
                    title={trans('containers.developmentPlanNotes.confirmDelete.title')}
                    description={trans('containers.developmentPlanNotes.confirmDelete.paragraph')}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onConfirm={handleDeleteNoteSubmit}
                    onCancel={handleCloseDeleteDialog}
                />
            </Dialog>
        </li>
    );
};

export default DevelopmentPlanNoteCard;
