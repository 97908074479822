import { FC, ReactElement, useEffect } from 'react';

import { OrganisationDetailCounselors } from '../../../containers';
import { scrollToTop } from '../../../helpers/scroll';
import useUnmount from '../../../hooks/useUnmount';
import { CounselorFormData } from '../../../models/Counselor/Counselor';
import { defaultCounselorOverview } from '../../../models/CounselorOverview/CounselorOverview';
import { createCounselor, finishCreateCounselor, updateCounselor } from '../../../redux/counselor/counselorActions';
import { setActiveFilterValues, setCounselorOverview } from '../../../redux/counselorOverview/counselorOverview';
import { fetchCounselorsForOrganisation } from '../../../redux/counselorOverview/counselorOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrganisationDetailCounselorsProps {
    className?: string;
}

const ConnectedOrganisationDetailCounselors: FC<ConnectedOrganisationDetailCounselorsProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);
    const organisation = useTypedSelector(state => state.organisationReducer.organisation);

    const counselorIsUpdating = useTypedSelector(state => state.counselorReducer.isUpdating);
    const counselorIsSuccessful = useTypedSelector(state => state.counselorReducer.isSuccessful);
    const counselorError = useTypedSelector(state => state.counselorReducer.error);

    const counselorOverviewIsLoading = useTypedSelector(state => state.counselorOverviewReducer.isLoading);
    const counselorOverviewError = useTypedSelector(state => state.counselorOverviewReducer.error);
    const counselorOverview = useTypedSelector(state => state.counselorOverviewReducer.counselorOverview);
    const activeFilterValues = useTypedSelector(state => state.counselorOverviewReducer.activeFilterValues);
    const pagination = useTypedSelector(state => state.counselorOverviewReducer.pagination);

    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);
    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = [
        organisationIsLoading,
        counselorOverviewIsLoading,
        userIsLoading,
    ].some(Boolean);

    const handleAddCounselor = (formData: CounselorFormData): void => {
        if (organisation) dispatch(createCounselor(formData, organisation.id));
    };

    const handleEditCounselor = (formData: CounselorFormData): void => {
        if (organisation) dispatch(updateCounselor(formData, organisation.id));
    };

    const handleCloseInviteCounselorSuccessMessage = (): void => {
        if (counselorIsSuccessful) {
            dispatch(finishCreateCounselor());
        }
    };

    const handlePaginationChange = (pageNumber: number): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber,
        };

        dispatch(setActiveFilterValues(newFilterValues));

        scrollToTop();
    };

    useUnmount((): void => {
        dispatch(setCounselorOverview(defaultCounselorOverview()));
    });

    useEffect((): void => {
        if (counselorIsSuccessful && organisation) {
            dispatch(fetchCounselorsForOrganisation(organisation.id));
        }
    }, [counselorIsSuccessful]);

    useEffect((): void => {
        if (organisation) dispatch(fetchCounselorsForOrganisation(organisation.id));
    }, [activeFilterValues, organisation]);

    return (
        <OrganisationDetailCounselors
            isLoading={isLoading}
            counselorFormIsLoading={counselorIsUpdating}
            counselorFormIsSuccessful={counselorIsSuccessful}
            counselorOverviewError={counselorOverviewError}
            counselorFormError={counselorError}
            counselors={counselorOverview.counselors}
            totalAmount={counselorOverview.totalAmount}
            pagination={pagination}
            userRoles={user?.roles}
            onAddCounselor={handleAddCounselor}
            onEditCounselor={handleEditCounselor}
            onCloseInviteCounselorSuccessMessage={handleCloseInviteCounselorSuccessMessage}
            onPaginationChange={handlePaginationChange}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailCounselors;
