import { FC, ReactElement, useState } from 'react';

import { Card } from '../../../components';
import { FilterControls } from '../../../compositions';
import { getActiveFilterValues } from '../../../helpers/filter';
import { getAmountOfDefinedValues } from '../../../helpers/object';
import trans from '../../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../../models/EducationLevels';
import { defaultVacancyFilterValues, VacancyFilterValues } from '../../../models/MatchingVacancies';
import { FormOption } from '../../../types';
import { VacancySearchForm } from '../..';
import { VacancySearchFormValues } from '../../@forms/VacancySearchForm/VacancySearchForm';
import { VacancyFiltersForm } from './subcomponents';

import './VacancyFilters.scss';

export interface VacancyFiltersProps {
    isLoading: boolean;
    activeFilterValues: VacancyFilterValues;
    whatOptions: string[];
    whereOptions: string[];
    sectorOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    workRemoteOptions: FormOption[];
    jobTypeOptions: FormOption[];
    amountOfEmployeesOptions: FormOption[];
    filterValues: VacancyFilterValues;
    onSearchSubmit: (value: VacancySearchFormValues) => void;
    onFilterChange: (value: Partial<VacancyFilterValues>) => void;
    onFilterReset: () => void;
    onFilterSubmit: () => void;
    className?: string;
}

const VacancyFilters: FC<VacancyFiltersProps> = ({
    isLoading,
    activeFilterValues,
    whatOptions,
    whereOptions,
    sectorOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    workRemoteOptions,
    jobTypeOptions,
    amountOfEmployeesOptions,
    filterValues,
    onSearchSubmit,
    onFilterChange,
    onFilterReset,
    onFilterSubmit,
    className = '',
}): ReactElement => {
    const [filterSidebarIsExpanded, setFilterSidebarIsExpanded] = useState<boolean>(false);

    const openFilterSidebar = (): void => setFilterSidebarIsExpanded(true);
    const closeFilterSidebar = (): void => setFilterSidebarIsExpanded(false);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
        pageNumber,
        pageSize,
        what,
        where,
        skills,
        competencies,
        ...sidebarFilters
    } = activeFilterValues;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const searchValues = { what, where };
    const defaultFilters = defaultVacancyFilterValues();
    const activeFilters = getActiveFilterValues(sidebarFilters, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    return (
        <header className={`vacancy-filters ${className}`}>
            <Card className="vacancy-filters__card">
                <VacancySearchForm
                    isLoading={isLoading}
                    value={searchValues}
                    whatOptions={whatOptions}
                    whereOptions={whereOptions}
                    onSubmit={onSearchSubmit}
                    className="vacancy-filters__search-form"
                />

                <FilterControls
                    isLoading={isLoading}
                    isExpanded={filterSidebarIsExpanded}
                    amountOfActiveFilters={amountOfActiveFilters}
                    sidebarTitle={trans('containers.vacancyFilters.sidebar.title')}
                    onOpenSidebar={openFilterSidebar}
                    onCloseSidebar={closeFilterSidebar}
                    onFilterReset={onFilterReset}
                    className="vacancy-filters__filter-wrapper"
                >
                    <VacancyFiltersForm
                        isLoading={isLoading}
                        hasLocation={!!where}
                        filterValues={filterValues}
                        sectorOptions={sectorOptions}
                        educationLevelOptions={educationLevelOptions}
                        educationSubLevelOptions={educationSubLevelOptions}
                        workRemoteOptions={workRemoteOptions}
                        jobTypeOptions={jobTypeOptions}
                        amountOfEmployeesOptions={amountOfEmployeesOptions}
                        onChange={onFilterChange}
                        onReset={onFilterReset}
                        onSubmit={onFilterSubmit}
                        onClose={closeFilterSidebar}
                    />
                </FilterControls>
            </Card>
        </header>
    );
};

export default VacancyFilters;
