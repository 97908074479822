import { addKmUnit } from '../helpers/string';
import { JobdiggerVacancyResource, transformJobdiggerVacancyToVacancy } from './JobdiggerVacancy';
import { MatchingVacancies, VacancyFilterValues } from './MatchingVacancies';

export interface JobdiggerVacanciesResource {
    _totalHits: number;
    jobs: JobdiggerVacancyResource[];
}

export const transformJobdiggerVacanciesToMatchingVacancies = (
    jobdiggerVacanciesResource: JobdiggerVacanciesResource,
): MatchingVacancies => ({
    totalAmount: jobdiggerVacanciesResource._totalHits,
    vacancies: jobdiggerVacanciesResource.jobs.map(transformJobdiggerVacancyToVacancy),
});

export interface JobdiggerVacancyFilterParams {
    userLanguage: string;
    query: {
        userQuery: string[];
        educationDegreesMinimumRequired?: string[];
        physicalLocation?: {
            place: string;
            distance: string;
        };
    };
    limit: number;
}

export const transformFilterValuesToJobdiggerFilterParams = (
    limit: number,
    filterValues: VacancyFilterValues,
): JobdiggerVacancyFilterParams => ({
    userLanguage: 'nl-NL',
    query: {
        userQuery: [filterValues.what],
        ...(filterValues.educationLevels.length > 0 && { educationDegreesMinimumRequired: filterValues.educationLevels }),
        ...(filterValues.where && {
            physicalLocation: {
                place: filterValues.where,
                distance: addKmUnit(filterValues.distance),
            },
        }),
    },
    limit,
});
