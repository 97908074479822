import { FC, FormEvent, ReactElement } from 'react';

import './PaginationInput.scss';

interface PaginationInputProps {
    paginationId: string;
    page: number;
    checked?: boolean;
    onChange: (page: number) => void;
}

const PaginationInput: FC<PaginationInputProps> = ({
    paginationId,
    page,
    checked,
    onChange,
}): ReactElement => {
    const handleChange = (event: FormEvent<HTMLInputElement>): void => {
        const pageNumber = Number(event.currentTarget.value);
        onChange(pageNumber);
    };

    return (
        <div className="pagination-input">
            <input
                id={`${paginationId}-${page}`}
                type="radio"
                name={paginationId}
                value={page}
                checked={checked}
                onChange={handleChange}
                className="pagination-input__radio"
            />
            <label htmlFor={`${paginationId}-${page}`} className="pagination-input__label">
                {page}
            </label>
        </div>
    );
};

export default PaginationInput;
