import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { LoadingSpinner } from '../../../components';
import { clamp } from '../../../helpers/number';
import { createCustomOption, searchOptionsOnQuery } from '../../../helpers/search';
import useHandleClickOutside from '../../../hooks/useHandleClickOutside';
import useKeyPress from '../../../hooks/useKeyPress';
import { SearchableOption } from '../../../types';
import { SearchInput } from '../..';
import { SearchInputProps } from '../SearchInput/SearchInput';
import { SearchableValueInputOption } from './subcomponents';

import './SearchableValueInput.scss';

interface SearchableValueInputProps extends Omit<SearchInputProps, 'onChange'> {
    isLoading?: boolean;
    isSearchable?: boolean;
    clearOnNewOptions?: boolean;
    options: SearchableOption[];
    resultLimit?: number;
    onChange: (option: SearchableOption) => void;
    className?: string;
    inputClassName?: string;
    inputWrapperClassName?: string;
    listClassName?: string;
}

const SearchableValueInput: FC<SearchableValueInputProps> = ({
    isLoading,
    isSearchable,
    clearOnNewOptions,
    hideIcon,
    options,
    resultLimit = 10,
    onChange,
    className = '',
    inputClassName,
    inputWrapperClassName,
    listClassName = '',
    ...inputProps
}): ReactElement => {
    const defaultFocusIndex = -1;

    const [searchResults, setSearchResults] = useState<SearchableOption[]>([]);
    const [focusIndex, setFocusIndex] = useState<number>(defaultFocusIndex);
    const pressedUp = useKeyPress('ArrowUp');
    const pressedDown = useKeyPress('ArrowDown');

    const searchableValueInputRef = useRef<HTMLDivElement>(null);
    const resultListRef = useRef<HTMLUListElement>(null);

    const updateSelectedIndex = useCallback((index: number): void => {
        const newIndex = clamp(index, 0, searchResults.length - 1);
        setFocusIndex(newIndex);
    }, [setFocusIndex, searchResults]);

    const clearResults = (): void => {
        setSearchResults([]);
        setFocusIndex(defaultFocusIndex);
    };

    useEffect((): void => {
        if (clearOnNewOptions) {
            clearResults();
        }
    }, [clearOnNewOptions, options]);

    useHandleClickOutside(searchableValueInputRef, clearResults);

    useEffect((): void => {
        if (searchResults.length > 0) {
            if (pressedUp) updateSelectedIndex(focusIndex - 1);
            if (pressedDown) updateSelectedIndex(focusIndex + 1);
        }
    }, [pressedUp, pressedDown]);

    useEffect((): void => {
        if (resultListRef.current && focusIndex >= 0) {
            const buttons = resultListRef.current.querySelectorAll('button');
            buttons[focusIndex].focus();
        }
    }, [focusIndex, resultListRef]);

    const handleChange = (query: string): void => {
        onChange(createCustomOption(query));

        if (isSearchable) {
            setSearchResults(searchOptionsOnQuery(options, query, resultLimit));
            setFocusIndex(defaultFocusIndex);
        }
    };

    const handleClick = (option: SearchableOption): void => {
        onChange(option);
        clearResults();
    };

    return (
        <div ref={searchableValueInputRef} className={`searchable-value-input ${className}`}>
            <SearchInput
                {...inputProps}
                hideIcon={hideIcon || !isSearchable || options.length === 0}
                onChange={handleChange}
                className={inputClassName}
                inputWrapperClassName={inputWrapperClassName}
            />

            {isLoading && (
                <div className="searchable-value-input__loading-wrapper">
                    <LoadingSpinner />
                </div>
            )}

            {!isLoading && searchResults.length > 0 && (
                <ul ref={resultListRef} className={`searchable-value-input__result-list ${listClassName}`}>
                    {searchResults.map(option => (
                        <SearchableValueInputOption
                            key={option.value}
                            option={option}
                            onSelect={handleClick}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchableValueInput;
