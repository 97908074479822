import { FC, ReactElement } from 'react';

import { Button } from '../../components';
import trans from '../../helpers/trans';
import { MessageState, UserRole } from '../../types';
import { DialogContent } from '..';

import './InviteSuccessMessage.scss';

interface InviteSuccessMessageProps {
    isCandidate?: boolean;
    firstName: string;
    onConfirm: () => void;
    className?: string;
}

const InviteSuccessMessage: FC<InviteSuccessMessageProps> = ({
    isCandidate,
    firstName,
    onConfirm,
    className = '',
}): ReactElement => {
    const roleKey = isCandidate ? UserRole.candidate : UserRole.counselor;

    return (
        <DialogContent
            title={trans(`compositions.inviteSuccessMessage.title.${roleKey}`)}
            titleIcon="check"
            messageState={MessageState.positive}
            className={`invite-success-message ${className}`}
        >
            <p className="invite-success-message__paragraph">
                {trans('compositions.inviteSuccessMessage.description', {
                    firstName,
                })}
            </p>

            <div className="invite-success-message__button-wrapper">
                <Button
                    text={trans('compositions.inviteSuccessMessage.confirm')}
                    onClick={onConfirm}
                />
            </div>
        </DialogContent>
    );
};

export default InviteSuccessMessage;
