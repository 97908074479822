/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconViewCompact: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 77.8" className={`icon ${className}`}>
        <rect x="0" width="100" height="22.2" />
        <rect x="0" y="27.8" width="100" height="22.2" />
        <rect x="0" y="55.6" width="100" height="22.2" />
    </svg>
);

export default IconViewCompact;
/* eslint-enable max-len */
