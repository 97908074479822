import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultVacancyFilterValues, VacancyFilterValues } from '../../models/MatchingVacancies';
import { Pagination } from '../../models/Pagination';
import { Vacancy } from '../../models/Vacancy';
import { AsyncReduxState } from '../../types';

export type MatchingVacanciesState = AsyncReduxState<{
    activeFilterValues: VacancyFilterValues;
    matchingVacancies: Vacancy[];
    pagination?: Pagination;
    shouldResetFilters: boolean;
}>;

const initialState: MatchingVacanciesState = {
    ...initialAsyncReduxState,
    isLoading: true,
    activeFilterValues: defaultVacancyFilterValues(),
    matchingVacancies: [],
    pagination: undefined,
    shouldResetFilters: false,
};

export const matchingVacanciesSlice = createSlice({
    name: 'matchingVacanciesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): MatchingVacanciesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): MatchingVacanciesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<VacancyFilterValues>): MatchingVacanciesState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setMatchingVacancies(state, action: PayloadAction<Vacancy[]>): MatchingVacanciesState {
            return {
                ...state,
                matchingVacancies: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): MatchingVacanciesState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
        setShouldResetFilters(state, action: PayloadAction<boolean>): MatchingVacanciesState {
            return {
                ...state,
                shouldResetFilters: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveFilterValues,
    setPagination,
    setMatchingVacancies,
    setShouldResetFilters,
} = matchingVacanciesSlice.actions;

export default matchingVacanciesSlice.reducer;
