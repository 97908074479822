import { FC, ReactElement, useState } from 'react';

import { ConnectedDevelopmentPlanEvaluation, ConnectedPage } from '../../connectors';
import { Candidate } from '../../models/Candidate/Candidate';
import { User } from '../../models/User/User';
import { getDevelopmentTitle } from '../Development/helpers';

import './DevelopmentPlanEvaluation.scss';

const DevelopmentPlanEvaluation: FC = (): ReactElement => {
    const [candidate, setCandidate] = useState<Candidate>();
    const [user, setUser] = useState<User>();

    const candidateFullName = candidate?.fullName;
    const pageTitle = getDevelopmentTitle(user?.roles, candidateFullName);

    return (
        <ConnectedPage
            hasNavigation
            title={pageTitle}
            className="development-plan-evaluation-page"
        >
            <ConnectedDevelopmentPlanEvaluation
                onCandidateResponse={setCandidate}
                onUserResponse={setUser}
                className="development-plan-evaluation-page__evaluation"
            />
        </ConnectedPage>
    );
};

export default DevelopmentPlanEvaluation;
