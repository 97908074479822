/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconDashboard: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect x="55.6" y="44.4" width="44.4" height="55.6" />
        <rect x="55.6" width="44.4" height="33.3" />
        <rect y="66.7" width="44.4" height="33.3" />
        <rect width="44.4" height="55.6" />
    </svg>
);

export default IconDashboard;
/* eslint-enable max-len */
