/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconNoticeError: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect x="45" y="25" width="10" height="30" />
        <rect x="45" y="65" width="10" height="10" />
        <path d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50s50-22.4,50-50C100,22.4,77.6,0,50,0z M50,90c-22.1,0-40-17.9-40-40c0-22.1,17.9-40,40-40s40,17.9,40,40C90,72.1,72.1,90,50,90z" />
    </svg>
);

export default IconNoticeError;
/* eslint-enable max-len */
