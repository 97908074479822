import { OrganisationOverviewItem, OrganisationOverviewItemResource } from '../OrganisationOverviewItem/OrganisationOverviewItem';

export type OrganisationResource = Omit<OrganisationOverviewItemResource, 'amountOfCounselors' | 'amountOfCandidates'>;

export type Organisation = Omit<OrganisationOverviewItem, 'amountOfCounselors' | 'amountOfCandidates'>;

export interface AddOrganisationRequest {
    name: string;
    location: string;
}

export interface EditOrganisationRequest {
    id: string;
    name: string;
    location: string;
}

export interface OrganisationFormData {
    id?: string;
    name: string;
    location: string;
}

export const defaultOrganisation = (): Organisation => ({
    id: '',
    name: '',
    location: '',
});
