import { FC, ReactElement } from 'react';

import { Icon } from '../../components';
import { IconName } from '../../components/Icon/Icon';
import { IconButton } from '..';

import './CallToActionNullState.scss';

interface CallToActionNullStateProps {
    isEditable?: boolean;
    icon: IconName;
    paragraph: string;
    subParagraph?: string;
    buttonText?: string;
    onButtonClick?: () => void;
    className?: string;
}

const CallToActionNullState: FC<CallToActionNullStateProps> = ({
    isEditable,
    icon,
    paragraph,
    subParagraph = '',
    buttonText = '',
    onButtonClick,
    className = '',
}): ReactElement => (
    <div className={`call-to-action-null-state ${className}`}>
        <div className="call-to-action-null-state__icon-wrapper">
            <Icon name={icon} className="call-to-action-null-state__icon" />
        </div>

        <p className="call-to-action-null-state__paragraph">{paragraph}</p>

        {isEditable && (
            <p className="call-to-action-null-state__sub-paragraph">{subParagraph}</p>
        )}

        {isEditable && buttonText && (
            <IconButton
                icon="plus"
                text={buttonText}
                onClick={onButtonClick}
                className="call-to-action-null-state__button"
                iconClassName="call-to-action-null-state__button-icon"
            />
        )}
    </div>
);

export default CallToActionNullState;
