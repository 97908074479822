import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './InternalOccupationDetailContentSkeletons.scss';

interface InternalOccupationDetailContentSkeletonsProps {
    className?: string;
}

const InternalOccupationDetailContentSkeletons: FC<InternalOccupationDetailContentSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const skillsAmount = randomInBetweenValue(8, 16);
    const skillsSkeletons = generateIdArray(skillsAmount);

    const competenciesAmount = randomInBetweenValue(8, 16);
    const competenciesSkeletons = generateIdArray(competenciesAmount);

    return (
        <div className={`internal-occupation-detail-content-skeletons ${className}`}>
            <Skeleton className="internal-occupation-detail-content-skeletons__skill-title" />

            <Card className="internal-occupation-detail-content-skeletons__skills">
                <Skeleton className="internal-occupation-detail-content-skeletons__label" />

                <ul className="internal-occupation-detail-content-skeletons__list">
                    {skillsSkeletons.map(skill => (
                        <li key={skill} className="internal-occupation-detail-content-skeletons__list-item" />
                    ))}
                </ul>
            </Card>

            <Skeleton className="internal-occupation-detail-content-skeletons__skill-title" />

            <Card className="internal-occupation-detail-content-skeletons__skills">
                <Skeleton className="internal-occupation-detail-content-skeletons__label" />

                <ul className="internal-occupation-detail-content-skeletons__list">
                    {competenciesSkeletons.map(competency => (
                        <li key={competency} className="internal-occupation-detail-content-skeletons__list-item" />
                    ))}
                </ul>
            </Card>
        </div>
    );
};

export default InternalOccupationDetailContentSkeletons;
