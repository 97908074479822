import { combineReducers } from 'redux';

import addressReducer, { AddressState } from './address/address';
import amountOfEmployeesReducer, { AmountOfEmployeesState } from './amountOfEmployees/amountOfEmployees';
import appReducer, { AppState } from './app/app';
import authenticationReducer, { AuthenticationState } from './authentication/authentication';
import candidateReducer, { CandidateState } from './candidate/candidate';
import candidateExchangeReducer, { CandidateExchangeState } from './candidateExchange/candidateExchange';
import candidateInviteReducer, { CandidateInviteState } from './candidateInvite/candidateInvite';
import candidateOverviewReducer, { CandidateOverviewState } from './candidateOverview/candidateOverview';
import candidateRelationsReducer, { CandidateRelationsState } from './candidateRelations/candidateRelations';
import coachingAdviceReducer, { CoachingAdviceState } from './coachingAdvice/coachingAdvice';
import competenciesReducer, { CompetenciesState } from './competencies/competencies';
import counselorReducer, { CounselorState } from './counselor/counselor';
import counselorOptionsReducer, { CounselorOptionsState } from './counselorOptions/counselorOptions';
import counselorOverviewReducer, { CounselorOverviewState } from './counselorOverview/counselorOverview';
import developmentOverviewReducer, { DevelopmentOverviewState } from './developmentOverview/developmentOverview';
import developmentPlanReducer, { DevelopmentPlanState } from './developmentPlan/developmentPlan';
import developmentPlanCompetencyReducer, { DevelopmentPlanCompetencyState } from './developmentPlanCompetency/developmentPlanCompetency';
import developmentPlanEvaluationReducer, { DevelopmentPlanEvaluationState } from './developmentPlanEvaluation/developmentPlanEvaluation';
import developmentPlanPrivateNotesReducer, { DevelopmentPlanPrivateNotesState } from './developmentPlanPrivateNotes/developmentPlanPrivateNotes';
import developmentPlanPublicNotesReducer, { DevelopmentPlanPublicNotesState } from './developmentPlanPublicNotes/developmentPlanPublicNotes';
import developmentPlanSkillReducer, { DevelopmentPlanSkillState } from './developmentPlanSkill/developmentPlanSkill';
import educationLevelsReducer, { EducationLevelsState } from './educationLevels/educationLevels';
import educationsReducer, { EducationsState } from './educations/educations';
import escoOccupationsReducer, { EscoOccupationsState } from './escoOccupations/escoOccupations';
import fieldsOfStudyReducer, { FieldsOfStudyState } from './fieldsOfStudy/fieldsOfStudy';
import internalOccupationDetailReducer, { InternalOccupationDetailState } from './internalOccupationDetail/internalOccupationDetail';
import internalOccupationOverviewReducer, { InternalOccupationOverviewState } from './internalOccupationOverview/internalOccupationOverview';
import jobExperienceReducer, { JobExperienceState } from './jobExperience/jobExperience';
import jobTypesReducer, { JobTypesState } from './jobTypes/jobTypes';
import learningPathsReducer, { LearningPathsState } from './learningPaths/learningPaths';
import matchingVacanciesReducer, { MatchingVacanciesState } from './matchingVacancies/matchingVacancies';
import modulesReducer, { ModulesState } from './modules/modules';
import occupationDetailReducer, { OccupationDetailState } from './occupationDetail/occupationDetail';
import occupationOverviewReducer, { OccupationOverviewState } from './occupationOverview/occupationOverview';
import organisationReducer, { OrganisationState } from './organisation/organisation';
import organisationOccupationReducer, { OrganisationOccupationState } from './organisationOccupation/organisationOccupation';
import organisationOccupationOverviewReducer, { OrganisationOccupationOverviewState } from './organisationOccupationOverview/organisationOccupationOverview';
import organisationOverviewReducer, { OrganisationOverviewState } from './organisationOverview/organisationOverview';
import residencesReducer, { ResidencesState } from './residences/residences';
import schoolExperienceReducer, { SchoolExperienceState } from './schoolExperience/schoolExperience';
import schoolsReducer, { SchoolsState } from './schools/schools';
import sectorsReducer, { SectorsState } from './sectors/sectors';
import skillsReducer, { SkillsState } from './skills/skills';
import skillsPassportReducer, { SkillsPassportState } from './skillsPassport/skillsPassport';
import talentzAuthReducer, { TalentzAuthState } from './talentzAuth/talentzAuth';
import tmaAnalysisSettingsReducer, { TmaAnalysisSettingsState } from './tmaAnalysisSettings/tmaAnalysisSettings';
import userReducer, { UserState } from './user/user';
import vacancyReducer, { VacancyState } from './vacancy/vacancy';
import workRemoteReducer, { WorkRemoteState } from './workRemote/workRemote';

export interface Reducers {
    addressReducer: AddressState;
    amountOfEmployeesReducer: AmountOfEmployeesState;
    appReducer: AppState;
    authenticationReducer: AuthenticationState;
    candidateReducer: CandidateState;
    candidateExchangeReducer: CandidateExchangeState;
    candidateInviteReducer: CandidateInviteState;
    candidateOverviewReducer: CandidateOverviewState;
    candidateRelationsReducer: CandidateRelationsState;
    coachingAdviceReducer: CoachingAdviceState;
    competenciesReducer: CompetenciesState;
    counselorReducer: CounselorState;
    counselorOptionsReducer: CounselorOptionsState;
    counselorOverviewReducer: CounselorOverviewState;
    developmentOverviewReducer: DevelopmentOverviewState;
    developmentPlanReducer: DevelopmentPlanState;
    developmentPlanCompetencyReducer: DevelopmentPlanCompetencyState;
    developmentPlanEvaluationReducer: DevelopmentPlanEvaluationState;
    developmentPlanPrivateNotesReducer: DevelopmentPlanPrivateNotesState;
    developmentPlanPublicNotesReducer: DevelopmentPlanPublicNotesState;
    developmentPlanSkillReducer: DevelopmentPlanSkillState;
    educationLevelsReducer: EducationLevelsState;
    educationsReducer: EducationsState;
    escoOccupationsReducer: EscoOccupationsState;
    fieldsOfStudyReducer: FieldsOfStudyState;
    internalOccupationOverviewReducer: InternalOccupationOverviewState;
    internalOccupationDetailReducer: InternalOccupationDetailState;
    jobExperienceReducer: JobExperienceState;
    jobTypesReducer: JobTypesState;
    learningPathsReducer: LearningPathsState;
    matchingVacanciesReducer: MatchingVacanciesState;
    modulesReducer: ModulesState;
    occupationDetailReducer: OccupationDetailState;
    occupationOverviewReducer: OccupationOverviewState;
    organisationReducer: OrganisationState;
    organisationOccupationReducer: OrganisationOccupationState;
    organisationOccupationOverviewReducer: OrganisationOccupationOverviewState;
    organisationOverviewReducer: OrganisationOverviewState;
    residencesReducer: ResidencesState;
    schoolExperienceReducer: SchoolExperienceState;
    schoolsReducer: SchoolsState;
    sectorsReducer: SectorsState;
    skillsReducer: SkillsState;
    skillsPassportReducer: SkillsPassportState;
    talentzAuthReducer: TalentzAuthState;
    tmaAnalysisSettingsReducer: TmaAnalysisSettingsState;
    userReducer: UserState;
    vacancyReducer: VacancyState;
    workRemoteReducer: WorkRemoteState;
}

export default combineReducers({
    addressReducer,
    amountOfEmployeesReducer,
    appReducer,
    authenticationReducer,
    candidateReducer,
    candidateExchangeReducer,
    candidateInviteReducer,
    candidateOverviewReducer,
    candidateRelationsReducer,
    coachingAdviceReducer,
    competenciesReducer,
    counselorReducer,
    counselorOptionsReducer,
    counselorOverviewReducer,
    developmentOverviewReducer,
    developmentPlanReducer,
    developmentPlanCompetencyReducer,
    developmentPlanEvaluationReducer,
    developmentPlanPrivateNotesReducer,
    developmentPlanPublicNotesReducer,
    developmentPlanSkillReducer,
    educationLevelsReducer,
    educationsReducer,
    escoOccupationsReducer,
    fieldsOfStudyReducer,
    internalOccupationDetailReducer,
    internalOccupationOverviewReducer,
    jobExperienceReducer,
    jobTypesReducer,
    learningPathsReducer,
    matchingVacanciesReducer,
    modulesReducer,
    occupationDetailReducer,
    occupationOverviewReducer,
    organisationReducer,
    organisationOccupationReducer,
    organisationOccupationOverviewReducer,
    organisationOverviewReducer,
    residencesReducer,
    schoolExperienceReducer,
    schoolsReducer,
    sectorsReducer,
    skillsReducer,
    skillsPassportReducer,
    talentzAuthReducer,
    tmaAnalysisSettingsReducer,
    userReducer,
    vacancyReducer,
    workRemoteReducer,
});
