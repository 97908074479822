/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconChevronLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 61.8 100" className={`icon ${className}`}>
        <path d="M61.8,88.2L23.6,50l38.2-38.2L50,0L0,50l50,50L61.8,88.2z" />
    </svg>
);

export default IconChevronLeft;
/* eslint-enable max-len */
