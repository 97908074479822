import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    DevelopmentPlan,
    DevelopmentPlanRequest,
    DevelopmentPlanResource,
    DevelopmentPlanWithSkillsRequest,
} from './DevelopmentPlan';
import { transformToDevelopmentPlan } from './DevelopmentPlanTransformer';

export const getDevelopmentPlanApiCall = async (developmentPlanUuid: string, candidateUuid: string): Promise<FetchResult<DevelopmentPlan, string>> => {
    try {
        const response = await lloFetch(`/api/developmentplan/${developmentPlanUuid}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanResource;

        const developmentPlan = transformToDevelopmentPlan(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlan,
        };
    } catch (error) {
        console.error('[getDevelopmentPlanApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDevelopmentPlanApiCall = async (requestData: DevelopmentPlanRequest, candidateUuid: string): Promise<FetchResult<DevelopmentPlan, string>> => {
    try {
        const response = await lloFetch('/api/developmentplan', {
            candidateUuid,
            method: 'POST',
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanResource;

        const developmentPlan = transformToDevelopmentPlan(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlan,
        };
    } catch (error) {
        console.error('[postDevelopmentPlanApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchDevelopmentPlanApiCall = async (requestData: DevelopmentPlanRequest, candidateUuid: string): Promise<FetchResult<DevelopmentPlan, string>> => {
    try {
        const response = await lloFetch(`/api/developmentplan/${requestData.id}`, {
            candidateUuid,
            method: 'PATCH',
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanResource;

        const developmentPlan = transformToDevelopmentPlan(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlan,
        };
    } catch (error) {
        console.error('[patchDevelopmentPlanApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDevelopmentPlanApiCall = async (developmentUuid: string, candidateUuid: string): Promise<FetchResult<string, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlan/${developmentUuid}`, {
            candidateUuid,
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: 'ok',
        };
    } catch (error) {
        console.error('[deleteDevelopmentPlanApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDevelopmentPlanWithSkillsApiCall = async (requestData: DevelopmentPlanWithSkillsRequest, candidateUuid: string): Promise<FetchResult<DevelopmentPlan, string>> => {
    try {
        const response = await lloFetch('/api/developmentPlan/withParts', {
            candidateUuid,
            method: 'POST',
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanResource;

        const developmentPlan = transformToDevelopmentPlan(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlan,
        };
    } catch (error) {
        console.error('[postDevelopmentPlanWithSkillsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
