/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconEmotionSatisfied: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M96.2,30.9c-2.5-6.1-6.2-11.6-10.8-16.2C80.7,10,75.2,6.3,69.1,3.8C63.1,1.3,56.6,0,50,0C22.3,0,0,22.5,0,50c0,13.3,5.3,26,14.6,35.4c4.6,4.6,10.2,8.3,16.2,10.8c6.1,2.5,12.6,3.8,19.1,3.8c13.3,0,26-5.3,35.4-14.6C94.7,76,100,63.3,100,50C100,43.4,98.7,36.9,96.2,30.9z M78.3,78.3C70.8,85.8,60.6,90,50,90c-10.6,0-20.8-4.2-28.3-11.7C14.2,70.8,10,60.6,10,50c0-10.6,4.2-20.8,11.7-28.3C29.2,14.2,39.4,10,50,10c10.6,0,20.8,4.2,28.3,11.7C85.8,29.2,90,39.4,90,50C90,60.6,85.8,70.8,78.3,78.3z" />
        <path d="M67.5,45c2,0,3.9-0.8,5.3-2.2c1.4-1.4,2.2-3.3,2.2-5.3s-0.8-3.9-2.2-5.3c-1.4-1.4-3.3-2.2-5.3-2.2s-3.9,0.8-5.3,2.2c-1.4,1.4-2.2,3.3-2.2,5.3s0.8,3.9,2.2,5.3C63.6,44.2,65.5,45,67.5,45z" />
        <path d="M32.5,45c2,0,3.9-0.8,5.3-2.2c1.4-1.4,2.2-3.3,2.2-5.3s-0.8-3.9-2.2-5.3S34.5,30,32.5,30s-3.9,0.8-5.3,2.2c-1.4,1.4-2.2,3.3-2.2,5.3s0.8,3.9,2.2,5.3C28.6,44.2,30.5,45,32.5,45z" />
        <path d="M50,77.5c11.7,0,21.5-7.3,25.5-17.5H24.4C28.5,70.2,38.3,77.5,50,77.5z" />
    </svg>
);

export default IconEmotionSatisfied;
/* eslint-enable max-len */
