import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    DescriptiveRadioList,
    Disclaimer,
    FormActionButtons,
    Notice,
    TextInput,
} from '../../../compositions';
import { tmaAnalysisTypes } from '../../../constants';
import { convertDateStringToYYYYMMDD } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import {
    defaultTmaAnalysisSettings,
    TmaAnalysisSettings,
    TmaAnalysisSettingsFormData,
    TmaAnalysisType,
} from '../../../models/TmaAnalysis/TmaAnalysis';
import { DialogFormProps, MessageState, NoticeType } from '../../../types';
import { TmaAnalysisSettingsFormErrors, validateTmaAnalysisSettingsFormData } from './validations';

import './TmaAnalysisSettingsForm.scss';

interface TmaAnalysisSettingsFormProps extends DialogFormProps<TmaAnalysisSettingsFormData> {
    tmaAnalysisSettings?: TmaAnalysisSettings;
    className?: string;
}

const TmaAnalysisSettingsForm: FC<TmaAnalysisSettingsFormProps> = ({
    isLoading,
    tmaAnalysisSettings = defaultTmaAnalysisSettings(),
    error,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<TmaAnalysisSettingsFormErrors>({});

    const [analysisType, setAnalysisType] = useState<string>(tmaAnalysisSettings.type);
    const [periodAvailableStart, setPeriodAvailableStart] = useState<string>(tmaAnalysisSettings.startDate);
    const [periodAvailableEnd, setPeriodAvailableEnd] = useState<string>(tmaAnalysisSettings.endDate);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            type: analysisType as TmaAnalysisType,
            startDate: convertDateStringToYYYYMMDD(periodAvailableStart),
            endDate: convertDateStringToYYYYMMDD(periodAvailableEnd),
        };

        const [errors, hasErrors] = validateTmaAnalysisSettingsFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`tma-analysis-settings-form ${className}`}>
            {tmaAnalysisSettings.isActive && (
                <Disclaimer
                    title={!tmaAnalysisSettings.isFinished
                        ? trans('forms.tmaAnalysisSettings.disclaimer.isCurrentlyActive.title')
                        : trans('forms.tmaAnalysisSettings.disclaimer.isFinished.title')}
                    messageState={MessageState.warning}
                    description={!tmaAnalysisSettings.isFinished
                        ? trans('forms.tmaAnalysisSettings.disclaimer.isCurrentlyActive.description')
                        : trans('forms.tmaAnalysisSettings.disclaimer.isFinished.description')}
                    className="tma-analysis-settings-form__disclaimer"
                />
            )}

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="tma-analysis-settings-form__error-message"
                />
            )}

            <div className="tma-analysis-settings-form__radio-wrapper">
                <DescriptiveRadioList
                    label={trans('forms.tmaAnalysisSettings.input.analysisType')}
                    required
                    name="tma-type"
                    options={tmaAnalysisTypes}
                    value={analysisType}
                    disabled={tmaAnalysisSettings.isActive}
                    error={formErrors.type}
                    onChange={setAnalysisType}
                />
            </div>

            <div className="tma-analysis-settings-form__period-available-wrapper">
                <TextInput
                    label={trans('forms.tmaAnalysisSettings.input.startDate')}
                    type="date"
                    required
                    value={periodAvailableStart}
                    disabled={tmaAnalysisSettings.isActive}
                    error={formErrors.startDate}
                    onChange={setPeriodAvailableStart}
                    className="tma-analysis-settings-form__input"
                />

                <TextInput
                    label={trans('forms.tmaAnalysisSettings.input.endDate')}
                    type="date"
                    required
                    value={periodAvailableEnd}
                    disabled={tmaAnalysisSettings.isActive}
                    error={formErrors.endDate}
                    onChange={setPeriodAvailableEnd}
                    className="tma-analysis-settings-form__input"
                />
            </div>

            <FormActionButtons
                isLoading={isLoading}
                submitIcon="arrow-right"
                submitText={trans('forms.tmaAnalysisSettings.submitButtonLabel')}
                disabled={tmaAnalysisSettings.isActive}
                onCancelClick={onCancel}
                className="tma-analysis-settings-form__action-buttons"
            />
        </form>
    );
};

export default TmaAnalysisSettingsForm;
