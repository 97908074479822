import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultOccupationDetail, OccupationDetail } from '../../models/OccupationDetail/OccupationDetail';
import { AsyncReduxState } from '../../types';

export type OccupationDetailState = AsyncReduxState<{
    occupation: OccupationDetail;
}>;

const initialState: OccupationDetailState = {
    ...initialAsyncReduxState,
    isLoading: true,
    occupation: defaultOccupationDetail(),
};

export const occupationDetailSlice = createSlice({
    name: 'occupationDetailReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OccupationDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OccupationDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOccupation(state, action: PayloadAction<OccupationDetail>): OccupationDetailState {
            return {
                ...state,
                occupation: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setOccupation,
} = occupationDetailSlice.actions;

export default occupationDetailSlice.reducer;
