/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconSortUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 70" className={`icon ${className}`}>
        <rect width="20" height="10" />
        <rect y="30" width="35" height="10" />
        <rect y="60" width="50" height="10" />
        <polygon points="100,20 80,0 60,20 75,20 75,70 85,70 85,20" />
    </svg>
);

export default IconSortUp;
/* eslint-enable max-len */
