import {
    ChangeEvent,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import './Toggle.scss';

export interface ToggleProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    id: string;
    label: string;
    hideLabel?: boolean;
    checked?: boolean;
    onChange: (isChecked: boolean) => void;
    className?: string;
}

const Toggle: FC<ToggleProps> = ({
    id,
    label,
    hideLabel = false,
    checked = false,
    onChange,
    className = '',
    ...toggleProps
}): ReactElement => {
    const [isActive, setIsActive] = useState<boolean>(checked);

    useEffect((): void => {
        setIsActive(checked);
    }, [checked]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setIsActive(event.currentTarget.checked);
        onChange(event.currentTarget.checked);
    };

    const toggleClassNames = classNames('toggle', {
        'toggle--is-active': isActive,
    }, className);

    return (
        <div className={toggleClassNames}>
            <input
                {...toggleProps}
                id={id}
                aria-label={hideLabel ? label : undefined}
                type="checkbox"
                checked={isActive}
                onChange={handleChange}
                className="toggle__checkbox"
            />
            <label htmlFor={id} className="toggle__label-wrapper">
                <div className="toggle__handle-wrapper">
                    <div className="toggle__handle">
                        <span className="toggle__handle-label" />
                    </div>
                </div>

                {!hideLabel && (
                    <span className="toggle__label">{label}</span>
                )}
            </label>
        </div>
    );
};

export default Toggle;
