import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { Breadcrumbs } from '../../compositions';
import { ConnectedOrganisationDetailHeader, ConnectedOrganisationDetailTabs, ConnectedPage } from '../../connectors';
import { userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { Organisation } from '../../models/Organisation/Organisation';
import { User } from '../../models/User/User';
import { RoutePaths } from '../../Routes';
import { UserRole } from '../../types';

import './OrganisationDetail.scss';

const OrganisationDetail: FC = (): ReactElement => {
    const [organisation, setOrganisation] = useState<Organisation>();
    const [user, setUser] = useState<User | undefined>();

    const breadcrumbs = [
        { label: trans('pages.organisationDetail.breadcrumb'), to: RoutePaths.organisationOverview() },
        { label: organisation?.name || '' },
    ];

    return (
        <ConnectedPage
            hasNavigation
            title={trans('pages.organisationDetail.title')}
            className="organisation-detail-page"
        >
            <Helmet>
                <title>
                    {trans('pages.organisationDetail.title')}
                </title>
            </Helmet>

            {userHasRole(UserRole.lloAdmin, user?.roles) && (
                <Breadcrumbs crumbs={breadcrumbs} />
            )}

            <ConnectedOrganisationDetailHeader
                onOrganisationResponse={setOrganisation}
                onUserResponse={setUser}
                className="organisation-detail-page__detail"
            />

            <ConnectedOrganisationDetailTabs />
        </ConnectedPage>
    );
};

export default OrganisationDetail;
