import trans from '../../helpers/trans';
import { generateFullName } from '../../helpers/user';
import { Candidate } from '../../models/Candidate/Candidate';
import { getCandidateApiCall, getCandidateCounselorApiCall, postCandidateApiCall } from '../../models/Candidate/CandidateService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { defaultVacancyFilterValues } from '../../models/MatchingVacancies';
import { setActiveFilterValues } from '../matchingVacancies/matchingVacancies';
import { ReducerGetter, TypedDispatch } from '../store';
import { updateUser } from '../user/userActions';
import {
    setCandidate,
    setCandidateCounselor,
    setError,
    setIsLoading,
} from './candidate';

export const fetchCandidate = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const candidateResponse = await getCandidateApiCall(candidateUuid);

        if (!isFetchResultSuccessful(candidateResponse)) {
            dispatch(setError(candidateResponse.error));
            return;
        }

        dispatch(setCandidate(candidateResponse.data));
        dispatch(setActiveFilterValues(defaultVacancyFilterValues()));
    } catch (error) {
        console.error('[fetchCandidate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateCandidate = (candidate: Candidate) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { user } = state.userReducer;

    try {
        const candidateResponse = await postCandidateApiCall(candidate);

        if (!isFetchResultSuccessful(candidateResponse)) {
            dispatch(setError(candidateResponse.error));
            return;
        }

        const updatedCandidate = candidateResponse.data;

        if (user) {
            dispatch(updateUser({
                ...user,
                firstName: updatedCandidate.firstName,
                lastNamePrefix: updatedCandidate.lastNamePrefix,
                lastName: updatedCandidate.lastName,
                fullName: generateFullName(updatedCandidate),
            }));
        }

        dispatch(setCandidate(updatedCandidate));
    } catch (error) {
        console.error('[updateCandidate]', error);
        dispatch(setError(trans('errors.unknownError')));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchCandidateCounselor = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const candidateCounselorResponse = await getCandidateCounselorApiCall(candidateUuid);

        if (!isFetchResultSuccessful(candidateCounselorResponse)) {
            dispatch(setError(candidateCounselorResponse.error));
            return;
        }

        dispatch(setCandidateCounselor(candidateCounselorResponse.data));
    } catch (error) {
        console.error('[fetchCandidateCounselor]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
