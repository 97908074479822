import { FC, ReactElement, useRef } from 'react';

import { useToggle } from 'react-use';

import { Card } from '../../../../../components';
import { ExpandableNavigation, IconButton } from '../../../../../compositions';
import { ExpandableNavigationItem } from '../../../../../compositions/ExpandableNavigation/ExpandableNavigation';
import { userHasRole } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { userSkillsPassportActions } from '../../../../../helpers/user';
import useHandleClickOutside from '../../../../../hooks/useHandleClickOutside';
import { User } from '../../../../../models/User/User';
import { SkillsPassportAction, UserRole } from '../../../../../types';

import './SkillsPassportActionsCard.scss';

interface SkillsPassportActionsCardProps {
    isDisabled: boolean;
    user?: User;
    navigationItems: ExpandableNavigationItem<SkillsPassportAction>[];
    onDownloadClick: () => void;
    className?: string;
}

const SkillsPassportActionsCard: FC<SkillsPassportActionsCardProps> = ({
    isDisabled,
    user,
    navigationItems,
    onDownloadClick,
    className = '',
}): ReactElement => {
    const [actionNavigationIsExpanded, toggleActionNavigationIsExpanded] = useToggle(false);

    const closeActionNavigation = (): void => toggleActionNavigationIsExpanded(false);

    const actionNavigationRef = useRef<HTMLDivElement>(null);
    useHandleClickOutside(actionNavigationRef, closeActionNavigation);

    const userRoles = user?.roles;
    const allowedActions = userRoles && userRoles.length > 0
        ? userRoles.map(role => userSkillsPassportActions[role]).flat()
        : [];
    const skillsPassportActions = navigationItems.filter(action => allowedActions.includes(action.key));

    return (
        <Card className={`skills-passport-actions-card ${className}`}>
            <div className="skills-passport-actions-card__text-wrapper">
                <h2 className="skills-passport-actions-card__title">
                    {userHasRole(UserRole.candidate, userRoles)
                        ? trans('containers.skillsPassportActionsCard.title')
                        : trans('containers.skillsPassportHeader.navigationAriaLabel')}
                </h2>
            </div>

            {userHasRole(UserRole.counselor, userRoles) ? (
                <div ref={actionNavigationRef} className="skills-passport-actions-card__action-navigation-wrapper">
                    <IconButton
                        icon="settings"
                        text={trans('containers.skillsPassportHeader.actionNavigationLabel')}
                        disabled={isDisabled}
                        onClick={toggleActionNavigationIsExpanded}
                        className="skills-passport-actions-card__action-navigation-button"
                    />

                    <ExpandableNavigation
                        isActive={actionNavigationIsExpanded}
                        name={trans('containers.skillsPassportHeader.navigationAriaLabel')}
                        navigationItems={skillsPassportActions}
                        onClose={closeActionNavigation}
                        className="skills-passport-actions-card__action-navigation"
                    />
                </div>
            ) : (
                <IconButton
                    text={trans('containers.skillsPassportActionsCard.buttonText')}
                    icon="download"
                    onClick={onDownloadClick}
                    className="skills-passport-actions-card__button"
                />
            )}
        </Card>
    );
};

export default SkillsPassportActionsCard;
