import { FC, ReactElement } from 'react';

import { CandidateExchangeFormData } from '../../../models/CandidateExchange/CandidateExchange';
import { CandidateFilterValues, CandidateOverview } from '../../../models/CandidateOverview/CandidateOverview';
import { CounselorOption } from '../../../models/Counselor/Counselor';
import { Organisation } from '../../../models/Organisation/Organisation';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { User } from '../../../models/User/User';
import { SortDirection, SortField } from '../../../types';
import { OrganisationCandidateTable, Pagination } from '../..';

import './OrganisationDetailCandidates.scss';

interface OrganisationDetailCandidatesProps {
    isLoading: boolean;
    exchangeIsLoading: boolean;
    counselorOptionsIsLoading: boolean;
    organisationOptionsIsLoading: boolean;
    exchangeIsSuccessful: boolean;
    user?: User;
    organisation?: Organisation;
    candidateOverview: CandidateOverview;
    activeFilterValues: CandidateFilterValues;
    pagination?: PaginationModel;
    error: string;
    exchangeError?: string;
    counselorOptionsError?: string;
    organisationOptionsError?: string;
    counselorOptions: CounselorOption[];
    exchangeCounselorOptions: CounselorOption[];
    organisationOptions: Organisation[];
    counselorSearchQuery: string;
    organisationSearchQuery: string;
    onTableSort: (field: SortField, direction?: SortDirection) => void;
    onTableSearch: (query: string) => void;
    onCounselorFilter: (counselorId: string) => void;
    onPaginationChange: (currentPage: number) => void;
    onOpenExchangeDialog: () => void;
    onOpenArchiveDialog: () => void;
    onSearchCounselorOptions: (query: string) => void;
    onSearchOrganisationOptions: (query: string) => void;
    onOrganisationSelect: (organisation: Organisation) => void;
    onSubmitExchangeRequest: (candidateExchangeFormData: CandidateExchangeFormData) => void;
    onSubmitArchiveCandidate: (candidateUuid: string) => void;
    onFinishExchange: () => void;
    className?: string;
}

const OrganisationDetailCandidates: FC<OrganisationDetailCandidatesProps> = ({
    isLoading,
    exchangeIsLoading,
    counselorOptionsIsLoading,
    organisationOptionsIsLoading,
    exchangeIsSuccessful,
    user,
    organisation,
    candidateOverview,
    activeFilterValues,
    pagination,
    error,
    exchangeError = '',
    counselorOptionsError = '',
    organisationOptionsError = '',
    counselorOptions,
    exchangeCounselorOptions,
    organisationOptions,
    counselorSearchQuery,
    organisationSearchQuery,
    onTableSort,
    onTableSearch,
    onCounselorFilter,
    onPaginationChange,
    onOpenExchangeDialog,
    onOpenArchiveDialog,
    onSearchCounselorOptions,
    onSearchOrganisationOptions,
    onOrganisationSelect,
    onSubmitExchangeRequest,
    onSubmitArchiveCandidate,
    onFinishExchange,
    className = '',
}): ReactElement => (
    <section className={`organisation-detail-candidates ${className}`}>
        <OrganisationCandidateTable
            isLoading={isLoading}
            exchangeIsLoading={exchangeIsLoading}
            counselorOptionsIsLoading={counselorOptionsIsLoading}
            organisationOptionsIsLoading={organisationOptionsIsLoading}
            exchangeIsSuccessful={exchangeIsSuccessful}
            user={user}
            organisation={organisation}
            candidateOverview={candidateOverview}
            activeFilterValues={activeFilterValues}
            error={error}
            exchangeError={exchangeError}
            counselorOptionsError={counselorOptionsError}
            organisationOptionsError={organisationOptionsError}
            counselorOptions={counselorOptions}
            exchangeCounselorOptions={exchangeCounselorOptions}
            organisationOptions={organisationOptions}
            counselorSearchQuery={counselorSearchQuery}
            organisationSearchQuery={organisationSearchQuery}
            onSort={onTableSort}
            onSearch={onTableSearch}
            onCounselorFilter={onCounselorFilter}
            onOpenExchangeDialog={onOpenExchangeDialog}
            onOpenArchiveDialog={onOpenArchiveDialog}
            onSearchCounselorOptions={onSearchCounselorOptions}
            onSearchOrganisationOptions={onSearchOrganisationOptions}
            onOrganisationSelect={onOrganisationSelect}
            onSubmitExchangeRequest={onSubmitExchangeRequest}
            onSubmitArchiveCandidate={onSubmitArchiveCandidate}
            onFinishExchange={onFinishExchange}
            className="organisation-detail-candidates__table"
        />

        {(pagination && candidateOverview.candidates.length > 0) && (
            <Pagination
                id="organisation-detail-candidates-pagination"
                amountOfPages={pagination.totalPages}
                currentPage={pagination.currentPage}
                onChange={onPaginationChange}
                className="organisation-detail-candidates__pagination"
            />
        )}
    </section>
);

export default OrganisationDetailCandidates;
