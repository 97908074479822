import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedOccupationTabs, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './SeekOccupation.scss';

const SeekOccupation: FC = (): ReactElement => (
    <ConnectedPage
        hasNavigation
        title={trans('pages.seekOccupation.title')}
        className="seek-occupation-page"
    >
        <Helmet>
            <title>
                {trans('pages.seekOccupation.title')}
            </title>
        </Helmet>

        <ConnectedOccupationTabs />
    </ConnectedPage>
);

export default SeekOccupation;
