import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { LinkIconButton } from '../../compositions';
import { ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';
import { RoutePaths } from '../../Routes';

import './NotFound.scss';

const NotFound: FC = (): ReactElement => (
    <ConnectedPage hasNavigation className="not-found-page">
        <Helmet>
            <title>{trans('pages.notFound.title')}</title>
        </Helmet>

        <h1>{trans('pages.notFound.title')}</h1>

        <p className="not-found-page__paragraph">
            {trans('pages.notFound.paragraph')}
        </p>

        <LinkIconButton
            icon="arrow-left"
            text={trans('common.backToDashboard')}
            to={RoutePaths.dashboard()}
            className="not-found-page__button"
        />
    </ConnectedPage>
);

export default NotFound;
