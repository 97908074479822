import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddOrganisationOccupationRequest,
    EditOrganisationOccupationRequest,
    OrganisationOccupation,
    OrganisationOccupationResource,
} from './OrganisationOccupation';
import { transformToOrganisationOccupation } from './OrganisationOccupationTransformers';

export const getOrganisationOccupationApiCall = async (organisationUuid: string, occupationUuid: string): Promise<FetchResult<OrganisationOccupation, string>> => {
    try {
        const response = await lloFetch(`/api/internalOccupations/${organisationUuid}/occupation/${occupationUuid}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationOccupationResponse: OrganisationOccupationResource = await response.json();

        const organisationOccupation = transformToOrganisationOccupation(organisationOccupationResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisationOccupation,
        };
    } catch (error) {
        console.error('[getOrganisationOccupationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postOrganisationOccupationApiCall = async (organisationOccupationRequest: AddOrganisationOccupationRequest): Promise<FetchResult<FetchResultType, string>> => {
    try {
        const response = await lloFetch(`/api/internalOccupations/${organisationOccupationRequest.organisationUuid}`, {
            method: 'POST',
            body: JSON.stringify(organisationOccupationRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[postOrganisationOccupationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchOrganisationOccupationApiCall = async (organisationOccupationRequest: EditOrganisationOccupationRequest): Promise<FetchResult<OrganisationOccupation, string>> => {
    try {
        const { id, organisationUuid } = organisationOccupationRequest;

        const response = await lloFetch(`/api/internalOccupations/${organisationUuid}/update/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(organisationOccupationRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationOccupationResponse: OrganisationOccupationResource = await response.json();

        const organisationOccupation = transformToOrganisationOccupation(organisationOccupationResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisationOccupation,
        };
    } catch (error) {
        console.error('[patchOrganisationOccupationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteOrganisationOccupationApiCall = async (occupation: OrganisationOccupation, organisationUuid: string): Promise<FetchResult<FetchResultType, string>> => {
    try {
        const response = await lloFetch(`/api/internalOccupations/${organisationUuid}/delete/${occupation.id}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[deleteOrganisationOccupationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
