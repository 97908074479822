import { v4 as generateUuid } from 'uuid';

import { startOfCurrentYear } from '../../helpers/date';

export interface SchoolExperienceResource {
    id: string;
    educationLevel: string;
    educationLevelId: string;
    educationSubLevel?: string;
    educationSubLevelId?: string;
    learningPath?: string;
    learningPathId?: string;
    fieldOfStudy: string;
    fieldOfStudyId?: string;
    school: string;
    schoolId: string;
    startDate: Date;
    endDate: Date;
}

export interface SchoolExperienceRequest {
    id: string;
    educationLevel: string;
    educationLevelId: string;
    educationSubLevel?: string;
    educationSubLevelId?: string;
    learningPath?: string;
    learningPathId?: string;
    fieldOfStudy: string;
    fieldOfStudyId?: string;
    school: string;
    schoolId: string;
    startDate: string;
    endDate: string;
}

export interface SchoolExperience {
    id: string;
    educationLevel: string;
    educationLevelId: string;
    educationSubLevel?: string;
    educationSubLevelId?: string;
    learningPath?: string;
    learningPathId?: string;
    fieldOfStudy: string;
    fieldOfStudyId?: string;
    school: string;
    schoolId: string;
    startDate: Date;
    endDate: Date;
}

export type SchoolExperienceFormData = SchoolExperience;

export const defaultSchoolExperience = (): SchoolExperience => ({
    id: generateUuid(),
    educationLevel: '',
    educationLevelId: '',
    fieldOfStudy: '',
    fieldOfStudyId: '',
    school: '',
    schoolId: '',
    startDate: startOfCurrentYear,
    endDate: startOfCurrentYear,
});
