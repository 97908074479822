import { DevelopmentPlanFormData } from '../../../../models/DevelopmentPlan/DevelopmentPlan';
import {
    FormErrors,
    FormValidation,
    validateDateString,
    validateEndDate,
    validateForm,
    validateRequiredString,
    validateStartDate,
} from '../../../../services/ValidationService';

export type DevelopmentPlanFormErrors = FormErrors<DevelopmentPlanFormData>;

export const validateDevelopmentPlanFormData = (formData: DevelopmentPlanFormData): FormValidation<DevelopmentPlanFormErrors> => {
    const errors: DevelopmentPlanFormErrors = {
        title: validateRequiredString('title', formData.title),
        start: validateDateString('start', formData.start) || validateStartDate(new Date(formData.start), new Date(formData.end)),
        end: validateDateString('start', formData.end) || validateEndDate(new Date(formData.end), new Date(formData.start)),
    };

    return validateForm<DevelopmentPlanFormErrors>(errors);
};
