import { v4 as generateUuid } from 'uuid';

import { generateFullName } from '../../helpers/user';
import { UserRole } from '../../types';
import { UserModuleResource } from '../Modules/Modules';
import { transformToUserModule } from '../Modules/ModulesTransformers';
import { User, UserResource, UserRoleResource } from './User';

export const transformToUserRole = (
    userRoleResource: UserRoleResource,
): UserRole => {
    const userRoles: Record<string, UserRole> = {
        lloAdmin: UserRole.lloAdmin,
        organisationAdmin: UserRole.organisationAdmin,
        tmaExpert: UserRole.tmaExpert,
        counselor: UserRole.counselor,
        candidate: UserRole.candidate,
    };

    return userRoles[userRoleResource.name];
};

export const transformToUser = (userResource: UserResource): User => {
    const roles = userResource.roles.map(transformToUserRole);

    const fullName = generateFullName(userResource);

    // Remove this when the education module can be turned on again
    const temporaryEducationModule: UserModuleResource = {
        id: generateUuid(),
        label: 'education',
        isActive: false,
    };

    const temporaryResourceModules = [
        ...userResource.modules,
        temporaryEducationModule,
    ];
    //

    return ({
        uuid: userResource.id,
        userName: userResource.azureAdLogInName,
        firstName: userResource.firstName,
        lastNamePrefix: userResource.lastNamePrefix,
        lastName: userResource.lastName,
        fullName,
        email: userResource.email,
        roles,
        modules: temporaryResourceModules.map(transformToUserModule),
        organisation: userResource.organisation,
    });
};
