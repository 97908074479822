import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';
import { useToggle } from 'react-use';

import { ConfirmDialog, Dialog, IconButton } from '../../../compositions';
import { ConfirmType } from '../../../compositions/ConfirmDialog/ConfirmDialog';
import { convertDateToReadableDate } from '../../../helpers/date';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { DevelopmentPlanWithEvaluation } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { DevelopmentPlanCompetencyEvaluationFormData } from '../../../models/DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluation';
import { DevelopmentPlanSkillEvaluationFormData } from '../../../models/DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluation';
import { User } from '../../../models/User/User';
import { UserRole } from '../../../types';
import {
    DevelopmentPlanEvaluationBreadcrumbs,
    DevelopmentPlanEvaluationCompetencyCard,
    DevelopmentPlanEvaluationNullState,
    DevelopmentPlanEvaluationSkeletons,
    DevelopmentPlanEvaluationSkillCard,
} from './subcomponents';

import './DevelopmentPlanEvaluation.scss';

interface DevelopmentPlanEvaluationProps {
    isLoading: boolean;
    isSuccessful: boolean;
    user?: User;
    candidate?: Candidate;
    developmentPlan?: DevelopmentPlanWithEvaluation;
    onSubmit: (competencyEvaluations: DevelopmentPlanCompetencyEvaluationFormData[], skillEvaluations: DevelopmentPlanSkillEvaluationFormData[]) => void;
    onDeleteEvaluation: () => void;
    className?: string;
}

const DevelopmentPlanEvaluationContainer: FC<DevelopmentPlanEvaluationProps> = ({
    isLoading,
    isSuccessful,
    candidate,
    user,
    developmentPlan,
    onSubmit,
    onDeleteEvaluation,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [isEditing, toggleIsEditing] = useToggle(false);
    const [changedCompetencyEvaluations, setChangedCompetencyEvaluations] = useState<DevelopmentPlanCompetencyEvaluationFormData[]>([]);
    const [changedSkillEvaluations, setChangedSkillEvaluations] = useState<DevelopmentPlanSkillEvaluationFormData[]>([]);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => closeDialog(dialogRef);

    const handleCompetencyChange = (competencyEvaluationFormData: DevelopmentPlanCompetencyEvaluationFormData): void => {
        const evaluationIndex = changedCompetencyEvaluations.findIndex(evaluation => evaluation.developmentPlanCompetencyId === competencyEvaluationFormData.developmentPlanCompetencyId);

        if (evaluationIndex === -1) {
            setChangedCompetencyEvaluations([
                ...changedCompetencyEvaluations,
                competencyEvaluationFormData,
            ]);
        } else {
            const newEvaluations = [...changedCompetencyEvaluations];
            newEvaluations[evaluationIndex] = competencyEvaluationFormData;

            setChangedCompetencyEvaluations(newEvaluations);
        }
    };

    const handleSkillChange = (skillEvaluationFormData: DevelopmentPlanSkillEvaluationFormData): void => {
        const evaluationIndex = changedSkillEvaluations.findIndex(evaluation => evaluation.developmentPlanSkillId === skillEvaluationFormData.developmentPlanSkillId);

        if (evaluationIndex === -1) {
            setChangedSkillEvaluations([
                ...changedSkillEvaluations,
                skillEvaluationFormData,
            ]);
        } else {
            const newEvaluations = [...changedSkillEvaluations];
            newEvaluations[evaluationIndex] = skillEvaluationFormData;

            setChangedSkillEvaluations(newEvaluations);
        }
    };

    const handleSubmit = () => onSubmit(changedCompetencyEvaluations, changedSkillEvaluations);

    useEffect((): void => {
        if (isSuccessful) toggleIsEditing(false);
    }, [isSuccessful]);

    if (
        isLoading
        || !candidate
        || !user
        || !developmentPlan
    ) {
        return <DevelopmentPlanEvaluationSkeletons className={className} />;
    }

    return (
        <div className={`development-plan-evaluation ${className}`}>
            <Helmet>
                <title>
                    {trans(
                        'connectors.connectedDevelopmentPlanEvaluationHelmet.title',
                        { title: developmentPlan.title },
                    )}
                </title>
            </Helmet>

            <DevelopmentPlanEvaluationBreadcrumbs
                user={user}
                candidate={candidate}
                developmentPlan={developmentPlan}
                className="development-plan-evaluation__breadcrumbs"
            />

            <header className="development-plan-evaluation__header">
                <div className="development-plan-evaluation__title-wrapper">
                    {developmentPlan && (
                        <>
                            <h1 className="development-plan-evaluation__title">
                                {developmentPlan.title}
                            </h1>

                            <p className="development-plan-evaluation__subtitle">
                                {developmentPlan.developmentPlanEvaluation && convertDateToReadableDate(developmentPlan.developmentPlanEvaluation.createdAt)}
                            </p>
                        </>
                    )}
                </div>

                {userHasRole(UserRole.counselor, user.roles) && (
                    <div className="development-plan-evaluation__actions-wrapper">
                        {isEditing && (
                            <>
                                <IconButton
                                    icon="bin"
                                    text={trans('containers.developmentPlanEvaluation.delete')}
                                    onClick={handleOpenDialog}
                                    className="development-plan-evaluation__delete-button"
                                />
                                <IconButton
                                    icon="check"
                                    text={trans('containers.developmentPlanEvaluation.save')}
                                    onClick={handleSubmit}
                                    className="development-plan-evaluation__save-button"
                                />
                            </>
                        )}

                        {!isEditing && (
                            <IconButton
                                icon="edit"
                                text={trans('containers.developmentPlanEvaluation.edit')}
                                onClick={toggleIsEditing}
                                className="development-plan-evaluation__edit-button"
                            />
                        )}
                    </div>
                )}

                <Dialog
                    enableBackdropClose
                    ref={dialogRef}
                    onClose={handleCloseDialog}
                >
                    <ConfirmDialog
                        title={trans('containers.developmentPlanEvaluation.dialog.deleteDevelopmentPlanEvaluation.title')}
                        description={trans('containers.developmentPlanEvaluation.dialog.deleteDevelopmentPlanEvaluation.description', {
                            developmentTitle: developmentPlan?.title,
                        })}
                        submitText={trans('common.delete')}
                        confirmType={ConfirmType.Delete}
                        onCancel={handleCloseDialog}
                        onConfirm={onDeleteEvaluation}
                    />
                </Dialog>
            </header>

            {[...developmentPlan.competencies, ...developmentPlan.skills].length === 0 && (
                <DevelopmentPlanEvaluationNullState
                    userRoles={user.roles}
                    className="development-plan-evaluation__null-state"
                />
            )}

            {developmentPlan.developmentPlanEvaluation && (
                <div className="development-plan-evaluation__list">
                    {developmentPlan.competencies.map(competency => (
                        <DevelopmentPlanEvaluationCompetencyCard
                            key={competency.id}
                            isEditing={isEditing}
                            userRoles={user.roles}
                            competency={competency}
                            evaluation={developmentPlan.developmentPlanEvaluation.competencyEvaluations.find(
                                evaluation => evaluation.developmentPlanCompetencyId === competency.id,
                            )}
                            onChange={handleCompetencyChange}
                            className="development-plan-evaluation__card"
                        />
                    ))}

                    {developmentPlan.skills.map(skill => (
                        <DevelopmentPlanEvaluationSkillCard
                            key={skill.id}
                            isEditing={isEditing}
                            skill={skill}
                            skillEvaluation={developmentPlan.developmentPlanEvaluation.skillEvaluations.find(
                                evaluation => evaluation.developmentPlanSkillId === skill.id,
                            )}
                            onChange={handleSkillChange}
                            className="development-plan-evaluation__card"
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default DevelopmentPlanEvaluationContainer;
