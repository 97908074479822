import {
    AddOrganisationRequest,
    EditOrganisationRequest,
    Organisation,
    OrganisationFormData,
    OrganisationResource,
} from './Organisation';

export const transformToOrganisation = (
    organisationResource: OrganisationResource,
): Organisation => ({
    id: organisationResource.id,
    name: organisationResource.name,
    location: organisationResource.location,
});

export const transformToAddOrganisationRequest = (
    formData: OrganisationFormData,
): AddOrganisationRequest => ({
    name: formData.name,
    location: formData.location,
});

export const transformToEditOrganisationRequest = (
    formData: OrganisationFormData,
): EditOrganisationRequest => ({
    id: formData.id || '',
    name: formData.name,
    location: formData.location,
});
