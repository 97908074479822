import { generateFullName } from '../../helpers/user';
import {
    CandidateOverview,
    CandidateOverviewResource,
    OrganisationCandidate,
    OrganisationCandidateResource,
} from './CandidateOverview';

export const transformToOrganisationCandidate = (
    organisationCandidateResource: OrganisationCandidateResource,
): OrganisationCandidate => ({
    id: organisationCandidateResource.id,
    fullName: generateFullName(organisationCandidateResource),
    counselorFullName: generateFullName(organisationCandidateResource.counselor),
    email: organisationCandidateResource.email,
    createdAt: new Date(organisationCandidateResource.createdAt),
});

export const transformToCandidateOverview = (
    candidateOverviewResource: CandidateOverviewResource,
): CandidateOverview => ({
    totalAmount: candidateOverviewResource.meta.totalAmount,
    candidates: candidateOverviewResource.organisationCandidates.map(transformToOrganisationCandidate),
});
