/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

import '../Logo.scss';

const LogoTalentZ: FC<SvgLogoProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 26.2" className={`logo logo-talentz ${className}`}>
        <path className="logo-talentz__black" d="M4.4,2.7H0V0h12.3v2.7H8v23.4H4.4V2.7z" />
        <path className="logo-talentz__black" d="M21.3,0h-3.5l-5.5,26.2h3.4l1.3-6.6h5.3l1.2,6.6h3.5L21.3,0z M19.6,5.2L21.8,17h-4.3L19.6,5.2z" />
        <path className="logo-talentz__black" d="M29.6,0h3.6v23.5h6.6v2.6H29.6V0z" />
        <path className="logo-talentz__black" d="M42.1,0h10.1v2.7h-6.5v8.7H51v2.6h-5.2v9.6h6.5v2.6H42.1V0z" />
        <path className="logo-talentz__black" d="M55.4,0h2.5l7.4,17.4V0h3v26.2H66L58.5,8.3v17.9h-3.1V0z" />
        <path className="logo-talentz__black" d="M75.3,2.7h-4.4V0h12.3v2.7h-4.3v23.4h-3.6V2.7z" />
        <polygon className="logo-talentz__black" points="98.4,16.2 93.6,19.7 95.4,20.4 94.3,23.5 88.3,23.5 96.4,1.6 96.4,0 85.4,0 85.4,2.6 92.6,2.6 84.9,23.5 84.9,26.2 96.4,26.2 96.4,26.1 96.4,26.2 98.2,21.3 100,22" />
    </svg>
);

export default LogoTalentZ;
/* eslint-enable max-len */
