import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useToggle } from 'react-use';

import { Button } from '../../../../../components';
import {
    ButtonList,
    IconButton,
    SearchableValueInput,
    SkillSelectedOptions,
    SkillsSelector,
    SubmitButton,
} from '../../../../../compositions';
import { getRoleForTranslation, userHasRole } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { EscoOccupation } from '../../../../../models/EscoOccupations/EscoOccupations';
import { transformEscoOccupationToSearchableValue } from '../../../../../models/EscoOccupations/EscoOccupationsTransformers';
import { Skill, SkillsFormData } from '../../../../../models/Skills/Skills';
import { DialogFormProps, SearchableOption, UserRole } from '../../../../../types';

import './MatchingSkillsFilterForm.scss';

interface MatchingSkillsFilterFormProps extends DialogFormProps<SkillsFormData> {
    userRoles: UserRole[];
    activeSkills: Skill[];
    suggestedOccupationSkills: Skill[];
    skillOptions: Skill[];
    occupationOptions: EscoOccupation[];
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onSubmitToSkillsPassport: (skillsFormData: SkillsFormData) => void;
    className?: string;
}

const MatchingSkillsFilterForm: FC<MatchingSkillsFilterFormProps> = ({
    isLoading,
    userRoles,
    activeSkills,
    suggestedOccupationSkills,
    skillOptions,
    error,
    occupationOptions,
    onSelectOccupation,
    onSubmit,
    onSubmitToSkillsPassport,
    onCancel,
    className = '',
}): ReactElement => {
    const [skills, setSkills] = useState<Skill[]>(activeSkills);
    const [occupation, setOccupation] = useState<string>('');
    const [suggestedSkills, setSuggestedSkills] = useState<Skill[]>([]);

    const [showOccupationView, toggleShowOccupationView] = useToggle(false);

    useEffect((): void => {
        if (suggestedOccupationSkills.length > 0) {
            setSuggestedSkills(suggestedOccupationSkills.filter(suggestedSkill => !skills.some(skill => skill.id === suggestedSkill.id)));
        } else {
            setSkills(activeSkills);
        }
    }, [activeSkills, suggestedOccupationSkills]);

    const handleChangeOccupation = (selectedOption: SearchableOption): void => {
        if (!selectedOption.value) {
            setSuggestedSkills([]);
        }

        setOccupation(selectedOption.label);

        const selectedOccupation = selectedOption.value
            ? occupationOptions.find(option => option.id === selectedOption.value)
            : undefined;

        if (selectedOccupation) {
            onSelectOccupation(selectedOccupation);
        }
    };

    const handleSuggestedSkillClick = (selectedSkill: Skill): void => {
        setSkills([...skills, selectedSkill]);
        setSuggestedSkills(suggestedSkills.filter(skill => selectedSkill.id !== skill.id));
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({ skills });
    };

    const handleSubmitToProfile = (): void => {
        onSubmitToSkillsPassport({ skills });
    };

    return (
        <form onSubmit={handleSubmit} className={`matching-skills-filter-form ${className}`}>
            <h1 className="matching-skills-filter-form__title">
                {trans(`containers.vacancyMatchingFilter.skills.dialog.title.${getRoleForTranslation(userRoles)}`)}
            </h1>

            <p className="matching-skills-filter-form__description">
                {trans(`containers.vacancyMatchingFilter.skills.dialog.description.${getRoleForTranslation(userRoles)}`)}
            </p>

            <div className="matching-skills-filter-form__input-wrapper">
                <h2 className="matching-skills-filter-form__input-title">
                    {trans(`containers.vacancyMatchingFilter.skills.dialog.skillsTitle.${getRoleForTranslation(userRoles)}`)}
                </h2>

                <p className="matching-skills-filter-form__input-description">
                    {trans(`containers.vacancyMatchingFilter.skills.dialog.skillsDescription.${getRoleForTranslation(userRoles)}`)}
                </p>

                <SkillsSelector
                    isLoading={isLoading}
                    value={skills}
                    options={skillOptions}
                    error={error}
                    onChange={setSkills}
                    className="matching-skills-filter-form__input"
                />
            </div>

            <div className="matching-skills-filter-form__input-wrapper">
                <IconButton
                    icon={showOccupationView ? 'chevron-up' : 'chevron-down'}
                    iconPos="right"
                    text={trans(`containers.vacancyMatchingFilter.skills.dialog.occupationTitle.${getRoleForTranslation(userRoles)}`)}
                    onClick={toggleShowOccupationView}
                    className="matching-skills-filter-form__collapse-button"
                />

                {showOccupationView && (
                    <>
                        <p className="matching-skills-filter-form__input-description">
                            {trans(`containers.vacancyMatchingFilter.skills.dialog.occupationDescription.${getRoleForTranslation(userRoles)}`)}
                        </p>

                        <SearchableValueInput
                            isSearchable
                            label={trans('containers.vacancyMatchingFilter.skills.dialog.occupationInput')}
                            value={occupation}
                            options={occupationOptions.map(transformEscoOccupationToSearchableValue)}
                            disabled={isLoading}
                            onChange={handleChangeOccupation}
                            className="matching-skills-filter-form__input"
                        />

                        {suggestedSkills.length > 0 && (
                            <SkillSelectedOptions
                                title={trans('containers.vacancyMatchingFilter.skills.dialog.suggestedSkills')}
                                selectedOptions={suggestedSkills}
                                onSelectOption={handleSuggestedSkillClick}
                                className="matching-skills-filter-form__suggested-skills"
                            />
                        )}
                    </>
                )}
            </div>

            <ButtonList className="matching-skills-filter-form__button-wrapper">
                <SubmitButton
                    isLoading={isLoading}
                    icon="search"
                    text={trans('common.search')}
                    disabled={skills.length === 0}
                />

                {userHasRole(UserRole.candidate, userRoles) && (
                    <Button
                        text={trans('containers.vacancyMatchingFilter.skills.dialog.saveToSkillsPassport')}
                        onClick={handleSubmitToProfile}
                        disabled={skills.length < 3}
                    />
                )}

                <Button
                    text={trans('common.cancel')}
                    onClick={onCancel}
                    className="matching-skills-filter-form__cancel-button"
                />
            </ButtonList>
        </form>
    );
};

export default MatchingSkillsFilterForm;
