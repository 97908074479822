import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    AddingCard,
    CallToActionNullState,
    Dialog,
    DialogContent,
    ExpandableTagList,
    Loading,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import { capitalizeFirstCharacter } from '../../../helpers/string';
import trans from '../../../helpers/trans';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { Skill, SkillsFormData } from '../../../models/Skills/Skills';
import { UserRole } from '../../../types';
import { SkillsForm } from '../..';

import './SkillsCard.scss';

interface SkillsCardProps {
    isLoading?: boolean;
    suggestionIsLoading?: boolean;
    skillsIsUpdating: boolean;
    hasError?: boolean;
    userRoles?: UserRole[];
    isEditable: boolean;
    skills: Skill[];
    suggestedSkills: Skill[];
    skillOptions: Skill[];
    skillsError?: string;
    occupationOptions: EscoOccupation[];
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onUpdateSkills: (skillsFormData: SkillsFormData) => void;
    onCloseSkillsDialog: () => void;
    className?: string;
}

const SkillsCard: FC<SkillsCardProps> = ({
    isLoading,
    suggestionIsLoading,
    skillsIsUpdating,
    hasError,
    userRoles = [],
    isEditable,
    skills,
    suggestedSkills,
    skillOptions,
    skillsError,
    occupationOptions,
    onSelectOccupation,
    onUpdateSkills,
    onCloseSkillsDialog,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [skillsFormKey, setSkillsFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);
        onCloseSkillsDialog();

        setSkillsFormKey(skillsFormKey + 1);
    };

    const handleUpdateSkills = (skillsFormData: SkillsFormData): void => {
        onUpdateSkills(skillsFormData);
        handleCloseDialog();
    };

    const skillsThreshold = 10;

    return (
        <AddingCard
            isEditable={isEditable && skills.length > 0}
            showFillLabel={skills.length === 0 && !isLoading}
            title={trans('containers.skillsCard.title')}
            item={trans('containers.skillsCard.skill')}
            description={trans(`containers.skillsCard.description.${getRoleForTranslation(userRoles)}`)}
            userRoles={userRoles}
            onAddClick={handleOpenDialog}
            className={`skills-card ${className}`}
        >
            {isLoading && <Loading className="skills-card__loader" />}

            {!isLoading && skills.length > 0 && (
                <ExpandableTagList
                    listItems={skills.map(skill => capitalizeFirstCharacter(skill.name))}
                    listItemsThreshold={skillsThreshold}
                    buttonLabel={trans('containers.skillsCard.showAllSkills')}
                    expandedButtonLabel={trans('containers.skillsCard.hideAllSkills')}
                    className="skills-card__skills-list"
                />
            )}

            {!isLoading && skills.length === 0 && (
                <CallToActionNullState
                    isEditable={!hasError && userHasRole(UserRole.candidate, userRoles)}
                    icon="skills-passport"
                    paragraph={trans('containers.skillsCard.nullState.paragraph')}
                    subParagraph={trans('containers.skillsCard.nullState.subParagraph')}
                    buttonText={trans('containers.skillsCard.nullState.callToAction')}
                    onButtonClick={handleOpenDialog}
                    className="skills-card__null-state"
                />
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog} className="skills-card__dialog">
                <DialogContent title={trans('containers.skillsCard.title')}>
                    <SkillsForm
                        key={skillsFormKey}
                        isLoading={skillsIsUpdating}
                        suggestionIsLoading={suggestionIsLoading}
                        activeSkills={skills}
                        suggestedOccupationSkills={suggestedSkills}
                        skillOptions={skillOptions}
                        occupationOptions={occupationOptions}
                        error={skillsError}
                        skillsTitle={trans('containers.skillsCard.skillsForm.skillsTitle')}
                        skillsDescription={trans('containers.skillsCard.skillsForm.skillsDescription')}
                        occupationTitle={trans('containers.skillsCard.skillsForm.occupationTitle')}
                        occupationDescription={trans('containers.skillsCard.skillsForm.occupationDescription')}
                        onSelectOccupation={onSelectOccupation}
                        onSubmit={handleUpdateSkills}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </AddingCard>
    );
};

export default SkillsCard;
