import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon, SafeHtml, Tag } from '../../components';
import { IconName } from '../../components/Icon/Icon';
import { MessageState } from '../../types';

import './DialogContent.scss';

interface DialogContentProps {
    title: string;
    titleIcon?: IconName;
    tag?: string;
    description?: string;
    messageState?: MessageState;
    className?: string;
}

const DialogContent: FC<PropsWithChildren<DialogContentProps>> = ({
    title,
    titleIcon,
    tag,
    description = '',
    messageState = MessageState.positive,
    children,
    className = '',
}): ReactElement => {
    const dialogContentClassNames = classNames('dialog-content', {
        [`dialog-content--is-${messageState}`]: messageState,
    }, className);

    return (
        <div className={dialogContentClassNames}>
            <header className="dialog-content__header">
                {titleIcon && (
                    <div className="dialog-content__icon-wrapper">
                        <Icon name={titleIcon} />
                    </div>
                )}

                <div className="dialog-content__text-wrapper">
                    <div className="dialog-content__title-wrapper">
                        <h1 className="dialog-content__title">{title}</h1>

                        {tag && <Tag isActive text={tag} />}
                    </div>

                    {description && <SafeHtml html={description} className="dialog-content__description" />}
                </div>
            </header>

            <div className="dialog-content__content-wrapper">
                {children}
            </div>
        </div>
    );
};

export default DialogContent;
