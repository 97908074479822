import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Card } from '../../../components';
import {
    Dialog,
    DialogContent,
    ExpandableNavigation,
    IconButton,
    Notice,
    TabsNavigation,
} from '../../../compositions';
import ConfirmDialog, { ConfirmType } from '../../../compositions/ConfirmDialog/ConfirmDialog';
import { ExpandableNavigationItem } from '../../../compositions/ExpandableNavigation/ExpandableNavigation';
import {
    ConnectedDevelopmentPlanCompetencyForm,
    ConnectedDevelopmentPlanOverview,
    ConnectedDevelopmentPlanPrivateNotes,
    ConnectedDevelopmentPlanPublicNotes,
    ConnectedDevelopmentPlanSkillForm,
} from '../../../connectors';
import { convertDateToReadableDate } from '../../../helpers/date';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import useHandleClickOutside from '../../../hooks/useHandleClickOutside';
import { DevelopmentPlan, DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { RouteParams, RoutePaths } from '../../../Routes';
import { NoticeType, RouteKey, UserRole } from '../../../types';
import { DevelopmentPlanForm } from '../..';
import { DevelopmentPlanHeaderSkeletons } from './skeletons';

import './DevelopmentPlanHeader.scss';

export enum DevelopmentPlanRouteTab {
    default = ':tab',
    progress = 'ontwikkelpunten',
    publicNotes = 'gedeelde-notities',
    privateNotes = 'persoonlijke-notities',
}

interface DevelopmentPlanParams extends RouteParams {
    candidateUuid: string;
    developmentPlanUuid: string;
    tab?: DevelopmentPlanRouteTab;
}

interface DevelopmentPlanHeaderProps {
    pageIsLoading: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
    isSuccessful: boolean;
    developmentPlan?: DevelopmentPlan;
    developmentPlanError: string;
    userRoles?: UserRole[];
    onDeleteDevelopmentPlan: () => void;
    onSubmitDevelopmentPlan: (formData: DevelopmentPlanFormData) => void;
    className?: string;
}

const DevelopmentPlanHeader: FC<DevelopmentPlanHeaderProps> = ({
    pageIsLoading,
    isDeleting,
    isUpdating,
    isSuccessful,
    developmentPlan,
    developmentPlanError,
    userRoles = [],
    onSubmitDevelopmentPlan,
    onDeleteDevelopmentPlan,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const {
        candidateUuid,
        planUuid: developmentPlanUuid,
        tab = DevelopmentPlanRouteTab.default,
    } = useParams<DevelopmentPlanParams>();

    const addCompetencyDialogRef = useRef<HTMLDialogElement>(null);
    const addSkillDialogRef = useRef<HTMLDialogElement>(null);
    const editDevelopmentDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDevelopmentDialogRef = useRef<HTMLDialogElement>(null);
    const addDevelopmentItemRef = useRef<HTMLDivElement>(null);

    const [connectedDevelopmentPlanCompetencyFormKey, setConnectedDevelopmentPlanCompetencyFormKey] = useState<number>(0);
    const [connectedDevelopmentPlanSkillFormKey, setConnectedDevelopmentPlanSkillFormKey] = useState<number>(0);
    const [developmentPlanFormKey, setDevelopmentPlanFormKey] = useState<number>(0);

    const [navigationIsOpen, toggleNavigationIsOpen] = useToggle(false);

    const handleOpenAddCompetencyDialog = (): void => openDialog(addCompetencyDialogRef);
    const handleCloseAddCompetencyDialog = (): void => {
        closeDialog(addCompetencyDialogRef);

        setConnectedDevelopmentPlanCompetencyFormKey(connectedDevelopmentPlanCompetencyFormKey + 1);
    };

    const handleOpenAddSkillDialog = (): void => openDialog(addSkillDialogRef);
    const handleCloseAddSkillDialog = (): void => {
        closeDialog(addSkillDialogRef);

        setConnectedDevelopmentPlanSkillFormKey(connectedDevelopmentPlanSkillFormKey + 1);
    };

    const handleOpenEditDevelopmentDialog = (): void => openDialog(editDevelopmentDialogRef);
    const handleCloseEditDevelopmentDialog = (): void => {
        closeDialog(editDevelopmentDialogRef);

        setDevelopmentPlanFormKey(developmentPlanFormKey + 1);
    };

    const handleOpenDeleteDevelopmentDialog = (): void => {
        handleCloseEditDevelopmentDialog();
        openDialog(deleteDevelopmentDialogRef);
    };
    const handleCloseDeleteDevelopmentDialog = (): void => closeDialog(deleteDevelopmentDialogRef);

    useHandleClickOutside(addDevelopmentItemRef, () => toggleNavigationIsOpen(false));

    const navigationItems: ExpandableNavigationItem[] = [
        {
            key: 'add-competency',
            label: trans('containers.developmentPlanHeader.addCompetency'),
            onClick: handleOpenAddCompetencyDialog,
        },
        {
            key: 'add-skill',
            label: trans('containers.developmentPlanHeader.addSkill'),
            onClick: handleOpenAddSkillDialog,
        },
    ];

    const tabs = [
        {
            key: RouteKey.developmentPlanProgress,
            to: RoutePaths.developmentPlan(candidateUuid || '', developmentPlanUuid || '', DevelopmentPlanRouteTab.progress),
            label: trans('containers.developmentPlanHeader.tabs.progress'),
        },
        {
            key: RouteKey.developmentPlanPublicNotes,
            to: RoutePaths.developmentPlan(candidateUuid || '', developmentPlanUuid || '', DevelopmentPlanRouteTab.publicNotes),
            label: trans('containers.developmentPlanHeader.tabs.publicNotes'),
        },
        {
            key: RouteKey.developmentPlanPrivateNotes,
            to: RoutePaths.developmentPlan(candidateUuid || '', developmentPlanUuid || '', DevelopmentPlanRouteTab.privateNotes),
            label: trans('containers.developmentPlanHeader.tabs.privateNotes'),
        },
    ];

    useEffect((): void => {
        if (tab === DevelopmentPlanRouteTab.default && candidateUuid && developmentPlanUuid) {
            navigate(RoutePaths.developmentPlan(candidateUuid, developmentPlanUuid, DevelopmentPlanRouteTab.progress), {
                replace: true,
            });
        }
    }, [tab]);

    useEffect((): void => {
        if (isSuccessful) handleCloseEditDevelopmentDialog();
    }, [isSuccessful]);

    if (pageIsLoading || userRoles.length === 0) {
        return (
            <DevelopmentPlanHeaderSkeletons />
        );
    }

    if (developmentPlanError) {
        return (
            <Notice
                type={NoticeType.error}
                text={developmentPlanError}
                className="development-plan-header__error-message"
            />
        );
    }

    return (
        <header className={`development-plan-header ${className}`}>
            <Card className="development-plan-header__card">
                {developmentPlan && (
                    <div className="development-plan-header__title-wrapper">
                        <h1 className="development-plan-header__title">
                            {developmentPlan.title}
                        </h1>

                        <p className="development-plan-header__subtitle">
                            {`${convertDateToReadableDate(developmentPlan.start)} - ${convertDateToReadableDate(developmentPlan.end)}`}
                        </p>
                    </div>
                )}

                <IconButton
                    icon="edit"
                    text={trans('containers.developmentPlanHeader.editInfo')}
                    onClick={handleOpenEditDevelopmentDialog}
                    className="development-plan-header__edit-button"
                />

                <div ref={addDevelopmentItemRef} className="development-plan-header__actions-wrapper">
                    <IconButton
                        icon="plus"
                        text={trans('containers.developmentPlanHeader.addDevelopmentPoint')}
                        onClick={toggleNavigationIsOpen}
                        className="development-plan-header__toggle-button"
                    />
                    <ExpandableNavigation
                        isActive={navigationIsOpen}
                        name={trans('containers.developmentPlanHeader.addDevelopmentPoint')}
                        navigationItems={navigationItems}
                        className="development-plan-header__nav"
                    />
                </div>
            </Card>

            <TabsNavigation
                navigationLabel={trans('containers.developmentPlanHeader.navigationLabel')}
                navigationItems={tabs}
                className="development-plan-header__tabs-navigation"
            />

            <div className="development-plan-header__tab">
                {tab === DevelopmentPlanRouteTab.progress && (
                    <ConnectedDevelopmentPlanOverview />
                )}

                {tab === DevelopmentPlanRouteTab.publicNotes && (
                    <ConnectedDevelopmentPlanPublicNotes />
                )}

                {tab === DevelopmentPlanRouteTab.privateNotes && (
                    <ConnectedDevelopmentPlanPrivateNotes />
                )}
            </div>

            <Dialog ref={addCompetencyDialogRef} onClose={handleCloseAddCompetencyDialog}>
                <DialogContent title={trans('forms.developmentPlanCompetencyForm.title')}>
                    <ConnectedDevelopmentPlanCompetencyForm
                        key={connectedDevelopmentPlanCompetencyFormKey}
                        onCancel={handleCloseAddCompetencyDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog ref={addSkillDialogRef} onClose={handleCloseAddSkillDialog}>
                <DialogContent title={trans('forms.developmentPlanSkillForm.title')}>
                    <ConnectedDevelopmentPlanSkillForm
                        key={connectedDevelopmentPlanSkillFormKey}
                        onCancel={handleCloseAddSkillDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog ref={editDevelopmentDialogRef} onClose={handleCloseEditDevelopmentDialog}>
                <DialogContent title={trans('forms.developmentPlanForm.edit.title')}>
                    <DevelopmentPlanForm
                        key={developmentPlanFormKey}
                        isEditing
                        isLoading={isUpdating}
                        developmentPlan={developmentPlan}
                        error={developmentPlanError}
                        onCancel={handleCloseEditDevelopmentDialog}
                        onDeleteDevelopmentPlan={handleOpenDeleteDevelopmentDialog}
                        onSubmit={onSubmitDevelopmentPlan}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDevelopmentDialogRef}
                onClose={handleCloseDeleteDevelopmentDialog}
            >
                <ConfirmDialog
                    isLoading={isDeleting}
                    title={trans('containers.developmentOverview.dialog.deleteDevelopmentPlan.title')}
                    description={trans('containers.developmentOverview.dialog.deleteDevelopmentPlan.description', {
                        developmentTitle: developmentPlan?.title,
                    })}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onCancel={handleCloseDeleteDevelopmentDialog}
                    onConfirm={onDeleteDevelopmentPlan}
                />
            </Dialog>
        </header>
    );
};

export default DevelopmentPlanHeader;
