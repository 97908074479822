import { FC, ReactElement } from 'react';

import {
    LogoLLO,
    LogoTalentZ,
    LogoTMA,
} from './set';

import './Logo.scss';

export interface SvgLogoProps {
    className?: string;
}

type LogoSet = Record<string, FC<SvgLogoProps>>;

interface LogoProps extends SvgLogoProps {
    name: string;
}

const logos: LogoSet = {
    llo: LogoLLO,
    talentz: LogoTalentZ,
    tma: LogoTMA,
};

const Logo: FC<LogoProps> = ({ name, className = '' }): ReactElement | null => {
    const LogoComponent = logos[name] || null;

    return LogoComponent ? <LogoComponent className={className} /> : null;
};

export default Logo;
