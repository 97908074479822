import { FC, FormEvent, ReactElement } from 'react';

import {
    AmountOfEmployeesFilter,
    ApplyAnonymousFilter,
    DistanceFilter,
    EducationLevelsFilter,
    EmploymentFilter,
    GrossSalaryFilter,
    IconButton,
    JobTypesFilter,
    SectorsFilter,
    SubmitButton,
    WorkRemoteFilter,
} from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { EducationLevel, EducationSubLevel } from '../../../../../models/EducationLevels';
import { VacancyFilterValues } from '../../../../../models/MatchingVacancies';
import { FormOption, MinMaxValue } from '../../../../../types';

import './VacancyFiltersForm.scss';

interface VacancyFiltersFormProps {
    isLoading: boolean;
    hasLocation: boolean;
    filterValues: VacancyFilterValues;
    sectorOptions: FormOption[];
    educationLevelOptions: EducationLevel[];
    educationSubLevelOptions: EducationSubLevel[];
    workRemoteOptions: FormOption[];
    jobTypeOptions: FormOption[];
    amountOfEmployeesOptions: FormOption[];
    onChange: (values: Partial<VacancyFilterValues>) => void;
    onReset: () => void;
    onSubmit: () => void;
    onClose: () => void;
    className?: string;
}

const VacancyFiltersForm: FC<VacancyFiltersFormProps> = ({
    isLoading,
    hasLocation,
    filterValues,
    sectorOptions,
    educationLevelOptions,
    educationSubLevelOptions,
    workRemoteOptions,
    jobTypeOptions,
    amountOfEmployeesOptions,
    onChange,
    onReset,
    onSubmit,
    onClose,
    className = '',
}): ReactElement => {
    const handleDistanceChange = (value: number): void => {
        onChange({ distance: value });
    };

    const handleApplyAnonymousChange = (value: boolean): void => {
        onChange({ applyAnonymous: value });
    };

    const handleSectorsChange = (value: string[]): void => {
        onChange({ sectors: value });
    };

    const handleEducationLevelsChange = (value: string[]): void => {
        onChange({ educationLevels: value });
    };

    const handleWorkRemotePreferenceChange = (value: string[]): void => {
        onChange({ workRemotePreference: value });
    };

    const handleJobTypesChange = (value: string[]): void => {
        onChange({ jobTypes: value });
    };

    const handleEmploymentHourRangeChange = (value: MinMaxValue): void => {
        onChange({ employmentHourRange: value });
    };

    const handleAmountOfEmployeesChange = (value: string[]): void => {
        onChange({ amountOfEmployees: value });
    };

    const handleSalaryChange = (value: number): void => {
        onChange({ salary: value });
    };

    const handleResetClick = (): void => {
        onClose();
        onReset();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onClose();
        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className={`vacancy-filters-form ${className}`}>
            <div className="vacancy-filters-form__property-wrapper">
                {hasLocation && (
                    <DistanceFilter
                        label={trans('containers.vacancyFilters.filters.distance')}
                        value={filterValues.distance}
                        onChange={handleDistanceChange}
                        className="vacancy-filters-form__property"
                    />
                )}

                <ApplyAnonymousFilter
                    label={trans('containers.vacancyFilters.filters.applyType')}
                    checkboxLabel={trans('containers.vacancyFilters.filters.applyAnonymous')}
                    value={filterValues.applyAnonymous}
                    onChange={handleApplyAnonymousChange}
                    className="vacancy-filters-form__property"
                />

                <SectorsFilter
                    label={trans('containers.vacancyFilters.filters.sector')}
                    value={filterValues.sectors}
                    sectors={sectorOptions}
                    onChange={handleSectorsChange}
                    className="vacancy-filters-form__property"
                />

                <EducationLevelsFilter
                    label={trans('containers.vacancyFilters.filters.educationLevel')}
                    name="education-levels"
                    value={filterValues.educationLevels}
                    educationLevels={educationLevelOptions}
                    educationSubLevels={educationSubLevelOptions}
                    onChange={handleEducationLevelsChange}
                    className="vacancy-filters-form__property"
                />

                <WorkRemoteFilter
                    label={trans('containers.vacancyFilters.filters.workRemote')}
                    value={filterValues.workRemotePreference}
                    workRemoteOptions={workRemoteOptions}
                    onChange={handleWorkRemotePreferenceChange}
                    className="vacancy-filters-form__property"
                />

                <JobTypesFilter
                    label={trans('containers.vacancyFilters.filters.jobType')}
                    value={filterValues.jobTypes}
                    options={jobTypeOptions}
                    onChange={handleJobTypesChange}
                    className="vacancy-filters-form__property"
                />

                <EmploymentFilter
                    label={trans('containers.vacancyFilters.filters.employment')}
                    value={filterValues.employmentHourRange}
                    onChange={handleEmploymentHourRangeChange}
                    className="vacancy-filters-form__property"
                />

                <AmountOfEmployeesFilter
                    label={trans('containers.vacancyFilters.filters.amountOfEmployees')}
                    value={filterValues.amountOfEmployees}
                    options={amountOfEmployeesOptions}
                    onChange={handleAmountOfEmployeesChange}
                    className="vacancy-filters-form__property"
                />

                <GrossSalaryFilter
                    label={trans('containers.vacancyFilters.filters.grossSalary')}
                    value={filterValues.salary}
                    onChange={handleSalaryChange}
                    className="vacancy-filters-form__property"
                />
            </div>

            <footer className="vacancy-filters-form__footer">
                <SubmitButton
                    isLoading={isLoading}
                    icon="search"
                    text={trans('common.search')}
                />
                <IconButton
                    icon="refresh"
                    text={trans('common.reset')}
                    disabled={isLoading}
                    onClick={handleResetClick}
                    className="vacancy-filters-form__reset-button"
                />
            </footer>
        </form>
    );
};

export default VacancyFiltersForm;
