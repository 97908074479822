import { maxEmploymentYears } from '../../../../constants';
import { generateIdArray } from '../../../../helpers/array';
import { formatDate } from '../../../../helpers/date';
import { capitalizeFirstCharacter } from '../../../../helpers/string';
import { FormOption } from '../../../../types';

const monthsInYear = 12;

export const defaultMinYear = new Date().getFullYear() - maxEmploymentYears;
export const defaultMaxYear = new Date().getFullYear() + maxEmploymentYears;

export const monthOptions = generateIdArray(monthsInYear).map(monthOption => {
    const monthDate = new Date(0, monthOption, 1);
    const monthLabel = formatDate(monthDate, 'MMMM');

    return {
        label: capitalizeFirstCharacter(monthLabel),
        value: String(monthOption),
    };
});

export const generateYearOptions = (minYear: number, maxYear: number): FormOption[] => {
    const amountOfYears = maxYear - minYear > 0 ? maxYear - minYear : 0;

    return generateIdArray(amountOfYears + 1).map(yearOption => ({
        label: String(minYear + yearOption),
        value: String(minYear + yearOption),
    }));
};

