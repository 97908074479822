import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '..';
import { IconName } from '../Icon/Icon';

import './Tag.scss';

interface TagProps {
    isActive?: boolean;
    isWarning?: boolean;
    isSmall?: boolean;
    icon?: IconName;
    text: string;
    className?: string;
}

const Tag: FC<TagProps> = ({
    isActive,
    isWarning,
    isSmall,
    icon,
    text,
    className = '',
}): ReactElement => {
    const tagClassNames = classNames('tag', {
        'tag--is-active': isActive,
        'tag--is-warning': isWarning,
        'tag--is-small': isSmall,
    }, className);

    return (
        <span className={tagClassNames}>
            {icon && (
                <Icon name={icon} className="tag__icon" />
            )}

            {text}
        </span>
    );
};

export default Tag;
