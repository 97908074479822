import { FC, ReactElement } from 'react';

import { LoadingSpinner } from '../../components';
import trans from '../../helpers/trans';

import './Loading.scss';

interface LoadingProps {
    text?: string;
    className?: string;
}

const Loading: FC<LoadingProps> = ({ text = trans('common.loading'), className = '' }): ReactElement => (
    <section className={`loading ${className}`}>
        <LoadingSpinner className="loading__spinner" />
        <span>{text}</span>
    </section>
);

export default Loading;
