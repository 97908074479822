import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { v4 as generateUuid } from 'uuid';

import { IconButton, SkillsPassportCard } from '../../../compositions';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { CommunicationStyles } from '../../../models/SkillsPassport/SkillsPassport';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

import './CommunicationStylesCard.scss';

interface CommunicationStylesCardProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    candidateUuid?: string;
    userRoles?: UserRole[];
    communicationStyles: CommunicationStyles;
    handleRefresh: () => void;
    className?: string;
}

const CommunicationStylesCard: FC<CommunicationStylesCardProps> = ({
    isLoading,
    isUpToDate,
    candidateUuid,
    userRoles = [],
    communicationStyles,
    handleRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const [effectiveIsExpanded, toggleEffectiveIsExpanded] = useToggle(false);
    const [ineffectiveIsExpanded, toggleInefectiveIsExpanded] = useToggle(false);

    const hasCommunicationStyles = communicationStyles.effective.length + communicationStyles.ineffective.length > 0;

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidateUuid));
    };

    const effectiveCollapseButtonLabel = effectiveIsExpanded
        ? trans('containers.communicationStylesCard.hideAllEffective')
        : trans('containers.communicationStylesCard.showAllEffective');

    const ineffectiveCollapseButtonLabel = ineffectiveIsExpanded
        ? trans('containers.communicationStylesCard.hideAllIneffective')
        : trans('containers.communicationStylesCard.showAllIneffective');

    const effectiveStylesWrapperClassNames = classNames('communication-styles-card__description', {
        'communication-styles-card__description--is-expanded': effectiveIsExpanded,
    });

    const ineffectiveStylesWrapperClassNames = classNames('communication-styles-card__description', {
        'communication-styles-card__description--is-expanded': ineffectiveIsExpanded,
    });

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={!hasCommunicationStyles}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            title={trans('containers.communicationStylesCard.title')}
            description={userRoles.length > 0 ? trans(`containers.communicationStylesCard.description.${getRoleForTranslation(userRoles)}`) : ''}
            nullStateText={trans('containers.communicationStylesCard.nullState')}
            callToActionButtonLabel={trans('containers.communicationStylesCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={handleRefresh}
            className={`communication-styles-card ${className}`}
        >
            {(!isLoading && hasCommunicationStyles) && (
                <>
                    <h3 className="communication-styles-card__effective-title">
                        {trans('containers.communicationStylesCard.effectiveTitle')}
                    </h3>
                    <div className={effectiveStylesWrapperClassNames}>
                        {communicationStyles.effective.map(description => {
                            const keyUuid = generateUuid();

                            return (
                                <p key={keyUuid} className="communication-styles-card__paragraph">
                                    {description}
                                </p>
                            );
                        })}
                    </div>

                    {communicationStyles.effective.length > 3 && (
                        <IconButton
                            icon={effectiveIsExpanded ? 'chevron-up' : 'chevron-down'}
                            iconPos="right"
                            text={effectiveCollapseButtonLabel}
                            onClick={toggleEffectiveIsExpanded}
                            className="communication-styles-card__collapse-button"
                        />
                    )}

                    <h3 className="communication-styles-card__ineffective-title">
                        {trans('containers.communicationStylesCard.ineffectiveTitle')}
                    </h3>
                    <div className={ineffectiveStylesWrapperClassNames}>
                        {communicationStyles.ineffective.map(description => {
                            const keyUuid = generateUuid();

                            return (
                                <p key={keyUuid} className="communication-styles-card__paragraph">
                                    {description}
                                </p>
                            );
                        })}
                    </div>

                    {communicationStyles.ineffective.length > 3 && (
                        <IconButton
                            icon={ineffectiveIsExpanded ? 'chevron-up' : 'chevron-down'}
                            iconPos="right"
                            text={ineffectiveCollapseButtonLabel}
                            onClick={toggleInefectiveIsExpanded}
                            className="communication-styles-card__collapse-button"
                        />
                    )}
                </>
            )}
        </SkillsPassportCard>
    );
};

export default CommunicationStylesCard;
