import { Resource } from '../../japi/types/Resource';

export interface AddressResource extends Resource {
    id: string;
    type: 'pro6pp';
    attributes: {
        city: string;
        latitude: number;
        longitude: number;
        municipality: string;
        postalCode: string;
        province: string;
        street: string;
    };
}

export interface Address {
    city: string;
    street: string;
    postalCode: string;
    houseNumber?: string;
    houseNumberAddition?: string;
    latitude: string;
    longitude: string;
}

export const defaultAddress = (): Address => ({
    street: '',
    city: '',
    postalCode: '',
    houseNumber: '',
    houseNumberAddition: '',
    latitude: '',
    longitude: '',
});
