import { FC, PropsWithChildren, ReactElement } from 'react';

import { AzureAD } from 'react-aad-msal';
import { useEffectOnce } from 'react-use';

import { AzureLoginData } from '../../models/Azure';
import { setAuthenticationToken } from '../../redux/authentication/authenticationActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { setTalentzAuthToken } from '../../redux/talentzAuth/talentzAuthActions';
import { fetchUser } from '../../redux/user/userActions';
import { AzureAuthProvider } from '../../services/AzureAuthService';

const ConnectedAzureLoginChild: FC<PropsWithChildren<{ azureLoginData: AzureLoginData }>> = ({
    azureLoginData,
    children,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const user = useTypedSelector(state => state.userReducer.user);

    useEffectOnce((): void => {
        if (azureLoginData && user === undefined) {
            dispatch(fetchUser(azureLoginData));

            dispatch(setTalentzAuthToken());
            dispatch(setAuthenticationToken());
        }
    });

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

const ConnectedAzureLogin: FC<PropsWithChildren> = ({ children }): ReactElement => (
    // Remove this when react-aad-msal has updated their typing
    // @ts-ignore
    <AzureAD provider={AzureAuthProvider}>
        {(azureLoginData: AzureLoginData): ReactElement => (
            <ConnectedAzureLoginChild azureLoginData={azureLoginData}>
                {children}
            </ConnectedAzureLoginChild>
        )}
    </AzureAD>
);

export default ConnectedAzureLogin;
