import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Logo } from '../../components';
import { LogoutIllustration } from '../../compositions';
import { ConnectedLogoutMessage, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './Logout.scss';

const Logout: FC = (): ReactElement => (
    <ConnectedPage className="logout-page">
        <Helmet>
            <title>{trans('pages.logout.title')}</title>
        </Helmet>

        <div className="logout-page__wrapper">
            <Logo name="llo" className="logout-page__logo" />

            <LogoutIllustration className="logout-page__illustration" />

            <ConnectedLogoutMessage className="logout-page__message" />
        </div>
    </ConnectedPage>
);

export default Logout;
