import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './DevelopmentOverviewSkeletons.scss';

interface DevelopmentOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const DevelopmentOverviewSkeletons: FC<DevelopmentOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const propertySkeletons = generateIdArray(amount);

    return (
        <section className={`development-overview-skeletons ${className}`}>
            <ul className="development-overview-skeletons__item-list">
                {propertySkeletons.map(property => (
                    <li key={property} className="development-overview-skeletons__item">
                        <Skeleton className="development-overview-skeletons__item-title" />

                        <Skeleton className="development-overview-skeletons__item-content" />
                        <Skeleton className="development-overview-skeletons__item-content" />
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default DevelopmentOverviewSkeletons;
