/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconCross: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect x="8.7" y="43.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.5394 49.8625)" width="82.5" height="13.2" />
        <rect x="44" y="8.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.3323 50.3623)" width="13.2" height="82.5" />
    </svg>
);

export default IconCross;
/* eslint-enable max-len */
