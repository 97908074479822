import { isValidDateString } from '../../helpers/date';
import { generateFullName, splitAliasIntoNameObject } from '../../helpers/user';
import {
    DevelopmentOverview,
    DevelopmentOverviewEvaluation,
    DevelopmentOverviewEvaluationResource,
    DevelopmentOverviewResource,
} from './DevelopmentOverview';

export const transformToDevelopmentOverviewEvaluation = (resource: DevelopmentOverviewEvaluationResource): DevelopmentOverviewEvaluation => {
    const createdAt = isValidDateString(resource.createdAt)
        ? new Date(resource.createdAt)
        : new Date();

    const updatedAt = isValidDateString(resource.updatedAt)
        ? new Date(resource.updatedAt)
        : new Date();

    return {
        id: resource.id,
        isCompleted: resource.completed,
        createdByName: generateFullName(splitAliasIntoNameObject(resource.createdByName)),
        createdAt,
        updatedAt,
    };
};

export const transformToDevelopmentOverview = (
    resource: DevelopmentOverviewResource,
): DevelopmentOverview => {
    const start = isValidDateString(resource.start)
        ? new Date(resource.start)
        : new Date();

    const end = resource.end && isValidDateString(resource.end)
        ? new Date(resource.end)
        : undefined;

    const createdAt = isValidDateString(resource.createdAt)
        ? new Date(resource.createdAt)
        : new Date();

    const updatedAt = isValidDateString(resource.updatedAt)
        ? new Date(resource.updatedAt)
        : new Date();

    return {
        id: resource.id,
        title: resource.title,
        start,
        end,
        isCompleted: resource.status,
        createdByName: generateFullName(splitAliasIntoNameObject(resource.createdByName)),
        createdAt,
        updatedAt,
        ...(resource.developmentPlanEvaluation && {
            developmentPlanEvaluation: transformToDevelopmentOverviewEvaluation(resource.developmentPlanEvaluation),
        }),
    };
};
