import { lloApiUrl } from '../constants';
import { AzureAuthProvider } from '../services/AzureAuthService';

interface RequestInitExtraOptions extends RequestInit {
    candidateUuid?: string;
    disableContentType?: boolean;
}

export const lloFetch = async (endpoint: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithToken: RequestInit = {
        ...options,
        headers: {
            Accept: 'application/json',
            ...(options?.candidateUuid && { candidateId: options?.candidateUuid }),
            ...options?.headers,
        },
    };

    if (!options?.disableContentType) {
        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            'Content-Type': 'application/json',
        };
    }

    const azureToken = await AzureAuthProvider.getIdToken();

    if (azureToken) {
        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            Authorization: `Bearer ${azureToken.idToken.rawIdToken}`,
        };
    }

    return fetch(lloApiUrl + endpoint, optionsWithToken);
};
