import { FC, ReactElement, useState } from 'react';

import { Card } from '../../../components';
import { ViewSelector } from '../../../compositions';
import { ComparedOccupationSkills } from '../../../connectors/@occupation/ConnectedOccupationDetailHeader/helpers';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { OccupationDetail } from '../../../models/OccupationDetail/OccupationDetail';
import { UserRole, ViewOption } from '../../../types';
import { OccupationDetailSkillsSkeletons } from './skeletons';
import { OccupationDetailSkillsList } from './subcomponents';

import './OccupationDetailSkills.scss';

enum View {
    matched = 'matched',
    default = 'default',
}

interface OccupationDetailSkillsProps {
    isLoading: boolean;
    error: string;
    userRoles?: UserRole[];
    occupation: OccupationDetail;
    comparedOccupationSkills: ComparedOccupationSkills;
    className?: string;
}

const OccupationDetailSkills: FC<OccupationDetailSkillsProps> = ({
    isLoading,
    error,
    userRoles = [],
    occupation,
    comparedOccupationSkills,
    className = '',
}): ReactElement => {
    const [view, setView] = useState<string>(View.matched);

    const viewOptions: ViewOption[] = [
        {
            icon: 'visibility-on',
            label: trans('containers.occupationDetail.view.matched'),
            value: View.matched,
        },
        {
            icon: 'visibility-off',
            label: trans('containers.occupationDetail.view.default'),
            value: View.default,
        },
    ];

    const hasMatchedSkills = comparedOccupationSkills.matchedEssential.length > 0 || comparedOccupationSkills.matchedOptional.length > 0;

    return (
        <div className={`occupation-detail-skills ${className}`}>
            {isLoading && <OccupationDetailSkillsSkeletons />}

            {!isLoading && !error && (
                <>
                    <header className="occupation-detail-skills__header">
                        <h2 className="occupation-detail-skills__title">
                            {trans('containers.occupationDetail.skills')}
                        </h2>

                        {userHasRole(UserRole.counselor, userRoles) && hasMatchedSkills && (
                            <ViewSelector
                                options={viewOptions}
                                value={view}
                                onChange={setView}
                                className="occupation-detail-skills__view-selector"
                            />
                        )}
                    </header>

                    {view === View.matched && (
                        <Card className="occupation-detail-skills__card">
                            {comparedOccupationSkills.matchedEssential.length > 0 && (
                                <OccupationDetailSkillsList
                                    isMatched
                                    title={trans(`containers.occupationDetail.essentialMatched.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedOccupationSkills.matchedEssential}
                                    className="occupation-detail-skills__list"
                                />
                            )}

                            {comparedOccupationSkills.notMatchedEssential.length > 0 && (
                                <OccupationDetailSkillsList
                                    title={trans(`containers.occupationDetail.essentialNotMatched.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedOccupationSkills.notMatchedEssential}
                                    className="occupation-detail-skills__list"
                                />
                            )}

                            {comparedOccupationSkills.matchedOptional.length > 0 && (
                                <OccupationDetailSkillsList
                                    isMatched
                                    title={trans(`containers.occupationDetail.optionalMatched.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedOccupationSkills.matchedOptional}
                                    className="occupation-detail-skills__list"
                                />
                            )}

                            {comparedOccupationSkills.notMatchedOptional.length > 0 && (
                                <OccupationDetailSkillsList
                                    title={trans(`containers.occupationDetail.optionalNotMatched.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedOccupationSkills.notMatchedOptional}
                                    className="occupation-detail-skills__list"
                                />
                            )}
                        </Card>
                    )}

                    {view === View.default && (
                        <Card className="occupation-detail-skills__card">
                            <OccupationDetailSkillsList
                                title={trans('containers.occupationDetail.essential')}
                                skills={occupation.essentialSkills}
                                className="occupation-detail-skills__list"
                            />

                            <OccupationDetailSkillsList
                                title={trans('containers.occupationDetail.optional')}
                                skills={occupation.optionalSkills}
                                className="occupation-detail-skills__list"
                            />
                        </Card>
                    )}
                </>
            )}
        </div>
    );
};

export default OccupationDetailSkills;
