import { FC, ReactElement } from 'react';

import { Locale } from '../../helpers/trans';
import {
    FlagNL,
} from './set';

import './Flag.scss';

export interface SvgFlagProps {
    className?: string;
}

type FlagSet = Record<Locale, FC<SvgFlagProps>>;

interface FlagProps extends SvgFlagProps {
    country: Locale;
}

const flags: FlagSet = {
    [Locale.nl]: FlagNL,
};

const Flag: FC<FlagProps> = ({ country, className = '' }): ReactElement | null => {
    const FlagComponent = flags[country] || null;

    return FlagComponent ? <FlagComponent className={className} /> : null;
};

export default Flag;
