import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { defaultEducationFilterValues, EducationFilterValues, EducationResource } from './Educations';
import { transformFilterValuesToFilterParams } from './EducationsTransformer';

export const getEducationsApiCall = async (candidateUuid: string, filters: EducationFilterValues): Promise<FetchResult<EducationResource, string>> => {
    const filterOptions = transformFilterValuesToFilterParams({
        ...defaultEducationFilterValues(),
        ...filters,
    });

    try {
        const response = await lloFetch('/api/externalEducation/search', {
            candidateUuid,
            method: 'POST',
            body: JSON.stringify(filterOptions),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data: EducationResource = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data,
        };
    } catch (error) {
        console.error('[getEducationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
