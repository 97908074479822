import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { CandidateRelation } from '../../models/CandidateRelations/CandidateRelations';
import { AsyncReduxState, FormOption } from '../../types';

export type CandidateRelationsState =AsyncReduxState<{
    isRetrieved: boolean;
    list: CandidateRelation[];
    formOptions: FormOption[];
    totalPages: number;
}>;

const initialState: CandidateRelationsState = {
    ...initialAsyncReduxState,
    isRetrieved: false,
    list: [],
    formOptions: [],
    totalPages: 1,
};

export const candidateRelationsSlice = createSlice({
    name: 'candidateRelationsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CandidateRelationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CandidateRelationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsRetrieved(state, action: PayloadAction<boolean>): CandidateRelationsState {
            return {
                ...state,
                isRetrieved: action.payload,
            };
        },
        setList(state, action: PayloadAction<CandidateRelation[]>): CandidateRelationsState {
            return {
                ...state,
                list: action.payload,
            };
        },
        setFormOptions(state, action: PayloadAction<FormOption[]>): CandidateRelationsState {
            return {
                ...state,
                formOptions: action.payload,
            };
        },
        setTotalPages(state, action: PayloadAction<number>): CandidateRelationsState {
            return {
                ...state,
                totalPages: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setIsRetrieved,
    setList,
    setFormOptions,
    setTotalPages,
} = candidateRelationsSlice.actions;

export default candidateRelationsSlice.reducer;
