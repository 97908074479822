import { isFetchResultSuccessful } from '../../models/FetchResult';
import { InternalOccupationFilterValues } from '../../models/InternalOccupationOverview/InternalOccupationOverview';
import { getInternalOccupationOverviewApiCall } from '../../models/InternalOccupationOverview/InternalOccupationOverviewService';
import { TypedDispatch } from '../store';
import { setError, setInternalOccupationOverview, setIsLoading } from './internalOccupationOverview';

export const setInternalOccupationOverviewIsLoading = (isLoading: boolean) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(isLoading));
};

export const fetchInternalOccupationOverview = (
    candidateUuid: string,
    activeFilterValues: InternalOccupationFilterValues,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const occupationOverviewResponse = await getInternalOccupationOverviewApiCall(candidateUuid, activeFilterValues);

        if (!isFetchResultSuccessful(occupationOverviewResponse)) {
            dispatch(setError(occupationOverviewResponse.error));
            return;
        }

        const occupationOverview = occupationOverviewResponse.data;

        dispatch(setInternalOccupationOverview(occupationOverview));
    } catch (error) {
        console.error('[fetchInternalOccupationOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
