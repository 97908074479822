import { FC, PropsWithChildren, ReactElement } from 'react';

import { Card, Tag } from '../../../components';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { UserRole } from '../../../types';
import { IconButton } from '../..';

import './AddingCard.scss';

interface AddingCardProps {
    isEditable?: boolean;
    showFillLabel?: boolean;
    title: string;
    description?: string;
    item?: string;
    userRoles?: UserRole[];
    onAddClick?: () => void;
    className?: string;
}

const AddingCard: FC<PropsWithChildren<AddingCardProps>> = ({
    isEditable,
    showFillLabel,
    title,
    description,
    item,
    userRoles = [],
    onAddClick,
    className = '',
    children,
}): ReactElement => {
    const getAddLabel = item
        ? trans('common.addItem', { item: item.toLowerCase() })
        : trans('common.add');

    return (
        <Card className={`adding-card ${className}`}>
            <header className="adding-card__header">
                <div className="adding-card__title-wrapper">
                    <h1 className="adding-card__title">{title}</h1>
                    {showFillLabel && userHasRole(UserRole.candidate, userRoles) && (
                        <Tag
                            isSmall
                            isWarning
                            text={trans('compositions.addingCard.fillLabel')}
                            className="adding-card__fill-label"
                        />
                    )}
                </div>

                {isEditable && (
                    <IconButton
                        icon="plus"
                        text={getAddLabel}
                        onClick={onAddClick}
                        className="adding-card__add-button"
                    />
                )}
            </header>

            {description && <p className="adding-card__description">{description}</p>}

            {children}
        </Card>
    );
};

export default AddingCard;
