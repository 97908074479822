import { FC, ReactElement, useState } from 'react';

import { Skeleton } from '../../../components';
import { EducationCard, ViewSelector } from '../../../compositions';
import trans from '../../../helpers/trans';
import { Education } from '../../../models/Educations/Educations';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { ViewOption } from '../../../types';
import { Pagination } from '../..';
import { EducationOverviewSkeletons } from './skeletons';
import { EducationOverviewNullState } from './subcomponents';

import './EducationOverview.scss';

enum View {
    extensive = 'extensive',
    compact = 'compact',
}

interface EducationOverviewProps {
    isLoading: boolean;
    educations: Education[];
    pagination?: PaginationModel;
    onPaginationChange: (currentPage: number) => void;
    onResetClick: () => void;
    className?: string;
}

const EducationOverview: FC<EducationOverviewProps> = ({
    isLoading,
    educations,
    pagination,
    onPaginationChange,
    onResetClick,
    className = '',
}): ReactElement => {
    const [view, setView] = useState<string>(View.extensive);

    const viewOptions: ViewOption[] = [
        {
            icon: 'view-extensive',
            label: trans('containers.educationOverview.view.extensive'),
            value: View.extensive,
        },
        {
            icon: 'view-compact',
            label: trans('containers.educationOverview.view.compact'),
            value: View.compact,
        },
    ];

    return (
        <div className={`education-overview ${className}`}>
            {!isLoading && pagination ? (
                <header className="education-overview__header">
                    <h1 className="education-overview__title">
                        {trans('containers.educationOverview.totalResults', {
                            amount: pagination.total || 0,
                        })}
                    </h1>

                    <ViewSelector
                        options={viewOptions}
                        value={view}
                        onChange={setView}
                        className="education-overview__view-selector"
                    />
                </header>
            ) : (
                <Skeleton className="education-overview__skeleton-title" />
            )}

            {isLoading && (
                <EducationOverviewSkeletons
                    amount={6}
                    className="education-overview__skeleton-overview"
                />
            )}

            {!isLoading && educations.length === 0 && (
                <EducationOverviewNullState
                    onResetClick={onResetClick}
                    className="education-overview__null-state"
                />
            )}

            {!isLoading && educations.length > 0 && (
                <ul className="education-overview__list">
                    {educations.map(education => (
                        <li key={education.uuid} className="education-overview__item">
                            <EducationCard isCompactView={view === View.compact} education={education} />
                        </li>
                    ))}
                </ul>
            )}

            {pagination && educations.length > 0 && (
                <Pagination
                    id="education-overview"
                    amountOfPages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    onChange={onPaginationChange}
                    className="education-overview__pagination"
                />
            )}
        </div>
    );
};

export default EducationOverview;
