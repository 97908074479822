import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultOrganisatioOccupation, OrganisationOccupation } from '../../models/OrganisationOccupation/OrganisationOccupation';
import { AsyncReduxState } from '../../types';

export type OrganisationOccupationState = AsyncReduxState<{
    isUpdating: boolean;
    isDeleted: boolean;
    formError: string;
    occupation: OrganisationOccupation;
}>;

const initialState: OrganisationOccupationState = {
    ...initialAsyncReduxState,
    isUpdating: false,
    isDeleted: false,
    formError: '',
    occupation: defaultOrganisatioOccupation(),
};

export const organisationOccupationSlice = createSlice({
    name: 'organisationOccupationReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrganisationOccupationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OrganisationOccupationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): OrganisationOccupationState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setIsDeleted(state, action: PayloadAction<boolean>): OrganisationOccupationState {
            return {
                ...state,
                isDeleted: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrganisationOccupationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFormError(state, action: PayloadAction<string>): OrganisationOccupationState {
            return {
                ...state,
                formError: action.payload,
            };
        },
        setOccupation(state, action: PayloadAction<OrganisationOccupation>): OrganisationOccupationState {
            return {
                ...state,
                occupation: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
    setIsDeleted,
    setError,
    setFormError,
    setOccupation,
} = organisationOccupationSlice.actions;

export default organisationOccupationSlice.reducer;
