import { v4 as generateUuid } from 'uuid';

import { capitalizeFirstCharacter } from '../../helpers/string';
import { SearchableOption } from '../../types';
import { transformToOccupation } from '../Occupations/OccupationsTransformers';
import { OccupationOverview, OccupationOverviewResource } from './OccupationOverview';

export const transformToOccupationOverview = (
    occupationOverviewResource: OccupationOverviewResource,
): OccupationOverview => ({
    totalAmount: occupationOverviewResource.meta.totalAmount,
    occupations: occupationOverviewResource.occupations.map(transformToOccupation),
});

export const transformSuggestionToSearchableOption = (
    label: string,
): SearchableOption => ({
    value: generateUuid(),
    label: capitalizeFirstCharacter(label),
});
