import { Locale } from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getCurrentVersion, getLatestVersion } from '../../services/AppService';
import { TypedDispatch } from '../store';
import {
    setCurrentVersion,
    setError,
    setIsLoading,
    setLatestVersion,
    setLocale,
} from './app';

export const fetchAppVersions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const currentVersionResponse = await getCurrentVersion();
        const latestVersionResponse = await getLatestVersion();

        if (!isFetchResultSuccessful(currentVersionResponse)) {
            throw Error(currentVersionResponse.error);
        }

        if (!isFetchResultSuccessful(latestVersionResponse)) {
            throw Error(latestVersionResponse.error);
        }

        const currentVersion = currentVersionResponse.data;
        const latestVersion = latestVersionResponse.data;

        dispatch(setCurrentVersion(currentVersion));
        dispatch(setLatestVersion(latestVersion));
    } catch (error) {
        console.error('[fetchAppVersions]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateLocale = (locale: Locale) => async (dispatch: TypedDispatch): Promise<void> => {
    localStorage.setItem('locale', locale);

    dispatch(setLocale(locale));
};
