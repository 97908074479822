import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';

import { getInitials } from '../../helpers/string';

import './Avatar.scss';

interface AvatarProps {
    isCover?: boolean;
    src?: string;
    alt: string;
    fallbackString: string;
    className?: string;
}

const Avatar: FC<AvatarProps> = ({
    isCover,
    src = '',
    alt,
    fallbackString,
    className = '',
}): ReactElement => {
    const [showFallbackInitials, setShowFallbackInitials] = useState<boolean>(!src && !!fallbackString);

    const initials = useMemo((): string => (
        getInitials(fallbackString)
    ), [fallbackString]);

    const setPlaceholderImage = (): void => setShowFallbackInitials(true);

    const avatarClassNames = classNames('avatar', {
        'avatar--has-fallback': showFallbackInitials,
    }, className);

    const avatarImageClassNames = classNames('avatar__image', {
        'avatar__image--is-cover': isCover,
    });

    return (
        <div className={avatarClassNames}>
            {showFallbackInitials ? (
                <h3 data-initials={initials} className="avatar__initials">
                    {initials}
                </h3>
            ) : (
                <img
                    src={src}
                    alt={alt}
                    onError={setPlaceholderImage}
                    className={avatarImageClassNames}
                />
            )}
        </div>
    );
};

export default Avatar;
