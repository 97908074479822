import {
    FC,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Card, Icon, Skeleton } from '../../../components';
import { Dialog, ExpandableTagList, FilterButton } from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { getRoleForTranslation } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Competency } from '../../../models/Competencies/Competencies';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { Skill, SkillsFormData } from '../../../models/Skills/Skills';
import { CompetencyLevel, UserRole } from '../../../types';
import VacancyMatchingFilterSkeletons from './skeletons/VacancyMatchingFilterSkeletons/VacancyMatchingFilterSkeletons';
import { MatchingSkillsFilterForm } from './subcomponents';

import './VacancyMatchingFilter.scss';

export interface VacancyMatchingFilterProps {
    isLoading: boolean;
    userRoles?: UserRole[];
    skills: string[];
    suggestedSkills: Skill[];
    skillOptions: Skill[];
    skillsError?: string;
    activeCompetencies?: Record<string, Competency[]>;
    occupationOptions: EscoOccupation[];
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onUpdateSkills: (skillsFormData: SkillsFormData) => void;
    onSaveSkillsToPassport: (skillsFormData: SkillsFormData) => void;
    onCloseSkillsDialog: () => void;
    className?: string;
}

const VacancyMatchingFilter: FC<VacancyMatchingFilterProps> = ({
    isLoading,
    userRoles = [],
    skills,
    suggestedSkills,
    skillOptions,
    skillsError,
    activeCompetencies,
    occupationOptions,
    onSelectOccupation,
    onUpdateSkills,
    onSaveSkillsToPassport,
    onCloseSkillsDialog,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [matchingSkillsFilterFormKey, setMatchingSkillsFilterFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);
        onCloseSkillsDialog();

        setMatchingSkillsFilterFormKey(matchingSkillsFilterFormKey + 1);
    };

    const activeSkills = useMemo((): Skill[] => (
        skillOptions.filter(option => skills.includes(option.name))
    ), [skillOptions, skills]);

    const [highTalentCompetencies, regularTalentCompetencies] = useMemo((): string[][] => {
        const highCompetencies = activeCompetencies?.[CompetencyLevel.high] || [];
        const regularCompetencies = activeCompetencies?.[CompetencyLevel.regular] || [];

        const highTalents = highCompetencies.map(competency => competency.label);
        const regularTalents = regularCompetencies.map(competency => competency.label);

        return [highTalents, regularTalents];
    }, [activeCompetencies]);

    const handleSelectOccupation = (value: EscoOccupation): void => {
        onSelectOccupation(value);
    };

    const handleSubmit = (skillsFormData: SkillsFormData): void => {
        onUpdateSkills(skillsFormData);
        handleCloseDialog();
    };

    const handleSubmitToSkillsPassport = (skillsFormData: SkillsFormData): void => {
        onSaveSkillsToPassport(skillsFormData);
        onUpdateSkills(skillsFormData);
        handleCloseDialog();
    };

    return (
        <>
            {isLoading && (
                <VacancyMatchingFilterSkeletons className={`vacancy-matching-filter ${className}`} />
            )}

            {!isLoading && (
                <Card className={`vacancy-matching-filter ${className}`}>
                    <h2 className="vacancy-matching-filter__title">
                        {trans(`containers.vacancyMatchingFilter.title.${getRoleForTranslation(userRoles)}`)}
                    </h2>
                    <p className="vacancy-matching-filter__description">
                        {trans(`containers.vacancyMatchingFilter.description.${getRoleForTranslation(userRoles)}`)}
                    </p>

                    <div className="vacancy-matching-filter__skills-wrapper">
                        <h3 className="vacancy-matching-filter__title">
                            {trans(`containers.vacancyMatchingFilter.skills.title.${getRoleForTranslation(userRoles)}`)}
                        </h3>

                        <FilterButton
                            text={trans('containers.vacancyMatchingFilter.skills.buttonLabel')}
                            activeFilters={activeSkills.length}
                            disabled={isLoading}
                            onClick={handleOpenDialog}
                            className="vacancy-matching-filter__filter-button"
                        />
                    </div>

                    <div className="vacancy-matching-filter__competencies-wrapper">
                        <h3 className="vacancy-matching-filter__title">
                            {trans(`containers.vacancyMatchingFilter.competencies.title.${getRoleForTranslation(userRoles)}`)}
                        </h3>

                        {[...highTalentCompetencies, ...regularTalentCompetencies].length === 0 ? (
                            <div className="vacancy-matching-filter__null-state">
                                <Icon name="info" className="vacancy-matching-filter__null-state-icon" />
                                {userRoles.length > 0 ? (
                                    <p className="vacancy-matching-filter__null-state-text">
                                        {trans(`containers.vacancyMatchingFilter.competencies.nullState.${getRoleForTranslation(userRoles)}`)}
                                    </p>
                                ) : <Skeleton className="vacancy-matching-filter__null-state-text" />}
                            </div>
                        ) : (
                            <ExpandableTagList
                                listItems={[...highTalentCompetencies, ...regularTalentCompetencies]}
                                listItemsThreshold={highTalentCompetencies.length}
                                buttonLabel={trans('containers.vacancyMatchingFilter.competencies.buttonLabel')}
                                expandedButtonLabel={trans('containers.vacancyMatchingFilter.competencies.expandedButtonLabel')}
                            />
                        )}
                    </div>
                </Card>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog} className="vacancy-matching-filter__dialog">
                <MatchingSkillsFilterForm
                    key={matchingSkillsFilterFormKey}
                    isLoading={isLoading}
                    userRoles={userRoles}
                    activeSkills={activeSkills}
                    suggestedOccupationSkills={suggestedSkills}
                    skillOptions={skillOptions}
                    error={skillsError}
                    occupationOptions={occupationOptions}
                    onSelectOccupation={handleSelectOccupation}
                    onSubmit={handleSubmit}
                    onSubmitToSkillsPassport={handleSubmitToSkillsPassport}
                    onCancel={handleCloseDialog}
                />
            </Dialog>
        </>
    );
};

export default VacancyMatchingFilter;
