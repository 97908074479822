import { FC, PropsWithChildren, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { FilterButton, IconButton, Sidebar } from '..';

import './FilterControls.scss';

export interface FilterControlsProps {
    isLoading?: boolean;
    isExpanded: boolean;
    amountOfActiveFilters: number;
    sidebarTitle: string;
    onOpenSidebar: () => void;
    onCloseSidebar: () => void;
    onFilterReset: () => void;
    className?: string;
}

const FilterControls: FC<PropsWithChildren<FilterControlsProps>> = ({
    isLoading,
    isExpanded,
    amountOfActiveFilters,
    sidebarTitle,
    onOpenSidebar,
    onCloseSidebar,
    onFilterReset,
    className = '',
    children,
}): ReactElement => (
    <div className={`filter-controls ${className}`}>
        <FilterButton
            hasIcon
            text={trans('common.filter')}
            activeFilters={amountOfActiveFilters}
            disabled={isLoading}
            onClick={onOpenSidebar}
            className="filter-controls__filter-button"
        />

        {amountOfActiveFilters > 0 && (
            <IconButton
                icon="refresh"
                text={trans('common.reset')}
                disabled={isLoading}
                onClick={onFilterReset}
                className="filter-controls__reset-button"
            />
        )}

        <Sidebar
            isLoading={isLoading}
            isExpanded={isExpanded}
            title={sidebarTitle}
            icon="filter"
            onClose={onCloseSidebar}
        >
            {children}
        </Sidebar>
    </div>
);

export default FilterControls;
