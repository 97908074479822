import { Resource } from '../japi/types/Resource';
import { SearchableOption } from '../types';

export interface SchoolResource extends Resource {
    id: string;
    type: 'schools';
    attributes: {
        address: {
            address: string;
            city: string;
            extension: string | null;
            latitude: string;
            longitude: string;
            streetNumber: string;
            uuid: string;
            zipcode: string;
        };
        createdAt: string;
        customerNumber: string | null;
        educationLevel: {
            name: string;
            slug: string;
            uuid: string;
        };
        fqdn: string;
        manual: boolean;
        name: string;
        updatedAt: string;
    };
}

export interface School {
    id: string;
    name: string;
    city?: string;
    relatedEducationLevelIds?: string[];
}

export const transformResourceToSchool = (
    schoolResource: SchoolResource,
): School => {
    // TODO: Apply correct JSON API data retrieval, like it's done with the FieldsOfStudy
    const relatedEducationLevel = schoolResource.attributes?.educationLevel;

    const relatedEducationLevelIds = relatedEducationLevel ? [relatedEducationLevel.uuid] : [];

    return {
        id: schoolResource.id,
        name: schoolResource.attributes.name,
        city: schoolResource.attributes?.address?.city,
        relatedEducationLevelIds,
    };
};

export const transformSchoolToSearchableOption = (
    school: School,
): SearchableOption => ({
    value: school.id,
    label: school.name,
    secondaryLabel: school.city,
});
