export const hotjarId = 'hotjar-snippet';

export const initHotjar = (): void => {
    const key = process.env.REACT_APP_HOTJAR_KEY;
    const snippetVersion = 6;

    // @ts-ignore
    window.hj = window.hj || function hjq() {
        // @ts-ignore
        const query = window.hj.q = window.hj.q || []; // eslint-disable-line
        query.push(arguments); // eslint-disable-line
    };

    // @ts-ignore
    window._hjSettings = {
        hjid: key,
        hjsv: snippetVersion,
    };

    const scriptTag = document.createElement('script');
    const newScript = document.getElementsByTagName('script')[0];

    scriptTag.async = true;
    scriptTag.id = hotjarId;
    scriptTag.src = `https://static.hotjar.com/c/hotjar-${key}.js?sv=${snippetVersion}`;

    if (newScript.parentNode) {
        newScript.parentNode.insertBefore(scriptTag, newScript);
    }
};
