import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './VacancyOverviewSkeletons.scss';

interface VacancyOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const VacancyOverviewSkeletons: FC<VacancyOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <ul className={`vacancy-overview-skeletons ${className}`}>
            {skeletons.map(skeleton => {
                const propertyAmount = randomInBetweenValue(2, 5);
                const propertySkeletons = generateIdArray(propertyAmount);

                return (
                    <li key={skeleton} className="vacancy-overview-skeletons__item">
                        <Card className="vacancy-overview-skeletons__card">
                            <div className="vacancy-overview-skeletons__header">
                                <Skeleton className="vacancy-overview-skeletons__logo" />
                                <div className="vacancy-overview-skeletons__title-wrapper">
                                    <Skeleton className="vacancy-overview-skeletons__title" />

                                    <div className="vacancy-overview-skeletons__meta-wrapper">
                                        <Skeleton className="vacancy-overview-skeletons__company" />
                                        <Skeleton className="vacancy-overview-skeletons__publish-date" />
                                    </div>
                                </div>
                            </div>

                            <ul className="vacancy-overview-skeletons__property-list">
                                {propertySkeletons.map(property => (
                                    <li key={property} className="vacancy-overview-skeletons__property" />
                                ))}
                            </ul>
                        </Card>
                    </li>
                );
            })}
        </ul>
    );
};

export default VacancyOverviewSkeletons;
