import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Card } from '../../../components';
import {
    CopyableValue,
    Dialog,
    DialogContent,
    IconButton,
    Notice,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { defaultOrganisation, Organisation, OrganisationFormData } from '../../../models/Organisation/Organisation';
import { NoticeType } from '../../../types';
import { OrganisationForm } from '../..';
import { OrganisationDetailHeaderSkeletons } from './skeletons';

import './OrganisationDetailHeader.scss';

interface OrganisationDetailHeaderProps {
    isLoading: boolean;
    isUpdating: boolean;
    isSuccessful: boolean;
    error: string;
    organisation?: Organisation;
    onEditOrganisationSubmit: (formData: OrganisationFormData) => void;
    className?: string;
}

const OrganisationDetailHeader: FC<OrganisationDetailHeaderProps> = ({
    isLoading,
    isUpdating,
    isSuccessful,
    error,
    organisation = defaultOrganisation(),
    onEditOrganisationSubmit,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [organisationFormKey, setOrganisationFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setOrganisationFormKey(organisationFormKey + 1);
    };

    useEffect((): void => {
        if (isSuccessful) handleCloseDialog();
    }, [isSuccessful]);

    return (
        <div className={`organisation-detail-header ${className}`}>
            {isLoading && <OrganisationDetailHeaderSkeletons />}

            {!isLoading && error && (
                <Notice
                    type={NoticeType.error}
                    text={trans('containers.organisationDetail.error')}
                />
            )}

            {!isLoading && !error && (
                <>
                    <Card className="organisation-detail-header__card">
                        <h2 className="organisation-detail-header__name">{organisation.name}</h2>
                        <p className="organisation-detail-header__location">{organisation.location}</p>

                        <IconButton
                            hideLabel
                            icon="edit"
                            text={trans('containers.organisationDetail.editOrganisation')}
                            onClick={handleOpenDialog}
                            className="organisation-detail-header__edit-button"
                        />
                    </Card>

                    <Card className="organisation-detail-header__card">
                        <h2 className="organisation-detail-header__external-id-title">
                            {trans('containers.organisationDetail.organisationId.title')}
                        </h2>
                        <p className="organisation-detail-header__external-id-description">
                            {trans('containers.organisationDetail.organisationId.description')}
                        </p>
                        <CopyableValue
                            value={organisation.id}
                            className="organisation-detail-header__copyable-value"
                        />
                    </Card>
                </>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent title={trans('containers.organisationDetail.editDialog.title')}>
                    <OrganisationForm
                        key={organisationFormKey}
                        isLoading={isUpdating}
                        error={error}
                        organisation={organisation}
                        onSubmit={onEditOrganisationSubmit}
                        onCancel={handleCloseDialog}
                        className="organisation-detail-header__form"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default OrganisationDetailHeader;
