import { FC, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import { SkillsPassportCard } from '../../../compositions';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Talent } from '../../../models/SkillsPassport/SkillsPassport';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';
import { TalentDot } from './subcomponents';

import './TalentsCard.scss';

interface TalentsCardProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    candidateUuid?: string;
    userRoles?: UserRole[];
    talents: Talent[];
    handleRefresh: () => void;
    className?: string;
}

const TalentsCard: FC<TalentsCardProps> = ({
    isLoading,
    isUpToDate,
    candidateUuid,
    userRoles = [],
    talents,
    handleRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidateUuid));
    };

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={talents.length === 0}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            title={trans('containers.talentsCard.title')}
            description={userRoles.length > 0 ? trans(`containers.talentsCard.description.${getRoleForTranslation(userRoles)}`) : ''}
            nullStateText={trans('containers.talentsCard.nullState')}
            callToActionButtonLabel={trans('containers.talentsCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={handleRefresh}
            className={`talents-card ${className}`}
        >
            {(!isLoading && talents.length > 0) && (
                <ul className="talents-card__list">
                    {talents.map(talent => (
                        <li key={talent.name} className="talents-card__list-item">
                            <TalentDot talent={talent} className="talents-card__dot" />
                        </li>
                    ))}
                </ul>
            )}
        </SkillsPassportCard>
    );
};

export default TalentsCard;
