import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Dialog, DialogContent, IconButton } from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { DevelopmentPlanForm } from '../..';
import { DevelopmentHeaderSkeletons } from './skeletons';

import './DevelopmentHeader.scss';

interface DevelopmentHeaderProps {
    developmentOverviewIsLoading: boolean;
    developmentPlanIsLoading: boolean;
    developmentPlanIsSuccessful: boolean;
    developmentPlanError?: string;
    onSubmitDevelopmentPlan: (formData: DevelopmentPlanFormData) => void;
    className?: string;
}

const DevelopmentHeader: FC<DevelopmentHeaderProps> = ({
    developmentOverviewIsLoading,
    developmentPlanIsLoading,
    developmentPlanIsSuccessful,
    developmentPlanError,
    onSubmitDevelopmentPlan,
    className = '',
}): ReactElement => {
    const developmentPlanDialogRef = useRef<HTMLDialogElement>(null);

    const [developmentPlanFormKey, setDevelopmentPlanFormKey] = useState<number>(0);

    const handleOpenDevelopmentPlanDialog = (): void => openDialog(developmentPlanDialogRef);
    const handleCloseDevelopmentPlanDialog = (): void => {
        closeDialog(developmentPlanDialogRef);

        setDevelopmentPlanFormKey(developmentPlanFormKey + 1);
    };

    useEffect((): void => {
        if (developmentPlanIsSuccessful) handleCloseDevelopmentPlanDialog();
    }, [developmentPlanIsSuccessful]);

    if (developmentOverviewIsLoading) {
        return <DevelopmentHeaderSkeletons />;
    }

    return (
        <header className={`development-header ${className}`}>
            <div className="development-header__actions-wrapper">
                <IconButton
                    icon="plus"
                    text={trans('containers.developmentHeader.addDevelopmentPlan')}
                    onClick={handleOpenDevelopmentPlanDialog}
                    className="development-header__toggle-button"
                />
            </div>

            <Dialog ref={developmentPlanDialogRef} onClose={handleCloseDevelopmentPlanDialog}>
                <DialogContent title={trans('forms.developmentPlanForm.title')}>
                    <DevelopmentPlanForm
                        key={developmentPlanFormKey}
                        isLoading={developmentPlanIsLoading}
                        error={developmentPlanError}
                        onSubmit={onSubmitDevelopmentPlan}
                        onCancel={handleCloseDevelopmentPlanDialog}
                    />
                </DialogContent>
            </Dialog>
        </header>
    );
};

export default DevelopmentHeader;
