import { Competency } from '../Competencies/Competencies';
import { OrganisationOccupationOverviewItem, OrganisationOccupationOverviewItemResource } from '../OrganisationOccupationOverview/OrganisationOccupationOverview';
import { Skill } from '../Skills/Skills';

export interface OrganisationOccupationResource extends OrganisationOccupationOverviewItemResource {
    skills: {
        id: string;
        label: string;
    }[];
    competencies: {
        id: string;
        label: string;
    }[];
}

export interface OrganisationOccupation extends OrganisationOccupationOverviewItem {
    skills: Skill[];
    competencies: Competency[];
}

export interface AddOrganisationOccupationRequest {
    organisationUuid: string;
    title: string;
    description: string;
}

export interface EditOrganisationOccupationRequest extends AddOrganisationOccupationRequest {
    id: string;
    skills: string[];
    competencies: string[];
}

export interface OrganisationOccupationFormData {
    id?: string;
    title: string;
    description: string;
    skills: string[];
    competencies: string[];
}

export const defaultOrganisatioOccupation = (): OrganisationOccupation => ({
    id: '',
    title: '',
    description: '',
    skills: [],
    competencies: [],
});
