import nlLanguage from '../translation/nl.json';

export enum Locale {
    nl = 'nl',
}

export interface Language {
    name: string;
    code: Locale;
}

export const languages: Language[] = [
    { name: 'Nederlands', code: Locale.nl },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locales: Record<Locale, any> = {
    [Locale.nl]: nlLanguage,
};

const trans = (path: string, params?: Record<string, string | number | undefined>): string => {
    const selectors = path.split('.');
    const paramKeys = Object.keys(params || {});

    const defaultLocale = Locale.nl;
    const locale = localStorage.getItem('locale') as Locale;

    let translation = locales[locale || defaultLocale];

    // Loop over selectors to select preferred translation
    for (let i = 0; i < selectors.length; i += 1) {
        const nextStep = translation[selectors[i]];

        // Throw an error if path can't be resolved, anywhere within translation
        if (!nextStep) {
            console.error(`No translation found for: ${path}`);
            return path;
        }

        translation = nextStep;
    }

    if (typeof translation !== 'string') {
        console.error(`No translation found for: ${path}`);
        return path;
    }

    if (!params) {
        return translation;
    }

    // Apply given parameters to translation
    for (let i = 0; i < paramKeys.length; i += 1) {
        const regex = new RegExp(`:${paramKeys[i]}:`, 'g');

        translation = translation.replace(regex, params[paramKeys[i]]);
    }

    return translation;
};

export default trans;
