import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './OrganisationDetailModulesSkeletons.scss';

interface OrganisationDetailModulesSkeletonsProps {
    amount: number;
    className?: string;
}

const OrganisationDetailModulesSkeletons: FC<OrganisationDetailModulesSkeletonsProps> = ({
    amount,
    className = '',
}): ReactElement => {
    const modules = generateIdArray(amount);

    return (
        <div className={`organisation-detail-modules-skeletons ${className}`}>
            <Skeleton className="organisation-detail-modules-skeletons__title" />

            {modules.map(module => (
                <div key={module} className="organisation-detail-modules-skeletons__module">
                    <div className="organisation-detail-modules-skeletons__module-content">
                        <Skeleton className="organisation-detail-modules-skeletons__label" />

                        <Skeleton className="organisation-detail-modules-skeletons__description" />
                        <Skeleton className="organisation-detail-modules-skeletons__description" />
                    </div>

                    <div className="organisation-detail-modules-skeletons__toggle" />
                </div>
            ))}
        </div>
    );
};

export default OrganisationDetailModulesSkeletons;
