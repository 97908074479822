import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { OccupationDetail, OccupationDetailResource } from './OccupationDetail';
import { transformToOccupationDetail } from './OccupationDetailTransformers';

export const getOccupationDetailApiCall = async (occupationUuid: string, candidateUuid: string, skills: string[]): Promise<FetchResult<OccupationDetail, string>> => {
    try {
        const response = await lloFetch(`/api/escoOccupation/${occupationUuid}`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify(skills),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const occupationDetailResponse: OccupationDetailResource = await response.json();

        const occupation = transformToOccupationDetail(occupationDetailResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: occupation,
        };
    } catch (error) {
        console.error('[getOccupationDetailApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
