import trans from '../../helpers/trans';
import { getCounselorsForOrganisationApiCall } from '../../models/CounselorOverview/CounselorOverviewService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { transformToPagination } from '../../models/Pagination';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setCounselorOverview,
    setError,
    setIsLoading,
    setPagination,
} from './counselorOverview';

export const fetchCounselorsForOrganisation = (organisationId: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const state = getState();
        const { activeFilterValues } = state.counselorOverviewReducer;

        const counselorOverviewResponse = await getCounselorsForOrganisationApiCall(organisationId, activeFilterValues);

        if (!isFetchResultSuccessful(counselorOverviewResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const pagination = transformToPagination(counselorOverviewResponse.data.totalAmount, activeFilterValues.pageNumber);

        dispatch(setCounselorOverview(counselorOverviewResponse.data));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchCounselorsForOrganisation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
