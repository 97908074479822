import {
    DialogHTMLAttributes,
    forwardRef,
    ForwardRefExoticComponent,
    MouseEvent,
    ReactElement,
    Ref,
    RefAttributes,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import trans from '../../helpers/trans';
import useCombinedRefs from '../../hooks/useCombinedRef';
import { IconButton } from '..';

import './Dialog.scss';

interface DialogProps extends DialogHTMLAttributes<HTMLDialogElement>, RefAttributes<HTMLDialogElement> {
    enableBackdropClose?: boolean;
    onClose: () => void;
    className?: string;
}

const Dialog: ForwardRefExoticComponent<DialogProps> = forwardRef(({
    enableBackdropClose,
    onClose,
    className = '',
    children,
    ...dialogProps
}, ref: Ref<HTMLDialogElement>): ReactElement => {
    const [isHidden, setIsHidden] = useState<boolean>(false);

    const dialogRef = useRef<HTMLDialogElement>(null);
    const combinedRef = useCombinedRefs<HTMLDialogElement>(dialogRef, ref);

    const hideDialog = (): void => setIsHidden(true);

    const handleDialogClick = (event: MouseEvent<HTMLDialogElement>): void => {
        event.stopPropagation();
        if (!combinedRef.current || !enableBackdropClose) return;

        const { clientX, clientY } = event;
        const { top, left } = combinedRef.current.getBoundingClientRect();
        const { width, height } = combinedRef.current.getBoundingClientRect();

        const clickedDialog = top <= clientY
            && clientY <= top + height
            && left <= clientX
            && clientX <= left + width;

        if (!clickedDialog) {
            hideDialog();
        }
    };

    const handleClose = (): void => {
        document.body.classList.remove('has-active-modal');

        setIsHidden(false);
        onClose();
    };

    const handleAnimationEnd = (): void => {
        if (!isHidden) return;

        handleClose();
    };

    const dialogClassNames = classNames('dialog', {
        'dialog--is-hidden': isHidden,
        'dialog--has-interactive-backdrop': enableBackdropClose,
    }, className);

    return (
        // eslint-disable-next-line
        <dialog
            {...dialogProps}
            ref={combinedRef}
            onClick={handleDialogClick}
            onAnimationEnd={handleAnimationEnd}
            onClose={handleClose}
            className={dialogClassNames}
        >
            <IconButton
                icon="cross"
                text={trans('common.close')}
                hideLabel
                onClick={hideDialog}
                className="dialog__close-button"
                iconClassName="dialog__close-icon"
            />

            {children}
        </dialog>
    );
});

export default Dialog;
