import { FC, ReactElement } from 'react';

import trans from '../../../../../helpers/trans';

import './DevelopmentPlanEvaluationCardQuestion.scss';

interface DevelopmentPlanEvaluationCardQuestionProps {
    label: string;
    value: string;
    className?: string;
}

const DevelopmentPlanEvaluationCardQuestion: FC<DevelopmentPlanEvaluationCardQuestionProps> = ({
    label,
    value,
    className = '',
}): ReactElement => (
    <div className={`development-plan-evaluation-card-question ${className}`}>
        <h3 className="development-plan-evaluation-card-question__label">{label}</h3>
        <p className="development-plan-evaluation-card-question__answer">
            {value || (<span className="development-plan-evaluation-card-question__empty-field">{trans('common.emptyField')}</span>)}
        </p>
    </div>
);

export default DevelopmentPlanEvaluationCardQuestion;
