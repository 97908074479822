import { CandidateExchangeFormData } from '../../../../models/CandidateExchange/CandidateExchange';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type CandidateExchangeFormErrors = FormErrors<CandidateExchangeFormData>;

export const validateCandidateExchangeFormData = (formData: CandidateExchangeFormData): FormValidation<CandidateExchangeFormErrors> => {
    const errors: CandidateExchangeFormErrors = {
        counselorId: validateRequiredString('counselor', formData.counselorId),
    };

    return validateForm<CandidateExchangeFormErrors>(errors);
};
