import { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { Skill } from '../../models/Skills/Skills';
import { SkillOption } from '..';

import './SkillSelectedOptions.scss';

interface SkillSelectedOptionsProps {
    isSelected?: boolean;
    title?: string;
    selectedOptions: Skill[];
    onSelectOption: (option: Skill) => void;
    className?: string;
}

const SkillSelectedOptions: FC<SkillSelectedOptionsProps> = ({
    isSelected,
    title = trans('compositions.skillsSelector.selected'),
    selectedOptions,
    onSelectOption,
    className = '',
}): ReactElement => (
    <div className={`skill-selected-options ${className}`}>
        <h2 className="skill-selected-options__title">
            {title}
        </h2>

        <ul className="skill-selected-options__list">
            {selectedOptions.map(option => (
                <SkillOption
                    key={option.id}
                    isSelected={isSelected}
                    option={option}
                    selectedOptions={selectedOptions}
                    onSelectOption={onSelectOption}
                    className="skill-selected-options__item"
                />
            ))}
        </ul>
    </div>
);

export default SkillSelectedOptions;
