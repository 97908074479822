import { FC, ReactElement } from 'react';

import { Textarea } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';

import './DevelopmentPlanCardInput.scss';

interface DevelopmentPlanCardInputProps {
    isEditing: boolean;
    disabled?: boolean;
    label: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

const DevelopmentPlanCardInput: FC<DevelopmentPlanCardInputProps> = ({
    isEditing,
    disabled = false,
    label,
    placeholder,
    value,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`development-plan-card-input ${className}`}>
        <h3 className="development-plan-card-input__label">{label}</h3>
        {isEditing ? (
            <Textarea
                disabled={disabled}
                label={label}
                hideLabel
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                textareaClassName="development-plan-card-input__input"
            />
        ) : (
            <p className="development-plan-card-input__answer">
                {value || (<span className="development-plan-card-input__empty-field">{trans('common.emptyField')}</span>)}
            </p>
        )}
    </div>
);

export default DevelopmentPlanCardInput;
