import { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { getYouTubeEmbedUrl } from '../../helpers/youTube';

import './YouTubeEmbed.scss';

interface YouTubeEmbedProps {
    autoPlay?: boolean;
    title?: string;
    embedId: string;
    className?: string;
}

const YouTubeEmbed: FC<YouTubeEmbedProps> = ({
    autoPlay,
    title = trans('compositions.youTubeEmbed.defaultTitle'),
    embedId,
    className = '',
}): ReactElement => {
    const embedUrl = getYouTubeEmbedUrl(embedId, autoPlay);

    return (
        <div className={`youtube-embed ${className}`}>
            <iframe
                src={embedUrl}
                title={title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="youtube-embed__iframe"
            />
        </div>
    );
};

export default YouTubeEmbed;
