import { FC, ReactElement } from 'react';

import { WorkplacePreferencesCard } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedWorkplacePreferencesCardProps {
    onRefresh: () => void;
    className?: string;
}

const ConnectedWorkplacePreferencesCard: FC<ConnectedWorkplacePreferencesCardProps> = ({
    onRefresh,
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const workplacePreferences = useTypedSelector(state => state.skillsPassportReducer.workplacePreferences);
    const isUpToDate = useTypedSelector(state => state.skillsPassportReducer.isUpToDate);

    return (
        <WorkplacePreferencesCard
            isLoading={skillsPassportIsLoading}
            isUpToDate={isUpToDate}
            candidate={candidate}
            userRoles={user?.roles}
            workplacePreferences={workplacePreferences}
            handleRefresh={onRefresh}
            className={className}
        />
    );
};

export default ConnectedWorkplacePreferencesCard;
