import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel } from '../../../components';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';
import { RadioItem } from './subcomponents';

import './RadioList.scss';

interface RadioListProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    maySelectOther?: boolean;
    label: string;
    hideLabel?: boolean;
    options: FormOption[];
    error?: string;
    onChange: (value: string) => void;
    className?: string;
}

const RadioList: FC<RadioListProps> = ({
    maySelectOther = false,
    label,
    hideLabel = false,
    options,
    value,
    required = false,
    error = '',
    className = '',
    ...radioItemProps
}): ReactElement => {
    const otherOption = 'other';

    const radioListClassNames = classNames('radio-list__options-wrapper', {
        'radio-list__options-wrapper--has-error': !!error,
    });

    return (
        <div className={`radio-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="radio-list__label"
                />
            )}

            <div className={radioListClassNames}>
                {options.map(option => (
                    <RadioItem
                        {...radioItemProps}
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        checked={value === option.value}
                        className="radio-list__option"
                    />
                ))}

                {maySelectOther && (
                    <RadioItem
                        {...radioItemProps}
                        key={otherOption}
                        label={trans('common.other')}
                        value={otherOption}
                        checked={value === otherOption}
                        className="radio-list__option"
                    />
                )}
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="radio-list__error-label"
                />
            )}
        </div>
    );
};

export default RadioList;
