import { generateFullName, splitAliasIntoNameObject } from '../../helpers/user';
import { FormOption } from '../../types';
import { transformToUserRole } from '../User/UserTransformers';
import {
    AddCounselorRequest,
    Counselor,
    CounselorFormData,
    CounselorOption,
    CounselorOptionResource,
    CounselorResource,
    EditCounselorRequest,
} from './Counselor';

export const transformToCounselor = (
    counselorResource: CounselorResource,
): Counselor => {
    const { firstName, lastNamePrefix, lastName } = splitAliasIntoNameObject(counselorResource.displayName);

    const transformedRoles = counselorResource.roles.map(transformToUserRole);
    const roles = transformedRoles.sort((role, roleToCompare) => role.localeCompare(roleToCompare));

    return ({
        id: counselorResource.id,
        firstName,
        lastNamePrefix,
        lastName,
        email: counselorResource.email,
        roles,
    });
};

export const transformToAddCounselorRequest = (
    formData: CounselorFormData,
    organisationId: string,
): AddCounselorRequest => {
    const roles = ['COUNSELOR'];

    if (formData.isOrganisationAdmin) roles.push('ORGANISATIONADMIN');
    if (formData.isTmaExpert) roles.push('TMAEXPERT');

    return ({
        organisationId,
        firstName: formData.firstName,
        lastNamePrefix: formData.lastNamePrefix,
        lastName: formData.lastName,
        email: formData.email,
        roles,
    });
};

export const transformToEditCounselorRequest = (
    formData: CounselorFormData,
    organisationId: string,
): EditCounselorRequest => {
    const roles = ['COUNSELOR'];

    if (formData.isOrganisationAdmin) roles.push('ORGANISATIONADMIN');
    if (formData.isTmaExpert) roles.push('TMAEXPERT');

    return ({
        id: formData.id || '',
        organisationId,
        firstName: formData.firstName,
        lastNamePrefix: formData.lastNamePrefix,
        lastName: formData.lastName,
        roles,
    });
};

export const transformToCounselorOption = (
    counselorOptionResource: CounselorOptionResource,
): CounselorOption => {
    const transformedRoles = counselorOptionResource.roles.map(transformToUserRole);
    const roles = transformedRoles.sort((role, roleToCompare) => role.localeCompare(roleToCompare));

    return ({
        id: counselorOptionResource.id,
        fullName: generateFullName(counselorOptionResource),
        email: counselorOptionResource.email,
        roles,
    });
};

export const transformCounselorOptionToFormOption = (
    counselorOption: CounselorOption,
): FormOption => ({
    value: counselorOption.id,
    label: counselorOption.fullName,
});
