import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getOccupationDetailApiCall } from '../../models/OccupationDetail/OccupationDetailService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setOccupation } from './occupationDetail';

export const fetchOccupationDetail = (occupationUuid: string, candidateUuid: string, skills: string[]) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const occupationDetailResponse = await getOccupationDetailApiCall(occupationUuid, candidateUuid, skills);

        if (!isFetchResultSuccessful(occupationDetailResponse)) {
            dispatch(setError(occupationDetailResponse.error));
            return;
        }

        const occupation = occupationDetailResponse.data;

        dispatch(setOccupation(occupation));
    } catch (error) {
        console.error('[fetchOccupationDetail]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
