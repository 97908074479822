import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import {
    defaultOrganisationFilterValues,
    defaultOrganisationOverview,
    OrganisationFilterValues,
    OrganisationOverview,
} from '../../models/OrganisationOverview/OrganisationOverview';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState } from '../../types';

export type OrganisationOverviewState = AsyncReduxState<{
    organisationOverview: OrganisationOverview;
    activeFilterValues: OrganisationFilterValues;
    pagination?: Pagination;
}>;

const initialState: OrganisationOverviewState = {
    ...initialAsyncReduxState,
    organisationOverview: defaultOrganisationOverview(),
    activeFilterValues: defaultOrganisationFilterValues(),
};

export const organisationOverviewSlice = createSlice({
    name: 'organisationOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrganisationOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrganisationOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOrganisationOverview(state, action: PayloadAction<OrganisationOverview>): OrganisationOverviewState {
            return {
                ...state,
                organisationOverview: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<OrganisationFilterValues>): OrganisationOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): OrganisationOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setOrganisationOverview,
    setActiveFilterValues,
    setPagination,
} = organisationOverviewSlice.actions;

export default organisationOverviewSlice.reducer;
