import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { DialogContent } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { OrganisationCandidate } from '../../../../../models/CandidateOverview/CandidateOverview';
import { MessageState } from '../../../../../types';

import './ArchiveSuccessMessage.scss';

interface ArchiveSuccessMessageProps {
    candidate: OrganisationCandidate;
    onConfirm: () => void;
    className?: string;
}

const ArchiveSuccessMessage: FC<ArchiveSuccessMessageProps> = ({
    candidate,
    onConfirm,
    className = '',
}): ReactElement => (
    <DialogContent
        title={trans('tables.organisationCandidate.archiveCandidate.successTitle')}
        titleIcon="check"
        messageState={MessageState.positive}
        className={`archive-success-message ${className}`}
    >
        <p className="archive-success-message__paragraph">
            {trans('tables.organisationCandidate.archiveCandidate.successText', {
                candidateFullName: candidate.fullName,
            })}
        </p>

        <p className="archive-success-message__paragraph">
            {trans('tables.organisationCandidate.archiveCandidate.updateDisclaimer', {
                candidateFullName: candidate.fullName,
            })}
        </p>

        <div className="archive-success-message__button-wrapper">
            <Button
                text={trans('tables.organisationCandidate.archiveCandidate.successConfirm')}
                onClick={onConfirm}
            />
        </div>
    </DialogContent>
);

export default ArchiveSuccessMessage;
