import { CompetencyLevel } from '../../types';
import { Competency, CompetencyResource } from './Competencies';

const transformToCompetencyLevel = (
    level: string,
): CompetencyLevel => {
    const levels: Record<string, CompetencyLevel> = {
        'Veel talent': CompetencyLevel.high,
        Talent: CompetencyLevel.regular,
        'Matig talent': CompetencyLevel.mediocre,
        'Weinig talent': CompetencyLevel.low,
    };

    return levels[level];
};

export const transformToCompetency = (
    competencyResource: CompetencyResource,
): Competency => ({
    id: competencyResource.id,
    label: competencyResource.label,
    description: competencyResource.description || '',
    level: competencyResource.talent
        ? transformToCompetencyLevel(competencyResource.talent)
        : CompetencyLevel.noLevel,
    developmentTips: competencyResource.developmentTips || [],
});
