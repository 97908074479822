/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconArrowRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M0,43.7v12.6h75.8L41,91l9,9l50-50L50,0l-9,9l34.7,34.7H0z" />
    </svg>
);

export default IconArrowRight;
/* eslint-enable max-len */
