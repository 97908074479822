/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconFilter: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 66.7" className={`icon ${className}`}>
        <rect x="38.9" y="55.6" width="22.2" height="11.1" />
        <rect x="16.7" y="27.8" width="66.7" height="11.1" />
        <rect x="0" width="100" height="11.1" />
    </svg>
);

export default IconFilter;
/* eslint-enable max-len */
