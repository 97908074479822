import { generateFullName, splitAliasIntoNameObject } from '../../helpers/user';
import { transformDevelopmentPlanCompetencyEvaluation } from '../DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluationTransformer';
import { transformDevelopmentPlanSkillEvaluation } from '../DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluationTransformer';
import { DevelopmentPlanEvaluation, DevelopmentPlanEvaluationResource } from './DevelopmentPlanEvaluation';

export const transformToDevelopmentPlanEvaluation = (resource: DevelopmentPlanEvaluationResource): DevelopmentPlanEvaluation => ({
    id: resource.id,
    createdAt: new Date(resource.createdAt),
    updatedAt: new Date(resource.updatedAt),
    createdByName: generateFullName(splitAliasIntoNameObject(resource.createdByName)),
    competencyEvaluations: resource.competencyEvaluations ? resource.competencyEvaluations
        .map(transformDevelopmentPlanCompetencyEvaluation) : [],
    skillEvaluations: resource.skillEvaluations ? resource.skillEvaluations
        .map(transformDevelopmentPlanSkillEvaluation) : [],
});
