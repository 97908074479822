import { CounselorFormData } from '../../../../models/Counselor/Counselor';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateName,
} from '../../../../services/ValidationService';

export type CounselorFormErrors = FormErrors<CounselorFormData>;

export const validateCounselorFormData = (formData: CounselorFormData): FormValidation<CounselorFormErrors> => {
    const errors: CounselorFormErrors = {
        firstName: validateName('firstName', formData.firstName),
        lastName: validateName('lastName', formData.lastName),
        email: validateEmailAddress(formData.email),
    };

    return validateForm<CounselorFormErrors>(errors);
};
