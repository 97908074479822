import { FC, ReactElement } from 'react';

import { CheckboxChange } from '@createnl/grouped-checkboxes/dist/CheckboxGroup';

import { InputLabel } from '../../../components';
import {
    EducationLevel,
    EducationSubLevel,
    transformEducationLevelToFormOption,
    transformEducationSubLevelToFormOption,
} from '../../../models/EducationLevels';
import { FormOption } from '../../../types';
import { Checkbox, CheckboxGroupList } from '../..';

import './EducationLevelsFilter.scss';

interface EducationLevelsFilterProps {
    label: string;
    hideLabel?: boolean;
    name: string;
    educationLevels: EducationLevel[];
    educationSubLevels: EducationSubLevel[];
    value: string[];
    tabIndex?: number;
    onChange: (value: string[]) => void;
    className?: string;
}

const EducationLevelsFilter: FC<EducationLevelsFilterProps> = ({
    label,
    hideLabel,
    name,
    educationLevels,
    educationSubLevels,
    value,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => {
    const getEducationSubLevelOptions = (educationLevel: EducationLevel): FormOption[] => {
        const filteredSubLevels = educationSubLevels.filter(subLevel => subLevel.relatedEducationLevelIds?.includes(educationLevel.id));

        return filteredSubLevels.map(transformEducationSubLevelToFormOption);
    };

    const handleSingleCheckboxChange = (educationLevel: EducationLevel, isChecked: boolean): void => {
        const newValue = isChecked
            ? [...value, educationLevel.id]
            : [...value].filter(levelId => levelId !== educationLevel.id);

        onChange(newValue);
    };

    const handleGroupCheckboxChange = (educationLevel: EducationLevel, checkboxes: CheckboxChange[]): void => {
        if (checkboxes.length === 0) return;

        let newValue = [...value];

        checkboxes.forEach(checkbox => {
            const educationSubLevel = educationSubLevels.find(subLevel => subLevel.id === checkbox.value);

            if (educationSubLevel) {
                if (checkbox.checked && !value.includes(educationSubLevel.id)) {
                    newValue = [...newValue, educationSubLevel.id];
                }

                if (!checkbox.checked) {
                    newValue = [...newValue].filter(levelId => levelId !== educationSubLevel.id);
                }
            }
        });

        const allChecked = checkboxes.every(checkbox => checkbox.checked);

        if (allChecked && !value.includes(educationLevel.id)) {
            newValue = [...newValue, educationLevel.id];
        }

        if (!allChecked) {
            newValue = [...newValue].filter(levelId => levelId !== educationLevel.id);
        }

        onChange(newValue);
    };

    return (
        <div className={`education-levels-filter ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    className="education-levels-filter__label"
                />
            )}

            {educationLevels.map(mainLevel => {
                const educationSubLevelOptions = getEducationSubLevelOptions(mainLevel);
                const handleSingleChange = (isChecked: boolean): void => handleSingleCheckboxChange(mainLevel, isChecked);

                if (educationSubLevelOptions.length === 0) {
                    return (
                        <Checkbox
                            key={mainLevel.id}
                            name={name}
                            label={mainLevel.label}
                            value={mainLevel.id}
                            checked={value.includes(mainLevel.id)}
                            tabIndex={tabIndex}
                            onChange={handleSingleChange}
                            className="education-levels-filter__item"
                        />
                    );
                }

                const selectedGroupOptions = educationSubLevelOptions.filter(option => value?.includes(option.value));
                const handleGroupChange = (checkboxes: CheckboxChange[]): void => handleGroupCheckboxChange(mainLevel, checkboxes);

                return (
                    <CheckboxGroupList
                        key={mainLevel.id}
                        name={name}
                        groupOption={transformEducationLevelToFormOption(mainLevel)}
                        options={educationSubLevelOptions}
                        selectedOptions={selectedGroupOptions}
                        tabIndex={tabIndex}
                        onChange={handleGroupChange}
                        className="education-levels-filter__item"
                    />
                );
            })}
        </div>
    );
};

export default EducationLevelsFilter;
