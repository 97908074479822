import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TabsNavigation } from '../../../compositions';
import {
    ConnectedInternalOccupationFilters,
    ConnectedInternalOccupationOverview,
    ConnectedOccupationFilters,
    ConnectedOccupationOverview,
} from '../../../connectors';
import { removeDisabledModulesFromNavigation } from '../../../helpers/navigation';
import trans from '../../../helpers/trans';
import { UserModule } from '../../../models/Modules/Modules';
import { RoutePaths } from '../../../Routes';
import { ModuleType, RouteKey } from '../../../types';
import { OccupationTabsSkeletons } from './skeletons';

import './OccupationTabs.scss';

export enum OccupationRouteTab {
    default = ':tab',
    occupations = 'beroepen',
    internalOccupations = 'interne-beroepen',
}

interface OccupationTabsProps {
    isLoading: boolean;
    userModules?: UserModule[];
    className?: string;
}

const OccupationTabs: FC<OccupationTabsProps> = ({
    isLoading,
    userModules = [],
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const {
        candidateUuid,
        tab = OccupationRouteTab.default,
    } = useParams();

    const tabs = [
        {
            key: RouteKey.seekOccupations,
            to: RoutePaths.seekOccupationTab(candidateUuid, OccupationRouteTab.occupations),
            label: trans('containers.occupationTabs.label.occupations'),
        },
        {
            key: RouteKey.seekInternalOccupations,
            to: RoutePaths.seekOccupationTab(candidateUuid, OccupationRouteTab.internalOccupations),
            label: trans('containers.occupationTabs.label.internalOccupations'),
            module: ModuleType.internalOccupations,
        },
    ];

    const filteredTabs = removeDisabledModulesFromNavigation(tabs, userModules);

    useEffect((): void => {
        if (tab === OccupationRouteTab.default) {
            navigate(RoutePaths.seekOccupationTab(candidateUuid, OccupationRouteTab.occupations), { replace: true });
        }
    }, [tab]);

    return (
        <div className={`occupation-tabs ${className}`}>
            {isLoading && (
                <OccupationTabsSkeletons
                    amountOfLabels={2}
                    className="occupation-tabs__skeletons"
                />
            )}

            {!isLoading && filteredTabs.length > 1 && (
                <TabsNavigation
                    navigationLabel={trans('containers.occupationTabs.navigationLabel')}
                    navigationItems={filteredTabs}
                    className="occupation-tabs__tabs-navigation"
                />
            )}

            <div className="occupation-tabs__tab">
                {tab === OccupationRouteTab.occupations && (
                    <>
                        <ConnectedOccupationFilters />
                        <ConnectedOccupationOverview className="occupation-tabs__overview" />
                    </>
                )}

                {tab === OccupationRouteTab.internalOccupations && (
                    <>
                        <ConnectedInternalOccupationFilters />
                        <ConnectedInternalOccupationOverview className="occupation-tabs__overview" />
                    </>
                )}
            </div>
        </div>
    );
};

export default OccupationTabs;
