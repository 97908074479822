import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface AmountOfEmployeesOptionResource extends Resource {
    id: string;
    type: 'amounts-of-employees';
    attributes: {
        slug: string;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
}

export type AmountOfEmployeesOption = FormOption;

export const transformResourceToAmountOfEmployeesOption = (
    amountOfEmployeesResource: AmountOfEmployeesOptionResource,
): AmountOfEmployeesOption => ({
    value: amountOfEmployeesResource.id,
    label: amountOfEmployeesResource.attributes.name,
});

export const transformAmountOfEmployeesOptionToFormOption = (
    amountOfEmployeesOption: AmountOfEmployeesOption,
): FormOption => ({
    value: amountOfEmployeesOption.value,
    label: amountOfEmployeesOption.label,
});
