import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Card } from '../../../../../components';
import {
    CallToActionNullState,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../../compositions';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { Competency, CompetencyFormData } from '../../../../../models/Competencies/Competencies';
import { OrganisationOccupation } from '../../../../../models/OrganisationOccupation/OrganisationOccupation';
import CompetenciesForm from '../../../../@forms/CompetenciesForm/CompetenciesForm';

import './OccupationCompetenciesCard.scss';

interface OccupationCompetenciesCardProps {
    isUpdating: boolean;
    isSuccessful: boolean;
    error: string;
    occupation: OrganisationOccupation;
    competencyOptions: Competency[];
    onUpdateCompetencies: (formData: CompetencyFormData) => void;
    className?: string;
}

const OccupationCompetenciesCard: FC<OccupationCompetenciesCardProps> = ({
    isUpdating,
    isSuccessful,
    error,
    occupation,
    competencyOptions,
    onUpdateCompetencies,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [competenciesFormKey, setCompetenciesFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setCompetenciesFormKey(competenciesFormKey + 1);
    };

    const { competencies } = occupation;

    useEffect((): void => {
        if (isSuccessful) handleCloseDialog();
    }, [isSuccessful]);

    return (
        <Card className={`occupation-competencies-card ${className}`}>
            <header className="occupation-competencies-card__header">
                <h3 className="occupation-competencies-card__title">{trans('common.competencies')}</h3>

                {competencies.length > 0 && (
                    <IconButton
                        icon="plus"
                        text={trans('containers.organisationDetailOccupation.actions.addCompetencies')}
                        onClick={handleOpenDialog}
                        className="occupation-competencies-card__add-button"
                    />
                )}
            </header>

            {competencies.length === 0 && (
                <CallToActionNullState
                    isEditable
                    icon="skills-passport"
                    paragraph={trans('containers.organisationDetailOccupation.competenciesNullState.paragraph')}
                    subParagraph={trans('containers.organisationDetailOccupation.competenciesNullState.subParagraph')}
                    buttonText={trans('containers.organisationDetailOccupation.competenciesNullState.callToAction')}
                    onButtonClick={handleOpenDialog}
                    className="occupation-competencies-card__null-state"
                />
            )}

            {competencies.length > 0 && (
                <ul className="occupation-competencies-card__list">
                    {competencies.map(competency => (
                        <li key={competency.id} className="occupation-competencies-card__list-item">{competency.label}</li>
                    ))}
                </ul>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent
                    title={competencies.length === 0
                        ? trans('containers.organisationDetailOccupation.competenciesForm.addTitle')
                        : trans('containers.organisationDetailOccupation.competenciesForm.editTitle')}
                >
                    <CompetenciesForm
                        key={competenciesFormKey}
                        isLoading={isUpdating}
                        error={error}
                        competencies={competencies}
                        competencyOptions={competencyOptions}
                        onSubmit={onUpdateCompetencies}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default OccupationCompetenciesCard;
