import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';

import './DevelopmentPlanHeaderSkeletons.scss';

interface DevelopmentPlanHeaderSkeletonsProps {
    className?: string;
}

const DevelopmentPlanHeaderSkeletons: FC<DevelopmentPlanHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <section className={`developmentplan-header-skeletons ${className}`}>
        <div className="developmentplan-header-skeletons__header">
            <div className="developmentplan-header-skeletons__title-and-date-wrapper">
                <Skeleton className="developmentplan-header-skeletons__title" />
                <Skeleton className="developmentplan-header-skeletons__date" />
            </div>

            <div className="developmentplan-header-skeletons__action-buttons">
                <Skeleton className="developmentplan-header-skeletons__add-button" />
            </div>
        </div>
    </section>
);

export default DevelopmentPlanHeaderSkeletons;
