import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import {
    defaultInternalOccupationFilterValues,
    defaultInternalOccupationOverview,
    InternalOccupationFilterValues,
    InternalOccupationOverview,
} from '../../models/InternalOccupationOverview/InternalOccupationOverview';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState } from '../../types';

export type InternalOccupationOverviewState = AsyncReduxState<{
    activeFilterValues: InternalOccupationFilterValues;
    internalOccupationOverview: InternalOccupationOverview;
    pagination?: Pagination;
}>;

const initialState: InternalOccupationOverviewState = {
    ...initialAsyncReduxState,
    isLoading: true,
    activeFilterValues: defaultInternalOccupationFilterValues(),
    internalOccupationOverview: defaultInternalOccupationOverview(),
};

export const internalOccupationOverviewSlice = createSlice({
    name: 'internalOccupationOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): InternalOccupationOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): InternalOccupationOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<InternalOccupationFilterValues>): InternalOccupationOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setInternalOccupationOverview(state, action: PayloadAction<InternalOccupationOverview>): InternalOccupationOverviewState {
            return {
                ...state,
                internalOccupationOverview: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): InternalOccupationOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveFilterValues,
    setInternalOccupationOverview,
    setPagination,
} = internalOccupationOverviewSlice.actions;

export default internalOccupationOverviewSlice.reducer;
