import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const doesEmailExistInTalentz = async (email: string): Promise<FetchResult<ResponseType, string>> => {
    try {
        const response = await talentzFetch(`/api/users/exists-by-email/${email}`);

        if (response.status === 404) {
            return {
                status: 404,
                type: FetchResultType.Success,
                data: 'default',
            };
        }

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Error,
            error: trans('errors.emailAlreadyExists'),
        };
    } catch (error) {
        console.error('[doesEmailExistInTalentz]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const doesLLOAccountExistInTalentz = async (email: string): Promise<FetchResult<ResponseType, string>> => {
    try {
        const response = await talentzFetch(`/public/llo-users/exists-by-email/${email}`);

        if (response.status === 404) {
            return {
                status: 404,
                type: FetchResultType.Error,
                error: trans('errors.talentzAccountNotFound'),
            };
        }

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: 'default',
        };
    } catch (error) {
        console.error('[doesLLOAccountExistInTalentz]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
