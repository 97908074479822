import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { DialogContent } from '../../../../../compositions';
import { userHasRole } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { OrganisationCandidate } from '../../../../../models/CandidateOverview/CandidateOverview';
import { CounselorOption } from '../../../../../models/Counselor/Counselor';
import { MessageState, UserRole } from '../../../../../types';

import './ExchangeSuccessMessage.scss';

interface ExchangeSuccessMessageProps {
    userRoles?: UserRole[];
    candidate: OrganisationCandidate;
    newCounselor: CounselorOption;
    onConfirm: () => void;
    className?: string;
}

const ExchangeSuccessMessage: FC<ExchangeSuccessMessageProps> = ({
    userRoles,
    candidate,
    newCounselor,
    onConfirm,
    className = '',
}): ReactElement => {
    const translationRole = userHasRole(UserRole.lloAdmin, userRoles) ? UserRole.lloAdmin : UserRole.counselor;

    return (
        <DialogContent
            title={trans(`tables.organisationCandidate.candidateExchange.successTitle.${translationRole}`)}
            titleIcon="check"
            messageState={MessageState.positive}
            className={`exchange-success-message ${className}`}
        >
            <p className="exchange-success-message__paragraph">
                {trans('tables.organisationCandidate.candidateExchange.successText', {
                    candidateFullName: candidate.fullName,
                    counselorFullName: newCounselor.fullName,
                })}
            </p>

            {!userHasRole(UserRole.lloAdmin, userRoles) && (
                <p className="exchange-success-message__paragraph">
                    {trans('tables.organisationCandidate.candidateExchange.updateDisclaimer', {
                        candidateFullName: candidate.fullName,
                    })}
                </p>
            )}

            <div className="exchange-success-message__button-wrapper">
                <Button
                    text={trans('tables.organisationCandidate.candidateExchange.successConfirm')}
                    onClick={onConfirm}
                />
            </div>
        </DialogContent>
    );
};

export default ExchangeSuccessMessage;
