import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './InternalOccupationDetailHeaderSkeletons.scss';

interface InternalOccupationDetailHeaderSkeletonsProps {
    className?: string;
}

const InternalOccupationDetailHeaderSkeletons: FC<InternalOccupationDetailHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const descriptionLineAmount = randomInBetweenValue(1, 2);
    const descriptionLineSkeletons = generateIdArray(descriptionLineAmount);

    return (
        <Card className={`internal-occupation-detail-header-skeletons ${className}`}>
            <div className="internal-occupation-detail-header-skeletons__text">
                <Skeleton className="internal-occupation-detail-header-skeletons__name" />

                {descriptionLineSkeletons.map(descriptionLine => (
                    <Skeleton key={descriptionLine} className="internal-occupation-detail-header-skeletons__description" />
                ))}
            </div>

            <div className="internal-occupation-detail-header-skeletons__matched-skills">
                <div className="internal-occupation-detail-header-skeletons__progress-circle" />
                <Skeleton className="internal-occupation-detail-header-skeletons__progress-description" />
            </div>
        </Card>
    );
};

export default InternalOccupationDetailHeaderSkeletons;
