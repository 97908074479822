export enum DevelopmentPlanNoteType {
    public = 'public',
    private = 'private',
}

export interface DevelopmentPlanNoteAuthorResource {
    id: string;
    firstName: string;
    lastName: string;
    lastNamePrefix?: string;
}

export interface DevelopmentPlanNoteResource {
    id: string;
    author?: DevelopmentPlanNoteAuthorResource;
    content: string;
    updatedAt: string;
}

export interface DevelopmentPlanNoteAuthor {
    uuid: string;
    fullName: string;
}

export interface DevelopmentPlanNote {
    id: string;
    author?: DevelopmentPlanNoteAuthor;
    content: string;
    updatedAt: Date;
}

export interface DevelopmentPlanNoteFormData {
    id?: string;
    content: string;
}

export interface AddDevelopmentPlanNoteRequest {
    developmentPlanId: string;
    content: string;
}

export interface EditDevelopmentPlanNoteRequest {
    id: string;
    content: string;
}
