import { convertDateStringToDDMMYYYY, formatDate } from '../helpers/date';
import { objectContainsValues } from '../helpers/object';
import trans from '../helpers/trans';
import {
    arrayHasMinimumLength,
    dateHasOccurred,
    dateIsEqualOrAfterComparison,
    dateIsEqualOrBeforeComparison,
    dateIsValid,
    stringContainsValue,
    stringDoesNotExceedLength,
    stringHasMinimumLength,
    stringMatchesRegEx,
} from '../helpers/validation';
import { Address } from '../models/Address/Address';
import { SearchableOption } from '../types';

export type FormError<Value = string> = Value | undefined;
export type FormErrors<FormData, FormSubData = string> = Partial<Record<keyof FormData, FormError<FormSubData>>>;

// ESlint needs to be updated for TypeScript to recognise tuple types, but doing so breaks the pipeline build.
// When attempting to upgrade, be careful and check pipeline build after each change you make
// eslint-disable-next-line
export type FormValidation<Errors> = any;
export type OptionalFormValidation<FormData> = Partial<Record<keyof FormData, boolean>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateForm = <Errors extends Record<string, any>>(errors: Errors): FormValidation<Errors> => {
    const hasErrors = objectContainsValues<Errors>(errors);

    return [
        errors,
        hasErrors,
    ];
};

export const validateRequiredString = (attribute: string, string?: string): FormError => {
    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateName = (attribute: string, string: string): FormError => {
    const minLength = 2;
    const maxLength = 25;

    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!stringHasMinimumLength(string, minLength)) {
        return trans('errors.stringFailsMinimumLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            length: String(minLength),
        });
    }

    if (!stringDoesNotExceedLength(string, maxLength)) {
        return trans('errors.stringExceedsLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            length: String(maxLength),
        });
    }

    return undefined;
};

export const validateEmailAddress = (email: string): FormError => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!stringContainsValue(email)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.email'),
        });
    }

    if (!stringMatchesRegEx(email, emailRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.email'),
            example: trans('errors.examples.email'),
        });
    }

    return undefined;
};

export const validatePhoneNumber = (phoneNumber: string): FormError => {
    // eslint-disable-next-line max-len
    const phoneNumberRegex = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;

    if (!stringMatchesRegEx(phoneNumber, phoneNumberRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.phoneNumber'),
            example: trans('errors.examples.phoneNumber'),
        });
    }

    return undefined;
};

export const validateFullPostalCode = (address: Address): FormError => {
    const postalCodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
    const houseNumberRegex = /^[0-9]*$/;

    const {
        postalCode = '',
        houseNumber = '',
    } = address;

    if (!stringContainsValue(postalCode)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.postalCode'),
        });
    }

    if (!stringMatchesRegEx(postalCode, postalCodeRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.postalCode'),
            example: trans('errors.examples.postalCode'),
        });
    }

    if (!stringContainsValue(String(houseNumber))) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.houseNumber'),
        });
    }

    if (!stringMatchesRegEx(String(houseNumber), houseNumberRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.houseNumber'),
            example: trans('errors.examples.houseNumber'),
        });
    }

    return undefined;
};

export const validateAddress = (address: Address): FormError => {
    const {
        street = '',
        city = '',
        latitude = '',
        longitude = '',
    } = address;

    const fullPostalCodeValidation = validateFullPostalCode(address);
    const hasFullPostalCodeError = !!fullPostalCodeValidation;

    if (hasFullPostalCodeError) {
        return fullPostalCodeValidation;
    }

    if (!stringContainsValue(street)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.street'),
        });
    }

    if (!stringContainsValue(city)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.city'),
        });
    }

    if (!stringContainsValue(latitude) || !stringContainsValue(longitude)) {
        return trans('errors.invalidAddress');
    }

    return undefined;
};

export const validateShortDescription = (attribute: string, description: string): FormError => {
    const maxDescriptionLength = 256;

    if (!stringContainsValue(description)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.description'),
        });
    }

    if (!stringDoesNotExceedLength(description, maxDescriptionLength)) {
        return trans('errors.stringExceedsLength', {
            attribute: trans('errors.attributes.description'),
            length: String(maxDescriptionLength),
        });
    }

    return undefined;
};

export const validateDateString = (attribute: string, dateString: string): FormError => {
    const formattedDateString = convertDateStringToDDMMYYYY(dateString);
    const DDMMYYYYRegex = /(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})/;

    if (!stringContainsValue(dateString)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!stringMatchesRegEx(formattedDateString, DDMMYYYYRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans(`errors.attributes.${attribute}`),
            example: trans('errors.examples.date'),
        });
    }

    return undefined;
};

export const validateStartDate = (startDate?: Date, endDate?: Date, dateNotation?: string): FormError => {
    if (!startDate) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    if (!dateIsValid(startDate)) {
        return trans('errors.invalidDate', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    if (endDate && !dateIsValid(endDate)) {
        return trans('errors.incomparableDate', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    if (endDate && !dateIsEqualOrBeforeComparison(startDate, endDate)) {
        return trans('errors.dateIsAfter', {
            attribute: trans('errors.attributes.startDate'),
            comparisonDate: formatDate(endDate, dateNotation),
        });
    }

    return undefined;
};

export const validateJobStartDate = (startDate: Date): FormError => {
    if (!dateHasOccurred(startDate)) {
        return trans('errors.futureDate', {
            attribute: trans('errors.attributes.startDate'),
        });
    }

    return undefined;
};

export const validateEndDate = (endDate?: Date, startDate?: Date, dateNotation?: string): FormError => {
    if (!endDate) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.endDate'),
        });
    }

    if (!dateIsValid(endDate)) {
        return trans('errors.invalidDate', {
            attribute: trans('errors.attributes.endDate'),
        });
    }

    if (startDate && !dateIsValid(startDate)) {
        return trans('errors.incomparableDate', {
            attribute: trans('errors.attributes.endDate'),
        });
    }

    if (startDate && !dateIsEqualOrAfterComparison(endDate, startDate)) {
        return trans('errors.dateIsBefore', {
            attribute: trans('errors.attributes.endDate'),
            comparisonDate: formatDate(startDate, dateNotation),
        });
    }

    return undefined;
};

export const validateDateOfBirthString = (attribute: string, dateString: string): FormError => {
    const date = new Date(dateString);
    const dateOfBirthLimitYear = 1900;
    const dateOfBirthLimit = new Date(dateOfBirthLimitYear, 0, 1);

    if (!dateIsValid(date)) {
        return trans('errors.invalidDate', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!dateHasOccurred(date)) {
        return trans('errors.futureDate', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!dateIsEqualOrAfterComparison(date, dateOfBirthLimit)) {
        return trans('errors.dateIsTooOld', {
            attribute: trans(`errors.attributes.${attribute}`),
            year: String(dateOfBirthLimitYear),
        });
    }

    return undefined;
};

export const validateMinimumArrayLength = <Type>(attribute: string, array: Type[], minimumLength: number): FormError => {
    if (!arrayHasMinimumLength(array, minimumLength)) {
        return trans('errors.minimumRequired', {
            attribute: trans(`errors.attributes.${attribute}`),
            amount: String(minimumLength),
        });
    }

    return undefined;
};

export const validateSelectedSearchableValueOption = (attribute: string, selectedOption: SearchableOption): FormError => {
    if (!stringContainsValue(selectedOption.value) || !stringContainsValue(selectedOption.label)) {
        return trans('errors.invalidSearchableValueOption', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};
