import trans from '../../helpers/trans';
import { getCounselorOptionsApiCall } from '../../models/Counselor/CounselorService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setOptions } from './counselorOptions';

export const fetchCounselorOptions = (organisationUuid: string, searchQuery: string = '') => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const counselorOptionsResponse = await getCounselorOptionsApiCall(organisationUuid, searchQuery);

        if (!isFetchResultSuccessful(counselorOptionsResponse)) {
            dispatch(setError(counselorOptionsResponse.error));
            return;
        }

        const counselorOptions = counselorOptionsResponse.data;

        if (counselorOptions.length === 0) {
            dispatch(setError(trans('errors.noResultsFoundFor', { searchQuery })));
            return;
        }

        dispatch(setOptions(counselorOptions));
    } catch (error) {
        console.error('[fetchCounselorOptions]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
