import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { OrganisationFilterValues, OrganisationOverview, OrganisationOverviewResource } from './OrganisationOverview';
import { transformToOrganisationOverview } from './OrganisationOverviewTransformers';

export const getOrganisationOverviewApiCall = async (activeFilterValues: OrganisationFilterValues): Promise<FetchResult<OrganisationOverview, string>> => {
    try {
        const { pageNumber, pageSize } = activeFilterValues;

        const response = await lloFetch(`/api/organisation?pageNumber=${pageNumber}&pageSize=${pageSize}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationOverviewResponse: OrganisationOverviewResource = await response.json();

        const organisationOverview = transformToOrganisationOverview(organisationOverviewResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisationOverview,
        };
    } catch (error) {
        console.error('[getOrganisationOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
