import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { DialogContent } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { Candidate } from '../../../../../models/Candidate/Candidate';
import { TmaAnalysisType } from '../../../../../models/TmaAnalysis/TmaAnalysis';
import { MessageState } from '../../../../../types';

import './TmaAnalysisSuccessMessage.scss';

interface TmaAnalysisSuccessMessageProps {
    candidate?: Candidate;
    tmaAnalysisType: TmaAnalysisType;
    onConfirm: () => void;
    className?: string;
}

const TmaAnalysisSuccessMessage: FC<TmaAnalysisSuccessMessageProps> = ({
    candidate,
    tmaAnalysisType,
    onConfirm,
    className = '',
}): ReactElement => (
    <DialogContent
        title={trans('containers.skillsPassportHeader.tmaAnalysisSettings.successTitle')}
        titleIcon="check"
        messageState={MessageState.positive}
        className={`tma-analysis-success-message ${className}`}
    >
        <p className="tma-analysis-success-message__paragraph">
            {trans('containers.skillsPassportHeader.tmaAnalysisSettings.successText', {
                analysisType: tmaAnalysisType,
                candidateFirstName: candidate?.firstName,
            })}
        </p>

        <div className="tma-analysis-success-message__button-wrapper">
            <Button
                text={trans('containers.skillsPassportHeader.tmaAnalysisSettings.successConfirm')}
                onClick={onConfirm}
            />
        </div>
    </DialogContent>
);

export default TmaAnalysisSuccessMessage;
