import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type DevelopmentPlanSkillState = AsyncReduxState<{
    updatingSkillUuid: string;
}>;

const initialState: DevelopmentPlanSkillState = {
    ...initialAsyncReduxState,
    updatingSkillUuid: '',
};

export const developmentPlanSkillSlice = createSlice({
    name: 'developmentPlanSkillReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DevelopmentPlanSkillState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setUpdatingSkillUuid(state, action: PayloadAction<string>): DevelopmentPlanSkillState {
            return {
                ...state,
                updatingSkillUuid: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DevelopmentPlanSkillState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DevelopmentPlanSkillState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setIsSuccessful,
    setUpdatingSkillUuid,
} = developmentPlanSkillSlice.actions;

export default developmentPlanSkillSlice.reducer;
