import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getLearningPathsApiCall } from '../../services/LearningPathsService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setLearningPaths } from './learningPaths';

export const fetchLearningPaths = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const learningPathsResponse = await getLearningPathsApiCall();

        if (!isFetchResultSuccessful(learningPathsResponse)) {
            dispatch(setError(learningPathsResponse.error));
            return;
        }

        dispatch(setLearningPaths(learningPathsResponse.data));
    } catch (error) {
        console.error('[fetchLearningPaths]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
