import { FC, ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { RoutePaths } from '../../Routes';

const Root: FC = (): ReactElement => (
    <Navigate to={RoutePaths.dashboard()} />
);

export default Root;
