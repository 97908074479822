import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './VacancyMatchingCompetenciesSkeletons.scss';

export interface VacancyMatchingCompetenciesSkeletonsProps {
    className?: string;
}

const VacancyMatchingCompetenciesSkeletons: FC<VacancyMatchingCompetenciesSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const competenciesSkeletons = generateIdArray(3);

    return (
        <>
            <Skeleton className="vacancy-matching-competencies-skeletons__title" />
            <ul className={`vacancy-matching-competencies-skeletons ${className}`}>
                {competenciesSkeletons.map(competency => (
                    <div key={competency} className="vacancy-matching-competencies-skeletons__tag" />
                ))}
            </ul>
        </>
    );
};

export default VacancyMatchingCompetenciesSkeletons;
