import { Occupation, OccupationResource } from '../Occupations/Occupations';

export enum OccupationSkillImportanceType {
    essential = 'essential',
    optional = 'optional',
}

export interface OccupationSkill {
    id: string;
    label: string;
    importanceType: OccupationSkillImportanceType;
}

export interface OccupationDetailResource extends OccupationResource {
    alternativeNames?: string[];
    skills: OccupationSkill[];
}

export interface OccupationDetail extends Occupation {
    alternativeNames: string[];
    essentialSkills: OccupationSkill[];
    optionalSkills: OccupationSkill[];
}

export const defaultOccupationDetail = (): OccupationDetail => ({
    id: '',
    name: '',
    alternativeNames: [],
    description: '',
    totalSkills: 0,
    matchedSkills: 0,
    matchedPercentage: 0,
    essentialSkills: [],
    optionalSkills: [],
});
