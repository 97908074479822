import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { OrganisationFormData } from '../../models/Organisation/Organisation';
import {
    deleteOrganisationApiCall,
    getOrganisationApiCall,
    getOrganisationOptionsApiCall,
    patchOrganisationApiCall,
    postOrganisationApiCall,
} from '../../models/Organisation/OrganisationService';
import { transformToAddOrganisationRequest, transformToEditOrganisationRequest } from '../../models/Organisation/OrganisationTransformers';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
    setOptions,
    setOptionsError,
    setOrganisation,
} from './organisation';

export const fetchOrganisation = (organisationId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const organisationResponse = await getOrganisationApiCall(organisationId);

        if (!isFetchResultSuccessful(organisationResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setOrganisation(organisationResponse.data));
    } catch (error) {
        console.error('[fetchOrganisation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createOrganisation = (formData: OrganisationFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const addOrganisationRequest = transformToAddOrganisationRequest(formData);

        const organisationResponse = await postOrganisationApiCall(addOrganisationRequest);

        if (!isFetchResultSuccessful(organisationResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(setOrganisation(organisationResponse.data));
    } catch (error) {
        console.error('[createOrganisation]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const updateOrganisation = (formData: OrganisationFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const editOrganisationRequest = transformToEditOrganisationRequest(formData);

        const organisationResponse = await patchOrganisationApiCall(editOrganisationRequest);

        if (!isFetchResultSuccessful(organisationResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(setOrganisation(organisationResponse.data));
    } catch (error) {
        console.error('[updateOrganisation]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const deleteOrganisation = (organisationId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const organisationResponse = await deleteOrganisationApiCall(organisationId);

        if (!isFetchResultSuccessful(organisationResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteOrganisation]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const fetchOrganisationOptions = (searchQuery: string = '') => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setOptionsError(''));

    try {
        const organisationOptionsResponse = await getOrganisationOptionsApiCall(searchQuery);

        if (!isFetchResultSuccessful(organisationOptionsResponse)) {
            dispatch(setOptionsError(organisationOptionsResponse.error));
            return;
        }

        const organisationOptions = organisationOptionsResponse.data;

        if (organisationOptions.length === 0) {
            dispatch(setOptionsError(trans('errors.noResultsFoundFor', { searchQuery })));
            return;
        }

        dispatch(setOptions(organisationOptions));
    } catch (error) {
        console.error('[fetchOrganisationOptions]', error);
        dispatch(setOptionsError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
