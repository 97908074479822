import { Params } from 'react-router-dom';

import { UserModule } from '../models/Modules/Modules';
import { NavigationItemProps, NavigationRoute, navigationRoutes } from '../Routes';

interface ParamReplacement {
    param: string;
    value: string;
}

type ParsedQueryParams = Record<string, string | string[] | null>;

const routeParam = (key: string): string => `:${key}`;

export const setParams = (routePath: string, replacements: ParamReplacement[]): string => {
    let replacedRoute = routePath;

    for (let i = 0; i < replacements.length; i += 1) {
        const param = new RegExp(routeParam(replacements[i].param), 'g');

        replacedRoute = replacedRoute.replace(param, replacements[i].value);
    }

    return replacedRoute;
};

export const resetParams = (routePath: string, replacements: ParamReplacement[]): string => {
    let replacedRoute = routePath;

    for (let i = 0; i < replacements.length; i += 1) {
        if (replacements[i].value === '*') {
            // eslint-disable-next-line no-continue
            continue;
        }

        const param = new RegExp(replacements[i].value, 'g');

        replacedRoute = replacedRoute.replace(param, routeParam(replacements[i].param));
    }

    return replacedRoute;
};

export const setNavigationParams = (navRoute: NavigationRoute, params: Readonly<Params>): NavigationRoute => {
    const paramReplacements: ParamReplacement[] = Object.keys(params).map(replacement => ({
        param: replacement || '',
        value: params[replacement] || '',
    }));

    return {
        ...navRoute,
        to: setParams(navRoute.to as string, paramReplacements),
    };
};

export const getCurrentRoute = (pathname: string, params: Readonly<Params>): NavigationRoute | undefined => navigationRoutes
    .find(route => {
        const paramReplacements: ParamReplacement[] = Object.keys(params).map(replacement => ({
            param: replacement || '',
            value: params[replacement] || '',
        }));

        return route.to === resetParams(pathname, paramReplacements);
    });

export const parseQueryParams = (queryString: string): ParsedQueryParams => {
    const params = new URLSearchParams(queryString);
    const obj: ParsedQueryParams = {};

    Array.from(params.keys()).forEach(key => {
        obj[key] = params.getAll(key).length > 1
            ? params.getAll(key)
            : params.get(key);
    });

    return obj;
};

export const removeDisabledModulesFromNavigation = (userNavigationRoutes: NavigationItemProps[], userModules: UserModule[]): NavigationItemProps[] => {
    const disabledModules = userModules.filter(module => !module.isActive);

    if (disabledModules.length > 0) {
        return userNavigationRoutes.filter(route => !disabledModules.some(module => route.module === module.label));
    }

    return userNavigationRoutes;
};
