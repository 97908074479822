import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddDevelopmentPlanCompetencyRequest,
    DevelopmentPlanCompetency,
    DevelopmentPlanCompetencyResource,
    EditDevelopmentPlanCompetencyRequest,
} from './DevelopmentPlanCompetency';
import { transformToDevelopmentPlanCompetency } from './DevelopmentPlanCompetencyTransformer';

// eslint-disable-next-line max-len
export const postDevelopmentPlanCompetencyApiCall = async (requestData: AddDevelopmentPlanCompetencyRequest, candidateUuid: string): Promise<FetchResult<DevelopmentPlanCompetency, string>> => {
    try {
        const response = await lloFetch('/api/developmentplancompetency/', {
            candidateUuid,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanCompetencyResource;

        const developmentPlanCompetency = transformToDevelopmentPlanCompetency(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanCompetency,
        };
    } catch (error) {
        console.error('[postDevelopmentPlanCompetencyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

// eslint-disable-next-line max-len
export const patchDevelopmentPlanCompetencyApiCall = async (requestData: EditDevelopmentPlanCompetencyRequest, competencyUuid: string, candidateUuid: string): Promise<FetchResult<DevelopmentPlanCompetency, string>> => {
    try {
        const response = await lloFetch(`/api/developmentplancompetency/${competencyUuid}`, {
            candidateUuid,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanCompetencyResource;

        const developmentPlanCompetency = transformToDevelopmentPlanCompetency(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanCompetency,
        };
    } catch (error) {
        console.error('[patchDevelopmentPlanCompetencyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDevelopmentPlanCompetencyApiCall = async (competencyUuid: string, candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/developmentplancompetency/${competencyUuid}`, {
            candidateUuid,
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDevelopmentPlanCompetencyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
