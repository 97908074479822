import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { longDebounceDelay } from '../../constants';
import { CandidatePersonalDataForm } from '../../containers';
import { candidateNeedsToUpdatePersonalData } from '../../helpers/registration';
import { Address, defaultAddress } from '../../models/Address/Address';
import { CandidatePersonalDataFormData } from '../../models/Candidate/Candidate';
import { fetchAddress, resetAddressError } from '../../redux/address/addressActions';
import { fetchCandidate, updateCandidate } from '../../redux/candidate/candidateActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { RoutePaths } from '../../Routes';
import { validateFullPostalCode } from '../../services/ValidationService';

interface ConnectedCandidatePersonalDataFormProps {
    isRegistration?: boolean;
    submitButtonLabel?: string;
    className?: string;
}

const ConnectedCandidatePersonalDataForm: FC<ConnectedCandidatePersonalDataFormProps> = ({
    isRegistration,
    submitButtonLabel,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const user = useTypedSelector(state => state.userReducer.user);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidateError = useTypedSelector(state => state.candidateReducer.error);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const addressIsLoading = useTypedSelector(state => state.addressReducer.isLoading);
    const addressError = useTypedSelector(state => state.addressReducer.error);
    const address = useTypedSelector(state => state.addressReducer.address);

    const [addressValue, setAddressValue] = useState<Address>(defaultAddress());

    useEffectOnce((): void => {
        if (user?.uuid) {
            dispatch(fetchCandidate(user.uuid));
            dispatch(resetAddressError());
        }
    });

    useEffect((): void => {
        if (!candidate && user?.uuid) {
            dispatch(fetchCandidate(user.uuid));
        }
    }, [candidate, user, dispatch]);

    useEffect((): void => {
        const isValidCandidate = !candidateError && !candidateNeedsToUpdatePersonalData(candidate);

        if (isRegistration && !candidateIsLoading && isValidCandidate) {
            navigate(RoutePaths.candidateDashboard(candidate?.uuid), { replace: true });
        }
    }, [candidateError, candidate, isRegistration, candidateIsLoading, navigate]);

    useEffect((): () => void => {
        const timer = setTimeout((): void => {
            const hasErrors = validateFullPostalCode(addressValue);

            if (!hasErrors) {
                const {
                    postalCode = '',
                    houseNumber = '',
                } = addressValue;

                dispatch(fetchAddress(postalCode, houseNumber));
            }
        }, longDebounceDelay);

        return (): void => clearTimeout(timer);
    }, [addressValue, dispatch]);

    const handleSubmit = (formData: CandidatePersonalDataFormData): void => {
        if (!candidate) return;

        const updatedCandidate = {
            ...candidate,
            ...formData,
        };

        dispatch(updateCandidate(updatedCandidate));
    };

    const handleLogout = (): void => navigate(RoutePaths.logout());

    return (
        <CandidatePersonalDataForm
            candidateIsLoading={candidateIsLoading}
            addressIsLoading={addressIsLoading}
            candidate={candidate}
            addressResult={address}
            error={candidateError}
            addressError={addressError}
            submitButtonLabel={submitButtonLabel}
            onAddressChange={setAddressValue}
            onSubmit={handleSubmit}
            onLogout={isRegistration ? handleLogout : undefined}
            className={className}
        />
    );
};

export default ConnectedCandidatePersonalDataForm;
