import { FC, ReactElement } from 'react';

import { NavigationRoute } from '../../Routes';
import { TabsNavigationItem } from './subcomponents';

import './TabsNavigation.scss';

export interface TabsNavigationItem extends Omit<NavigationRoute, 'icon'> {
    hasIndicator?: boolean;
}

interface NavigationProps {
    navigationLabel: string;
    navigationItems: TabsNavigationItem[];
    className?: string;
}

const TabsNavigation: FC<NavigationProps> = ({
    navigationLabel,
    navigationItems,
    className = '',
}): ReactElement => (
    <nav aria-label={navigationLabel} className={`tabs-navigation ${className}`}>
        <ul className="tabs-navigation__list">
            {navigationItems.map(navItem => (
                <TabsNavigationItem
                    {...navItem}
                    key={navItem.key}
                    className="tabs-navigation__list-item"
                />
            ))}
        </ul>
    </nav>
);

export default TabsNavigation;
