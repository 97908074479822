import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    FormActionButtons,
    Notice,
    Textarea,
    TextInput,
} from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { OrganisationOccupation, OrganisationOccupationFormData } from '../../../models/OrganisationOccupation/OrganisationOccupation';
import { DialogFormProps, NoticeType } from '../../../types';
import { OrganisationOccupationFormErrors, validateOrganisationOccupationFormData } from './validations';

import './OrganisationOccupationForm.scss';

interface OrganisationOccupationFormProps extends DialogFormProps<OrganisationOccupationFormData> {
    occupation?: OrganisationOccupation;
    className?: string;
}

const OrganisationOccupationForm: FC<OrganisationOccupationFormProps> = ({
    isLoading,
    error = '',
    occupation,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<OrganisationOccupationFormErrors>({});

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (occupation?.title) setTitle(occupation.title);
        if (occupation?.description) setDescription(occupation.description);
    }, [occupation]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const skills = occupation
            ? occupation.skills.map(skill => skill.id)
            : [];

        const competencies = occupation
            ? occupation.competencies.map(competency => competency.id)
            : [];

        const formData = {
            id: occupation?.id || '',
            title,
            description,
            skills,
            competencies,
        };

        const [errors, hasErrors] = validateOrganisationOccupationFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`organisation-occupation-form ${className}`}>
            <TextInput
                required
                label={trans('forms.organisationOccupationForm.input.title')}
                value={title}
                error={formErrors.title}
                onChange={setTitle}
                className="organisation-occupation-form__input"
            />

            <Textarea
                required
                label={trans('forms.organisationOccupationForm.input.description')}
                value={description}
                error={formErrors.description}
                rows={8}
                onChange={setDescription}
                className="organisation-occupation-form__input"
            />

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="organisation-occupation-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="organisation-occupation-form__action-buttons"
            />
        </form>
    );
};

export default OrganisationOccupationForm;
