/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */
import { defaultPageSize } from '../Pagination';

export interface EducationDocument {
    id: string;
    compositeId: string;
    productReference: string;
    accountId: string;
    accountName: string;
    assortmentId: string;
    assortmentName: string;
    title: string;
    searchableTitle: string;
    endDate: string | null;
    content: string;
    level: string;
    level_nl: string;
    courseDuration: number;
    courseDurationInDays: number;
    courseDurationUnit: string;
    providerName: string;
    providerLogoUrl: string;
    providerId: string;
    startMoments: string[];
    locations: string[];
    domain: string;
    domainName: string;
    variants: string[];
    variants_nl: string[];
    learningMethodType: string;
    learningMethodTypeDisplayValue: string;
    learningMethodFormat: string;
    learningMethodFormatDisplayValue: string[];
    amountWithVatAndWithDiscount: number;
    amountWithoutVatWithDiscount: number;
    amountWithVatWithoutDiscount: number;
    amountWithoutVatWithoutDiscount: number;
    vatWithDiscount: number;
    vatWithoutDiscount: number;
    discountWithVat: number;
    discountWithoutVat: number;
    totalAmountWithVatWithDiscountWithSubsidy: number | null;
    rating: number | null;
    reviewCount: number | null;
    externalId: string;
    subsidies: string[];
    subsidiesDisplayValue: string[];
    segments: string[];
    segmentsDisplayValue: string[];
    subSegments: string[];
    subSegmentsDisplayValue: string[];
    productType: string;
    productTypeDisplayValue: string;
    dateIndexUpdated: string;
}

export interface EducationResult {
    highlights: any;
    score: number;
    document: EducationDocument;
}

export enum EducationFacetProperty {
    level_nl = 'level_nl',
    domainName = 'domainName',
    providerName = 'providerName',
    startMoments = 'startMoments',
    variants_nl = 'variants_nl',
    subsidies = 'subsidies',
    locations = 'locations',
    courseDurationInDays = 'courseDurationInDays',
}

export interface EducationFacet {
    count: number;
    from: unknown;
    to: unknown;
    type: number;
    value: string | number;
}

export interface EducationResource {
    continuationToken: any;
    count: number;
    coverage: number | null;
    facets: Record<EducationFacetProperty, EducationFacet[]>;
    results: EducationResult[];
}

export interface Education {
    uuid: string;
    isQualifiedForSTAP: boolean;
    minimumPrice: string;
    logo: string;
    title: string;
    educator: string;
    properties: string[];
    description: string;
}

export interface EducationFilterValues {
    pageNumber: number;
    pageSize: number;
    searchQuery: string;
    educationLevel: string[];
    fieldOfStudy: string[];
    educator: string[];
    startMoment: string[];
    dayPart: string[];
    subsidy: string[];
    location: string[];
    duration: string[];
}

export const defaultEducationFilterValues = (): EducationFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
    searchQuery: '',
    educationLevel: [],
    fieldOfStudy: [],
    educator: [],
    startMoment: [],
    dayPart: [],
    subsidy: [],
    location: [],
    duration: [],
});

export interface EducationFilterParams {
    search: string;
    filter?: string;
    facets?: string[];
    top: number;
    skip: number;
    orderBy: string[];
}
/* eslint-enable @typescript-eslint/no-explicit-any, camelcase */
