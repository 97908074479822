import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Card, CircleProgress, SafeHtml } from '../../../components';
import { Dialog, IconButton, Notice } from '../../../compositions';
import { ComparedOccupationSkills } from '../../../connectors/@occupation/ConnectedOccupationDetailHeader/helpers';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { getRoleForTranslation } from '../../../helpers/role';
import { capitalizeFirstCharacter } from '../../../helpers/string';
import trans from '../../../helpers/trans';
import { OccupationDetail } from '../../../models/OccupationDetail/OccupationDetail';
import { NoticeType, UserRole } from '../../../types';
import { DevelopmentPlanWithSkillsForm } from '../..';
import { DevelopmentPlanWithSkillsFormData } from '../../@forms/DevelopmentPlanWithSkillsForm/DevelopmentPlanWithSkillsForm';
import { OccupationDetailHeaderSkeletons } from './skeletons';

import './OccupationDetailHeader.scss';

interface OccupationDetailHeaderProps {
    pageIsLoading: boolean;
    developmentPlanFormIsLoading: boolean;
    occupationError: string;
    developmentPlanFormError: string;
    userRoles?: UserRole[];
    occupation: OccupationDetail;
    comparedOccupationSkills: ComparedOccupationSkills;
    onAddDevelopmentPlan: (formData: DevelopmentPlanWithSkillsFormData) => void;
    className?: string;
}

const OccupationDetailHeader: FC<OccupationDetailHeaderProps> = ({
    pageIsLoading,
    developmentPlanFormIsLoading,
    occupationError,
    developmentPlanFormError,
    userRoles = [],
    occupation,
    comparedOccupationSkills,
    onAddDevelopmentPlan,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [developmentPlanWithSkillsFormKey, setDevelopmentPlanWithSkillsFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setDevelopmentPlanWithSkillsFormKey(developmentPlanWithSkillsFormKey + 1);
    };

    return (
        <div className={`occupation-detail-header ${className}`}>
            {pageIsLoading && <OccupationDetailHeaderSkeletons />}

            {!pageIsLoading && occupationError && (
                <Notice
                    type={NoticeType.error}
                    text={trans('containers.occupationDetail.error')}
                />
            )}

            {!pageIsLoading && !occupationError && (
                <Card className="occupation-detail-header__card">
                    <div className="occupation-detail-header__text">
                        <h2 className="occupation-detail-header__name">{occupation.name}</h2>
                        <p className="occupation-detail-header__description">{occupation.description}</p>

                        {occupation.alternativeNames.length > 0 && (
                            <div className="occupation-detail-header__alternative-names">
                                <h3 className="occupation-detail-header__alternative-names-label">
                                    {trans('containers.occupationDetail.alternativeNames')}
                                </h3>

                                <ul className="occupation-detail-header__list">
                                    {occupation.alternativeNames.map(alternativeName => (
                                        <li key={alternativeName} className="occupation-detail-header__list-item">
                                            {capitalizeFirstCharacter(alternativeName)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="occupation-detail-header__matched-skills">
                        <CircleProgress
                            showPercentage
                            icon="occupation"
                            finishedSteps={occupation.matchedSkills}
                            totalSteps={occupation.totalSkills}
                            className="occupation-detail-header__progress-circle"
                        />

                        <SafeHtml
                            html={trans(`containers.occupationDetail.progressDescription.${getRoleForTranslation(userRoles)}`, {
                                matchedSkills: String(occupation.matchedSkills),
                                totalSkills: String(occupation.totalSkills),
                            })}
                            className="occupation-detail-header__progress-description"
                        />

                        <IconButton
                            icon="plus"
                            text={trans('containers.occupationDetail.createDevelopmentPlan')}
                            onClick={handleOpenDialog}
                            className="occupation-detail-header__create-development-plan"
                        />
                    </div>

                    <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                        <DevelopmentPlanWithSkillsForm
                            key={developmentPlanWithSkillsFormKey}
                            isLoading={developmentPlanFormIsLoading}
                            error={developmentPlanFormError}
                            essentialSkillsOptions={comparedOccupationSkills.notMatchedEssential}
                            optionalSkillsOptions={comparedOccupationSkills.notMatchedOptional}
                            onSubmit={onAddDevelopmentPlan}
                            onCancel={handleCloseDialog}
                        />
                    </Dialog>
                </Card>
            )}
        </div>
    );
};

export default OccupationDetailHeader;
