/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconMessageOpen: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M97.1,2.9C95.2,1.1,92.7,0,90,0H10C4.5,0,0,4.5,0,10v90l20-20h70c2.7,0,5.2-1.1,7.1-2.9c1.9-1.9,2.9-4.4,2.9-7.1V10C100,7.3,98.9,4.8,97.1,2.9z M90,70H15.9L10,75.8V10h80V70z" />
        <rect x="20" y="25" width="60" height="10" />
        <rect x="20" y="45" width="45" height="10" />
    </svg>
);

export default IconMessageOpen;
/* eslint-enable max-len */
