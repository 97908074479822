import { JobExperienceFormData } from '../../../../models/JobExperience/JobExperience';
import {
    FormErrors,
    FormValidation,
    validateEndDate,
    validateForm,
    validateJobStartDate,
    validateRequiredString,
    validateShortDescription,
    validateStartDate,
} from '../../../../services/ValidationService';

export type JobExperienceFormErrors = FormErrors<JobExperienceFormData>;

export const validateJobExperienceFormData = (formData: JobExperienceFormData): FormValidation<JobExperienceFormErrors> => {
    const dateNotation = 'MMMM yyyy';

    const errors: JobExperienceFormErrors = {
        position: validateRequiredString('position', formData.position),
        company: validateRequiredString('company', formData.company),
        startDate: validateStartDate(formData.startDate, formData.endDate, dateNotation) || validateJobStartDate(formData.startDate),
        endDate: !formData.isCurrentJob
            ? validateEndDate(formData.endDate, formData.startDate, dateNotation)
            : undefined,
        description: validateShortDescription('description', formData.description),
    };

    return validateForm<JobExperienceFormErrors>(errors);
};
