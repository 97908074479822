import { FC, ReactElement, useEffect } from 'react';

import { VacancyMatchingFilter } from '../../../containers';
import useUnmount from '../../../hooks/useUnmount';
import { Candidate } from '../../../models/Candidate/Candidate';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { SkillsFormData } from '../../../models/Skills/Skills';
import { User } from '../../../models/User/User';
import { setSuggestedSkills } from '../../../redux/escoOccupations/escoOccupations';
import { clearEscoSuggestions, fetchEscoOccupations, fetchSuggestedSkills } from '../../../redux/escoOccupations/escoOccupationsActions';
import { setActiveFilterValues } from '../../../redux/matchingVacancies/matchingVacancies';
import { fetchSkills, updateCandidateSkills } from '../../../redux/skills/skillsActions';
import { fetchSkillsPassport } from '../../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { CompetencyLevel } from '../../../types';

interface ConnectedVacancyMatchingFilterProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onUserResponse: (user: User) => void;
    className?: string;
}

const ConnectedVacancyMatchingFilter: FC<ConnectedVacancyMatchingFilterProps> = ({
    onCandidateResponse,
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const activeFilterValues = useTypedSelector(state => state.matchingVacanciesReducer.activeFilterValues);

    const user = useTypedSelector(state => state.userReducer.user);
    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const candidateSkills = useTypedSelector(state => state.skillsPassportReducer.skills);
    const candidateCompetencies = useTypedSelector(state => state.skillsPassportReducer.competencies);

    const skillsIsLoading = useTypedSelector(state => state.skillsReducer.isLoading);
    const skillsIsUpdating = useTypedSelector(state => state.skillsReducer.isUpdating);
    const skillsError = useTypedSelector(state => state.skillsReducer.error);
    const skills = useTypedSelector(state => state.skillsReducer.skills);

    const escoOccupationsIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const escoOccupations = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const suggestedSkills = useTypedSelector(state => state.escoOccupationsReducer.suggestedSkills);

    useUnmount((): void => {
        dispatch(clearEscoSuggestions());
    });

    useEffect((): void => {
        if (skills.length === 0) dispatch(fetchSkills());
        if (escoOccupations.length === 0) dispatch(fetchEscoOccupations());
    }, []);

    useEffect((): void => {
        if (candidate?.uuid) {
            dispatch(fetchSkillsPassport(candidate.uuid));
        }
    }, [dispatch, candidate]);

    useEffect((): void => {
        if (candidateSkills.length > 0 || candidateCompetencies) {
            const highTalents = candidateCompetencies?.[CompetencyLevel.high]
                ? candidateCompetencies[CompetencyLevel.high].map(competency => competency.label)
                : [];

            const regularTalents = candidateCompetencies?.[CompetencyLevel.regular]
                ? candidateCompetencies[CompetencyLevel.regular].map(competency => competency.label)
                : [];

            dispatch(setActiveFilterValues({
                ...activeFilterValues,
                ...(activeFilterValues.skills.length === 0 && { skills: candidateSkills.map(skill => skill.name) }),
                competencies: [...highTalents, ...regularTalents],
            }));
        }
    }, [candidateSkills, candidateCompetencies]);

    useEffect((): void => {
        if (user) {
            onUserResponse(user);
        }
    }, [user]);

    useEffect((): void => {
        if (candidate) {
            onCandidateResponse(candidate);
        }
    }, [candidate]);

    const handleSelectOccupation = (occupation: EscoOccupation): void => {
        dispatch(fetchSuggestedSkills(occupation.id));
    };

    const handleUpdateSkills = (skillsFormData: SkillsFormData): void => {
        dispatch(setActiveFilterValues({
            ...activeFilterValues,
            skills: skillsFormData.skills.map(skill => skill.name),
        }));

        dispatch(clearEscoSuggestions());
    };

    const handleSaveSkillsToPassport = (skillsFormData: SkillsFormData): void => {
        if (candidate?.uuid) {
            dispatch(updateCandidateSkills(skillsFormData, candidate.uuid));
            dispatch(clearEscoSuggestions());
        }
    };

    const handleCloseSkillsDialog = (): void => {
        dispatch(setSuggestedSkills([]));
    };

    const isLoading = [
        candidateIsLoading,
        skillsIsLoading,
        escoOccupationsIsLoading,
        userIsLoading,
        skillsIsUpdating,
    ].some(Boolean);

    return (
        <VacancyMatchingFilter
            isLoading={isLoading}
            userRoles={user?.roles}
            skills={activeFilterValues.skills}
            suggestedSkills={suggestedSkills}
            skillOptions={skills}
            skillsError={skillsError}
            activeCompetencies={candidateCompetencies}
            occupationOptions={escoOccupations}
            onSelectOccupation={handleSelectOccupation}
            onUpdateSkills={handleUpdateSkills}
            onSaveSkillsToPassport={handleSaveSkillsToPassport}
            onCloseSkillsDialog={handleCloseSkillsDialog}
            className={className}
        />
    );
};

export default ConnectedVacancyMatchingFilter;
