import { FC, ReactElement } from 'react';

import { Button } from '../../../components';
import { IconName } from '../../../components/Icon/Icon';
import trans from '../../../helpers/trans';
import { ButtonList, SubmitButton } from '../..';

import './FormActionButtons.scss';

interface FormActionButtonsProps {
    isLoading?: boolean;
    submitIcon?: IconName;
    submitText?: string;
    disabled?: boolean;
    onCancelClick: () => void;
    className?: string;
}

const FormActionButtons: FC<FormActionButtonsProps> = ({
    isLoading,
    submitIcon,
    submitText = trans('common.save'),
    disabled,
    onCancelClick,
    className = '',
}): ReactElement => (
    <ButtonList className={`form-action-buttons ${className}`}>
        <SubmitButton
            isLoading={isLoading}
            icon={submitIcon}
            text={submitText}
            disabled={disabled}
            className="form-action-buttons__button"
        />
        {/* TODO: Do we need to cancel submit action on cancel click? Cancel only closes modal, in most cases now */}
        <Button
            text={trans('common.cancel')}
            onClick={onCancelClick}
            className="form-action-buttons__button form-action-buttons__button--cancel"
        />
    </ButtonList>
);

export default FormActionButtons;
