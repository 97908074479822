/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconDisk: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M77.78,0H11.11C4.94,0,0,5,0,11.11v77.78C0,95,4.94,100,11.11,100h77.78C95,100,100,95,100,88.89V22.22L77.78,0z M88.89,88.89H11.11V11.11h62.06l15.72,15.72V88.89z M50,50c-9.22,0-16.67,7.44-16.67,16.67S40.78,83.33,50,83.33 s16.67-7.44,16.67-16.67S59.22,50,50,50z M16.67,16.67h50v22.22h-50V16.67z" />
    </svg>
);

export default IconDisk;
/* eslint-enable max-len */
