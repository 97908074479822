import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getResidencesApiCall = async (): Promise<FetchResult<string[], string>> => {
    try {
        const response = await fetch('/residences-nl.json');

        const responseData = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: responseData.data,
        };
    } catch (error) {
        console.error('[getResidencesApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
