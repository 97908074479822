/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconStarCheck: FC<SvgIconProps> = ({ className = '', accentClassName = '' }): ReactElement => (
    <svg viewBox="0 0 100 95.1" className={`icon ${className}`}>
        <path d="M100,47.5L88.9,34.9l1.5-16.7L74,14.5L65.5,0L50,6.6L34.5,0L26,14.5L9.5,18.1l1.5,16.7L0,47.5l11.1,12.6L9.5,77L26,80.7l8.6,14.5L50,88.5l15.5,6.6L74,80.6l16.4-3.7l-1.5-16.7L100,47.5z" />
        <polygon className={accentClassName} points="29.1,45.7 22.7,52.1 40.9,70.3 77.3,33.9 70.9,27.5 40.9,57.4" />
    </svg>
);

export default IconStarCheck;
/* eslint-enable max-len */
