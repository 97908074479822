import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { Sector, SectorsResource, transformResourceToSector } from '../models/Sectors';

export const getSectorsApiCall = async (): Promise<FetchResult<Sector[], string>> => {
    try {
        const response = await talentzFetch('/api/sectors');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<SectorsResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const sectors = doc.data.map(transformResourceToSector);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sectors,
        };
    } catch (error) {
        console.error('[getSectorsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
