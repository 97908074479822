import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    AddingCard,
    CallToActionNullState,
    Dialog,
    DialogContent,
    Loading,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { JobExperience, JobExperienceFormData } from '../../../models/JobExperience/JobExperience';
import { UserRole } from '../../../types';
import { JobExperienceForm } from '../..';
import { JobExperienceItem } from './subcomponents';

import './JobExperiencesCard.scss';

interface JobExperiencesCardProps {
    isLoading?: boolean;
    jobExperienceIsLoading: boolean;
    jobExperienceIsSuccessful: boolean;
    hasError?: boolean;
    jobExperienceError: string;
    userRoles?: UserRole[];
    jobExperiences: JobExperience[];
    onAddJobExperience: (jobExperienceFormData: JobExperienceFormData) => void;
    onEditJobExperience: (jobExperienceFormData: JobExperienceFormData) => void;
    onDeleteJobExperience: (jobExperience: JobExperience) => void;
    onIsSuccessfulReset: () => void;
    className?: string;
}

const JobExperiencesCard: FC<JobExperiencesCardProps> = ({
    isLoading,
    jobExperienceIsLoading,
    jobExperienceIsSuccessful,
    hasError,
    jobExperienceError,
    userRoles = [],
    jobExperiences,
    onAddJobExperience,
    onEditJobExperience,
    onDeleteJobExperience,
    onIsSuccessfulReset,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [jobExperienceFormKey, setJobExperienceFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setJobExperienceFormKey(jobExperienceFormKey + 1);
    };

    const handleNullStateButtonClick = (): void => handleOpenDialog();

    useEffect((): void => {
        if (jobExperienceIsSuccessful) {
            handleCloseDialog();
            onIsSuccessfulReset();
        }
    }, [jobExperienceIsSuccessful]);

    return (
        <AddingCard
            isEditable={userHasRole(UserRole.candidate, userRoles) && jobExperiences.length > 0}
            showFillLabel={jobExperiences.length === 0 && !isLoading}
            title={trans('containers.jobExperiencesCard.title')}
            item={trans('common.jobExperience')}
            userRoles={userRoles}
            onAddClick={handleOpenDialog}
            className={`job-experiences-card ${className}`}
        >
            {isLoading && <Loading className="job-experiences-card__loader" />}

            {!isLoading && jobExperiences.length === 0 && (
                <CallToActionNullState
                    isEditable={!hasError && userHasRole(UserRole.candidate, userRoles)}
                    icon="job-experience"
                    paragraph={trans('containers.jobExperiencesCard.nullState.paragraph')}
                    subParagraph={trans('containers.jobExperiencesCard.nullState.subParagraph')}
                    buttonText={trans('containers.jobExperiencesCard.nullState.callToAction')}
                    onButtonClick={handleNullStateButtonClick}
                    className="job-experiences-card__null-state"
                />
            )}

            {!isLoading && jobExperiences.length > 0 && (
                <ul className="job-experiences-card__job-wrapper">
                    {jobExperiences.map((jobExperience: JobExperience) => (
                        <JobExperienceItem
                            key={jobExperience.id}
                            isLoading={jobExperienceIsLoading}
                            isSuccessful={jobExperienceIsSuccessful}
                            error={jobExperienceError}
                            jobExperience={jobExperience}
                            isEditable={userHasRole(UserRole.candidate, userRoles)}
                            onEdit={onEditJobExperience}
                            onDelete={onDeleteJobExperience}
                            onIsSuccessfulReset={onIsSuccessfulReset}
                        />
                    ))}
                </ul>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent title={trans('forms.jobExperience.addTitle')}>
                    <JobExperienceForm
                        key={jobExperienceFormKey}
                        isLoading={jobExperienceIsLoading}
                        isSuccessful={jobExperienceIsSuccessful}
                        error={jobExperienceError}
                        onSubmit={onAddJobExperience}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </AddingCard>
    );
};

export default JobExperiencesCard;
