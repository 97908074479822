import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { School, SchoolResource, transformResourceToSchool } from '../models/Schools';

export const getSchoolsApiCall = async (): Promise<FetchResult<School[], string>> => {
    try {
        // TODO: Apply correct JSON API data retrieval, like it's done with the FieldsOfStudy
        const response = await talentzFetch('/api/schools/');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<SchoolResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const existingSchools = doc.data.filter(school => !school.attributes.manual);
        const schools = existingSchools.map(transformResourceToSchool);
        const sortedSchools = schools.sort((school, comparisonSchool) => school.name.localeCompare(comparisonSchool.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedSchools,
        };
    } catch (error) {
        console.error('[getSchoolsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
