import { FC, ReactElement } from 'react';

import { PaginationInput } from '..';

interface PaginationInputListProps {
    paginationId: string;
    list: number[];
    activePage: number;
    onChange: (page: number) => void;
}

const PaginationInputList: FC<PaginationInputListProps> = ({
    paginationId,
    list,
    activePage,
    onChange,
}): ReactElement => (
    <>
        {list.map((page) => (
            <PaginationInput
                key={page}
                paginationId={paginationId}
                page={page}
                checked={activePage === page}
                onChange={onChange}
            />
        ))}
    </>
);

export default PaginationInputList;
