import trans from '../../helpers/trans';
import { getEducationFilterOptionsApiCall } from '../../models/EducationFormOptions/EducationFormOptionsService';
import { EducationFilterValues } from '../../models/Educations/Educations';
import { getEducationsApiCall } from '../../models/Educations/EducationsService';
import { transformToEducation } from '../../models/Educations/EducationsTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { transformToPagination } from '../../models/Pagination';
import { TypedDispatch } from '../store';
import {
    setEducations,
    setError,
    setFilterOptions,
    setIsLoading,
    setPagination,
} from './educations';

export const fetchEducations = (candidateUuid: string, activeFilterValues: EducationFilterValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const educationsResponse = await getEducationsApiCall(candidateUuid, activeFilterValues);

        if (!isFetchResultSuccessful(educationsResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const { count, results = [] } = educationsResponse.data;

        const educations = results.map(result => transformToEducation(result.document));

        const currentPage = activeFilterValues.pageNumber;
        const pagination = transformToPagination(count, currentPage);

        dispatch(setEducations(educations));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchEducations]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchEducationFilterOptions = (candidateUuid: string, activeFilterValues: EducationFilterValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const educationsResponse = await getEducationFilterOptionsApiCall(candidateUuid, activeFilterValues);

        if (!isFetchResultSuccessful(educationsResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setFilterOptions(educationsResponse.data));
    } catch (error) {
        console.error('[fetchEducationFilterOptions]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
