import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { SkillsPassportCard } from '../../../compositions';
import { CandidateIllustrationNotification } from '../../../compositions/CandidateIllustration/CandidateIllustration';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { Competency } from '../../../models/Competencies/Competencies';
import { RoutePaths } from '../../../Routes';
import { CompetencyLevel, UserRole } from '../../../types';
import { CompetencyTag } from './subcomponents';

import './CompetenciesCard.scss';

interface CompetenciesCardProps {
    isLoading: boolean;
    isUpToDate: boolean;
    candidate?: Candidate;
    competencies?: Record<string, Competency[]>;
    userRoles?: UserRole[];
    handleRefresh: () => void;
    className?: string;
}

const CompetenciesCard: FC<CompetenciesCardProps> = ({
    isLoading,
    isUpToDate,
    candidate,
    competencies,
    userRoles = [],
    handleRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidate?.uuid));
    };

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={!competencies}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            title={trans(`containers.competenciesCard.title.${getRoleForTranslation(userRoles)}`)}
            description={userRoles.length > 0 ? trans(`containers.competenciesCard.description.${getRoleForTranslation(userRoles)}`) : ''}
            candidateGender={candidate?.gender}
            candidateIllustrationType={CandidateIllustrationNotification.competency}
            nullStateText={trans('containers.competenciesCard.nullState')}
            callToActionButtonLabel={trans('containers.competenciesCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={handleRefresh}
            className={`competencies-card ${className}`}
        >
            {!isLoading && competencies && (
                (Object.keys(CompetencyLevel) as Array<keyof typeof CompetencyLevel>).map(level => {
                    const levelTitleClassNames = classNames('competencies-card__level-title', className, {
                        'competencies-card__level-title--is-positive-level': level === CompetencyLevel.high || level === CompetencyLevel.regular,
                    });

                    return competencies[level] && (
                        <div key={level} className="competencies-card__level">
                            <h3 className={levelTitleClassNames}>
                                {trans(`containers.competenciesCard.competencyLevel.label.${level}`)}
                            </h3>
                            <p className="competencies-card__level-description">
                                {trans(`containers.competenciesCard.competencyLevel.description.${getRoleForTranslation(userRoles)}.${level}`)}
                            </p>

                            <ul className="competencies-card__tag-list">
                                {competencies[level].map(competency => (
                                    <CompetencyTag
                                        key={competency.label}
                                        competency={competency}
                                        className="competencies-card__tag"
                                    />
                                ))}
                            </ul>
                        </div>
                    );
                })
            )}
        </SkillsPassportCard>
    );
};

export default CompetenciesCard;
