import { getServiceWorkerRegistration } from './pwa';

export const clearCaches = async (): Promise<void> => {
    const serviceWorkerRegistration = await getServiceWorkerRegistration();

    if (!serviceWorkerRegistration) {
        window.location.reload();
        return;
    }

    if (caches) {
        caches.keys().then(names => {
            for (let i = 0; i < names.length; i += 1) {
                caches.delete(names[i]);
            }
        });
    }
};
