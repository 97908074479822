import { formatDate } from '../../helpers/date';
import { convertNumberToCurrency } from '../../helpers/number';
import { splitString } from '../../helpers/string';
import trans from '../../helpers/trans';
import { dateIsValid } from '../../helpers/validation';
import {
    Education,
    EducationDocument,
    EducationFilterParams,
    EducationFilterValues,
} from './Educations';

enum EducationDurationUnit {
    days = 'Days',
    months = 'Months',
    years = 'Years',
}

const retrieveEducationDuration = (
    educationDocument: EducationDocument,
): string => {
    const amount = educationDocument.courseDuration;
    const durationUnit = educationDocument.courseDurationUnit;

    if (durationUnit === EducationDurationUnit.days) {
        return amount === 1
            ? trans('models.educations.duration.day', { amount })
            : trans('models.educations.duration.days', { amount });
    }

    if (durationUnit === EducationDurationUnit.months) {
        return amount === 1
            ? trans('models.educations.duration.month', { amount })
            : trans('models.educations.duration.months', { amount });
    }

    if (durationUnit === EducationDurationUnit.years) {
        return trans('models.educations.duration.years', { amount });
    }

    return '';
};

const retrieveEducationFirstStartMoment = (
    educationDocument: EducationDocument,
): string => {
    if (educationDocument.startMoments.length === 0) return '';

    const firstStartMoment = educationDocument.startMoments[0];
    const [year, month] = splitString(firstStartMoment, 4).map(Number);
    const date = new Date();

    date.setFullYear(year);
    date.setMonth(month - 1);

    return dateIsValid(date)
        ? trans('models.educations.firstStartMoment', {
            date: formatDate(date, 'MMMM yyyy'),
        })
        : '';
};

export const transformToEducation = (
    educationDocument: EducationDocument,
): Education => {
    const sortedPrices = [
        educationDocument.amountWithoutVatWithDiscount,
        educationDocument.amountWithoutVatWithoutDiscount,
        educationDocument.amountWithVatAndWithDiscount,
        educationDocument.amountWithVatWithoutDiscount,
    ].filter(Boolean).sort();

    const minimumPrice = sortedPrices.length > 0
        ? trans('models.educations.minimumPrice', { price: convertNumberToCurrency(sortedPrices[0]) })
        : '';

    const duration = retrieveEducationDuration(educationDocument);

    const variants = educationDocument.variants.length > 0
        ? trans('models.educations.variants', { amount: educationDocument.variants.length })
        : '';

    const locations = educationDocument.locations.length > 0
        ? trans('models.educations.locations', { amount: educationDocument.locations.length })
        : '';

    const firstStartMoment = retrieveEducationFirstStartMoment(educationDocument);

    return {
        uuid: educationDocument.id,
        isQualifiedForSTAP: educationDocument.subsidies.includes('STAP'),
        minimumPrice,
        logo: educationDocument.providerLogoUrl,
        title: educationDocument.title,
        educator: educationDocument.providerName,
        properties: [
            educationDocument.level_nl,
            duration,
            variants,
            locations,
            firstStartMoment,
        ].filter(Boolean),
        description: educationDocument.content,
    };
};

export const transformFilterValuesToFilterParams = (
    filterValues: EducationFilterValues,
): EducationFilterParams => {
    const resultsToSkip = (filterValues.pageNumber - 1) * filterValues.pageSize;

    const educationLevelFilters = filterValues.educationLevel.length > 0
        ? filterValues.educationLevel.map(educationLevel => `level_nl eq '${educationLevel}'`).join(' or ')
        : undefined;

    const fieldOfStudyFilters = filterValues.fieldOfStudy.length > 0
        ? filterValues.fieldOfStudy.map(fieldOfStudy => `domainName eq '${fieldOfStudy}'`).join(' or ')
        : undefined;

    const educatorFilters = filterValues.educator.length > 0
        ? filterValues.educator.map(educator => `providerName eq '${educator}'`).join(' or ')
        : undefined;

    const startMomentFilters = filterValues.startMoment.length > 0
        ? `startMoments/any(startMoment: ${filterValues.startMoment.map(startMoment => `startMoment eq '${startMoment}'`).join(' or ')})`
        : undefined;

    const dayPartFilters = filterValues.dayPart.length > 0
        ? `variants_nl/any(variant: ${filterValues.dayPart.map(dayPart => `variant eq '${dayPart}'`).join(' or ')})`
        : undefined;

    const subsidyFilters = filterValues.subsidy.length > 0
        ? `subsidies/any(subsidy: ${filterValues.subsidy.map(subsidy => `subsidy eq '${subsidy}'`).join(' or ')})`
        : undefined;

    const locationFilters = filterValues.location.length > 0
        ? `locations/any(location: ${filterValues.location.map(location => `location eq '${location}'`).join(' or ')})`
        : undefined;

    const durationFilters = filterValues.duration.length > 0
        ? filterValues.duration.map(duration => `courseDurationInDays eq ${duration}`).join(' or ')
        : undefined;

    const filterQuery = [
        educationLevelFilters,
        fieldOfStudyFilters,
        educatorFilters,
        startMomentFilters,
        dayPartFilters,
        subsidyFilters,
        locationFilters,
        durationFilters,
    ].filter(Boolean).join(' and ');

    return {
        search: filterValues.searchQuery,
        filter: filterQuery,
        top: filterValues.pageSize,
        skip: resultsToSkip,
        orderBy: [],
    };
};
