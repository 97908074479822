import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, Notice, Textarea } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { DevelopmentPlanNote, DevelopmentPlanNoteFormData } from '../../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import { DialogFormProps, NoticeType } from '../../../types';
import { DevelopmentPlanNoteFormErrors, validateDevelopmentPlanNoteFormData } from './validations';

import './DevelopmentPlanNoteForm.scss';

interface DevelopmentPlanNoteFormProps extends DialogFormProps<DevelopmentPlanNoteFormData> {
    note?: DevelopmentPlanNote;
    className?: string;
}

const DevelopmentPlanNoteForm: FC<DevelopmentPlanNoteFormProps> = ({
    isLoading,
    isSuccessful,
    error = '',
    note,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<DevelopmentPlanNoteFormErrors>({});

    const [content, setContent] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (note?.content) setContent(note.content);
    }, [note]);

    useEffect((): void => {
        if (isSuccessful) {
            setContent('');
        }
    }, [isSuccessful]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: note?.id || '',
            content,
        };

        const [errors, hasErrors] = validateDevelopmentPlanNoteFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`development-plan-note-form ${className}`}>
            <Textarea
                required
                label={trans('forms.developmentPlanNoteForm.input.content')}
                value={content}
                error={formErrors.content}
                onChange={setContent}
                className="development-plan-note-form__input"
            />

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="development-plan-note-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="development-plan-note-form__action-buttons"
            />
        </form>
    );
};

export default DevelopmentPlanNoteForm;
