import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../components';

import './Breadcrumbs.scss';

export interface Breadcrumb {
    label: string;
    to?: string;
    disabled?: boolean;
}

interface BreadcrumbsProps {
    crumbs: Breadcrumb[];
    className?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
    crumbs,
    className = '',
}): ReactElement => (
    <ol className={`breadcrumbs ${className}`}>
        {crumbs.map((crumb, index) => {
            const isFirstCrumb = index === 0;
            const isFinalCrumb = index === crumbs.length - 1;
            const crumbIsDisabled = crumb.disabled || !crumb.to || isFinalCrumb;

            return (
                <li key={crumb.label} className="breadcrumbs__item">
                    {!isFirstCrumb && (
                        <Icon name="chevron-right" className="breadcrumbs__icon" />
                    )}

                    {crumbIsDisabled ? (
                        <span className="breadcrumbs__label">
                            {crumb.label}
                        </span>
                    ) : (
                        <Link to={crumb.to || ''} className="breadcrumbs__link">
                            {crumb.label}
                        </Link>
                    )}
                </li>
            );
        })}
    </ol>
);

export default Breadcrumbs;
