import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getWorkRemoteOptionsApiCall } from '../../services/WorkRemoteService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setWorkRemoteOptions } from './workRemote';

export const fetchWorkRemoteOptions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const workRemoteOptionsResponse = await getWorkRemoteOptionsApiCall();

        if (!isFetchResultSuccessful(workRemoteOptionsResponse)) {
            dispatch(setError(workRemoteOptionsResponse.error));
            return;
        }

        dispatch(setWorkRemoteOptions(workRemoteOptionsResponse.data));
    } catch (error) {
        console.error('[fetchWorkRemoteOptions]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
