import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Locale } from '../../helpers/trans';

export type AppState = Readonly<{
    isLoading: boolean;
    error: string;
    currentVersion: string;
    latestVersion: string;
    locale: Locale;
}>;

const initialState: AppState = {
    isLoading: false,
    error: '',
    currentVersion: '',
    latestVersion: '',
    locale: Locale.nl,
};

const appSlice = createSlice({
    name: 'appReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCurrentVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                currentVersion: action.payload,
            };
        },
        setLatestVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                latestVersion: action.payload,
            };
        },
        setLocale(state, action: PayloadAction<Locale>): AppState {
            return {
                ...state,
                locale: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCurrentVersion,
    setLatestVersion,
    setLocale,
} = appSlice.actions;

export default appSlice.reducer;
