import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    Dialog,
    DialogContent,
    IconButton,
    InviteSuccessMessage,
} from '../../compositions';
import { closeDialog, openDialog } from '../../helpers/dialog';
import trans from '../../helpers/trans';
import { CandidateInviteFormData } from '../../models/Candidate/Candidate';
import { CandidateRelation } from '../../models/CandidateRelations/CandidateRelations';
import { SortConfig, SortDirection, SortField } from '../../types';
import { CandidateInviteForm, CandidateTable, Pagination } from '..';

import './CounselorDashboard.scss';

interface CounselorDashboardProps {
    candidateInviteIsLoading: boolean;
    candidateInviteIsSuccessful: boolean;
    candidateInviteError: string;
    onCandidateInvite: (candidateInviteFormData: CandidateInviteFormData) => void;
    onCloseInviteCandidateSuccessMessage: () => void;
    //
    tableIsLoading: boolean;
    tableError: string;
    tableContent: CandidateRelation[];
    tableSortConfig: SortConfig;
    tableSearchQuery: string;
    tableTotalPages: number;
    tableCurrentPage: number;
    onTableSort: (field: SortField, direction?: SortDirection) => void;
    onTableSearch: (query: string) => void;
    onTableRefresh: () => void;
    onTablePaginationChange: (currentPage: number) => void;
    //
    className?: string;
}

const CounselorDashboard: FC<CounselorDashboardProps> = ({
    candidateInviteIsLoading,
    candidateInviteIsSuccessful,
    candidateInviteError,
    onCandidateInvite,
    onCloseInviteCandidateSuccessMessage,
    //
    tableIsLoading,
    tableError,
    tableContent,
    tableSortConfig,
    tableSearchQuery,
    tableTotalPages,
    tableCurrentPage,
    onTableSort,
    onTableSearch,
    onTableRefresh,
    onTablePaginationChange,
    //
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [candidateFirstName, setCandidateFirstName] = useState<string>('');
    const [candidateInviteFormKey, setCandidateInviteFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);
        setCandidateInviteFormKey(candidateInviteFormKey + 1);
    };

    const handleCandidateInviteDialog = (candidateInviteFormData: CandidateInviteFormData): void => {
        onCandidateInvite(candidateInviteFormData);

        setCandidateFirstName(candidateInviteFormData.firstName);
    };

    const handleCloseInviteSuccessMessage = (): void => {
        handleCloseDialog();
        onCloseInviteCandidateSuccessMessage();
        setCandidateFirstName('');
    };

    return (
        <article className={`counselor-dashboard ${className}`}>
            <header className="counselor-dashboard__header">
                <IconButton
                    icon="plus"
                    text={trans('containers.counselorDashboard.candidateInviteButton')}
                    onClick={handleOpenDialog}
                />
                <IconButton
                    icon="refresh"
                    text={trans('containers.counselorDashboard.refreshTableLabel')}
                    disabled={tableIsLoading}
                    onClick={onTableRefresh}
                    className="counselor-dashboard__refresh-button"
                />
            </header>

            <CandidateTable
                isLoading={tableIsLoading}
                error={tableError}
                candidates={tableContent}
                sortConfig={tableSortConfig}
                searchQuery={tableSearchQuery}
                onSort={onTableSort}
                onSearch={onTableSearch}
                onCandidateInviteClick={handleOpenDialog}
                className="counselor-dashboard__candidate-table"
            />

            <Pagination
                id="candidate-relations"
                amountOfPages={tableTotalPages}
                currentPage={tableCurrentPage}
                onChange={onTablePaginationChange}
                className="counselor-dashboard__pagination"
            />

            <Dialog
                enableBackdropClose={candidateInviteIsSuccessful}
                ref={dialogRef}
                onClose={candidateInviteIsSuccessful ? handleCloseInviteSuccessMessage : handleCloseDialog}
            >
                {candidateInviteIsSuccessful ? (
                    <InviteSuccessMessage
                        isCandidate
                        firstName={candidateFirstName}
                        onConfirm={handleCloseInviteSuccessMessage}
                    />
                ) : (
                    <DialogContent title={trans('containers.counselorDashboard.candidateInviteTitle')}>
                        <CandidateInviteForm
                            key={candidateInviteFormKey}
                            isLoading={candidateInviteIsLoading}
                            candidateInviteError={candidateInviteError}
                            onSubmit={handleCandidateInviteDialog}
                            onCancel={handleCloseDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>
        </article>
    );
};

export default CounselorDashboard;
