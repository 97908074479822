import { InternalOccupationCompetency, InternalOccupationSkill } from '../../../../models/InternalOccupationDetail/InternalOccupationDetail';

export interface ComparedInternalOccupationSkills {
    matchedSkills: InternalOccupationSkill[];
    notMatchedSkills: InternalOccupationSkill[];
}

export interface ComparedInternalOccupationCompetencies {
    matchedCompetencies: InternalOccupationCompetency[];
    notMatchedCompetencies: InternalOccupationCompetency[];
}

export const getComparedInternalOccupationSkills = (occupationSkills: InternalOccupationSkill[]): ComparedInternalOccupationSkills => ({
    matchedSkills: occupationSkills.filter(skill => skill.isMatch),
    notMatchedSkills: occupationSkills.filter(skill => !skill.isMatch),
});

export const getComparedInternalOccupationCompetencies = (occupationCompetencies: InternalOccupationCompetency[]): ComparedInternalOccupationCompetencies => ({
    matchedCompetencies: occupationCompetencies.filter(competency => competency.isMatch),
    notMatchedCompetencies: occupationCompetencies.filter(competency => !competency.isMatch),
});

