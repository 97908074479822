import { InternalOccupationResource } from '../InternalOccupations/InternalOccupations';
import { Occupation } from '../Occupations/Occupations';
import { defaultPageSize } from '../Pagination';

export interface InternalOccupationOverviewResource {
    meta: {
        totalAmount: number;
    };
    internalOccupations: InternalOccupationResource[];
}

export interface InternalOccupationOverview {
    totalAmount: number;
    occupations: Occupation[];
}

export interface InternalOccupationFilterValues {
    pageNumber: number;
    pageSize: number;
    searchQuery: string;
}

export const defaultInternalOccupationFilterValues = (): InternalOccupationFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
    searchQuery: '',
});

export const defaultInternalOccupationOverview = (): InternalOccupationOverview => ({
    totalAmount: 0,
    occupations: [],
});
