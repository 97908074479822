import { FC, ReactElement } from 'react';

import { RadioList } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { FormOption } from '../../../../../types';
import { SchoolExperienceFormErrors } from '../../validations';

import './EducationLevelSelector.scss';

interface EducationLevelSelectorProps {
    educationLevel: string;
    educationSubLevel?: string;
    learningPath?: string;
    educationLevelOptions: FormOption[];
    educationSubLevelOptions: FormOption[];
    learningPathOptions: FormOption[];
    formErrors: SchoolExperienceFormErrors;
    onEducationLevelChange: (educationLevel: FormOption) => void;
    onEducationSubLevelChange: (educationSubLevel?: FormOption) => void;
    onLearningPathChange: (learningPath?: FormOption) => void;
    className?: string;
}

const EducationLevelSelector: FC<EducationLevelSelectorProps> = ({
    educationLevel,
    educationSubLevel,
    learningPath,
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions,
    formErrors,
    onEducationLevelChange,
    onEducationSubLevelChange,
    onLearningPathChange,
    className = '',
}): ReactElement => {
    const handleEducationLevelChange = (valueId: string): void => {
        const educationLevelLabel = educationLevelOptions.find(level => level.value === valueId)?.label;

        onEducationLevelChange({
            label: educationLevelLabel || trans('common.other'),
            value: valueId,
        });

        onEducationSubLevelChange(undefined);
        onLearningPathChange(undefined);
    };

    const handleEducationSubLevelChange = (valueId: string): void => {
        const educationSubLevelLabel = educationSubLevelOptions.find(subLevel => subLevel.value === valueId)?.label;

        onEducationSubLevelChange({
            label: educationSubLevelLabel || '',
            value: valueId,
        });
    };

    const handleLearningPathChange = (valueId: string): void => {
        const learningPathLabel = learningPathOptions.find(path => path.value === valueId)?.label;

        onLearningPathChange({
            label: learningPathLabel || '',
            value: valueId,
        });
    };

    return (
        <div className={`education-level-selector ${className}`}>
            <RadioList
                maySelectOther
                label={trans('forms.schoolExperience.input.educationLevel')}
                name="education-level"
                required
                options={educationLevelOptions}
                value={educationLevel}
                error={formErrors.educationLevel}
                onChange={handleEducationLevelChange}
                className="education-level-selector__input"
            />

            {educationSubLevelOptions.length > 0 && (
                <RadioList
                    label={trans('forms.schoolExperience.input.educationSubLevel')}
                    name="education-sub-level"
                    required
                    options={educationSubLevelOptions}
                    value={educationSubLevel || ''}
                    error={formErrors.educationSubLevel}
                    onChange={handleEducationSubLevelChange}
                    className="education-level-selector__input"
                />
            )}

            {learningPathOptions.length > 0 && (
                <RadioList
                    maySelectOther
                    label={trans('forms.schoolExperience.input.learningPath')}
                    name="learning-path"
                    required
                    options={learningPathOptions}
                    value={learningPath || ''}
                    error={formErrors.learningPath}
                    onChange={handleLearningPathChange}
                    className="education-level-selector__input"
                />
            )}
        </div>
    );
};

export default EducationLevelSelector;
