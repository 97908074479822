import { FC, ReactElement } from 'react';

import { Card, SafeHtml } from '../../../../../components';
import { ConnectedIncentivesCard, ConnectedTalentsCard } from '../../../../../connectors';
import { getRoleForTranslation } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { UserRole } from '../../../../../types';

import './TalentsAndIncentives.scss';

interface TalentsAndIncentivesProps {
    userRoles?: UserRole[];
    onRefresh: () => void;
}

const TalentsAndIncentives: FC<TalentsAndIncentivesProps> = ({
    userRoles = [],
    onRefresh,
}): ReactElement => (
    <div className="talents-and-incentives-tab">
        <Card className="talents-and-incentives-tab__card">
            <h1 className="talents-and-incentives-tab__title">
                {trans('containers.skillsPassportTabs.talentsAndIncentives.title')}
            </h1>

            <SafeHtml
                html={userRoles.length > 0 ? trans(`containers.skillsPassportTabs.talentsAndIncentives.intro.${getRoleForTranslation(userRoles)}`) : ''}
                className="talents-and-incentives-tab__intro"
            />
        </Card>

        <ConnectedTalentsCard
            onRefresh={onRefresh}
            className="talents-and-incentives-tab__card"
        />

        <ConnectedIncentivesCard
            onRefresh={onRefresh}
            className="talents-and-incentives-tab__card"
        />
    </div>
);

export default TalentsAndIncentives;
