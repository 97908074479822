import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { DevelopmentPlan } from '../../models/DevelopmentPlan/DevelopmentPlan';
import { AsyncReduxState } from '../../types';

export type DevelopmentPlanState = AsyncReduxState<{
    developmentPlan?: DevelopmentPlan;
    isUpdating: boolean;
    isDeleting: boolean;
}>;

const initialState: DevelopmentPlanState = {
    ...initialAsyncReduxState,
    isUpdating: false,
    isDeleting: false,
};

export const developmentPlanSlice = createSlice({
    name: 'developmentPlanReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DevelopmentPlanState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): DevelopmentPlanState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setIsDeleting(state, action: PayloadAction<boolean>): DevelopmentPlanState {
            return {
                ...state,
                isDeleting: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DevelopmentPlanState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DevelopmentPlanState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDevelopmentPlan(state, action: PayloadAction<DevelopmentPlan | undefined>): DevelopmentPlanState {
            return {
                ...state,
                developmentPlan: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setIsDeleting,
    setIsSuccessful,
    setError,
    setDevelopmentPlan,
} = developmentPlanSlice.actions;

export default developmentPlanSlice.reducer;
