import { alternateArrays } from '../../helpers/array';
import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { maximumAmountOfVacancies, transformToPagination } from '../../models/Pagination';
import { Vacancy } from '../../models/Vacancy';
import { getJobdiggerVacanciesApiCall, getMatchingVacanciesApiCall } from '../../services/MatchingService';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setMatchingVacancies,
    setPagination,
} from './matchingVacancies';

export const fetchMatchingVacancies = (candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { matchingVacanciesReducer } = getState();
        const { activeFilterValues } = matchingVacanciesReducer;

        const vacancyOverviewResponse = await getMatchingVacanciesApiCall(activeFilterValues);

        if (!isFetchResultSuccessful(vacancyOverviewResponse)) {
            dispatch(setError(trans('errors.matchingVacancies')));
            return;
        }

        const { totalAmount, vacancies } = vacancyOverviewResponse.data;

        let jobdiggerVacancies: Vacancy[] = [];

        if (totalAmount < maximumAmountOfVacancies && activeFilterValues.what) {
            const jobdiggerVacancyOverviewResponse = await getJobdiggerVacanciesApiCall(candidateUuid, maximumAmountOfVacancies - totalAmount, activeFilterValues);

            if (!isFetchResultSuccessful(jobdiggerVacancyOverviewResponse)) {
                dispatch(setError(trans('errors.jobdiggerVacancies')));
            } else {
                jobdiggerVacancies = jobdiggerVacancyOverviewResponse.data.vacancies;
            }
        }

        const alternatedVacancies = alternateArrays(vacancies, jobdiggerVacancies);

        const pagination = transformToPagination(alternatedVacancies.length, 1);

        dispatch(setMatchingVacancies(alternatedVacancies));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchMatchingVacancies]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
