import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import trans from '../../../helpers/trans';
import { ViewOption } from '../../../types';
import { ViewSelectorOption } from './subcomponents';

import './ViewSelector.scss';

interface ViewSelectorProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    label?: string;
    hideLabel?: boolean;
    options: ViewOption[];
    error?: string;
    onChange: (value: string) => void;
    className?: string;
}

const ViewSelector: FC<ViewSelectorProps> = ({
    label = trans('compositions.viewSelector.label'),
    hideLabel,
    options,
    value,
    className = '',
    ...viewSelectorProps
}): ReactElement => (
    <div className={`view-selector ${className}`}>
        {!hideLabel && (
            <InputLabel
                text={label}
                className="view-selector__label"
            />
        )}

        <div className="view-selector__option-list">
            {options.map(option => (
                <ViewSelectorOption
                    {...viewSelectorProps}
                    key={option.value}
                    icon={option.icon}
                    label={option.label}
                    value={option.value}
                    checked={value === option.value}
                />
            ))}
        </div>
    </div>
);

export default ViewSelector;
