import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { CounselorFilterValues, CounselorOverview, CounselorOverviewResource } from './CounselorOverview';
import { transformToCounselorOverview } from './CounselorOverviewTransformers';

export const getCounselorsForOrganisationApiCall = async (organisationId: string, activeFilterValues: CounselorFilterValues): Promise<FetchResult<CounselorOverview, string>> => {
    try {
        const { pageNumber, pageSize } = activeFilterValues;

        const response = await lloFetch(`/api/user/counselors/${organisationId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const counselorOverviewResponse: CounselorOverviewResource = await response.json();

        const counselorOverview = transformToCounselorOverview(counselorOverviewResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: counselorOverview,
        };
    } catch (error) {
        console.error('[getCounselorsForOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
