import { matchingServiceApiUrl } from '../constants';
import { authorisedFetch } from '../helpers/authorisedFetch';
import { lloFetch } from '../helpers/lloFetch';
import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { transformFilterValuesToJobdiggerFilterParams, transformJobdiggerVacanciesToMatchingVacancies } from '../models/JobdiggerVacancies';
import {
    defaultVacancyFilterValues,
    MatchingVacancies,
    transformFilterValuesToFilterParams,
    transformToMatchingVacancies,
    VacancyFilterValues,
} from '../models/MatchingVacancies';

export const getMatchingVacanciesApiCall = async (filters?: VacancyFilterValues): Promise<FetchResult<MatchingVacancies, string>> => {
    const filterOptions = transformFilterValuesToFilterParams({
        ...defaultVacancyFilterValues(),
        ...filters,
    });

    try {
        const matchingVacanciesResponse = await authorisedFetch(`${matchingServiceApiUrl}/vacancies`, {
            method: 'POST',
            body: JSON.stringify(filterOptions),
        });

        const response = await matchingVacanciesResponse.json();

        if (!response) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const matchingVacancies = transformToMatchingVacancies(response);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: matchingVacancies,
        };
    } catch (error) {
        console.error('[getMatchingVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getJobdiggerVacanciesApiCall = async (candidateUuid: string, amountToFetch: number, filters?: VacancyFilterValues): Promise<FetchResult<MatchingVacancies, string>> => {
    const filterOptions = transformFilterValuesToJobdiggerFilterParams(amountToFetch, {
        ...defaultVacancyFilterValues(),
        ...filters,
    });

    try {
        const jobdiggerVacanciesResponse = await lloFetch('/api/jobdiggerVacancies', {
            candidateUuid,
            method: 'POST',
            body: JSON.stringify(filterOptions),
        });

        const response = await jobdiggerVacanciesResponse.json();

        if (!response) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const jobdiggerVacancies = transformJobdiggerVacanciesToMatchingVacancies(response);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: jobdiggerVacancies,
        };
    } catch (error) {
        console.error('[getJobdiggerVacanciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
