import {
    FC,
    ReactElement,
    UIEvent,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../../../components';
import trans from '../../../../../helpers/trans';
import { Competency } from '../../../../../models/Competencies/Competencies';
import { CompetencyOption } from '../../../..';

import './CompetencyResults.scss';

interface CompetencyResultsProps {
    searchResults: Competency[];
    selectedOptions: Competency[];
    onSelectOption: (selectedOption: Competency) => void;
    onClearSearch: () => void;
    className?: string;
}

const CompetencyResults: FC<CompetencyResultsProps> = ({
    searchResults,
    selectedOptions,
    onSelectOption,
    onClearSearch,
    className = '',
}): ReactElement => {
    const resultsLimit = 20;

    const [resultsPage, setResultsPage] = useState<number>(1);
    const [limitedSearchResults, setLimitedSearchResults] = useState<Competency[]>([]);

    useEffect((): void => {
        if (searchResults.length === 0) {
            setResultsPage(1);
        }
    }, [searchResults]);

    useEffect((): void => {
        const limitedResults = searchResults.slice(0, resultsLimit * resultsPage);
        setLimitedSearchResults(limitedResults);
    }, [searchResults, resultsPage]);

    const handleListScroll = (event: UIEvent<HTMLUListElement>): void => {
        if (searchResults.length <= resultsLimit) return;

        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        const scrollPosition = scrollTop + clientHeight;

        if (scrollPosition === scrollHeight) {
            setResultsPage(resultsPage + 1);
        }
    };

    return (
        <div className={`competency-results ${className}`}>
            {limitedSearchResults.length > 0 && (
                <ul onScroll={handleListScroll} className="competency-results__list">
                    {limitedSearchResults.map(option => (
                        <CompetencyOption
                            key={option.id}
                            option={option}
                            selectedOptions={selectedOptions}
                            onSelectOption={onSelectOption}
                            className="competency-results__item"
                        />
                    ))}
                </ul>
            )}

            {limitedSearchResults.length > 0 && (
                <div className="competency-results__button-wrapper">
                    <Button
                        text={trans('compositions.competencySelector.resetSearch')}
                        onClick={onClearSearch}
                        className="competency-results__reset-button"
                    />
                </div>
            )}
        </div>
    );
};

export default CompetencyResults;
