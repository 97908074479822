import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    Checkbox,
    FormActionButtons,
    MonthYearInput,
    Notice,
    Textarea,
    TextInput,
} from '../../../compositions';
import { maxEmploymentYears } from '../../../constants';
import { startOfGivenMonth } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { defaultJobExperience, JobExperience, JobExperienceFormData } from '../../../models/JobExperience/JobExperience';
import { DialogFormProps, NoticeType } from '../../../types';
import { JobExperienceFormErrors, validateJobExperienceFormData } from './validations';

import './JobExperienceForm.scss';

interface JobExperienceFormProps extends DialogFormProps<JobExperienceFormData> {
    jobExperience?: JobExperience;
    className?: string;
}

const JobExperienceForm: FC<JobExperienceFormProps> = ({
    isLoading,
    error,
    jobExperience = defaultJobExperience(),
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<JobExperienceFormErrors>({});

    const [position, setPosition] = useState<string>(jobExperience.position);
    const [company, setCompany] = useState<string>(jobExperience.company);
    const [isCurrentJob, setIsCurrentJob] = useState<boolean>(!!jobExperience.isCurrentJob);
    const [startDate, setStartDate] = useState<Date>(jobExperience.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(jobExperience.endDate);
    const [description, setDescription] = useState<string>(jobExperience.description);

    const formRef = useRef<HTMLFormElement>(null);

    const handleIsCurrentJobChange = (value: boolean): void => {
        setEndDate(undefined);
        setIsCurrentJob(value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: jobExperience.id,
            position,
            company,
            isCurrentJob,
            startDate: startOfGivenMonth(startDate),
            endDate: endDate ? startOfGivenMonth(endDate) : undefined,
            description,
        };

        const [errors, hasErrors] = validateJobExperienceFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`job-experience-form ${className}`}>
            <div className="job-experience-form__input-wrapper">
                <TextInput
                    label={trans('forms.jobExperience.input.position')}
                    required
                    value={position}
                    error={formErrors.position}
                    onChange={setPosition}
                    className="job-experience-form__input"
                />
                <TextInput
                    label={trans('forms.jobExperience.input.company')}
                    required
                    value={company}
                    error={formErrors.company}
                    onChange={setCompany}
                    className="job-experience-form__input"
                />
            </div>

            <Checkbox
                label={trans('forms.jobExperience.input.currentJob')}
                checked={isCurrentJob}
                onChange={handleIsCurrentJobChange}
                className="job-experience-form__input job-experience-form__checkbox"
            />

            <MonthYearInput
                label={trans('forms.jobExperience.input.startDate')}
                name="startDate"
                required
                minYear={new Date().getFullYear() - maxEmploymentYears}
                maxYear={new Date().getFullYear()}
                value={startDate}
                error={formErrors.startDate}
                onChange={setStartDate}
                className="job-experience-form__input"
            />

            {!isCurrentJob && (
                <MonthYearInput
                    label={trans('forms.jobExperience.input.endDate')}
                    name="endDate"
                    required
                    minYear={startDate.getFullYear()}
                    maxYear={startDate.getFullYear() + maxEmploymentYears}
                    value={endDate}
                    error={formErrors.endDate}
                    onChange={setEndDate}
                    className="job-experience-form__input"
                />
            )}

            <Textarea
                label={trans('forms.jobExperience.input.description')}
                required
                placeholder={trans('forms.jobExperience.placeholder.description')}
                value={description}
                error={formErrors.description}
                onChange={setDescription}
                className="job-experience-form__input"
            />

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="job-experience-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="job-experience-form__action-buttons"
            />
        </form>
    );
};

export default JobExperienceForm;
