import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type TalentzAuthState = AsyncReduxState;

const initialState: TalentzAuthState = {
    ...initialAsyncReduxState,
};

const talentzAuthSlice = createSlice({
    name: 'talentzAuthReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): TalentzAuthState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): TalentzAuthState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
} = talentzAuthSlice.actions;

export default talentzAuthSlice.reducer;
