import { CSSProperties, FC, ReactElement } from 'react';

import { Incentive } from '../../../../../models/SkillsPassport/SkillsPassport';
import { IncentiveSliderValueIllustration } from '..';

import './IncentiveSlider.scss';

interface IncentiveSliderProps {
    incentive: Incentive;
    className?: string;
}

const IncentiveSlider: FC<IncentiveSliderProps> = ({
    incentive,
    className = '',
}): ReactElement => {
    const maxValue = 9;
    const step = 100 / (maxValue - 1);
    const sliderPercentage = Math.round(step * (incentive.value - 1));

    const cssVariables = {
        '--incentive-slider-value': `${sliderPercentage}%`,
    } as CSSProperties;

    return (
        <div className={`incentive-slider ${className}`}>
            <div className="incentive-slider__labels">
                <p className="incentive-slider__label">{incentive.startLabel}</p>
                <p className="incentive-slider__label">{incentive.endLabel}</p>
            </div>

            <div className="incentive-slider__slider">
                <IncentiveSliderValueIllustration style={cssVariables} className="incentive-slider__value-illustration" />
            </div>
        </div>
    );
};

export default IncentiveSlider;
