import {
    AddDevelopmentPlanSkillEvaluationRequest,
    DevelopmentPlanSkillEvaluation,
    DevelopmentPlanSkillEvaluationFormData,
    DevelopmentPlanSkillEvaluationResource,
    EditDevelopmentPlanSkillEvaluationRequest,
} from './DevelopmentPlanSkillEvaluation';

export const transformDevelopmentPlanSkillEvaluation = (resource: DevelopmentPlanSkillEvaluationResource): DevelopmentPlanSkillEvaluation => ({
    id: resource.id,
    feedback: resource.feedback,
    isCompleted: resource.isCompleted,
    developmentPlanSkillId: resource.developmentPlanSkillId,
});

export const transformAddDevelopmentPlanSkillEvaluationRequest = (formData: DevelopmentPlanSkillEvaluationFormData): AddDevelopmentPlanSkillEvaluationRequest => ({
    feedback: formData.feedback,
    isCompleted: formData.isCompleted,
    developmentPlanSkillId: formData.developmentPlanSkillId || '',
});

export const transformEditDevelopmentPlanSkillEvaluationRequest = (formData: DevelopmentPlanSkillEvaluationFormData): EditDevelopmentPlanSkillEvaluationRequest => ({
    id: formData.id || '',
    feedback: formData.feedback,
    isCompleted: formData.isCompleted,
});
