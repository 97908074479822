import { FC, ReactElement } from 'react';

import { OrganisationDetailTabs } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedOrganisationDetailTabsProps {
    className?: string;
}

const ConnectedOrganisationDetailTabs: FC<ConnectedOrganisationDetailTabsProps> = ({
    className = '',
}): ReactElement => {
    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);

    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);
    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = [organisationIsLoading, userIsLoading].some(Boolean);

    return (
        <OrganisationDetailTabs
            isLoading={isLoading}
            userModules={user?.modules}
            userRoles={user?.roles}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailTabs;
