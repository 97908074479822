import { isFetchResultSuccessful } from '../../models/FetchResult';
import { fetchAuthenticationToken } from '../../services/AuthenticationService';
import { AuthToken } from '../../types';
import { TypedDispatch } from '../store';
import { setError, setIsLoading } from './authentication';

export const setAuthenticationToken = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const authResponse = await fetchAuthenticationToken();

        if (!isFetchResultSuccessful(authResponse)) {
            dispatch(setError(authResponse.error));
            return;
        }

        const token = authResponse.data;

        localStorage.setItem(AuthToken.auth, JSON.stringify(token));
    } catch (error) {
        console.error('[setAuthenticationToken]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
