import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { JobType } from '../../models/JobTypes';
import { AsyncReduxState } from '../../types';

export type JobTypesState = AsyncReduxState<{
    jobTypes: JobType[];
}>;

const initialState: JobTypesState = {
    ...initialAsyncReduxState,
    jobTypes: [],
};

export const jobTypesSlice = createSlice({
    name: 'jobTypesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): JobTypesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): JobTypesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setJobTypes(state, action: PayloadAction<JobType[]>): JobTypesState {
            return {
                ...state,
                jobTypes: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setJobTypes,
} = jobTypesSlice.actions;

export default jobTypesSlice.reducer;
