import { editArrayEntry } from '../../helpers/array';
import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { Module, ModuleRequest } from '../../models/Modules/Modules';
import { getModulesForOrganisationApiCall, patchModulesForOrganisationApiCall } from '../../models/Modules/ModulesService';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsUpdating,
    setModules,
    setShowConfirmation,
} from './modules';

export const fetchModulesForOrganisation = (organisationId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const modulesResponse = await getModulesForOrganisationApiCall(organisationId);

        if (!isFetchResultSuccessful(modulesResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setModules(modulesResponse.data));
    } catch (error) {
        console.error('[fetchModulesForOrganisation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateModulesForOrganisation = (organisationId: string, moduleRequest: ModuleRequest) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setShowConfirmation(false));
    dispatch(setError(''));

    try {
        const state = getState();
        const { modules } = state.modulesReducer;

        const modulesResponse = await patchModulesForOrganisationApiCall(organisationId, moduleRequest);

        if (!isFetchResultSuccessful(modulesResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const updatedModules = editArrayEntry<Module>(modules, modulesResponse.data);

        dispatch(setModules(updatedModules));
        dispatch(setShowConfirmation(true));
    } catch (error) {
        console.error('[updateModulesForOrganisation]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};
