import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Card } from '../../../../../components';
import {
    CallToActionNullState,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../../compositions';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { EscoOccupation } from '../../../../../models/EscoOccupations/EscoOccupations';
import { OrganisationOccupation } from '../../../../../models/OrganisationOccupation/OrganisationOccupation';
import { Skill, SkillsFormData } from '../../../../../models/Skills/Skills';
import { SkillsForm } from '../../../..';

import './OccupationSkillsCard.scss';

interface OccupationSkillsCardProps {
    suggestionIsLoading?: boolean;
    isUpdating: boolean;
    isSuccessful: boolean;
    occupation: OrganisationOccupation;
    suggestedSkills: Skill[];
    skillOptions: Skill[];
    skillsError?: string;
    occupationOptions: EscoOccupation[];
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onUpdateSkills: (skillsFormData: SkillsFormData) => void;
    onCloseSkillsDialog: () => void;
    className?: string;
}

const OccupationSkillsCard: FC<OccupationSkillsCardProps> = ({
    suggestionIsLoading,
    isUpdating,
    isSuccessful,
    occupation,
    suggestedSkills,
    skillOptions,
    skillsError,
    occupationOptions,
    onSelectOccupation,
    onUpdateSkills,
    onCloseSkillsDialog,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [skillsFormKey, setSkillsFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);
        onCloseSkillsDialog();

        setSkillsFormKey(skillsFormKey + 1);
    };

    const { skills } = occupation;

    useEffect((): void => {
        if (isSuccessful) handleCloseDialog();
    }, [isSuccessful]);

    return (
        <Card className={`occupation-skills-card ${className}`}>
            <header className="occupation-skills-card__header">
                <h3 className="occupation-skills-card__title">{trans('common.skills')}</h3>

                {skills.length > 0 && (
                    <IconButton
                        icon="plus"
                        text={trans('containers.organisationDetailOccupation.actions.addSkills')}
                        onClick={handleOpenDialog}
                        className="occupation-skills-card__add-button"
                    />
                )}
            </header>

            {skills.length === 0 && (
                <CallToActionNullState
                    isEditable
                    icon="skills-passport"
                    paragraph={trans('containers.organisationDetailOccupation.skillsNullState.paragraph')}
                    subParagraph={trans('containers.organisationDetailOccupation.skillsNullState.subParagraph')}
                    buttonText={trans('containers.organisationDetailOccupation.skillsNullState.callToAction')}
                    onButtonClick={handleOpenDialog}
                    className="occupation-skills-card__null-state"
                />
            )}

            {skills.length > 0 && (
                <ul className="occupation-skills-card__list">
                    {skills.map(skill => (
                        <li key={skill.id} className="occupation-skills-card__list-item">{skill.name}</li>
                    ))}
                </ul>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent
                    title={skills.length === 0
                        ? trans('containers.organisationDetailOccupation.skillsForm.addTitle')
                        : trans('containers.organisationDetailOccupation.skillsForm.editTitle')}
                >
                    <SkillsForm
                        key={skillsFormKey}
                        isLoading={isUpdating}
                        suggestionIsLoading={suggestionIsLoading}
                        activeSkills={skills}
                        suggestedOccupationSkills={suggestedSkills}
                        skillOptions={skillOptions}
                        occupationOptions={occupationOptions}
                        error={skillsError}
                        skillsTitle={trans('containers.organisationDetailOccupation.skillsForm.skillsTitle')}
                        skillsDescription={trans('containers.organisationDetailOccupation.skillsForm.skillsDescription')}
                        occupationTitle={trans('containers.organisationDetailOccupation.skillsForm.occupationTitle')}
                        occupationDescription={trans('containers.organisationDetailOccupation.skillsForm.occupationDescription')}
                        onSelectOccupation={onSelectOccupation}
                        onSubmit={onUpdateSkills}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default OccupationSkillsCard;
