import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { defaultCompetencyList } from '../../../constants/tma';
import { DevelopmentPlanCompetencyForm } from '../../../containers';
import { Competency } from '../../../models/Competencies/Competencies';
import { AddDevelopmentPlanCompetencyFormData } from '../../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import { fetchCandidate } from '../../../redux/candidate/candidateActions';
import { addDevelopmentPlanCompetency } from '../../../redux/developmentPlanCompetency/developmentPlanCompetencyActions';
import { fetchSkillsPassport } from '../../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { CompetencyLevel, SearchableOption } from '../../../types';

interface ConnectedDevelopmentPlanCompetencyFormProps {
    onCancel: () => void;
    className?: string;
}

const ConnectedDevelopmentPlanCompetencyForm: FC<ConnectedDevelopmentPlanCompetencyFormProps> = ({
    onCancel,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const isLoadingCandidateCompetencyOptions = useTypedSelector(state => state.candidateReducer.isLoading);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const competencies = useTypedSelector(state => state.skillsPassportReducer.competencies);

    const isLoadingDevelopmentPlanCompetencies = useTypedSelector(state => state.developmentPlanCompetencyReducer.isLoading);

    const developmentPlan = useTypedSelector(state => state.developmentPlanReducer.developmentPlan);

    const competencyList: Competency[] = competencies
        ? Object.entries(competencies).map(([, competency]) => competency).flat()
        : defaultCompetencyList;

    useEffectOnce((): void => {
        if (candidateUuid) {
            dispatch(fetchCandidate(candidateUuid));
            dispatch(fetchSkillsPassport(candidateUuid));
        }
    });

    const competencyOptions: SearchableOption[] = competencyList.map(competency => ({
        label: competency.label,
        value: competency.label,
        secondaryLabel: competency.level !== CompetencyLevel.noLevel
            ? competency.level
            : undefined,
    }));

    const handleSubmitAddCompetency = (formData: AddDevelopmentPlanCompetencyFormData): void => {
        if (candidateUuid) {
            dispatch(addDevelopmentPlanCompetency(formData, candidateUuid));
            onCancel();
        }
    };

    return (
        <DevelopmentPlanCompetencyForm
            isLoading={isLoadingDevelopmentPlanCompetencies}
            isLoadingCompetencies={isLoadingCandidateCompetencyOptions || skillsPassportIsLoading}
            competencyOptions={competencyOptions}
            developmentPlanId={developmentPlan?.id || ''}
            onCancel={onCancel}
            onSubmit={handleSubmitAddCompetency}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanCompetencyForm;
