import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './OrganisationDetailOccupationOverviewSkeletons.scss';

interface OrganisationDetailOccupationOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const OrganisationDetailOccupationOverviewSkeletons: FC<OrganisationDetailOccupationOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <div className={`organisation-detail-occupation-overview-skeletons ${className}`}>
            <div className="organisation-detail-occupation-overview-skeletons__header">
                <Skeleton className="organisation-detail-occupation-overview-skeletons__title" />
                <Skeleton className="organisation-detail-occupation-overview-skeletons__button" />
            </div>

            <ul className="organisation-detail-occupation-overview-skeletons__list">
                {skeletons.map(skeleton => (
                    <li key={skeleton} className="organisation-detail-occupation-overview-skeletons__list-item">
                        <Card className="organisation-detail-occupation-overview-skeletons__card">
                            <Skeleton className="organisation-detail-occupation-overview-skeletons__card-title" />

                            <Skeleton className="organisation-detail-occupation-overview-skeletons__card-description" />
                            <Skeleton className="organisation-detail-occupation-overview-skeletons__card-description" />

                            <Skeleton className="organisation-detail-occupation-overview-skeletons__card-action-button" />
                        </Card>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OrganisationDetailOccupationOverviewSkeletons;
