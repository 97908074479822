import { FC, ReactElement } from 'react';

import { OccupationDetailSkills } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';
import { getComparedOccupationSkills } from '../ConnectedOccupationDetailHeader/helpers';

interface ConnectedOccupationDetailSkillsProps {
    className?: string;
}

const ConnectedOccupationDetailSkills: FC<ConnectedOccupationDetailSkillsProps> = ({
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skills = useTypedSelector(state => state.skillsPassportReducer.skills);

    const occupationIsLoading = useTypedSelector(state => state.occupationDetailReducer.isLoading);
    const error = useTypedSelector(state => state.occupationDetailReducer.error);
    const occupation = useTypedSelector(state => state.occupationDetailReducer.occupation);

    const isLoading = [
        skillsPassportIsLoading,
        occupationIsLoading,
    ].some(Boolean);

    return (
        <OccupationDetailSkills
            isLoading={isLoading}
            error={error}
            userRoles={user?.roles}
            occupation={occupation}
            comparedOccupationSkills={getComparedOccupationSkills(occupation, skills)}
            className={className}
        />
    );
};

export default ConnectedOccupationDetailSkills;
