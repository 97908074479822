import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { TmaAnalysisSettings, TmaAnalysisSettingsFormData, TmaAnalysisSettingsResource } from './TmaAnalysis';
import { transformToTmaAnalysisSettings } from './TmaAnalysisTransformers';

export const getTmaAnalysisSettingsApiCall = async (candidateUuid: string): Promise<FetchResult<TmaAnalysisSettings | undefined, string>> => {
    try {
        const response = await lloFetch(`/api/tmaAnalysis/${candidateUuid}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const tmaAnalysisSettingsResponse: TmaAnalysisSettingsResource = await response.json();

        const tmaAnalysisSettings = tmaAnalysisSettingsResponse.created
            ? transformToTmaAnalysisSettings(tmaAnalysisSettingsResponse)
            : undefined;

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: tmaAnalysisSettings,
        };
    } catch (error) {
        console.error('[getTmaAnalysisSettingsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postTmaAnalysisSettingsApiCall = async (candidateUuid: string, tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData): Promise<FetchResult<TmaAnalysisSettings, string>> => {
    try {
        const response = await lloFetch(`/api/tmaAnalysis/${candidateUuid}`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify(tmaAnalysisSettingsFormData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const tmaAnalysisSettingsResponse: TmaAnalysisSettingsResource = await response.json();

        const tmaAnalysisSettings = transformToTmaAnalysisSettings(tmaAnalysisSettingsResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: tmaAnalysisSettings,
        };
    } catch (error) {
        console.error('[postTmaAnalysisSettingsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
