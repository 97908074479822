import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Module } from '../../models/Modules/Modules';
import { AsyncReduxState } from '../../types';

export type ModulesState = AsyncReduxState<{
    isUpdating: boolean;
    showConfirmation: boolean;
    modules: Module[];
}>;

const initialState: ModulesState = {
    ...initialAsyncReduxState,
    isUpdating: false,
    showConfirmation: false,
    modules: [],
};

export const modulesSlice = createSlice({
    name: 'modulesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ModulesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): ModulesState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setShowConfirmation(state, action: PayloadAction<boolean>): ModulesState {
            return {
                ...state,
                showConfirmation: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ModulesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setModules(state, action: PayloadAction<Module[]>): ModulesState {
            return {
                ...state,
                modules: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setShowConfirmation,
    setError,
    setModules,
} = modulesSlice.actions;

export default modulesSlice.reducer;
