import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import sanitizeHtml, { AllowedAttribute } from 'sanitize-html';

interface SafeHtmlProps extends RefAttributes<HTMLDivElement> {
    html?: string;
    allowedTags?: string[];
    allowedAttributes?: Record<string, AllowedAttribute[]>;
    className?: string;
}

const SafeHtml: ForwardRefExoticComponent<SafeHtmlProps> = forwardRef(({
    html = '',
    allowedTags = [],
    allowedAttributes = {},
    className = '',
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const __html = sanitizeHtml(html, {
        allowedTags: [
            ...['h1', 'h2', 'h3', 'h4'],
            ...['p', 'strong', 'em', 'a', 'b', 'i'],
            ...['ul', 'ol', 'li'],
            ...['br', 'pre', 'img'],
            ...allowedTags,
        ],
        allowedAttributes: {
            a: ['href'],
            img: ['src', 'alt'],
            ...allowedAttributes,
        },
    });

    return (
        <div
            ref={ref}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html }}
            className={className}
        />
    );
});

export default SafeHtml;
