import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { generateFullName } from '../../helpers/user';
import { FetchResult, FetchResultType } from '../FetchResult';
import { CandidateExchangeFormData } from './CandidateExchange';
import { transformToExchangeConfirmationError, transformToExchangeRequestError } from './CandidateExchangeTransformers';

export const postCandidateExchangeRequestApiCall = async (organisationId: string, canidateExchangeFormData: CandidateExchangeFormData): Promise<FetchResult<undefined, string>> => {
    try {
        const { candidateId, counselorId } = canidateExchangeFormData;

        const response = await lloFetch(`/api/candidate/${candidateId}/exchange/request/${organisationId}`, {
            method: 'POST',
            body: JSON.stringify({ counselorId }),
        });

        if (!response.ok) {
            const exchangeErrorResource = await response.json();

            const exchangeRequestError = transformToExchangeRequestError(exchangeErrorResource);

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: exchangeRequestError
                    ? trans(`errors.exchangeRequest.${exchangeRequestError}`)
                    : trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[postCandidateExchangeRequestApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postConfirmCandidateExchangeApiCall = async (candidateUuid: string, counselorId: string): Promise<FetchResult<string, string>> => {
    try {
        const response = await lloFetch(`/api/candidate/${candidateUuid}/exchange`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify({ counselorId }),
        });

        if (!response.ok) {
            const exchangeErrorResource = await response.json();

            const exchangeConfirmationError = transformToExchangeConfirmationError(exchangeErrorResource);

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: exchangeConfirmationError
                    ? trans(`errors.exchangeConfirmation.${exchangeConfirmationError}`)
                    : trans('errors.unknownError'),
            };
        }

        const counselorNameResource = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: generateFullName(counselorNameResource),
        };
    } catch (error) {
        console.error('[postConfirmCandidateExchangeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postArchiveCandidateApiCall = async (candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/candidate/${candidateUuid}/lloOrganisation`, {
            method: 'POST',
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[postArchiveCandidateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postTransferCandidateApiCall = async (canidateExchangeFormData: CandidateExchangeFormData): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch('/api/candidate/transfer', {
            method: 'POST',
            body: JSON.stringify(canidateExchangeFormData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[postTransferCandidateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
