import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { AmountOfEmployeesOption, AmountOfEmployeesOptionResource, transformResourceToAmountOfEmployeesOption } from '../models/AmountOfEmployees';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const getAmountOfEmployeesOptionsApiCall = async (): Promise<FetchResult<AmountOfEmployeesOption[], string>> => {
    try {
        const response = await talentzFetch('/api/amounts-of-employees');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<AmountOfEmployeesOptionResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const amountOfEmployeesOptions = doc.data.map(transformResourceToAmountOfEmployeesOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: amountOfEmployeesOptions,
        };
    } catch (error) {
        console.error('[getAmountOfEmployeesOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
