import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type CandidateInviteState = AsyncReduxState;

const initialState: CandidateInviteState = {
    ...initialAsyncReduxState,
};

export const candidateInviteSlice = createSlice({
    name: 'candidateInviteReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CandidateInviteState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CandidateInviteState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CandidateInviteState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = candidateInviteSlice.actions;

export default candidateInviteSlice.reducer;
