import { TmaAnalysisSettings, TmaAnalysisSettingsResource, TmaAnalysisType } from './TmaAnalysis';

export const transformToTmaAnalysisSettings = (
    tmaAnalysisSettingsResource: TmaAnalysisSettingsResource,
): TmaAnalysisSettings => ({
    type: tmaAnalysisSettingsResource.type as TmaAnalysisType,
    startDate: tmaAnalysisSettingsResource.startDate,
    endDate: tmaAnalysisSettingsResource.endDate,
    isActive: tmaAnalysisSettingsResource.created,
    isFinished: tmaAnalysisSettingsResource.finished,
});
