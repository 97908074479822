import { FC, ReactElement } from 'react';

import Breadcrumbs, { Breadcrumb } from '../../../compositions/Breadcrumbs/Breadcrumbs';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

interface ConnectedDevelopmentOverviewBreadcrumbsProps {
    className?: string;
}

const ConnectedDevelopmentOverviewBreadcrumbs: FC<ConnectedDevelopmentOverviewBreadcrumbsProps> = ({
    className = '',
}): ReactElement => {
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);

    const crumbs: Breadcrumb[] = userHasRole(UserRole.counselor, user?.roles) ? ([
        {
            label: trans('routes.dashboard'),
            to: RoutePaths.dashboard(),
        },
        {
            label: candidate?.fullName || '',
            to: RoutePaths.candidateDashboard(candidate?.uuid),
        },
        {
            label: trans('connectors.connectedDevelopmentOverviewBreadcrumbs.development'),
        },
    ]) : [];

    return (
        <Breadcrumbs
            crumbs={crumbs}
            className={className}
        />
    );
};

export default ConnectedDevelopmentOverviewBreadcrumbs;
