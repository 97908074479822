import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddDevelopmentPlanSkillRequest,
    DevelopmentPlanSkill,
    DevelopmentPlanSkillResource,
    EditDevelopmentPlanSkillRequest,
} from './DevelopmentPlanSkill';
import { transformToDevelopmentPlanSkill } from './DevelopmentPlanSkillTransformer';

export const postDevelopmentPlanSkillApiCall = async (requestData: AddDevelopmentPlanSkillRequest, candidateUuid: string): Promise<FetchResult<DevelopmentPlanSkill, string>> => {
    try {
        const response = await lloFetch('/api/developmentPlanSkill/', {
            candidateUuid,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanSkillResource;

        const developmentPlanSkill = transformToDevelopmentPlanSkill(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanSkill,
        };
    } catch (error) {
        console.error('[postDevelopmentPlanSkillApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchDevelopmentPlanSkillApiCall = async (requestData: EditDevelopmentPlanSkillRequest, skillUuid: string, candidateUuid: string): Promise<FetchResult<DevelopmentPlanSkill, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlanSkill/${skillUuid}`, {
            candidateUuid,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanSkillResource;

        const developmentPlanSkill = transformToDevelopmentPlanSkill(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanSkill,
        };
    } catch (error) {
        console.error('[patchDevelopmentPlanSkillApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDevelopmentPlanSkillApiCall = async (skillUuid: string, candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlanSkill/${skillUuid}`, {
            candidateUuid,
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDevelopmentPlanSkillApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
