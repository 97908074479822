import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { capitalizeFirstCharacter } from '../../../../../helpers/string';
import { InternalOccupationSkill } from '../../../../../models/InternalOccupationDetail/InternalOccupationDetail';

import './InternalOccupationDetailContentList.scss';

interface InternalOccupationDetailContentListProps {
    isMatched?: boolean;
    title: string;
    skills: InternalOccupationSkill[];
    className?: string;
}

const InternalOccupationDetailContentList: FC<InternalOccupationDetailContentListProps> = ({
    isMatched,
    title,
    skills,
    className = '',
}): ReactElement => {
    const listItemClassNames = classNames('internal-occupation-detail-content-list__list-item', {
        'internal-occupation-detail-content-list__list-item--is-matched': isMatched,
    });

    return (
        <div className={`internal-occupation-detail-content-list ${className}`}>
            <h3 className="internal-occupation-detail-content-list__title">
                {title}
            </h3>
            <ul className="internal-occupation-detail-content-list__list">
                {skills.map(skill => (
                    <li key={skill.id} className={listItemClassNames}>
                        {capitalizeFirstCharacter(skill.label)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InternalOccupationDetailContentList;
