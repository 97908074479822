import { FC, ReactElement } from 'react';

import { Button, Card, Icon } from '../../../../../components';
import { getRoleForTranslation } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { UserRole } from '../../../../../types';

import './DevelopmentPlanOverviewNullState.scss';

interface DevelopmentOverviewNullStateProps {
    userRoles: UserRole[];
    onAddCompetency: () => void;
    onAddSkill: () => void;
    className?: string;
}

const DevelopmentPlanOverviewNullState: FC<DevelopmentOverviewNullStateProps> = ({
    userRoles,
    onAddCompetency,
    onAddSkill,
    className = '',
}): ReactElement => (
    <Card className={`development-overview-null-state ${className}`}>
        <div className="development-overview-null-state__icon-wrapper">
            <Icon name="development" className="development-overview-null-state__icon" />
        </div>

        <h1 className="development-overview-null-state__title">
            {trans('containers.developmentPlanOverview.nullState.title')}
        </h1>
        <p className="development-overview-null-state__description">
            {trans(`containers.developmentPlanOverview.nullState.description.${getRoleForTranslation(userRoles)}`)}
        </p>

        <div className="development-overview-null-state__action-buttons">
            <Button
                text={trans('containers.developmentPlanOverview.nullState.addCompetency')}
                onClick={onAddCompetency}
                className="development-overview-null-state__button"
            />
            <Button
                text={trans('containers.developmentPlanOverview.nullState.addSkill')}
                onClick={onAddSkill}
                className="development-overview-null-state__button"
            />
        </div>
    </Card>
);

export default DevelopmentPlanOverviewNullState;
