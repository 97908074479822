import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { SafeHtml } from '../../../components';
import { IconButton, SkillsPassportCard } from '../../../compositions';
import { CandidateIllustrationNotification } from '../../../compositions/CandidateIllustration/CandidateIllustration';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

import './PersonalityDescriptionCard.scss';

interface PersonalityDescriptionCardProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    candidate?: Candidate;
    userRoles?: UserRole[];
    consistencyScore: number;
    htmlText?: string;
    onRefresh: () => void;
    className?: string;
}

const PersonalityDescriptionCard: FC<PersonalityDescriptionCardProps> = ({
    isLoading,
    isUpToDate,
    candidate,
    userRoles = [],
    consistencyScore,
    htmlText = '',
    onRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const [showCollapseButton, setShowCollapseButton] = useState<boolean>(false);
    const [isExpanded, toggleIsExpanded] = useToggle(false);

    const htmlWrapperRef = useRef<HTMLDivElement>(null);

    useEffect((): void => {
        setShowCollapseButton(false);

        if (htmlWrapperRef.current) {
            const paragraphs = htmlWrapperRef.current.children;

            setShowCollapseButton(paragraphs.length > 1);
        }
    }, [isLoading, htmlText]);

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidate?.uuid));
    };

    const userRole = userRoles.length > 0
        ? getRoleForTranslation(userRoles)
        : undefined;

    const description = userRole
        ? trans(`containers.personalityDescriptionCard.description.${userRole}`)
        : '';

    const extraDescription = consistencyScore && userRole
        ? trans(`containers.personalityDescriptionCard.consistencyScoreDescription.${userRole}`, { score: consistencyScore })
        : undefined;

    const collapseButtonLabel = isExpanded
        ? trans('containers.personalityDescriptionCard.hideFullDescription')
        : trans('containers.personalityDescriptionCard.showFullDescription');

    const htmlWrapperClassNames = classNames('personality-description-card__html-wrapper', {
        'personality-description-card__html-wrapper--is-expanded': isExpanded,
    });

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={!htmlText}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            candidateGender={candidate?.gender}
            nullStateText={trans('containers.personalityDescriptionCard.nullState')}
            title={trans('containers.personalityDescriptionCard.title')}
            description={description}
            extraDescription={extraDescription}
            candidateIllustrationType={CandidateIllustrationNotification.personalityDescription}
            callToActionButtonLabel={trans('containers.personalityDescriptionCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={onRefresh}
            className={`personality-description-card ${className}`}
        >
            {!isLoading && (
                <SafeHtml
                    ref={htmlWrapperRef}
                    html={htmlText}
                    className={htmlWrapperClassNames}
                />
            )}

            {showCollapseButton && (
                <IconButton
                    icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                    iconPos="right"
                    text={collapseButtonLabel}
                    onClick={toggleIsExpanded}
                    className="personality-description-card__collapse-button"
                />
            )}
        </SkillsPassportCard>
    );
};

export default PersonalityDescriptionCard;
