import { OrganisationOverviewItem, OrganisationOverviewItemResource } from './OrganisationOverviewItem';

export const transformToOrganisationOverviewItem = (
    organisationResource: OrganisationOverviewItemResource,
): OrganisationOverviewItem => ({
    id: organisationResource.id,
    name: organisationResource.name,
    location: organisationResource.location,
    amountOfCounselors: organisationResource.amountOfCounselors,
    amountOfCandidates: organisationResource.amountOfCandidates,
});
