/* eslint-disable prefer-promise-reject-errors */
import { configureRefreshFetch } from 'refresh-fetch';

import { talentzApiUrl } from '../constants';
import { TalentzAccessToken } from '../models/AccessToken';
import { isFetchResultSuccessful } from '../models/FetchResult';
import { RoutePaths } from '../Routes';
import { exchangeTalentzRefreshTokenApiCall } from '../services/TalentzAuthService';
import { AuthToken } from '../types';

interface RequestInitExtraOptions extends RequestInit {
    disableContentType?: boolean;
}

const fetchWithToken = async (endpoint: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithToken: RequestInit = {
        ...options,
        headers: {
            Accept: 'application/json',
            ...options?.headers,
        },
    };

    if (!options?.disableContentType) {
        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            'Content-Type': 'application/json',
        };
    }

    const token = localStorage.getItem(AuthToken.talentz);

    if (token) {
        const parsedToken: TalentzAccessToken = JSON.parse(token);

        optionsWithToken.headers = {
            ...optionsWithToken.headers,
            Authorization: `Bearer ${parsedToken.access_token}`,
        };
    }

    const response = await fetch(talentzApiUrl + endpoint, optionsWithToken);

    if (response.status === 401) {
        return Promise.reject({
            response,
        });
    }

    return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefreshToken = (error: any): boolean => error?.response?.status === 401;

const updateRefreshToken = async (): Promise<void> => {
    const token = localStorage.getItem(AuthToken.talentz);

    if (!token) {
        throw new Error('No token found');
    }

    const parsedToken: TalentzAccessToken = JSON.parse(token);
    const refreshResponse = await exchangeTalentzRefreshTokenApiCall(parsedToken.refresh_token);

    if (!isFetchResultSuccessful(refreshResponse)) {
        localStorage.removeItem(AuthToken.talentz);
        window.location.href = RoutePaths.root();

        throw new Error('Refresh token was invalid');
    }

    localStorage.setItem(AuthToken.talentz, JSON.stringify(refreshResponse.data));
};

export const talentzFetch = configureRefreshFetch({
    fetch: fetchWithToken,
    refreshToken: updateRefreshToken,
    shouldRefreshToken,
});
