import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { Button, Icon } from '../../../components';
import { ButtonWithRefProps } from '../../../components/@buttons/Button/Button';

import './FilterButton.scss';

interface FilterButtonProps extends ButtonWithRefProps {
    hasIcon?: boolean;
    activeFilters?: number;
}

const FilterButton: ForwardRefExoticComponent<FilterButtonProps> = forwardRef(({
    hasIcon,
    activeFilters = 0,
    text,
    className = '',
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => (
    <Button
        {...buttonProps}
        ref={ref}
        text={text}
        className={`filter-button ${className}`}
    >
        {hasIcon && (
            <Icon name="filter" className="filter-button__icon" />
        )}

        <span className="filter-button__label">
            {text}
        </span>

        {activeFilters > 0 && (
            <div className="filter-button__active-filter-count">
                {activeFilters}
            </div>
        )}
    </Button>
));

export default FilterButton;
