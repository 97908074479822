import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Sector } from '../../models/Sectors';
import { AsyncReduxState } from '../../types';

export type SectorsState = AsyncReduxState<{
    sectors: Sector[];
}>;

const initialState: SectorsState = {
    ...initialAsyncReduxState,
    sectors: [],
};

export const sectorsSlice = createSlice({
    name: 'sectorsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SectorsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SectorsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSectors(state, action: PayloadAction<Sector[]>): SectorsState {
            return {
                ...state,
                sectors: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setSectors,
} = sectorsSlice.actions;

export default sectorsSlice.reducer;
