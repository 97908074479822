import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { CandidateFilterValues, CandidateOverview, CandidateOverviewResource } from './CandidateOverview';
import { transformToCandidateOverview } from './CandidateOverviewTransformers';

export const getCandidatesForOrganisationApiCall = async (organisationId: string, activeFilterValues: CandidateFilterValues): Promise<FetchResult<CandidateOverview, string>> => {
    try {
        const {
            pageNumber,
            pageSize,
            sortConfig,
            searchQuery,
            counselorId,
        } = activeFilterValues;

        const response = await lloFetch(`/api/candidate/getAll/${organisationId}?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            method: 'POST',
            body: JSON.stringify({
                sortConfig,
                searchQuery,
                counselorId,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidateOverviewResponse: CandidateOverviewResource = await response.json();

        const candidateOverview = transformToCandidateOverview(candidateOverviewResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidateOverview,
        };
    } catch (error) {
        console.error('[getCandidatesForOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
