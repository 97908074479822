import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TabsNavigation } from '../../../compositions';
import {
    ConnectedOrganisationDetailCandidates,
    ConnectedOrganisationDetailCounselors,
    ConnectedOrganisationDetailModules,
    ConnectedOrganisationDetailOccupation,
    ConnectedOrganisationDetailOccupationOverview,
} from '../../../connectors';
import { removeDisabledModulesFromNavigation } from '../../../helpers/navigation';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { UserModule } from '../../../models/Modules/Modules';
import { RouteParams, RoutePaths } from '../../../Routes';
import { ModuleType, RouteKey, UserRole } from '../../../types';
import { OrganisationDetailTabsSkeletons } from './skeletons';

import './OrganisationDetailTabs.scss';

export enum OrganisationDetailRouteTab {
    default = ':tab',
    counselors = 'adviseurs',
    candidates = 'kandidaten',
    modules = 'modules',
    occupationOverview = 'beroepen',
    occupation = ':occupationUuid',
}

interface OrganisationDetailParams extends RouteParams {
    organisationUuid: string;
    tab?: OrganisationDetailRouteTab;
    occupationUuid?: string;
}

interface OrganisationDetailTabsProps {
    isLoading: boolean;
    userModules?: UserModule[];
    userRoles?: UserRole[];
    className?: string;
}

const OrganisationDetailTabs: FC<OrganisationDetailTabsProps> = ({
    isLoading,
    userModules = [],
    userRoles,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const {
        organisationUuid,
        tab = OrganisationDetailRouteTab.default,
        occupationUuid,
    } = useParams<OrganisationDetailParams>();

    const isLloAdmin = userHasRole(UserRole.lloAdmin, userRoles);

    const tabs = [
        {
            key: RouteKey.organisationDetailCounselors,
            to: RoutePaths.organisationDetailTab(organisationUuid, OrganisationDetailRouteTab.counselors),
            label: trans('containers.organisationDetailTabs.tabs.counselors'),
        },
        {
            key: RouteKey.organisationDetailCandidates,
            to: RoutePaths.organisationDetailTab(organisationUuid, OrganisationDetailRouteTab.candidates),
            label: trans('containers.organisationDetailTabs.tabs.candidates'),
        },
        ...(isLloAdmin ? [
            {
                key: RouteKey.organisationDetailModules,
                to: RoutePaths.organisationDetailTab(organisationUuid, OrganisationDetailRouteTab.modules),
                label: trans('containers.organisationDetailTabs.tabs.modules'),
            },
        ] : []),
        {
            key: RouteKey.organisationDetailOccupationOverview,
            to: RoutePaths.organisationDetailTab(organisationUuid, OrganisationDetailRouteTab.occupationOverview),
            label: trans('containers.organisationDetailTabs.tabs.occupations'),
            module: ModuleType.internalOccupations,
        },
    ];

    useEffect((): void => {
        if (tab === OrganisationDetailRouteTab.default) {
            navigate(RoutePaths.organisationDetailTab(organisationUuid, OrganisationDetailRouteTab.counselors), { replace: true });
        }
    }, [tab]);

    return (
        <div className={`organisation-detail-tabs ${className}`}>
            {isLoading && (
                <OrganisationDetailTabsSkeletons
                    amountOfLabels={3}
                    className="organisation-detail-tabs__skeletons"
                />
            )}

            {!isLoading && (
                <TabsNavigation
                    navigationLabel={trans('containers.organisationDetailTabs.label')}
                    navigationItems={removeDisabledModulesFromNavigation(tabs, userModules)}
                    className="organisation-detail-tabs__tabs-navigation"
                />
            )}

            <div className="organisation-detail-tabs__tab">
                {tab === OrganisationDetailRouteTab.counselors && (
                    <ConnectedOrganisationDetailCounselors />
                )}

                {tab === OrganisationDetailRouteTab.candidates && (
                    <ConnectedOrganisationDetailCandidates />
                )}

                {(tab === OrganisationDetailRouteTab.modules && isLloAdmin) && (
                    <ConnectedOrganisationDetailModules />
                )}

                {tab === OrganisationDetailRouteTab.occupationOverview && !occupationUuid && (
                    <ConnectedOrganisationDetailOccupationOverview />
                )}

                {tab === OrganisationDetailRouteTab.occupationOverview && occupationUuid && (
                    <ConnectedOrganisationDetailOccupation />
                )}
            </div>
        </div>
    );
};

export default OrganisationDetailTabs;
