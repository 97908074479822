/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconVacancySearch: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect x="22.1" y="19.6" width="49" height="9.8" />
        <path d="M34.3,78.5h-22V9.8h68.7v29.5c1.9,1.2,3.7,2.7,5.4,4.3c1.7,1.7,3.2,3.6,4.4,5.5V11c0-6.1-4.9-11-11-11H13.5c-6.1,0-11,4.9-11,11v66.3c0,6.1,4.9,11,11,11h27.3C37.9,85.3,35.8,82,34.3,78.5z" />
        <path d="M32.7,58.9H22.1v9.8H32C31.7,65.4,32,62.1,32.7,58.9z" />
        <path d="M41.2,43.7c1.7-1.7,3.6-3.2,5.5-4.4H22.1V49h14.8C38.1,47.1,39.5,45.3,41.2,43.7z" />
        <path d="M97.6,93.1L82.4,77.9c5.4-8.5,4.4-19.9-3.1-27.3c-8.6-8.6-22.6-8.6-31.2,0c-8.6,8.6-8.6,22.6,0,31.2c7.4,7.4,18.8,8.4,27.3,3.1l15.2,15.2L97.6,93.1z M55.1,74.9c-4.8-4.8-4.8-12.6,0-17.3c4.8-4.8,12.6-4.8,17.3,0c4.8,4.8,4.8,12.6,0,17.3C67.6,79.7,59.9,79.7,55.1,74.9z" />
    </svg>
);

export default IconVacancySearch;
/* eslint-enable max-len */
