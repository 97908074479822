interface NotificationConfig extends NotificationOptions {
    title: string;
}

export const showNotification = (options: NotificationConfig): void => {
    if (Notification.permission === 'granted') {
        navigator.serviceWorker.getRegistration().then(registration => {
            if (registration && typeof registration.showNotification === 'function') {
                registration.showNotification(options.title, {
                    badge: './app-icons/32.png',
                    icon: './app-icons/196.png',
                    lang: 'nl-NL',
                    ...options,
                    data: {
                        dateOfArrival: Date.now(),
                        location: window?.location?.href,
                        ...options.data,
                    },
                });
            }
        });
    }
};
