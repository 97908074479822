import { transformToCompetency } from '../Competencies/CompetenciesTransformer';
import { Skill } from '../Skills/Skills';
import {
    AddOrganisationOccupationRequest,
    EditOrganisationOccupationRequest,
    OrganisationOccupation,
    OrganisationOccupationFormData,
    OrganisationOccupationResource,
} from './OrganisationOccupation';

export const transformToOrganisationOccupation = (
    organisationOccupationResource: OrganisationOccupationResource,
): OrganisationOccupation => {
    const skills: Skill[] = organisationOccupationResource.skills.map(skill => ({
        id: skill.id,
        name: skill.label,
    }));

    return ({
        id: organisationOccupationResource.id,
        title: organisationOccupationResource.title,
        description: organisationOccupationResource.description,
        skills,
        competencies: organisationOccupationResource.competencies.map(transformToCompetency),
    });
};

export const transformToAddOrganisationOccupationRequest = (
    formData: OrganisationOccupationFormData,
    organisationUuid: string,
): AddOrganisationOccupationRequest => ({
    organisationUuid,
    title: formData.title,
    description: formData.description,
});

export const transformToEditOrganisationOccupationRequest = (
    formData: OrganisationOccupationFormData,
    organisationUuid: string,
): EditOrganisationOccupationRequest => ({
    id: formData.id || '',
    organisationUuid,
    title: formData.title,
    description: formData.description,
    skills: formData.skills,
    competencies: formData.competencies,
});
