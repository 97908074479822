import { SearchableOption } from '../types';

export const createCustomOption = (label: string): SearchableOption => ({ value: '', label });

export const searchOptionsOnQuery = (options: SearchableOption[], query: string, limit: number): SearchableOption[] => {
    try {
        if (query === '') return [];

        const searchQuery = new RegExp(query, 'i');

        const labelResults = options.filter(item => item.label.match(searchQuery));
        const secondaryLabelResults = options.filter(item => item?.secondaryLabel?.match(searchQuery));

        const combinedResults = new Set([...labelResults, ...secondaryLabelResults]);
        const resultArray = Array.from(combinedResults);

        return resultArray.slice(0, limit);
    } catch {
        return [];
    }
};
