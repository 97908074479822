import { FC, ReactElement } from 'react';

import { setNavigationParams } from '../../helpers/navigation';
import { NavigationRoute } from '../../Routes';
import { NavigationItem } from './subcomponents';

import './Navigation.scss';

interface NavigationProps {
    candidateUuid?: string;
    organisationUuid?: string;
    navigationLabel: string;
    navigationItems: NavigationRoute[];
    className?: string;
}

const Navigation: FC<NavigationProps> = ({
    candidateUuid = '',
    organisationUuid = '',
    navigationLabel,
    navigationItems,
    className = '',
}): ReactElement => {
    const userSpecificNavigationItems = navigationItems.map(item => setNavigationParams(item, { candidateUuid, organisationUuid }));

    return (
        <nav aria-label={navigationLabel} className={`navigation ${className}`}>
            <ul className="navigation__list">
                {userSpecificNavigationItems.map(navItem => (
                    <NavigationItem {...navItem} key={navItem.key} />
                ))}
            </ul>
        </nav>
    );
};

export default Navigation;
