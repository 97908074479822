import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { OrganisationDetailOccupation } from '../../../containers';
import { OrganisationDetailRouteTab } from '../../../containers/@organisation/OrganisationDetailTabs/OrganisationDetailTabs';
import { CompetencyFormData } from '../../../models/Competencies/Competencies';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { OrganisationOccupation, OrganisationOccupationFormData } from '../../../models/OrganisationOccupation/OrganisationOccupation';
import { SkillsFormData } from '../../../models/Skills/Skills';
import { fetchCompetencies } from '../../../redux/competencies/competenciesActions';
import { setSuggestedSkills } from '../../../redux/escoOccupations/escoOccupations';
import { fetchEscoOccupations, fetchSuggestedSkills } from '../../../redux/escoOccupations/escoOccupationsActions';
import { setIsDeleted } from '../../../redux/organisationOccupation/organisationOccupation';
import { deleteOrganisationOccupation, fetchOrganisationOccupation, updateOrganisationOccupation } from '../../../redux/organisationOccupation/organisationOccupationActions';
import { fetchSkills } from '../../../redux/skills/skillsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';

interface ConnectedOrganisationDetailOccupationProps {
    className?: string;
}

const ConnectedOrganisationDetailOccupation: FC<ConnectedOrganisationDetailOccupationProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { occupationUuid } = useParams();

    const occupationIsLoading = useTypedSelector(state => state.organisationOccupationReducer.isLoading);
    const occupationIsUpdating = useTypedSelector(state => state.organisationOccupationReducer.isUpdating);
    const occupationIsSuccessful = useTypedSelector(state => state.organisationOccupationReducer.isSuccessful);
    const occupationIsDeleted = useTypedSelector(state => state.organisationOccupationReducer.isDeleted);
    const pageError = useTypedSelector(state => state.organisationOccupationReducer.error);
    const formError = useTypedSelector(state => state.organisationOccupationReducer.formError);
    const occupation = useTypedSelector(state => state.organisationOccupationReducer.occupation);

    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);
    const organisation = useTypedSelector(state => state.organisationReducer.organisation);

    const skills = useTypedSelector(state => state.skillsReducer.skills);
    const skillsError = useTypedSelector(state => state.skillsReducer.error);

    const escoOccupations = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const escoOccupationsIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const escoOccupationsError = useTypedSelector(state => state.escoOccupationsReducer.error);
    const suggestedSkills = useTypedSelector(state => state.escoOccupationsReducer.suggestedSkills);

    const competencyOptions = useTypedSelector(state => state.competenciesReducer.competencies);

    const isLoading = [occupationIsLoading, organisationIsLoading].some(Boolean);

    const handleOccupationFormSubmit = (formData: OrganisationOccupationFormData): void => {
        if (organisation) {
            dispatch(updateOrganisationOccupation(formData, organisation.id));
        }
    };

    const handleOccupationDeleteSubmit = (occupationToDelete: OrganisationOccupation): void => {
        if (organisation) {
            dispatch(deleteOrganisationOccupation(occupationToDelete, organisation.id));
        }
    };

    const handleSelectOccupation = (selectedOccupation: EscoOccupation): void => {
        dispatch(fetchSuggestedSkills(selectedOccupation.id));
    };

    const handleUpdateSkills = (skillsFormData: SkillsFormData): void => {
        if (organisation) {
            const formData: OrganisationOccupationFormData = {
                ...occupation,
                skills: skillsFormData.skills.map(skill => skill.id),
                competencies: occupation.competencies.map(competency => competency.id),
            };

            dispatch(updateOrganisationOccupation(formData, organisation.id));
        }
    };

    const handleCloseSkillsDialog = (): void => {
        dispatch(setSuggestedSkills([]));
    };

    const handleUpdateCompetencies = (competenciesFormData: CompetencyFormData): void => {
        if (organisation) {
            const formData: OrganisationOccupationFormData = {
                ...occupation,
                skills: occupation.skills.map(skill => skill.id),
                competencies: competenciesFormData.competencies,
            };

            dispatch(updateOrganisationOccupation(formData, organisation.id));
        }
    };

    useEffect((): () => void => {
        if (occupationIsDeleted && organisation) {
            navigate(RoutePaths.organisationDetailTab(organisation.id, OrganisationDetailRouteTab.occupationOverview));
        }

        return (): void => {
            dispatch(setIsDeleted(false));
        };
    }, [occupationIsDeleted]);

    useEffect((): void => {
        if (occupationUuid && organisation) dispatch(fetchOrganisationOccupation(organisation?.id, occupationUuid));
    }, [organisation, occupationUuid]);

    useEffect((): void => {
        if (skills.length === 0) dispatch(fetchSkills());
        if (escoOccupations.length === 0) dispatch(fetchEscoOccupations());
        if (competencyOptions.length === 0) dispatch(fetchCompetencies());
    }, []);

    return (
        <OrganisationDetailOccupation
            isLoading={isLoading}
            suggestionIsLoading={escoOccupationsIsLoading}
            isUpdating={occupationIsUpdating}
            isSuccessful={occupationIsSuccessful}
            pageError={pageError}
            formError={formError}
            occupation={occupation}
            organisationUuid={organisation?.id}
            suggestedSkills={suggestedSkills}
            skillOptions={skills}
            skillsError={skillsError || escoOccupationsError}
            occupationOptions={escoOccupations}
            competencyOptions={competencyOptions}
            onOccupationFormSubmit={handleOccupationFormSubmit}
            onOccupationDeleteSubmit={handleOccupationDeleteSubmit}
            onSelectOccupation={handleSelectOccupation}
            onUpdateSkills={handleUpdateSkills}
            onCloseSkillsDialog={handleCloseSkillsDialog}
            onUpdateCompetencies={handleUpdateCompetencies}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailOccupation;
