import { addMonthsToGivenDate, formatDate, isDateInputSupported } from '../../helpers/date';

export enum TmaAnalysisType {
    TMA170 = 'TMA170',
    TMA312 = 'TMA312',
}

export interface TmaAnalysisSettingsResource {
    type: string;
    startDate: string;
    endDate: string;
    created: boolean;
    finished: boolean;
}

export interface TmaAnalysisSettings {
    type: TmaAnalysisType;
    startDate: string;
    endDate: string;
    isActive: boolean;
    isFinished: boolean;
}

export type TmaAnalysisSettingsFormData = Omit<TmaAnalysisSettings, 'isActive' | 'isFinished'>;

export const defaultTmaAnalysisSettings = (): TmaAnalysisSettings => ({
    type: TmaAnalysisType.TMA170,
    startDate: isDateInputSupported()
        ? formatDate(new Date(), 'yyyy-MM-dd')
        : formatDate(new Date()),
    endDate: isDateInputSupported()
        ? formatDate(addMonthsToGivenDate(new Date(), 1), 'yyyy-MM-dd')
        : formatDate(addMonthsToGivenDate(new Date(), 1)),
    isActive: false,
    isFinished: false,
});
