import { aliasSplitCharacter } from '../constants';
import { UserModule } from '../models/Modules/Modules';
import {
    ModuleType,
    RouteKey,
    SkillsPassportAction,
    UserRole,
} from '../types';

export const userMainNavigationRoutes: Record<UserRole, RouteKey[]> = {
    [UserRole.lloAdmin]: [
        RouteKey.organisationOverview,
    ],
    [UserRole.organisationAdmin]: [
        RouteKey.organisationDetail,
    ],
    [UserRole.tmaExpert]: [
        RouteKey.dashboard,
        RouteKey.manageAnalyses,
    ],
    [UserRole.counselor]: [
        RouteKey.dashboard,
    ],
    [UserRole.candidate]: [
        RouteKey.candidateDashboard,
        RouteKey.skillsPassport,
        RouteKey.development,
        RouteKey.takeAnalysis,
        RouteKey.seekEducation,
        RouteKey.seekOccupation,
        RouteKey.seekVacancies,
    ],
};

export const counselorCandidateMainNavigationRoutes: RouteKey[] = [
    RouteKey.candidateDashboard,
    RouteKey.skillsPassport,
    RouteKey.development,
    RouteKey.takeAnalysis,
    RouteKey.seekEducation,
    RouteKey.seekOccupation,
    RouteKey.seekVacancies,
];

export const userForbiddenRoutes: Record<UserRole, RouteKey[]> = {
    [UserRole.lloAdmin]: [RouteKey.candidateExchange, RouteKey.manageAnalyses, RouteKey.registration],
    [UserRole.organisationAdmin]: [RouteKey.candidateExchange, RouteKey.manageAnalyses, RouteKey.organisationOverview, RouteKey.registration],
    [UserRole.tmaExpert]: [RouteKey.candidateExchange, RouteKey.organisationDetail, RouteKey.organisationOverview, RouteKey.registration],
    [UserRole.counselor]: [RouteKey.candidateExchange, RouteKey.manageAnalyses, RouteKey.organisationDetail, RouteKey.organisationOverview, RouteKey.registration],
    [UserRole.candidate]: [RouteKey.manageAnalyses, RouteKey.organisationDetail, RouteKey.organisationOverview],
};

export const userProfileNavigationItems: Record<UserRole, RouteKey[]> = {
    [UserRole.lloAdmin]: [RouteKey.settings, RouteKey.logout],
    [UserRole.organisationAdmin]: [RouteKey.settings, RouteKey.logout],
    [UserRole.tmaExpert]: [RouteKey.settings, RouteKey.logout],
    [UserRole.counselor]: [RouteKey.settings, RouteKey.logout],
    [UserRole.candidate]: [RouteKey.settings, RouteKey.logout],
};

export const userSkillsPassportActions: Record<UserRole, SkillsPassportAction[]> = {
    [UserRole.lloAdmin]: [],
    [UserRole.organisationAdmin]: [],
    [UserRole.tmaExpert]: [SkillsPassportAction.tmaAnalysisSettings, SkillsPassportAction.download],
    [UserRole.counselor]: [SkillsPassportAction.tmaAnalysisSettings, SkillsPassportAction.download],
    [UserRole.candidate]: [SkillsPassportAction.download],
};

export const generateFullName = (names: {
    firstName?: string;
    lastNamePrefix?: string;
    lastName?: string;
}): string => {
    const { firstName, lastNamePrefix, lastName } = names;

    if (!lastName) return firstName || '';

    if (!firstName) {
        return lastNamePrefix
            ? `${lastNamePrefix} ${lastName}`
            : lastName;
    }

    return lastNamePrefix
        ? `${firstName} ${lastNamePrefix} ${lastName}`
        : `${firstName} ${lastName}`;
};

export const splitAliasIntoNameObject = (alias: string): { firstName: string; lastNamePrefix: string; lastName: string } => {
    const [firstName, lastNamePrefix, lastName] = alias.split(aliasSplitCharacter);

    return {
        firstName,
        lastNamePrefix,
        lastName,
    };
};

export const userHasModule = (userModules: UserModule[], module: ModuleType): boolean => {
    const usermodule = userModules.find(userModule => userModule.label === module);

    return !!usermodule?.isActive;
};
