import {
    CSSProperties,
    FC,
    ReactElement,
    useMemo,
} from 'react';

import classNames from 'classnames';

import { Icon } from '..';
import { IconName } from '../Icon/Icon';

import './CircleProgress.scss';

export enum ProgressState {
    positive = 'positive',
    warning = 'warning',
    negative = 'negative',
    neutral = 'neutral',
}

interface CircleProgressProps {
    showSteps?: boolean;
    showPercentage?: boolean;
    warningThreshold?: number;
    positiveThreshold?: number;
    totalSteps?: number;
    finishedSteps: number;
    icon?: IconName;
    className?: string;
}

const CircleProgress: FC<CircleProgressProps> = ({
    showSteps,
    showPercentage,
    warningThreshold = 10,
    positiveThreshold = 100,
    totalSteps = 100,
    finishedSteps,
    icon,
    className = '',
}): ReactElement => {
    const percentage = totalSteps ? Math.ceil((finishedSteps / totalSteps) * 100) : 0;

    const state = useMemo((): ProgressState => {
        if (percentage >= positiveThreshold) return ProgressState.positive;

        if (percentage >= warningThreshold) {
            return ProgressState.warning;
        }

        return ProgressState.neutral;
    }, [percentage]);

    const circleProps = {
        cx: 18,
        cy: 18,
        r: 16,
    };

    const cssVariables = {
        '--circle-progress-value': 100 - percentage,
    } as CSSProperties;

    const circleProgressClassNames = classNames('circle-progress', {
        [`circle-progress--${state}`]: state,
    }, className);

    return (
        <div className={circleProgressClassNames}>
            <svg
                style={cssVariables}
                viewBox="0 0 36 36"
                className="circle-progress__svg"
            >
                <circle {...circleProps} className="circle-progress__path" />
                <circle {...circleProps} className="circle-progress__path circle-progress__path--meter" />
            </svg>

            {icon && (
                <div className="circle-progress__icon-wrapper">
                    <Icon name={icon} className="circle-progress__icon" />
                </div>
            )}

            {(showSteps || showPercentage) && (
                <div className="circle-progress__tooltip">
                    {showSteps && (
                        <p>{`${finishedSteps}/${totalSteps}`}</p>
                    )}

                    {showPercentage && !showSteps && (
                        <p>{`${percentage}%`}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default CircleProgress;
