import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { DevelopmentOverview } from '../../models/DevelopmentOverview/DevelopmentOverview';
import { AsyncReduxState } from '../../types';

export type DevelopmentOverviewState = AsyncReduxState<{
    developments: DevelopmentOverview[];
}>;

const initialState: DevelopmentOverviewState = {
    ...initialAsyncReduxState,
    developments: [],
};

export const developmentsSlice = createSlice({
    name: 'developmentsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DevelopmentOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DevelopmentOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDevelopments(state, action: PayloadAction<DevelopmentOverview[]>): DevelopmentOverviewState {
            return {
                ...state,
                developments: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setDevelopments,
} = developmentsSlice.actions;

export default developmentsSlice.reducer;
