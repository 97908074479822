import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    ConfirmDialog,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../../compositions';
import { ConfirmType } from '../../../../../compositions/ConfirmDialog/ConfirmDialog';
import { formatDate, isFutureDate } from '../../../../../helpers/date';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { SchoolExperience, SchoolExperienceFormData } from '../../../../../models/SchoolExperience/SchoolExperience';
import { SchoolExperienceForm } from '../../../..';
import { SchoolExperienceFormOptions } from '../../../../@forms/SchoolExperienceForm/SchoolExperienceForm';
import { getEducationLevelDescription } from '../../helpers';

import './SchoolExperienceItem.scss';

interface SchoolExperienceItemProps extends SchoolExperienceFormOptions {
    isLoading: boolean;
    isSuccessful: boolean;
    isEditable?: boolean;
    error: string;
    schoolExperience: SchoolExperience;
    onEdit: (schoolExperienceFormData: SchoolExperienceFormData) => void;
    onDelete: (schoolExperience: SchoolExperience) => void;
    onIsSuccessfulReset: () => void;
    className?: string;
}

const SchoolExperienceItem: FC<SchoolExperienceItemProps> = ({
    isLoading,
    isSuccessful,
    isEditable = false,
    error,
    schoolExperience,
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions,
    fieldOfStudyOptions,
    schoolOptions,
    onEdit,
    onDelete,
    onIsSuccessfulReset,
    className = '',
}): ReactElement => {
    const editDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDialogRef = useRef<HTMLDialogElement>(null);

    const [schoolExperienceFormKey, setSchoolExperienceFormKey] = useState<number>(0);

    const handleOpenEditDialog = (): void => openDialog(editDialogRef);
    const handleCloseEditDialog = (): void => {
        closeDialog(editDialogRef);

        setSchoolExperienceFormKey(schoolExperienceFormKey + 1);
    };

    const handleOpenDeleteDialog = (): void => openDialog(deleteDialogRef);
    const handleCloseDeleteDialog = (): void => closeDialog(deleteDialogRef);

    const handleDeleteSchoolExperience = (): void => {
        onDelete(schoolExperience);
    };

    useEffect((): void => {
        if (isSuccessful) {
            handleCloseEditDialog();
            handleCloseDeleteDialog();
            onIsSuccessfulReset();
        }
    }, [isSuccessful]);

    return (
        <li className={`school-experience-item ${className}`}>
            <div className="school-experience-item__content-wrapper">
                <h3 className="school-experience-item__field-of-study">
                    {schoolExperience.fieldOfStudy}
                    <span className="school-experience-item__school">
                        {trans('containers.schoolExperiencesCard.atSchool', {
                            school: schoolExperience.school,
                        })}
                    </span>
                </h3>
                <p className="school-experience-item__dates">
                    {trans('containers.schoolExperiencesCard.dates', {
                        startDate: formatDate(schoolExperience.startDate, 'MMMM yyyy'),
                        endDate: isFutureDate(schoolExperience.endDate)
                            ? trans('common.presentTime')
                            : formatDate(schoolExperience.endDate, 'MMMM yyyy'),
                    })}
                </p>

                {schoolExperience.educationLevel !== trans('common.other') && (
                    <p className="school-experience-item__description">
                        {getEducationLevelDescription(schoolExperience.educationLevel, schoolExperience.learningPath)}
                    </p>
                )}
            </div>

            {isEditable && (
                <div className="school-experience-item__controls-wrapper">
                    <IconButton
                        icon="edit"
                        text={trans('common.edit')}
                        hideLabel
                        onClick={handleOpenEditDialog}
                        className="school-experience-item__control-button"
                    />

                    <IconButton
                        icon="bin"
                        text={trans('common.delete')}
                        hideLabel
                        onClick={handleOpenDeleteDialog}
                        className="school-experience-item__control-button school-experience-item__control-button--is-delete"
                    />
                </div>
            )}

            <Dialog ref={editDialogRef} onClose={handleCloseEditDialog}>
                <DialogContent title={trans('forms.schoolExperience.editTitle')}>
                    <SchoolExperienceForm
                        key={schoolExperienceFormKey}
                        isLoading={isLoading}
                        error={error}
                        schoolExperience={schoolExperience}
                        educationLevelOptions={educationLevelOptions}
                        educationSubLevelOptions={educationSubLevelOptions}
                        learningPathOptions={learningPathOptions}
                        fieldOfStudyOptions={fieldOfStudyOptions}
                        schoolOptions={schoolOptions}
                        onSubmit={onEdit}
                        onCancel={handleCloseEditDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDialogRef}
                onClose={handleCloseDeleteDialog}
            >
                <ConfirmDialog
                    isLoading={isLoading}
                    error={error}
                    title={trans('containers.schoolExperiencesCard.confirmDelete.title')}
                    description={trans('containers.schoolExperiencesCard.confirmDelete.paragraph', {
                        school: schoolExperience.school,
                    })}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onConfirm={handleDeleteSchoolExperience}
                    onCancel={handleCloseDeleteDialog}
                />
            </Dialog>
        </li>
    );
};

export default SchoolExperienceItem;
