import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Card, Icon } from '../../../../components';
import { Action } from '../../../../connectors/ConnectedMyActions/ConnectedMyActions';
import trans from '../../../../helpers/trans';

import './ActionItem.scss';

interface ActionItemProps {
    isLoading: boolean;
    showContent: boolean;
    index: number;
    action: Action;
    totalAmountOfActions: number;
    onToggleActionItem: (action?: Action) => void;
    className?: string;
}

const ActionItem: FC<ActionItemProps> = ({
    isLoading,
    showContent,
    index,
    action,
    totalAmountOfActions,
    onToggleActionItem,
    className = '',
}): ReactElement => {
    const handleToggleActionItem = (): void => onToggleActionItem(showContent ? undefined : action);

    const indexClassNames = classNames('action-item__index', {
        'action-item__index--is-active': showContent,
        'action-item__index--is-completed': action.isCompleted,
        'action-item__index--is-first-item': index === 0,
        'action-item__index--is-last-item': index === totalAmountOfActions - 1,
    });

    const titleClassNames = classNames('action-item__title', {
        'action-item__title--is-active': showContent,
    });

    return (
        <li className={`action-item ${className}`}>
            <Card className="action-item__card">
                <header className="action-item__header">
                    <Button
                        text={showContent
                            ? trans('containers.myActions.hideContent')
                            : trans('containers.myActions.showContent')}
                        disabled={isLoading}
                        onClick={handleToggleActionItem}
                        className="action-item__header-button"
                    >
                        <span className={indexClassNames}>
                            {action.isCompleted
                                ? <Icon name="check" className="action-item__check-icon" />
                                : index + 1}
                        </span>

                        <div className="action-item__header-text-wrapper">
                            <h2 className={titleClassNames}>{action.title}</h2>

                            <div className="action-item__header-extra-wrapper">
                                {action.isOptional && (
                                    <p className="action-item__optional-label">
                                        {trans('containers.myActions.optional')}
                                    </p>
                                )}

                                {!showContent && (
                                    <p className="action-item__toggle-label">
                                        {trans('containers.myActions.showContent')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Button>
                </header>

                {showContent && (
                    <div className="action-item__content">
                        <div>{action.content}</div>
                    </div>
                )}
            </Card>
        </li>
    );
};

export default ActionItem;
