import { FC, ReactElement } from 'react';

import { Organisation } from '../../../../../models/Organisation/Organisation';

import './OrganisationSearchResult.scss';

interface OrganisationSearchResultProps {
    name: string;
    organisation: Organisation;
    checked: boolean;
    onSelect: (organisation: Organisation) => void;
    className?: string;
}

const OrganisationSearchResult: FC<OrganisationSearchResultProps> = ({
    name,
    organisation,
    checked,
    onSelect,
    className = '',
}): ReactElement => {
    const handleChange = (): void => onSelect(organisation);

    return (
        <li className={`organisation-search-result ${className}`}>
            <label className="organisation-search-result__label">
                <input
                    type="radio"
                    name={name}
                    value={organisation.id}
                    checked={checked}
                    onChange={handleChange}
                    className="organisation-search-result__input"
                />

                <div className="organisation-search-result__box" />

                <div className="organisation-search-result__text-wrapper">
                    <h2 className="organisation-search-result__name">
                        {organisation.name}
                    </h2>

                    <p className="organisation-search-result__email">
                        {organisation.location}
                    </p>
                </div>
            </label>
        </li>
    );
};

export default OrganisationSearchResult;
