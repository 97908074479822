import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { LearningStyle } from '../../../../../models/SkillsPassport/SkillsPassport';

import './LearningStyleGraph.scss';

interface LearningStyleGraphProps {
    learningStyle: LearningStyle;
    className?: string;
}

const LearningStyleGraph: FC<LearningStyleGraphProps> = ({
    learningStyle,
    className = '',
}): ReactElement => {
    const { graph } = learningStyle;

    const xAxisKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const yAxisKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

    const selectedCoordinates = yAxisKeys[graph.yAxis.value - 1] + xAxisKeys[graph.xAxis.value - 1];

    return (
        <div className={`learning-style-graph ${className}`}>
            <p className="learning-style-graph__y-axis-label">
                {graph.yAxis.topLabel}
            </p>

            <p className="learning-style-graph__x-axis-left-label">
                {graph.xAxis.leftLabel}
            </p>

            <div className="learning-style-graph__dots">
                {yAxisKeys.map((y, yIndex) => (
                    <div key={y} className="learning-style-graph__row">
                        {xAxisKeys.map((x, xIndex) => {
                            const currentCoordinates = yAxisKeys[yIndex] + xAxisKeys[xIndex];

                            const dotWrapperClassNames = classNames('learning-style-graph__dot', {
                                'learning-style-graph__dot--is-selected': currentCoordinates === selectedCoordinates,
                            });

                            return (
                                <div key={x} className={dotWrapperClassNames} />
                            );
                        })}
                    </div>
                ))}
            </div>

            <p className="learning-style-graph__y-axis-label">
                {graph.yAxis.bottomLabel}
            </p>

            <p className="learning-style-graph__x-axis-right-label">
                {graph.xAxis.rightLabel}
            </p>
        </div>
    );
};

export default LearningStyleGraph;
