import { FC, ReactElement } from 'react';

import trans from '../../../../../helpers/trans';
import { CounselorOption } from '../../../../../models/Counselor/Counselor';

import './CounselorSearchResult.scss';

interface CounselorSearchResultProps {
    name: string;
    counselor: CounselorOption;
    checked: boolean;
    onSelect: (counselor: CounselorOption) => void;
    className?: string;
}

const CounselorSearchResult: FC<CounselorSearchResultProps> = ({
    name,
    counselor,
    checked,
    onSelect,
    className = '',
}): ReactElement => {
    const handleChange = (): void => onSelect(counselor);

    return (
        <li className={`counselor-search-result ${className}`}>
            <label className="counselor-search-result__label">
                <input
                    type="radio"
                    name={name}
                    value={counselor.id}
                    checked={checked}
                    onChange={handleChange}
                    className="counselor-search-result__input"
                />

                <div className="counselor-search-result__box" />

                <div className="counselor-search-result__text-wrapper">
                    <h2 className="counselor-search-result__name">
                        {counselor.fullName}
                    </h2>

                    <p className="counselor-search-result__roles">
                        {counselor.roles.map(role => trans(`roles.${role}`)).join(', ')}
                    </p>

                    <p className="counselor-search-result__email">
                        {counselor.email}
                    </p>
                </div>
            </label>
        </li>
    );
};

export default CounselorSearchResult;
