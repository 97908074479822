import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { OrganisationDetailOccupationOverview } from '../../../containers';
import { scrollToTop } from '../../../helpers/scroll';
import { OrganisationOccupationFormData } from '../../../models/OrganisationOccupation/OrganisationOccupation';
import { createOrganisationOccupation } from '../../../redux/organisationOccupation/organisationOccupationActions';
import { setActiveFilterValues } from '../../../redux/organisationOccupationOverview/organisationOccupationOverview';
import { fetchOrganisationOccupationOverview, setOrganisationOccupationOverviewPagination } from '../../../redux/organisationOccupationOverview/organisationOccupationOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrganisationDetailOccupationOverviewProps {
    className?: string;
}

const ConnectedOrganisationDetailOccupationOverview: FC<ConnectedOrganisationDetailOccupationOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { organisationUuid } = useParams();

    const overviewIsLoading = useTypedSelector(state => state.organisationOccupationOverviewReducer.isLoading);
    const error = useTypedSelector(state => state.organisationOccupationOverviewReducer.error);
    const activeFilterValues = useTypedSelector(state => state.organisationOccupationOverviewReducer.activeFilterValues);
    const occupationOverview = useTypedSelector(state => state.organisationOccupationOverviewReducer.occupationOverview);
    const pagination = useTypedSelector(state => state.organisationOccupationOverviewReducer.pagination);

    const occupationIsLoading = useTypedSelector(state => state.organisationOccupationReducer.isLoading);
    const occupationError = useTypedSelector(state => state.organisationOccupationReducer.formError);
    const occupationIsSuccessful = useTypedSelector(state => state.organisationOccupationReducer.isSuccessful);

    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);
    const organisation = useTypedSelector(state => state.organisationReducer.organisation);

    const isLoading = [overviewIsLoading, organisationIsLoading].some(Boolean);

    const handlePaginationChange = (pageNumber: number): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber,
        };

        dispatch(setOrganisationOccupationOverviewPagination(pageNumber));
        dispatch(setActiveFilterValues(newFilterValues));

        scrollToTop();
    };


    const handleOccupationFormSubmit = (formData: OrganisationOccupationFormData): void => {
        if (organisation) {
            dispatch(createOrganisationOccupation(formData, organisation.id));
        }
    };

    useEffect((): void => {
        if (organisationUuid) {
            dispatch(fetchOrganisationOccupationOverview(organisationUuid, activeFilterValues));
        }
    }, [activeFilterValues]);

    useEffect((): void => {
        if (occupationIsSuccessful) handlePaginationChange(1);
    }, [occupationIsSuccessful]);

    return (
        <OrganisationDetailOccupationOverview
            isLoading={isLoading}
            occupationIsLoading={occupationIsLoading}
            occupationIsSuccessful={occupationIsSuccessful}
            error={error}
            occupationError={occupationError}
            organisationUuid={organisation?.id}
            totalAmount={occupationOverview.totalAmount}
            occupations={occupationOverview.occupations}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            onOccupationFormSubmit={handleOccupationFormSubmit}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailOccupationOverview;
