import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { Breadcrumbs } from '../../compositions';
import { ConnectedInternalOccupationDetailContent, ConnectedInternalOccupationDetailHeader, ConnectedPage } from '../../connectors';
import { OccupationRouteTab } from '../../containers/@occupation/OccupationTabs/OccupationTabs';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { InternalOccupationDetail as InternalOccupationDetailModel } from '../../models/InternalOccupationDetail/InternalOccupationDetail';
import { RoutePaths } from '../../Routes';

import './InternalOccupationDetail.scss';

const InternalOccupationDetail: FC = (): ReactElement => {
    const [candidate, setCandidate] = useState<Candidate>();
    const [occupation, setOccupation] = useState<InternalOccupationDetailModel>();

    const breadcrumbs = [
        {
            label: trans('pages.internalOccupationDetail.breadcrumb'),
            to: RoutePaths.seekOccupationTab(candidate?.uuid, OccupationRouteTab.internalOccupations),
        },
        { label: occupation?.name || '' },
    ];

    return (
        <ConnectedPage
            hasNavigation
            title={trans('pages.internalOccupationDetail.title')}
            className="internal-occupation-detail-page"
        >
            <Helmet>
                <title>
                    {trans('pages.internalOccupationDetail.metaTitle', {
                        name: occupation?.name || '',
                    })}
                </title>
            </Helmet>

            <Breadcrumbs crumbs={breadcrumbs} />

            <ConnectedInternalOccupationDetailHeader
                onCandidateResponse={setCandidate}
                onInternalOccupationDetailResponse={setOccupation}
                className="internal-occupation-detail-page__overview"
            />

            <ConnectedInternalOccupationDetailContent
                className="internal-occupation-detail-page__overview"
            />
        </ConnectedPage>
    );
};

export default InternalOccupationDetail;
