import { capitalizeFirstCharacter } from '../../helpers/string';
import { InternalOccupationDetail, InternalOccupationDetailResource } from './InternalOccupationDetail';

export const transformToInternalOccupationDetail = (
    internalOccupationDetailResource: InternalOccupationDetailResource,
): InternalOccupationDetail => ({
    id: internalOccupationDetailResource.id,
    name: capitalizeFirstCharacter(internalOccupationDetailResource.title),
    description: internalOccupationDetailResource.description,
    totalSkills: internalOccupationDetailResource.amountOfSkills,
    matchedSkills: internalOccupationDetailResource.amountOfMatchedSkills,
    totalCompetencies: internalOccupationDetailResource.amountOfCompetencies,
    matchedCompetencies: internalOccupationDetailResource.amountOfMatchedCompetencies,
    matchedPercentage: internalOccupationDetailResource.totalMatchedPercentage,
    skills: internalOccupationDetailResource.skills,
    competencies: internalOccupationDetailResource.competencies,
});
