import { groupArrayByValueOfKey } from '../../helpers/array';
import { sortByStartDateAsc } from '../../helpers/sort';
import { transformToCompetency } from '../Competencies/CompetenciesTransformer';
import { transformToJobExperience } from '../JobExperience/JobExperienceTransformers';
import { transformToSchoolExperience } from '../SchoolExperience/SchoolExperienceTransformers';
import { transformToSkill } from '../Skills/SkillsTransformers';
import {
    defaultCommunicationStyles,
    defaultLearningStyle,
    Incentive,
    IncentiveResource,
    LearningStyle,
    LearningStyleResource,
    SkillsPassport,
    SkillsPassportResource,
    Talent,
    TalentResource,
} from './SkillsPassport';

const transformToIncentive = (
    incentiveResource: IncentiveResource,
): Incentive => ({
    dimension: incentiveResource.dimension,
    name: incentiveResource.name,
    description: incentiveResource.definition,
    startLabel: incentiveResource.talentLow,
    endLabel: incentiveResource.talentHigh,
    value: incentiveResource.value,
});

const transformToLearningStyle = (
    learningStyleResource: LearningStyleResource,
): LearningStyle => ({
    styles: learningStyleResource.learningStyles,
    graph: {
        xAxis: {
            leftLabel: learningStyleResource.learningStyleAxes.horizontal.low,
            rightLabel: learningStyleResource.learningStyleAxes.horizontal.high,
            value: learningStyleResource.learningStyleAxes.vertical.normScore,
        },
        yAxis: {
            topLabel: learningStyleResource.learningStyleAxes.vertical.high,
            bottomLabel: learningStyleResource.learningStyleAxes.vertical.low,
            value: learningStyleResource.learningStyleAxes.horizontal.normScore,
        },
    },
});

const transformToTalent = (
    talentResource: TalentResource,
): Talent => {
    const descriptions = talentResource.descriptions.map(item => ({
        title: item.title,
        description: item.text,
    }));

    return ({
        name: talentResource.name,
        value: talentResource.value,
        introduction: talentResource.intro,
        descriptions,
    });
};

export const transformToSkillsPassport = (
    skillsPassportResource: SkillsPassportResource,
): SkillsPassport => {
    const incentives = skillsPassportResource.drives.map(transformToIncentive);
    const groupedIncentives = skillsPassportResource.drives.length > 0
        ? groupArrayByValueOfKey(incentives, 'dimension')
        : undefined;

    const competencies = skillsPassportResource.competences.map(transformToCompetency);
    const groupedCompetencies = skillsPassportResource.competences.length > 0
        ? groupArrayByValueOfKey(competencies, 'level')
        : undefined;

    const jobExperiences = skillsPassportResource.workingExperiences.map(transformToJobExperience);
    const sortedJobExperiences = [...jobExperiences].sort(sortByStartDateAsc);

    const schoolExperiences = skillsPassportResource.schoolExperiences.map(transformToSchoolExperience);
    const sortedSchoolExperiences = [...schoolExperiences].sort(sortByStartDateAsc);

    return ({
        communicationStyles: skillsPassportResource.communicationStyle
            ? {
                effective: skillsPassportResource.communicationStyle.effectiveCommunicationBlocks,
                ineffective: skillsPassportResource.communicationStyle.inEffectiveCommunicationBlocks,
            }
            : defaultCommunicationStyles(),
        consistencyScore: skillsPassportResource.consistencyScore,
        workplacePreferences: skillsPassportResource.workingEnvironment,
        incentives: groupedIncentives,
        learningStyle: skillsPassportResource.learningStyle
            ? transformToLearningStyle(skillsPassportResource.learningStyle)
            : defaultLearningStyle(),
        competencies: groupedCompetencies,
        personalityDescription: skillsPassportResource.summary || '',
        talents: skillsPassportResource.talents.map(transformToTalent),
        skills: skillsPassportResource.skills.map(transformToSkill),
        jobExperiences: sortedJobExperiences,
        schoolExperiences: sortedSchoolExperiences,
        updatedAt: new Date(),
        isUpToDate: false,
    });
};
