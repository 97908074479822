import { generateFullName } from '../../helpers/user';
import { FormOption } from '../../types';
import {
    CandidateRelation,
    CandidateRelationFormOptionResource,
    CandidateRelationResource,
    CandidateRelations,
    CandidateRelationsResource,
} from './CandidateRelations';

export const transformToCandidateRelation = (
    candidateRelationResource: CandidateRelationResource,
): CandidateRelation => {
    const fullName = generateFullName(candidateRelationResource);

    return ({
        id: candidateRelationResource.id,
        email: candidateRelationResource.email,
        fullName,
        dateOfBirth: candidateRelationResource.dateOfBirth
            ? new Date(candidateRelationResource.dateOfBirth)
            : undefined,
        city: candidateRelationResource.city,
        createdAt: new Date(candidateRelationResource.createdAt),
    });
};

export const transformToCandidateRelations = (
    candidateRelationsResource: CandidateRelationsResource,
): CandidateRelations => ({
    totalPages: candidateRelationsResource.totalPages,
    candidates: candidateRelationsResource.candidates.map(transformToCandidateRelation),
});

export const transformToCandidateRelationFormOption = (
    candidateRelationFormOption: CandidateRelationFormOptionResource,
): FormOption => ({
    value: candidateRelationFormOption.id,
    label: generateFullName(candidateRelationFormOption),
});

export const transformCandidateRelationToFormOption = (
    candidateRelation: CandidateRelation,
): FormOption => ({
    value: candidateRelation.id,
    label: candidateRelation.fullName,
});
