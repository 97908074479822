// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectContainsValues = <T extends Record<string, any>>(object: T): boolean => {
    const valueArray = Object.values(object);

    for (let i = 0; i < valueArray.length; i += 1) {
        if (![undefined, null].includes(valueArray[i])) {
            return true;
        }
    }

    return false;
};

export const getAmountOfDefinedValues = (object: Record<string, unknown>): number => {
    let amount = 0;

    Object.values(object).forEach(param => {
        if (param !== undefined) {
            amount += 1;
        }
    });

    return amount;
};

export const objectsAreEqual = (object: Record<string, unknown>, comparisonObject: Record<string, unknown>): boolean => {
    const sortedObject = Object.keys(object).sort().reduce((obj: Record<string, unknown>, key: string) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = object[key];
        return obj;
    }, {});

    const sortedComparisonObject = Object.keys(comparisonObject).sort().reduce((obj: Record<string, unknown>, key: string) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = comparisonObject[key];
        return obj;
    }, {});

    return JSON.stringify(sortedObject) === JSON.stringify(sortedComparisonObject);
};
