import { IconName } from '../components/Icon/Icon';

export enum AuthToken {
    auth = 'authToken',
    talentz = 'talentzToken',
}

export enum SortField {
    name = 'name',
    candidateName = 'candidateName',
    counselorName = 'counselorName',
    city = 'city',
    createdAt = 'createdAt',
}

export interface SortConfig {
    field: SortField;
    direction: SortDirection;
}

export enum SortDirection {
    asc = 'ASCENDING',
    desc = 'DESCENDING',
}

export enum Gender {
    male = 'male',
    female = 'female',
}

export enum HorizontalAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum MessageState {
    positive = 'positive',
    warning = 'warning',
    negative = 'negative',
}

export enum NoticeType {
    regular = 'regular',
    error = 'error',
    warning = 'warning',
}

export enum RouteKey {
    account = 'account',
    home = 'home',
    registration = 'registration',
    candidateDashboard = 'candidate-dashboard',
    candidateExchange = 'candidate-exchange',
    coachingAdvice = 'coaching-advice',
    dashboard = 'dashboard',
    development = 'development',
    developmentPlan = 'development-plan',
    developmentPlanProgress = 'development-plan-progress',
    developmentPlanPublicNotes = 'development-plan-public-notes',
    developmentPlanPrivateNotes = 'development-plan-private-notes',
    developmentPlanEvaluation = 'development-plan-evaluation',
    skillsPassport = 'skills-passport',
    personality = 'personality',
    talentsAndIncentives = 'talents-and-incentives',
    competencies = 'competencies',
    learningStyle = 'learning-style',
    takeAnalysis = 'take-analysis',
    manageAnalyses = 'manage-analyses',
    seekEducation = 'seek-education',
    seekOccupation = 'seek-occupation',
    seekOccupations = 'seek-occupations',
    seekInternalOccupations = 'seek-internal-occupations',
    occupationDetail = 'occupation-detail',
    internalOccupationDetail = 'internal-occupation-detail',
    seekVacancies = 'seek-vacancies',
    vacancyDetail = 'vacancy-detail',
    organisationDetail = 'organisation-detail',
    organisationDetailCounselors = 'organisation-detail-counselors',
    organisationDetailCandidates = 'organisation-detail-candidates',
    organisationDetailModules = 'organisation-detail-modules',
    organisationDetailOccupationOverview = 'organisation-detail-occupation-overview',
    organisationOverview = 'organisation-overview',
    personalDetails = 'personal-details',
    settings = 'settings',
    logout = 'logout',
    notFound = '404',
    workplace = 'workplace',
}

export enum SkillsPassportAction {
    tmaAnalysisSettings = 'tma-analysis-settings',
    download = 'download',
}

export enum CompetencyLevel {
    high = 'high',
    regular = 'regular',
    mediocre = 'mediocre',
    low = 'low',
    noLevel = 'no-level',
}

export enum UserRole {
    lloAdmin = 'lloAdmin',
    organisationAdmin = 'organisationAdmin',
    tmaExpert = 'tmaExpert',
    counselor = 'counselor',
    candidate = 'candidate',
}

export enum ModuleType {
    matching = 'matching',
    internalOccupations = 'interne-beroepen',
    education = 'education',
}

export interface DialogFormProps<FormData> {
    isLoading?: boolean;
    isSuccessful?: boolean;
    error?: string;
    onSubmit: (formData: FormData) => void;
    onCancel: () => void;
}

export interface MinMaxValue {
    min: number;
    max: number;
}

export interface FormOption {
    value: string;
    label: string;
}

export interface SearchableOption extends FormOption {
    secondaryLabel?: string;
}

export interface ViewOption extends FormOption {
    icon: IconName;
}

interface AsyncReduxProperties {
    isLoading: boolean;
    isSuccessful: boolean;
    error: string;
}

export type AsyncReduxState<T = Record<string, unknown>> = Readonly<{
    [P in keyof T]: T[P];
} & AsyncReduxProperties>;
