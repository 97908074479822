import trans from '../../helpers/trans';
import { CandidateRelationsConfig } from '../../models/CandidateRelations/CandidateRelations';
import { getCandidateRelationFormOptionsApiCall, getCandidateRelationsApiCall } from '../../models/CandidateRelations/CandidateRelationsService';
import { transformCandidateRelationToFormOption } from '../../models/CandidateRelations/CandidateRelationsTransformers';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import {
    setError,
    setFormOptions,
    setIsLoading,
    setIsRetrieved,
    setList,
    setTotalPages,
} from './candidateRelations';

export const fetchCandidateRelations = (candidateRelationsConfig: CandidateRelationsConfig) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const candidateRelationsResponse = await getCandidateRelationsApiCall(candidateRelationsConfig);

        if (!isFetchResultSuccessful(candidateRelationsResponse)) {
            dispatch(setError(candidateRelationsResponse.error));
            return;
        }

        const { candidates, totalPages } = candidateRelationsResponse.data;

        if (candidates.length === 0) {
            const { searchQuery } = candidateRelationsConfig;

            if (searchQuery) {
                dispatch(setError(trans('errors.noResultsFoundFor', { searchQuery })));
            } else {
                dispatch(setError(trans('errors.noResultsFound')));
            }

            return;
        }

        if (totalPages === 1) {
            const candidateRelationFormOptions = candidates.map(transformCandidateRelationToFormOption);
            dispatch(setFormOptions(candidateRelationFormOptions));
        }

        dispatch(setIsRetrieved(true));
        dispatch(setList(candidates));
        dispatch(setTotalPages(totalPages));
    } catch (error) {
        console.error('[fetchCandidateRelations]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchCandidateRelationFormOptions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const candidateRelationsResponse = await getCandidateRelationFormOptionsApiCall();

        if (!isFetchResultSuccessful(candidateRelationsResponse)) {
            dispatch(setError(candidateRelationsResponse.error));
            return;
        }

        dispatch(setIsRetrieved(true));
        dispatch(setFormOptions(candidateRelationsResponse.data));
    } catch (error) {
        console.error('[fetchCandidateRelationFormOptions]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
