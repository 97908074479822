import { FC, ReactElement } from 'react';

import { Tag } from '../../../../../components';
import trans from '../../../../../helpers/trans';
import { VacancyProperties } from '../../../../../models/Vacancy';

import './VacancyPropertyTags.scss';

interface VacancyPropertyTagsProps {
    isAnonymous?: boolean;
    vacancyUuid: string;
    properties: VacancyProperties;
    className?: string;
}

const VacancyPropertyTags: FC<VacancyPropertyTagsProps> = ({
    isAnonymous,
    vacancyUuid,
    properties,
    className = '',
}): ReactElement => {
    const propertyList = [
        properties.jobType,
        properties.amountOfEmployees,
        properties.educationLevels.join(', '),
        properties.employmentHourRange,
        properties.compensation,
        properties.sector,
    ].filter(Boolean);

    return (
        <ul className={`vacancy-property-tags ${className}`}>
            {isAnonymous && (
                <li className="vacancy-property-tags__tag-item">
                    <Tag text={trans('compositions.vacancyCard.applyAnonymous')} />
                </li>
            )}

            {propertyList.map(property => (
                <li key={`${vacancyUuid}-${property}`} className="vacancy-property-tags__tag-item">
                    <Tag text={property} />
                </li>
            ))}
        </ul>
    );
};

export default VacancyPropertyTags;
