import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { defaultTravelDistance } from '../../../constants';
import { VacancyFilters } from '../../../containers';
import { VacancySearchFormValues } from '../../../containers/@forms/VacancySearchForm/VacancySearchForm';
import { convertIdsToFormOptionLabels, convertLabelsToFormOptionValues } from '../../../helpers/form';
import { scrollToTop } from '../../../helpers/scroll';
import { transformEducationLevelToFormOption } from '../../../models/EducationLevels';
import { defaultVacancyFilterValues, VacancyFilterValues } from '../../../models/MatchingVacancies';
import { transformSectorToFormOption } from '../../../models/Sectors';
import { fetchAmountOfEmployeesOptions } from '../../../redux/amountOfEmployees/amountOfEmployeesActions';
import { fetchEducationLevels } from '../../../redux/educationLevels/educationLevelsActions';
import { fetchEscoOccupations } from '../../../redux/escoOccupations/escoOccupationsActions';
import { fetchJobTypes } from '../../../redux/jobTypes/jobTypesActions';
import { setActiveFilterValues, setShouldResetFilters } from '../../../redux/matchingVacancies/matchingVacancies';
import { fetchResidences } from '../../../redux/residences/residencesActions';
import { fetchSectors } from '../../../redux/sectors/sectorsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { fetchWorkRemoteOptions } from '../../../redux/workRemote/workRemoteActions';
import { FormOption } from '../../../types';

interface ConnectedVacancyFiltersProps {
    currentPage: number;
    className?: string;
}

const ConnectedVacancyFilters: FC<ConnectedVacancyFiltersProps> = ({
    currentPage,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const activeFilterValues = useTypedSelector(state => state.matchingVacanciesReducer.activeFilterValues);
    const shouldResetFilters = useTypedSelector(state => state.matchingVacanciesReducer.shouldResetFilters);

    const escoOccupationIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const escoOccupationOptions = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);

    const residencesOptions = useTypedSelector(state => state.residencesReducer.residences);

    const sectorIsLoading = useTypedSelector(state => state.sectorsReducer.isLoading);
    const sectors = useTypedSelector(state => state.sectorsReducer.sectors);
    const sectorOptions = useMemo((): FormOption[] => sectors.map(transformSectorToFormOption), [sectors]);

    const educationLevelIsLoading = useTypedSelector(state => state.educationLevelsReducer.isLoading);
    const educationLevels = useTypedSelector(state => state.educationLevelsReducer.educationLevels);
    const educationSubLevels = useTypedSelector(state => state.educationLevelsReducer.educationSubLevels);
    const educationLevelOptions = useMemo((): FormOption[] => educationLevels.map(transformEducationLevelToFormOption), [educationLevels]);
    const educationSubLevelOptions = useMemo((): FormOption[] => educationSubLevels.map(transformEducationLevelToFormOption), [educationSubLevels]);

    const workRemoteIsLoading = useTypedSelector(state => state.workRemoteReducer.isLoading);
    const workRemoteOptions = useTypedSelector(state => state.workRemoteReducer.workRemoteOptions);

    const jobTypeIsLoading = useTypedSelector(state => state.jobTypesReducer.isLoading);
    const jobTypeOptions = useTypedSelector(state => state.jobTypesReducer.jobTypes);

    const amountOfEmployeesIsLoading = useTypedSelector(state => state.amountOfEmployeesReducer.isLoading);
    const amountOfEmployeesOptions = useTypedSelector(state => state.amountOfEmployeesReducer.amountOfEmployeesOptions);

    const transformedActiveFilterValues = useMemo(() => ({
        ...activeFilterValues,
        sectors: convertLabelsToFormOptionValues(activeFilterValues.sectors, sectorOptions),
        educationLevels: convertLabelsToFormOptionValues(activeFilterValues.educationLevels, [...educationLevelOptions, ...educationSubLevelOptions]),
        workRemotePreference: convertLabelsToFormOptionValues(activeFilterValues.workRemotePreference, workRemoteOptions),
        jobTypes: convertLabelsToFormOptionValues(activeFilterValues.jobTypes, jobTypeOptions),
        amountOfEmployees: convertLabelsToFormOptionValues(activeFilterValues.amountOfEmployees, amountOfEmployeesOptions),
    }), [activeFilterValues]);

    const [filterValues, setFilterValues] = useState<VacancyFilterValues>(transformedActiveFilterValues);

    useEffect((): void => {
        if (escoOccupationOptions.length === 0) dispatch(fetchEscoOccupations());
        if (residencesOptions.length === 0) dispatch(fetchResidences());
        if (sectorOptions.length === 0) dispatch(fetchSectors());
        if (educationLevelOptions.length === 0) dispatch(fetchEducationLevels());
        if (workRemoteOptions.length === 0) dispatch(fetchWorkRemoteOptions());
        if (jobTypeOptions.length === 0) dispatch(fetchJobTypes());
        if (amountOfEmployeesOptions.length === 0) dispatch(fetchAmountOfEmployeesOptions());
    }, [dispatch]);

    useEffect((): void => {
        scrollToTop();
    }, [currentPage]);

    const handleSearchSubmit = (value: VacancySearchFormValues): void => {
        const { what, where } = value;
        const distance = activeFilterValues.distance || defaultTravelDistance;

        const newFilterValues = {
            ...activeFilterValues,
            what,
            where,
            distance: where ? distance : 0,
        };

        setFilterValues(newFilterValues);
        dispatch(setActiveFilterValues(newFilterValues));
    };

    const handleFilterChange = (value: Partial<VacancyFilterValues>): void => {
        setFilterValues({
            ...filterValues,
            ...value,
        });
    };

    const handleFilterReset = (): void => {
        const defaultFilters = defaultVacancyFilterValues();

        setFilterValues(defaultFilters);
        dispatch(setActiveFilterValues({
            ...defaultFilters,
            skills: activeFilterValues.skills,
            competencies: activeFilterValues.competencies,
        }));
    };

    const handleFilterSubmit = (): void => {
        const combinedEducationLevelOptions = [...educationLevelOptions, ...educationSubLevelOptions];

        const newFilterValues: VacancyFilterValues = {
            ...filterValues,
            what: activeFilterValues.what,
            where: activeFilterValues.where,
            sectors: convertIdsToFormOptionLabels(sectorOptions, filterValues.sectors),
            educationLevels: convertIdsToFormOptionLabels(combinedEducationLevelOptions, filterValues.educationLevels),
            workRemotePreference: convertIdsToFormOptionLabels(workRemoteOptions, filterValues.workRemotePreference),
            jobTypes: convertIdsToFormOptionLabels(jobTypeOptions, filterValues.jobTypes),
            amountOfEmployees: convertIdsToFormOptionLabels(amountOfEmployeesOptions, filterValues.amountOfEmployees),
            skills: activeFilterValues.skills,
            competencies: activeFilterValues.competencies,
        };

        dispatch(setActiveFilterValues(newFilterValues));
    };

    useEffect((): void => {
        if (shouldResetFilters) {
            handleFilterReset();
            dispatch(setShouldResetFilters(false));
        }
    }, [shouldResetFilters]);

    const isLoading = [
        escoOccupationIsLoading,
        sectorIsLoading,
        educationLevelIsLoading,
        workRemoteIsLoading,
        jobTypeIsLoading,
        amountOfEmployeesIsLoading,
        workRemoteIsLoading,
    ].some(Boolean);

    return (
        <VacancyFilters
            isLoading={isLoading}
            activeFilterValues={activeFilterValues}
            whatOptions={escoOccupationOptions.map(escoOccupation => escoOccupation.name)}
            whereOptions={residencesOptions}
            sectorOptions={sectorOptions}
            educationLevelOptions={educationLevels}
            educationSubLevelOptions={educationSubLevels}
            workRemoteOptions={workRemoteOptions}
            jobTypeOptions={jobTypeOptions}
            amountOfEmployeesOptions={amountOfEmployeesOptions}
            filterValues={filterValues}
            onSearchSubmit={handleSearchSubmit}
            onFilterChange={handleFilterChange}
            onFilterReset={handleFilterReset}
            onFilterSubmit={handleFilterSubmit}
            className={className}
        />
    );
};

export default ConnectedVacancyFilters;
