import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, ErrorLabel, SafeHtml } from '../../components';
import { IconName } from '../../components/Icon/Icon';
import trans from '../../helpers/trans';
import { ButtonList, DialogContent, SubmitButton } from '..';

import './ConfirmDialog.scss';

export enum ConfirmType {
    Delete = 'delete',
    Warning = 'warning',
    Notify = 'notify',
}

interface ConfirmDialogProps {
    isLoading?: boolean;
    error?: string;
    title: string;
    description: string;
    submitText?: string;
    submitIcon?: IconName;
    confirmType?: ConfirmType;
    cancelText?: string;
    onConfirm: () => void;
    onCancel: () => void;
    className?: string;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
    isLoading,
    error = '',
    title,
    description,
    submitText = trans('common.save'),
    submitIcon,
    confirmType = ConfirmType.Notify,
    cancelText = trans('common.cancel'),
    onConfirm,
    onCancel,
    className = '',
}): ReactElement => {
    const confirmButtonClasses = classNames('confirm-dialog__button', {
        [`confirm-dialog__button--${confirmType}`]: confirmType,
    });

    return (
        <DialogContent
            title={title}
            className={`confirm-dialog ${className}`}
        >
            <SafeHtml html={description} className="confirm-dialog__description" />

            {error && <ErrorLabel text={error} className="confirm-dialog__error" />}

            <ButtonList className="confirm-dialog__buttons">
                <SubmitButton
                    isLoading={isLoading}
                    icon={submitIcon}
                    text={submitText}
                    onClick={onConfirm}
                    className={confirmButtonClasses}
                />
                <Button
                    text={cancelText}
                    onClick={onCancel}
                    className="confirm-dialog__button--cancel"
                />
            </ButtonList>
        </DialogContent>
    );
};

export default ConfirmDialog;
