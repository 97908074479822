import { FC, ReactElement } from 'react';

import Breadcrumbs from '../../../compositions/Breadcrumbs/Breadcrumbs';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

const ConnectedSkillsPassportBreadcrumb: FC = (): ReactElement | null => {
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);

    return userHasRole(UserRole.counselor, user?.roles) ? (
        <Breadcrumbs
            crumbs={[
                {
                    label: trans('routes.dashboard'),
                    to: RoutePaths.dashboard(),
                },
                {
                    label: candidate?.fullName || '',
                    to: RoutePaths.candidateDashboard(candidate?.uuid),
                },
                {
                    label: trans('routes.skillsPassport'),
                    disabled: true,
                },
            ]}
        />
    ) : null;
};

export default ConnectedSkillsPassportBreadcrumb;
