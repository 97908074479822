import { isFetchResultSuccessful } from '../../models/FetchResult';
import { InternalOccupationDetailRequest } from '../../models/InternalOccupationDetail/InternalOccupationDetail';
import { getInternalOccupationDetailApiCall } from '../../models/InternalOccupationDetail/InternalOccupationDetailService';
import { TypedDispatch } from '../store';
import { setError, setInternalOccupation, setIsLoading } from './internalOccupationDetail';

export const fetchInternalOccupationDetail = (internalOccupationDetailRequest: InternalOccupationDetailRequest) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const internalOccupationDetailResponse = await getInternalOccupationDetailApiCall(internalOccupationDetailRequest);

        if (!isFetchResultSuccessful(internalOccupationDetailResponse)) {
            dispatch(setError(internalOccupationDetailResponse.error));
            return;
        }

        const internalOccupation = internalOccupationDetailResponse.data;

        dispatch(setInternalOccupation(internalOccupation));
    } catch (error) {
        console.error('[fetchInternalOccupationDetail]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
