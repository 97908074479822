import { FC, FormEvent, ReactElement } from 'react';

import classNames from 'classnames';

import './BlockRadioItem.scss';

interface BlockRadioItemProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    label: string;
    onChange: (value: string) => void;
    className?: string;
}

const BlockRadioItem: FC<BlockRadioItemProps> = ({
    label,
    disabled,
    checked,
    onChange,
    className = '',
    ...radioProps
}): ReactElement => {
    const handleChange = (event: FormEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    const blockRadioItemClassNames = classNames('block-radio-item', {
        'block-radio-item--is-checked': checked,
        'block-radio-item--is-disabled': disabled,
    }, className);

    return (
        <label data-label={label} className={blockRadioItemClassNames}>
            <input
                {...radioProps}
                id={label}
                type="radio"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                className="block-radio-item__input"
            />
            <span>{label}</span>
        </label>
    );
};

export default BlockRadioItem;
