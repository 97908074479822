import { FC, ReactElement } from 'react';

import { Breadcrumbs, Notice } from '../../../compositions';
import trans from '../../../helpers/trans';
import { Competency, CompetencyFormData } from '../../../models/Competencies/Competencies';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { OrganisationOccupation, OrganisationOccupationFormData } from '../../../models/OrganisationOccupation/OrganisationOccupation';
import { Skill, SkillsFormData } from '../../../models/Skills/Skills';
import { RoutePaths } from '../../../Routes';
import { NoticeType } from '../../../types';
import { OrganisationDetailRouteTab } from '../OrganisationDetailTabs/OrganisationDetailTabs';
import { OrganisationDetailOccupationSkeletons } from './skeletons';
import { OccupationCompetenciesCard, OccupationHeaderCard, OccupationSkillsCard } from './subcomponents';

import './OrganisationDetailOccupation.scss';

interface OrganisationDetailOccupationProps {
    isLoading: boolean;
    suggestionIsLoading?: boolean;
    isUpdating: boolean;
    isSuccessful: boolean;
    pageError: string;
    formError: string;
    occupation: OrganisationOccupation;
    organisationUuid?: string;
    suggestedSkills: Skill[];
    skillOptions: Skill[];
    skillsError?: string;
    occupationOptions: EscoOccupation[];
    competencyOptions: Competency[];
    onOccupationFormSubmit: (formData: OrganisationOccupationFormData) => void;
    onOccupationDeleteSubmit: (occupation: OrganisationOccupation) => void;
    onSelectOccupation: (occupation: EscoOccupation) => void;
    onUpdateSkills: (skillsFormData: SkillsFormData) => void;
    onCloseSkillsDialog: () => void;
    onUpdateCompetencies: (formData: CompetencyFormData) => void;
    className?: string;
}

const OrganisationDetailOccupation: FC<OrganisationDetailOccupationProps> = ({
    isLoading,
    suggestionIsLoading,
    isUpdating,
    isSuccessful,
    pageError,
    formError,
    occupation,
    organisationUuid,
    suggestedSkills,
    skillOptions,
    skillsError,
    occupationOptions,
    competencyOptions,
    onOccupationFormSubmit,
    onOccupationDeleteSubmit,
    onSelectOccupation,
    onUpdateSkills,
    onCloseSkillsDialog,
    onUpdateCompetencies,
    className = '',
}): ReactElement => {
    const breadcrumbs = [
        {
            label: trans('containers.organisationDetailOccupation.breadcrumb'),
            to: RoutePaths.organisationDetailTab(organisationUuid, OrganisationDetailRouteTab.occupationOverview),
        },
        { label: occupation.title },
    ];

    return (
        <div className={`organisation-detail-occupation ${className}`}>
            {isLoading && (
                <OrganisationDetailOccupationSkeletons />
            )}

            {!isLoading && pageError && (
                <Notice
                    type={NoticeType.error}
                    text={pageError}
                />
            )}

            {!isLoading && !pageError && (
                <>
                    <Breadcrumbs crumbs={breadcrumbs} />

                    <OccupationHeaderCard
                        isUpdating={isUpdating}
                        isSuccessful={isSuccessful}
                        formError={formError}
                        occupation={occupation}
                        onOccupationFormSubmit={onOccupationFormSubmit}
                        onOccupationDeleteSubmit={onOccupationDeleteSubmit}
                        className="organisation-detail-occupation__header-card"
                    />

                    <OccupationSkillsCard
                        suggestionIsLoading={suggestionIsLoading}
                        isUpdating={isUpdating}
                        isSuccessful={isSuccessful}
                        occupation={occupation}
                        suggestedSkills={suggestedSkills}
                        skillOptions={skillOptions}
                        skillsError={skillsError}
                        occupationOptions={occupationOptions}
                        onSelectOccupation={onSelectOccupation}
                        onUpdateSkills={onUpdateSkills}
                        onCloseSkillsDialog={onCloseSkillsDialog}
                        className="organisation-detail-occupation__card"
                    />

                    <OccupationCompetenciesCard
                        isUpdating={isUpdating}
                        isSuccessful={isSuccessful}
                        error={formError}
                        occupation={occupation}
                        competencyOptions={competencyOptions}
                        onUpdateCompetencies={onUpdateCompetencies}
                        className="organisation-detail-occupation__card"
                    />
                </>
            )}
        </div>
    );
};

export default OrganisationDetailOccupation;
