import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { User, UserResource } from './User';
import { transformToUser } from './UserTransformers';

export const getUserProfileApiCall = async (): Promise<FetchResult<User, string>> => {
    try {
        const response = await lloFetch('/api/user/token');

        if (!response.ok) {
            const errorMessage = await response.json();
            const noOrganisationErrorMessage = 'User has no organisation';
            let error = trans('errors.unknownError');

            if (errorMessage === noOrganisationErrorMessage) {
                error = trans('errors.noOrganisation');
            }

            return {
                status: response.status,
                type: FetchResultType.Error,
                error,
            };
        }

        const userResponse: UserResource = await response.json();

        const user = transformToUser(userResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: user,
        };
    } catch (error) {
        console.error('[getUserProfileApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
