import { FC, ReactElement } from 'react';

import { Card } from '../../../../../components';
import { SubmitButton } from '../../../../../compositions';
import { formatDate } from '../../../../../helpers/date';
import trans from '../../../../../helpers/trans';
import useTimeout from '../../../../../hooks/useTimeout';

import './UpdateSkillsPassportCard.scss';

interface UpdateSkillsPassportCardProps {
    isLoading: boolean;
    isUpToDate: boolean;
    updatedAt: Date;
    onRefreshClick: () => void;
    resetIsUpToDate: () => void;
    className?: string;
}

const UpdateSkillsPassportCard: FC<UpdateSkillsPassportCardProps> = ({
    isLoading,
    isUpToDate,
    updatedAt,
    onRefreshClick,
    resetIsUpToDate,
    className = '',
}): ReactElement => {
    const refreshDelay = 5000;

    useTimeout((): void => {
        if (isUpToDate) {
            resetIsUpToDate();
        }
    }, refreshDelay, [isUpToDate]);

    const handleRefreshClick = (): void => {
        onRefreshClick();
    };

    const buttonText = isUpToDate
        ? trans('containers.updateSkillsPassportCard.buttonTextSuccessful')
        : trans('containers.updateSkillsPassportCard.buttonText');

    return (
        <Card className={`update-skills-passport-card ${className}`}>
            <h2 className="update-skills-passport-card__title">
                {trans('containers.updateSkillsPassportCard.title')}
            </h2>

            <div className="update-skills-passport-card__update-wrapper">
                <p className="update-skills-passport-card__last-updated">
                    {trans('containers.updateSkillsPassportCard.lastUpdated', {
                        date: formatDate(updatedAt, 'dd MMMM yyyy'),
                        time: formatDate(updatedAt, 'HH:mm'),
                    })}
                </p>

                <SubmitButton
                    isLoading={isLoading}
                    icon={isUpToDate ? 'check' : 'refresh'}
                    text={buttonText}
                    disabled={isUpToDate}
                    onClick={handleRefreshClick}
                    className="update-skills-passport-card__button"
                />
            </div>
        </Card>
    );
};

export default UpdateSkillsPassportCard;
