import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';

import { LogoutMessage } from '../../containers';
import { clearCaches } from '../../helpers/reload';
import { useTypedSelector } from '../../redux/store';

interface ConnectedLogoutMessageProps {
    className?: string;
}

const ConnectedLogoutMessage: FC<ConnectedLogoutMessageProps> = ({ className = '' }): ReactElement | null => {
    const locationState = useLocation().state as { sessionEnd?: boolean };
    const sessionExpired = locationState?.sessionEnd;

    const userLogout = useTypedSelector(state => state.userReducer.logout);

    const logout = useCallback((): void => {
        userLogout();
        localStorage.clear();
        clearCaches();
    }, [userLogout]);

    // Logout if user wants to manipulate history
    useEffect(() => {
        window.addEventListener('beforeunload', logout);
        return (): void => logout();
    }, [logout]);

    // Logout immediately if user requested logout
    useEffect((): void => {
        if (!sessionExpired) {
            logout();
        }
    }, [sessionExpired, logout]);

    return sessionExpired ? (
        <LogoutMessage
            onConfirm={logout}
            className={className}
        />
    ) : null;
};

export default ConnectedLogoutMessage;
