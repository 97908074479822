import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Card, Logo } from '../../components';
import { ConnectedCandidateExchange, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './CandidateExchange.scss';

const CandidateExchange: FC = (): ReactElement => (
    <ConnectedPage className="candidate-exchange-page">
        <Helmet>
            <title>{trans('pages.candidateExchange.title')}</title>
        </Helmet>

        <div className="candidate-exchange-page__wrapper">
            <Logo name="llo" className="candidate-exchange-page__logo" />

            <Card className="candidate-exchange-page__card">
                <h1 className="candidate-exchange-page__title">
                    {trans('pages.candidateExchange.title')}
                </h1>

                <p className="candidate-exchange-page__description">
                    {trans('pages.candidateExchange.description')}
                </p>

                <ConnectedCandidateExchange className="candidate-exchange-page__content" />
            </Card>
        </div>
    </ConnectedPage>
);

export default CandidateExchange;
