/* eslint-disable camelcase */
import { talentzApiUrl } from '../constants';
import trans from '../helpers/trans';
import { TalentzAccessToken } from '../models/AccessToken';
import { FetchResult, FetchResultType } from '../models/FetchResult';

export const fetchTalentzAuthToken = async (): Promise<FetchResult<TalentzAccessToken, string>> => {
    try {
        const query = new URLSearchParams();
        query.set('grant_type', 'client_credentials');
        query.set('client_id', process.env.REACT_APP_TALENTZ_OAUTH_CLIENT_ID || '');
        query.set('client_secret', process.env.REACT_APP_TALENTZ_OAUTH_CLIENT_SECRET || '');

        const response = await fetch(`${talentzApiUrl}/token`, {
            body: query.toString(),
            headers: {
                Authorization: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchTalentzAuthToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const exchangeTalentzRefreshTokenApiCall = async (refreshToken: string): Promise<FetchResult<TalentzAccessToken, string>> => {
    try {
        const response = await fetch(`${talentzApiUrl}/token/refresh`, {
            body: JSON.stringify({
                refresh_token: refreshToken,
                client_id: process.env.REACT_APP_TALENTZ_OAUTH_CLIENT_ID,
            }),
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[exchangeTalentzRefreshTokenApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
/* eslint-enable camelcase */
