import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { DevelopmentPlanNotes } from '../../../containers';
import { DevelopmentPlanNoteFormData, DevelopmentPlanNoteType } from '../../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import {
    addDevelopmentPlanPublicNote,
    deleteDevelopmentPlanPublicNote,
    editDevelopmentPlanPublicNote,
    getDevelopmentPlanPublicNotes,
} from '../../../redux/developmentPlanPublicNotes/developmentPlanPublicNotesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedDevelopmentPlanPublicNotesProps {
    className?: string;
}

const ConnectedDevelopmentPlanPublicNotes: FC<ConnectedDevelopmentPlanPublicNotesProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid, planUuid: developmentPlanUuid } = useParams<{ candidateUuid: string; planUuid: string }>();

    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = useTypedSelector(state => state.developmentPlanPublicNotesReducer.isLoading);
    const isUpdating = useTypedSelector(state => state.developmentPlanPublicNotesReducer.isUpdating);
    const isSuccessful = useTypedSelector(state => state.developmentPlanPublicNotesReducer.isSuccessful);
    const notes = useTypedSelector(state => state.developmentPlanPublicNotesReducer.notes);

    const handleAddNoteSubmit = (formData: DevelopmentPlanNoteFormData): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(addDevelopmentPlanPublicNote(formData, developmentPlanUuid, candidateUuid));
        }
    };

    const handleEditNoteSubmit = (formData: DevelopmentPlanNoteFormData): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(editDevelopmentPlanPublicNote(formData, candidateUuid));
        }
    };

    const handleDeleteNoteSubmit = (noteId: string): void => {
        if (candidateUuid) {
            dispatch(deleteDevelopmentPlanPublicNote(noteId, candidateUuid));
        }
    };

    useEffect((): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(getDevelopmentPlanPublicNotes(developmentPlanUuid, candidateUuid));
        }
    }, [developmentPlanUuid, candidateUuid]);

    useEffect((): void => {
        if (isSuccessful && developmentPlanUuid && candidateUuid) {
            dispatch(getDevelopmentPlanPublicNotes(developmentPlanUuid, candidateUuid));
        }
    }, [isSuccessful]);

    return (
        <DevelopmentPlanNotes
            isLoading={isLoading}
            isUpdating={isUpdating}
            isSuccessful={isSuccessful}
            user={user}
            noteType={DevelopmentPlanNoteType.public}
            notes={notes}
            onAddNoteSubmit={handleAddNoteSubmit}
            onEditNoteSubmit={handleEditNoteSubmit}
            onDeleteNoteSubmit={handleDeleteNoteSubmit}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanPublicNotes;
