import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';

import './DevelopmentHeaderSkeletons.scss';

interface DevelopmentHeaderSkeletonsProps {
    className?: string;
}

const DevelopmentHeaderSkeletons: FC<DevelopmentHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <section className={`development-header-skeletons ${className}`}>
        <Skeleton className="development-header-skeletons__button" />
    </section>
);

export default DevelopmentHeaderSkeletons;
