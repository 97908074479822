import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import { ErrorLabel, InputLabel } from '../../../components';
import { getClosestNumber } from '../../../helpers/number';
import trans from '../../../helpers/trans';
import { MinMaxValue } from '../../../types';
import { RangeInputBar, RangeInputHandle, RangeInputMarks } from './subcomponents';

import './RangeInput.scss';

interface RangeInputProps {
    label: string;
    hideLabel?: boolean;
    minHandleLabel?: string;
    maxHandleLabel?: string;
    value: MinMaxValue;
    min?: number;
    max?: number;
    step?: number;
    required?: boolean;
    error?: string;
    tabIndex?: number;
    disabled?: boolean;
    marks?: number;
    hiddenMarginPercentage?: number;
    valueFormat?: (value: number) => string;
    onChange: (rangeValue: MinMaxValue) => void;
    className?: string;
}

const RangeInput: FC<RangeInputProps> = ({
    label,
    hideLabel,
    minHandleLabel = trans('compositions.rangeInput.minimum'),
    maxHandleLabel = trans('compositions.rangeInput.maximum'),
    value,
    min = 0,
    max = 100,
    step = 1,
    required,
    error = '',
    tabIndex = 0,
    disabled,
    marks,
    hiddenMarginPercentage = 10,
    valueFormat,
    onChange,
    className = '',
}): ReactElement => {
    const { width } = useWindowSize();
    const [barWidth, setBarWidth] = useState<number>(0);

    const minMaxDiff = max - min;
    const midPoint = min + (minMaxDiff / 2);

    const barRef = useRef<HTMLDivElement>(null);

    useEffect((): void => {
        if (barRef.current) {
            setBarWidth(barRef.current.offsetWidth);
        }
    }, [barRef, width]);

    const handleMinChange = (minValue: number): void => {
        onChange({
            min: minValue,
            max: value.max,
        });
    };

    const handleMaxChange = (maxValue: number): void => {
        onChange({
            min: value.min,
            max: maxValue,
        });
    };

    const handleBarClick = (clickedValue: number): void => {
        const closestValue = getClosestNumber(clickedValue, Object.values(value));

        onChange({
            min: closestValue === value.min ? clickedValue : value.min,
            max: closestValue === value.max ? clickedValue : value.max,
        });
    };

    const inputWrapperClassNames = classNames('range-input__input-wrapper', {
        'range-input__input-wrapper--is-disabled': !!disabled,
    });

    const cssVariables = {
        '--range-input-width': `${barWidth}px`,
        '--range-input-min': (value.min - min) / minMaxDiff,
        '--range-input-max': (value.max - min) / minMaxDiff,
        '--range-input-track-size': (value.max - value.min) / minMaxDiff,
    } as CSSProperties;

    const rangeInputProps = {
        min,
        max,
        step,
        disabled,
    };

    const rangeInputHandleProps = {
        ...rangeInputProps,
        parentRef: barRef,
        hiddenMarginPercentage,
        valueFormat,
        rangeSize: value.max - value.min,
        tabIndex: disabled ? -1 : tabIndex,
    };

    return (
        <label
            style={cssVariables}
            aria-label={hideLabel ? label : undefined}
            className={`range-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="range-input__label"
                />
            )}

            <div ref={barRef} className={inputWrapperClassNames}>
                <RangeInputHandle
                    {...rangeInputHandleProps}
                    crossedMidPoint={value.min > midPoint}
                    label={minHandleLabel}
                    value={value.min}
                    clampMin={min}
                    clampMax={value.max}
                    onChange={handleMinChange}
                    className="range-input__handle--min"
                />

                <RangeInputHandle
                    {...rangeInputHandleProps}
                    crossedMidPoint={value.max <= midPoint}
                    label={maxHandleLabel}
                    value={value.max}
                    clampMin={value.min}
                    clampMax={max}
                    onChange={handleMaxChange}
                    className="range-input__handle--max"
                />

                <RangeInputBar
                    {...rangeInputProps}
                    value={value}
                    onClick={handleBarClick}
                />
            </div>

            {marks && (
                <RangeInputMarks
                    amount={marks}
                    min={min}
                    max={max}
                    className="range-input__marks"
                />
            )}

            {error && (
                <ErrorLabel text={error} className="range-input__error" />
            )}
        </label>
    );
};

export default RangeInput;
