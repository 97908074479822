import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type JobExperienceState = AsyncReduxState;

const initialState: JobExperienceState = {
    ...initialAsyncReduxState,
};

export const jobExperienceSlice = createSlice({
    name: 'jobExperienceReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): JobExperienceState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): JobExperienceState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): JobExperienceState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = jobExperienceSlice.actions;

export default jobExperienceSlice.reducer;
