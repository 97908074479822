import { FC, ReactElement } from 'react';

import { InternalOccupationDetailContent } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';
import { getComparedInternalOccupationCompetencies, getComparedInternalOccupationSkills } from './helpers';

interface ConnectedInternalOccupationDetailContentProps {
    className?: string;
}

const ConnectedInternalOccupationDetailContent: FC<ConnectedInternalOccupationDetailContentProps> = ({
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = useTypedSelector(state => state.internalOccupationDetailReducer.isLoading);
    const error = useTypedSelector(state => state.internalOccupationDetailReducer.error);
    const internalOccupation = useTypedSelector(state => state.internalOccupationDetailReducer.internalOccupation);

    return (
        <InternalOccupationDetailContent
            isLoading={isLoading}
            error={error}
            userRoles={user?.roles}
            occupation={internalOccupation}
            comparedSkills={getComparedInternalOccupationSkills(internalOccupation.skills)}
            comparedCompetencies={getComparedInternalOccupationCompetencies(internalOccupation.competencies)}
            className={className}
        />
    );
};

export default ConnectedInternalOccupationDetailContent;
