import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { SearchInput, SubmitButton } from '../../../compositions';
import trans from '../../../helpers/trans';

import './EducationSearchForm.scss';

interface EducationSearchFormProps {
    isLoading: boolean;
    value?: string;
    onSubmit: (value: string) => void;
    className?: string;
}

const EducationSearchForm: FC<EducationSearchFormProps> = ({
    isLoading,
    value,
    onSubmit,
    className = '',
}): ReactElement => {
    const [query, setQuery] = useState<string>('');

    useEffect((): void => {
        setQuery(value || '');
    }, [value]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit(query);
    };

    return (
        <form onSubmit={handleSubmit} className={`education-search-form ${className}`}>
            <SearchInput
                label={trans('forms.educationSearch.input.search')}
                value={query}
                placeholder={trans('forms.educationSearch.placeholder.search')}
                disabled={isLoading}
                onChange={setQuery}
                className="education-search-form__input"
                inputWrapperClassName="education-search-form__input-field"
            />

            <SubmitButton
                isLoading={isLoading}
                icon="arrow-right"
                hideLabel
                text={trans('forms.educationSearch.submitButtonLabel')}
                className="education-search-form__submit-button"
            />
        </form>
    );
};

export default EducationSearchForm;
