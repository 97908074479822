import { SkillsFormData } from '../../../../models/Skills/Skills';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateMinimumArrayLength,
} from '../../../../services/ValidationService';

export type SkillsFormErrors = FormErrors<SkillsFormData>;

export const validateSkillsFormData = (formData: SkillsFormData): FormValidation<SkillsFormErrors> => {
    const errors: SkillsFormErrors = {
        skills: validateMinimumArrayLength('skills', formData.skills, 3),
    };

    return validateForm<SkillsFormErrors>(errors);
};
