import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { clamp } from '../../../helpers/number';
import { createCustomOption, searchOptionsOnQuery } from '../../../helpers/search';
import trans from '../../../helpers/trans';
import useHandleClickOutside from '../../../hooks/useHandleClickOutside';
import useKeyPress from '../../../hooks/useKeyPress';
import { CompetencyLevel, SearchableOption } from '../../../types';
import { SearchInput } from '../..';
import { SearchInputProps } from '../SearchInput/SearchInput';
import { SearchableCompetencyOption } from './subcomponents';

import './CompetencyTalentSelector.scss';

interface CompetencyTalentSelectorProps extends Omit<SearchInputProps, 'onChange'> {
    isSearchable?: boolean;
    clearOnNewOptions?: boolean;
    options: SearchableOption[];
    resultLimit?: number;
    onChange: (option: SearchableOption) => void;
    className?: string;
    inputClassName?: string;
    inputWrapperClassName?: string;
    listClassName?: string;
}

const CompetencyTalentSelector: FC<CompetencyTalentSelectorProps> = ({
    isSearchable,
    clearOnNewOptions,
    options,
    resultLimit = 10,
    onChange,
    className = '',
    inputClassName,
    inputWrapperClassName,
    listClassName = '',
    ...inputProps
}): ReactElement => {
    const defaultFocusIndex = -1;

    const [searchResults, setSearchResults] = useState<SearchableOption[]>([]);
    const [focusIndex, setFocusIndex] = useState<number>(defaultFocusIndex);
    const pressedUp = useKeyPress('ArrowUp');
    const pressedDown = useKeyPress('ArrowDown');

    const searchableValueInputRef = useRef<HTMLDivElement>(null);
    const resultListRef = useRef<HTMLUListElement>(null);

    const defaultCompetencyList = useMemo((): SearchableOption[] => {
        const defaultCompetencies = options.filter(competency => (competency.secondaryLabel === CompetencyLevel.noLevel || !competency.secondaryLabel) && competency);

        return defaultCompetencies.filter(competency => searchResults.includes(competency));
    }, [options, searchResults]);

    const highTalentList = useMemo((): SearchableOption[] => {
        const highTalents = options.filter(competency => competency.secondaryLabel === CompetencyLevel.high && competency);

        return highTalents.filter(competency => searchResults.includes(competency));
    }, [options, searchResults]);

    const regularTalentList = useMemo((): SearchableOption[] => {
        const regularTalents = options.filter(competency => competency.secondaryLabel === CompetencyLevel.regular && competency);

        return regularTalents.filter(competency => searchResults.includes(competency));
    }, [options, searchResults]);

    const mediocreTalentList = useMemo((): SearchableOption[] => {
        const mediocreTalents = options.filter(competency => competency.secondaryLabel === CompetencyLevel.mediocre && competency);

        return mediocreTalents.filter(competency => searchResults.includes(competency));
    }, [options, searchResults]);

    const lowTalentList = useMemo((): SearchableOption[] => {
        const lowTalents = options.filter(competency => competency.secondaryLabel === CompetencyLevel.low && competency);

        return lowTalents.filter(competency => searchResults.includes(competency));
    }, [options, searchResults]);

    const updateSelectedIndex = useCallback((index: number): void => {
        const newIndex = clamp(index, 0, searchResults.length - 1);
        setFocusIndex(newIndex);
    }, [setFocusIndex, searchResults]);

    const clearResults = (): void => {
        setSearchResults([]);
        setFocusIndex(defaultFocusIndex);
    };

    useEffect((): void => {
        if (clearOnNewOptions) {
            clearResults();
        }
    }, [clearOnNewOptions, options]);

    useHandleClickOutside(searchableValueInputRef, clearResults);

    useEffect((): void => {
        if (searchResults.length > 0) {
            if (pressedUp) updateSelectedIndex(focusIndex - 1);
            if (pressedDown) updateSelectedIndex(focusIndex + 1);
        }
    }, [pressedUp, pressedDown]);

    useEffect((): void => {
        if (resultListRef.current && focusIndex >= 0) {
            const buttons = resultListRef.current.querySelectorAll('button');
            buttons[focusIndex].focus();
        }
    }, [focusIndex, resultListRef]);

    const handleChange = (query: string): void => {
        onChange(createCustomOption(query));

        if (isSearchable) {
            setSearchResults(searchOptionsOnQuery(options, query, resultLimit));
            setFocusIndex(defaultFocusIndex);
        }
    };

    const handleSearchClick = (): void => {
        setSearchResults(options);
    };

    const handleOptionClick = (option: SearchableOption): void => {
        onChange(option);
        clearResults();
    };

    return (
        <div ref={searchableValueInputRef} className={`competency-talent-selector ${className}`}>
            <SearchInput
                {...inputProps}
                autoComplete="off"
                onClick={handleSearchClick}
                onChange={handleChange}
                className={inputClassName}
                inputWrapperClassName={inputWrapperClassName}
            />

            {searchResults.length > 0 && (
                <ul ref={resultListRef} className={`competency-talent-selector__result-list ${listClassName}`}>
                    {defaultCompetencyList.length > 0 && (
                        <li className="competency-talent-selector__talent-section">
                            <ul className="competency-talent-selector__competency-list">
                                {defaultCompetencyList.map(option => (
                                    <SearchableCompetencyOption
                                        key={option.value}
                                        option={option}
                                        onSelect={handleOptionClick}
                                    />
                                ))}
                            </ul>
                        </li>
                    )}

                    {highTalentList.length > 0 && (
                        <li className="competency-talent-selector__talent-section">
                            <h3 className="competency-talent-selector__talent-level competency-talent-selector__talent-level--talented">
                                {trans('forms.developmentPlanCompetencyForm.competencyLevel.high')}
                            </h3>
                            <ul className="competency-talent-selector__competency-list">
                                {highTalentList.map(option => (
                                    <SearchableCompetencyOption
                                        key={option.value}
                                        option={option}
                                        onSelect={handleOptionClick}
                                    />
                                ))}
                            </ul>
                        </li>
                    )}

                    {regularTalentList.length > 0 && (
                        <li className="competency-talent-selector__talent-section">
                            <h3 className="competency-talent-selector__talent-level competency-talent-selector__talent-level--talented">
                                {trans('forms.developmentPlanCompetencyForm.competencyLevel.regular')}
                            </h3>
                            <ul className="competency-talent-selector__competency-list">
                                {regularTalentList.map(option => (
                                    <SearchableCompetencyOption
                                        key={option.value}
                                        option={option}
                                        onSelect={handleOptionClick}
                                    />
                                ))}
                            </ul>
                        </li>
                    )}

                    {mediocreTalentList.length > 0 && (
                        <li className="competency-talent-selector__talent-section">
                            <h3 className="competency-talent-selector__talent-level competency-talent-selector__talent-level--less-talented">
                                {trans('forms.developmentPlanCompetencyForm.competencyLevel.mediocre')}
                            </h3>
                            <ul className="competency-talent-selector__competency-list">
                                {mediocreTalentList.map(option => (
                                    <SearchableCompetencyOption
                                        key={option.value}
                                        option={option}
                                        onSelect={handleOptionClick}
                                    />
                                ))}
                            </ul>
                        </li>
                    )}

                    {lowTalentList.length > 0 && (
                        <li className="competency-talent-selector__talent-section">
                            <h3 className="competency-talent-selector__talent-level competency-talent-selector__talent-level--less-talented">
                                {trans('forms.developmentPlanCompetencyForm.competencyLevel.low')}
                            </h3>
                            <ul className="competency-talent-selector__competency-list">
                                {lowTalentList.map(option => (
                                    <SearchableCompetencyOption
                                        key={option.value}
                                        option={option}
                                        onSelect={handleOptionClick}
                                    />
                                ))}
                            </ul>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default CompetencyTalentSelector;
