import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { CompetencyTalentSelector, FormActionButtons } from '../../../compositions';
import { defaultFormOption } from '../../../constants';
import trans from '../../../helpers/trans';
import { AddDevelopmentPlanCompetencyFormData } from '../../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import { DialogFormProps, FormOption, SearchableOption } from '../../../types';

import './DevelopmentPlanCompetencyForm.scss';

interface DevelopmentPlanCompetencyFormProps extends DialogFormProps<AddDevelopmentPlanCompetencyFormData> {
    isLoading: boolean;
    isLoadingCompetencies: boolean;
    competencyOptions: FormOption[];
    developmentPlanId: string;
    onCancel: () => void;
    onSubmit: (formData: AddDevelopmentPlanCompetencyFormData) => void;
    className?: string;
}

const DevelopmentPlanCompetencyForm: FC<DevelopmentPlanCompetencyFormProps> = ({
    isLoading,
    competencyOptions,
    developmentPlanId,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [selectedCompetency, setSelectedCompetency] = useState<SearchableOption>(defaultFormOption());

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData: AddDevelopmentPlanCompetencyFormData = {
            competency: selectedCompetency?.label || '',
            developmentPlanId,
        };

        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} className={`development-plan-competency-form ${className}`}>
            <CompetencyTalentSelector
                isSearchable={!!developmentPlanId}
                label={trans('forms.developmentPlanCompetencyForm.input.competency')}
                placeholder={trans('forms.developmentPlanCompetencyForm.placeholder.competency')}
                value={selectedCompetency?.label}
                name="competency"
                options={competencyOptions}
                onChange={setSelectedCompetency}
                className="development-plan-competency-form__selector"
            />

            <FormActionButtons
                isLoading={isLoading}
                disabled={selectedCompetency.value === ''}
                onCancelClick={onCancel}
                className="development-plan-competency-form__action-buttons"
            />
        </form>
    );
};

export default DevelopmentPlanCompetencyForm;
