import { UserRole } from '../types';

export const userHasRole = (roleToInclude: UserRole, userRoles: UserRole[] = []): boolean => (
    userRoles.includes(roleToInclude)
);

export const getRoleForTranslation = (userRoles: UserRole[] = []): string => {
    if (userHasRole(UserRole.candidate, userRoles)) {
        return UserRole.candidate;
    }

    return UserRole.counselor;
};
