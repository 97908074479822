import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { Gender } from '../../types';
import { CandidateIllustration, Dialog, LinkIconButton } from '..';
import { CandidateIllustrationNotification } from '../CandidateIllustration/CandidateIllustration';

import './LeavingPlatformDialog.scss';

interface LeavingPlatformDialogProps {
    illustrationType?: CandidateIllustrationNotification;
    gender?: Gender;
    title: string;
    description: string;
    buttonText: string;
    buttonUrl: string;
    onClose: () => void;
    className?: string;
}

type LeavingPlatformDialogWithRefProps = LeavingPlatformDialogProps & RefAttributes<HTMLDialogElement>;

const LeavingPlatformDialog: ForwardRefExoticComponent<LeavingPlatformDialogWithRefProps> = forwardRef(({
    illustrationType,
    gender,
    title,
    description,
    buttonText,
    buttonUrl,
    onClose,
    className = '',
}, ref: Ref<HTMLDialogElement>): ReactElement => (
    <Dialog
        enableBackdropClose
        ref={ref}
        onClose={onClose}
        className={`leaving-platform-dialog ${className}`}
    >
        <CandidateIllustration type={illustrationType} gender={gender} />

        <h2 className="leaving-platform-dialog__title">
            {title}
        </h2>

        <p className="leaving-platform-dialog__description">
            {description}
        </p>

        <LinkIconButton
            to={buttonUrl}
            icon="arrow-right"
            iconPos="right"
            text={buttonText}
            onClick={onClose}
            className="leaving-platform-dialog__link-button"
        />
    </Dialog>
));

export default LeavingPlatformDialog;
