import { FC, ReactElement } from 'react';

import { IconButton } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';

import './DevelopmentPlanNotesNullState.scss';

interface DevelopmentPlanNotesNullStateProps {
    onAddNoteClick: () => void;
    className?: string;
}

const DevelopmentPlanNotesNullState: FC<DevelopmentPlanNotesNullStateProps> = ({
    onAddNoteClick,
    className = '',
}): ReactElement => (
    <div className={`development-plan-notes-null-state ${className}`}>
        <p className="development-plan-notes-null-state__description">
            {trans('containers.developmentPlanNotes.nullState')}
        </p>

        <IconButton
            icon="plus"
            text={trans('containers.developmentPlanNotes.addFirstNote')}
            onClick={onAddNoteClick}
            className="development-plan-notes-null-state__add-note-button"
        />
    </div>
);

export default DevelopmentPlanNotesNullState;
