import { FC, ReactElement } from 'react';

import { InputLabel } from '../../../components';
import { Checkbox } from '../..';

import './ApplyAnonymousFilter.scss';

interface ApplyAnonymousFilterProps {
    label: string;
    hideLabel?: boolean;
    checkboxLabel: string;
    value: boolean;
    tabIndex?: number;
    onChange: (value: boolean) => void;
    className?: string;
}

const ApplyAnonymousFilter: FC<ApplyAnonymousFilterProps> = ({
    label,
    hideLabel,
    checkboxLabel,
    value,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`apply-anonymous-filter ${className}`}>
        {!hideLabel && (
            <InputLabel
                text={label}
                className="apply-anonymous-filter__label"
            />
        )}

        <Checkbox
            label={checkboxLabel || label}
            checked={value}
            tabIndex={tabIndex}
            onChange={onChange}
        />
    </div>
);

export default ApplyAnonymousFilter;
