import { FC, ReactElement } from 'react';

import { PaginationInputList } from '..';

interface PaginationLongInitialProps {
    paginationId: string;
    startList: number[];
    endList: number[];
    activePage: number;
    onChange: (page: number) => void;
}

const PaginationLongInitial: FC<PaginationLongInitialProps> = ({
    paginationId,
    startList,
    endList,
    activePage,
    onChange,
}): ReactElement => (
    <>
        <PaginationInputList
            paginationId={paginationId}
            list={startList}
            activePage={activePage}
            onChange={onChange}
        />
        <span className="pagination__ellipsis">…</span>
        <PaginationInputList
            paginationId={paginationId}
            list={endList}
            activePage={activePage}
            onChange={onChange}
        />
    </>
);
export default PaginationLongInitial;
