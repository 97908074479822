import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { DevelopmentPlanHeader } from '../../../containers';
import useUnmount from '../../../hooks/useUnmount';
import { Candidate } from '../../../models/Candidate/Candidate';
import { DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { User } from '../../../models/User/User';
import { setDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlan';
import { deleteDevelopmentPlan, editDevelopmentPlan, fetchDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlanActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';

interface ConnectedSkillsPassportHeaderProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onUserResponse: (user: User) => void;
    className?: string;
}

const ConnectedDevelopmentPlanHeader: FC<ConnectedSkillsPassportHeaderProps> = ({
    onCandidateResponse,
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { candidateUuid, planUuid: developmentPlanUuid } = useParams<{ 'candidateUuid': string; planUuid: string }>();

    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isUpdating = useTypedSelector(state => state.developmentPlanReducer.isUpdating);
    const isSuccessful = useTypedSelector(state => state.developmentPlanReducer.isSuccessful);
    const developmentPlan = useTypedSelector(state => state.developmentPlanReducer.developmentPlan);
    const developmentPlanError = useTypedSelector(state => state.developmentPlanReducer.error);
    const developmentPlanIsDeleting = useTypedSelector(state => state.developmentPlanReducer.isDeleting);
    const developmentPlanIsLoading = useTypedSelector(state => state.developmentPlanReducer.isLoading);

    const handleSubmitDevelopmentPlan = (formData: DevelopmentPlanFormData): void => {
        if (candidateUuid) dispatch(editDevelopmentPlan(formData, candidateUuid));
    };

    const handleDeleteDevelopmentPlan = (): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(deleteDevelopmentPlan(developmentPlanUuid, candidateUuid));
            navigate(RoutePaths.development(candidateUuid));
        }
    };

    useUnmount((): void => {
        dispatch(setDevelopmentPlan(undefined));
    });

    useEffectOnce((): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(fetchDevelopmentPlan(developmentPlanUuid, candidateUuid));
        }
    });

    useEffect((): void => {
        if (user) onUserResponse(user);
    }, [user]);

    useEffect((): void => {
        if (candidate) onCandidateResponse(candidate);
    }, [candidate]);

    return (
        <DevelopmentPlanHeader
            pageIsLoading={developmentPlanIsLoading}
            isDeleting={developmentPlanIsDeleting}
            isUpdating={isUpdating}
            isSuccessful={isSuccessful}
            developmentPlan={developmentPlan}
            developmentPlanError={developmentPlanError}
            userRoles={user?.roles}
            onSubmitDevelopmentPlan={handleSubmitDevelopmentPlan}
            onDeleteDevelopmentPlan={handleDeleteDevelopmentPlan}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanHeader;
