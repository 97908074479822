import { FC, ReactElement, useState } from 'react';

import { Card } from '../../../components';
import { FilterControls } from '../../../compositions';
import { getActiveFilterValues } from '../../../helpers/filter';
import { getAmountOfDefinedValues } from '../../../helpers/object';
import trans from '../../../helpers/trans';
import { EducationFilterOptions } from '../../../models/EducationFormOptions/EducationFormOptions';
import { defaultEducationFilterValues, EducationFilterValues } from '../../../models/Educations/Educations';
import { EducationSearchForm } from '../..';
import { EducationFiltersForm } from './subcomponents';

import './EducationFilters.scss';

export interface EducationFiltersProps {
    isLoading: boolean;
    activeFilterValues: EducationFilterValues;
    filterValues: EducationFilterValues;
    filterOptions: EducationFilterOptions;
    onSearchSubmit: (value: string) => void;
    onFilterChange: (value: Partial<EducationFilterValues>) => void;
    onFilterReset: () => void;
    onFilterSubmit: () => void;
    className?: string;
}

const EducationFilters: FC<EducationFiltersProps> = ({
    isLoading,
    activeFilterValues,
    filterValues,
    filterOptions,
    onSearchSubmit,
    onFilterChange,
    onFilterReset,
    onFilterSubmit,
    className = '',
}): ReactElement => {
    const [filterSidebarIsExpanded, setFilterSidebarIsExpanded] = useState<boolean>(false);

    const openFilterSidebar = (): void => setFilterSidebarIsExpanded(true);
    const closeFilterSidebar = (): void => setFilterSidebarIsExpanded(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pageNumber, pageSize, ...sidebarFilters } = activeFilterValues;

    const defaultFilters = defaultEducationFilterValues();
    const activeFilters = getActiveFilterValues(sidebarFilters, defaultFilters);
    const amountOfActiveFilters = getAmountOfDefinedValues(activeFilters);

    return (
        <header className={`education-filters ${className}`}>
            <Card className="education-filters__card">
                <EducationSearchForm
                    isLoading={isLoading}
                    value={activeFilterValues.searchQuery}
                    onSubmit={onSearchSubmit}
                    className="education-filters__search-form"
                />

                <FilterControls
                    isLoading={isLoading}
                    isExpanded={filterSidebarIsExpanded}
                    amountOfActiveFilters={amountOfActiveFilters}
                    sidebarTitle={trans('containers.educationFilters.sidebar.title')}
                    onOpenSidebar={openFilterSidebar}
                    onCloseSidebar={closeFilterSidebar}
                    onFilterReset={onFilterReset}
                    className="education-filters__filter-wrapper"
                >
                    <EducationFiltersForm
                        isLoading={isLoading}
                        filterValues={filterValues}
                        filterOptions={filterOptions}
                        onChange={onFilterChange}
                        onReset={onFilterReset}
                        onSubmit={onFilterSubmit}
                        onClose={closeFilterSidebar}
                    />
                </FilterControls>
            </Card>
        </header>
    );
};

export default EducationFilters;
