import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { CoachingAdvice, CoachingAdviceResource } from './CoachingAdvice';
import { transformToCoachingAdvice } from './CoachingAdviceTransformers';

export const getCoachingAdviceApiCall = async (competencyId: string): Promise<FetchResult<CoachingAdvice, string>> => {
    try {
        const response = await lloFetch(`/api/tmaCompetence/${competencyId}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const coachingAdviceResponse: CoachingAdviceResource = await response.json();

        const coachingAdvice = transformToCoachingAdvice(coachingAdviceResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: coachingAdvice,
        };
    } catch (error) {
        console.error('[getCoachingAdviceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
