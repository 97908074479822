import { FC, ReactElement } from 'react';

import { Icon } from '../../../../../components';
import trans from '../../../../../helpers/trans';

import './StapLabel.scss';

interface StapLabelProps {
    className?: string;
}

const StapLabel: FC<StapLabelProps> = ({
    className = '',
}): ReactElement => (
    <div className={`stap-label ${className}`}>
        <Icon
            name="star-check"
            className="stap-label__icon"
            accentClassName="stap-label__icon-accent"
        />

        <span className="stap-label__label">
            {trans('compositions.educationCard.stap')}
        </span>
    </div>
);

export default StapLabel;
