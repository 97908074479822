import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { CounselorOption } from '../../models/Counselor/Counselor';
import { AsyncReduxState } from '../../types';

export type CandidateExchangeState = AsyncReduxState<{
    newCounselorFullName: string;
    counselorOptions: CounselorOption[];
}>;

const initialState: CandidateExchangeState = {
    ...initialAsyncReduxState,
    newCounselorFullName: '',
    counselorOptions: [],
};

export const candidateExchangeSlice = createSlice({
    name: 'candidateExchangeReducer',
    initialState,
    reducers: {
        clearCandidateExchange(): CandidateExchangeState {
            return initialState;
        },
        setIsLoading(state, action: PayloadAction<boolean>): CandidateExchangeState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CandidateExchangeState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CandidateExchangeState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setNewCounselorFullName(state, action: PayloadAction<string>): CandidateExchangeState {
            return {
                ...state,
                newCounselorFullName: action.payload,
            };
        },
        setCounselorOptions(state, action: PayloadAction<CounselorOption[]>): CandidateExchangeState {
            return {
                ...state,
                counselorOptions: action.payload,
            };
        },
    },
});

export const {
    clearCandidateExchange,
    setIsLoading,
    setIsSuccessful,
    setError,
    setNewCounselorFullName,
    setCounselorOptions,
} = candidateExchangeSlice.actions;

export default candidateExchangeSlice.reducer;
