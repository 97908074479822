import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedMyActions, ConnectedPage } from '../../connectors';
import { userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { User } from '../../models/User/User';
import { UserRole } from '../../types';

import './MyActions.scss';

const MyActions: FC = (): ReactElement => {
    const [user, setUser] = useState<User>();
    const [candidate, setCandidate] = useState<Candidate>();

    const title = userHasRole(UserRole.candidate, user?.roles)
        ? trans('pages.myActions.title.candidate')
        : trans('pages.myActions.title.counselor', {
            name: candidate?.firstName || '',
        });

    return (
        <ConnectedPage
            hasNavigation
            title={title}
            className="my-actions-page"
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <ConnectedMyActions
                onCandidateResponse={setCandidate}
                onUserResponse={setUser}
                className="my-actions-page__wrapper"
            />
        </ConnectedPage>
    );
};

export default MyActions;
