import { Occupation, OccupationResource } from '../Occupations/Occupations';
import { defaultPageSize } from '../Pagination';

export interface OccupationOverviewResource {
    meta: {
        totalAmount: number;
    };
    occupations: OccupationResource[];
}

export interface OccupationOverview {
    totalAmount: number;
    occupations: Occupation[];
}

export interface OccupationFilterValues {
    pageNumber: number;
    pageSize: number;
    searchQuery: string;
}

export const defaultOccupationFilterValues = (): OccupationFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
    searchQuery: '',
});

export const defaultOccupationOverview = (): OccupationOverview => ({
    totalAmount: 0,
    occupations: [],
});
