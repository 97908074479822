/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconJobExperience: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 24 23" className={`icon ${className}`}>
        <path d="M9.6 0H14.4C15.0365 0 15.647 0.252856 16.0971 0.702943C16.5471 1.15303 16.8 1.76348 16.8 2.4V4.8H21.6C22.2365 4.8 22.847 5.05286 23.2971 5.50294C23.7471 5.95303 24 6.56348 24 7.2V20.4C24 21.0365 23.7471 21.647 23.2971 22.0971C22.847 22.5471 22.2365 22.8 21.6 22.8H2.4C1.068 22.8 0 21.72 0 20.4V7.2C0 5.868 1.068 4.8 2.4 4.8H7.2V2.4C7.2 1.068 8.268 0 9.6 0ZM14.4 4.8V2.4H9.6V4.8H14.4Z" />
    </svg>
);

export default IconJobExperience;
/* eslint-enable max-len */
