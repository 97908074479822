/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconRefresh: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 21 21" className={`icon ${className}`}>
        <path d="M0 9C0 13.97 4.03 18 9 18C11.39 18 13.68 17.06 15.4 15.4L13.9 13.9C12.63 15.25 10.86 16 9 16C2.76 16 -0.36 8.46 4.05 4.05C8.46 -0.36 16 2.77 16 9H13L17 13H17.1L21 9H18C18 4.03 13.97 0 9 0C4.03 0 0 4.03 0 9Z" />
    </svg>
);

export default IconRefresh;
/* eslint-enable max-len */
