import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { UserRole } from '../../../types';

export const getSeekVacanciesOverviewTitle = (userRoles?: UserRole[], candidateName?: string): string => {
    if (userHasRole(UserRole.candidate, userRoles)) {
        return trans('pages.seekVacancies.overviewTitle.candidate');
    }

    if (userHasRole(UserRole.counselor, userRoles) && candidateName) {
        return trans('pages.seekVacancies.overviewTitle.counselor', { candidateName });
    }

    return '';
};
