import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Card, Logo } from '../../components';
import { ConnectedCandidatePersonalDataForm, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './Registration.scss';

const Registration: FC = (): ReactElement => (
    <ConnectedPage className="registration-page">
        <Helmet>
            <title>{trans('pages.registration.title')}</title>
        </Helmet>

        <div className="registration-page__wrapper">
            <Logo name="llo" className="registration-page__logo" />

            <Card className="registration-page__card">
                <h1 className="registration-page__title">
                    {trans('pages.registration.title')}
                </h1>

                <p className="registration-page__description">
                    {trans('pages.registration.description')}
                </p>

                <ConnectedCandidatePersonalDataForm
                    isRegistration
                    submitButtonLabel={trans('pages.registration.submitButtonLabel')}
                    className="registration-page__form"
                />
            </Card>
        </div>
    </ConnectedPage>
);

export default Registration;
