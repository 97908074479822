import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { addMonths } from 'date-fns';

import {
    FormActionButtons,
    IconButton,
    Notice,
    TextInput,
} from '../../../compositions';
import { formatDate } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { DevelopmentOverview } from '../../../models/DevelopmentOverview/DevelopmentOverview';
import { DevelopmentPlan, DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { DialogFormProps, NoticeType } from '../../../types';
import { DevelopmentPlanFormErrors, validateDevelopmentPlanFormData } from './validations';

import './DevelopmentPlanForm.scss';

interface DevelopmentPlanFormProps extends DialogFormProps<DevelopmentPlanFormData> {
    isEditing?: boolean;
    developmentPlan?: DevelopmentPlan | DevelopmentOverview;
    onDeleteDevelopmentPlan?: (state: boolean) => void;
    className?: string;
}

const DevelopmentPlanForm: FC<DevelopmentPlanFormProps> = ({
    isLoading,
    isEditing,
    error,
    developmentPlan,
    onCancel,
    onDeleteDevelopmentPlan,
    onSubmit,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<DevelopmentPlanFormErrors>({});

    const [title, setTitle] = useState<string>('');
    const [startDate, setStartDate] = useState<string>(formatDate(new Date(), 'y-MM-dd'));
    const [endDate, setEndDate] = useState<string>(formatDate(addMonths(new Date(), 3), 'y-MM-dd'));

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: developmentPlan?.id,
            title,
            start: startDate,
            end: endDate,
        };

        const [errors, hasErrors] = validateDevelopmentPlanFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const handleDeleteClick = (): void => {
        if (onDeleteDevelopmentPlan) onDeleteDevelopmentPlan(true);
    };

    useEffect(() => {
        if (developmentPlan?.title) setTitle(developmentPlan.title);
        if (developmentPlan?.start) setStartDate(formatDate(developmentPlan?.start, 'y-MM-dd'));
        if (developmentPlan?.end) setEndDate(formatDate(developmentPlan?.end, 'y-MM-dd'));
    }, [developmentPlan]);

    const actionButtonsClassNames = classNames('development-plan-form__action-buttons', {
        'development-plan-form__action-buttons--is-editing': isEditing,
    });

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`development-plan-form ${className}`}>
            <div className="development-plan-form__row">
                <TextInput
                    label={trans('forms.developmentPlanForm.input.title')}
                    required
                    value={title}
                    error={formErrors.title}
                    onChange={setTitle}
                    className="development-plan-form__input"
                />
            </div>

            <div className="development-plan-form__row">
                <TextInput
                    label={trans('forms.developmentPlanForm.input.startDate')}
                    type="date"
                    required
                    value={startDate}
                    error={formErrors.start}
                    onChange={setStartDate}
                    className="development-plan-form__column"
                />

                <TextInput
                    label={trans('forms.developmentPlanForm.input.endDate')}
                    type="date"
                    required
                    value={endDate}
                    error={formErrors.end}
                    onChange={setEndDate}
                    className="development-plan-form__column"
                />
            </div>

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="development-plan-form__error-message"
                />
            )}

            {isEditing && (
                <IconButton
                    icon="bin"
                    text={trans('forms.developmentPlanForm.delete.title')}
                    onClick={handleDeleteClick}
                    className="development-plan-form__delete-button"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className={actionButtonsClassNames}
            />
        </form>
    );
};

export default DevelopmentPlanForm;
