import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Card } from '../../../../../components';
import { BlockRadioList, IconButton } from '../../../../../compositions';
import { getRoleForTranslation } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { DevelopmentPlanCompetency } from '../../../../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import { DevelopmentPlanCompetencyEvaluation, DevelopmentPlanCompetencyEvaluationFormData } from '../../../../../models/DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluation';
import { UserRole } from '../../../../../types';
import { DevelopmentPlanCardInput, DevelopmentPlanEvaluationCardQuestion } from '..';

import './DevelopmentPlanEvaluationCompetencyCard.scss';

interface DevelopmentPlanEvaluationCompetencyCardProps {
    isEditing: boolean;
    userRoles: UserRole[];
    competency: DevelopmentPlanCompetency;
    evaluation?: DevelopmentPlanCompetencyEvaluation;
    onChange: (formData: DevelopmentPlanCompetencyEvaluationFormData) => void;
    className?: string;
}

const DevelopmentPlanEvaluationCompetencyCard: FC<DevelopmentPlanEvaluationCompetencyCardProps> = ({
    isEditing,
    userRoles,
    competency,
    evaluation,
    onChange,
    className = '',
}): ReactElement => {
    const [feedback, setFeedback] = useState<string>('');
    const [grade, setGrade] = useState<number>(4);
    const [planIsExpanded, togglePlanIsExpanded] = useToggle(false);

    const userRole = getRoleForTranslation(userRoles);

    const gradeOptions = ['1', '2', '3', '4', '5', '6', '7'];

    const expandablePlanClasses = classNames('development-plan-evaluation-competency-card__expandable-section', {
        'development-plan-evaluation-competency-card__expandable-section--is-expanded': planIsExpanded,
    });

    const togglePlanIconClasses = classNames('development-plan-evaluation-competency-card__toggle-icon', {
        'development-plan-evaluation-competency-card__toggle-icon--is-expanded': planIsExpanded,
    });

    const handleGradeChange = (value: string): void => {
        const formData: DevelopmentPlanCompetencyEvaluationFormData = {
            id: evaluation?.id,
            grade: Number(value),
            feedback,
            developmentPlanCompetencyId: competency.id,
        };

        setGrade(Number(value));

        onChange(formData);
    };

    const handleFeedbackChange = (value: string): void => {
        const formData: DevelopmentPlanCompetencyEvaluationFormData = {
            id: evaluation?.id,
            grade,
            feedback: value,
            developmentPlanCompetencyId: competency.id,
        };

        setFeedback(value);

        onChange(formData);
    };

    useEffect((): void => {
        if (evaluation?.feedback) setFeedback(evaluation.feedback);
        if (evaluation?.grade) setGrade(evaluation.grade);
    }, [evaluation]);

    return (
        <Card className={`development-plan-evaluation-competency-card ${className}`}>
            <form>
                <div className="development-plan-evaluation-competency-card__header">
                    <h2 className="development-plan-evaluation-competency-card__title">
                        {`${trans('common.competency')}: ${competency.name}`}
                    </h2>

                    <div className="development-plan-evaluation-competency-card__action-buttons">
                        <p className="development-plan-evaluation-competency-card__grade-label">
                            {trans('containers.developmentPlanEvaluation.developmentPlanCompetencyEvaluationCard.grade')}
                        </p>
                        <BlockRadioList
                            label={trans('containers.developmentPlanEvaluation.developmentPlanCompetencyEvaluationCard.grade')}
                            hideLabel
                            value={String(grade)}
                            options={gradeOptions.map(gradeNumber => ({
                                label: gradeNumber,
                                value: gradeNumber,
                            }))}
                            onChange={handleGradeChange}
                            disabled={!isEditing}
                            className="development-plan-evaluation-competency-card__grade"
                            itemClassName="development-plan-evaluation-competency-card__grade-option"
                        />
                    </div>
                </div>
                <DevelopmentPlanCardInput
                    isEditing={isEditing}
                    label={trans('containers.developmentPlanEvaluation.developmentPlanCompetencyEvaluationCard.feedback.label')}
                    value={feedback || ''}
                    placeholder={trans('containers.developmentPlanEvaluation.developmentPlanCompetencyEvaluationCard.feedback.placeholder')}
                    onChange={handleFeedbackChange}
                    className="development-plan-evaluation-competency-card__input"
                />
                <IconButton
                    text={trans(`containers.developmentPlanEvaluation.developmentPlan.${planIsExpanded ? 'hide' : 'show'}`)}
                    icon="chevron-down"
                    onClick={togglePlanIsExpanded}
                    className="development-plan-evaluation-competency-card__toggle-button development-plan-evaluation-competency-card__toggle-button--plan"
                    iconClassName={togglePlanIconClasses}
                />
                <div className={expandablePlanClasses}>
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans(`containers.developmentPlanEvaluation.developmentPlanCompetencyCardQuestion.what.${userRole}`)}
                        value={competency.what}
                        className="development-plan-evaluation-competency-card__question"
                    />
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans(`containers.developmentPlanEvaluation.developmentPlanCompetencyCardQuestion.why.${userRole}`)}
                        value={competency.why}
                        className="development-plan-evaluation-competency-card__question"
                    />
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans(`containers.developmentPlanEvaluation.developmentPlanCompetencyCardQuestion.when.${userRole}`)}
                        value={competency.when}
                        className="development-plan-evaluation-competency-card__question"
                    />
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans(`containers.developmentPlanEvaluation.developmentPlanCompetencyCardQuestion.how.${userRole}`)}
                        value={competency.how}
                        className="development-plan-evaluation-competency-card__question"
                    />
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans(`containers.developmentPlanEvaluation.developmentPlanCompetencyCardQuestion.goals.${userRole}`)}
                        value={competency.goals}
                        className="development-plan-evaluation-competency-card__question"
                    />
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans(`containers.developmentPlanEvaluation.developmentPlanCompetencyCardQuestion.remarks.${userRole}`)}
                        value={competency.remarks}
                        className="development-plan-evaluation-competency-card__question"
                    />
                </div>
            </form>
        </Card>
    );
};

export default DevelopmentPlanEvaluationCompetencyCard;
