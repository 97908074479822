import { formatDate } from '../../helpers/date';
import { generateFullName } from '../../helpers/user';
import { Gender } from '../../types';
import {
    Candidate,
    CandidateCounselor,
    CandidateCounselorResource,
    CandidateInviteFormData,
    CandidateResource,
    CreateCandidateRequest,
} from './Candidate';

export const transformToCreateCandidateRequest = (
    formData: CandidateInviteFormData,
): CreateCandidateRequest => ({
    firstName: formData.firstName,
    lastNamePrefix: formData.lastNamePrefix,
    lastName: formData.lastName,
    email: formData.email,
    gender: formData.gender,
});

export const transformToCandidate = (
    candidateResource: CandidateResource,
): Candidate => {
    const fullName = generateFullName(candidateResource);

    const dateOfBirth = candidateResource.dateOfBirth
        ? formatDate(new Date(candidateResource.dateOfBirth), 'y-MM-dd')
        : undefined;

    return ({
        uuid: candidateResource.candidateId,
        email: candidateResource.email,
        firstName: candidateResource.firstName,
        lastName: candidateResource.lastName,
        lastNamePrefix: candidateResource.lastNamePrefix,
        fullName,
        gender: candidateResource.gender as Gender,
        dateOfBirth,
        phoneNumber: candidateResource.phoneNumber,
        address: candidateResource.address,
        firstVisit: candidateResource.firstVisit,
        createdAt: candidateResource.createdAt,
    });
};

export const transformToCandidateCounselor = (
    candidateCounselorResource: CandidateCounselorResource,
): CandidateCounselor => {
    const fullName = generateFullName(candidateCounselorResource);

    return {
        id: candidateCounselorResource.id,
        firstName: candidateCounselorResource.firstName,
        lastName: candidateCounselorResource.lastName,
        lastNamePrefix: candidateCounselorResource.lastNamePrefix,
        fullName,
        email: candidateCounselorResource.email,
    };
};
