import { AddDevelopmentPlanSkillFormData, EditDevelopmentPlanSkillFormData } from '../../models/DevelopmentPlanSkill/DevelopmentPlanSkill';
import { deleteDevelopmentPlanSkillApiCall, patchDevelopmentPlanSkillApiCall, postDevelopmentPlanSkillApiCall } from '../../models/DevelopmentPlanSkill/DevelopmentPlanSkillService';
import { transformToAddDevelopmentPlanSkillRequest, transformToEditDevelopmentPlanSkillRequest } from '../../models/DevelopmentPlanSkill/DevelopmentPlanSkillTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { setError } from '../app/app';
import { TypedDispatch } from '../store';
import { setIsLoading, setIsSuccessful, setUpdatingSkillUuid } from './developmentPlanSkill';

export const addDevelopmentPlanSkill = (formData: AddDevelopmentPlanSkillFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToAddDevelopmentPlanSkillRequest(formData);
        const developmentPlanSkillResponse = await postDevelopmentPlanSkillApiCall(requestData, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanSkillResponse)) {
            dispatch(setError(developmentPlanSkillResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlanSkill]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDevelopmentPlanSkill = (formData: EditDevelopmentPlanSkillFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setUpdatingSkillUuid(formData.id));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToEditDevelopmentPlanSkillRequest(formData);
        const developmentPlanSkillResponse = await patchDevelopmentPlanSkillApiCall(requestData, formData.id, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanSkillResponse)) {
            dispatch(setError(developmentPlanSkillResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDevelopmentPlanSkill]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setUpdatingSkillUuid(''));
    }
};

export const deleteDevelopmentPlanSkill = (id: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentPlanSkillResponse = await deleteDevelopmentPlanSkillApiCall(id, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanSkillResponse)) {
            dispatch(setError(developmentPlanSkillResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDevelopmentPlanSkill]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
