import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './OccupationOverviewSkeletons.scss';

interface OccupationOverviewSkeletonsProps {
    showViewOptions?: boolean;
    amount?: number;
    className?: string;
}

const OccupationOverviewSkeletons: FC<OccupationOverviewSkeletonsProps> = ({
    showViewOptions,
    amount = 3,
    className = '',
}): ReactElement => {
    const skeletons = generateIdArray(amount);

    return (
        <div className={`occupation-overview-skeletons ${className}`}>
            <div className="occupation-overview-skeletons__header">
                <Skeleton className="occupation-overview-skeletons__title" />

                {showViewOptions && (
                    <Skeleton className="occupation-overview-skeletons__view-selector" />
                )}
            </div>

            <ul className="occupation-overview-skeletons__list">
                {skeletons.map(skeleton => {
                    const descriptionAmount = randomInBetweenValue(1, 2);
                    const descriptionSkeletons = generateIdArray(descriptionAmount);

                    return (
                        <li key={skeleton} className="occupation-overview-skeletons__list-item">
                            <Card className="occupation-overview-skeletons__card">
                                <div className="occupation-overview-skeletons__text">
                                    <Skeleton className="occupation-overview-skeletons__name" />

                                    {descriptionSkeletons.map(description => (
                                        <Skeleton key={description} className="occupation-overview-skeletons__description" />
                                    ))}
                                </div>

                                <div className="occupation-overview-skeletons__matched-skills">
                                    <div className="occupation-overview-skeletons__progress-circle" />
                                </div>
                            </Card>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default OccupationOverviewSkeletons;
