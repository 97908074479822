export interface Pagination {
    total: number;
    totalPages: number;
    perPage: number;
    currentPage: number;
}

export const defaultPageSize = 10;
export const maximumAmountOfVacancies = 250;

export const transformToPagination = (total: number, currentPage = 1): Pagination => {
    const perPage = defaultPageSize;
    const totalPages = Math.ceil(total / perPage);

    return {
        total,
        totalPages,
        perPage,
        currentPage,
    };
};
