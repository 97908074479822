import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { defaultEducationFilterValues, EducationFilterValues, EducationResource } from '../Educations/Educations';
import { transformFilterValuesToFilterParams } from '../Educations/EducationsTransformer';
import { FetchResult, FetchResultType } from '../FetchResult';
import { EducationFilterOptions } from './EducationFormOptions';
import { transformToFilterOptions } from './EducationFormOptionsTransformer';

export const getEducationFilterOptionsApiCall = async (candidateUuid: string, activeFilterValues: EducationFilterValues): Promise<FetchResult<EducationFilterOptions, string>> => {
    const filters = activeFilterValues.searchQuery
        ? transformFilterValuesToFilterParams({
            ...defaultEducationFilterValues(),
            ...activeFilterValues,
        })
        : {};

    const facetLimit = 25;

    try {
        const response = await lloFetch('/api/externalEducation/search', {
            candidateUuid,
            method: 'POST',
            body: JSON.stringify({
                ...filters,
                facets: [
                    'level_nl',
                    'domainName',
                    'providerName',
                    'startMoments',
                    'variants_nl',
                    'subsidies',
                    'locations',
                    'courseDurationInDays',
                ].map(facet => `${facet},count:${facetLimit}`),
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data: EducationResource = await response.json();

        const filterOptions = transformToFilterOptions(data.facets);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: filterOptions,
        };
    } catch (error) {
        console.error('[getEducationFilterOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
