import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Card, SafeHtml } from '../../components';
import {
    Dialog,
    DialogContent,
    IconButton,
    LeavingPlatformDialog,
    LogoCard,
    Notice,
} from '../../compositions';
import { CandidateIllustrationNotification } from '../../compositions/CandidateIllustration/CandidateIllustration';
import { tmaUrl } from '../../constants';
import { closeDialog, openDialog } from '../../helpers/dialog';
import { getRoleForTranslation, userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { TmaAnalysisSettings, TmaAnalysisSettingsFormData, TmaAnalysisType } from '../../models/TmaAnalysis/TmaAnalysis';
import { User } from '../../models/User/User';
import { Gender, NoticeType, UserRole } from '../../types';
import { TmaAnalysisSuccessMessage } from '../@skillsPassport/SkillsPassportHeader/subcomponents';
import { TmaAnalysisSettingsForm } from '../index';
import { TakeAnalysisSkeletons } from './skeletons';

import './TakeAnalysis.scss';

interface TakeAnalysisProps {
    isLoading: boolean;
    tmaAnalysisSettingsIsLoading: boolean;
    tmaAnalysisSettingsIsSuccessful: boolean;
    user?: User;
    candidate?: Candidate;
    tmaAnalysisSettings?: TmaAnalysisSettings;
    tmaAnalysisSettingsError: string;
    onFinishTmaAnalysisSettings: () => void;
    onSubmitTmaAnalysisSettings: (tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData) => void;
    className?: string;
}

const TakeAnalysis: FC<TakeAnalysisProps> = ({
    isLoading,
    tmaAnalysisSettingsIsLoading,
    tmaAnalysisSettingsIsSuccessful,
    user,
    candidate,
    tmaAnalysisSettings,
    tmaAnalysisSettingsError,
    onFinishTmaAnalysisSettings,
    onSubmitTmaAnalysisSettings,
    className = '',
}): ReactElement => {
    const counselorDialogRef = useRef<HTMLDialogElement>(null);
    const candidateDialogRef = useRef<HTMLDialogElement>(null);

    const [chosenTmaAnalysisType, setChosenTmaAnalysisType] = useState<TmaAnalysisType>(TmaAnalysisType.TMA170);
    const [tmaAnalysisSettingsFormKey, setTmaAnalysisSettingsFormKey] = useState<number>(0);

    const isCounselor = userHasRole(UserRole.counselor, user?.roles);
    const isCandidate = userHasRole(UserRole.candidate, user?.roles);

    const handleOpenCounselorDialog = (): void => openDialog(counselorDialogRef);
    const handleCloseCounselorDialog = (): void => {
        onFinishTmaAnalysisSettings();
        setChosenTmaAnalysisType(TmaAnalysisType.TMA170);

        closeDialog(counselorDialogRef);

        setTmaAnalysisSettingsFormKey(tmaAnalysisSettingsFormKey + 1);
    };

    const handleOpenCandidateDialog = (): void => openDialog(candidateDialogRef);
    const handleCloseCandidateDialog = (): void => closeDialog(candidateDialogRef);

    const handleSubmitTmaAnalysisSettings = (tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData): void => {
        setChosenTmaAnalysisType(tmaAnalysisSettingsFormData.type);
        onSubmitTmaAnalysisSettings(tmaAnalysisSettingsFormData);
    };

    return (
        <div className={`take-analysis ${className}`}>
            {isLoading && <TakeAnalysisSkeletons className="take-analysis__skeletons" />}

            {!isLoading && (
                <>
                    <LogoCard logo="tma" className="take-analysis__logo-card" />

                    <Card className="take-analysis__description-card">
                        <h1 className="take-analysis__title">
                            {trans('containers.takeAnalysis.descriptionTitle')}
                        </h1>

                        <SafeHtml
                            html={trans(`containers.takeAnalysis.descriptionText.${getRoleForTranslation(user?.roles)}`)}
                            className="take-analysis__description"
                        />

                        <div className="take-analysis__notice-and-button-wrapper">
                            {!tmaAnalysisSettings?.isActive && (
                                <Notice
                                    type={NoticeType.warning}
                                    text={user && user.roles.length > 0 ? trans(`containers.takeAnalysis.errorMessage.${getRoleForTranslation(user?.roles)}`) : ''}
                                    className="take-analysis__error-message"
                                />
                            )}

                            {tmaAnalysisSettings?.isFinished && (
                                <Notice
                                    type={NoticeType.warning}
                                    text={trans('containers.takeAnalysis.finishedMessage.description')}
                                    className="take-analysis__error-message"
                                />
                            )}

                            {isCounselor && !tmaAnalysisSettings?.isActive && (
                                <IconButton
                                    icon="settings"
                                    text={trans('containers.takeAnalysis.startAnalysis')}
                                    onClick={handleOpenCounselorDialog}
                                    className="take-analysis__start-analysis-button"
                                />
                            )}

                            {isCounselor && tmaAnalysisSettings?.isActive && (
                                <Notice
                                    type={NoticeType.regular}
                                    text={trans('containers.takeAnalysis.activeMessage.description')}
                                    className="take-analysis__active-message"
                                />
                            )}

                            {isCandidate && tmaAnalysisSettings?.isActive && !tmaAnalysisSettings?.isFinished && (
                                <IconButton
                                    icon="arrow-right"
                                    iconPos="right"
                                    text={trans('containers.takeAnalysis.buttonText')}
                                    disabled={isLoading || tmaAnalysisSettings?.isFinished}
                                    onClick={handleOpenCandidateDialog}
                                    className="take-analysis__take-analysis-button"
                                />
                            )}
                        </div>
                    </Card>
                </>
            )}

            {isCandidate && (
                <LeavingPlatformDialog
                    ref={candidateDialogRef}
                    illustrationType={CandidateIllustrationNotification.tma}
                    gender={candidate?.gender as Gender}
                    title={trans('containers.takeAnalysis.dialog.title')}
                    description={trans('containers.takeAnalysis.dialog.description')}
                    buttonText={trans('containers.takeAnalysis.dialog.buttonText')}
                    buttonUrl={tmaUrl}
                    onClose={handleCloseCandidateDialog}
                />
            )}

            {isCounselor && (
                <Dialog
                    ref={counselorDialogRef}
                    enableBackdropClose={tmaAnalysisSettingsIsSuccessful}
                    onClose={handleCloseCounselorDialog}
                >
                    {tmaAnalysisSettingsIsSuccessful ? (
                        <TmaAnalysisSuccessMessage
                            candidate={candidate}
                            tmaAnalysisType={chosenTmaAnalysisType}
                            onConfirm={handleCloseCounselorDialog}
                        />
                    ) : (
                        <DialogContent title={trans('containers.skillsPassportHeader.tmaAnalysisSettings.title')}>
                            <TmaAnalysisSettingsForm
                                key={tmaAnalysisSettingsFormKey}
                                isLoading={tmaAnalysisSettingsIsLoading}
                                tmaAnalysisSettings={tmaAnalysisSettings}
                                error={tmaAnalysisSettingsError}
                                onSubmit={handleSubmitTmaAnalysisSettings}
                                onCancel={handleCloseCounselorDialog}
                            />
                        </DialogContent>
                    )}
                </Dialog>
            )}
        </div>
    );
};

export default TakeAnalysis;
