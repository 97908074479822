import { FC, ReactElement } from 'react';

import { SingleValue } from 'react-select';

import { ErrorLabel, InputLabel } from '../../../components';
import Select from '../../../components/@inputs/Select/Select';
import { FormOption } from '../../../types';

import './SelectInput.scss';

interface SelectInputProps {
    defaultMenuIsOpen?: boolean;
    hasAutoFocus?: boolean;
    hideLabel?: boolean;
    isLoading?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    required?: boolean;
    error?: string;
    label: string;
    placeholder?: string;
    name: string;
    options: FormOption[];
    value?: SingleValue<FormOption>;
    onChange: (value: string) => void;
    className?: string;
}

const SelectInput: FC<SelectInputProps> = ({
    defaultMenuIsOpen = false,
    hasAutoFocus = false,
    hideLabel = false,
    isLoading = false,
    isClearable = false,
    isDisabled = false,
    required = false,
    error = '',
    label,
    placeholder,
    name,
    options,
    value,
    onChange,
    className = '',
}): ReactElement => {
    const handleChange = (selected: FormOption | null): void => {
        if (selected) {
            onChange(selected.value);
        } else {
            onChange('');
        }
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`select-input ${className}`}
        >
            {!hideLabel && <InputLabel text={label} required={required} className="select-input__label" />}
            <Select
                defaultMenuIsOpen={defaultMenuIsOpen}
                hasAutoFocus={hasAutoFocus}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                error={error}
                name={name}
                placeholder={placeholder}
                options={options}
                value={value}
                onChange={handleChange}
            />
            {error && <ErrorLabel text={error} className="select-input__error-label" />}
        </label>
    );
};

export default SelectInput;
