/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconEmotionHappy: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M96.2,30.9c-2.5-6.1-6.2-11.6-10.8-16.2C80.7,10,75.2,6.3,69.1,3.8C63.1,1.3,56.6,0,50,0C36.7,0,24,5.3,14.6,14.6S0,36.7,0,50c0,13.3,5.3,26,14.6,35.4c4.6,4.6,10.2,8.3,16.2,10.8c6.1,2.5,12.6,3.8,19.1,3.8c13.3,0,26-5.3,35.4-14.6C94.7,76,100,63.3,100,50C100,43.4,98.7,36.9,96.2,30.9z M78.3,78.3C70.8,85.8,60.6,90,50,90c-10.6,0-20.8-4.2-28.3-11.7C14.2,70.8,10,60.6,10,50c0-10.6,4.2-20.8,11.7-28.3C29.2,14.2,39.4,10,50,10c10.6,0,20.8,4.2,28.3,11.7C85.8,29.2,90,39.4,90,50C90,60.6,85.8,70.8,78.3,78.3z" />
        <circle cx="67.5" cy="37.5" r="7.5" />
        <circle cx="32.5" cy="37.5" r="7.5" />
        <path d="M50,76.2c-8.8,0-16.5-3.7-21-9.1l7.1-7.1c2.2,3.6,7.6,6.1,13.8,6.1s11.6-2.5,13.9-6.1l7.1,7.1C66.4,72.5,58.8,76.2,50,76.2z" />
    </svg>
);

export default IconEmotionHappy;
/* eslint-enable max-len */
