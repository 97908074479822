import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Skill, SkillResource } from './Skills';
import { transformToSkill } from './SkillsTransformers';

export const getSkillsApiCall = async (): Promise<FetchResult<Skill[], string>> => {
    try {
        const response = await lloFetch('/api/escoSkills');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const skillsResponse: SkillResource[] = await response.json();

        const skills = skillsResponse.map(transformToSkill);
        const sortedSkills = skills.sort((skill, comparisonSkill) => skill.name.localeCompare(comparisonSkill.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedSkills,
        };
    } catch (error) {
        console.error('[getSkillsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postCandidateSkillsApiCall = async (skillIds: string[], candidateUuid: string): Promise<FetchResult<Skill[], string>> => {
    try {
        const response = await lloFetch(`/api/userSkills/${candidateUuid}`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify(skillIds),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const skillsResponse: SkillResource[] = await response.json();

        const skills = skillsResponse.map(transformToSkill);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: skills,
        };
    } catch (error) {
        console.error('[postCandidateSkillsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
