import { capitalizeFirstCharacter } from '../../helpers/string';
import { Occupation, OccupationResource } from './Occupations';

export const transformToOccupation = (
    occupationResource: OccupationResource,
): Occupation => ({
    id: occupationResource.id,
    name: capitalizeFirstCharacter(occupationResource.name),
    description: occupationResource.description,
    totalSkills: occupationResource.totalSkillCount,
    matchedSkills: occupationResource.matchedSkillCount,
    matchedPercentage: occupationResource.matchedPercentage,
});
