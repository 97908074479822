import { FC, ReactElement } from 'react';

import { LearningStyleCard } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedLearningStyleCardProps {
    onRefresh: () => void;
    className?: string;
}

const ConnectedLearningStyleCard: FC<ConnectedLearningStyleCardProps> = ({
    onRefresh,
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const learningStyle = useTypedSelector(state => state.skillsPassportReducer.learningStyle);
    const isUpToDate = useTypedSelector(state => state.skillsPassportReducer.isUpToDate);

    return (
        <LearningStyleCard
            isLoading={skillsPassportIsLoading}
            isUpToDate={isUpToDate}
            candidateUuid={candidate?.uuid}
            userRoles={user?.roles}
            learningStyle={learningStyle}
            handleRefresh={onRefresh}
            className={className}
        />
    );
};

export default ConnectedLearningStyleCard;
