import { formatDate } from '../../helpers/date';
import {
    SchoolExperience,
    SchoolExperienceFormData,
    SchoolExperienceRequest,
    SchoolExperienceResource,
} from './SchoolExperience';

export const transformToSchoolExperience = (
    schoolExperienceResource: SchoolExperienceResource,
): SchoolExperience => ({
    id: schoolExperienceResource.id,
    educationLevel: schoolExperienceResource.educationLevel,
    educationLevelId: schoolExperienceResource.educationLevelId,
    educationSubLevel: schoolExperienceResource.educationSubLevel,
    educationSubLevelId: schoolExperienceResource.educationSubLevelId,
    learningPath: schoolExperienceResource.learningPath,
    learningPathId: schoolExperienceResource.learningPathId,
    fieldOfStudy: schoolExperienceResource.fieldOfStudy,
    fieldOfStudyId: schoolExperienceResource.fieldOfStudyId,
    school: schoolExperienceResource.school,
    schoolId: schoolExperienceResource.schoolId,
    startDate: new Date(schoolExperienceResource.startDate),
    endDate: new Date(schoolExperienceResource.endDate),
});

export const transformToSchoolExperienceRequest = (
    schoolExperienceFormData: SchoolExperienceFormData,
): SchoolExperienceRequest => {
    const startDate = formatDate(schoolExperienceFormData.startDate, 'y-MM-dd');
    const endDate = formatDate(schoolExperienceFormData.endDate, 'y-MM-dd');

    return ({
        id: schoolExperienceFormData.id,
        educationLevel: schoolExperienceFormData.educationLevel,
        educationLevelId: schoolExperienceFormData.educationLevelId,
        educationSubLevel: schoolExperienceFormData.educationSubLevel,
        educationSubLevelId: schoolExperienceFormData.educationSubLevelId,
        learningPath: schoolExperienceFormData.learningPath,
        learningPathId: schoolExperienceFormData.learningPathId,
        fieldOfStudy: schoolExperienceFormData.fieldOfStudy,
        fieldOfStudyId: schoolExperienceFormData.fieldOfStudyId,
        school: schoolExperienceFormData.school,
        schoolId: schoolExperienceFormData.schoolId,
        startDate,
        endDate,
    });
};
