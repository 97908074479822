import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    AddingCard,
    CallToActionNullState,
    Dialog,
    DialogContent,
    Loading,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { SchoolExperience, SchoolExperienceFormData } from '../../../models/SchoolExperience/SchoolExperience';
import { UserRole } from '../../../types';
import { SchoolExperienceForm } from '../..';
import { SchoolExperienceFormOptions } from '../../@forms/SchoolExperienceForm/SchoolExperienceForm';
import { SchoolExperienceItem } from './subcomponents';

import './SchoolExperiencesCard.scss';

interface SchoolExperiencesCardProps extends SchoolExperienceFormOptions {
    isLoading: boolean;
    schoolExperienceIsLoading: boolean;
    schoolExperienceIsSuccessful: boolean;
    hasError?: boolean;
    schoolExperienceError: string;
    userRoles?: UserRole[];
    schoolExperiences: SchoolExperience[];
    onAddSchoolExperience: (schoolExperienceFormData: SchoolExperienceFormData) => void;
    onEditSchoolExperience: (schoolExperienceFormData: SchoolExperienceFormData) => void;
    onDeleteSchoolExperience: (schoolExperience: SchoolExperience) => void;
    onIsSuccessfulReset: () => void;
    className?: string;
}

const SchoolExperiencesCard: FC<SchoolExperiencesCardProps> = ({
    isLoading,
    schoolExperienceIsLoading,
    schoolExperienceIsSuccessful,
    hasError,
    schoolExperienceError,
    userRoles = [],
    schoolExperiences,
    educationLevelOptions,
    educationSubLevelOptions,
    learningPathOptions,
    fieldOfStudyOptions,
    schoolOptions,
    onAddSchoolExperience,
    onEditSchoolExperience,
    onDeleteSchoolExperience,
    onIsSuccessfulReset,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [schoolExperienceFormKey, setSchoolExperienceFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setSchoolExperienceFormKey(schoolExperienceFormKey + 1);
    };

    const schoolExperienceFormOptions = {
        educationLevelOptions,
        educationSubLevelOptions,
        learningPathOptions,
        fieldOfStudyOptions,
        schoolOptions,
    };

    useEffect((): void => {
        if (schoolExperienceIsSuccessful) {
            handleCloseDialog();
            onIsSuccessfulReset();
        }
    }, [schoolExperienceIsSuccessful]);

    return (
        <AddingCard
            isEditable={userHasRole(UserRole.candidate, userRoles) && schoolExperiences.length > 0}
            showFillLabel={schoolExperiences.length === 0 && !isLoading}
            title={trans('containers.schoolExperiencesCard.title')}
            item={trans('common.schoolExperience')}
            userRoles={userRoles}
            onAddClick={handleOpenDialog}
            className={`school-experiences-card ${className}`}
        >
            {isLoading && <Loading className="school-experiences-card__loader" />}

            {!isLoading && schoolExperiences.length === 0 && (
                <CallToActionNullState
                    isEditable={!hasError && userHasRole(UserRole.candidate, userRoles)}
                    icon="education"
                    paragraph={trans('containers.schoolExperiencesCard.nullState.paragraph')}
                    subParagraph={trans('containers.schoolExperiencesCard.nullState.subParagraph')}
                    buttonText={trans('containers.schoolExperiencesCard.nullState.callToAction')}
                    onButtonClick={handleOpenDialog}
                    className="school-experiences-card__null-state"
                />
            )}

            {!isLoading && schoolExperiences.length > 0 && (
                <ul className="school-experiences-card__school-experience-wrapper">
                    {schoolExperiences.map(schoolExperience => (
                        <SchoolExperienceItem
                            {...schoolExperienceFormOptions}
                            key={schoolExperience.id}
                            isLoading={schoolExperienceIsLoading}
                            isSuccessful={schoolExperienceIsSuccessful}
                            isEditable={userHasRole(UserRole.candidate, userRoles)}
                            error={schoolExperienceError}
                            schoolExperience={schoolExperience}
                            onEdit={onEditSchoolExperience}
                            onDelete={onDeleteSchoolExperience}
                            onIsSuccessfulReset={onIsSuccessfulReset}
                            className="school-experiences-card__school-experience"
                        />
                    ))}
                </ul>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent title={trans('forms.schoolExperience.addTitle')}>
                    <SchoolExperienceForm
                        {...schoolExperienceFormOptions}
                        key={schoolExperienceFormKey}
                        isLoading={schoolExperienceIsLoading}
                        error={schoolExperienceError}
                        onSubmit={onAddSchoolExperience}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </AddingCard>
    );
};

export default SchoolExperiencesCard;
