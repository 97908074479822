/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconNoticeWarning: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect x="45.5" y="42.2" width="9.1" height="22.7" />
        <rect x="45.5" y="69.5" width="9.1" height="9.1" />
        <path d="M50,24l34.2,59.1H15.8L50,24z M50,5.8L0,92.2h100L50,5.8z" />
    </svg>
);

export default IconNoticeWarning;
/* eslint-enable max-len */
