import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon, LoadingSpinner } from '../../../components';
import { ButtonWithRefProps } from '../../../components/@buttons/Button/Button';
import { IconName } from '../../../components/Icon/Icon';

import './SubmitButton.scss';

interface SubmitButtonProps extends ButtonWithRefProps {
    isLoading?: boolean;
    hideLabel?: boolean;
    icon?: IconName;
    iconPos?: 'left' | 'right';
}

const SubmitButton: FC<SubmitButtonProps> = ({
    isLoading,
    text,
    hideLabel,
    icon,
    iconPos = 'left',
    disabled,
    className = '',
    ...submitButtonProps
}): ReactElement => {
    const submitButtonClassNames = classNames('submit-button', {
        [`submit-button--align-${iconPos}`]: iconPos,
        'submit-button--hidden-label': hideLabel,
    }, className);

    return (
        <Button
            {...submitButtonProps}
            type="submit"
            text={text}
            disabled={isLoading || disabled}
            className={submitButtonClassNames}
        >
            {isLoading ? (
                <LoadingSpinner className="submit-button__icon" />
            ) : (
                icon && <Icon name={icon} className="submit-button__icon" />
            )}

            {!hideLabel && text}
        </Button>
    );
};

export default SubmitButton;
