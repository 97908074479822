import { FC, ReactElement } from 'react';

import { Competency } from '../../../models/Competencies/Competencies';
import { Skill } from '../../../models/Skills/Skills';
import { Vacancy } from '../../../models/Vacancy';
import { UserRole } from '../../../types';
import { VacancyOverviewSkeletons } from './skeletons';
import { VacancyDetailAside, VacancyDetailBody, VacancyDetailHeader } from './subcomponents';

import './VacancyDetail.scss';

export interface VacancyDetailProps {
    isLoading: boolean;
    userRoles?: UserRole[];
    vacancy?: Vacancy;
    skills: Skill[];
    candidateCompetencies: Competency[];
    candidateSkills: Skill[];
    className?: string;
}

const VacancyDetail: FC<VacancyDetailProps> = ({
    isLoading,
    userRoles = [],
    vacancy,
    skills,
    candidateCompetencies,
    candidateSkills,
    className = '',
}): ReactElement => {
    if (isLoading || !vacancy) {
        return <VacancyOverviewSkeletons className={className} />;
    }

    return (
        <div className={`vacancy-detail ${className}`}>
            <VacancyDetailHeader
                vacancy={vacancy}
                className="vacancy-detail__header"
            />

            <div className="vacancy-detail__content-wrapper">
                <VacancyDetailBody
                    userRoles={userRoles}
                    vacancy={vacancy}
                    skills={skills}
                    candidateCompetencies={candidateCompetencies}
                    candidateSkills={candidateSkills}
                    className="vacancy-detail__body"
                />
                <VacancyDetailAside
                    userRoles={userRoles}
                    vacancy={vacancy}
                    className="vacancy-detail__aside"
                />
            </div>
        </div>
    );
};

export default VacancyDetail;
