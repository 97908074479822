import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Card } from '../../../components';
import {
    CallToActionNullState,
    Dialog,
    DialogContent,
    IconButton,
    Notice,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { OrganisationOccupationFormData } from '../../../models/OrganisationOccupation/OrganisationOccupation';
import { OrganisationOccupationOverviewItem } from '../../../models/OrganisationOccupationOverview/OrganisationOccupationOverview';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { NoticeType } from '../../../types';
import { OrganisationOccupationForm, Pagination } from '../..';
import { OrganisationDetailOccupationOverviewSkeletons } from './skeletons';
import { OccupationLinkCard } from './subcomponents';

import './OrganisationDetailOccupationOverview.scss';

interface OrganisationDetailOccupationOverviewProps {
    isLoading: boolean;
    occupationIsLoading: boolean;
    occupationIsSuccessful: boolean;
    error: string;
    occupationError: string;
    organisationUuid?: string;
    totalAmount: number;
    occupations: OrganisationOccupationOverviewItem[];
    pagination?: PaginationModel;
    onPaginationChange: (currentPage: number) => void;
    onOccupationFormSubmit: (formData: OrganisationOccupationFormData) => void;
    className?: string;
}

const OrganisationDetailOccupationOverview: FC<OrganisationDetailOccupationOverviewProps> = ({
    isLoading,
    occupationIsLoading,
    occupationIsSuccessful,
    error,
    occupationError,
    organisationUuid = '',
    totalAmount,
    occupations,
    pagination,
    onPaginationChange,
    onOccupationFormSubmit,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [organisationOccupationFormKey, setOrganisationOccupationFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setOrganisationOccupationFormKey(organisationOccupationFormKey + 1);
    };

    useEffect((): void => {
        if (occupationIsSuccessful) handleCloseDialog();
    }, [occupationIsSuccessful]);

    return (
        <div className={`organisation-detail-occupation-overview ${className}`}>
            {isLoading && (
                <OrganisationDetailOccupationOverviewSkeletons />
            )}

            {!isLoading && error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                />
            )}

            {!isLoading && !error && (
                <>
                    <header className="organisation-detail-occupation-overview__header">
                        <h2 className="organisation-detail-occupation-overview__title">
                            {trans('containers.organisationDetailOccupationOverview.totalAmount', {
                                totalAmount,
                            })}
                        </h2>

                        {occupations.length > 0 && (
                            <IconButton
                                icon="plus"
                                text={trans('containers.organisationDetailOccupationOverview.addOccupation')}
                                onClick={handleOpenDialog}
                            />
                        )}
                    </header>

                    {occupations.length === 0 && (
                        <Card className="organisation-detail-occupation-overview__null-state-card">
                            <CallToActionNullState
                                isEditable
                                icon="occupation"
                                paragraph={trans('containers.organisationDetailOccupationOverview.nullState.paragraph')}
                                subParagraph={trans('containers.organisationDetailOccupationOverview.nullState.subParagraph')}
                                buttonText={trans('containers.organisationDetailOccupationOverview.nullState.callToAction')}
                                onButtonClick={handleOpenDialog}
                                className="organisation-detail-occupation-overview__null-state"
                            />
                        </Card>
                    )}

                    {occupations.length > 0 && (
                        <ul className="organisation-detail-occupation-overview__list">
                            {occupations.map(occupation => (
                                <OccupationLinkCard
                                    key={occupation.id}
                                    organisationUuid={organisationUuid}
                                    occupation={occupation}
                                    className="organisation-detail-occupation-overview__list-item"
                                />
                            ))}
                        </ul>
                    )}

                    {pagination && (
                        <Pagination
                            id="organisation-occupation-overview"
                            amountOfPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            onChange={onPaginationChange}
                            className="organisation-detail-occupation-overview__pagination"
                        />
                    )}
                </>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent title={trans('containers.organisationDetailOccupationOverview.addDialog.title')}>
                    <OrganisationOccupationForm
                        key={organisationOccupationFormKey}
                        isLoading={occupationIsLoading}
                        error={occupationError}
                        onSubmit={onOccupationFormSubmit}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default OrganisationDetailOccupationOverview;
