import trans from '../helpers/trans';
import { TmaAnalysisType } from '../models/TmaAnalysis/TmaAnalysis';
import { AsyncReduxState, FormOption } from '../types';

export const tmaUrl = `${process.env.REACT_APP_TMA_URL}`;
export const talentzJobVacancyUrl = (vacancyUuid: string): string => `${process.env.REACT_APP_TALENTZ_FE_URL}/baanvacature/${vacancyUuid}`;
export const educationSearchUrl = 'https://opleiding.nl/Zoekresultaten.html';

export const authenticationApiUrl = process.env.REACT_APP_AUTHENTICATION_SERVICE_API_URL;
export const matchingServiceApiUrl = process.env.REACT_APP_MATCHING_SERVICE_API_URL;
export const talentzApiUrl = process.env.REACT_APP_TALENTZ_API_URL;
export const talentzStorageUrl = process.env.REACT_APP_TALENTZ_STORAGE_URL;
export const lloApiUrl = process.env.REACT_APP_LLO_API_URL;

export const sessionDurationInMinutes = 60 * 6;
export const sessionLogoutDelay = 1000 * 60 * sessionDurationInMinutes;
export const debounceDelay = 250;
export const longDebounceDelay = debounceDelay * 2;

export const defaultTravelDistance = 25;
export const fulltimeThreshold = 32;
export const maxEmploymentYears = 100;
export const maxEmploymentHoursPerWeek = 40;
export const maxGrossMonthlySalary = 10000;

export const amountOfOccupations = 20;

export const lloOrganisationName = 'Leven Lang Ontwikkelen organisatie';

export const defaultFormOption = (): FormOption => ({
    value: '',
    label: '',
});

export const tmaAnalysisTypes = [
    {
        label: 'TMA 170',
        description: trans('forms.tmaAnalysisSettings.tma170Description'),
        value: TmaAnalysisType.TMA170,
    },
    {
        label: 'TMA 312',
        description: trans('forms.tmaAnalysisSettings.tma312Description'),
        value: TmaAnalysisType.TMA312,
    },
];

export const aliasSplitCharacter = '|';

export const initialAsyncReduxState: AsyncReduxState = {
    isLoading: false,
    isSuccessful: false,
    error: '',
};
