import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ErrorLabel, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import Select from '../../../components/@inputs/Select/Select';
import { FormOption } from '../../../types';
import {
    defaultMaxYear,
    defaultMinYear,
    generateYearOptions,
    monthOptions,
} from './helpers';

import './MonthYearInput.scss';

interface MonthYearInputProps extends Omit<HTMLInputProps, 'value' | 'onChange'> {
    label: string;
    hideLabel?: boolean;
    name: string;
    value?: Date;
    minYear?: number;
    maxYear?: number;
    required?: boolean;
    error?: string;
    onChange: (value: Date) => void;
}

const MonthYearInput: FC<MonthYearInputProps> = ({
    label,
    hideLabel = false,
    name,
    value,
    minYear = defaultMinYear,
    maxYear = defaultMaxYear,
    required = false,
    error = '',
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const [month, setMonth] = useState<number>();
    const [year, setYear] = useState<number>();

    useEffect((): void => {
        if (value) {
            setMonth(value.getMonth());
            setYear(value.getFullYear());
        }
    }, [value]);

    const yearOptions = generateYearOptions(minYear, maxYear);

    const handleMonthChange = (monthOption: FormOption): void => {
        const monthNumber = Number(monthOption.value);

        setMonth(monthNumber);
        onChange(new Date(year || minYear, monthNumber, 1));
    };

    const handleYearChange = (yearOption: FormOption): void => {
        const yearNumber = Number(yearOption.value);

        setYear(yearNumber);
        onChange(new Date(yearNumber, month || 0, 1));
    };

    return (
        <div className={`month-year-input ${className}`}>
            {!hideLabel && <InputLabel text={label} required={required} className="month-year-input__label" />}
            <div className="month-year-input__input-wrapper">
                <Select
                    {...inputProps}
                    name={`${name}-month`}
                    options={monthOptions}
                    value={monthOptions.find(option => Number(option.value) === month)}
                    error={error}
                    onChange={handleMonthChange}
                    className="month-year-input__input"
                />
                <Select
                    {...inputProps}
                    name={`${name}-year`}
                    options={yearOptions}
                    value={yearOptions.find(option => Number(option.value) === year)}
                    error={error}
                    onChange={handleYearChange}
                    className="month-year-input__input"
                />
            </div>
            {error && <ErrorLabel text={error} className="month-year-input__error-label" />}
        </div>
    );
};

export default MonthYearInput;
