import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getResidencesApiCall } from '../../services/ResidencesService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setResidences } from './residences';

export const fetchResidences = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const residencesResponse = await getResidencesApiCall();

        if (!isFetchResultSuccessful(residencesResponse)) {
            dispatch(setError(residencesResponse.error));
            return;
        }

        dispatch(setResidences(residencesResponse.data));
    } catch (error) {
        console.error('[fetchResidences]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
