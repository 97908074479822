import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../components';
import { IconName } from '../../components/Icon/Icon';
import { MessageState } from '../../types';

import './Disclaimer.scss';

interface DisclaimerProps {
    messageState?: MessageState;
    icon?: IconName;
    title: string;
    description: string;
    className?: string;
}


const Disclaimer: FC<DisclaimerProps> = ({
    messageState = MessageState.warning,
    icon = 'exclamation',
    title,
    description,
    className = '',
}): ReactElement => {
    const disclaimerClassNames = classNames('disclaimer', {
        [`disclaimer--is-${messageState}`]: messageState,
    }, className);

    return (
        <div className={disclaimerClassNames}>
            <div className="disclaimer__icon-wrapper">
                <Icon name={icon} className="disclaimer__icon" />
            </div>

            <div className="disclaimer__text-wrapper">
                <h3 className="disclaimer__title">{title}</h3>
                <p className="disclaimer__description">{description}</p>
            </div>
        </div>
    );
};

export default Disclaimer;
