import { capitalizeFirstCharacter } from '../../helpers/string';
import { FormOption } from '../../types';
import {
    OccupationDetail,
    OccupationDetailResource,
    OccupationSkill,
    OccupationSkillImportanceType,
} from './OccupationDetail';

export const transformToOccupationDetail = (
    occupationDetailResource: OccupationDetailResource,
): OccupationDetail => {
    const essentialSkills = occupationDetailResource.skills.filter(skill => skill.importanceType === OccupationSkillImportanceType.essential);
    const optionalSkills = occupationDetailResource.skills.filter(skill => skill.importanceType === OccupationSkillImportanceType.optional);

    return ({
        id: occupationDetailResource.id,
        name: capitalizeFirstCharacter(occupationDetailResource.name),
        alternativeNames: occupationDetailResource.alternativeNames
            ? occupationDetailResource.alternativeNames
            : [],
        description: occupationDetailResource.description,
        totalSkills: occupationDetailResource.totalSkillCount,
        matchedSkills: occupationDetailResource.matchedSkillCount,
        matchedPercentage: occupationDetailResource.matchedPercentage,
        essentialSkills,
        optionalSkills,
    });
};

export const transformOccupationSkillToFormOptions = (
    skill: OccupationSkill,
): FormOption => ({
    value: skill.id,
    label: capitalizeFirstCharacter(skill.label),
});
