import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Competency } from './Competencies';
import { transformToCompetency } from './CompetenciesTransformer';

export const getCompetenciesApiCall = async (): Promise<FetchResult<Competency[], string>> => {
    try {
        const response = await lloFetch('/api/tmaCompetence');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json();

        const competencies = data.map(transformToCompetency);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: competencies,
        };
    } catch (error) {
        console.error('[getCompetenciesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
