import { FC, ReactElement } from 'react';

import './InputLabel.scss';

interface InputLabelProps {
    text: string;
    required?: boolean;
    className?: string;
}

const InputLabel: FC<InputLabelProps> = ({ text, required = false, className = '' }): ReactElement => (
    <div className={`input-label ${className}`}>
        {text}
        {required && <span className="input-label__required-star"> *</span>}
    </div>
);

export default InputLabel;
