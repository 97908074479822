import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon } from '../../../../components';
import { NavigationItemProps } from '../../../../Routes';

import './NavigationItem.scss';

const NavigationItem: FC<NavigationItemProps> = ({
    label,
    icon,
    ...navigationItemProps
}): ReactElement => {
    const getNavLinkClassName = (props: { isActive: boolean }) => classNames('navigation-item__link', {
        'navigation-item__link--is-active': props.isActive,
    });

    return (
        <li className="navigation-item">
            <NavLink
                {...navigationItemProps}
                className={getNavLinkClassName}
            >
                {icon && <Icon name={icon} className="navigation-item__link-icon" />}
                {label}
            </NavLink>
        </li>
    );
};

export default NavigationItem;
