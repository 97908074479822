import { FC, ReactElement, useEffect } from 'react';

import { SkillsCard } from '../../../containers';
import { userHasRole } from '../../../helpers/role';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { SkillsFormData } from '../../../models/Skills/Skills';
import { setSuggestedSkills } from '../../../redux/escoOccupations/escoOccupations';
import { fetchEscoOccupations, fetchSuggestedSkills } from '../../../redux/escoOccupations/escoOccupationsActions';
import { fetchSkills, updateCandidateSkills } from '../../../redux/skills/skillsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { UserRole } from '../../../types';

interface ConnectedSkillsCardProps {
    className?: string;
}

const ConnectedSkillsCard: FC<ConnectedSkillsCardProps> = ({ className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skillsPassportError = useTypedSelector(state => state.skillsPassportReducer.error);
    const skills = useTypedSelector(state => state.skillsPassportReducer.skills);

    const skillsIsUpdating = useTypedSelector(state => state.skillsReducer.isUpdating);
    const skillOptions = useTypedSelector(state => state.skillsReducer.skills);
    const skillOptionsError = useTypedSelector(state => state.skillsReducer.error);

    const escoOccupations = useTypedSelector(state => state.escoOccupationsReducer.escoOccupations);
    const escoOccupationsIsLoading = useTypedSelector(state => state.escoOccupationsReducer.isLoading);
    const escoOccupationsError = useTypedSelector(state => state.escoOccupationsReducer.error);
    const suggestedSkills = useTypedSelector(state => state.escoOccupationsReducer.suggestedSkills);

    useEffect((): void => {
        if (!userHasRole(UserRole.candidate, user?.roles)) return;

        if (skillOptions.length === 0) dispatch(fetchSkills());
        if (escoOccupations.length === 0) dispatch(fetchEscoOccupations());
    }, [user]);

    const handleSelectOccupation = (occupation: EscoOccupation): void => {
        dispatch(fetchSuggestedSkills(occupation.id));
    };

    const handleUpdateSkills = (skillsFormData: SkillsFormData): void => {
        if (candidate?.uuid) {
            dispatch(updateCandidateSkills(skillsFormData, candidate.uuid));
        }
    };

    const handleCloseSkillsDialog = (): void => {
        dispatch(setSuggestedSkills([]));
    };

    return (
        <SkillsCard
            isLoading={skillsPassportIsLoading || skillsIsUpdating}
            suggestionIsLoading={escoOccupationsIsLoading}
            skillsIsUpdating={skillsIsUpdating}
            isEditable={!skillsPassportError && userHasRole(UserRole.candidate, user?.roles)}
            hasError={!!skillsPassportError}
            userRoles={user?.roles}
            skills={skills}
            suggestedSkills={suggestedSkills}
            skillOptions={skillOptions}
            skillsError={skillOptionsError || escoOccupationsError}
            occupationOptions={escoOccupations}
            onSelectOccupation={handleSelectOccupation}
            onUpdateSkills={handleUpdateSkills}
            onCloseSkillsDialog={handleCloseSkillsDialog}
            className={className}
        />
    );
};

export default ConnectedSkillsCard;
