/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconMessage: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M90,0H10C7.3,0,4.8,1.1,2.9,2.9C1.1,4.8,0,7.3,0,10v90l20-20h70c2.7,0,5.2-1.1,7.1-2.9c1.9-1.9,2.9-4.4,2.9-7.1V10C100,4.4,95.5,0,90,0z" />
    </svg>
);

export default IconMessage;
/* eslint-enable max-len */
