import { FC, ReactElement, useMemo } from 'react';

import { useToggle } from 'react-use';

import { IconButton, TagList } from '..';

import './ExpandableTagList.scss';

interface ExpandableTagListProps {
    listItems: string[];
    listItemsThreshold: number;
    expandedButtonLabel: string;
    buttonLabel: string;
    className?: string;
}

const ExpandableTagList: FC<ExpandableTagListProps> = ({
    listItems = [],
    listItemsThreshold,
    expandedButtonLabel,
    buttonLabel,
    className = '',
}): ReactElement => {
    const [isExpanded, toggleIsExpanded] = useToggle(false);

    const slicedListItems = useMemo(() => {
        const slicedList = listItems.slice(0, listItemsThreshold);

        return isExpanded ? listItems : slicedList;
    }, [listItems, listItemsThreshold, isExpanded]);

    const thresholdOverflow = listItems.length - listItemsThreshold;
    const exceedsThreshold = thresholdOverflow > 0;

    return (
        <div className={`expandable-tag-list ${className}`}>
            {(slicedListItems.length > 0) && (
                <TagList tags={slicedListItems} />
            )}

            {exceedsThreshold && (
                <div className="expandable-tag-list__button-wrapper">
                    {!isExpanded && (
                        <div className="expandable-tag-list__overflow-indicator">
                            {thresholdOverflow}
                        </div>
                    )}

                    <IconButton
                        icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                        iconPos="right"
                        text={isExpanded ? expandedButtonLabel : buttonLabel}
                        onClick={toggleIsExpanded}
                        className="expandable-tag-list__expand-button"
                    />
                </div>
            )}
        </div>
    );
};

export default ExpandableTagList;
