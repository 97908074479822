/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgLogoProps } from '../Logo';

import '../Logo.scss';

const LogoTMA: FC<SvgLogoProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 1000 367.4" className={`logo logo-tma ${className}`}>
        <g>
            <circle className="logo-tma__lime" cx="158.2" cy="18.4" r="18.4" />
            <circle className="logo-tma__orange" cx="96.4" cy="125.4" r="21.5" />
            <circle className="logo-tma__yellow" cx="33.7" cy="233" r="33.7" />
            <circle className="logo-tma__pink" cx="158.3" cy="233" r="24.4" />
            <circle className="logo-tma__blue" cx="97.5" cy="340.3" r="27.1" />
            <circle className="logo-tma__green" cx="969.2" cy="125.6" r="30.8" />
        </g>
        <g>
            <path className="logo-tma__grey" d="M355.2,252.2c-3.8,0-7,2.9-7.3,6.7c-1.4,16.9-8.4,27.9-25.5,27.9c-19.3,0-24.8-13.9-24.8-34.6v-66.8c0-4.1,3.3-7.3,7.3-7.3h70.7c4.1,0,7.3-3.3,7.3-7.3V135c0-4.1-3.3-7.3-7.3-7.3h-70.7c-4.1,0-7.3-3.3-7.3-7.3V57c0-4.1-3.3-7.3-7.3-7.3h-40.5c-4.1,0-7.3,3.3-7.3,7.3v63.3c0,4.1-3.3,7.3-7.3,7.3h-38.2c-4.1,0-7.3,3.3-7.3,7.3v35.7c0,4.1,3.3,7.3,7.3,7.3H235c4.1,0,7.3,3.3,7.3,7.3v66.8v11.5c0,35.8,29.3,78.6,80,78.6s81-42.9,81-78.6v-4.2c0-4.1-3.3-7.3-7.3-7.3H355.2z" />
            <path className="logo-tma__grey" d="M703.5,200.1v130.5c0,4.1-3.3,7.3-7.3,7.3h-40.5c-4.1,0-7.3-3.3-7.3-7.3V199.7l0,0c0-5.9-6.9-25.8-28.3-25.8c-21.4,0-28.4,19.9-28.4,25.8v130.8c0,4.1-3.3,7.3-7.3,7.3h-40.5c-4.1,0-7.3-3.3-7.3-7.3V199.7c0-5.9-6.9-25.8-28.3-25.8c-21.4,0-28.4,19.9-28.4,25.8v130.8c0,4.1-3.3,7.3-7.3,7.3h-40.5c-4.1,0-7.3-3.3-7.3-7.3V135c0-4.1,3.3-7.3,7.3-7.3h23.4c7.9,0,10.1,10.8,18.2,10.8c8.4,0,20-15.4,46.2-15.4c27,0,43.6,18.5,49,18.5c14.4,0,22.3-18.5,56.5-18.5C670.8,123,703.5,158,703.5,200.1z" />
            <path className="logo-tma__grey" d="M905.3,126.4h-24.6c-2.5,0-7.2,12.1-11.3,12.1c-8.7,0-12.7-15.5-55.6-15.5c-42.9,0-88.5,22.1-88.5,111.7s54.1,108.3,88.5,108.3s50.2-17.3,55.6-17.3s7.5,12.2,11,12.2h24.9c4.1,0,7.3-3.3,7.3-7.3V133.8C912.7,129.7,909.4,126.4,905.3,126.4z M852.8,233c0-0.1,0-0.2,0-0.3c-0.1,57-26.2,57-36.6,57c-9.6,0-35.8,0-35.9-57c0,0.1,0,0.2,0,0.3v-0.5c0,0.1,0,0.2,0,0.3c0.1-59,26.3-59,35.9-59c10.3,0,36.5,0,36.6,59c0-0.1,0-0.2,0-0.3V233z" />
        </g>
    </svg>
);

export default LogoTMA;
/* eslint-enable max-len */
