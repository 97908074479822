import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './DevelopmentPlanNotesSkeletons.scss';

interface DevelopmentPlanNotesSkeletonsProps {
    amount?: number;
    className?: string;
}

const DevelopmentPlanNotesSkeletons: FC<DevelopmentPlanNotesSkeletonsProps> = ({
    amount = 2,
    className = '',
}): ReactElement => {
    const noteSkeletons = generateIdArray(amount);

    return (
        <section className={`development-plan-notes-skeletons ${className}`}>
            <div className="development-plan-notes-skeletons__action-buttons">
                <Skeleton className="development-plan-notes-skeletons__add-note-button" />
            </div>

            <ul className="development-plan-notes-skeletons__list">
                {noteSkeletons.map(note => {
                    const noteContentAmount = randomInBetweenValue(1, 3);
                    const noteContentSkeletons = generateIdArray(noteContentAmount);

                    return (
                        <Card key={note} className="development-plan-notes-skeletons__list-item">
                            <div className="development-plan-notes-skeletons__note-content-wrapper">
                                {noteContentSkeletons.map(skeleton => (
                                    <Skeleton key={skeleton} className="development-plan-notes-skeletons__note-content" />
                                ))}

                                <Skeleton className="development-plan-notes-skeletons__updated-at" />
                            </div>

                            <div className="development-plan-notes-skeletons__controls-wrapper">
                                <div className="development-plan-notes-skeletons__control-button" />
                                <div className="development-plan-notes-skeletons__control-button" />
                            </div>
                        </Card>
                    );
                })}
            </ul>
        </section>
    );
};

export default DevelopmentPlanNotesSkeletons;
