/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconSettings: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,67.5c-4.6,0-9.1-1.8-12.4-5.1c-3.3-3.3-5.1-7.7-5.1-12.4c0-4.6,1.8-9.1,5.1-12.4c3.3-3.3,7.7-5.1,12.4-5.1c4.6,0,9.1,1.8,12.4,5.1c3.3,3.3,5.1,7.7,5.1,12.4c0,4.6-1.8,9.1-5.1,12.4C59.1,65.7,54.7,67.5,50,67.5z M87.2,54.9c0.2-1.6,0.4-3.2,0.4-4.9c0-1.6-0.2-3.3-0.4-5l10.6-8.1c1-0.8,1.2-2.1,0.6-3.2l-10-17.3c-0.6-1.1-1.9-1.6-3-1.1l-12.4,5c-2.6-1.9-5.3-3.6-8.5-4.9L62.5,2.1C62.3,0.9,61.3,0,60,0H40c-1.2,0-2.3,0.9-2.5,2.1l-1.8,13.2c-3.1,1.2-5.8,3-8.4,4.9l-12.4-5c-1.1-0.5-2.5,0-3.1,1.1l-10,17.3c-0.6,1.1-0.3,2.4,0.6,3.2L12.9,45c-0.2,1.7-0.4,3.4-0.4,5c0,1.6,0.2,3.2,0.4,4.9L2.3,63.1c-0.9,0.8-1.2,2.1-0.6,3.2l10,17.3c0.6,1.1,2,1.5,3.1,1.1l12.4-5c2.6,2,5.3,3.7,8.4,4.9l1.8,13.3c0.2,1.2,1.3,2.1,2.5,2.1h20c1.3,0,2.3-0.9,2.5-2.1l1.8-13.3c3.1-1.3,5.8-2.9,8.5-4.9l12.4,5c1.1,0.4,2.5,0,3-1.1l10-17.3c0.6-1.1,0.4-2.4-0.6-3.2L87.2,54.9z" />
    </svg>
);

export default IconSettings;
/* eslint-enable max-len */
