import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './OccupationDetailHeaderSkeletons.scss';

interface OccupationDetailHeaderSkeletonsProps {
    className?: string;
}

const OccupationDetailHeaderSkeletons: FC<OccupationDetailHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const descriptionLineAmount = randomInBetweenValue(1, 2);
    const descriptionLineSkeletons = generateIdArray(descriptionLineAmount);

    const alternativeNamesAmount = randomInBetweenValue(3, 6);
    const alternativeNamesSkeletons = generateIdArray(alternativeNamesAmount);

    return (
        <Card className={`occupation-detail-header-skeletons ${className}`}>
            <div className="occupation-detail-header-skeletons__text">
                <Skeleton className="occupation-detail-header-skeletons__name" />

                {descriptionLineSkeletons.map(descriptionLine => (
                    <Skeleton key={descriptionLine} className="occupation-detail-header-skeletons__description" />
                ))}

                <Skeleton className="occupation-detail-header-skeletons__alternative-names-label" />

                <ul className="occupation-detail-header-skeletons__list">
                    {alternativeNamesSkeletons.map(alternativeName => (
                        <li key={alternativeName} className="occupation-detail-header-skeletons__list-item" />
                    ))}
                </ul>
            </div>

            <div className="occupation-detail-header-skeletons__matched-skills">
                <div className="occupation-detail-header-skeletons__progress-circle" />
                <Skeleton className="occupation-detail-header-skeletons__progress-description" />
                <Skeleton className="occupation-detail-header-skeletons__create-development-plan" />
            </div>
        </Card>
    );
};

export default OccupationDetailHeaderSkeletons;
