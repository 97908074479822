import { shuffleArray } from '../../../../helpers/array';
import { Competency } from '../../../../models/Competencies/Competencies';

export const searchCompetencyOptionsOnQuery = (options: Competency[], query: string): Competency[] => {
    try {
        const searchQuery = new RegExp(query, 'i');

        return options.filter(item => item.label.match(searchQuery));
    } catch {
        return [];
    }
};

export const sortCompetencyOptions = (options: Competency[]): Competency[] => (
    options.sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
);

export const getRandomExampleOptions = (options: Competency[], amount = 2): string => {
    const maxLength = 14;
    const shortOptions = options.filter(option => option.label.length <= maxLength);
    const shuffledOptions = shuffleArray<Competency>(shortOptions);

    return shuffledOptions
        .slice(0, amount)
        .map(option => option.label)
        .join(', ');
};
