/* eslint-disable arrow-body-style */
import {
    isAfter,
    isBefore,
    isEqual,
    isPast,
    isValid,
} from 'date-fns';

export const stringContainsValue = (string?: string): boolean => {
    if (!string) {
        return false;
    }

    return string.trim().length > 0;
};

export const stringEqualsLength = (string: string, exactLength: number): boolean => {
    return string.length === exactLength;
};

export const stringHasMinimumLength = (string: string, minLength: number): boolean => {
    return string.length >= minLength;
};

export const stringDoesNotExceedLength = (string: string, maxLength: number): boolean => {
    return string.length <= maxLength;
};

export const stringMatchesRegEx = (string: string, regEx: RegExp, flag?: string): boolean => {
    const regExp = new RegExp(regEx, flag);

    return regExp.test(string);
};

export const arrayContainsValue = <T>(array?: T[]): boolean => {
    if (!array) {
        return false;
    }

    return array
        .filter(item => !([null, undefined] as unknown as T[]).includes(item))
        .length > 0;
};

export const arrayHasMinimumLength = <T>(array: T[], minimumLength: number): boolean => {
    return array.length >= minimumLength;
};

export const dateIsValid = (date: Date): boolean => {
    return isValid(date);
};

export const dateHasOccurred = (date: Date): boolean => {
    return isPast(date);
};

export const dateEqualsComparison = (date: Date, comparisonDate: Date): boolean => {
    return isEqual(date, comparisonDate);
};

export const dateIsEqualOrBeforeComparison = (date: Date, comparisonDate: Date): boolean => {
    return isEqual(date, comparisonDate) || isBefore(date, comparisonDate);
};

export const dateIsEqualOrAfterComparison = (date: Date, comparisonDate: Date): boolean => {
    return isEqual(date, comparisonDate) || isAfter(date, comparisonDate);
};

/* eslint-enable arrow-body-style */
