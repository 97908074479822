import { FC, ReactElement, useEffect } from 'react';

import { OrganisationOverview } from '../../../containers';
import { scrollToTop } from '../../../helpers/scroll';
import useUnmount from '../../../hooks/useUnmount';
import { OrganisationFormData } from '../../../models/Organisation/Organisation';
import { setOrganisation } from '../../../redux/organisation/organisation';
import { createOrganisation, deleteOrganisation, updateOrganisation } from '../../../redux/organisation/organisationActions';
import { setActiveFilterValues } from '../../../redux/organisationOverview/organisationOverview';
import { fetchOrganisationOverview } from '../../../redux/organisationOverview/organisationOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrganisationOverviewProps {
    className?: string;
}

const ConnectedOrganisationOverview: FC<ConnectedOrganisationOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const organisationOverviewIsLoading = useTypedSelector(state => state.organisationOverviewReducer.isLoading);
    const organisationOverviewError = useTypedSelector(state => state.organisationOverviewReducer.error);
    const organisationOverview = useTypedSelector(state => state.organisationOverviewReducer.organisationOverview);
    const activeFilterValues = useTypedSelector(state => state.organisationOverviewReducer.activeFilterValues);
    const pagination = useTypedSelector(state => state.organisationOverviewReducer.pagination);

    const organisationIsUpdating = useTypedSelector(state => state.organisationReducer.isUpdating);
    const organisationIsSuccessful = useTypedSelector(state => state.organisationReducer.isSuccessful);
    const organisationError = useTypedSelector(state => state.organisationReducer.error);

    const handleAddOrganisationSubmit = (formData: OrganisationFormData): void => {
        dispatch(createOrganisation(formData));
    };

    const handleEditOrganisationSubmit = (formData: OrganisationFormData): void => {
        dispatch(updateOrganisation(formData));
    };

    const handleDeleteOrganisationSubmit = (organisationId: string): void => {
        dispatch(deleteOrganisation(organisationId));
    };

    const handlePaginationChange = (pageNumber: number): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber,
        };

        dispatch(setActiveFilterValues(newFilterValues));

        scrollToTop();
    };

    useUnmount((): void => {
        dispatch(setOrganisation(undefined));
    });

    useEffect((): void => {
        dispatch(fetchOrganisationOverview());
    }, [activeFilterValues]);

    useEffect((): void => {
        if (organisationIsSuccessful) dispatch(fetchOrganisationOverview());
    }, [organisationIsSuccessful]);

    return (
        <OrganisationOverview
            organisationOverviewIsLoading={organisationOverviewIsLoading}
            organisationIsUpdating={organisationIsUpdating}
            organisationIsSuccessful={organisationIsSuccessful}
            organisationOverviewError={organisationOverviewError}
            organisationError={organisationError}
            totalAmount={organisationOverview.totalAmount}
            organisations={organisationOverview.organisations}
            pagination={pagination}
            onAddOrganisationSubmit={handleAddOrganisationSubmit}
            onEditOrganisationSubmit={handleEditOrganisationSubmit}
            onDeleteOrganisationSubmit={handleDeleteOrganisationSubmit}
            onPaginationChange={handlePaginationChange}
            className={className}
        />
    );
};

export default ConnectedOrganisationOverview;
