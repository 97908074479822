import { FC, ReactElement, RefAttributes } from 'react';

import { Checkbox } from '@createnl/grouped-checkboxes';
import classNames from 'classnames';

import { FormOption } from '../../../types';

import './Checkbox.scss';

export type HTMLInputProps = JSX.IntrinsicElements['input'] & RefAttributes<HTMLInputElement>;

interface GroupedCheckboxProps extends HTMLInputProps {
    option: FormOption;
    name: string;
    checked?: boolean;
    className?: string;
}

const GroupedCheckbox: FC<GroupedCheckboxProps> = ({
    option,
    name,
    checked = false,
    className = '',
    ...inputProps
}): ReactElement => {
    const checkboxClassNames = classNames('checkbox', {
        'checkbox--is-checked': checked,
    }, className);

    return (
        <label className={checkboxClassNames}>
            <Checkbox
                {...inputProps}
                id={option.label}
                name={name}
                value={option.value}
                checked={checked}
                className="checkbox__input"
            />

            <div className="checkbox__box" />

            <span className="checkbox__label">
                {option.label}
            </span>
        </label>
    );
};

export default GroupedCheckbox;
