import { Action } from 'redux';

import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TmaAnalysisSettingsFormData } from '../../models/TmaAnalysis/TmaAnalysis';
import { getTmaAnalysisSettingsApiCall, postTmaAnalysisSettingsApiCall } from '../../models/TmaAnalysis/TmaAnalysisService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setTmaAnalysisSettings,
} from './tmaAnalysisSettings';

export const fetchTmaAnalysisSettings = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getTmaAnalysisSettingsApiCall(candidateUuid);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        dispatch(setTmaAnalysisSettings(response.data));
    } catch (error) {
        console.error('[fetchTmaAnalysisSettings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const postTmaAnalysisSettings = (candidateUuid: string, tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await postTmaAnalysisSettingsApiCall(candidateUuid, tmaAnalysisSettingsFormData);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        dispatch(setTmaAnalysisSettings(response.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[postTmaAnalysisSettings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateTmaAnalysisSettingsSuccess = (isSuccessful: boolean) => (dispatch: TypedDispatch): Action => (
    dispatch(setIsSuccessful(isSuccessful))
);
