import { FC, PropsWithChildren, ReactElement } from 'react';

import './Table.scss';

interface TableProps {
    className?: string;
}

const Table: FC<PropsWithChildren<TableProps>> = ({ className = '', children }): ReactElement => (
    <table className={`table ${className}`}>
        {children}
    </table>
);

export default Table;
