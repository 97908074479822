import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Card,
    LoadingSpinner,
    TableCell,
    TableRow,
} from '../../../components';
import {
    Dialog,
    DialogContent,
    IconButton,
    InviteSuccessMessage,
    NonSortTable,
    Notice,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { generateFullName } from '../../../helpers/user';
import { Counselor, CounselorFormData } from '../../../models/Counselor/Counselor';
import { NoticeType, UserRole } from '../../../types';
import { CounselorForm } from '../..';

import './CounselorTable.scss';

interface CounselorTableProps {
    counselorOverviewIsLoading: boolean;
    counselorFormIsLoading: boolean;
    counselorFormIsSuccessful: boolean;
    counselorOverviewError: string;
    counselorFormError: string;
    counselors: Counselor[];
    totalAmount: number;
    userRoles?: UserRole[];
    onAddCounselor: (formData: CounselorFormData) => void;
    onEditCounselor: (formData: CounselorFormData) => void;
    onCloseInviteCounselorSuccessMessage: () => void;
    className?: string;
}

const CounselorTable: FC<CounselorTableProps> = ({
    counselorOverviewIsLoading,
    counselorFormIsLoading,
    counselorFormIsSuccessful,
    counselorOverviewError,
    counselorFormError,
    counselors,
    totalAmount,
    userRoles,
    onAddCounselor,
    onEditCounselor,
    onCloseInviteCounselorSuccessMessage,
    className = '',
}): ReactElement => {
    const addDialogRef = useRef<HTMLDialogElement>(null);
    const editDialogRef = useRef<HTMLDialogElement>(null);

    const [selectedCounselor, setSelectedCounselor] = useState<Counselor>();
    const [newCounselorFirstName, setNewCounselorFirstName] = useState<string>('');

    const [counselorFormKey, setCounselorFormKey] = useState<number>(0);

    const handleOpenAddDialog = (): void => openDialog(addDialogRef);
    const handleCloseAddDialog = (): void => closeDialog(addDialogRef);

    const handleOpenEditDialog = (): void => openDialog(editDialogRef);
    const handleCloseEditDialog = (): void => {
        closeDialog(editDialogRef);
        setSelectedCounselor(undefined);

        setCounselorFormKey(counselorFormKey + 1);
    };

    const handleAddCounselor = (formData: CounselorFormData): void => {
        onAddCounselor(formData);

        setNewCounselorFirstName(formData.firstName);
    };

    const handleCloseInviteSuccessMessage = (): void => {
        handleCloseAddDialog();
        onCloseInviteCounselorSuccessMessage();
        setNewCounselorFirstName('');
    };

    const tableHeaders: string[] = [
        trans('tables.counselor.labels.name'),
        trans('tables.counselor.labels.email'),
        trans('tables.counselor.labels.role'),
    ];

    useEffect((): void => {
        if (counselorFormIsSuccessful) {
            handleCloseEditDialog();
        }
    }, [counselorFormIsSuccessful]);

    return (
        <Card className={`counselor-table ${className}`}>
            <header className="counselor-table__header">
                <h1 className="counselor-table__title">
                    {trans('tables.counselor.title', {
                        totalAmount: String(totalAmount),
                    })}
                </h1>

                {counselorOverviewIsLoading && <LoadingSpinner className="counselor-table__loader" />}
            </header>

            {!counselorOverviewIsLoading && !counselorOverviewError && counselors.length === 0 && (
                <div className="counselor-table__null-state-wrapper">
                    <p>{trans('tables.counselor.nullState.description')}</p>

                    <IconButton
                        icon="plus"
                        text={trans('tables.counselor.nullState.buttonLabel')}
                        onClick={handleOpenAddDialog}
                        className="counselor-table__null-state-button"
                    />
                </div>
            )}

            {counselorOverviewError && counselors.length === 0 && (
                <div className="counselor-table__error-wrapper">
                    <Notice
                        type={NoticeType.error}
                        text={counselorOverviewError}
                        className="counselor-table__error-message"
                    />
                </div>
            )}

            {counselors.length > 0 && (
                <NonSortTable
                    hasStickyColumn
                    tableHeaders={tableHeaders}
                    rows={counselors}
                    headerRowClassName="counselor-table__header-row"
                    cellClassName="counselor-table__cell"
                    className="counselor-table__table"
                >
                    {(rows): JSX.Element[] => rows.map(counselor => {
                        const handleEditCounselorClick = (): void => {
                            handleOpenEditDialog();
                            setSelectedCounselor(counselor);
                        };

                        return (
                            <TableRow key={counselor.id} className="counselor-table__row">
                                <TableCell className="counselor-table__cell counselor-table__cell--is-name">
                                    {generateFullName(counselor)}
                                </TableCell>
                                <TableCell className="counselor-table__cell">
                                    {counselor.email}
                                </TableCell>
                                <TableCell className="counselor-table__cell">
                                    {counselor.roles.map(role => trans(`roles.${role}`)).join(', ')}
                                </TableCell>
                                <TableCell contentClassName="counselor-table__fixed-cell-content" className="counselor-table__fixed-cell">
                                    <IconButton
                                        icon="edit"
                                        text={trans('tables.counselor.editButtonLabel')}
                                        hideLabel
                                        onClick={handleEditCounselorClick}
                                        className="counselor-table__action-button"
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </NonSortTable>
            )}

            <Dialog ref={addDialogRef} onClose={handleCloseAddDialog}>
                {counselorFormIsSuccessful ? (
                    <InviteSuccessMessage
                        firstName={newCounselorFirstName}
                        onConfirm={handleCloseInviteSuccessMessage}
                    />
                ) : (
                    <DialogContent title={trans('tables.counselor.addDialog.title')}>
                        <CounselorForm
                            key={counselorFormKey}
                            isLoading={counselorFormIsLoading}
                            error={counselorFormError}
                            userRoles={userRoles}
                            onSubmit={handleAddCounselor}
                            onCancel={handleCloseAddDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>

            <Dialog ref={editDialogRef} onClose={handleCloseEditDialog}>
                <DialogContent title={trans('tables.counselor.editDialog.title')}>
                    <CounselorForm
                        key={counselorFormKey}
                        isLoading={counselorFormIsLoading}
                        error={counselorFormError}
                        counselor={selectedCounselor}
                        userRoles={userRoles}
                        onSubmit={onEditCounselor}
                        onCancel={handleCloseEditDialog}
                    />
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default CounselorTable;
