import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Avatar } from '../../../components';
import { formatAgeInDays } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { Vacancy } from '../../../models/Vacancy';
import { RoutePaths } from '../../../Routes';
import { FloatingCard } from '../..';
import { VacancyPropertyTags } from './subcomponents';

import './VacancyCard.scss';

interface VacancyCardProps {
    candidate?: Candidate;
    vacancy: Vacancy;
    className?: string;
}

const VacancyCard: FC<VacancyCardProps> = ({
    candidate,
    vacancy,
    className = '',
}): ReactElement => (
    <FloatingCard
        className={`vacancy-card ${className}`}
        cardClassName="vacancy-card__card"
    >
        <header className="vacancy-card__header">
            <Avatar
                src={vacancy.logo}
                alt={trans('compositions.vacancyCard.logoAlt', { company: vacancy.companyName })}
                fallbackString={vacancy.companyName}
                className="vacancy-card__logo"
            />

            <div className="vacancy-card__title-wrapper">
                <Link
                    to={RoutePaths.vacancyDetail(vacancy.uuid, candidate?.uuid || '')}
                    className="vacancy-card__link"
                >
                    <h2 className="vacancy-card__title">
                        {vacancy.title}
                    </h2>
                </Link>

                <p className="vacancy-card__company">
                    {trans('compositions.vacancyCard.atCompany', {
                        company: vacancy.companyName,
                        city: vacancy.city,
                    })}
                </p>

                <p className="vacancy-card__publish-date">
                    {formatAgeInDays(vacancy.publicationDate)}
                </p>
            </div>
        </header>

        <VacancyPropertyTags
            isAnonymous={vacancy.isAnonymous}
            vacancyUuid={vacancy.uuid}
            properties={vacancy.properties}
            className="vacancy-card__property-list"
        />
    </FloatingCard>
);

export default VacancyCard;
