import { FC, PropsWithChildren, ReactElement } from 'react';

import { Card, SafeHtml } from '../../../components';
import trans from '../../../helpers/trans';
import { Gender } from '../../../types';
import {
    CandidateIllustration,
    IconButton,
    Loading,
    SubmitButton,
} from '../..';
import { CandidateIllustrationNotification } from '../../CandidateIllustration/CandidateIllustration';

import './SkillsPassportCard.scss';

interface SkillsPassportProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    editButtonDisabled?: boolean;
    showNullState: boolean;
    showCallToActionButton?: boolean;
    candidateGender?: Gender;
    nullStateText: string;
    callToActionButtonLabel?: string;
    title: string;
    description: string;
    extraDescription?: string;
    candidateIllustrationType?: CandidateIllustrationNotification;
    onEditClick?: () => void;
    callToActionButtonClick?: () => void;
    onRefreshClick: () => void;
    className?: string;
}

const SkillsPassportCard: FC<PropsWithChildren<SkillsPassportProps>> = ({
    isLoading,
    isUpToDate,
    showNullState,
    showCallToActionButton = false,
    candidateGender,
    nullStateText,
    callToActionButtonLabel,
    title,
    description,
    extraDescription,
    candidateIllustrationType,
    callToActionButtonClick,
    onRefreshClick,
    children,
    className = '',
}): ReactElement => {
    const buttonText = isUpToDate
        ? trans('containers.skillsPassportCard.buttonTextSuccessful')
        : trans('containers.skillsPassportCard.refreshTmaAnalysisData');

    return (
        <Card className={`skills-passport-card ${className}`}>
            <header className="skills-passport-card__header">
                <div className="skills-passport-card__title-and-description">
                    <h1 className="skills-passport-card__title">{title}</h1>

                    <p className="skills-passport-card__description">{description}</p>

                    {extraDescription && (
                        <SafeHtml
                            html={extraDescription}
                            className="skills-passport-card__extra-description"
                        />
                    )}
                </div>

                {!!candidateIllustrationType && candidateGender && (
                    <CandidateIllustration
                        type={candidateIllustrationType}
                        gender={candidateGender}
                        className="skills-passport-card__candidate-illustration"
                    />
                )}
            </header>

            {isLoading && <Loading className="skills-passport-card__loader" />}

            {(!isLoading && showNullState) && (
                <div className="skills-passport-card__null-state">
                    <p className="skills-passport-card__null-state-text">
                        {nullStateText}
                    </p>

                    {(showCallToActionButton && callToActionButtonLabel) && (
                        <>
                            <SubmitButton
                                isLoading={isLoading}
                                icon={isUpToDate ? 'check' : 'refresh'}
                                text={buttonText}
                                disabled={isUpToDate}
                                onClick={onRefreshClick}
                                className="skills-passport-card__refresh-button"
                            />
                            <IconButton
                                icon="arrow-right"
                                iconPos="right"
                                text={callToActionButtonLabel}
                                onClick={callToActionButtonClick}
                                className="skills-passport-card__call-to-action-button"
                            />
                        </>
                    )}
                </div>
            )}

            {children}
        </Card>
    );
};

export default SkillsPassportCard;
