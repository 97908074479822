import { FC, ReactElement } from 'react';

import { ManageAnalyses } from '../../containers';
import { useTypedSelector } from '../../redux/store';

const ConnectedManageAnalyses: FC = (): ReactElement => {
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);

    return (
        <ManageAnalyses
            isLoading={candidateIsLoading}
            candidate={candidate}
        />
    );
};

export default ConnectedManageAnalyses;
