import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { validate as validateUuid } from 'uuid';

import { VacancyDetail } from '../../../containers';
import { Candidate } from '../../../models/Candidate/Candidate';
import { Vacancy } from '../../../models/Vacancy';
import { fetchSkills } from '../../../redux/skills/skillsActions';
import { fetchSkillsPassport } from '../../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { fetchJobdiggerVacancy, fetchVacancy } from '../../../redux/vacancy/vacancyActions';

interface ConnectedVacancyDetailProps {
    onCandidateResponse?: (candidate: Candidate) => void;
    onVacancyResponse?: (vacancy: Vacancy) => void;
    className?: string;
}

const ConnectedVacancyDetail: FC<ConnectedVacancyDetailProps> = ({
    onCandidateResponse,
    onVacancyResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { vacancyUuid, candidateUuid } = useParams();

    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = useTypedSelector(state => state.vacancyReducer.isLoading);
    const vacancy = useTypedSelector(state => state.vacancyReducer.vacancy);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const candidateSkills = useTypedSelector(state => state.skillsPassportReducer.skills);
    const candidateCompetencies = useTypedSelector(state => state.skillsPassportReducer.competencies);

    const skills = useTypedSelector(state => state.skillsReducer.skills);

    useEffect((): void => {
        if (!vacancyUuid || !candidateUuid) return;

        if (validateUuid(vacancyUuid)) {
            dispatch(fetchVacancy(vacancyUuid));
        } else {
            dispatch(fetchJobdiggerVacancy(vacancyUuid, candidateUuid));
        }
    }, [dispatch, vacancyUuid]);

    useEffect((): void => {
        if (candidate?.uuid) {
            dispatch(fetchSkillsPassport(candidate.uuid));
        }
    }, [dispatch, candidate]);

    useEffect((): void => {
        if (onVacancyResponse && vacancy) {
            onVacancyResponse(vacancy);
        }
    }, [onVacancyResponse, vacancy]);

    useEffect((): void => {
        if (onCandidateResponse && candidate) {
            onCandidateResponse(candidate);
        }
    }, [onCandidateResponse, candidate]);

    useEffectOnce((): void => {
        if (skills.length === 0) dispatch(fetchSkills());
    });

    return (
        <VacancyDetail
            isLoading={isLoading}
            userRoles={user?.roles}
            vacancy={vacancy}
            skills={skills}
            candidateCompetencies={candidateCompetencies ? Object.entries(candidateCompetencies).map(([, competency]) => competency).flat() : []}
            candidateSkills={candidateSkills}
            className={className}
        />
    );
};

export default ConnectedVacancyDetail;
