import { CandidateInviteFormData } from '../../../../models/Candidate/Candidate';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateName,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type CandidateInviteFormErrors = FormErrors<CandidateInviteFormData>;

export const validateCandidateInviteFormData = (formData: CandidateInviteFormData): FormValidation<CandidateInviteFormErrors> => {
    const errors: CandidateInviteFormErrors = {
        firstName: validateName('firstName', formData.firstName),
        lastName: validateName('lastName', formData.lastName),
        gender: validateRequiredString('gender', formData.gender),
        email: validateEmailAddress(formData.email),
    };

    return validateForm<CandidateInviteFormErrors>(errors);
};
