import { FC, ReactElement } from 'react';

import { VacancyCard } from '../../../compositions';
import { Candidate } from '../../../models/Candidate/Candidate';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { Vacancy } from '../../../models/Vacancy';
import { Pagination } from '../..';
import { VacancyOverviewSkeletons } from './skeletons';
import { VacancyOverviewNullState } from './subcomponents';

import './VacancyOverview.scss';

export interface VacancyOverviewProps {
    isLoading: boolean;
    candidate?: Candidate;
    vacancies: Vacancy[];
    pagination?: PaginationModel;
    onPaginationChange: (currentPage: number) => void;
    onResetClick: () => void;
    className?: string;
}

const VacancyOverview: FC<VacancyOverviewProps> = ({
    isLoading,
    candidate,
    vacancies,
    pagination,
    onPaginationChange,
    onResetClick,
    className = '',
}): ReactElement => (
    <section className={`vacancy-overview ${className}`}>
        {isLoading && (
            <VacancyOverviewSkeletons
                amount={6}
                className="vacancy-overview__list"
            />
        )}

        {!isLoading && vacancies.length === 0 && (
            <VacancyOverviewNullState
                onResetClick={onResetClick}
                className="vacancy-overview__null-state"
            />
        )}

        {!isLoading && vacancies.length > 0 && (
            <ul className="vacancy-overview__list">
                {vacancies.map(vacancy => (
                    <li key={vacancy.uuid} className="vacancy-overview__list-item">
                        <VacancyCard
                            candidate={candidate}
                            vacancy={vacancy}
                            className="vacancy-overview__vacancy-card"
                        />
                    </li>
                ))}
            </ul>
        )}

        {pagination && (
            <Pagination
                id="vacancy-overview"
                amountOfPages={pagination.totalPages}
                currentPage={pagination.currentPage}
                onChange={onPaginationChange}
                className="vacancy-overview__pagination"
            />
        )}
    </section>
);

export default VacancyOverview;
