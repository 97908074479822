import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { CompetencySelector, FormActionButtons, Notice } from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import { Competency, CompetencyFormData } from '../../../models/Competencies/Competencies';
import { DialogFormProps, NoticeType } from '../../../types';
import { CompetenciesFormErrors, validateCompetenciesFormData } from './validations';

import './CompetenciesForm.scss';

interface CompetenciesFormProps extends DialogFormProps<CompetencyFormData> {
    competencies?: Competency[];
    competencyOptions: Competency[];
    className?: string;
}

const CompetenciesForm: FC<CompetenciesFormProps> = ({
    isLoading,
    error,
    competencies = [],
    competencyOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CompetenciesFormErrors>({});

    const [selectedCompetencies, setSelectedCompetencies] = useState<Competency[]>([]);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (competencies) setSelectedCompetencies(competencies);
    }, [competencies]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            competencies: selectedCompetencies.map(competency => competency.id),
        };

        const [errors, hasErrors] = validateCompetenciesFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`competencies-form ${className}`}>
            <CompetencySelector
                isLoading={isLoading}
                value={selectedCompetencies}
                error={formErrors.competencies}
                options={competencyOptions}
                onChange={setSelectedCompetencies}
            />

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="competencies-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="competencies-form__action-buttons"
            />
        </form>
    );
};

export default CompetenciesForm;
