import { FC, useEffect } from 'react';

import { CacheBuster } from '../../compositions';
import { fetchAppVersions } from '../../redux/app/appActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedCacheBuster: FC = () => {
    const dispatch = useTypedDispatch();

    const currentVersion = useTypedSelector(state => state.appReducer.currentVersion);
    const latestVersion = useTypedSelector(state => state.appReducer.latestVersion);

    useEffect((): void => {
        if (!currentVersion || !latestVersion) {
            dispatch(fetchAppVersions());
        }
    }, [currentVersion, latestVersion, dispatch]);

    return (
        <CacheBuster
            currentVersion={currentVersion}
            latestVersion={latestVersion}
        />
    );
};

export default ConnectedCacheBuster;
