import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { generateIdArray } from '../../helpers/array';
import { clamp } from '../../helpers/number';
import {
    checkEndForActivePage,
    checkStartForActivePage,
    generateCenterList,
    generateEndList,
    generateStartList,
} from './helpers';
import {
    PaginationButton,
    PaginationInputList,
    PaginationLongCenter,
    PaginationLongInitial,
} from './subcomponents';
import { Direction } from './subcomponents/PaginationButton/PaginationButton';

import './Pagination.scss';

interface PaginationProps {
    id: string;
    amountOfPages?: number;
    currentPage?: number;
    marginInitial?: number;
    marginCenter?: number;
    onChange: (currentPage: number) => void;
    className?: string;
}

const Pagination: FC<PaginationProps> = ({
    id,
    amountOfPages = 1,
    currentPage = 1,
    marginInitial = 4,
    marginCenter = 2,
    onChange,
    className = '',
}): ReactElement | null => {
    const minActivePage = 1;
    const [activePage, setActivePage] = useState<number>(clamp(currentPage, minActivePage, amountOfPages));

    useEffect((): void => {
        setActivePage(clamp(currentPage, minActivePage, amountOfPages));
    }, [currentPage, amountOfPages]);

    if (amountOfPages <= 1) {
        return null;
    }

    const pageNumbers = generateIdArray(amountOfPages).map(page => page + 1);
    const centerListWithMarginLength = (marginInitial + 1 + marginInitial);
    const shouldShowEllipsis = amountOfPages > centerListWithMarginLength;

    const startList = generateStartList(pageNumbers, marginInitial);
    const centerList = generateCenterList(pageNumbers, marginCenter, activePage);
    const endList = generateEndList(pageNumbers, marginInitial);

    const isAtStart = checkStartForActivePage(startList, activePage);
    const isAtEnd = checkEndForActivePage(endList, activePage);

    const handleChange = (pageNumber: number): void => {
        const clampedPageNumber = clamp(pageNumber, minActivePage, amountOfPages);
        setActivePage(clampedPageNumber);
        onChange(clampedPageNumber);
    };

    const handlePrevClick = (): void => handleChange(activePage - 1);
    const handleNextClick = (): void => handleChange(activePage + 1);

    const paginationInfo = {
        paginationId: id,
        amountOfPages,
        activePage,
        onChange: handleChange,
    };

    return (
        <div className={`pagination ${className}`}>
            <div className="pagination__controls">
                <PaginationButton
                    direction={Direction.Prev}
                    disabled={activePage <= 1}
                    onClick={handlePrevClick}
                />

                <div className="pagination__list">
                    {shouldShowEllipsis && (
                        isAtStart || isAtEnd
                            ? <PaginationLongInitial {...paginationInfo} startList={startList} endList={endList} />
                            : <PaginationLongCenter {...paginationInfo} centerList={centerList} />
                    )}
                    {!shouldShowEllipsis && <PaginationInputList {...paginationInfo} list={pageNumbers} />}
                </div>

                <PaginationButton
                    direction={Direction.Next}
                    disabled={activePage >= amountOfPages}
                    onClick={handleNextClick}
                />
            </div>
        </div>
    );
};

export default Pagination;
