import { FC, ReactElement } from 'react';

import { ErrorLabel } from '../../../../../components';
import { Loading, TextInput } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { Address } from '../../../../../models/Address/Address';

import './AddressInput.scss';

interface AddressInputProps {
    isLoading: boolean;
    value: Address;
    error?: string;
    onChange: (value: Address) => void;
    className?: string;
}

const AddressInput: FC<AddressInputProps> = ({
    isLoading,
    value,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const {
        city,
        postalCode,
        houseNumber,
        houseNumberAddition,
        street,
    } = value;

    const handlePostalCodeChange = (postalCodeValue: string): void => onChange({
        ...value,
        postalCode: postalCodeValue,
    });

    const handleHouseNumberChange = (houseNumberValue: string): void => onChange({
        ...value,
        houseNumber: houseNumberValue,
    });

    const handleHouseNumberAdditionChange = (houseNumberAdditionValue: string): void => onChange({
        ...value,
        houseNumberAddition: houseNumberAdditionValue,
    });

    const showAddressResult = !error && postalCode && houseNumber && city && street;

    return (
        <div className={`address-input ${className}`}>
            <div className="address-input__wrapper">
                <TextInput
                    label={trans('forms.candidatePersonalData.input.postalCode')}
                    required
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    className="address-input__input"
                />

                <TextInput
                    label={trans('forms.candidatePersonalData.input.houseNumber')}
                    required
                    value={houseNumber}
                    onChange={handleHouseNumberChange}
                    className="address-input__input"
                />

                <TextInput
                    label={trans('forms.candidatePersonalData.input.houseNumberAddition')}
                    value={houseNumberAddition}
                    onChange={handleHouseNumberAdditionChange}
                    className="address-input__input"
                />
            </div>

            <ErrorLabel text={error} className="address-input__error-label" />

            {isLoading && <Loading className="address-input__loader" />}

            {showAddressResult && (
                <div className="address-input__result-wrapper">
                    <h2 className="address-input__result-title">
                        {trans('forms.candidatePersonalData.result.title')}
                    </h2>

                    <ul className="address-input__result-list">
                        <li className="address-input__result">
                            <h2 className="address-input__result-label">
                                {trans('forms.candidatePersonalData.result.street')}
                            </h2>
                            <p>{street}</p>
                        </li>

                        <li className="address-input__result">
                            <h2 className="address-input__result-label">
                                {trans('forms.candidatePersonalData.result.city')}
                            </h2>
                            <p>{city}</p>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AddressInput;
