import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddDevelopmentPlanNoteRequest,
    DevelopmentPlanNote,
    DevelopmentPlanNoteResource,
    DevelopmentPlanNoteType,
    EditDevelopmentPlanNoteRequest,
} from './DevelopmentPlanNote';
import { transformToDevelopmentPlanNote } from './DevelopmentPlanNoteTransformer';

export const getDevelopmentPlanNotesApiCall = async (
    noteType: DevelopmentPlanNoteType,
    developmentPlanUuid: string,
    candidateUuid: string,
): Promise<FetchResult<DevelopmentPlanNote[], string>> => {
    try {
        const response = await lloFetch(`/api/note/${developmentPlanUuid}/${noteType}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanNoteResource[];

        const developmentPlanNotes = data.map(transformToDevelopmentPlanNote);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanNotes,
        };
    } catch (error) {
        console.error('[getDevelopmentPlanNotesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDevelopmentPlanNoteApiCall = async (
    noteType: DevelopmentPlanNoteType,
    requestData: AddDevelopmentPlanNoteRequest,
    candidateUuid: string,
): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch('/api/note', {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify({
                ...requestData,
                IsPublic: noteType === DevelopmentPlanNoteType.public,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[postDevelopmentPlanNoteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchDevelopmentPlanNoteApiCall = async (requestData: EditDevelopmentPlanNoteRequest, candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/note/${requestData.id}`, {
            method: 'PATCH',
            candidateUuid,
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[patchDevelopmentPlanNoteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDevelopmentPlanNoteApiCall = async (noteId: string, candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/note/${noteId}`, {
            method: 'DELETE',
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDevelopmentPlanNoteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
