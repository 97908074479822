import { FC, ReactElement, useState } from 'react';

import { Card } from '../../../components';
import { ViewSelector } from '../../../compositions';
import { ComparedInternalOccupationCompetencies, ComparedInternalOccupationSkills } from '../../../connectors/@occupation/ConnectedInternalOccupationDetailContent/helpers';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { InternalOccupationDetail } from '../../../models/InternalOccupationDetail/InternalOccupationDetail';
import { UserRole, ViewOption } from '../../../types';
import { InternalOccupationDetailContentSkeletons } from './skeletons';
import { InternalOccupationDetailContentList } from './subcomponents';

import './InternalOccupationDetailContent.scss';

enum View {
    matched = 'matched',
    default = 'default',
}

interface InternalOccupationDetailContentProps {
    isLoading: boolean;
    error: string;
    userRoles?: UserRole[];
    occupation: InternalOccupationDetail;
    comparedSkills: ComparedInternalOccupationSkills;
    comparedCompetencies: ComparedInternalOccupationCompetencies;
    className?: string;
}

const InternalOccupationDetailContent: FC<InternalOccupationDetailContentProps> = ({
    isLoading,
    error,
    userRoles = [],
    occupation,
    comparedSkills,
    comparedCompetencies,
    className = '',
}): ReactElement => {
    const [view, setView] = useState<string>(View.matched);

    const viewOptions: ViewOption[] = [
        {
            icon: 'visibility-on',
            label: trans('containers.internalOccupationDetail.view.matched'),
            value: View.matched,
        },
        {
            icon: 'visibility-off',
            label: trans('containers.internalOccupationDetail.view.default'),
            value: View.default,
        },
    ];

    const hasMatchedSkills = comparedSkills.matchedSkills.length > 0;

    return (
        <div className={`internal-occupation-detail-content ${className}`}>
            {isLoading && <InternalOccupationDetailContentSkeletons />}

            {!isLoading && !error && (
                <>
                    <div className="internal-occupation-detail-content__header">
                        <h2 className="internal-occupation-detail-content__title">
                            {trans('containers.internalOccupationDetail.skills')}
                        </h2>

                        {userHasRole(UserRole.counselor, userRoles) && hasMatchedSkills && (
                            <ViewSelector
                                options={viewOptions}
                                value={view}
                                onChange={setView}
                                className="internal-occupation-detail-content-selector"
                            />
                        )}
                    </div>

                    {view === View.matched && (
                        <Card className="internal-occupation-detail-content__card">
                            {comparedSkills.matchedSkills.length > 0 && (
                                <InternalOccupationDetailContentList
                                    isMatched
                                    title={trans(`containers.internalOccupationDetail.matchedSkills.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedSkills.matchedSkills}
                                    className="internal-occupation-detail-content__list"
                                />
                            )}

                            {comparedSkills.notMatchedSkills.length > 0 && (
                                <InternalOccupationDetailContentList
                                    title={trans(`containers.internalOccupationDetail.notMatchedSkills.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedSkills.notMatchedSkills}
                                    className="internal-occupation-detail-content__list"
                                />
                            )}
                        </Card>
                    )}

                    {view === View.default && (
                        <Card className="internal-occupation-detail-content__card">
                            <InternalOccupationDetailContentList
                                title={trans(`containers.internalOccupationDetail.notMatchedSkills.${getRoleForTranslation(userRoles)}`)}
                                skills={occupation.skills}
                                className="internal-occupation-detail-content__list"
                            />
                        </Card>
                    )}

                    <div className="internal-occupation-detail-content__competencies-wrapper">
                        <h2 className="internal-occupation-detail-content__title">
                            {trans('containers.internalOccupationDetail.competencies')}
                        </h2>
                    </div>

                    {view === View.matched && (
                        <Card className="internal-occupation-detail-content__card">
                            {comparedCompetencies.matchedCompetencies.length > 0 && (
                                <InternalOccupationDetailContentList
                                    isMatched
                                    title={trans(`containers.internalOccupationDetail.matchedCompetencies.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedCompetencies.matchedCompetencies}
                                    className="internal-occupation-detail-content__list"
                                />
                            )}

                            {comparedCompetencies.notMatchedCompetencies.length > 0 && (
                                <InternalOccupationDetailContentList
                                    title={trans(`containers.internalOccupationDetail.notMatchedCompetencies.${getRoleForTranslation(userRoles)}`)}
                                    skills={comparedCompetencies.notMatchedCompetencies}
                                    className="internal-occupation-detail-content__list"
                                />
                            )}
                        </Card>
                    )}

                    {view === View.default && (
                        <Card className="internal-occupation-detail-content__card">
                            <InternalOccupationDetailContentList
                                title={trans(`containers.internalOccupationDetail.notMatchedCompetencies.${getRoleForTranslation(userRoles)}`)}
                                skills={occupation.competencies}
                                className="internal-occupation-detail-content__list"
                            />
                        </Card>
                    )}
                </>
            )}
        </div>
    );
};

export default InternalOccupationDetailContent;
