import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    BlockRadioList,
    ButtonList,
    IconButton,
    Loading,
    Notice,
    SubmitButton,
    TextInput,
} from '../../../compositions';
import { aliasSplitCharacter } from '../../../constants';
import { convertDateStringToYYYYMMDD } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import { stripCharacterFromString } from '../../../helpers/string';
import trans from '../../../helpers/trans';
import { Address, defaultAddress } from '../../../models/Address/Address';
import { Candidate, CandidatePersonalDataFormData } from '../../../models/Candidate/Candidate';
import { Gender, NoticeType } from '../../../types';
import { AddressInput } from './subcomponents';
import { CandidatePersonalDataFormErrors, validateCandidatePersonalDataFormData } from './validations';

import './CandidatePersonalDataForm.scss';

interface CandidatePersonalDataFormProps {
    candidateIsLoading: boolean;
    addressIsLoading: boolean;
    candidate?: Candidate;
    addressResult?: Address;
    error: string;
    addressError: string;
    submitButtonLabel?: string;
    onAddressChange: (address: Address) => void;
    onSubmit: (formData: CandidatePersonalDataFormData) => void;
    onLogout?: () => void;
    className?: string;
}

const CandidatePersonalDataForm: FC<CandidatePersonalDataFormProps> = ({
    candidateIsLoading,
    addressIsLoading,
    candidate,
    addressResult,
    error = '',
    addressError = '',
    submitButtonLabel = trans('common.save'),
    onAddressChange,
    onSubmit,
    onLogout,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CandidatePersonalDataFormErrors>({});

    const [firstName, setFirstName] = useState<string>('');
    const [lastNamePrefix, setLastNamePrefix] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [address, setAddress] = useState<Address>(defaultAddress());

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (candidate?.firstName) setFirstName(candidate.firstName);
        if (candidate?.lastNamePrefix) setLastNamePrefix(candidate.lastNamePrefix);
        if (candidate?.lastName) setLastName(candidate.lastName);
        if (candidate?.gender) setGender(candidate.gender);
        if (candidate?.dateOfBirth) setDateOfBirth(candidate.dateOfBirth);
        if (candidate?.phoneNumber) setPhoneNumber(candidate.phoneNumber);
        if (candidate?.address) setAddress(candidate.address);
    }, [candidate]);

    useEffect((): void => {
        if (addressResult) {
            setAddress({
                ...address,
                ...addressResult,
            });
        }
    }, [addressResult]);

    const genderOptions = [
        { label: trans('common.gender.male'), value: Gender.male },
        { label: trans('common.gender.female'), value: Gender.female },
    ];

    const handleAddressChange = (addressValue: Address): void => {
        const newAddressData = {
            ...addressValue,
            city: '',
            street: '',
        };

        setAddress(newAddressData);
        onAddressChange(newAddressData);

        setFormErrors({
            ...formErrors,
            address: undefined,
        });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            firstName: stripCharacterFromString(firstName, aliasSplitCharacter),
            lastNamePrefix: stripCharacterFromString(lastNamePrefix, aliasSplitCharacter),
            lastName: stripCharacterFromString(lastName, aliasSplitCharacter),
            dateOfBirth: convertDateStringToYYYYMMDD(dateOfBirth),
            gender: gender as Gender,
            phoneNumber,
            address,
        };

        const [errors, hasErrors] = validateCandidatePersonalDataFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <div className={`candidate-personal-data-form ${className}`}>
            {candidateIsLoading && <Loading className="candidate-personal-data-form__loader" />}

            {!candidateIsLoading && (
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="candidate-personal-data-form__row">
                        <TextInput
                            label={trans('forms.candidatePersonalData.input.firstName')}
                            required
                            value={firstName}
                            error={formErrors.firstName}
                            onChange={setFirstName}
                            className="candidate-personal-data-form__column"
                        />
                        <TextInput
                            label={trans('forms.candidatePersonalData.input.lastNamePrefix')}
                            value={lastNamePrefix}
                            error={formErrors.lastNamePrefix}
                            onChange={setLastNamePrefix}
                            className="candidate-personal-data-form__column"
                        />
                        <TextInput
                            label={trans('forms.candidatePersonalData.input.lastName')}
                            required
                            value={lastName}
                            error={formErrors.lastName}
                            onChange={setLastName}
                            className="candidate-personal-data-form__column"
                        />
                    </div>

                    <div className="candidate-personal-data-form__row">
                        <TextInput
                            label={trans('forms.candidatePersonalData.input.dateOfBirth')}
                            type="date"
                            required
                            value={dateOfBirth}
                            error={formErrors.dateOfBirth}
                            onChange={setDateOfBirth}
                            className="candidate-personal-data-form__column"
                        />
                        <BlockRadioList
                            label={trans('forms.candidatePersonalData.input.gender')}
                            name="gender"
                            required
                            value={gender}
                            error={formErrors.gender}
                            options={genderOptions}
                            onChange={setGender}
                            className="candidate-personal-data-form__column"
                        />
                    </div>

                    <div className="candidate-personal-data-form__row">
                        <TextInput
                            label={trans('forms.candidatePersonalData.input.phoneNumber')}
                            type="tel"
                            value={phoneNumber}
                            error={formErrors.phoneNumber}
                            onChange={setPhoneNumber}
                            className="candidate-personal-data-form__input"
                        />
                    </div>

                    <div className="candidate-personal-data-form__row">
                        <AddressInput
                            isLoading={addressIsLoading}
                            value={address}
                            error={formErrors.address || addressError}
                            onChange={handleAddressChange}
                            className="candidate-personal-data-form__address-input"
                        />
                    </div>

                    {error && (
                        <Notice
                            type={NoticeType.error}
                            text={error}
                            className="candidate-personal-data-form__error-message"
                        />
                    )}

                    <ButtonList className="candidate-personal-data-form__button-wrapper">
                        <SubmitButton
                            text={submitButtonLabel}
                            className="candidate-personal-data-form__button"
                        />

                        {onLogout && (
                            <IconButton
                                icon="logout"
                                text={trans('common.logout')}
                                onClick={onLogout}
                                className="candidate-personal-data-form__button candidate-personal-data-form__button--logout"
                            />
                        )}
                    </ButtonList>
                </form>
            )}
        </div>
    );
};

export default CandidatePersonalDataForm;
