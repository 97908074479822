/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconOccupation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="m89.86666,25.08334l-19.93333,0l0,-9.96666l-9.96666,-9.96666l-19.93333,0l-9.96666,9.96666l0,9.96666l-19.93333,0c-5.48167,0 -9.96666,4.485 -9.96666,9.96666l0,24.91666c0,3.7375 1.99333,6.877 4.98333,8.62116l0,16.2955c0,5.5315 4.43517,9.96666 9.96666,9.96666l69.76665,0c5.5315,0 9.96666,-4.43517 9.96666,-9.96666l0,-16.34533c2.94017,-1.74417 4.98333,-4.9335 4.98333,-8.57133l0,-24.91666c0,-5.48167 -4.485,-9.96666 -9.96666,-9.96666zm-49.83332,-9.96666l19.93333,0l0,9.96666l-19.93333,0l0,-9.96666zm-29.89999,19.93333l79.73331,0l0,24.91666l-24.91666,0l0,-14.95l-29.89999,0l0,14.95l-24.91666,0l0,-24.91666zm44.84999,29.89999l-9.96666,0l0,-9.96666l9.96666,0l0,9.96666zm29.89999,19.93333l-69.76665,0l0,-14.95l19.93333,0l0,4.98333l29.89999,0l0,-4.98333l19.93333,0l0,14.95z" />
    </svg>
);

export default IconOccupation;
/* eslint-enable max-len */
