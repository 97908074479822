import { CandidatePersonalDataFormData } from '../../../../models/Candidate/Candidate';
import {
    FormErrors,
    FormValidation,
    validateAddress,
    validateDateOfBirthString,
    validateDateString,
    validateForm,
    validateName,
    validatePhoneNumber,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type CandidatePersonalDataFormErrors = FormErrors<CandidatePersonalDataFormData>;

export const validateCandidatePersonalDataFormData = (formData: CandidatePersonalDataFormData): FormValidation<CandidatePersonalDataFormErrors> => {
    const errors: CandidatePersonalDataFormErrors = {
        firstName: validateName('firstName', formData.firstName),
        lastName: validateName('lastName', formData.lastName),
        dateOfBirth: validateDateString('date', formData.dateOfBirth) || validateDateOfBirthString('dateOfBirth', formData.dateOfBirth),
        gender: validateRequiredString('gender', formData.gender),
        phoneNumber: formData.phoneNumber ? validatePhoneNumber(formData.phoneNumber) : undefined,
        address: validateAddress(formData.address),
    };

    return validateForm<CandidatePersonalDataFormErrors>(errors);
};
