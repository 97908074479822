import { FC, ReactElement, useRef } from 'react';

import { Card, SafeHtml } from '../../components';
import { IconButton, LeavingPlatformDialog, LogoCard } from '../../compositions';
import { CandidateIllustrationNotification } from '../../compositions/CandidateIllustration/CandidateIllustration';
import { tmaUrl } from '../../constants';
import { closeDialog, openDialog } from '../../helpers/dialog';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { Gender } from '../../types';

import './ManageAnalyses.scss';

interface ManageAnalysesProps {
    isLoading: boolean;
    candidate?: Candidate;
    error?: string;
    className?: string;
}

const ManageAnalyses: FC<ManageAnalysesProps> = ({
    isLoading,
    candidate,
    error = '',
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => closeDialog(dialogRef);

    const tmaDashboardUrl = `${tmaUrl}/Customer/Dashboard`;

    return (
        <div className={`manage-analyses ${className}`}>
            <LogoCard logo="tma" className="manage-analyses__logo-card" />

            <Card className="manage-analyses__description-card">
                <h1 className="manage-analyses__description-title">
                    {trans('containers.manageAnalyses.descriptionTitle')}
                </h1>

                <SafeHtml
                    html={trans('containers.manageAnalyses.descriptionText')}
                    className="manage-analyses__description-text"
                />

                <IconButton
                    icon="arrow-right"
                    iconPos="right"
                    text={trans('containers.manageAnalyses.buttonText')}
                    disabled={isLoading || !!error}
                    onClick={handleOpenDialog}
                    className="manage-analyses__action-button"
                />
            </Card>

            <LeavingPlatformDialog
                ref={dialogRef}
                illustrationType={CandidateIllustrationNotification.tma}
                gender={candidate?.gender as Gender}
                title={trans('containers.manageAnalyses.dialog.title')}
                description={trans('containers.manageAnalyses.dialog.description')}
                buttonText={trans('containers.manageAnalyses.dialog.buttonText')}
                buttonUrl={tmaDashboardUrl}
                onClose={handleCloseDialog}
            />
        </div>
    );
};

export default ManageAnalyses;
