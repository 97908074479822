import { formatDate } from '../../helpers/date';
import {
    JobExperience,
    JobExperienceFormData,
    JobExperienceRequest,
    JobExperienceResource,
} from './JobExperience';

export const transformToJobExperience = (
    jobExperienceResource: JobExperienceResource,
): JobExperience => ({
    id: jobExperienceResource.id,
    position: jobExperienceResource.position,
    company: jobExperienceResource.company,
    description: jobExperienceResource.description,
    isCurrentJob: jobExperienceResource.isCurrentJob,
    startDate: new Date(jobExperienceResource.startDate),
    endDate: jobExperienceResource.endDate
        ? new Date(jobExperienceResource.endDate)
        : undefined,
});

export const transformToJobExperienceRequest = (
    jobExperienceFormData: JobExperienceFormData,
): JobExperienceRequest => {
    const startDate = formatDate(jobExperienceFormData.startDate, 'y-MM-dd');
    const endDate = jobExperienceFormData.endDate && !jobExperienceFormData.isCurrentJob
        ? formatDate(jobExperienceFormData.endDate, 'y-MM-dd')
        : undefined;

    return ({
        id: jobExperienceFormData.id,
        position: jobExperienceFormData.position,
        company: jobExperienceFormData.company,
        description: jobExperienceFormData.description,
        isCurrentJob: jobExperienceFormData.isCurrentJob,
        startDate,
        endDate,
    });
};
