import {
    FC,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    Card,
    LinkButton,
    LoadingSpinner,
    TableCell,
    TableRow,
} from '../../../components';
import {
    Dialog,
    DialogContent,
    IconButton,
    Notice,
    SearchInput,
    SelectInput,
    SortTable,
} from '../../../compositions';
import { SortLabelInfo } from '../../../compositions/@tables/SortLabel/SortLabel';
import { lloOrganisationName } from '../../../constants';
import { convertDateToReadableDate } from '../../../helpers/date';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { CandidateExchangeFormData } from '../../../models/CandidateExchange/CandidateExchange';
import { CandidateFilterValues, CandidateOverview, OrganisationCandidate } from '../../../models/CandidateOverview/CandidateOverview';
import { CounselorOption } from '../../../models/Counselor/Counselor';
import { transformCounselorOptionToFormOption } from '../../../models/Counselor/CounselorTransformers';
import { Organisation } from '../../../models/Organisation/Organisation';
import { User } from '../../../models/User/User';
import { RoutePaths } from '../../../Routes';
import {
    FormOption,
    NoticeType,
    SortDirection,
    SortField,
    UserRole,
} from '../../../types';
import { ArchiveCandidateForm, CandidateExchangeForm } from '../..';
import { ArchiveSuccessMessage, ExchangeSuccessMessage } from './subcomponents';

import './OrganisationCandidateTable.scss';

interface OrganisationCandidateTableProps {
    isLoading: boolean;
    exchangeIsLoading: boolean;
    counselorOptionsIsLoading: boolean;
    organisationOptionsIsLoading: boolean;
    exchangeIsSuccessful: boolean;
    user?: User;
    organisation?: Organisation;
    candidateOverview: CandidateOverview;
    activeFilterValues: CandidateFilterValues;
    error?: string;
    exchangeError?: string;
    counselorOptionsError?: string;
    organisationOptionsError?: string;
    counselorOptions: CounselorOption[];
    exchangeCounselorOptions: CounselorOption[];
    organisationOptions: Organisation[];
    counselorSearchQuery: string;
    organisationSearchQuery: string;
    onSort: (field: SortField, direction?: SortDirection) => void;
    onSearch: (query: string) => void;
    onCounselorFilter: (counselorId: string) => void;
    onOpenExchangeDialog: () => void;
    onOpenArchiveDialog: () => void;
    onSearchCounselorOptions: (query: string) => void;
    onSearchOrganisationOptions: (query: string) => void;
    onOrganisationSelect: (organisation: Organisation) => void;
    onSubmitExchangeRequest: (candidateExchangeFormData: CandidateExchangeFormData) => void;
    onSubmitArchiveCandidate: (candidateUuid: string) => void;
    onFinishExchange: () => void;
    className?: string;
}

const OrganisationCandidateTable: FC<OrganisationCandidateTableProps> = ({
    isLoading,
    exchangeIsLoading,
    organisationOptionsIsLoading,
    exchangeIsSuccessful,
    user,
    organisation,
    candidateOverview,
    activeFilterValues,
    error = '',
    exchangeError = '',
    organisationOptionsError = '',
    counselorOptions,
    exchangeCounselorOptions,
    organisationOptions,
    counselorSearchQuery,
    organisationSearchQuery,
    onSort,
    onSearch,
    onCounselorFilter,
    onOpenExchangeDialog,
    onOpenArchiveDialog,
    onSearchCounselorOptions,
    onSearchOrganisationOptions,
    onOrganisationSelect,
    onSubmitExchangeRequest,
    onSubmitArchiveCandidate,
    onFinishExchange,
    className = '',
}): ReactElement => {
    const exchangeDialogRef = useRef<HTMLDialogElement>(null);
    const archiveDialogRef = useRef<HTMLDialogElement>(null);

    const [selectedCandidate, setSelectedCandidate] = useState<OrganisationCandidate>();
    const [newCounselor, setNewCounselor] = useState<CounselorOption>();

    const [candidateExchangeFormKey, setCandidateExchangeFormKey] = useState<number>(0);

    const handleOpenExchangeDialog = (): void => {
        openDialog(exchangeDialogRef);
        onOpenExchangeDialog();
    };

    const handleCloseExchangeDialog = (): void => {
        closeDialog(exchangeDialogRef);
        setSelectedCandidate(undefined);
        setNewCounselor(undefined);

        onSearchCounselorOptions('');
        onSearchOrganisationOptions('');
        setCandidateExchangeFormKey(candidateExchangeFormKey + 1);
    };

    const handleOpenArchiveDialog = (): void => {
        openDialog(archiveDialogRef);
        onOpenArchiveDialog();
    };

    const handleCloseArchiveDialog = (): void => {
        closeDialog(archiveDialogRef);
        setSelectedCandidate(undefined);
    };

    const { totalAmount, candidates } = candidateOverview;

    const isLloAdmin = userHasRole(UserRole.lloAdmin, user?.roles);
    const isLloOrganisation = organisation?.id === lloOrganisationName;

    const counselorFormOptions = useMemo((): FormOption[] => (
        counselorOptions.map(transformCounselorOptionToFormOption)
    ), [counselorOptions]);

    const sortCells: SortLabelInfo[] = [
        { label: trans('tables.organisationCandidate.labels.candidateName'), field: SortField.candidateName },
        { label: trans('tables.organisationCandidate.labels.email') },
        { label: trans('tables.organisationCandidate.labels.counselorName'), field: SortField.counselorName },
        { label: trans('tables.organisationCandidate.labels.createdAt'), field: SortField.createdAt },
    ];

    const handleSubmitCandidateExchangeRequest = (candidateExchangeFormData: CandidateExchangeFormData): void => {
        setNewCounselor(counselorOptions.find(counselor => counselor.id === candidateExchangeFormData.counselorId));
        onSubmitExchangeRequest(candidateExchangeFormData);
        onSearchCounselorOptions('');
    };

    const handleFinishCandidateExchange = (): void => {
        onFinishExchange();
        setNewCounselor(undefined);
        handleCloseExchangeDialog();
    };

    const handleFinishArchiveCandidate = (): void => {
        onFinishExchange();
        handleCloseArchiveDialog();
    };

    return (
        <Card className={`organisation-candidate-table ${className}`}>
            <header className="organisation-candidate-table__header">
                <div className="organisation-candidate-table__header-state-wrapper">
                    <h1 className="organisation-candidate-table__title">
                        {trans('tables.organisationCandidate.title', {
                            totalAmount: String(totalAmount),
                        })}
                    </h1>

                    {isLoading && <LoadingSpinner className="organisation-candidate-table__loader" />}
                </div>

                {candidates.length > 0 && (
                    <>
                        <SelectInput
                            hideLabel
                            isClearable
                            isDisabled={isLoading}
                            name="organisation-candidate-table-select"
                            label={trans('tables.organisationCandidate.counselorSelect.label')}
                            placeholder={trans('tables.organisationCandidate.counselorSelect.placeholder')}
                            value={counselorFormOptions.find(option => option.value === activeFilterValues.counselorId)}
                            options={counselorFormOptions}
                            onChange={onCounselorFilter}
                            className="organisation-candidate-table__counselor-select"
                        />

                        <SearchInput
                            hideLabel
                            label={trans('tables.organisationCandidate.search')}
                            value={activeFilterValues.searchQuery}
                            placeholder={trans('tables.organisationCandidate.search')}
                            onChange={onSearch}
                            className="organisation-candidate-table__search-input"
                        />
                    </>
                )}
            </header>

            {!isLoading && candidates.length === 0 && (
                <div className="organisation-candidate-table__null-state-wrapper">
                    <p>{trans('tables.organisationCandidate.nullState.description')}</p>
                </div>
            )}

            {error && candidates.length > 0 && (
                <div className="organisation-candidate-table__error-wrapper">
                    <Notice
                        type={NoticeType.error}
                        text={error}
                        className="organisation-candidate-table__error-message"
                    />
                </div>
            )}

            {candidates.length > 0 && (
                <SortTable<OrganisationCandidate>
                    hasStickyColumn
                    sortConfig={activeFilterValues.sortConfig}
                    sortCells={sortCells}
                    rows={candidates}
                    onSort={onSort}
                    className="organisation-candidate-table__table"
                    rowClassName="organisation-candidate-table__row"
                    cellClassName="organisation-candidate-table__cell"
                >
                    {(sortedRows): JSX.Element[] => sortedRows.map(candidate => {
                        const handleCandidateExchangeClick = (): void => {
                            handleOpenExchangeDialog();
                            setSelectedCandidate(candidate);
                        };

                        const handleArchiveCandidateClick = (): void => {
                            handleOpenArchiveDialog();
                            setSelectedCandidate(candidate);
                        };

                        return (
                            <TableRow
                                key={candidate.id}
                                className="organisation-candidate-table__row organisation-candidate-table__row--is-interactive"
                            >
                                <TableCell className="organisation-candidate-table__cell">
                                    <LinkButton
                                        to={RoutePaths.candidateDashboard(candidate.id)}
                                        text={candidate.fullName}
                                        className="organisation-candidate-table__link-button"
                                    />
                                </TableCell>
                                <TableCell className="organisation-candidate-table__cell">
                                    {candidate.email}
                                </TableCell>
                                <TableCell className="organisation-candidate-table__cell">
                                    {candidate.counselorFullName}
                                </TableCell>
                                <TableCell className="organisation-candidate-table__cell">
                                    {convertDateToReadableDate(candidate.createdAt)}
                                </TableCell>
                                <TableCell
                                    contentClassName="organisation-candidate-table__fixed-cell-content"
                                    className="organisation-candidate-table__fixed-cell"
                                >
                                    <IconButton
                                        icon="exchange"
                                        text={trans('tables.organisationCandidate.candidateExchange.actionButtonLabel')}
                                        hideLabel
                                        onClick={handleCandidateExchangeClick}
                                        className="organisation-candidate-table__action-button"
                                    />
                                    {!isLloOrganisation && (
                                        <IconButton
                                            icon="archive"
                                            text={trans('tables.organisationCandidate.archiveCandidate.actionButtonLabel')}
                                            hideLabel
                                            onClick={handleArchiveCandidateClick}
                                            className="organisation-candidate-table__action-button"
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </SortTable>
            )}

            <Dialog ref={exchangeDialogRef} onClose={handleCloseExchangeDialog}>
                {(exchangeIsSuccessful && selectedCandidate && newCounselor) ? (
                    <ExchangeSuccessMessage
                        userRoles={user?.roles}
                        candidate={selectedCandidate}
                        newCounselor={newCounselor}
                        onConfirm={handleFinishCandidateExchange}
                    />
                ) : (
                    <DialogContent title={trans('tables.organisationCandidate.candidateExchange.title')}>
                        <CandidateExchangeForm
                            key={candidateExchangeFormKey}
                            isLoading={exchangeIsLoading}
                            organisationOptionsIsLoading={organisationOptionsIsLoading}
                            isLloAdmin={isLloAdmin}
                            error={exchangeError}
                            userRoles={user?.roles}
                            candidate={selectedCandidate}
                            organisationOptionsError={organisationOptionsError}
                            counselorOptions={exchangeCounselorOptions}
                            organisationOptions={organisationOptions}
                            counselorSearchQuery={counselorSearchQuery}
                            organisationSearchQuery={organisationSearchQuery}
                            onSearchCounselorOptions={onSearchCounselorOptions}
                            onSearchOrganisationOptions={onSearchOrganisationOptions}
                            onOrganisationSelect={onOrganisationSelect}
                            onSubmit={handleSubmitCandidateExchangeRequest}
                            onCancel={handleCloseExchangeDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>

            <Dialog ref={archiveDialogRef} onClose={handleCloseArchiveDialog}>
                {(exchangeIsSuccessful && selectedCandidate) ? (
                    <ArchiveSuccessMessage
                        candidate={selectedCandidate}
                        onConfirm={handleFinishArchiveCandidate}
                    />
                ) : (
                    <DialogContent title={trans('tables.organisationCandidate.archiveCandidate.title')}>
                        <ArchiveCandidateForm
                            isLoading={exchangeIsLoading}
                            error={exchangeError}
                            candidate={selectedCandidate}
                            onSubmit={onSubmitArchiveCandidate}
                            onCancel={handleCloseArchiveDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>
        </Card>
    );
};

export default OrganisationCandidateTable;
