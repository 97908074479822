import { FC, ReactElement } from 'react';

import { FloatingCard, LinkIconButton } from '../../../../../compositions';
import { trimToMaxLength } from '../../../../../helpers/string';
import trans from '../../../../../helpers/trans';
import { OrganisationOccupationOverviewItem } from '../../../../../models/OrganisationOccupationOverview/OrganisationOccupationOverview';
import { RoutePaths } from '../../../../../Routes';

import './OccupationLinkCard.scss';

interface OccupationLinkCardProps {
    occupation: OrganisationOccupationOverviewItem;
    organisationUuid: string;
    className?: string;
}

const OccupationLinkCard: FC<OccupationLinkCardProps> = ({
    organisationUuid,
    occupation,
    className = '',
}): ReactElement => (
    <li className={`occupation-link-card ${className}`}>
        <FloatingCard cardClassName="occupation-link-card__card">
            <h3 className="occupation-link-card__title">{occupation.title}</h3>
            <p className="occupation-link-card__description">{trimToMaxLength(occupation.description, 160)}</p>

            <LinkIconButton
                icon="arrow-right"
                iconPos="right"
                text={trans('containers.organisationDetailOccupationOverview.cardAction')}
                to={RoutePaths.organisationDetailOccupation(organisationUuid, occupation.id)}
                className="occupation-link-card__action-button"
            />
        </FloatingCard>
    </li>
);

export default OccupationLinkCard;
