import { isFetchResultSuccessful } from '../../models/FetchResult';
import { OccupationFilterValues } from '../../models/OccupationOverview/OccupationOverview';
import { getOccupationOverviewApiCall, getOccupationSuggestionsApiCall } from '../../models/OccupationOverview/OccupationOverviewService';
import { transformToPagination } from '../../models/Pagination';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setOccupationOverview,
    setOccupationSuggestions,
    setPagination,
    setSuggestionsIsLoading,
} from './occupationOverview';

export const setOccupationOverviewIsLoading = (isLoading: boolean) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(isLoading));
};

export const setOccupationSuggestionsIsLoading = (isLoading: boolean) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setSuggestionsIsLoading(isLoading));
};

export const fetchOccupationOverview = (candidateUuid: string, skills: string[], activeFilterValues: OccupationFilterValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const occupationOverviewResponse = await getOccupationOverviewApiCall(candidateUuid, skills, activeFilterValues);

        if (!isFetchResultSuccessful(occupationOverviewResponse)) {
            dispatch(setError(occupationOverviewResponse.error));
            return;
        }

        const occupationOverview = occupationOverviewResponse.data;

        const currentPage = activeFilterValues.pageNumber;
        const pagination = transformToPagination(occupationOverview.occupations.length, currentPage);

        dispatch(setOccupationOverview(occupationOverview));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchOccupationOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const setOccupationOverviewPagination = (currentPage: number) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const state = getState();
    const { pagination } = state.occupationOverviewReducer;

    if (pagination) {
        await dispatch(setPagination({
            ...pagination,
            currentPage,
        }));
    }
};

export const fetchOccupationSuggestions = (candidateUuid: string, query: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setSuggestionsIsLoading(true));
    dispatch(setError(''));

    try {
        const occupationSuggestionsResponse = await getOccupationSuggestionsApiCall(candidateUuid, query);

        if (!isFetchResultSuccessful(occupationSuggestionsResponse)) {
            dispatch(setError(occupationSuggestionsResponse.error));
            return;
        }

        const occupationSuggestions = occupationSuggestionsResponse.data;

        dispatch(setOccupationSuggestions(occupationSuggestions));
    } catch (error) {
        console.error('[fetchOccupationSuggestions]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setSuggestionsIsLoading(false));
    }
};
