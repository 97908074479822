import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '../../../../../components';
import trans from '../../../../../helpers/trans';
import { CompetencyLevel, SearchableOption } from '../../../../../types';

import './SearchableCompetencyOption.scss';

interface SearchableCompetencyOptionProps {
    option: SearchableOption;
    onSelect: (competency: SearchableOption) => void;
    className?: string;
}

const SearchableCompetencyOption: FC<SearchableCompetencyOptionProps> = ({
    option,
    onSelect,
    className = '',
}): ReactElement => {
    const handleClick = (): void => onSelect(option);
    const isTalented = option.secondaryLabel === CompetencyLevel.high || option.secondaryLabel === CompetencyLevel.regular;
    const isDefaultCompetency = option.secondaryLabel === CompetencyLevel.noLevel;

    const secondaryLabelClassNames = classNames('searchable-competency-option__secondary-label', {
        'searchable-competency-option__secondary-label--talented': isTalented,
        'searchable-competency-option__secondary-label--less-talented': !isTalented,
    });

    const competencyLevelIconClassNames = classNames('searchable-competency-option__icon', {
        'searchable-competency-option__icon--talented': isTalented,
        'searchable-competency-option__icon--less-talented': !isTalented,
        'searchable-competency-option__icon--default-competency': isDefaultCompetency,
    });

    return (
        <li className={`searchable-competency-option ${className}`}>
            <Button
                text={option.label}
                onClick={handleClick}
                className="searchable-competency-option__button"
            >
                <p className="searchable-competency-option__label">
                    {option.label}
                </p>

                {option.secondaryLabel && (
                    <span className={secondaryLabelClassNames}>
                        {trans(`forms.developmentPlanCompetencyForm.competencyLevel.${option.secondaryLabel}`)}
                    </span>
                )}
                <span className={competencyLevelIconClassNames} />
            </Button>
        </li>
    );
};

export default SearchableCompetencyOption;
