import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { capitalizeFirstCharacter } from '../../../../../helpers/string';
import { OccupationSkill } from '../../../../../models/OccupationDetail/OccupationDetail';

import './OccupationDetailSkillsList.scss';

interface OccupationDetailSkillsListProps {
    isMatched?: boolean;
    title: string;
    skills: OccupationSkill[];
    className?: string;
}

const OccupationDetailSkillsList: FC<OccupationDetailSkillsListProps> = ({
    isMatched,
    title,
    skills,
    className = '',
}): ReactElement => {
    const listItemClassNames = classNames('occupation-detail-skills-list__list-item', {
        'occupation-detail-skills-list__list-item--is-matched': isMatched,
    });

    return (
        <div className={`occupation-detail-skills-list ${className}`}>
            <h3 className="occupation-detail-skills-list__title">
                {title}
            </h3>
            <ul className="occupation-detail-skills-list__list">
                {skills.map(skill => (
                    <li key={skill.id} className={listItemClassNames}>
                        {capitalizeFirstCharacter(skill.label)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OccupationDetailSkillsList;
