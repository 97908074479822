/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconBin: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M87.4,12.5c-1-1-2.4-1.6-3.9-1.6l-18.6,0l0-5.3c0-3.1-2.6-5.6-5.5-5.5L41,0c-1.6,0-2.9,0.6-3.9,1.7c-1,1-1.6,2.4-1.6,3.9l0,5.3l-18.9,0c-1.5,0-2.9,0.6-3.9,1.6c-1,1-1.7,2.4-1.7,3.9l0,5.5h34.7h2.7H89l0-5.5C89,14.9,88.4,13.5,87.4,12.5z" />
        <path d="M16.5,32.9l0,61.5c0,3.1,2.6,5.6,5.5,5.5l55.8,0c1.6,0,2.9-0.6,3.9-1.7c1-1,1.6-2.4,1.6-3.9V32.9L16.5,32.9z" />
    </svg>
);

export default IconBin;
/* eslint-enable max-len */
