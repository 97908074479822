import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { UserRole } from '../../../types';

export const getDevelopmentTitle = (userRoles?: UserRole[], candidateName?: string): string => {
    if (userHasRole(UserRole.candidate, userRoles)) {
        return trans('pages.development.pageTitle.candidate');
    }

    if (userHasRole(UserRole.counselor, userRoles) && candidateName) {
        return trans('pages.development.pageTitle.counselor', { name: candidateName });
    }

    return '';
};
