import { convertDateStringToYYYYMMDD } from '../../../../helpers/date';
import { TmaAnalysisSettingsFormData } from '../../../../models/TmaAnalysis/TmaAnalysis';
import {
    FormErrors,
    FormValidation,
    validateDateString,
    validateEndDate,
    validateForm,
    validateRequiredString,
    validateStartDate,
} from '../../../../services/ValidationService';

export type TmaAnalysisSettingsFormErrors = FormErrors<TmaAnalysisSettingsFormData>;

export const validateTmaAnalysisSettingsFormData = (formData: TmaAnalysisSettingsFormData): FormValidation<TmaAnalysisSettingsFormErrors> => {
    const isoStartDateString = convertDateStringToYYYYMMDD(formData.startDate);
    const isoEndDateString = convertDateStringToYYYYMMDD(formData.endDate);
    const startDate = new Date(isoStartDateString);
    const endDate = new Date(isoEndDateString);

    const errors: TmaAnalysisSettingsFormErrors = {
        type: validateRequiredString('tmaType', formData.type),
        startDate: validateDateString('startDate', formData.startDate) || validateStartDate(startDate, endDate),
        endDate: validateDateString('endDate', formData.endDate) || validateEndDate(endDate, startDate),
    };

    return validateForm<TmaAnalysisSettingsFormErrors>(errors);
};
