import { FC, ReactElement } from 'react';

import { PersonalityDescriptionCard } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedPersonalityDescriptionCardProps {
    onRefresh: () => void;
    className?: string;
}

const ConnectedPersonalityDescriptionCard: FC<ConnectedPersonalityDescriptionCardProps> = ({
    onRefresh,
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const consistencyScore = useTypedSelector(state => state.skillsPassportReducer.consistencyScore);
    const personalityDescription = useTypedSelector(state => state.skillsPassportReducer.personalityDescription);
    const isUpToDate = useTypedSelector(state => state.skillsPassportReducer.isUpToDate);

    return (
        <PersonalityDescriptionCard
            isLoading={skillsPassportIsLoading}
            isUpToDate={isUpToDate}
            candidate={candidate}
            userRoles={user?.roles}
            consistencyScore={consistencyScore}
            htmlText={personalityDescription}
            onRefresh={onRefresh}
            className={className}
        />
    );
};

export default ConnectedPersonalityDescriptionCard;
