import { FC, ReactElement } from 'react';

import { ConnectedWorkplacePreferencesCard } from '../../../../../connectors';

import './Workplace.scss';

interface WorkplaceProps {
    onRefresh: () => void;
}

const Workplace: FC<WorkplaceProps> = ({ onRefresh }): ReactElement => (
    <ConnectedWorkplacePreferencesCard
        onRefresh={onRefresh}
        className="workplace-tab__card"
    />
);

export default Workplace;
