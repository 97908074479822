import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { OccupationDetailHeader } from '../../../containers';
import { DevelopmentPlanWithSkillsFormData } from '../../../containers/@forms/DevelopmentPlanWithSkillsForm/DevelopmentPlanWithSkillsForm';
import { Candidate } from '../../../models/Candidate/Candidate';
import { OccupationDetail } from '../../../models/OccupationDetail/OccupationDetail';
import { setIsSuccessful } from '../../../redux/developmentPlan/developmentPlan';
import { addDevelopmentPlanWithSkills } from '../../../redux/developmentPlan/developmentPlanActions';
import { fetchOccupationDetail } from '../../../redux/occupationDetail/occupationDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';
import { getComparedOccupationSkills } from './helpers';

interface ConnectedOccupationDetailHeaderProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onOccupationDetailResponse: (occupationDetail: OccupationDetail) => void;
    className?: string;
}

const ConnectedOccupationDetailHeader: FC<ConnectedOccupationDetailHeaderProps> = ({
    onCandidateResponse,
    onOccupationDetailResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { occupationUuid } = useParams();

    const user = useTypedSelector(state => state.userReducer.user);

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skills = useTypedSelector(state => state.skillsPassportReducer.skills);

    const occupationIsLoading = useTypedSelector(state => state.occupationDetailReducer.isLoading);
    const occupationError = useTypedSelector(state => state.occupationDetailReducer.error);
    const occupation = useTypedSelector(state => state.occupationDetailReducer.occupation);

    const developmentPlanIsLoading = useTypedSelector(state => state.developmentPlanReducer.isLoading);
    const developmentPlanIsSuccessful = useTypedSelector(state => state.developmentPlanReducer.isSuccessful);
    const developmentPlanError = useTypedSelector(state => state.developmentPlanReducer.error);
    const developmentPlan = useTypedSelector(state => state.developmentPlanReducer.developmentPlan);

    const pageIsLoading = [
        candidateIsLoading,
        occupationIsLoading,
        skillsPassportIsLoading,
    ].some(Boolean);

    const handleAddDevelopmentPlanWithSkills = (formData: DevelopmentPlanWithSkillsFormData): void => {
        dispatch(addDevelopmentPlanWithSkills(formData, candidate?.uuid || ''));
    };

    useEffect((): void => {
        if (occupation) onOccupationDetailResponse(occupation);
    }, [occupation]);

    useEffect((): void => {
        if (candidate) onCandidateResponse(candidate);

        if (candidate && candidate.uuid && occupationUuid) {
            const skillLabels = skills.map(skill => skill.name);

            dispatch(fetchOccupationDetail(occupationUuid, candidate.uuid, skillLabels));
        }
    }, [candidate]);

    useEffect((): void => {
        if (developmentPlanIsSuccessful && candidate && developmentPlan) {
            navigate(RoutePaths.developmentPlan(candidate.uuid || '', developmentPlan.id));
            dispatch(setIsSuccessful(false));
        }
    }, [developmentPlanIsSuccessful]);

    return (
        <OccupationDetailHeader
            pageIsLoading={pageIsLoading}
            developmentPlanFormIsLoading={developmentPlanIsLoading}
            occupationError={occupationError}
            developmentPlanFormError={developmentPlanError}
            userRoles={user?.roles}
            occupation={occupation}
            comparedOccupationSkills={getComparedOccupationSkills(occupation, skills)}
            onAddDevelopmentPlan={handleAddDevelopmentPlanWithSkills}
            className={className}
        />
    );
};

export default ConnectedOccupationDetailHeader;
