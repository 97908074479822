import { FC, ReactElement, useMemo } from 'react';

import { Card } from '../../../../../components';
import { LinkIconButton, TagList } from '../../../../../compositions';
import { getRoleForTranslation } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { Vacancy, VacancyOrigin } from '../../../../../models/Vacancy';
import { UserRole } from '../../../../../types';

import './VacancyDetailAside.scss';

interface VacancyPropertyListItem {
    label: string;
    value: string[];
}

export interface VacancyDetailAsideProps {
    userRoles: UserRole[];
    vacancy: Vacancy;
    className?: string;
}

const VacancyDetailAside: FC<VacancyDetailAsideProps> = ({
    userRoles,
    vacancy,
    className = '',
}): ReactElement => {
    const properties = useMemo((): VacancyPropertyListItem[] => {
        const mainProperties = Object.entries(vacancy.properties).map(([key, value]) => ({
            label: trans(`containers.vacancyDetail.properties.${key}`),
            value: value.length ? [value].flat() : [],
        }));

        const extraProperties = [
            {
                label: trans('containers.vacancyDetail.properties.fieldsOfStudy'),
                value: vacancy.fieldsOfStudy,
            },
        ];

        return [...mainProperties, ...extraProperties].filter(property => property.value.length);
    }, [vacancy]);

    return (
        <aside className={`vacancy-detail-aside ${className}`}>
            {vacancy.origin === VacancyOrigin.matchingService && (
                <Card className="vacancy-detail-aside__card">
                    <h2 className="vacancy-detail-aside__card-title">
                        {trans('containers.vacancyDetail.apply.title')}
                    </h2>

                    <p className="vacancy-detail-aside__card-description">
                        {trans(`containers.vacancyDetail.apply.description.${getRoleForTranslation(userRoles)}`)}
                    </p>

                    <LinkIconButton
                        to={vacancy.externalUrl}
                        icon="arrow-right"
                        iconPos="right"
                        text={trans('containers.vacancyDetail.apply.buttonLabel')}
                        className="vacancy-detail-aside__link-button"
                    />
                </Card>
            )}

            {vacancy.origin === VacancyOrigin.jobdigger && (
                <Card className="vacancy-detail-aside__card">
                    <h2 className="vacancy-detail-aside__card-title">
                        {trans('containers.vacancyDetail.applyJobdigger.title')}
                    </h2>

                    <p className="vacancy-detail-aside__card-description">
                        {trans(`containers.vacancyDetail.applyJobdigger.description.${getRoleForTranslation(userRoles)}`)}
                    </p>

                    <LinkIconButton
                        to={vacancy.externalUrl}
                        icon="arrow-right"
                        iconPos="right"
                        text={trans('containers.vacancyDetail.applyJobdigger.buttonLabel')}
                        className="vacancy-detail-aside__link-button"
                    />
                </Card>
            )}

            <Card className="vacancy-detail-aside__card">
                <h2 className="vacancy-detail-aside__card-title">
                    {trans('containers.vacancyDetail.properties.title')}
                </h2>

                <div className="vacancy-detail-aside__property-wrapper">
                    {properties.map(property => (
                        <div key={property.label} className="vacancy-detail-aside__property">
                            <TagList
                                title={property.label}
                                tags={property.value}
                            />
                        </div>
                    ))}
                </div>
            </Card>
        </aside>
    );
};

export default VacancyDetailAside;
