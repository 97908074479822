/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconInfo: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 16 17" className={`icon ${className}`}>
        <path d="M7.2 5.72129H8.8V4.08664H7.2V5.72129ZM8 14.7119C4.472 14.7119 1.6 11.7777 1.6 8.17328C1.6 4.56886 4.472 1.63466 8 1.63466C11.528 1.63466 14.4 4.56886 14.4 8.17328C14.4 11.7777 11.528 14.7119 8 14.7119ZM8 0C6.94943 0 5.90914 0.211408 4.93853 0.622154C3.96793 1.0329 3.08601 1.63494 2.34315 2.3939C0.842855 3.92668 0 6.00559 0 8.17328C0 10.341 0.842855 12.4199 2.34315 13.9527C3.08601 14.7116 3.96793 15.3137 4.93853 15.7244C5.90914 16.1351 6.94943 16.3466 8 16.3466C10.1217 16.3466 12.1566 15.4854 13.6569 13.9527C15.1571 12.4199 16 10.341 16 8.17328C16 7.09995 15.7931 6.03713 15.391 5.0455C14.989 4.05387 14.3997 3.15286 13.6569 2.3939C12.914 1.63494 12.0321 1.0329 11.0615 0.622154C10.0909 0.211408 9.05058 0 8 0ZM7.2 12.2599H8.8V7.35595H7.2V12.2599Z" />
    </svg>
);

export default IconInfo;
/* eslint-enable max-len */
