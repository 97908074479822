import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, LinkButton } from '../../components';
import { IconName } from '../../components/Icon/Icon';
import { IconButton, LinkIconButton } from '..';

import './ExpandableNavigation.scss';

export interface ExpandableNavigationItem<Key = string> {
    key: Key;
    label: string;
    icon?: IconName;
    to?: string;
    onClick?: () => void;
}

interface ExpendableNavigationButtonProps {
    icon?: IconName;
    text: string;
    tabIndex: 0 | -1;
    className: string;
}

interface ExpandableNavigationProps {
    isActive: boolean;
    name: string;
    navigationItems: ExpandableNavigationItem[];
    onClose?: () => void;
    className?: string;
}

const ExpandableNavigation: FC<ExpandableNavigationProps> = ({
    isActive,
    name,
    navigationItems = [],
    onClose,
    className = '',
}): ReactElement => {
    const navigationClassNames = classNames('expandable-navigation', {
        'expandable-navigation--is-active': isActive,
    }, className);

    const renderLinkButton = (buttonProps: ExpendableNavigationButtonProps, to: string) => (
        buttonProps.icon
            ? <LinkIconButton {...buttonProps} icon={buttonProps.icon} to={to} />
            : <LinkButton {...buttonProps} to={to} />
    );
    const renderButton = (buttonProps: ExpendableNavigationButtonProps, onClick?: () => void) => (
        buttonProps.icon
            ? <IconButton {...buttonProps} icon={buttonProps.icon} onClick={onClick} />
            : <Button {...buttonProps} onClick={onClick} />
    );

    return (
        <nav aria-label={name} className={navigationClassNames}>
            <ul className="expandable-navigation__list">
                {navigationItems.map(navItem => {
                    const buttonProps: ExpendableNavigationButtonProps = {
                        icon: navItem.icon,
                        text: navItem.label,
                        tabIndex: isActive ? 0 : -1,
                        className: 'expandable-navigation__button',
                    };

                    const handleClick = (): void => {
                        if (onClose) onClose();
                        if (navItem.onClick) navItem.onClick();
                    };

                    return (
                        <li key={navItem.key} className="expandable-navigation__item">
                            {navItem.to
                                ? renderLinkButton(buttonProps, navItem.to)
                                : renderButton(buttonProps, handleClick)}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default ExpandableNavigation;
