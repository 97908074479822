import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Button } from '../../components';
import trans from '../../helpers/trans';
import useTimeout from '../../hooks/useTimeout';

import './CopyableValue.scss';

interface CopyableValueProps {
    value: string;
    className?: string;
}

const CopyableValue: FC<CopyableValueProps> = ({
    value,
    className = '',
}): ReactElement => {
    const copyRef = useRef<HTMLDivElement>(null);
    const stateDelay = 2000;

    const [copyButtonLabel, setCopyButtonLabel] = useState<string>(trans('compositions.copyableValue.copy'));
    const [isCopied, setIsCopied] = useState<boolean>(false);

    useTimeout((): void => {
        if (isCopied) {
            setCopyButtonLabel(trans('compositions.copyableValue.copy'));
            setIsCopied(false);
        }
    }, stateDelay, [isCopied]);

    const handleCopy = (): void => {
        const textToCopy = copyRef.current && copyRef.current.textContent;

        if (navigator.clipboard && textToCopy) {
            navigator.clipboard.writeText(textToCopy).then((): void => {
                setCopyButtonLabel(trans('compositions.copyableValue.copied'));
                setIsCopied(true);
            });
        }
    };

    return (
        <div className={`copyable-value ${className}`}>
            <div ref={copyRef}>{value}</div>
            <Button
                text={copyButtonLabel}
                disabled={isCopied}
                onClick={handleCopy}
                className="copyable-value__copy-button"
            />
        </div>
    );
};

export default CopyableValue;
