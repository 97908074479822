import {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import { User } from '../../models/User/User';
import { FormOption } from '../../types';
import { NavigationHeader } from '..';

import './Page.scss';

export interface PageProps {
    candidateIsLoading?: boolean;
    hasNavigation?: boolean;
    candidateOptions?: FormOption[];
    candidateUuid?: string;
    selectedCandidateOption?: FormOption;
    title?: string;
    user?: User;
    onCandidateSelect?: (candidateId: string) => void;
    className?: string;
}

const Page: FC<PropsWithChildren<PageProps>> = ({
    candidateIsLoading = false,
    hasNavigation,
    candidateOptions = [],
    candidateUuid,
    selectedCandidateOption,
    title,
    user,
    onCandidateSelect,
    className = '',
    children,
}): ReactElement => {
    const { height } = useWindowSize();
    const [pageVariables, setPageVariables] = useState<CSSProperties>({});

    useEffect((): void => {
        const variables = {
            ...pageVariables,
            '--page-viewport-height': `${height}px`,
        };

        setPageVariables(variables);
    }, [height]);

    const handleCandidateSelect = (candidateId: string) => {
        if (onCandidateSelect) {
            onCandidateSelect(candidateId);
        }
    };

    const pageClassNames = classNames('page', {
        'page--has-navigation': hasNavigation,
    }, className);

    return (
        <main style={pageVariables} className={pageClassNames}>
            {hasNavigation && (
                <NavigationHeader
                    candidateIsLoading={candidateIsLoading}
                    candidateOptions={candidateOptions}
                    candidateUuid={candidateUuid}
                    selectedCandidateOption={selectedCandidateOption}
                    title={title}
                    user={user}
                    onCandidateSelect={handleCandidateSelect}
                    className="page__navigation-header"
                />
            )}

            {children}
        </main>
    );
};

export default Page;
