import { FC, ReactElement } from 'react';

import { CommunicationStylesCard } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedCommunicationStylesCardProps {
    onRefresh: () => void;
    className?: string;
}

const ConnectedCommunicationStylesCard: FC<ConnectedCommunicationStylesCardProps> = ({
    onRefresh,
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const communicationStyles = useTypedSelector(state => state.skillsPassportReducer.communicationStyles);
    const isUpToDate = useTypedSelector(state => state.skillsPassportReducer.isUpToDate);

    return (
        <CommunicationStylesCard
            isLoading={skillsPassportIsLoading}
            isUpToDate={isUpToDate}
            candidateUuid={candidate?.uuid}
            userRoles={user?.roles}
            communicationStyles={communicationStyles}
            handleRefresh={onRefresh}
            className={className}
        />
    );
};

export default ConnectedCommunicationStylesCard;
