export const zendeskId = 'ze-snippet';

export const initZendesk = (): void => {
    const key = process.env.REACT_APP_ZENDESK_KEY;
    const scriptTag = document.createElement('script');
    const newScript = document.getElementsByTagName('script')[0];

    scriptTag.async = true;
    scriptTag.id = zendeskId;
    scriptTag.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    scriptTag.charset = 'UTF-8';
    scriptTag.setAttribute('crossorigin', '*');

    if (newScript.parentNode) {
        newScript.parentNode.insertBefore(scriptTag, newScript);
    }
};
