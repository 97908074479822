import { objectsAreEqual } from './object';

export const getActiveFilterValues = <T = Record<string, unknown>>(filters: Partial<T>, comparisonFilters: Partial<T>): Partial<T> => {
    const filterEntries = Object.entries(filters);
    let activeFilters = {};

    for (let i = 0; i < filterEntries.length; i += 1) {
        const [key, value] = filterEntries[i];

        const comparisonValue = comparisonFilters[key as keyof T];

        if (Array.isArray(value) && Array.isArray(comparisonValue)) {
            if (value.length !== comparisonValue.length) {
                const sortedValue = [...value].sort();
                const sortedComparisonValue = [...comparisonValue].sort();

                if (sortedValue.every((val, index) => val !== sortedComparisonValue[index])) {
                    activeFilters = {
                        ...activeFilters,
                        [key]: value,
                    };
                }
            }
        } else if (typeof value === 'object') {
            const objectValue = value as Record<string, unknown>;
            const objectComparisonValue = comparisonValue as unknown as Record<string, unknown>;

            if (!objectsAreEqual(objectValue, objectComparisonValue)) {
                activeFilters = {
                    ...activeFilters,
                    [key]: value,
                };
            }
        } else if (value !== comparisonValue) {
            activeFilters = {
                ...activeFilters,
                [key]: value,
            };
        }
    }

    return activeFilters;
};
