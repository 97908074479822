import { DevelopmentPlanWithSkillsFormData } from '../../containers/@forms/DevelopmentPlanWithSkillsForm/DevelopmentPlanWithSkillsForm';
import { generateFullName, splitAliasIntoNameObject } from '../../helpers/user';
import { transformToDevelopmentPlanCompetency } from '../DevelopmentPlanCompetency/DevelopmentPlanCompetencyTransformer';
import { transformToDevelopmentPlanEvaluation } from '../DevelopmentPlanEvaluation/DevelopmentPlanEvaluationTransformer';
import { transformToDevelopmentPlanSkill } from '../DevelopmentPlanSkill/DevelopmentPlanSkillTransformer';
import {
    DevelopmentPlan,
    DevelopmentPlanFormData,
    DevelopmentPlanRequest,
    DevelopmentPlanResource,
    DevelopmentPlanStatus,
    DevelopmentPlanWithSkillsRequest,
} from './DevelopmentPlan';

export const transformToDevelopmentPlan = (resource: DevelopmentPlanResource): DevelopmentPlan => ({
    id: resource.id,
    developmentId: resource.id,
    title: resource.title,
    start: new Date(resource?.start),
    end: new Date(resource?.end),
    createdByName: generateFullName(splitAliasIntoNameObject(resource.createdByName)),
    createdAt: new Date(resource.createdAt),
    updatedAt: new Date(resource.updatedAt),
    status: resource.status as DevelopmentPlanStatus,
    skills: resource.developmentPlanSkills
        ? resource.developmentPlanSkills.map(transformToDevelopmentPlanSkill)
        : [],
    competencies: resource.developmentPlanCompetencies
        ? resource.developmentPlanCompetencies.map(transformToDevelopmentPlanCompetency)
        : [],
    ...(resource.developmentPlanEvaluation && {
        developmentPlanEvaluation: transformToDevelopmentPlanEvaluation(resource.developmentPlanEvaluation),
    }),
});

export const transformToDevelopmentPlanRequest = (formData: DevelopmentPlanFormData): DevelopmentPlanRequest => ({
    ...(formData.id && { id: formData.id }),
    title: formData.title,
    start: formData.start,
    end: formData.end,
});

export const transformToDevelopmentPlanWithSkillsRequest = (
    formData: DevelopmentPlanWithSkillsFormData,
): DevelopmentPlanWithSkillsRequest => ({
    title: formData.title,
    start: formData.start,
    end: formData.end,
    skills: formData.skills.map(skill => ({
        name: skill.label,
        escoSkillId: skill.id,
    })),
    competencies: [],
});
