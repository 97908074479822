import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import trans from '../../../helpers/trans';
import { Competency } from '../../../models/Competencies/Competencies';
import { CompetencySelectedOptions, Loading, SearchInput } from '../..';
import { getRandomExampleOptions, searchCompetencyOptionsOnQuery, sortCompetencyOptions } from './helpers';
import { CompetencyResults } from './subcomponents';

import './CompetencySelector.scss';

interface CompetencySelectorProps {
    isLoading?: boolean;
    value: Competency[];
    options: Competency[];
    error?: string;
    validationError?: string;
    onChange: (competencies: Competency[]) => void;
    className?: string;
}

const CompetencySelector: FC<CompetencySelectorProps> = ({
    isLoading,
    value = [],
    options,
    error = '',
    validationError = '',
    onChange,
    className = '',
}): ReactElement => {
    const defaultResults: Competency[] = [];

    const [placeholderExample, setPlaceholderExample] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Competency[]>(defaultResults);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect((): void => {
        if (options.length > 0 && !placeholderExample) {
            const example = getRandomExampleOptions(options);
            setPlaceholderExample(trans('common.example', { example }));
        }
    }, [options, placeholderExample]);

    const handleSearch = (query: string): void => {
        setSearchQuery(query);

        if (query === '') {
            setSearchResults(defaultResults);
        } else {
            const results = searchCompetencyOptionsOnQuery(options, query);
            setSearchResults(results);
        }
    };

    const handleSelectOption = (selectedOption: Competency): void => {
        const optionExists = value.find(option => option.id === selectedOption.id);

        const updatedSelectedOptions = optionExists
            ? value.filter(option => option.id !== selectedOption.id)
            : [...value, selectedOption];

        const sortedSelectedOptions = sortCompetencyOptions(updatedSelectedOptions);

        onChange(sortedSelectedOptions);
    };

    const handleClearSearch = (): void => {
        if (inputRef.current) {
            inputRef.current.focus();
            setSearchQuery('');
            setSearchResults(defaultResults);
        }
    };

    return (
        <div className={`competency-selector ${className}`}>
            <SearchInput
                ref={inputRef}
                label={trans('compositions.competencySelector.searchLabel')}
                value={searchQuery}
                placeholder={placeholderExample}
                disabled={!!error || isLoading}
                error={error || validationError}
                onChange={handleSearch}
            />

            <CompetencyResults
                searchResults={searchResults}
                selectedOptions={value}
                onSelectOption={handleSelectOption}
                onClearSearch={handleClearSearch}
            />

            {isLoading && (
                <Loading className="competency-selector__loader" />
            )}

            {!isLoading && value.length > 0 && (
                <CompetencySelectedOptions
                    isSelected
                    selectedOptions={value}
                    onSelectOption={handleSelectOption}
                    className="competency-selector__selected-options"
                />
            )}
        </div>
    );
};

export default CompetencySelector;
