import { formatDate } from '../../helpers/date';
import { splitString } from '../../helpers/string';
import trans from '../../helpers/trans';
import { dateIsValid } from '../../helpers/validation';
import { FormOption } from '../../types';
import { EducationFacet, EducationFacetProperty } from '../Educations/Educations';
import { EducationFilterOptions } from './EducationFormOptions';

const transformFacetToFormOption = (facet: EducationFacet): FormOption => ({
    value: String(facet.value),
    label: `${facet.value} (${facet.count})`,
});

const sortStartMoments = (startMoment: FormOption, comparisonStartMoment: FormOption): number => {
    const date = new Date();
    const [year, month] = splitString(startMoment.value, 4).map(Number);

    date.setFullYear(year);
    date.setMonth(month - 1);

    const comparisonDate = new Date();
    const [comparisonYear, comparisonMonth] = splitString(comparisonStartMoment.value, 4).map(Number);

    comparisonDate.setFullYear(comparisonYear);
    comparisonDate.setMonth(comparisonMonth - 1);

    return date.getTime() - comparisonDate.getTime();
};

const convertDaysToDuration = (days: number): string => {
    if (days === 0) return trans('models.educationFilterOptions.durationOneDay');

    if (days % 30 === 0) {
        return trans('models.educationFilterOptions.durationMonths', {
            months: days / 30,
        });
    }

    return trans('models.educationFilterOptions.durationDays', { days });
};

export const transformToFilterOptions = (
    educationFacets: Record<EducationFacetProperty, EducationFacet[]>,
): EducationFilterOptions => {
    const educationLevels = educationFacets.level_nl
        ? educationFacets.level_nl
            .map(transformFacetToFormOption)
            .filter(option => option.value)
            .sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
        : [];

    const fieldsOfStudy = educationFacets.domainName
        ? educationFacets.domainName
            .map(transformFacetToFormOption)
            .filter(option => option.value)
            .sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
        : [];

    const educators = educationFacets.providerName
        ? educationFacets.providerName
            .map(transformFacetToFormOption)
            .filter(option => option.value)
            .sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
        : [];

    const startMoments = educationFacets.startMoments
        ? educationFacets.startMoments
            .map(startMoment => {
                const date = new Date();
                const [year, month] = splitString(String(startMoment.value), 4).map(Number);

                date.setFullYear(year);
                date.setMonth(month - 1);

                return {
                    value: String(startMoment.value),
                    label: dateIsValid(date)
                        ? `${formatDate(date, 'MMMM yyyy')} (${startMoment.count})`
                        : String(startMoment.value),
                };
            })
            .filter(option => option.value)
            .sort(sortStartMoments)
        : [];

    const dayParts = educationFacets.variants_nl
        ? educationFacets.variants_nl
            .map(transformFacetToFormOption)
            .filter(option => option.value)
            .sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
        : [];

    const subsidies = educationFacets.subsidies
        ? educationFacets.subsidies
            .map(transformFacetToFormOption)
            .filter(option => option.value)
            .sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
        : [];

    const locations = educationFacets.locations
        ? educationFacets.locations
            .map(transformFacetToFormOption)
            .filter(option => option.value)
            .sort((option, comparisonOption) => option.label.localeCompare(comparisonOption.label))
        : [];

    const durations = educationFacets.courseDurationInDays
        ? educationFacets.courseDurationInDays
            .map(duration => {
                const readableDuration = convertDaysToDuration(Number(duration.value));

                return {
                    value: String(duration.value),
                    label: `${readableDuration} (${duration.count})`,
                };
            })
            .filter(option => option.value)
            .sort((option, comparisonOption) => Number(option.value) - Number(comparisonOption.value))
        : [];

    return {
        educationLevels,
        fieldsOfStudy,
        educators,
        startMoments,
        dayParts,
        subsidies,
        locations,
        durations,
    };
};
