import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { SafeHtml } from '../../../components';
import { Dialog, DialogContent, IconButton } from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { DevelopmentPlanNote, DevelopmentPlanNoteFormData, DevelopmentPlanNoteType } from '../../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import { User } from '../../../models/User/User';
import { UserRole } from '../../../types';
import { DevelopmentPlanNoteForm } from '../..';
import { DevelopmentPlanNotesSkeletons } from './skeletons';
import { DevelopmentPlanNoteCard, DevelopmentPlanNotesNullState } from './subcomponents';

import './DevelopmentPlanNotes.scss';

interface DevelopmentPlanNotesProps {
    isLoading: boolean;
    isUpdating: boolean;
    isSuccessful: boolean;
    user?: User;
    noteType: DevelopmentPlanNoteType;
    notes: DevelopmentPlanNote[];
    onAddNoteSubmit: (formData: DevelopmentPlanNoteFormData) => void;
    onEditNoteSubmit: (formData: DevelopmentPlanNoteFormData) => void;
    onDeleteNoteSubmit: (noteId: string) => void;
    className?: string;
}

const DevelopmentPlanNotes: FC<DevelopmentPlanNotesProps> = ({
    isLoading,
    isUpdating,
    isSuccessful,
    user,
    noteType,
    notes,
    onAddNoteSubmit,
    onEditNoteSubmit,
    onDeleteNoteSubmit,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [developmentPlanNoteFormKey, setDevelopmentPlanNoteFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setDevelopmentPlanNoteFormKey(developmentPlanNoteFormKey + 1);
    };

    useEffect((): void => {
        if (isSuccessful) handleCloseDialog();
    }, [isSuccessful]);

    const isCandidate = userHasRole(UserRole.candidate, user?.roles);
    const roleKey = isCandidate ? UserRole.candidate : UserRole.counselor;

    return (
        <div className={`development-plan-notes ${className}`}>
            {isLoading && (
                <DevelopmentPlanNotesSkeletons />
            )}

            {!isLoading && notes.length === 0 && (
                <DevelopmentPlanNotesNullState
                    onAddNoteClick={handleOpenDialog}
                    className="development-plan-notes__null-state"
                />
            )}

            {!isLoading && notes.length > 0 && (
                <>
                    <div className="development-plan-notes__header">
                        <IconButton
                            icon="plus"
                            text={trans('containers.developmentPlanNotes.addNote')}
                            onClick={handleOpenDialog}
                            className="development-plan-notes__add-note-button"
                        />

                        {noteType === DevelopmentPlanNoteType.public && (
                            <SafeHtml
                                html={trans(`containers.developmentPlanNotes.publicDisclaimer.${roleKey}`)}
                                className="development-plan-notes__public-disclaimer"
                            />
                        )}
                    </div>

                    <ul className="development-plan-notes__list">
                        {notes.map(note => (
                            <DevelopmentPlanNoteCard
                                key={note.id}
                                isUpdating={isUpdating}
                                isSuccessful={isSuccessful}
                                isAuthor={user?.uuid === note.author?.uuid || noteType === DevelopmentPlanNoteType.private}
                                noteType={noteType}
                                note={note}
                                onEditNoteSubmit={onEditNoteSubmit}
                                onDeleteNoteSubmit={onDeleteNoteSubmit}
                                className="development-plan-notes__list-item"
                            />
                        ))}
                    </ul>
                </>
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent title={trans('containers.developmentPlanNotes.addDialog.title')}>
                    <DevelopmentPlanNoteForm
                        key={developmentPlanNoteFormKey}
                        isLoading={isUpdating}
                        isSuccessful={isSuccessful}
                        onSubmit={onAddNoteSubmit}
                        onCancel={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DevelopmentPlanNotes;
