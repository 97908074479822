import { isNotSSR, localStorageExists } from '.';

interface ThemePreference {
    userPrefersDark: boolean;
    userPrefersLight: boolean;
    userHasPreference: boolean;
}

// Configuration
const darkmodeStorageLabel = 'darkmode-active';
const themeAttribute = 'data-theme';
const nightStartHour = 20;
const nightEndHour = 6;

// Provide information about the preferred theme settings on the user's system
const userThemePreference = (): ThemePreference => {
    const canDetectPreference = isNotSSR && window.matchMedia;
    const userPrefersDark = canDetectPreference && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const userPrefersLight = canDetectPreference && window.matchMedia('(prefers-color-scheme: light)').matches;

    return {
        userHasPreference: userPrefersDark || userPrefersLight || false,
        userPrefersDark: userPrefersDark || false,
        userPrefersLight: userPrefersLight || false,
    };
};

// Retrieve stored darkmode-specific value from the localStorage of the user's browser
const getStoredDarkmode = (): boolean => {
    if (isNotSSR && localStorageExists) {
        return localStorage.getItem(darkmodeStorageLabel) === 'true';
    }

    return false;
};

// Check whether darkmode is active within the app
const checkIfDarkmodeIsActive = (): boolean => {
    const darkmodeInDocumentTheme = isNotSSR && document.documentElement.getAttribute(themeAttribute) === 'dark';
    const darkmodeInStorage = getStoredDarkmode();
    const { userPrefersDark } = userThemePreference();

    return darkmodeInDocumentTheme || darkmodeInStorage || userPrefersDark || false;
};

// Apply site theme, and set it to darkmode if this is requested
const setDarkmode = (state: boolean): void => {
    const darkmodeIsStored = (isNotSSR && localStorageExists) && localStorage.getItem(darkmodeStorageLabel) !== null;
    const storedDarkmodeSetting = getStoredDarkmode();

    const shouldBeDarkmode = darkmodeIsStored ? storedDarkmodeSetting : state;
    const siteTheme = shouldBeDarkmode ? 'dark' : 'default';

    if (isNotSSR) {
        document.documentElement.setAttribute(themeAttribute, siteTheme);
    }
};

// Store darkmode-specific value in the localStorage of the user's browser
const storeDarkmode = (state: boolean): void => {
    if (isNotSSR && localStorageExists) {
        localStorage.setItem(darkmodeStorageLabel, String(state));
    }

    setDarkmode(state);
};

// Check if night has fallen yet
const checkForNight = (): void => {
    const start = nightStartHour;
    const end = nightEndHour;
    const currentHour = new Date().getHours();

    const isNight = end < start
        ? currentHour >= start || currentHour < end
        : currentHour >= start && currentHour < end;

    setDarkmode(isNight);
};

// Applied when darkmode at night is requested: Checks every minute if night has fallen
const applyDarkmodeAtNight = (): void => {
    checkForNight();
    setInterval(checkForNight, 1000 * 60);
};

// Initialize darkmode of application
const initDarkmode = (): void => {
    applyDarkmodeAtNight();
};

export {
    checkIfDarkmodeIsActive,
    initDarkmode,
    setDarkmode,
    storeDarkmode,
    userThemePreference,
};
