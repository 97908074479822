import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultEducationFilterOptions, EducationFilterOptions } from '../../models/EducationFormOptions/EducationFormOptions';
import { defaultEducationFilterValues, Education, EducationFilterValues } from '../../models/Educations/Educations';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState } from '../../types';

export type EducationsState = AsyncReduxState<{
    filterOptions: EducationFilterOptions;
    activeFilterValues: EducationFilterValues;
    educations: Education[];
    pagination?: Pagination;
    shouldResetFilters: boolean;
}>;

const initialState: EducationsState = {
    ...initialAsyncReduxState,
    filterOptions: defaultEducationFilterOptions(),
    activeFilterValues: defaultEducationFilterValues(),
    educations: [],
    pagination: undefined,
    shouldResetFilters: false,
};

export const educationsSlice = createSlice({
    name: 'educationsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): EducationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EducationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFilterOptions(state, action: PayloadAction<EducationFilterOptions>): EducationsState {
            return {
                ...state,
                filterOptions: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<EducationFilterValues>): EducationsState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setEducations(state, action: PayloadAction<Education[]>): EducationsState {
            return {
                ...state,
                educations: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): EducationsState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
        setShouldResetFilters(state, action: PayloadAction<boolean>): EducationsState {
            return {
                ...state,
                shouldResetFilters: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setFilterOptions,
    setActiveFilterValues,
    setEducations,
    setPagination,
    setShouldResetFilters,
} = educationsSlice.actions;

export default educationsSlice.reducer;
