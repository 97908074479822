import {
    FC,
    MouseEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Button, Card } from '../../../../../components';
import { IconButton, SubmitButton } from '../../../../../compositions';
import { getRoleForTranslation } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { DevelopmentPlanCompetency, EditDevelopmentPlanCompetencyFormData } from '../../../../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import { UserRole } from '../../../../../types';
import { DevelopmentPlanCardQuestion } from '..';

import './DevelopmentPlanCompetencyCard.scss';

interface DevelopmentPlanCompetencyCardProps {
    isLoading: boolean;
    isSuccessful: boolean;
    competency: DevelopmentPlanCompetency;
    userRoles: UserRole[];
    onSubmit: (formData: EditDevelopmentPlanCompetencyFormData) => void;
    onDelete: (competency: DevelopmentPlanCompetency) => void;
    className?: string;
}

const DevelopmentPlanCompetencyCard: FC<DevelopmentPlanCompetencyCardProps> = ({
    isLoading,
    isSuccessful,
    competency,
    userRoles,
    onSubmit,
    onDelete,
    className = '',
}): ReactElement => {
    const [hasChanges, setHasChanges] = useState(false);
    const [what, setWhat] = useState<string>(competency.what);
    const [why, setWhy] = useState<string>(competency.why);
    const [when, setWhen] = useState<string>(competency.when);
    const [how, setHow] = useState<string>(competency.how);
    const [goals, setGoals] = useState<string>(competency.goals);
    const [remarks, setRemarks] = useState<string>(competency.remarks);
    const [isCompleted, toggleIsCompleted] = useToggle(competency.isCompleted);

    const formRef = useRef<HTMLFormElement>(null);

    const [isExpanded, toggleIsExpanded] = useToggle(false);
    const [isEditing, toggleIsEditing] = useToggle(false);

    const expandableSectionClasses = classNames('development-plan-competency-card__expandable-section', {
        'development-plan-competency-card__expandable-section--is-expanded': isExpanded,
    });
    const toggleIconClasses = classNames('development-plan-competency-card__toggle-icon', {
        'development-plan-competency-card__toggle-icon--is-expanded': isExpanded,
    });

    const handleSubmit = (): void => {
        const formData = {
            id: competency.id,
            what,
            why,
            when,
            how,
            goals,
            remarks,
            isCompleted,
        };

        onSubmit(formData);
    };

    const handleSubmitButton = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        handleSubmit();
    };

    const handleEdit = (): void => {
        toggleIsExpanded(true);
        toggleIsEditing(true);
    };

    const handleChange = (setFunction: React.Dispatch<React.SetStateAction<string>>) => (value: string): void => {
        setFunction(value);
        setHasChanges(true);
    };

    const handleSubmitIsCompleted = (): void => {
        const formData = {
            id: competency.id,
            what,
            why,
            when,
            how,
            goals,
            remarks,
            isCompleted: !isCompleted,
        };

        onSubmit(formData);
    };

    const handleComplete = (): void => {
        toggleIsCompleted();
        handleSubmitIsCompleted();
    };

    const handleDelete = (): void => {
        onDelete(competency);
    };

    useEffect((): void => {
        if (isSuccessful) {
            setHasChanges(false);
            toggleIsEditing(false);
        }
    }, [isSuccessful]);

    return (
        <Card className={`development-plan-competency-card ${className}`}>
            <form ref={formRef}>
                <div className="development-plan-competency-card__header">
                    <div className="development-plan-competency-card__title-wrapper">
                        <IconButton
                            text={`${trans('common.competency')}: ${competency.name}`}
                            icon="chevron-down"
                            onClick={toggleIsExpanded}
                            disabled={hasChanges}
                            className="development-plan-competency-card__toggle-button"
                            iconClassName={toggleIconClasses}
                        />
                    </div>
                    <div className="development-plan-competency-card__action-buttons">
                        {!isEditing && (
                            <>
                                {!competency.isCompleted && (
                                    <Button
                                        text={trans('containers.developmentPlanOverview.developmentPlanCompetencyCard.complete')}
                                        disabled={isLoading}
                                        onClick={handleComplete}
                                        className="development-plan-competency-card__complete-button"
                                    />
                                )}

                                {competency.isCompleted && (
                                    <IconButton
                                        icon="check"
                                        text={trans('containers.developmentPlanOverview.developmentPlanCompetencyCard.isCompleted')}
                                        disabled={isLoading}
                                        onClick={handleComplete}
                                        className="development-plan-competency-card__complete-button"
                                    />
                                )}

                                <IconButton
                                    icon="edit"
                                    text={trans('common.edit')}
                                    hideLabel
                                    onClick={handleEdit}
                                    className="development-plan-competency-card__action-button"
                                />
                            </>
                        )}

                        {isEditing && (
                            <SubmitButton
                                isLoading={isLoading}
                                text={trans('common.saveChanges')}
                                icon="disk"
                                onClick={handleSubmitButton}
                                className="development-plan-competency-card__save-button"
                            />
                        )}

                        <IconButton
                            icon="bin"
                            text={trans('common.delete')}
                            hideLabel
                            onClick={handleDelete}
                            className="development-plan-competency-card__action-button development-plan-competency-card__action-button--is-delete"
                        />
                    </div>
                </div>
                <div className={expandableSectionClasses}>
                    <DevelopmentPlanCardQuestion
                        isEditing={isEditing}
                        label={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.what.${getRoleForTranslation(userRoles)}`)}
                        placeholder={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.whatPlaceholder.${getRoleForTranslation(userRoles)}`)}
                        value={what}
                        onChange={handleChange(setWhat)}
                        className="development-plan-competency-card__question"
                    />
                    <DevelopmentPlanCardQuestion
                        isEditing={isEditing}
                        label={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.why.${getRoleForTranslation(userRoles)}`)}
                        placeholder={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.whyPlaceholder.${getRoleForTranslation(userRoles)}`)}
                        value={why}
                        onChange={handleChange(setWhy)}
                        className="development-plan-competency-card__question"
                    />
                    <DevelopmentPlanCardQuestion
                        isEditing={isEditing}
                        label={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.when.${getRoleForTranslation(userRoles)}`)}
                        placeholder={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.whenPlaceholder.${getRoleForTranslation(userRoles)}`)}
                        value={when}
                        onChange={handleChange(setWhen)}
                        className="development-plan-competency-card__question"
                    />
                    <DevelopmentPlanCardQuestion
                        isEditing={isEditing}
                        label={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.how.${getRoleForTranslation(userRoles)}`)}
                        placeholder={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.howPlaceholder.${getRoleForTranslation(userRoles)}`)}
                        value={how}
                        onChange={handleChange(setHow)}
                        className="development-plan-competency-card__question"
                    />
                    <DevelopmentPlanCardQuestion
                        isEditing={isEditing}
                        label={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.goals.${getRoleForTranslation(userRoles)}`)}
                        placeholder={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.goalsPlaceholder.${getRoleForTranslation(userRoles)}`)}
                        value={goals}
                        onChange={handleChange(setGoals)}
                        className="development-plan-competency-card__question"
                    />
                    <DevelopmentPlanCardQuestion
                        isEditing={isEditing}
                        label={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.remarks.${getRoleForTranslation(userRoles)}`)}
                        placeholder={trans(`containers.developmentPlanOverview.developmentPlanCompetencyCard.remarksPlaceholder.${getRoleForTranslation(userRoles)}`)}
                        value={remarks}
                        onChange={handleChange(setRemarks)}
                        className="development-plan-competency-card__question"
                    />
                </div>
            </form>
        </Card>
    );
};

export default DevelopmentPlanCompetencyCard;
