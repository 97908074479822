import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Checkbox,
    FormActionButtons,
    Notice,
    TextInput,
} from '../../../compositions';
import { userHasRole } from '../../../helpers/role';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { Counselor, CounselorFormData } from '../../../models/Counselor/Counselor';
import { NoticeType, UserRole } from '../../../types';
import { CounselorFormErrors, validateCounselorFormData } from './validations';

import './CounselorForm.scss';

interface CounselorFormProps {
    isLoading: boolean;
    error: string;
    counselor?: Counselor;
    userRoles?: UserRole[];
    onSubmit: (formData: CounselorFormData) => void;
    onCancel: () => void;
    className?: string;
}

const CounselorForm: FC<CounselorFormProps> = ({
    isLoading,
    error,
    counselor,
    userRoles,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CounselorFormErrors>({});

    const [firstName, setFirstName] = useState<string>('');
    const [lastNamePrefix, setLastNamePrefix] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isOrganisationAdmin, setIsOrganisationAdmin] = useState<boolean>(false);
    const [isTmaExpert, setIsTmaExpert] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: counselor?.id || '',
            firstName,
            lastNamePrefix,
            lastName,
            email,
            isOrganisationAdmin,
            isTmaExpert,
        };

        const [errors, hasErrors] = validateCounselorFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    useEffect((): void => {
        setFirstName(counselor?.firstName || '');
        setLastNamePrefix(counselor?.lastNamePrefix || '');
        setLastName(counselor?.lastName || '');
        setEmail(counselor?.email || '');
        setIsOrganisationAdmin(counselor ? userHasRole(UserRole.organisationAdmin, counselor.roles) : false);
        setIsTmaExpert(counselor ? userHasRole(UserRole.tmaExpert, counselor.roles) : false);
    }, [counselor]);

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`counselor-form ${className}`}>
            <div className="counselor-form__row">
                <TextInput
                    required
                    label={trans('forms.counselor.input.firstName')}
                    value={firstName}
                    error={formErrors.firstName}
                    onChange={setFirstName}
                    className="counselor-form__column"
                />
            </div>

            <div className="counselor-form__row">
                <TextInput
                    label={trans('forms.counselor.input.lastNamePrefix')}
                    value={lastNamePrefix}
                    error={formErrors.lastNamePrefix}
                    onChange={setLastNamePrefix}
                    className="counselor-form__column counselor-form__column--is-last-name-prefix"
                />

                <TextInput
                    required
                    label={trans('forms.counselor.input.lastName')}
                    value={lastName}
                    error={formErrors.lastName}
                    onChange={setLastName}
                    className="counselor-form__column"
                />
            </div>

            <div className="counselor-form__row">
                <TextInput
                    required
                    disabled={!!counselor}
                    label={trans('forms.counselor.input.email')}
                    value={email}
                    error={formErrors.email}
                    onChange={setEmail}
                    className="counselor-form__column"
                />
            </div>

            {userHasRole(UserRole.lloAdmin, userRoles) && (
                <>
                    <div className="counselor-form__row">
                        <Checkbox
                            checked={isOrganisationAdmin}
                            label={trans('forms.counselor.input.organisationAdmin')}
                            onChange={setIsOrganisationAdmin}
                            className="counselor-form__column"
                        />
                    </div>
                    <div className="counselor-form__row">
                        <Checkbox
                            checked={isTmaExpert}
                            label={trans('forms.counselor.input.tmaExpert')}
                            onChange={setIsTmaExpert}
                            className="counselor-form__column"
                        />
                    </div>
                </>
            )}

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="counselor-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="counselor-form__action-buttons"
            />
        </form>
    );
};

export default CounselorForm;
