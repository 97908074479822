/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconStep: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 76.9" className={`icon ${className}`}>
        <path d="M7.7,0v30.8h30.8V0H7.7z M28.2,20.5H17.9V10.3h10.3V20.5z" />
        <rect x="48.7" y="10.3" width="51.3" height="10.3" />
        <rect x="48.7" y="51.3" width="51.3" height="10.3" />
        <polygon points="48.7,46.2 41.5,38.9 17.9,62.5 7.2,51.7 0,59 17.9,76.9 17.9,76.9 17.9,76.9" />
    </svg>
);

export default IconStep;
/* eslint-enable max-len */
