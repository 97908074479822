import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { usePrevious } from 'react-use';

import { VacancyOverview } from '../../../containers';
import { arrayIndexesForPagination } from '../../../helpers/pagination';
import useTimeout from '../../../hooks/useTimeout';
import { defaultVacancyFilterValues } from '../../../models/MatchingVacancies';
import { Vacancy } from '../../../models/Vacancy';
import { setShouldResetFilters } from '../../../redux/matchingVacancies/matchingVacancies';
import { fetchMatchingVacancies } from '../../../redux/matchingVacancies/matchingVacanciesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedVacancyOverviewProps {
    currentPage: number;
    onPaginationChange: (currentPage: number) => void;
    className?: string;
}

const ConnectedVacancyOverview: FC<ConnectedVacancyOverviewProps> = ({
    currentPage,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const stateDelay = 400;

    const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
    const [vacancies, setVacancies] = useState<Vacancy[]>([]);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isLoading = useTypedSelector(state => state.matchingVacanciesReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.matchingVacanciesReducer.activeFilterValues);
    const matchingVacancies = useTypedSelector(state => state.matchingVacanciesReducer.matchingVacancies);
    const pagination = useTypedSelector(state => state.matchingVacanciesReducer.pagination);

    const previousActiveFilterValues = usePrevious(activeFilterValues);

    const handlePaginationChange = (page: number): void => {
        onPaginationChange(page);
        setPageIsLoading(true);
    };

    const handleResetClick = (): void => {
        dispatch(setShouldResetFilters(true));
    };

    useEffect((): void => {
        if (!candidate?.uuid) return;

        const hasSelectedFilters = JSON.stringify(activeFilterValues) !== JSON.stringify(defaultVacancyFilterValues());
        const hasNewFilters = JSON.stringify(activeFilterValues) !== JSON.stringify(previousActiveFilterValues);

        if (hasNewFilters || !hasSelectedFilters) {
            dispatch(fetchMatchingVacancies(candidate.uuid));
        }
    }, [activeFilterValues]);

    useTimeout((): void => {
        const { start, end } = arrayIndexesForPagination(currentPage);

        setVacancies(matchingVacancies.slice(start, end));
        setPageIsLoading(false);
    }, stateDelay, [matchingVacancies, currentPage]);

    return (
        <VacancyOverview
            isLoading={isLoading || pageIsLoading}
            candidate={candidate}
            vacancies={vacancies}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            onResetClick={handleResetClick}
            className={className}
        />
    );
};

export default ConnectedVacancyOverview;
