import { Address, AddressResource } from './Address';

export const transformToAddress = (
    addressResource: AddressResource,
): Address => ({
    city: addressResource.attributes.city,
    street: addressResource.attributes.street,
    postalCode: addressResource.attributes.postalCode,
    latitude: addressResource.attributes.latitude
        ? String(addressResource.attributes.latitude)
        : '',
    longitude: addressResource.attributes.longitude
        ? String(addressResource.attributes.longitude)
        : '',
});
