import { generateFullName } from '../../helpers/user';
import {
    AddDevelopmentPlanNoteRequest,
    DevelopmentPlanNote,
    DevelopmentPlanNoteAuthor,
    DevelopmentPlanNoteAuthorResource,
    DevelopmentPlanNoteFormData,
    DevelopmentPlanNoteResource,
    EditDevelopmentPlanNoteRequest,
} from './DevelopmentPlanNote';

const transformToDevelopmentPlanNoteAuthor = (
    developmentPlanNoteAuthorResource: DevelopmentPlanNoteAuthorResource,
): DevelopmentPlanNoteAuthor => {
    const fullName = generateFullName(developmentPlanNoteAuthorResource);

    return {
        uuid: developmentPlanNoteAuthorResource.id,
        fullName,
    };
};

export const transformToDevelopmentPlanNote = (
    developmentPlanNoteResource: DevelopmentPlanNoteResource,
): DevelopmentPlanNote => {
    const author = developmentPlanNoteResource.author
        ? transformToDevelopmentPlanNoteAuthor(developmentPlanNoteResource.author)
        : undefined;

    return {
        id: developmentPlanNoteResource.id,
        author,
        content: developmentPlanNoteResource.content,
        updatedAt: new Date(developmentPlanNoteResource.updatedAt),
    };
};

export const transformToAddDevelopmentPlanNoteRequest = (
    developmentPlanId: string,
    formData: DevelopmentPlanNoteFormData,
): AddDevelopmentPlanNoteRequest => ({
    developmentPlanId,
    content: formData.content,
});

export const transformToEditDevelopmentPlanNoteRequest = (
    formData: DevelopmentPlanNoteFormData,
): EditDevelopmentPlanNoteRequest => ({
    id: formData.id || '',
    content: formData.content,
});
