import { fetchDevelopmentOverviewApiCall } from '../../models/DevelopmentOverview/DevelopmentOverviewService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import { setDevelopments, setError, setIsLoading } from './developmentOverview';

export const fetchDevelopmentOverview = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const developmentOverviewResponse = await fetchDevelopmentOverviewApiCall(candidateUuid);

        if (!isFetchResultSuccessful(developmentOverviewResponse)) {
            dispatch(setError(developmentOverviewResponse.error));
            return;
        }

        dispatch(setDevelopments(developmentOverviewResponse.data));
    } catch (error) {
        console.error('[fetchDevelopmentOverview]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
