import { Counselor, CounselorResource } from '../Counselor/Counselor';
import { defaultPageSize } from '../Pagination';

export interface CounselorOverviewResource {
    meta: {
        totalAmount: number;
    };
    organisationCounselors: CounselorResource[];
}

export interface CounselorOverview {
    totalAmount: number;
    counselors: Counselor[];
}

export interface CounselorFilterValues {
    pageNumber: number;
    pageSize: number;
}

export const defaultCounselorOverview = (): CounselorOverview => ({
    totalAmount: 0,
    counselors: [],
});

export const defaultCounselorFilterValues = (): CounselorFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
});
