import { SortConfig, SortDirection, SortField } from '../../types';

export interface CandidateRelationResource {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    lastNamePrefix: string;
    dateOfBirth?: string;
    city?: string;
    createdAt: Date;
}

export type CandidateRelationFormOptionResource = Omit<CandidateRelationResource, 'email' | 'dateOfBirth' | 'city' | 'createdAt'>;

export interface CandidateRelation {
    id: string;
    email: string;
    fullName: string;
    dateOfBirth?: Date;
    city?: string;
    createdAt: Date;
}

export interface CandidateRelationsResource {
    totalPages: number;
    candidates: CandidateRelationResource[];
}

export interface CandidateRelations {
    totalPages: number;
    candidates: CandidateRelation[];
}

export interface CandidateRelationsConfig {
    pageNumber: number;
    pageSize: number;
    sortConfig: SortConfig;
    searchQuery: string;
}

export const defaultCandidateRelationsConfig = (): CandidateRelationsConfig => ({
    pageNumber: 1,
    pageSize: 25,
    sortConfig: {
        field: SortField.createdAt,
        direction: SortDirection.desc,
    },
    searchQuery: '',
});
