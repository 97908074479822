import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { OrganisationOccupationFilterValues, OrganisationOccupationOverview, OrganisationOccupationOverviewResource } from './OrganisationOccupationOverview';
import { transformToOrganisationOccupationOverview } from './OrganisationOccupationOverviewTransformers';

export const getOrganisationOccupationOverviewApiCall = async (
    organisationUuid: string,
    activeFilterValues: OrganisationOccupationFilterValues,
): Promise<FetchResult<OrganisationOccupationOverview, string>> => {
    try {
        const { pageNumber, pageSize } = activeFilterValues;

        const response = await lloFetch(`/api/internalOccupations/${organisationUuid}?pageNumber=${pageNumber}&pageSize=${pageSize}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationOccupationOverviewResponse: OrganisationOccupationOverviewResource = await response.json();

        const organisationOccupationOverview = transformToOrganisationOccupationOverview(organisationOccupationOverviewResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisationOccupationOverview,
        };
    } catch (error) {
        console.error('[getOrganisationOccupationOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
