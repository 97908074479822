/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconChevronUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 61.7" className={`icon ${className}`}>
        <path d="M88.2,61.7L50,23.6L11.8,61.7L0,50L50,0l50,50L88.2,61.7z" />
    </svg>
);

export default IconChevronUp;
/* eslint-enable max-len */
