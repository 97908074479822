import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { CircleProgress } from '../../../components';
import { OccupationCardView } from '../../../containers/@occupation/OccupationOverview/OccupationOverview';
import { trimToMaxLength } from '../../../helpers/string';
import { Occupation } from '../../../models/Occupations/Occupations';
import { RoutePaths } from '../../../Routes';
import { FloatingCard } from '../..';

import './OccupationCard.scss';

interface OccupationCardProps {
    occupationIsInternal?: boolean;
    candidateUuid?: string;
    view: OccupationCardView;
    occupation: Occupation;
    className?: string;
}

const OccupationCard: FC<OccupationCardProps> = ({
    occupationIsInternal,
    candidateUuid = '',
    view,
    occupation,
    className = '',
}): ReactElement => {
    const internalOccupationPercentageTotal = 100;

    return (
        <FloatingCard
            className={`occupation-card ${className}`}
            cardClassName="occupation-card__card"
        >
            <div className="occupation-card__text">
                <Link
                    to={occupationIsInternal
                        ? RoutePaths.internalOccupationDetail(candidateUuid, occupation.id)
                        : RoutePaths.occupationDetail(candidateUuid, occupation.id)}
                    className="occupation-card__link"
                >
                    <h3 className="occupation-card__title">{occupation.name}</h3>
                </Link>
                <p className="occupation-card__description">{trimToMaxLength(occupation.description, 160)}</p>
            </div>

            <div className="occupation-card__matched-skills">
                <CircleProgress
                    showPercentage={view === OccupationCardView.percentage}
                    showSteps={view === OccupationCardView.skills}
                    icon="occupation"
                    finishedSteps={occupationIsInternal ? occupation.matchedPercentage : occupation.matchedSkills}
                    totalSteps={occupationIsInternal ? internalOccupationPercentageTotal : occupation.totalSkills}
                    className="occupation-card__progress-circle"
                />
            </div>
        </FloatingCard>
    );
};

export default OccupationCard;
