import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { User } from '../../../models/User/User';
import { UserRole } from '../../../types';

export const getDashboardTitle = (user?: User, candidate?: Candidate): string => {
    if (userHasRole(UserRole.counselor, user?.roles) && candidate) {
        return trans('pages.dashboard.title.counselorCandidate', { name: candidate.firstName });
    }

    if (user) {
        return trans(`pages.dashboard.title.${getRoleForTranslation(user.roles)}`, { name: user.firstName });
    }

    return '';
};
