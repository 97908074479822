import { FC, ReactElement } from 'react';

import { ErrorLabel, Icon, LoadingSpinner } from '../../components';
import { IconButton } from '../../compositions';
import trans from '../../helpers/trans';

import './CandidateExchange.scss';

interface CandidateExchangeProps {
    isLoading: boolean;
    isSuccessful: boolean;
    error?: string;
    newCounselorFullName: string;
    onFinishExchangeClick: () => void;
    className?: string;
}

const CandidateExchange: FC<CandidateExchangeProps> = ({
    isLoading,
    isSuccessful,
    error = '',
    newCounselorFullName,
    onFinishExchangeClick,
    className = '',
}): ReactElement => (
    <div className={`candidate-exchange ${className}`}>
        <div className="candidate-exchange__status">
            {isLoading && <LoadingSpinner className="candidate-exchange__loading-spinner" />}

            {isSuccessful && (
                <div className="candidate-exchange__success">
                    <Icon name="check" className="candidate-exchange__success-icon" />
                </div>
            )}

            {error && (
                <div className="candidate-exchange__error">
                    <Icon name="cross" className="candidate-exchange__error-icon" />
                </div>
            )}
        </div>

        <div className="candidate-exchange__result-wrapper">
            {isSuccessful && (
                <p className="candidate-exchange__success-message">
                    {trans('containers.candidateExchange.successMessage', {
                        newCounselorFullName,
                    })}
                </p>
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="candidate-exchange__error-label"
                />
            )}

            {!isLoading && (
                <IconButton
                    icon="arrow-right"
                    text={trans('containers.candidateExchange.buttonLabel')}
                    onClick={onFinishExchangeClick}
                    className="candidate-exchange__action-button"
                />
            )}
        </div>
    </div>
);

export default CandidateExchange;
