import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';

import './ProfileInformationCard.scss';

interface ProfileInformationCardProps {
    isLoading: boolean;
    userName?: string;
    location?: string;
    className?: string;
}

const ProfileInformationCard: FC<ProfileInformationCardProps> = ({
    isLoading,
    userName,
    location,
    className = '',
}): ReactElement => (
    <Card className={`profile-information-card ${className}`}>
        {isLoading && (
            <div className="profile-information-card__skeleton-wrapper">
                <Skeleton className="profile-information-card__skeleton" />
                <Skeleton className="profile-information-card__skeleton" />
            </div>
        )}

        {!isLoading && (
            <div className="profile-information-card__text-wrapper">
                <h2 className="profile-information-card__user-name">
                    {userName}
                </h2>

                {location && (
                    <p className="profile-information-card__location">
                        {location}
                    </p>
                )}
            </div>
        )}
    </Card>
);

export default ProfileInformationCard;
