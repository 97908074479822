import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { FormActionButtons, SearchableValueInput } from '../../../compositions';
import { defaultFormOption } from '../../../constants';
import trans from '../../../helpers/trans';
import { AddDevelopmentPlanSkillFormData } from '../../../models/DevelopmentPlanSkill/DevelopmentPlanSkill';
import { DialogFormProps, FormOption } from '../../../types';

import './DevelopmentPlanSkillForm.scss';

interface DevelopmentPlanSkillFormProps extends DialogFormProps<AddDevelopmentPlanSkillFormData> {
    isLoading: boolean;
    isLoadingSkills: boolean;
    skillOptions: FormOption[];
    developmentPlanId: string;
    onCancel: () => void;
    onSubmit: (formData: AddDevelopmentPlanSkillFormData) => void;
    className?: string;
}

const DevelopmentPlanSkillForm: FC<DevelopmentPlanSkillFormProps> = ({
    isLoading,
    skillOptions,
    developmentPlanId,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [selectedSkill, setSelectedSkill] = useState<FormOption>(defaultFormOption());

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData: AddDevelopmentPlanSkillFormData = {
            name: selectedSkill?.label || '',
            escoSkillId: selectedSkill?.value || '',
            developmentPlanId,
        };

        onSubmit(formData);
    };

    const handleSkillChange = (skillOption: FormOption): void => {
        setSelectedSkill(skillOption);
    };

    return (
        <form onSubmit={handleSubmit} className={`development-plan-skill-form ${className}`}>
            <SearchableValueInput
                isSearchable
                label={trans('forms.developmentPlanSkillForm.input.skill')}
                placeholder={trans('forms.developmentPlanSkillForm.placeholder.skill')}
                name="skill"
                options={skillOptions}
                value={selectedSkill?.label}
                resultLimit={15}
                onChange={handleSkillChange}
                className="development-plan-skill-form__selector"
            />

            <FormActionButtons
                isLoading={isLoading}
                disabled={selectedSkill.value === ''}
                onCancelClick={onCancel}
                className="development-plan-skill-form__action-buttons"
            />
        </form>
    );
};

export default DevelopmentPlanSkillForm;
