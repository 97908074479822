import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface JobTypeResource extends Resource {
    id: string;
    type: 'job-types';
    attributes: {
        slug: string;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
}

export type JobType = FormOption;

export const transformResourceToJobType = (
    jobTypeResource: JobTypeResource,
): JobType => ({
    value: jobTypeResource.id,
    label: jobTypeResource.attributes.name,
});

export const transformJobTypeToFormOption = (
    jobTypeOption: JobType,
): FormOption => ({
    value: jobTypeOption.value,
    label: jobTypeOption.label,
});
