import { Action } from 'redux';

import trans from '../../helpers/trans';
import { CounselorFormData } from '../../models/Counselor/Counselor';
import { patchCounselorApiCall, postCounselorApiCall } from '../../models/Counselor/CounselorService';
import { transformToAddCounselorRequest, transformToEditCounselorRequest } from '../../models/Counselor/CounselorTransformers';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import {
    setCounselor,
    setError,
    setIsSuccessful,
    setIsUpdating,
} from './counselor';

export const createCounselor = (formData: CounselorFormData, organisationId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const addCounselorRequest = transformToAddCounselorRequest(formData, organisationId);

        const counselorResponse = await postCounselorApiCall(addCounselorRequest);

        if (!isFetchResultSuccessful(counselorResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setCounselor(counselorResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createCounselor]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const updateCounselor = (formData: CounselorFormData, organisationId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const editCounselorRequest = transformToEditCounselorRequest(formData, organisationId);

        const counselorResponse = await patchCounselorApiCall(editCounselorRequest);

        if (!isFetchResultSuccessful(counselorResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setCounselor(counselorResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[updateCounselor]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const finishCreateCounselor = () => (dispatch: TypedDispatch): Action => (
    dispatch(setIsSuccessful(false))
);
