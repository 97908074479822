import { FC, ReactElement } from 'react';

import { SkillsPassportTabs } from '../../../containers';
import { setIsUpToDate } from '../../../redux/skillsPassport/skillsPassport';
import { fetchSkillsPassport, importSkillsPassport } from '../../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedSkillsPassportTabsProps {
    className?: string;
}

const ConnectedSkillsPassportTabs: FC<ConnectedSkillsPassportTabsProps> = ({ className }): ReactElement => {
    const dispatch = useTypedDispatch();

    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const tmaAnalysisSettings = useTypedSelector(state => state.tmaAnalysisSettingsReducer.tmaAnalysisSettings);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skillsPassport = useTypedSelector(state => state.skillsPassportReducer);

    const handleRefresh = (): void => {
        dispatch(setIsUpToDate(true));

        if (candidate) {
            dispatch(fetchSkillsPassport(candidate.uuid));

            if (!tmaAnalysisSettings?.isFinished) dispatch(importSkillsPassport(candidate.uuid));
        }
    };

    return (
        <SkillsPassportTabs
            isLoading={skillsPassportIsLoading}
            user={user}
            skillsPassport={skillsPassport}
            onRefresh={handleRefresh}
            className={className}
        />
    );
};

export default ConnectedSkillsPassportTabs;
