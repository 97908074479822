import { getCompetenciesApiCall } from '../../models/Competencies/CompetenciesService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import { setCompetencies, setError, setIsLoading } from './competencies';

export const fetchCompetencies = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const competenciesResponse = await getCompetenciesApiCall();

        if (!isFetchResultSuccessful(competenciesResponse)) {
            dispatch(setError(competenciesResponse.error));
            return;
        }

        dispatch(setCompetencies(competenciesResponse.data));
    } catch (error) {
        console.error('[fetchCompetencies]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
