import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { LearningPath, LearningPathResource, transformResourceToLearningPath } from '../models/LearningPaths';

export const getLearningPathsApiCall = async (): Promise<FetchResult<LearningPath[], string>> => {
    try {
        const includes = ['educationLevel'].join(',');
        const response = await talentzFetch(`/api/learning-paths?include=${includes}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<LearningPathResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const existingLearningPaths = doc.data.filter((learningPath: LearningPathResource) => !learningPath?.attributes?.manual);
        const learningPaths = existingLearningPaths.map(transformResourceToLearningPath);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: learningPaths,
        };
    } catch (error) {
        console.error('[getLearningPathsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
