import { OrganisationOverviewItem, OrganisationOverviewItemResource } from '../OrganisationOverviewItem/OrganisationOverviewItem';
import { defaultPageSize } from '../Pagination';

export interface OrganisationOverviewResource {
    meta: {
        totalAmount: number;
    };
    organisations: OrganisationOverviewItemResource[];
}

export interface OrganisationOverview {
    totalAmount: number;
    organisations: OrganisationOverviewItem[];
}

export interface OrganisationFilterValues {
    pageNumber: number;
    pageSize: number;
}

export const defaultOrganisationOverview = (): OrganisationOverview => ({
    totalAmount: 0,
    organisations: [],
});

export const defaultOrganisationFilterValues = (): OrganisationFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
});
