import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type DevelopmentPlanCompetencyState = AsyncReduxState<{
    updatingCompetencyUuid: string;
}>;

const initialState: DevelopmentPlanCompetencyState = {
    ...initialAsyncReduxState,
    updatingCompetencyUuid: '',
};

export const developmentPlanCompetencySlice = createSlice({
    name: 'developmentPlanCompetencyReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DevelopmentPlanCompetencyState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setUpdatingCompetencyUuid(state, action: PayloadAction<string>): DevelopmentPlanCompetencyState {
            return {
                ...state,
                updatingCompetencyUuid: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DevelopmentPlanCompetencyState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DevelopmentPlanCompetencyState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setIsSuccessful,
    setUpdatingCompetencyUuid,
} = developmentPlanCompetencySlice.actions;

export default developmentPlanCompetencySlice.reducer;
