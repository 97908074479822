import { amountOfOccupations } from '../../constants';
import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { SearchableOption } from '../../types';
import { FetchResult, FetchResultType } from '../FetchResult';
import { OccupationFilterValues, OccupationOverview, OccupationOverviewResource } from './OccupationOverview';
import { transformSuggestionToSearchableOption, transformToOccupationOverview } from './OccupationOverviewTransformers';

export const getOccupationOverviewApiCall = async (candidateUuid: string, skills: string[], activeFilterValues: OccupationFilterValues): Promise<FetchResult<OccupationOverview, string>> => {
    try {
        const response = await lloFetch(`/api/escoOccupation?amountOfOccupations=${amountOfOccupations}`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify({
                search: activeFilterValues.searchQuery,
                skillsToMatch: skills,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const occupationOverviewResponse: OccupationOverviewResource = await response.json();

        const occupationOverview = transformToOccupationOverview(occupationOverviewResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: occupationOverview,
        };
    } catch (error) {
        console.error('[getOccupationOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getOccupationSuggestionsApiCall = async (candidateUuid: string, query: string): Promise<FetchResult<SearchableOption[], string>> => {
    try {
        const response = await lloFetch(`/api/escoOccupation/suggestions/${query.toLowerCase()}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const occupationSuggestionsResponse: string[] = await response.json();

        const occupationSuggestions = occupationSuggestionsResponse.map(transformSuggestionToSearchableOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: occupationSuggestions,
        };
    } catch (error) {
        console.error('[getOccupationSuggestionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
