import { talentzJobVacancyUrl, talentzStorageUrl } from '../constants';
import { isValidDateString } from '../helpers/date';
import { sumCompensation, sumEmploymentHourRange } from '../helpers/vacancy';

export enum VacancyOrigin {
    matchingService = 'matchingService',
    jobdigger = 'jobdigger',
}

export enum VacancyType {
    internship = 'internship',
    job = 'job',
}

export enum SalaryTimeframe {
    hour = 'hour',
    month = 'month',
}

export interface VacancyResource {
    uuid: string;
    title: string;
    description: string;
    company: {
        uuid: string;
        name: string;
        address: {
            city: string;
            postalCode: string;
        };
        logo: string | null;
        coverPhoto: string | null;
        coverVideo: string | null;
        amountOfEmployees: string;
    };
    publicationDate: string;
    slug: string;
    grossHourlyWage: {
        min: number;
        max: number;
    };
    minimumCompensation: number;
    maximumCompensation: number;
    isAnonymous: boolean;
    sector: string | null;
    educationLevel: string[];
    educationGrade: string[];
    creboNumber: number[];
    sbbApproval: boolean;
    type: string;
    subType: string;
    employment: string | null;
    employmentHourRange: {
        min: number;
        max: number;
    };
    remoteWorkingOptions: string[];
    workRemoteSlug: string;
    fieldsOfStudy: string[];
    skills: string[];
    skillsToLearn: string[];
    competencies: string[];
    competenciesToLearn: string[];
    periodFrom: string;
    periodTill: string;
    address: {
        city: string;
        street: string;
        houseNumber: string;
        houseNumberAddition: string | null;
        province: string;
        country: string;
        latitude: string;
        longitude: string;
        postalCode: string;
    };
}

export interface VacancyProperties {
    jobType: string;
    amountOfEmployees: string;
    educationLevels: string[];
    employmentHourRange: string;
    compensation: string;
    sector: string;
    workRemotePreference: string[];
}

export interface Vacancy {
    isAnonymous: boolean;
    origin: VacancyOrigin;
    uuid: string;
    title: string;
    description: string;
    companyName: string;
    logo: string;
    city: string;
    coverImage?: string;
    coverVideo?: string;
    publicationDate: Date;
    externalUrl: string;
    skills: string[];
    competencies: string[];
    fieldsOfStudy: string[];
    properties: VacancyProperties;
}

export const transformToVacancy = (
    vacancyResource: VacancyResource,
): Vacancy => {
    const logo = vacancyResource.company.logo
        ? `${talentzStorageUrl}/logos/${vacancyResource.company.logo}`
        : '';

    const coverImage = vacancyResource.company.coverPhoto
        ? `${talentzStorageUrl}/cover-images/${vacancyResource.company.coverPhoto}`
        : '';

    const publicationDate = isValidDateString(vacancyResource.publicationDate)
        ? new Date(vacancyResource.publicationDate)
        : new Date();

    const employmentHourRange = vacancyResource.employmentHourRange
        ? sumEmploymentHourRange(vacancyResource.employmentHourRange)
        : '';

    const compensation = vacancyResource.grossHourlyWage.max
        ? sumCompensation(SalaryTimeframe.hour, vacancyResource.grossHourlyWage)
        : sumCompensation(SalaryTimeframe.month, {
            min: vacancyResource.minimumCompensation,
            max: vacancyResource.maximumCompensation,
        });

    return {
        isAnonymous: vacancyResource.isAnonymous,
        uuid: vacancyResource.uuid,
        origin: VacancyOrigin.matchingService,
        title: vacancyResource.title,
        description: vacancyResource.description,
        companyName: vacancyResource.company.name,
        logo,
        city: vacancyResource.address.city,
        coverImage,
        coverVideo: vacancyResource.company.coverVideo || '',
        publicationDate,
        externalUrl: talentzJobVacancyUrl(vacancyResource.uuid),
        skills: vacancyResource.skills,
        competencies: vacancyResource.competencies,
        fieldsOfStudy: vacancyResource.fieldsOfStudy,
        properties: {
            jobType: vacancyResource.subType,
            amountOfEmployees: vacancyResource.company.amountOfEmployees,
            educationLevels: vacancyResource.educationLevel,
            employmentHourRange,
            sector: vacancyResource.sector || '',
            compensation,
            workRemotePreference: vacancyResource.remoteWorkingOptions,
        },
    };
};
