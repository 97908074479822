import { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { Competency } from '../../models/Competencies/Competencies';
import { CompetencyOption } from '..';

import './CompetencySelectedOptions.scss';

interface CompetencySelectedOptionsProps {
    isSelected?: boolean;
    title?: string;
    selectedOptions: Competency[];
    onSelectOption: (option: Competency) => void;
    className?: string;
}

const CompetencySelectedOptions: FC<CompetencySelectedOptionsProps> = ({
    isSelected,
    title = trans('compositions.competencySelector.selected'),
    selectedOptions,
    onSelectOption,
    className = '',
}): ReactElement => (
    <div className={`competency-selected-options ${className}`}>
        <h2 className="competency-selected-options__title">
            {title}
        </h2>

        <ul className="competency-selected-options__list">
            {selectedOptions.map(option => (
                <CompetencyOption
                    key={option.id}
                    isSelected={isSelected}
                    option={option}
                    selectedOptions={selectedOptions}
                    onSelectOption={onSelectOption}
                    className="competency-selected-options__item"
                />
            ))}
        </ul>
    </div>
);

export default CompetencySelectedOptions;
