import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getImportSkillsPassport, getSkillsPassportApiCall } from '../../models/SkillsPassport/SkillsPassportService';
import { TypedDispatch } from '../store';
import {
    setCommunicationStyles,
    setCompetencies,
    setConsistencyScore,
    setError,
    setIncentives,
    setIsLoading,
    setJobExperiences,
    setLearningStyle,
    setPersonalityDescription,
    setSchoolExperiences,
    setSkills,
    setTalents,
    setUpdatedAt,
    setWorkPlacePreferences,
} from './skillsPassport';

export const fetchSkillsPassport = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const skillsPassportResponse = await getSkillsPassportApiCall(candidateUuid);

        if (!isFetchResultSuccessful(skillsPassportResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const {
            communicationStyles,
            workplacePreferences,
            incentives,
            learningStyle,
            competencies,
            consistencyScore,
            personalityDescription,
            talents,
            skills,
            jobExperiences,
            schoolExperiences,
        } = skillsPassportResponse.data;

        dispatch(setCommunicationStyles(communicationStyles));
        dispatch(setWorkPlacePreferences(workplacePreferences));
        dispatch(setIncentives(incentives));
        dispatch(setLearningStyle(learningStyle));
        dispatch(setCompetencies(competencies));
        dispatch(setConsistencyScore(consistencyScore));
        dispatch(setPersonalityDescription(personalityDescription));
        dispatch(setTalents(talents));
        dispatch(setSkills(skills));
        dispatch(setJobExperiences(jobExperiences));
        dispatch(setSchoolExperiences(schoolExperiences));
        dispatch(setUpdatedAt(new Date()));
    } catch (error) {
        console.error('[fetchSkillsPassport]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

// This is an API call to start the back-end process of filling the database with skills passport data
export const importSkillsPassport = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const skillsPassportImportResponse = await getImportSkillsPassport(candidateUuid);

        if (!isFetchResultSuccessful(skillsPassportImportResponse)) {
            // A 404 status means that there is 'no analysis available'
            // This is an expected result for which we don't want to return an error
            if (skillsPassportImportResponse.status === 404) return;

            dispatch(setError(trans('errors.unknownError')));
        }

        dispatch(setUpdatedAt(new Date()));
    } catch (error) {
        console.error('[importSkillsPassport]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
