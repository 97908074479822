import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Candidate, CandidateCounselor, CreateCandidateRequest } from './Candidate';
import { transformToCandidate, transformToCandidateCounselor } from './CandidateTransformers';

export const getCandidateApiCall = async (candidateUuid: string): Promise<FetchResult<Candidate, string>> => {
    try {
        const response = await lloFetch(`/api/userInfo/${candidateUuid}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidateResource = await response.json();
        const candidate = transformToCandidate(candidateResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidate,
        };
    } catch (error) {
        console.error('[getCandidateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postCandidateApiCall = async (candidateRequest: Candidate): Promise<FetchResult<Candidate, string>> => {
    try {
        const response = await lloFetch(`/api/userInfo/${candidateRequest.uuid}`, {
            method: 'POST',
            candidateUuid: candidateRequest.uuid,
            body: JSON.stringify(candidateRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidateResource = await response.json();
        const candidate = transformToCandidate(candidateResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidate,
        };
    } catch (error) {
        console.error('[postCandidateApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postCandidateInviteApiCall = async (createCandidateRequest: CreateCandidateRequest): Promise<FetchResult<ResponseType, string>> => {
    try {
        const response = await lloFetch('/api/user/candidate/organisation', {
            method: 'POST',
            body: JSON.stringify(createCandidateRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[postCandidateInviteApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getCandidateCounselorApiCall = async (candidateUuid: string): Promise<FetchResult<CandidateCounselor, string>> => {
    try {
        const response = await lloFetch('/api/counselor', {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidateCounselorResource = await response.json();
        const candidateCounselor = transformToCandidateCounselor(candidateCounselorResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidateCounselor,
        };
    } catch (error) {
        console.error('[getCandidateCounselorApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
