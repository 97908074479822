import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { v4 as generateUuid } from 'uuid';

import { IconButton, SkillsPassportCard } from '../../../compositions';
import { CandidateIllustrationNotification } from '../../../compositions/CandidateIllustration/CandidateIllustration';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

import './WorkplacePreferencesCard.scss';

interface WorkplacePreferencesCardProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    candidate?: Candidate;
    userRoles?: UserRole[];
    workplacePreferences: string[];
    handleRefresh: () => void;
    className?: string;
}

const WorkplacePreferencesCard: FC<WorkplacePreferencesCardProps> = ({
    isLoading,
    isUpToDate,
    candidate,
    userRoles = [],
    workplacePreferences,
    handleRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const [isExpanded, toggleIsExpanded] = useToggle(false);

    const hasPreferences = workplacePreferences.length > 0;

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidate?.uuid));
    };

    const collapseButtonLabel = isExpanded
        ? trans('containers.workplacePreferencesCard.hideFullDescription')
        : trans('containers.workplacePreferencesCard.showFullDescription');

    const preferencesWrapperClassNames = classNames('workplace-preferences-card__description', {
        'workplace-preferences-card__description--is-expanded': isExpanded,
    });

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={!hasPreferences}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            title={trans('containers.workplacePreferencesCard.title')}
            description={userRoles.length > 0 ? trans(`containers.workplacePreferencesCard.description.${getRoleForTranslation(userRoles)}`) : ''}
            candidateGender={candidate?.gender}
            candidateIllustrationType={CandidateIllustrationNotification.workplacePreferences}
            nullStateText={trans('containers.workplacePreferencesCard.nullState')}
            callToActionButtonLabel={trans('containers.workplacePreferencesCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={handleRefresh}
            className={`workplace-preferences-card ${className}`}
        >
            {(!isLoading && hasPreferences) && (
                <>
                    <div className={preferencesWrapperClassNames}>
                        {workplacePreferences.map(preference => {
                            const keyUuid = generateUuid();

                            return (
                                <p key={keyUuid} className="workplace-preferences-card__paragraph">
                                    {preference}
                                </p>
                            );
                        })}
                    </div>

                    {workplacePreferences.length > 3 && (
                        <IconButton
                            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                            iconPos="right"
                            text={collapseButtonLabel}
                            onClick={toggleIsExpanded}
                            className="workplace-preferences-card__collapse-button"
                        />
                    )}
                </>
            )}
        </SkillsPassportCard>
    );
};

export default WorkplacePreferencesCard;
