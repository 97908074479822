import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Card } from '../../../../../components';
import {
    ConfirmDialog,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../../compositions';
import { ConfirmType } from '../../../../../compositions/ConfirmDialog/ConfirmDialog';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { OrganisationOccupation, OrganisationOccupationFormData } from '../../../../../models/OrganisationOccupation/OrganisationOccupation';
import OrganisationOccupationForm from '../../../../@forms/OrganisationOccupationForm/OrganisationOccupationForm';

import './OccupationHeaderCard.scss';

interface OccupationHeaderCardProps {
    isUpdating: boolean;
    isSuccessful: boolean;
    formError: string;
    occupation: OrganisationOccupation;
    onOccupationFormSubmit: (formData: OrganisationOccupationFormData) => void;
    onOccupationDeleteSubmit: (occupation: OrganisationOccupation) => void;
    className?: string;
}

const OccupationHeaderCard: FC<OccupationHeaderCardProps> = ({
    isUpdating,
    isSuccessful,
    formError,
    occupation,
    onOccupationFormSubmit,
    onOccupationDeleteSubmit,
    className = '',
}): ReactElement => {
    const editDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDialogRef = useRef<HTMLDialogElement>(null);

    const [organisationOccupationFormKey, setOrganisationOccupationFormKey] = useState<number>(0);

    const handleOpenEditDialog = (): void => openDialog(editDialogRef);
    const handleCloseEditDialog = (): void => {
        closeDialog(editDialogRef);

        setOrganisationOccupationFormKey(organisationOccupationFormKey + 1);
    };

    const handleOpenDeleteDialog = (): void => openDialog(deleteDialogRef);
    const handleCloseDeleteDialog = (): void => closeDialog(deleteDialogRef);

    const handleOccupationDeleteSubmit = (): void => {
        onOccupationDeleteSubmit(occupation);
    };

    useEffect((): void => {
        if (isSuccessful) {
            handleCloseEditDialog();
            handleCloseDeleteDialog();
        }
    }, [isSuccessful]);

    return (
        <Card className={`occupation-header-card ${className}`}>
            <div className="occupation-header-card__content-wrapper">
                <h3 className="occupation-header-card__title">{occupation.title}</h3>
                <p className="occupation-header-card__description">{occupation.description}</p>
            </div>

            <div className="occupation-header-card__action-buttons">
                <IconButton
                    hideLabel
                    icon="edit"
                    text={trans('containers.organisationDetailOccupation.actions.editOccupation')}
                    onClick={handleOpenEditDialog}
                    className="occupation-header-card__action-button"
                />

                <IconButton
                    hideLabel
                    icon="bin"
                    text={trans('containers.organisationDetailOccupation.actions.deleteOccupation')}
                    onClick={handleOpenDeleteDialog}
                    className="occupation-header-card__action-button"
                />
            </div>

            <Dialog ref={editDialogRef} onClose={handleCloseEditDialog}>
                <DialogContent title={trans('containers.organisationDetailOccupation.editDialog.title')}>
                    <OrganisationOccupationForm
                        key={organisationOccupationFormKey}
                        isLoading={isUpdating}
                        error={formError}
                        occupation={occupation}
                        onSubmit={onOccupationFormSubmit}
                        onCancel={handleCloseEditDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDialogRef}
                onClose={handleCloseDeleteDialog}
            >
                <ConfirmDialog
                    isLoading={isUpdating}
                    error={formError}
                    title={trans('containers.organisationDetailOccupation.deleteDialog.title')}
                    description={trans('containers.organisationDetailOccupation.deleteDialog.description', {
                        occupationTitle: occupation.title,
                    })}
                    submitIcon="bin"
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onConfirm={handleOccupationDeleteSubmit}
                    onCancel={handleCloseDeleteDialog}
                />
            </Dialog>
        </Card>
    );
};

export default OccupationHeaderCard;
