import { FC, ReactElement } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Notice } from '../../compositions';
import { userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { User } from '../../models/User/User';
import { useTypedSelector } from '../../redux/store';
import { RoutePaths } from '../../Routes';
import { NoticeType, UserRole } from '../../types';
import { ConnectedCounselorDashboard } from '..';

interface ConnectedDashboardProps {
    onUserResponse?: (user: User) => void;
    className?: string;
}

const ConnectedDashboard: FC<ConnectedDashboardProps> = ({
    onUserResponse,
    className = '',
}): ReactElement | null => {
    const navigate = useNavigate();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const user = useTypedSelector(state => state.userReducer.user);
    const userError = useTypedSelector(state => state.userReducer.error);

    const handleLogout = (): void => navigate(RoutePaths.logout());

    if (!user && userError) {
        return (
            <Notice
                type={NoticeType.error}
                text={userError}
                buttonLabel={trans('common.logout')}
                buttonAction={handleLogout}
            />
        );
    }

    if ((userHasRole(UserRole.candidate, user?.roles)) || (userHasRole(UserRole.counselor, user?.roles) && candidateUuid)) {
        navigate(RoutePaths.candidateDashboard(user?.uuid));
    }

    if (userHasRole(UserRole.counselor, user?.roles)) {
        return (
            <ConnectedCounselorDashboard
                onUserResponse={onUserResponse}
                className={className}
            />
        );
    }

    if (userHasRole(UserRole.lloAdmin, user?.roles)) {
        navigate(RoutePaths.organisationOverview());
    }

    return null;
};

export default ConnectedDashboard;
