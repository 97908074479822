import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './DevelopmentPlanEvaluationSkeletons.scss';

interface DevelopmentPlanEvaluationSkeletonsProps {
    amount?: number;
    className?: string;
}

const DevelopmentPlanEvaluationSkeletons: FC<DevelopmentPlanEvaluationSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const propertySkeletons = generateIdArray(amount);

    return (
        <section className={`development-plan-evaluation-skeletons ${className}`}>
            <Skeleton className="development-plan-evaluation-skeletons__breadcrumbs" />

            <div className="development-plan-evaluation-skeletons__header">
                <div className="development-plan-evaluation-skeletons__title-wrapper">
                    <Skeleton className="development-plan-evaluation-skeletons__title" />
                    <Skeleton className="development-plan-evaluation-skeletons__date" />
                </div>

                <div className="development-plan-evaluation-skeletons__action-buttons">
                    <Skeleton className="development-plan-evaluation-skeletons__delete-button" />
                </div>
            </div>

            <ul className="development-plan-evaluation-skeletons__item-list">
                {propertySkeletons.map(property => (
                    <li key={property} className="development-plan-evaluation-skeletons__item">
                        <div className="development-plan-evaluation-skeletons__item-header">
                            <Skeleton className="development-plan-evaluation-skeletons__item-title" />
                        </div>
                        <Skeleton className="development-plan-evaluation-skeletons__item-label" />
                        <Skeleton className="development-plan-evaluation-skeletons__item-input" />
                        <Skeleton className="development-plan-evaluation-skeletons__item-toggle" />
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default DevelopmentPlanEvaluationSkeletons;
