import { FC } from 'react';

import { Button, Card, Skeleton } from '../../../../components';
import { LogoCard } from '../../../../compositions';

import './TakeAnalysisSkeletons.scss';

interface TakeAnalysisSkeletonsProps {
    className?: string;
}

const TakeAnalysisSkeletons: FC<TakeAnalysisSkeletonsProps> = ({ className = '' }) => (
    <div className={`take-analysis-skeletons ${className}`}>
        <LogoCard logo="tma" className="take-analysis-skeletons__logo-card" />

        <Card className="take-analysis-skeletons__description-card">
            <Skeleton className="take-analysis-skeletons__title" />
            <Skeleton className="take-analysis-skeletons__paragraph-line" />
            <Skeleton className="take-analysis-skeletons__paragraph-line" />
            <Skeleton className="take-analysis-skeletons__paragraph-line" />

            <Button
                disabled
                text=""
                className="take-analysis-skeletons__button"
            />
        </Card>
    </div>
);

export default TakeAnalysisSkeletons;
