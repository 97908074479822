import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { Breadcrumbs } from '../../compositions';
import { ConnectedPage, ConnectedVacancyDetail } from '../../connectors';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { Vacancy } from '../../models/Vacancy';
import { RoutePaths } from '../../Routes';

import './VacancyDetail.scss';

const VacancyDetail: FC = (): ReactElement => {
    const [vacancy, setVacancy] = useState<Vacancy>();
    const [candidate, setCandidate] = useState<Candidate>();

    const title = vacancy
        ? trans('pages.vacancyDetail.vacancyTitle', { vacancyTitle: vacancy.title })
        : trans('pages.vacancyDetail.title');

    const breadcrumbs = [
        { label: trans('pages.seekVacancies.breadcrumb'), to: RoutePaths.seekVacancies(candidate?.uuid) },
        { label: vacancy?.title || '' },
    ];

    return (
        <ConnectedPage
            title={title}
            hasNavigation
            className="vacancy-detail-page"
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <Breadcrumbs crumbs={breadcrumbs} />

            <ConnectedVacancyDetail
                onCandidateResponse={setCandidate}
                onVacancyResponse={setVacancy}
                className="vacancy-detail-page__content-wrapper"
            />
        </ConnectedPage>
    );
};

export default VacancyDetail;
