import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '../../../../components';
import trans from '../../../../helpers/trans';

import './HamburgerButton.scss';

interface HamburgerButtonProps {
    isActive: boolean;
    onToggle: () => void;
    className?: string;
}

const HamburgerButton: FC<HamburgerButtonProps> = ({
    isActive,
    onToggle,
    className = '',
}): ReactElement => {
    const hamburgerButtonClassNames = classNames('hamburger-button', {
        'hamburger-button--is-active': isActive,
    }, className);

    return (
        <Button
            text={trans(`containers.topBar.menu.${isActive ? 'close' : 'open'}`)}
            onClick={onToggle}
            className={hamburgerButtonClassNames}
        >
            <div className="hamburger-button__bar-wrapper">
                <div className="hamburger-button__bar" />
                <div className="hamburger-button__bar" />
                <div className="hamburger-button__bar" />
            </div>
        </Button>
    );
};

export default HamburgerButton;
