import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ConfirmDialog, Dialog, DialogContent } from '../../../compositions';
import { ConfirmType } from '../../../compositions/ConfirmDialog/ConfirmDialog';
import { ConnectedDevelopmentPlanCompetencyForm, ConnectedDevelopmentPlanSkillForm } from '../../../connectors';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { DevelopmentPlanCompetency, EditDevelopmentPlanCompetencyFormData } from '../../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import { DevelopmentPlanSkill, EditDevelopmentPlanSkillFormData } from '../../../models/DevelopmentPlanSkill/DevelopmentPlanSkill';
import { UserRole } from '../../../types';
import { DevelopmentPlanOverviewSkeletons } from './skeletons';
import { DevelopmentPlanCompetencyCard, DevelopmentPlanOverviewNullState, DevelopmentPlanSkillCard } from './subcomponents';

import './DevelopmentPlanOverview.scss';

interface DevelopmentPlanOverviewProps {
    isLoading: boolean;
    competencyIsSuccessful: boolean;
    skillIsSuccessful: boolean;
    updatingCompetencyUuid?: string;
    updatingSkillUuid?: string;
    competencies: DevelopmentPlanCompetency[];
    skills: DevelopmentPlanSkill[];
    userRoles?: UserRole[];
    onEditCompetencySubmit: (formData: EditDevelopmentPlanCompetencyFormData) => void;
    onEditSkillSubmit: (formData: EditDevelopmentPlanSkillFormData) => void;
    onDeleteCompetency: (competencyUuid: string) => void;
    onDeleteSkill: (skillUuid: string) => void;
    className?: string;
}

const DevelopmentPlanOverview: FC<DevelopmentPlanOverviewProps> = ({
    isLoading,
    competencyIsSuccessful,
    skillIsSuccessful,
    updatingCompetencyUuid,
    updatingSkillUuid,
    competencies,
    skills,
    userRoles = [],
    onEditCompetencySubmit,
    onEditSkillSubmit,
    onDeleteCompetency,
    onDeleteSkill,
    className = '',
}): ReactElement => {
    const addCompetencyDialogRef = useRef<HTMLDialogElement>(null);
    const deleteCompetencyDialogRef = useRef<HTMLDialogElement>(null);
    const addSkillDialogRef = useRef<HTMLDialogElement>(null);
    const deleteSkillDialogRef = useRef<HTMLDialogElement>(null);

    const [competencyToDelete, setCompetencyToDelete] = useState<DevelopmentPlanCompetency>();
    const [skillToDelete, setSkillToDelete] = useState<DevelopmentPlanSkill>();

    const [connectedDevelopmentPlanCompetencyFormKey, setConnectedDevelopmentPlanCompetencyFormKey] = useState<number>(0);
    const [connectedDevelopmentPlanSkillFormKey, setConnectedDevelopmentPlanSkillFormKey] = useState<number>(0);

    const handleOpenAddCompetencyDialog = (): void => openDialog(addCompetencyDialogRef);
    const handleCloseAddCompetencyDialog = (): void => {
        closeDialog(addCompetencyDialogRef);

        setConnectedDevelopmentPlanCompetencyFormKey(connectedDevelopmentPlanCompetencyFormKey + 1);
    };

    const handleOpenDeleteCompetencyDialog = (competency: DevelopmentPlanCompetency): void => {
        setCompetencyToDelete(competency);
        openDialog(deleteCompetencyDialogRef);
    };
    const handleCloseDeleteCompetencyDialog = (): void => {
        setCompetencyToDelete(undefined);
        closeDialog(deleteCompetencyDialogRef);
    };

    const handleOpenAddSkillDialog = (): void => openDialog(addSkillDialogRef);
    const handleCloseAddSkillDialog = (): void => {
        closeDialog(addSkillDialogRef);

        setConnectedDevelopmentPlanSkillFormKey(connectedDevelopmentPlanSkillFormKey + 1);
    };

    const handleOpenDeleteSkillDialog = (skill: DevelopmentPlanSkill): void => {
        setSkillToDelete(skill);
        openDialog(deleteSkillDialogRef);
    };
    const handleCloseDeleteSkillDialog = (): void => {
        setSkillToDelete(undefined);
        closeDialog(deleteSkillDialogRef);
    };

    const handleDeleteCompetency = (): void => {
        if (competencyToDelete) {
            onDeleteCompetency(competencyToDelete.id);
            handleCloseDeleteCompetencyDialog();
        }
    };

    const handleDeleteSkill = (): void => {
        if (skillToDelete) {
            onDeleteSkill(skillToDelete.id);
            handleCloseDeleteSkillDialog();
        }
    };

    useEffect((): void => {
        handleCloseAddCompetencyDialog();
        handleCloseAddSkillDialog();
    }, []);

    if (isLoading || userRoles.length === 0) {
        return (
            <DevelopmentPlanOverviewSkeletons className={className} />
        );
    }

    return (
        <div className={`development-plan-overview ${className}`}>
            {(skills.length === 0 && competencies.length === 0) && (
                <DevelopmentPlanOverviewNullState
                    userRoles={userRoles}
                    onAddCompetency={handleOpenAddCompetencyDialog}
                    onAddSkill={handleOpenAddSkillDialog}
                    className="development-plan-overview__null-state"
                />
            )}

            {competencies.map(competency => (
                <DevelopmentPlanCompetencyCard
                    isLoading={updatingCompetencyUuid === competency.id}
                    isSuccessful={competencyIsSuccessful}
                    key={competency.id}
                    competency={competency}
                    userRoles={userRoles}
                    onSubmit={onEditCompetencySubmit}
                    onDelete={handleOpenDeleteCompetencyDialog}
                    className="development-plan-overview__card"
                />
            ))}

            {skills.map(skill => (
                <DevelopmentPlanSkillCard
                    isLoading={updatingSkillUuid === skill.id}
                    isSuccessful={skillIsSuccessful}
                    key={skill.id}
                    skill={skill}
                    onSubmit={onEditSkillSubmit}
                    onDelete={handleOpenDeleteSkillDialog}
                    className="development-plan-overview__card"
                />
            ))}

            <Dialog ref={addCompetencyDialogRef} onClose={handleCloseAddCompetencyDialog}>
                <DialogContent title={trans('forms.developmentPlanCompetencyForm.title')}>
                    <ConnectedDevelopmentPlanCompetencyForm
                        key={connectedDevelopmentPlanCompetencyFormKey}
                        onCancel={handleCloseAddCompetencyDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog ref={addSkillDialogRef} onClose={handleCloseAddSkillDialog}>
                <DialogContent title={trans('forms.developmentPlanSkillForm.title')}>
                    <ConnectedDevelopmentPlanSkillForm
                        key={connectedDevelopmentPlanSkillFormKey}
                        onCancel={handleCloseAddSkillDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteCompetencyDialogRef}
                onClose={handleCloseDeleteCompetencyDialog}
            >
                <ConfirmDialog
                    isLoading={!!updatingCompetencyUuid}
                    title={trans('containers.developmentPlanOverview.competency.delete.title')}
                    description={trans(
                        'containers.developmentPlanOverview.competency.delete.description',
                        { name: competencyToDelete?.name || '' },
                    )}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onCancel={handleCloseDeleteCompetencyDialog}
                    onConfirm={handleDeleteCompetency}
                />
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteSkillDialogRef}
                onClose={handleCloseDeleteSkillDialog}
            >
                <ConfirmDialog
                    isLoading={!!updatingSkillUuid}
                    title={trans('containers.developmentPlanOverview.skill.delete.title')}
                    description={trans(
                        'containers.developmentPlanOverview.skill.delete.description',
                        { name: skillToDelete?.name || '' },
                    )}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onCancel={handleCloseDeleteSkillDialog}
                    onConfirm={handleDeleteSkill}
                />
            </Dialog>
        </div>
    );
};

export default DevelopmentPlanOverview;
