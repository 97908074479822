import { FC, FormEvent, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../../../components';
import { IconName } from '../../../../../components/Icon/Icon';

import './ViewSelectorOption.scss';

interface ViewSelectorOptionProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    icon: IconName;
    label: string;
    onChange: (value: string) => void;
    className?: string;
}

const ViewSelectorOption: FC<ViewSelectorOptionProps> = ({
    icon,
    label,
    disabled,
    checked,
    onChange,
    className = '',
    ...optionProps
}): ReactElement => {
    const handleChange = (event: FormEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    const viewSelectorOptionClassNames = classNames('view-selector-option', {
        'view-selector-option--is-checked': checked,
        'view-selector-option--is-disabled': disabled,
    }, className);

    return (
        <label className={viewSelectorOptionClassNames}>
            <input
                {...optionProps}
                id={label}
                type="radio"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                className="view-selector-option__input"
            />
            <Icon
                name={icon}
                className="view-selector-option__icon"
            />

            <span className="view-selector-option__label">
                {label}
            </span>
        </label>
    );
};

export default ViewSelectorOption;
