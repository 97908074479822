import { FC, ReactElement } from 'react';

import {
    Card,
    LinkButton,
    LoadingSpinner,
    TableCell,
    TableRow,
} from '../../../components';
import {
    IconButton,
    LinkIconButton,
    Notice,
    SearchInput,
    SortTable,
} from '../../../compositions';
import { SortLabelInfo } from '../../../compositions/@tables/SortLabel/SortLabel';
import { convertDateToReadableDate } from '../../../helpers/date';
import trans from '../../../helpers/trans';
import { CandidateRelation } from '../../../models/CandidateRelations/CandidateRelations';
import { RoutePaths } from '../../../Routes';
import {
    NoticeType,
    SortConfig,
    SortDirection,
    SortField,
} from '../../../types';

import './CandidateTable.scss';

interface CandidateTableProps {
    isLoading?: boolean;
    candidates: CandidateRelation[];
    sortConfig: SortConfig;
    searchQuery: string;
    error?: string;
    onSort: (field: SortField, direction?: SortDirection) => void;
    onSearch: (query: string) => void;
    onCandidateInviteClick: () => void;
    className?: string;
}

const CandidateTable: FC<CandidateTableProps> = ({
    isLoading,
    candidates,
    sortConfig,
    searchQuery,
    error,
    onSort,
    onSearch,
    onCandidateInviteClick,
    className = '',
}): ReactElement => {
    const emptyCell = '-';

    const sortCells: SortLabelInfo[] = [
        { label: trans('tables.candidate.labels.name'), field: SortField.name },
        { label: trans('tables.candidate.labels.email') },
        { label: trans('tables.candidate.labels.dateOfBirth') },
        { label: trans('tables.candidate.labels.city'), field: SortField.city },
        { label: trans('tables.candidate.labels.createdAt'), field: SortField.createdAt },
    ];

    return (
        <Card className={`candidate-table ${className}`}>
            <header className="candidate-table__header">
                <div className="candidate-table__header-state-wrapper">
                    <h1 className="candidate-table__title">
                        {trans('tables.candidate.title')}
                    </h1>

                    {isLoading && <LoadingSpinner className="candidate-table__loader" />}
                </div>

                {candidates.length > 0 && (
                    <SearchInput
                        label={trans('tables.candidate.search')}
                        hideLabel
                        value={searchQuery}
                        placeholder={trans('tables.candidate.search')}
                        onChange={onSearch}
                        className="candidate-table__search-input"
                    />
                )}
            </header>

            {!isLoading && candidates.length === 0 && (
                <div className="candidate-table__null-state-wrapper">
                    <p>{trans('tables.candidate.nullState.description')}</p>

                    <IconButton
                        icon="plus"
                        text={trans('tables.candidate.nullState.buttonLabel')}
                        onClick={onCandidateInviteClick}
                        className="candidate-table__null-state-button"
                    />
                </div>
            )}

            {error && candidates.length > 0 && (
                <div className="candidate-table__error-wrapper">
                    <Notice
                        type={NoticeType.error}
                        text={error}
                        className="candidate-table__error-message"
                    />
                </div>
            )}

            {candidates.length > 0 && (
                <SortTable<CandidateRelation>
                    sortConfig={sortConfig}
                    sortCells={sortCells}
                    rows={candidates}
                    hasStickyColumn
                    onSort={onSort}
                    className="candidate-table__table"
                    rowClassName="candidate-table__row"
                    cellClassName="candidate-table__cell"
                >
                    {(sortedRows): JSX.Element[] => sortedRows.map(candidate => (
                        <TableRow
                            key={candidate.id}
                            className="candidate-table__row candidate-table__row--is-interactive"
                        >
                            <TableCell className="candidate-table__cell">
                                <LinkButton
                                    to={RoutePaths.candidateDashboard(candidate.id)}
                                    text={candidate.fullName}
                                    className="candidate-table__link-button"
                                />
                            </TableCell>
                            <TableCell className="candidate-table__cell">
                                {candidate.email}
                            </TableCell>
                            <TableCell className="candidate-table__cell">
                                {candidate.dateOfBirth ? convertDateToReadableDate(candidate.dateOfBirth) : emptyCell}
                            </TableCell>
                            <TableCell className="candidate-table__cell">
                                {candidate.city || emptyCell}
                            </TableCell>
                            <TableCell className="candidate-table__cell">
                                {convertDateToReadableDate(candidate.createdAt)}
                            </TableCell>
                            <TableCell contentClassName="candidate-table__fixed-cell-content" className="candidate-table__fixed-cell">
                                <LinkIconButton
                                    to={RoutePaths.candidateDashboard(candidate.id)}
                                    icon="eye"
                                    text={trans('tables.candidate.viewSkillsPassport')}
                                    hideLabel
                                    tabIndex={-1}
                                    className="candidate-table__view-skills-passport-button"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </SortTable>
            )}
        </Card>
    );
};

export default CandidateTable;
