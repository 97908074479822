import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    ConfirmDialog,
    DevelopmentPlanCard,
    Dialog,
    DialogContent,
} from '../../../compositions';
import { ConfirmType } from '../../../compositions/ConfirmDialog/ConfirmDialog';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { DevelopmentOverview as DevelopmentOverviewModel } from '../../../models/DevelopmentOverview/DevelopmentOverview';
import { DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { UserRole } from '../../../types';
import { DevelopmentPlanForm } from '../..';
import { DevelopmentOverviewSkeletons } from './skeletons';
import { DevelopmentOverviewNullState } from './subcomponents';

import './DevelopmentOverview.scss';

interface DevelopmentOverviewProps {
    isDeleting: boolean;
    //
    candidateUuid: string;
    userRoles?: UserRole[];
    //
    developmentOverviewIsLoading: boolean;
    developmentPlanEvaluationIsSuccessful: boolean;
    developmentPlanEvaluationIsLoading: boolean;
    developmentPlanIsSuccessful: boolean;
    developments: DevelopmentOverviewModel[];
    developmentPlanError: string;
    //
    onAddEvaluation: (developmentUuid: string) => void;
    onDeleteDevelopmentPlan: (developmentPlanUuid: string) => void;
    onSubmitDevelopmentPlan: (formData: DevelopmentPlanFormData) => void;
    //
    className?: string;
}

const DevelopmentOverview: FC<DevelopmentOverviewProps> = ({
    isDeleting,
    candidateUuid,
    userRoles,
    developmentOverviewIsLoading,
    developmentPlanEvaluationIsSuccessful,
    developmentPlanEvaluationIsLoading,
    developmentPlanIsSuccessful,
    developments,
    developmentPlanError,
    onAddEvaluation,
    onDeleteDevelopmentPlan,
    onSubmitDevelopmentPlan,
    className = '',
}): ReactElement => {
    const editDevelopmentDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDevelopmentDialogRef = useRef<HTMLDialogElement>(null);
    const evaluateDevelopmentDialogRef = useRef<HTMLDialogElement>(null);

    const [selectedDevelopmentPlan, setSelectedDevelopmentPlan] = useState<DevelopmentOverviewModel>();
    const [developmentPlanToDelete, setDevelopmentPlanToDelete] = useState<DevelopmentOverviewModel>();
    const [developmentToEvaluate, setDevelopmentToEvaluate] = useState<DevelopmentOverviewModel>();

    const handleOpenEditDevelopmentDialog = (developmentPlan: DevelopmentOverviewModel): void => {
        setSelectedDevelopmentPlan(developmentPlan);
        openDialog(editDevelopmentDialogRef);
    };
    const handleCloseEditDevelopmentDialog = (): void => {
        setSelectedDevelopmentPlan(undefined);
        closeDialog(editDevelopmentDialogRef);
    };

    const handleOpenDeleteDevelopmentDialog = (developmentPlan: DevelopmentOverviewModel): void => {
        setDevelopmentPlanToDelete(developmentPlan);
        openDialog(deleteDevelopmentDialogRef);
    };
    const handleCloseDeleteDevelopmentDialog = (): void => {
        setDevelopmentPlanToDelete(undefined);
        closeDialog(deleteDevelopmentDialogRef);
    };

    const handleOpenEvaluateDevelopmentDialog = (developmentPlan: DevelopmentOverviewModel): void => {
        setDevelopmentToEvaluate(developmentPlan);
        openDialog(evaluateDevelopmentDialogRef);
    };
    const handleCloseEvaluateDevelopmentDialog = (): void => {
        setDevelopmentToEvaluate(undefined);
        closeDialog(evaluateDevelopmentDialogRef);
    };

    const handleDeleteDevelopmentPlan = (): void => {
        onDeleteDevelopmentPlan(developmentPlanToDelete?.id || '');
    };

    useEffect((): void => {
        if (developmentPlanIsSuccessful) {
            handleCloseEditDevelopmentDialog();
            handleCloseDeleteDevelopmentDialog();
        }
    }, [developmentPlanIsSuccessful]);

    useEffect((): void => {
        if (developmentPlanEvaluationIsSuccessful) {
            closeDialog(evaluateDevelopmentDialogRef);
        }
    }, [developmentPlanEvaluationIsSuccessful]);

    const handleAddEvaluation = (): void => {
        onAddEvaluation(developmentToEvaluate?.id || '');
    };

    if (developmentOverviewIsLoading) {
        return <DevelopmentOverviewSkeletons className={className} />;
    }

    return (
        <div className={`development-overview ${className}`}>
            {developments.length === 0 && (
                <DevelopmentOverviewNullState className="development-overview__null-state" />
            )}

            {developments.length > 0 && developments.map(development => (
                <DevelopmentPlanCard
                    key={development.id}
                    developmentPlan={development}
                    candidateUuid={candidateUuid}
                    userRoles={userRoles}
                    onEditDevelopmentPlan={handleOpenEditDevelopmentDialog}
                    onDeleteDevelopmentPlan={handleOpenDeleteDevelopmentDialog}
                    onAddEvaluation={handleOpenEvaluateDevelopmentDialog}
                    className="development-overview__card"
                />
            ))}

            <Dialog ref={editDevelopmentDialogRef} onClose={handleCloseEditDevelopmentDialog}>
                <DialogContent title={trans('containers.developmentOverview.dialog.editDevelopmentPlan.title')}>
                    <DevelopmentPlanForm
                        developmentPlan={selectedDevelopmentPlan}
                        error={developmentPlanError}
                        onCancel={handleCloseEditDevelopmentDialog}
                        onSubmit={onSubmitDevelopmentPlan}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDevelopmentDialogRef}
                onClose={handleCloseDeleteDevelopmentDialog}
            >
                <ConfirmDialog
                    isLoading={isDeleting}
                    title={trans('containers.developmentOverview.dialog.deleteDevelopmentPlan.title')}
                    description={trans('containers.developmentOverview.dialog.deleteDevelopmentPlan.description', {
                        developmentTitle: developmentPlanToDelete?.title || '',
                    })}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onCancel={handleCloseDeleteDevelopmentDialog}
                    onConfirm={handleDeleteDevelopmentPlan}
                />
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={evaluateDevelopmentDialogRef}
                onClose={handleCloseEvaluateDevelopmentDialog}
            >
                <ConfirmDialog
                    isLoading={developmentPlanEvaluationIsLoading}
                    title={trans('containers.developmentOverview.dialog.addEvaluation.title')}
                    description={trans(
                        'containers.developmentOverview.dialog.addEvaluation.description',
                        { developmentTitle: developmentToEvaluate?.title || '' },
                    )}
                    submitIcon="plus"
                    submitText={trans('common.add')}
                    confirmType={ConfirmType.Notify}
                    onCancel={handleCloseEvaluateDevelopmentDialog}
                    onConfirm={handleAddEvaluation}
                />
            </Dialog>
        </div>
    );
};

export default DevelopmentOverview;
