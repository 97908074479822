import { FC, ReactElement } from 'react';

import { PaginationInput, PaginationInputList } from '..';

interface PaginationLongCenterProps {
    paginationId: string;
    amountOfPages: number;
    centerList: number[];
    activePage: number;
    onChange: (page: number) => void;
}

const PaginationLongCenter: FC<PaginationLongCenterProps> = ({
    paginationId,
    amountOfPages,
    centerList,
    activePage,
    onChange,
}): ReactElement => (
    <>
        <PaginationInput
            paginationId={paginationId}
            page={1}
            checked={activePage === 1}
            onChange={onChange}
        />
        <span className="pagination__ellipsis">…</span>
        <PaginationInputList
            paginationId={paginationId}
            list={centerList}
            activePage={activePage}
            onChange={onChange}
        />
        <span className="pagination__ellipsis">…</span>
        <PaginationInput
            paginationId={paginationId}
            page={amountOfPages}
            checked={activePage === amountOfPages}
            onChange={onChange}
        />
    </>
);

export default PaginationLongCenter;
