/* eslint-disable max-len */
import { CSSProperties, FC, ReactElement } from 'react';

import './IncentiveSliderValueIllustration.scss';

interface IncentiveSliderValueIllustrationProps {
    style?: CSSProperties;
    className?: string;
}

const IncentiveSliderValueIllustration: FC<IncentiveSliderValueIllustrationProps> = ({
    style,
    className = '',
}): ReactElement => (
    <svg style={style} viewBox="0 0 48 48" className={`incentive-slider-value-illustration ${className}`}>
        <path className="incentive-slider-value-illustration__arrow" d="M24 44C20.287 44 16.726 42.525 14.1005 39.8995C11.475 37.274 10 33.713 10 30C10 19.5 24 4 24 4C24 4 38 19.5 38 30C38 33.713 36.525 37.274 33.8995 39.8995C31.274 42.525 27.713 44 24 44Z" />
        <circle className="incentive-slider-value-illustration__inner-circle" cx="24" cy="30" r="10" />
        <path className="incentive-slider-value-illustration__star" d="M23.9999 33.5134L28.1199 36L27.0266 31.3134L30.6666 28.16L25.8733 27.7467L23.9999 23.3334L22.1266 27.7467L17.3333 28.16L20.9666 31.3134L19.8799 36L23.9999 33.5134Z" />
    </svg>
);

export default IncentiveSliderValueIllustration;
/* eslint-enable max-len */
