/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconDownload: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 96.7 100" className={`icon ${className}`}>
        <polygon points="61.5,45.4 61.5,0 35.2,0 35.2,45.4 16.1,45.4 48.4,77.4 80.6,45.4" />
        <rect x="0" y="88.1" width="96.7" height="11.9" />
    </svg>
);

export default IconDownload;
/* eslint-enable max-len */
