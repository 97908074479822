import { FC, ReactElement, useEffect } from 'react';

import { IntroductionAction } from '../../../containers/MyActions/actions';
import { userHasRole } from '../../../helpers/role';
import { useTypedSelector } from '../../../redux/store';
import { Gender, UserRole } from '../../../types';

interface ConnectedIntroductionActionProps {
    onIntroductionIsActive: (isActive: boolean) => void;
    className?: string;
}

const ConnectedIntroductionAction: FC<ConnectedIntroductionActionProps> = ({
    onIntroductionIsActive,
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const isCandidate = userHasRole(UserRole.candidate, user?.roles);

    useEffect((): void => {
        onIntroductionIsActive(true);
    }, []);

    const handleFinish = (): void => onIntroductionIsActive(false);

    return (
        <IntroductionAction
            isCandidate={isCandidate}
            gender={candidate?.gender as Gender}
            onFinish={handleFinish}
            className={className}
        />
    );
};

export default ConnectedIntroductionAction;
