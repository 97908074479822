import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { AzureAD } from 'react-aad-msal';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { configureStore } from './redux/store';
import { AzureAuthProvider } from './services/AzureAuthService';

import './styles/global.scss';

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

configureStore({}).then((store): void => {
    root.render(
        // Remove this when react-aad-msal has updated their typing
        // @ts-ignore
        <AzureAD provider={AzureAuthProvider} forceLogin>
            <StoreProvider store={store}>
                <HelmetProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </HelmetProvider>
            </StoreProvider>
        </AzureAD>,
    );
});

if (process.env.REACT_APP_APP_ENV !== 'local' && 'serviceWorker' in navigator) {
    const askNotificationPermission = false;

    if (askNotificationPermission && 'Notification' in window) {
        Notification.requestPermission();
    }

    navigator.serviceWorker.register('/service-worker.js');

    // Purge expired cache files that follow the Cache first strategy
    // Configure in service-worker, or remove entirely if cache should have no expiration date
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(JSON.stringify({
            action: 'purge-cache',
        }));
    }
}
