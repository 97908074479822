import { getCoachingAdviceApiCall } from '../../models/CoachingAdvice/CoachingAdviceService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import { setCoachingAdvice, setError, setIsLoading } from './coachingAdvice';

export const fetchCoachingAdvice = (competencyId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const coachingAdviceResponse = await getCoachingAdviceApiCall(competencyId);

        if (!isFetchResultSuccessful(coachingAdviceResponse)) {
            dispatch(setError(coachingAdviceResponse.error));
            return;
        }

        dispatch(setCoachingAdvice(coachingAdviceResponse.data));
    } catch (error) {
        console.error('[fetchCoachingAdvice]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
