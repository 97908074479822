import { Candidate } from '../models/Candidate/Candidate';

export const candidateNeedsToUpdatePersonalData = (candidate?: Partial<Candidate>): boolean => (
    !candidate
        || !candidate.firstName
        || !candidate.lastName
        || !candidate.dateOfBirth
        || !candidate.gender
        || !candidate.address?.postalCode
        || !candidate.address?.houseNumber
        || !candidate.address?.street
        || !candidate.address?.city
        || !candidate.address?.latitude
        || !candidate.address?.longitude
);
