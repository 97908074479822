import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedManageAnalyses, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './ManageAnalyses.scss';

const ManageAnalyses: FC = (): ReactElement => (
    <ConnectedPage
        hasNavigation
        title={trans('pages.manageAnalyses.title')}
        className="manage-analyses-page"
    >
        <Helmet>
            <title>{trans('pages.manageAnalyses.title')}</title>
        </Helmet>

        <div className="manage-analyses-page__content-wrapper">
            <ConnectedManageAnalyses />
        </div>
    </ConnectedPage>
);

export default ManageAnalyses;
