import { FC, FormEvent, ReactElement } from 'react';

import { CheckboxList, IconButton, SubmitButton } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { EducationFilterOptions } from '../../../../../models/EducationFormOptions/EducationFormOptions';
import { EducationFilterValues } from '../../../../../models/Educations/Educations';

import './EducationFiltersForm.scss';

interface EducationFiltersFormProps {
    isLoading: boolean;
    filterValues: EducationFilterValues;
    filterOptions: EducationFilterOptions;
    onChange: (values: Partial<EducationFilterValues>) => void;
    onReset: () => void;
    onSubmit: () => void;
    onClose: () => void;
    className?: string;
}

const EducationFiltersForm: FC<EducationFiltersFormProps> = ({
    isLoading,
    filterValues,
    filterOptions,
    onChange,
    onReset,
    onSubmit,
    onClose,
    className = '',
}): ReactElement => {
    const handleEducationLevelChange = (value: string[]): void => {
        onChange({ educationLevel: value });
    };

    const handleFieldOfStudyChange = (value: string[]): void => {
        onChange({ fieldOfStudy: value });
    };

    const handleEducatorChange = (value: string[]): void => {
        onChange({ educator: value });
    };

    const handleStartMomentChange = (value: string[]): void => {
        onChange({ startMoment: value });
    };

    const handleDayPartChange = (value: string[]): void => {
        onChange({ dayPart: value });
    };

    const handleSubsidyChange = (value: string[]): void => {
        onChange({ subsidy: value });
    };

    const handleLocationChange = (value: string[]): void => {
        onChange({ location: value });
    };

    const handleDurationChange = (value: string[]): void => {
        onChange({ duration: value });
    };

    const handleResetClick = (): void => {
        onClose();
        onReset();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onClose();
        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className={`education-filters-form ${className}`}>
            <div className="education-filters-form__property-wrapper">
                {filterOptions.educationLevels.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="education-levels"
                            label={trans('containers.educationFilters.filters.educationLevel')}
                            value={filterValues.educationLevel}
                            options={filterOptions.educationLevels}
                            showLessThreshold={6}
                            onChange={handleEducationLevelChange}
                        />
                    </div>
                )}

                {filterOptions.fieldsOfStudy.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="fields-of-study"
                            label={trans('containers.educationFilters.filters.fieldsOfStudy')}
                            value={filterValues.fieldOfStudy}
                            options={filterOptions.fieldsOfStudy}
                            showLessThreshold={6}
                            onChange={handleFieldOfStudyChange}
                        />
                    </div>
                )}

                {filterOptions.educators.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="educator"
                            label={trans('containers.educationFilters.filters.educator')}
                            value={filterValues.educator}
                            options={filterOptions.educators}
                            showLessThreshold={6}
                            onChange={handleEducatorChange}
                        />
                    </div>
                )}

                {filterOptions.startMoments.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="start-moments"
                            label={trans('containers.educationFilters.filters.startMoment')}
                            value={filterValues.startMoment}
                            options={filterOptions.startMoments}
                            showLessThreshold={6}
                            onChange={handleStartMomentChange}
                        />
                    </div>
                )}

                {filterOptions.dayParts.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="dat-part"
                            label={trans('containers.educationFilters.filters.dayPart')}
                            value={filterValues.dayPart}
                            options={filterOptions.dayParts}
                            showLessThreshold={6}
                            onChange={handleDayPartChange}
                        />
                    </div>
                )}

                {filterOptions.subsidies.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="subsidies"
                            label={trans('containers.educationFilters.filters.subsidy')}
                            value={filterValues.subsidy}
                            options={filterOptions.subsidies}
                            showLessThreshold={6}
                            onChange={handleSubsidyChange}
                        />
                    </div>
                )}

                {filterOptions.locations.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="locations"
                            label={trans('containers.educationFilters.filters.location')}
                            value={filterValues.location}
                            options={filterOptions.locations}
                            showLessThreshold={6}
                            onChange={handleLocationChange}
                        />
                    </div>
                )}

                {filterOptions.durations.length > 0 && (
                    <div className="education-filters-form__property">
                        <CheckboxList
                            name="duration"
                            label={trans('containers.educationFilters.filters.duration')}
                            value={filterValues.duration}
                            options={filterOptions.durations}
                            showLessThreshold={6}
                            onChange={handleDurationChange}
                        />
                    </div>
                )}
            </div>

            <footer className="education-filters-form__footer">
                <SubmitButton
                    isLoading={isLoading}
                    icon="search"
                    text={trans('common.search')}
                />
                <IconButton
                    icon="refresh"
                    text={trans('common.reset')}
                    disabled={isLoading}
                    onClick={handleResetClick}
                    className="education-filters-form__reset-button"
                />
            </footer>
        </form>
    );
};

export default EducationFiltersForm;
