import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { SearchableValueInput, SubmitButton } from '../../../compositions';
import trans from '../../../helpers/trans';
import { SearchableOption } from '../../../types';

import './VacancySearchForm.scss';

export interface VacancySearchFormValues {
    what: string;
    where: string;
}

interface VacancySearchFormProps {
    isLoading: boolean;
    value: Partial<VacancySearchFormValues>;
    whatOptions: string[];
    whereOptions: string[];
    onSubmit: (values: VacancySearchFormValues) => void;
    className?: string;
}

const VacancySearchForm: FC<VacancySearchFormProps> = ({
    isLoading,
    value,
    whatOptions,
    whereOptions,
    onSubmit,
    className = '',
}): ReactElement => {
    const resultLimit = 25;

    const [what, setWhat] = useState<string>('');
    const [where, setWhere] = useState<string>('');

    useEffect((): void => {
        setWhat(value?.what || '');
        setWhere(value?.where || '');
    }, [value]);

    const whatSearchOptions = whatOptions.map((option, index): SearchableOption => ({
        value: String(index),
        label: option,
    }));

    const whereSearchOptions = whereOptions.map((option, index): SearchableOption => ({
        value: String(index),
        label: option,
    }));

    const handleWhatChange = (selectedOption: SearchableOption) => {
        setWhat(selectedOption.label);
    };

    const handleWhereChange = (selectedOption: SearchableOption) => {
        setWhere(selectedOption.label);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit({ what, where });
    };

    return (
        <form onSubmit={handleSubmit} className={`vacancy-search-form ${className}`}>
            <SearchableValueInput
                isSearchable
                hideIcon
                label={trans('forms.vacancySearch.input.what')}
                value={what}
                options={whatSearchOptions}
                placeholder={trans('forms.vacancySearch.placeholder.what')}
                resultLimit={resultLimit}
                disabled={isLoading}
                onChange={handleWhatChange}
                className="vacancy-search-form__input"
                inputWrapperClassName="vacancy-search-form__input-field"
                listClassName="vacancy-search-form__input-list"
            />

            <SearchableValueInput
                isSearchable
                hideIcon
                label={trans('forms.vacancySearch.input.where')}
                value={where}
                options={whereSearchOptions}
                placeholder={trans('forms.vacancySearch.placeholder.where')}
                resultLimit={resultLimit}
                disabled={isLoading}
                onChange={handleWhereChange}
                className="vacancy-search-form__input"
                inputWrapperClassName="vacancy-search-form__input-field"
                listClassName="vacancy-search-form__input-list"
            />

            <SubmitButton
                isLoading={isLoading}
                icon="arrow-right"
                hideLabel
                text={trans('forms.vacancySearch.submitButtonLabel')}
                className="vacancy-search-form__submit-button"
            />
        </form>
    );
};

export default VacancySearchForm;
