import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';

import { EducationFilters } from '../../../containers';
import { scrollToTop } from '../../../helpers/scroll';
import { defaultEducationFilterValues, EducationFilterValues } from '../../../models/Educations/Educations';
import { setActiveFilterValues, setShouldResetFilters } from '../../../redux/educations/educations';
import { fetchEducationFilterOptions } from '../../../redux/educations/educationsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedEducationFiltersProps {
    currentPage: number;
    className?: string;
}

const ConnectedEducationFilters: FC<ConnectedEducationFiltersProps> = ({
    currentPage,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const isLoading = useTypedSelector(state => state.educationsReducer.isLoading);
    const filterOptions = useTypedSelector(state => state.educationsReducer.filterOptions);
    const activeFilterValues = useTypedSelector(state => state.educationsReducer.activeFilterValues);
    const shouldResetFilters = useTypedSelector(state => state.educationsReducer.shouldResetFilters);

    const [filterValues, setFilterValues] = useState<EducationFilterValues>(activeFilterValues);

    useEffect((): void => {
        if (!isLoading && candidateUuid) {
            dispatch(fetchEducationFilterOptions(candidateUuid, activeFilterValues));
        }
    }, [activeFilterValues]);

    useEffect((): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber: currentPage,
        };

        setFilterValues(newFilterValues);
        dispatch(setActiveFilterValues(newFilterValues));

        scrollToTop();
    }, [currentPage]);

    const handleSearchSubmit = (value: string): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber: 1,
            searchQuery: value,
        };

        setFilterValues(newFilterValues);
        dispatch(setActiveFilterValues(newFilterValues));
    };

    const handleFilterChange = (value: Partial<EducationFilterValues>): void => {
        setFilterValues({
            ...filterValues,
            ...value,
        });
    };

    const handleFilterReset = (): void => {
        const defaultFilters = defaultEducationFilterValues();

        setFilterValues(defaultFilters);
        dispatch(setActiveFilterValues(defaultFilters));
    };

    const handleFilterSubmit = (): void => {
        const newFilterValues: EducationFilterValues = {
            ...filterValues,
            pageNumber: 1,
        };

        dispatch(setActiveFilterValues(newFilterValues));
    };

    useEffect((): void => {
        if (shouldResetFilters) {
            handleFilterReset();
            dispatch(setShouldResetFilters(false));
        }
    }, [shouldResetFilters]);

    return (
        <EducationFilters
            isLoading={isLoading}
            activeFilterValues={activeFilterValues}
            filterValues={filterValues}
            filterOptions={filterOptions}
            onFilterChange={handleFilterChange}
            onSearchSubmit={handleSearchSubmit}
            onFilterReset={handleFilterReset}
            onFilterSubmit={handleFilterSubmit}
            className={className}
        />
    );
};

export default ConnectedEducationFilters;
