import { MultipleRelationship } from '../japi/types/Relationships';
import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface EducationLevelResource extends Resource {
    id: string;
    type: 'education-levels';
    attributes: {
        name: string;
        slug: string;
        manual: boolean;
        createdAt: string;
        updatedAt: string;
    };
    relationships: {
        educationLevelDescendants: MultipleRelationship<'education-levels'>;
    };
}

export interface EducationLevel {
    id: string;
    label: string;
}

export interface EducationSubLevel {
    id: string;
    label: string;
    relatedEducationLevelIds?: string[];
}

interface EducationLevelIds {
    educationLevelId: string;
    educationSubLevelIds: string[];
}

export const transformResourceToEducationLevelIds = (
    educationLevel: EducationLevelResource,
): EducationLevelIds => ({
    educationLevelId: educationLevel.id,
    educationSubLevelIds: educationLevel.relationships.educationLevelDescendants.data
        .map(subLevel => subLevel.id),
});

export const transformResourceToEducationLevel = (
    educationLevelResource: EducationLevelResource,
): EducationLevel => ({
    id: educationLevelResource.id,
    label: educationLevelResource.attributes.name,
});

export const transformResourceToEducationSubLevel = (
    educationSubLevelResource: EducationLevelResource,
    educationLevelIds: EducationLevelIds[],
): EducationSubLevel => {
    const relatedEducationLevelIds = educationLevelIds
        .filter(educationLevel => educationLevel.educationSubLevelIds.includes(educationSubLevelResource.id))
        .map(educationLevel => educationLevel.educationLevelId);

    return {
        id: educationSubLevelResource.id,
        label: educationSubLevelResource.attributes.name,
        relatedEducationLevelIds,
    };
};

export const transformEducationLevelToFormOption = (
    educationLevel: EducationLevel,
): FormOption => ({
    value: educationLevel.id,
    label: educationLevel.label,
});

export const transformEducationSubLevelToFormOption = (
    educationSubLevel: EducationSubLevel,
): FormOption => ({
    value: educationSubLevel.id,
    label: educationSubLevel.label,
});
