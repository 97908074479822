import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { EducationOverview } from '../../../containers';
import { setShouldResetFilters } from '../../../redux/educations/educations';
import { fetchEducations } from '../../../redux/educations/educationsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedEducationOverviewProps {
    onPaginationChange: (currentPage: number) => void;
    className?: string;
}

const ConnectedEducationOverview: FC<ConnectedEducationOverviewProps> = ({
    onPaginationChange,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const isLoading = useTypedSelector(state => state.educationsReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.educationsReducer.activeFilterValues);
    const educations = useTypedSelector(state => state.educationsReducer.educations);
    const pagination = useTypedSelector(state => state.educationsReducer.pagination);

    useEffect((): void => {
        if (!isLoading && candidateUuid) {
            dispatch(fetchEducations(candidateUuid, activeFilterValues));
        }
    }, [activeFilterValues]);

    const handleResetClick = (): void => {
        dispatch(setShouldResetFilters(true));
    };

    return (
        <EducationOverview
            isLoading={isLoading}
            educations={educations}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
            onResetClick={handleResetClick}
            className={className}
        />
    );
};

export default ConnectedEducationOverview;
