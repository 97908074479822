import { formatDate } from './date';
import { convertToKebabCase } from './string';

interface FileNameOptions {
    date?: Date;
    subject: string;
    content: string;
    extension: string;
}

export const generateDownloadFileName = (options: FileNameOptions): string => {
    const date = formatDate(options.date || new Date(), 'y-MM-dd');
    const subject = convertToKebabCase(options.subject);

    return `${date}_${subject}_${options.content}.${options.extension}`;
};
