import { FC, ReactElement } from 'react';

import { TroubleshootDetailsCard } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedTroubleshootDetailsCardProps {
    className?: string;
}

const ConnectedTroubleshootDetailsCard: FC<ConnectedTroubleshootDetailsCardProps> = ({
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);
    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);

    return (
        <TroubleshootDetailsCard
            isLoading={userIsLoading}
            user={user}
            className={className}
        />
    );
};

export default ConnectedTroubleshootDetailsCard;
