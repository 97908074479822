import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './OccupationDetailSkillsSkeletons.scss';

interface OccupationDetailSkillsSkeletonsProps {
    className?: string;
}

const OccupationDetailSkillsSkeletons: FC<OccupationDetailSkillsSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const essentialSkillsAmount = randomInBetweenValue(8, 16);
    const essentialSkillsSkeletons = generateIdArray(essentialSkillsAmount);

    const optionalSkillsAmount = randomInBetweenValue(8, 16);
    const optionalSkillsSkeletons = generateIdArray(optionalSkillsAmount);

    return (
        <div className={`occupation-detail-skills-skeletons ${className}`}>
            <Skeleton className="occupation-detail-skills-skeletons__skill-title" />

            <Card className="occupation-detail-skills-skeletons__skills">
                <Skeleton className="occupation-detail-skills-skeletons__label" />

                <ul className="occupation-detail-skills-skeletons__list">
                    {essentialSkillsSkeletons.map(skill => (
                        <li key={skill} className="occupation-detail-skills-skeletons__list-item" />
                    ))}
                </ul>

                <Skeleton className="occupation-detail-skills-skeletons__label" />

                <ul className="occupation-detail-skills-skeletons__list">
                    {optionalSkillsSkeletons.map(skill => (
                        <li key={skill} className="occupation-detail-skills-skeletons__list-item" />
                    ))}
                </ul>
            </Card>
        </div>
    );
};

export default OccupationDetailSkillsSkeletons;
