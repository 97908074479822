import { FC, ReactElement, useEffect } from 'react';

import { OrganisationDetailModules } from '../../../containers';
import useTimeout from '../../../hooks/useTimeout';
import { Module } from '../../../models/Modules/Modules';
import { setShowConfirmation } from '../../../redux/modules/modules';
import { fetchModulesForOrganisation, updateModulesForOrganisation } from '../../../redux/modules/modulesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrganisationDetailModulesProps {
    className?: string;
}

const ConnectedOrganisationDetailModules: FC<ConnectedOrganisationDetailModulesProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);
    const organisation = useTypedSelector(state => state.organisationReducer.organisation);

    const modulesIsLoading = useTypedSelector(state => state.modulesReducer.isLoading);
    const isUpdating = useTypedSelector(state => state.modulesReducer.isUpdating);
    const showConfirmation = useTypedSelector(state => state.modulesReducer.showConfirmation);
    const error = useTypedSelector(state => state.modulesReducer.error);
    const modules = useTypedSelector(state => state.modulesReducer.modules);

    const isLoading = [
        organisationIsLoading,
        modulesIsLoading,
    ].some(Boolean);

    const handleToggleChange = ({ id, isActive }: Module): void => {
        if (organisation) dispatch(updateModulesForOrganisation(organisation?.id, { id, isActive }));
    };

    useTimeout((): void => {
        if (showConfirmation) {
            dispatch(setShowConfirmation(false));
        }
    }, 2000, [showConfirmation]);

    useEffect((): void => {
        if (organisation) dispatch(fetchModulesForOrganisation(organisation?.id));
    }, [organisation]);

    return (
        <OrganisationDetailModules
            isLoading={isLoading}
            isUpdating={isUpdating}
            showConfirmation={showConfirmation}
            error={error}
            modules={modules}
            onToggleChange={handleToggleChange}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailModules;
