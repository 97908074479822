import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { DevelopmentHeader } from '../../../containers';
import { Candidate } from '../../../models/Candidate/Candidate';
import { DevelopmentPlanFormData } from '../../../models/DevelopmentPlan/DevelopmentPlan';
import { User } from '../../../models/User/User';
import { fetchDevelopmentOverview } from '../../../redux/developmentOverview/developmentOverviewActions';
import { addDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlanActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedDevelopmentHeaderProps {
    onCandidateResponse?: (candidate: Candidate) => void;
    onUserResponse?: (user: User) => void;
    className?: string;
}

const ConnectedDevelopmentHeader: FC<ConnectedDevelopmentHeaderProps> = ({
    onCandidateResponse,
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);

    const developmentOverviewIsLoading = useTypedSelector(state => state.developmentOverviewReducer.isLoading);

    const developmentPlanIsLoading = useTypedSelector(state => state.developmentPlanReducer.isLoading);
    const developmentPlanIsSuccessful = useTypedSelector(state => state.developmentPlanReducer.isSuccessful);
    const developmentPlanError = useTypedSelector(state => state.developmentPlanReducer.error);

    const handleSubmitDevelopmentPlan = (formData: DevelopmentPlanFormData): void => {
        if (candidateUuid) {
            dispatch(addDevelopmentPlan(formData, candidateUuid));
        }
    };

    useEffect((): void => {
        if (onUserResponse && user) {
            onUserResponse(user);
        }
    }, [onUserResponse, user]);

    useEffect((): void => {
        if (onCandidateResponse && candidate) {
            onCandidateResponse(candidate);
        }
    }, [onCandidateResponse, candidate]);

    useEffectOnce((): void => {
        if (candidateUuid) {
            dispatch(fetchDevelopmentOverview(candidateUuid));
        }
    });

    return (
        <DevelopmentHeader
            developmentOverviewIsLoading={developmentOverviewIsLoading}
            developmentPlanIsLoading={developmentPlanIsLoading}
            developmentPlanIsSuccessful={developmentPlanIsSuccessful}
            developmentPlanError={developmentPlanError}
            onSubmitDevelopmentPlan={handleSubmitDevelopmentPlan}
            className={className}
        />
    );
};

export default ConnectedDevelopmentHeader;
