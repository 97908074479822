import { addArrayEntry, deleteArrayEntry, editArrayEntry } from '../../helpers/array';
import { sortByStartDateAsc } from '../../helpers/sort';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { SchoolExperience, SchoolExperienceFormData } from '../../models/SchoolExperience/SchoolExperience';
import { deleteSchoolExperienceApiCall, patchSchoolExperienceApiCall, postSchoolExperienceApiCall } from '../../models/SchoolExperience/SchoolExperienceService';
import { transformToSchoolExperienceRequest } from '../../models/SchoolExperience/SchoolExperienceTransformers';
import { setSchoolExperiences } from '../skillsPassport/skillsPassport';
import { ReducerGetter, TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSuccessful } from './schoolExperience';

export const addSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData, candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { schoolExperiences } = state.skillsPassportReducer;

    try {
        const schoolExperienceRequest = transformToSchoolExperienceRequest(schoolExperienceFormData);

        const schoolExperienceResponse = await postSchoolExperienceApiCall(schoolExperienceRequest, candidateUuid);

        if (!isFetchResultSuccessful(schoolExperienceResponse)) {
            dispatch(setError(schoolExperienceResponse.error));
            return;
        }

        const schoolExperience = schoolExperienceResponse.data;
        const newSchoolExperienceArray = addArrayEntry(schoolExperiences, schoolExperience);
        const sortedSchoolExperiences = [...newSchoolExperienceArray].sort(sortByStartDateAsc);

        dispatch(setSchoolExperiences(sortedSchoolExperiences));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addSchoolExperience]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editSchoolExperience = (schoolExperienceFormData: SchoolExperienceFormData, candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { schoolExperiences } = state.skillsPassportReducer;

    try {
        const schoolExperienceRequest = transformToSchoolExperienceRequest(schoolExperienceFormData);

        const schoolExperienceResponse = await patchSchoolExperienceApiCall(schoolExperienceRequest, candidateUuid);

        if (!isFetchResultSuccessful(schoolExperienceResponse)) {
            dispatch(setError(schoolExperienceResponse.error));
            return;
        }

        const schoolExperience = schoolExperienceResponse.data;
        const newSchoolExperienceArray = editArrayEntry(schoolExperiences, schoolExperience);
        const sortedSchoolExperiences = [...newSchoolExperienceArray].sort(sortByStartDateAsc);

        dispatch(setSchoolExperiences(sortedSchoolExperiences));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editSchoolExperience]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteSchoolExperience = (schoolExperience: SchoolExperience, candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { schoolExperiences } = state.skillsPassportReducer;

    try {
        const schoolExperienceResponse = await deleteSchoolExperienceApiCall(schoolExperience.id, candidateUuid);

        if (!isFetchResultSuccessful(schoolExperienceResponse)) {
            dispatch(setError(schoolExperienceResponse.error));
            return;
        }

        const newSchoolExperienceArray = deleteArrayEntry(schoolExperiences, schoolExperience);

        dispatch(setSchoolExperiences(newSchoolExperienceArray));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteSchoolExperience]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
