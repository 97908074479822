import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { SkillsPassportHeader } from '../../../containers';
import { userHasRole } from '../../../helpers/role';
import { TmaAnalysisSettingsFormData } from '../../../models/TmaAnalysis/TmaAnalysis';
import { User } from '../../../models/User/User';
import { setIsUpToDate } from '../../../redux/skillsPassport/skillsPassport';
import { fetchSkillsPassport, importSkillsPassport } from '../../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { postTmaAnalysisSettings, updateTmaAnalysisSettingsSuccess } from '../../../redux/tmaAnalysisSettings/tmaAnalysisSettingsActions';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

interface ConnectedSkillsPassportHeaderProps {
    onUserResponse?: (user: User) => void;
    className?: string;
}

const ConnectedSkillsPassportHeader: FC<ConnectedSkillsPassportHeaderProps> = ({
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { candidateUuid } = useParams<{ 'candidateUuid': string }>();

    const user = useTypedSelector(state => state.userReducer.user);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const skillsPassport = useTypedSelector(state => state.skillsPassportReducer);
    const skillsPassportError = useTypedSelector(state => state.skillsPassportReducer.error);

    const tmaAnalysisSettingsIsLoading = useTypedSelector(state => state.tmaAnalysisSettingsReducer.isLoading);
    const tmaAnalysisSettingsIsSuccessful = useTypedSelector(state => state.tmaAnalysisSettingsReducer.isSuccessful);
    const tmaAnalysisSettingsError = useTypedSelector(state => state.tmaAnalysisSettingsReducer.error);
    const tmaAnalysisSettings = useTypedSelector(state => state.tmaAnalysisSettingsReducer.tmaAnalysisSettings);

    useEffect((): void => {
        if (userHasRole(UserRole.candidate, user?.roles) && user?.uuid !== candidateUuid) {
            navigate(RoutePaths.notFound(), { replace: true });
        }
    }, [navigate, candidateUuid, user]);

    useEffectOnce((): void => {
        if (candidateUuid) {
            dispatch(fetchSkillsPassport(candidateUuid));
        }
    });

    useEffect((): void => {
        if (onUserResponse && user) {
            onUserResponse(user);
        }
    }, [onUserResponse, user]);

    const handleSubmitTmaAnalysisSettings = (tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData): void => {
        if (candidate) {
            dispatch(postTmaAnalysisSettings(candidate.uuid, tmaAnalysisSettingsFormData));
        }
    };

    const handleFinishTmaAnalysisSettings = (): void => {
        dispatch(updateTmaAnalysisSettingsSuccess(false));
    };

    const handleRefresh = (): void => {
        dispatch(setIsUpToDate(true));

        if (candidateUuid) {
            dispatch(fetchSkillsPassport(candidateUuid));

            if (!tmaAnalysisSettings?.isFinished) dispatch(importSkillsPassport(candidateUuid));
        }
    };

    const resetIsUpToDate = (): void => {
        dispatch(setIsUpToDate(false));
    };

    return (
        <SkillsPassportHeader
            user={user}
            candidate={candidate}
            handleRefresh={handleRefresh}
            resetIsUpToDate={resetIsUpToDate}
            //
            skillsPassportIsLoading={skillsPassportIsLoading}
            skillsPassportError={skillsPassportError}
            skillsPassport={skillsPassport}
            //
            tmaAnalysisSettingsIsLoading={tmaAnalysisSettingsIsLoading}
            tmaAnalysisSettingsIsSuccessful={tmaAnalysisSettingsIsSuccessful}
            tmaAnalysisSettings={tmaAnalysisSettings}
            tmaAnalysisSettingsError={tmaAnalysisSettingsError}
            onSubmitTmaAnalysisSettings={handleSubmitTmaAnalysisSettings}
            onFinishTmaAnalysisSettings={handleFinishTmaAnalysisSettings}
            className={className}
        />
    );
};

export default ConnectedSkillsPassportHeader;
