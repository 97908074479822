/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconEducation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="m50,5.46022l-49.85417,29.5543l18.12879,10.73806l0,29.5543l31.72538,18.81624l31.72538,-18.81624l0,-29.5543l9.06439,-5.36903l0,34.0367l9.06439,0l0,-39.40573l-49.85417,-29.5543m30.90958,29.5543l-30.90958,18.32366l-30.90958,-18.32366l30.90958,-18.32366l30.90958,18.32366m-8.2486,34.48001l-22.66098,13.39795l-22.66098,-13.39795l0,-18.37292l22.66098,13.4472l22.66098,-13.4472l0,18.37292z" />
    </svg>
);

export default IconEducation;
/* eslint-enable max-len */
