import { matchingServiceApiUrl } from '../constants';
import { authorisedFetch } from '../helpers/authorisedFetch';
import { lloFetch } from '../helpers/lloFetch';
import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { transformJobdiggerVacancyToVacancy } from '../models/JobdiggerVacancy';
import { transformToVacancy, Vacancy } from '../models/Vacancy';

export const getVacancyApiCall = async (vacancyUuid: string): Promise<FetchResult<Vacancy, string>> => {
    try {
        const vacancyDetailResponse = await authorisedFetch(`${matchingServiceApiUrl}/vacancies?vacancyId=${vacancyUuid}`);

        if (!vacancyDetailResponse.ok) {
            return {
                status: vacancyDetailResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const response = await vacancyDetailResponse.json();
        const vacancy = transformToVacancy(response);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: vacancy,
        };
    } catch (error) {
        console.error('[getVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getJobdiggerVacancyApiCall = async (vacancyUuid: string, candidateUuid: string): Promise<FetchResult<Vacancy, string>> => {
    try {
        const vacancyDetailResponse = await lloFetch(`/api/jobdiggerVacancies/${vacancyUuid}?userLanguage=nl-NL`, {
            candidateUuid,
        });

        if (!vacancyDetailResponse.ok) {
            return {
                status: vacancyDetailResponse.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const response = await vacancyDetailResponse.json();
        const vacancy = transformJobdiggerVacancyToVacancy(response.jobs[0]);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: vacancy,
        };
    } catch (error) {
        console.error('[getJobdiggerVacancyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
