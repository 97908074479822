import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import {
    defaultOccupationFilterValues,
    defaultOccupationOverview,
    OccupationFilterValues,
    OccupationOverview,
} from '../../models/OccupationOverview/OccupationOverview';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState, SearchableOption } from '../../types';

export type OccupationOverviewState = AsyncReduxState<{
    suggestionsIsLoading: boolean;
    activeFilterValues: OccupationFilterValues;
    occupationOverview: OccupationOverview;
    occupationSuggestions: SearchableOption[];
    pagination?: Pagination;
}>;

const initialState: OccupationOverviewState = {
    ...initialAsyncReduxState,
    isLoading: true,
    suggestionsIsLoading: false,
    activeFilterValues: defaultOccupationFilterValues(),
    occupationOverview: defaultOccupationOverview(),
    occupationSuggestions: [],
};

export const occupationOverviewSlice = createSlice({
    name: 'occupationOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OccupationOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setSuggestionsIsLoading(state, action: PayloadAction<boolean>): OccupationOverviewState {
            return {
                ...state,
                suggestionsIsLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OccupationOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<OccupationFilterValues>): OccupationOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setOccupationOverview(state, action: PayloadAction<OccupationOverview>): OccupationOverviewState {
            return {
                ...state,
                occupationOverview: action.payload,
            };
        },
        setOccupationSuggestions(state, action: PayloadAction<SearchableOption[]>): OccupationOverviewState {
            return {
                ...state,
                occupationSuggestions: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): OccupationOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setSuggestionsIsLoading,
    setError,
    setActiveFilterValues,
    setOccupationOverview,
    setOccupationSuggestions,
    setPagination,
} = occupationOverviewSlice.actions;

export default occupationOverviewSlice.reducer;
