import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedCacheBuster, ConnectedDashboard, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';
import { User } from '../../models/User/User';
import { getDashboardTitle } from './helpers';

import './Dashboard.scss';

const Dashboard: FC = (): ReactElement => {
    const [user, setUser] = useState<User>();

    const title = getDashboardTitle(user);

    return (
        <ConnectedPage
            hasNavigation
            title={title}
            className="dashboard-page"
        >
            <Helmet>
                <title>{trans('pages.dashboard.title.common')}</title>
            </Helmet>

            <ConnectedDashboard
                onUserResponse={setUser}
                className="dashboard-page__wrapper"
            />

            <ConnectedCacheBuster />
        </ConnectedPage>
    );
};

export default Dashboard;
