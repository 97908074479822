import { FC, ReactElement } from 'react';

import { IconButton } from '../../../../compositions';
import trans from '../../../../helpers/trans';

import './PaginationButton.scss';

export enum Direction {
    Prev,
    Next,
}

interface PaginationButtonProps {
    direction: Direction;
    disabled: boolean;
    onClick: () => void;
}

const PaginationButton: FC<PaginationButtonProps> = ({
    direction,
    disabled,
    onClick,
}): ReactElement => {
    const buttonIcon = direction === Direction.Prev
        ? 'chevron-left'
        : 'chevron-right';

    const buttonLabel = direction === Direction.Prev
        ? trans('containers.pagination.previousPageLabel')
        : trans('containers.pagination.nextPageLabel');

    return (
        <IconButton
            icon={buttonIcon}
            text={buttonLabel}
            hideLabel
            disabled={disabled}
            onClick={onClick}
            className="pagination-button"
        />
    );
};

export default PaginationButton;
