/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconArrowLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M100,56.3V43.7H24.2L59,9l-9-9L0,50l50,50l9-9L24.2,56.3H100z" />
    </svg>
);

export default IconArrowLeft;
/* eslint-enable max-len */
