import { isValidDateString } from '../helpers/date';
import { sumCompensation, sumEmploymentHourRange } from '../helpers/vacancy';
import { SalaryTimeframe, Vacancy, VacancyOrigin } from './Vacancy';

interface LocationCoordinates {
    lat: string;
    long: string;
}

export interface JobdiggerVacancyResource {
    Reference: string;
    DateFound: string;
    DateDeactivated: string;
    DateUpdated: string;
    ActiveWeeks: string;
    OrganizationName: string;
    OrganizationId: string;
    OrganizationNameGeneralized: string;
    NoAcquisition: string;
    KVK: string;
    Intermediate: string;
    NrOfEmployees: string;
    IndustryCode: string;
    IndustryDescription: string;
    ContactName: string;
    ContactGender: string;
    ContactJobTitle: string;
    ContactTelephone: string;
    ContactEmailAddress: string;
    OrganizationCountryCode: string;
    OrganizationPostalCode: string;
    OrganizationStreetName: string;
    OrganizationPlace: string;
    OrganizationCoordinates: LocationCoordinates;
    PhysicalLocationProvince: string;
    PhysicalLocationMunicipality: string;
    PhysicalLocationPostalCode: string;
    PhysicalLocationCoordinates: LocationCoordinates;
    PhysicalLocationCountryCode: string;
    PhysicalLocationPlace: string;
    ISCOID: string;
    ISCOCode: string;
    ISCODescription: string;
    JDCOCode: string;
    JDCODescription: string;
    PositionTitle: string;
    PositionTitleCleaned: string;
    PositionTitleGeneralized: string;
    PositionTitlePopularSynonym: string;
    BasePayAmount: string;
    BasePayAmountAverage: string;
    ContractDuration: string;
    TypeOfContract: string[];
    WorkingHoursFrom: string;
    WorkingHoursTo: string;
    EducationDegree: string[];
    EducationDegreeMinimumRequired: string;
    YearsOfRelevantWorkingExperience: string;
    GroupID: string;
    GroupLeader: string;
    URIHost: string;
    URL: string;
    IsJobboard: string;
    Excerpt: string;
    ExcerptWithoutHTML: string;
    Fulltext: string;
    Requirements: string;
    Tasks: string;
    Application: string;
    WorkingConditions: string;
    WorkingExperienceIndicator: string;
    Language: string;
    Deleted: string;
    EscoCompetence: string[];
    EscoComperenceRequired: string[];
    EscoCompetenceOptional: string[];
    EscoKnowledge: string[];
    EscoKnowledgeRequired: string[];
    EscoKnowledgdeOptional: string[];
    EscoUuid: string;
    Skills: string[];
    BasePayAmountHour: string;
    BasePayAmountAverageHour: number;
    BasePayAmountMinHour: number;
    BasePayAmountMaxHour: number;
    EducationDegreeMinimumRequiredDetailed: string;
}

export const transformJobdiggerVacancyToVacancy = (
    jobdiggerVacancyResource: JobdiggerVacancyResource,
): Vacancy => {
    const publicationDate = isValidDateString(jobdiggerVacancyResource.DateFound)
        ? new Date(jobdiggerVacancyResource.DateFound)
        : new Date();

    const transformToCompensation = (): string => {
        if (jobdiggerVacancyResource.BasePayAmount === 'unknown') return '';

        const [minPayAmount, maxPayAmount] = jobdiggerVacancyResource.BasePayAmount.split(' - ');

        if (minPayAmount && maxPayAmount) {
            const min = minPayAmount.replace('.', '');
            const max = maxPayAmount.replace('.', '');

            return sumCompensation(SalaryTimeframe.month, {
                min: Number(min),
                max: Number(max),
            });
        }

        return '';
    };

    const description = `<p>${jobdiggerVacancyResource.Excerpt}</p><p>${jobdiggerVacancyResource.Application}</p>`;

    const transformDecimalToHexadecimal = (decimal: number): string => {
        const hexadecimal = decimal.toString(16);
        return hexadecimal.padStart(8, '0');
    };

    const employmentHourRange = jobdiggerVacancyResource.WorkingHoursFrom && jobdiggerVacancyResource.WorkingHoursTo
        ? sumEmploymentHourRange({
            min: Number(jobdiggerVacancyResource.WorkingHoursFrom),
            max: Number(jobdiggerVacancyResource.WorkingHoursTo),
        })
        : '';

    return {
        uuid: jobdiggerVacancyResource.Reference,
        isAnonymous: false,
        origin: VacancyOrigin.jobdigger,
        title: `${jobdiggerVacancyResource.PositionTitle} bij ${jobdiggerVacancyResource.OrganizationName}`,
        description,
        companyName: jobdiggerVacancyResource.OrganizationName,
        logo: '',
        city: jobdiggerVacancyResource.PhysicalLocationPlace,
        publicationDate,
        externalUrl: jobdiggerVacancyResource.URL,
        skills: jobdiggerVacancyResource.EscoCompetence.map(competence => transformDecimalToHexadecimal(Number(competence))),
        competencies: [],
        fieldsOfStudy: [],
        properties: {
            jobType: !jobdiggerVacancyResource.TypeOfContract.includes('unknown')
                ? jobdiggerVacancyResource.TypeOfContract.join(', ')
                : '',
            amountOfEmployees: jobdiggerVacancyResource.NrOfEmployees !== 'unknown'
                ? `${jobdiggerVacancyResource.NrOfEmployees} medewerkers`
                : '',
            educationLevels: !jobdiggerVacancyResource.EducationDegree.includes('unknown')
                ? jobdiggerVacancyResource.EducationDegree
                : [jobdiggerVacancyResource.EducationDegreeMinimumRequired],
            employmentHourRange,
            compensation: transformToCompensation(),
            sector: jobdiggerVacancyResource.ISCODescription,
            workRemotePreference: [],
        },
    };
};
