import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { ErrorLabel, InputLabel } from '../../../components';
import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';
import { Checkbox, IconButton } from '../..';

import './CheckboxList.scss';

interface CheckboxListProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    maySelectOther?: boolean;
    label: string;
    hideLabel?: boolean;
    value: string[];
    options: FormOption[];
    showLessThreshold?: number;
    error?: string;
    onChange: (values: string[]) => void;
    className?: string;
}

const CheckboxList: FC<CheckboxListProps> = ({
    label,
    hideLabel = false,
    options,
    value,
    showLessThreshold = 0,
    required = false,
    error = '',
    onChange,
    className = '',
    ...checkboxProps
}): ReactElement => {
    const [showLessOptions, toggleShowLessOptions] = useToggle(!!showLessThreshold);

    const handleSelectCheckbox = (option: FormOption, isChecked: boolean): void => {
        const newCheckedOptions = isChecked
            ? [...value, option.value]
            : [...value].filter(selected => selected !== option.value);

        onChange(newCheckedOptions);
    };

    const checkboxListClassNames = classNames('checkbox-list__options-wrapper', {
        'checkbox-list__options-wrapper--has-error': !!error,
    });

    return (
        <div className={`checkbox-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="checkbox-list__label"
                />
            )}

            <div className={checkboxListClassNames}>
                {options.map((option, index) => {
                    const isChecked = value.includes(option.value);

                    const handleSelect = (checked: boolean): void => handleSelectCheckbox(option, checked);

                    const optionClassNames = classNames('checkbox-list__option', {
                        'checkbox-list__option--is-hidden': showLessOptions && index >= showLessThreshold && !isChecked,
                    });

                    return (
                        <Checkbox
                            {...checkboxProps}
                            key={option.value}
                            label={option.label}
                            checked={isChecked}
                            onChange={handleSelect}
                            className={optionClassNames}
                        />
                    );
                })}
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="checkbox-list__error-label"
                />
            )}

            {showLessThreshold && options.length > showLessThreshold ? (
                <IconButton
                    icon={showLessOptions ? 'chevron-down' : 'chevron-up'}
                    text={showLessOptions ? trans('common.show.more') : trans('common.show.less')}
                    onClick={toggleShowLessOptions}
                    className="checkbox-list__toggle-button"
                />
            ) : null}
        </div>
    );
};

export default CheckboxList;
