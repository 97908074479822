import { fulltimeThreshold } from '../constants';
import { SalaryTimeframe } from '../models/Vacancy';
import { MinMaxValue } from '../types';
import { convertNumberToCurrency } from './number';
import trans from './trans';

export const sumEmploymentHourRange = (employmentHourRange: MinMaxValue): string => {
    const { min, max } = employmentHourRange;

    if (max === 0) return '';

    if (min === max) {
        const hours = `${max}`;

        return min >= fulltimeThreshold
            ? trans('models.vacancy.properties.employmentHourRange.fulltimeSpecific', { hours })
            : trans('models.vacancy.properties.employmentHourRange.parttimeSpecific', { hours });
    }

    const hours = {
        min: `${min}`,
        max: `${max}`,
    };

    return min >= fulltimeThreshold
        ? trans('models.vacancy.properties.employmentHourRange.fulltime', hours)
        : trans('models.vacancy.properties.employmentHourRange.parttime', hours);
};

export const sumCompensation = (timeframe: SalaryTimeframe, compensation: MinMaxValue): string => {
    const { min, max } = compensation;

    if (max === 0) return '';

    if (timeframe === SalaryTimeframe.hour) {
        const hourlyCompensation = {
            min: convertNumberToCurrency(min / 100),
            max: convertNumberToCurrency(max / 100),
        };

        return min === max
            ? trans('models.vacancy.properties.compensation.hourSpecific', {
                compensation: hourlyCompensation.max,
            })
            : trans('models.vacancy.properties.compensation.hour', hourlyCompensation);
    }

    return min === max
        ? trans('models.vacancy.properties.compensation.monthSpecific', {
            compensation: convertNumberToCurrency(max),
        })
        : trans('models.vacancy.properties.compensation.month', {
            min: convertNumberToCurrency(min),
            max: convertNumberToCurrency(max),
        });
};
