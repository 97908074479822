import { FC, ReactElement } from 'react';

import { InternalOccupationFilters } from '../../../containers';
import { setActiveFilterValues } from '../../../redux/internalOccupationOverview/internalOccupationOverview';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedInternalOccupationFiltersProps {
    className?: string;
}

const ConnectedInternalOccupationFilters: FC<ConnectedInternalOccupationFiltersProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.internalOccupationOverviewReducer.isLoading);
    const activeFilterValues = useTypedSelector(state => state.internalOccupationOverviewReducer.activeFilterValues);

    const handleSearchSubmit = (value: string): void => {
        const newFilterValues = {
            ...activeFilterValues,
            pageNumber: 1,
            searchQuery: value,
        };

        dispatch(setActiveFilterValues(newFilterValues));
    };

    return (
        <InternalOccupationFilters
            isLoading={isLoading}
            activeFilterValues={activeFilterValues}
            onSearchSubmit={handleSearchSubmit}
            className={className}
        />
    );
};

export default ConnectedInternalOccupationFilters;
