import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import {
    ConnectedPage,
    ConnectedSkillsPassportBreadcrumb,
    ConnectedSkillsPassportHeader,
    ConnectedSkillsPassportTabs,
} from '../../connectors';
import { getRoleForTranslation } from '../../helpers/role';
import trans from '../../helpers/trans';
import { User } from '../../models/User/User';

import './SkillsPassport.scss';

const SkillsPassport: FC = (): ReactElement => {
    const [user, setUser] = useState<User>();

    const title = user
        ? trans(`pages.skillsPassport.title.${getRoleForTranslation(user.roles)}`)
        : '';

    return (
        <ConnectedPage
            hasNavigation
            title={title}
            className="skills-passport-page"
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <ConnectedSkillsPassportBreadcrumb />

            <ConnectedSkillsPassportHeader
                onUserResponse={setUser}
                className="skills-passport-page__header"
            />

            <ConnectedSkillsPassportTabs className="skills-passport-page__tabs" />
        </ConnectedPage>
    );
};

export default SkillsPassport;
