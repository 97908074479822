import { FC, ReactElement, useMemo } from 'react';

import classNames from 'classnames';

import { CounselorNavigation, Navigation } from '../../compositions';
import { removeDisabledModulesFromNavigation } from '../../helpers/navigation';
import { userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { counselorCandidateMainNavigationRoutes, userMainNavigationRoutes } from '../../helpers/user';
import { UserModule } from '../../models/Modules/Modules';
import { NavigationRoute, navigationRoutes } from '../../Routes';
import { FormOption, UserRole } from '../../types';
import { EnvironmentLogo } from './subcomponents';

import './Menu.scss';

interface MenuProps {
    isLoading: boolean;
    menuIsOpen: boolean;
    candidateOptions: FormOption[];
    candidateUuid?: string;
    userUuid?: string;
    userRoles?: UserRole[];
    userModules?: UserModule[];
    selectedCandidateOption?: FormOption;
    organisationUuid?: string;
    onCandidateSelect: (candidateId: string) => void;
    className?: string;
}

const Menu: FC<MenuProps> = ({
    isLoading,
    menuIsOpen,
    candidateOptions,
    candidateUuid,
    userUuid,
    userRoles = [],
    userModules = [],
    selectedCandidateOption,
    organisationUuid,
    onCandidateSelect,
    className = '',
}): ReactElement => {
    const organisationAdminAllowedRoutes = userMainNavigationRoutes[UserRole.organisationAdmin];
    const organisationAdminNavigationRoutes = navigationRoutes.filter(route => organisationAdminAllowedRoutes.includes(route.key));

    const candidateAllowedRoutes = userMainNavigationRoutes[UserRole.candidate];
    const candidateNavigationRoutes = navigationRoutes.filter(route => candidateAllowedRoutes.includes(route.key));

    const counselorAllowedRoutes = userMainNavigationRoutes[UserRole.counselor];
    const tmaExpertAllowedRoutes = userMainNavigationRoutes[UserRole.tmaExpert];

    const counselorCandidateNavigationRoutes = navigationRoutes
        .filter(route => counselorCandidateMainNavigationRoutes.includes(route.key));

    const counselorNavigationItems = useMemo((): NavigationRoute[] => {
        const isOrganisationAdmin = userHasRole(UserRole.organisationAdmin, userRoles);
        const isTmaExpert = userHasRole(UserRole.tmaExpert, userRoles);

        const counselorNavigationRoutes = navigationRoutes.filter(route => counselorAllowedRoutes.includes(route.key));
        const tmaExpertNavigationRoutes = navigationRoutes.filter(route => tmaExpertAllowedRoutes.includes(route.key));

        const baseNavigationRoutes = isTmaExpert ? tmaExpertNavigationRoutes : counselorNavigationRoutes;

        return isOrganisationAdmin
            ? [...baseNavigationRoutes, ...organisationAdminNavigationRoutes]
            : baseNavigationRoutes;
    }, [userRoles]);

    const menuClassNames = classNames('menu', {
        'menu--is-open': menuIsOpen,
    }, className);

    return (
        <div className={menuClassNames}>
            {userHasRole(UserRole.candidate, userRoles) && (
                <Navigation
                    candidateUuid={userUuid}
                    navigationLabel={trans('containers.menu.navigationAriaLabel')}
                    navigationItems={removeDisabledModulesFromNavigation(candidateNavigationRoutes, userModules)}
                    className="menu__navigation"
                />
            )}

            {!userHasRole(UserRole.candidate, userRoles) && (
                <CounselorNavigation
                    isLoading={isLoading}
                    candidateOptions={candidateOptions}
                    candidateNavigationItems={removeDisabledModulesFromNavigation(counselorCandidateNavigationRoutes, userModules)}
                    counselorNavigationItems={counselorNavigationItems}
                    navigationLabel={trans('containers.menu.navigationAriaLabel')}
                    selectedCandidateOption={selectedCandidateOption}
                    onCandidateSelect={onCandidateSelect}
                    candidateUuid={candidateUuid}
                    userUuid={candidateUuid}
                    userRoles={userRoles}
                    organisationUuid={organisationUuid}
                    className="menu__navigation"
                />
            )}

            <EnvironmentLogo
                userRoles={userRoles}
                className="menu__logo"
            />
        </div>
    );
};

export default Menu;
