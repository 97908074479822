/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconDevelopment: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path className="st0" d="M75,10v30L65,30L55,40V10H35v80h50V10H75z M5,25V15h10v-5c0-5.55,4.5-10,10-10h60c5.25,0,10,4.75,10,10v80 c0,5.25-4.75,10-10,10H25c-5.25,0-10-4.75-10-10v-5H5V75h10V55H5V45h10V25H5z M15,15v10h10V15H15z M15,85h10V75H15V85z M15,55h10V45 H15V55z" />
    </svg>
);

export default IconDevelopment;
/* eslint-enable max-len */
