import {
    OrganisationOccupationOverview,
    OrganisationOccupationOverviewItem,
    OrganisationOccupationOverviewItemResource,
    OrganisationOccupationOverviewResource,
} from './OrganisationOccupationOverview';

export const transformToOrganisationOccupatioOverviewItem = (
    organisationOccupationOverviewItemResource: OrganisationOccupationOverviewItemResource,
): OrganisationOccupationOverviewItem => ({
    id: organisationOccupationOverviewItemResource.id,
    title: organisationOccupationOverviewItemResource.title,
    description: organisationOccupationOverviewItemResource.description,
});

export const transformToOrganisationOccupationOverview = (
    organisationOccupationOverviewResource: OrganisationOccupationOverviewResource,
): OrganisationOccupationOverview => ({
    totalAmount: organisationOccupationOverviewResource.totalAmount,
    occupations: organisationOccupationOverviewResource.occupations.map(transformToOrganisationOccupatioOverviewItem),
});
