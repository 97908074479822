import { FC, ReactElement, useMemo } from 'react';

import { Card, SafeHtml } from '../../../../../components';
import { TagList } from '../../../../../compositions';
import { getRoleForTranslation } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { Competency } from '../../../../../models/Competencies/Competencies';
import { Skill } from '../../../../../models/Skills/Skills';
import { Vacancy, VacancyOrigin } from '../../../../../models/Vacancy';
import { UserRole } from '../../../../../types';

import './VacancyDetailBody.scss';

export interface VacancyDetailBodyProps {
    userRoles: UserRole[];
    vacancy: Vacancy;
    skills: Skill[];
    candidateCompetencies: Competency[];
    candidateSkills: Skill[];
    className?: string;
}

const VacancyDetailBody: FC<VacancyDetailBodyProps> = ({
    userRoles,
    vacancy,
    skills,
    candidateCompetencies,
    candidateSkills,
    className = '',
}): ReactElement => {
    const skillsFromEscoSkills = useMemo((): string[] => {
        const filteredEscoSkills = skills.filter(skill => {
            if (!skill.escoUuid) return false;

            const shortUuid = skill.escoUuid.substring(0, 8);

            return vacancy.skills.includes(shortUuid);
        });

        return filteredEscoSkills.map(skill => skill.name);
    }, [skills]);

    return (
        <article className={`vacancy-detail-body ${className}`}>
            <Card className="vacancy-detail-body__card">
                <h2 className="vacancy-detail-body__card-title">
                    {trans('containers.vacancyDetail.about.title')}
                </h2>

                <SafeHtml
                    html={vacancy.description}
                    className="vacancy-detail-body__about-description"
                />
            </Card>

            <Card className="vacancy-detail-body__card">
                <h2 className="vacancy-detail-body__card-title">
                    {trans(`containers.vacancyDetail.knowledge.title.${getRoleForTranslation(userRoles)}`)}
                </h2>

                <p className="vacancy-detail-body__card-description">
                    {trans(`containers.vacancyDetail.knowledge.description.${getRoleForTranslation(userRoles)}`)}
                </p>

                {vacancy.competencies.length > 0 && (
                    <TagList
                        title={trans('containers.vacancyDetail.knowledge.competencies.title')}
                        description={trans('containers.vacancyDetail.knowledge.competencies.description')}
                        tags={vacancy.competencies}
                        compareTags={candidateCompetencies.map(competency => competency.label)}
                        className="vacancy-detail-body__tag-list"
                    />
                )}

                <TagList
                    title={trans('containers.vacancyDetail.knowledge.skills.title')}
                    description={trans('containers.vacancyDetail.knowledge.skills.description')}
                    tags={vacancy.origin === VacancyOrigin.jobdigger ? skillsFromEscoSkills : vacancy.skills}
                    compareTags={candidateSkills.map(skill => skill.name)}
                    className="vacancy-detail-body__tag-list"
                />
            </Card>
        </article>
    );
};

export default VacancyDetailBody;
