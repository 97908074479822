/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 10 8" className={`icon ${className}`}>
        <path d="M8.825 0L3.33333 5.5966L1.175 3.40552L0 4.60297L3.33333 8L10 1.20594L8.825 0Z" />
    </svg>
);

export default IconCheck;
/* eslint-enable max-len */
