import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './OrganisationDetailHeaderSkeletons.scss';

interface OrganisationDetailHeaderSkeletonsProps {
    className?: string;
}

const OrganisationDetailHeaderSkeletons: FC<OrganisationDetailHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const descriptionLineSkeletons = generateIdArray(3);

    return (
        <div className={`organisation-detail-header-skeletons ${className}`}>
            <Card className="organisation-detail-header-skeletons__card">
                <Skeleton className="organisation-detail-header-skeletons__name" />
                <Skeleton className="organisation-detail-header-skeletons__location" />
            </Card>

            <Card className="organisation-detail-header-skeletons__card">
                <Skeleton className="organisation-detail-header-skeletons__external-id-title" />

                {descriptionLineSkeletons.map(descriptionLine => (
                    <Skeleton
                        key={descriptionLine}
                        className="organisation-detail-header-skeletons__external-id-description"
                    />
                ))}

                <div className="organisation-detail-header-skeletons__copyable-value" />
            </Card>
        </div>
    );
};

export default OrganisationDetailHeaderSkeletons;
