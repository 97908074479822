import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Vacancy } from '../../models/Vacancy';
import { AsyncReduxState } from '../../types';

export type VacancyState = AsyncReduxState<{
    vacancy?: Vacancy;
}>;

const initialState: VacancyState = {
    ...initialAsyncReduxState,
};

export const vacancySlice = createSlice({
    name: 'vacancyReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacancyState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacancyState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setVacancy(state, action: PayloadAction<Vacancy>): VacancyState {
            return {
                ...state,
                vacancy: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setVacancy,
} = vacancySlice.actions;

export default vacancySlice.reducer;
