import { FC, ReactElement } from 'react';

import { maxEmploymentHoursPerWeek } from '../../../constants';
import { addHourUnit } from '../../../helpers/string';
import { MinMaxValue } from '../../../types';
import { RangeInput } from '../..';

interface EmploymentFilterProps {
    label: string;
    hideLabel?: boolean;
    value: MinMaxValue;
    tabIndex?: number;
    onChange: (value: MinMaxValue) => void;
    className?: string;
}

const EmploymentFilter: FC<EmploymentFilterProps> = ({
    label,
    hideLabel,
    value,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`employment-filter ${className}`}>
        <RangeInput
            label={label}
            hideLabel={hideLabel}
            value={value}
            max={maxEmploymentHoursPerWeek}
            step={2}
            hiddenMarginPercentage={15}
            valueFormat={addHourUnit}
            marks={5}
            tabIndex={tabIndex}
            onChange={onChange}
        />
    </div>
);

export default EmploymentFilter;
