/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconNumerical: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M 10 75 L 10 35 L 0 35 L 0 25 L 20 25 L 20 75 L 10 75 Z M 100 65 C 100 70.55 95.5 75 90 75 L 70 75 L 70 65 L 90 65 L 90 55 L 80 55 L 80 45 L 90 45 L 90 35 L 70 35 L 70 25 L 90 25 C 92.65 25 95.195 26.055 97.07 27.93 C 98.945 29.805 100 32.35 100 35 L 100 42.5 C 100 44.49 99.21 46.395 97.805 47.805 C 96.395 49.21 94.49 50 92.5 50 C 94.49 50 96.395 50.79 97.805 52.195 C 99.21 53.605 100 55.51 100 57.5 L 100 65 Z M 60 65 L 60 75 L 30 75 L 30 55 C 30 49.45 34.5 45 40 45 L 50 45 L 50 35 L 30 35 L 30 25 L 50 25 C 52.65 25 55.195 26.055 57.07 27.93 C 58.945 29.805 60 32.35 60 35 L 60 45 C 60 50.55 55.5 55 50 55 L 40 55 L 40 65 L 60 65 Z" />
    </svg>
);

export default IconNumerical;
/* eslint-enable max-len */
