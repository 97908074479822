/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconEye: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 16 11" className={`icon ${className}`}>
        <path d="M8 0C4.36364 0 1.25818 2.26182 0 5.45455C1.25818 8.64727 4.36364 10.9091 8 10.9091C11.6364 10.9091 14.7418 8.64727 16 5.45455C14.7418 2.26182 11.6364 0 8 0ZM8 9.09091C5.99273 9.09091 4.36364 7.46182 4.36364 5.45455C4.36364 3.44727 5.99273 1.81818 8 1.81818C10.0073 1.81818 11.6364 3.44727 11.6364 5.45455C11.6364 7.46182 10.0073 9.09091 8 9.09091ZM8 3.27273C6.79273 3.27273 5.81818 4.24727 5.81818 5.45455C5.81818 6.66182 6.79273 7.63636 8 7.63636C9.20727 7.63636 10.1818 6.66182 10.1818 5.45455C10.1818 4.24727 9.20727 3.27273 8 3.27273Z" />
    </svg>
);

export default IconEye;
/* eslint-enable max-len */
