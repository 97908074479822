/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgFlagProps } from '../Flag';

import '../Flag.scss';

const FlagNL: FC<SvgFlagProps> = ({ className = '' }): ReactElement => {
    const colors = {
        vermilion: '#ae1c28',
        white: '#ffffff',
        cobalt: '#21468b',
    };

    return (
        <svg viewBox="0 0 300 200" className={`flag ${className}`}>
            <rect fill={colors.cobalt} width="300" height="200" />
            <rect fill={colors.white} width="300" height="133.2" />
            <rect fill={colors.vermilion} width="300" height="66.6" />
        </svg>
    );
};

export default FlagNL;
/* eslint-enable max-len */
