import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { CounselorOption } from '../../models/Counselor/Counselor';
import { AsyncReduxState } from '../../types';

export interface CounselorOptionsState extends AsyncReduxState {
    options: CounselorOption[];
}

const initialState: CounselorOptionsState = {
    ...initialAsyncReduxState,
    options: [],
};

export const counselorOptionsSlice = createSlice({
    name: 'counselorOptionsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CounselorOptionsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CounselorOptionsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CounselorOptionsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOptions(state, action: PayloadAction<CounselorOption[]>): CounselorOptionsState {
            return {
                ...state,
                options: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setOptions,
} = counselorOptionsSlice.actions;

export default counselorOptionsSlice.reducer;
