import { FC, ReactElement } from 'react';

import { ConnectedLearningStyleCard } from '../../../../../connectors';

import './LearningStyle.scss';

interface LearningStyleProps {
    onRefresh: () => void;
}

const LearningStyle: FC<LearningStyleProps> = ({ onRefresh }): ReactElement => (
    <ConnectedLearningStyleCard
        onRefresh={onRefresh}
        className="learning-style-tab__card"
    />
);

export default LearningStyle;
