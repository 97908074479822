import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { InternalOccupationDetailHeader } from '../../../containers';
import { Candidate } from '../../../models/Candidate/Candidate';
import { InternalOccupationDetail } from '../../../models/InternalOccupationDetail/InternalOccupationDetail';
import { fetchInternalOccupationDetail } from '../../../redux/internalOccupationDetail/internalOccupationDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedInternalOccupationDetailHeaderProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onInternalOccupationDetailResponse: (occupationDetail: InternalOccupationDetail) => void;
    className?: string;
}

const ConnectedInternalOccupationDetailHeader: FC<ConnectedInternalOccupationDetailHeaderProps> = ({
    onCandidateResponse,
    onInternalOccupationDetailResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { occupationUuid } = useParams();

    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);

    const occupationIsLoading = useTypedSelector(state => state.internalOccupationDetailReducer.isLoading);
    const occupationError = useTypedSelector(state => state.internalOccupationDetailReducer.error);
    const occupation = useTypedSelector(state => state.internalOccupationDetailReducer.internalOccupation);

    const pageIsLoading = [
        candidateIsLoading,
        occupationIsLoading,
        skillsPassportIsLoading,
    ].some(Boolean);

    useEffect((): void => {
        if (occupation) onInternalOccupationDetailResponse(occupation);
    }, [occupation]);

    useEffect((): void => {
        if (!candidate) return;

        onCandidateResponse(candidate);

        if (candidate.uuid && occupationUuid) {
            dispatch(fetchInternalOccupationDetail({
                occupationUuid,
                candidateUuid: candidate.uuid,
            }));
        }
    }, [candidate]);

    return (
        <InternalOccupationDetailHeader
            pageIsLoading={pageIsLoading}
            occupationError={occupationError}
            occupation={occupation}
            className={className}
        />
    );
};

export default ConnectedInternalOccupationDetailHeader;
