import { FC, ReactElement } from 'react';

import Breadcrumbs, { Breadcrumb } from '../../../compositions/Breadcrumbs/Breadcrumbs';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { useTypedSelector } from '../../../redux/store';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';

interface ConnectedDevelopmentPlanBreadcrumbsProps {
    className?: string;
}

const ConnectedDevelopmentPlanBreadcrumbs: FC<ConnectedDevelopmentPlanBreadcrumbsProps> = ({
    className = '',
}): ReactElement | null => {
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const user = useTypedSelector(state => state.userReducer.user);
    const developmentPlanTitle = useTypedSelector(state => state.developmentPlanReducer.developmentPlan?.title);

    const crumbs: Breadcrumb[] = userHasRole(UserRole.counselor, user?.roles) ? ([
        {
            label: trans('routes.dashboard'),
            to: RoutePaths.dashboard(),
        },
        {
            label: candidate?.fullName || '',
            to: RoutePaths.candidateDashboard(candidate?.uuid),
        },
        {
            label: trans('connectors.connectedDevelopmentPlanBreadcrumbs.development.counselor'),
            to: RoutePaths.development(candidate?.uuid),
        },
        {
            label: developmentPlanTitle || '',
            disabled: true,
        },
    ]) : ([
        {
            label: trans('connectors.connectedDevelopmentPlanBreadcrumbs.development.candidate'),
            to: RoutePaths.development(user?.uuid),
        },
        {
            label: developmentPlanTitle || '',
            disabled: true,
        },
    ]);

    return developmentPlanTitle ? (
        <Breadcrumbs
            crumbs={crumbs}
            className={className}
        />
    ) : null;
};

export default ConnectedDevelopmentPlanBreadcrumbs;
