import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { SkillsPassport, SkillsPassportResource } from './SkillsPassport';
import { transformToSkillsPassport } from './SkillsPassportTransformers';

export const getSkillsPassportApiCall = async (candidateUuid: string): Promise<FetchResult<SkillsPassport, string>> => {
    try {
        const response = await lloFetch(`/api/skillsPassport/${candidateUuid}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const skillsPassportResponse: SkillsPassportResource = await response.json();

        const skillsPassport = transformToSkillsPassport(skillsPassportResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: skillsPassport,
        };
    } catch (error) {
        console.error('[getSkillsPassportApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getImportSkillsPassport = async (candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/skillsPassport/import/${candidateUuid}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[getImportSkillsPassport]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
