import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { User } from '../../models/User/User';
import { AsyncReduxState } from '../../types';

export type UserState = AsyncReduxState<{
    user?: User;
    logout: () => void;
}>;

const initialState: UserState = {
    ...initialAsyncReduxState,
    user: undefined,
    logout: () => null,
};

export const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): UserState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): UserState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setUser(state, action: PayloadAction<User>): UserState {
            return {
                ...state,
                user: action.payload,
            };
        },
        setLogout(state, action: PayloadAction<() => void>): UserState {
            return {
                ...state,
                logout: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setUser,
    setLogout,
} = userSlice.actions;

export default userSlice.reducer;
