import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { DevelopmentPlanNote } from '../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import { AsyncReduxState } from '../../types';

export type DevelopmentPlanPublicNotesState = AsyncReduxState<{
    isUpdating: boolean;
    notes: DevelopmentPlanNote[];
}>;

const initialState: DevelopmentPlanPublicNotesState = {
    ...initialAsyncReduxState,
    isUpdating: false,
    notes: [],
};

export const developmentPlanPublicNotesSlice = createSlice({
    name: 'developmentPlanPublicNotesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DevelopmentPlanPublicNotesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): DevelopmentPlanPublicNotesState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DevelopmentPlanPublicNotesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DevelopmentPlanPublicNotesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDevelopmentPlanPublicNotes(state, action: PayloadAction<DevelopmentPlanNote[]>): DevelopmentPlanPublicNotesState {
            return {
                ...state,
                notes: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setIsSuccessful,
    setError,
    setDevelopmentPlanPublicNotes,
} = developmentPlanPublicNotesSlice.actions;

export default developmentPlanPublicNotesSlice.reducer;
