/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconLogout: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 90 100" className={`icon ${className}`}>
        <polygon points="90,50 65,25 65,40 30,40 30,60 65,60 65,75" />
        <path d="M55,80v10H10V10h45v10h10V10c0-5.5-4.5-10-10-10H10C4.5,0,0,4.5,0,10v80c0,5.5,4.5,10,10,10h45c5.5,0,10-4.5,10-10V80H55z" />
    </svg>
);

export default IconLogout;
/* eslint-enable max-len */
