import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon, TableCell } from '../../../components';
import trans from '../../../helpers/trans';
import { HorizontalAlignment, SortDirection, SortField } from '../../../types';

import './SortLabel.scss';

export interface SortLabelInfo {
    label: string;
    field?: SortField;
    align?: HorizontalAlignment;
    colSpan?: number;
}

interface SortLabelProps extends SortLabelInfo {
    isActive?: boolean;
    activeDirection: SortDirection;
    onSort: (field: SortField, direction: SortDirection) => void;
    className?: string;
}

const SortLabel: FC<SortLabelProps> = ({
    isActive,
    label,
    field,
    align,
    colSpan,
    activeDirection,
    onSort,
    className = '',
}): ReactElement => {
    const isAscending = activeDirection === SortDirection.asc;

    const descriptiveLabel = trans('compositions.sortLabel.descriptiveLabel', {
        label,
        sortDirection: trans(`common.sort.${activeDirection?.toLowerCase()}`),
    });

    const handleClick = (): void => {
        const newDirection: SortDirection = isAscending ? SortDirection.desc : SortDirection.asc;

        if (field) onSort(field, newDirection);
    };

    const sortLabelClassNames = classNames('sort-label', {
        'sort-label--is-active': isActive,
        [`sort-label--align-${align}`]: align,
    }, className);

    return (
        <TableCell align={align} colSpan={colSpan} className={sortLabelClassNames}>
            <Button
                disabled={!field}
                text={isActive ? descriptiveLabel : label}
                onClick={handleClick}
                className="sort-label__button"
            >
                {label}
                <Icon
                    name={`sort-${isAscending ? 'down' : 'up'}`}
                    className="sort-label__icon"
                />
            </Button>
        </TableCell>
    );
};

export default SortLabel;
