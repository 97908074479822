import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    ConfirmDialog,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../../compositions';
import { ConfirmType } from '../../../../../compositions/ConfirmDialog/ConfirmDialog';
import { formatDate } from '../../../../../helpers/date';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { JobExperience, JobExperienceFormData } from '../../../../../models/JobExperience/JobExperience';
import { JobExperienceForm } from '../../../..';

import './JobExperienceItem.scss';

interface JobExperienceItemProps {
    isLoading: boolean;
    isSuccessful: boolean;
    isEditable?: boolean;
    error: string;
    jobExperience: JobExperience;
    onEdit: (jobExperienceFormData: JobExperienceFormData) => void;
    onDelete: (jobExperience: JobExperience) => void;
    onIsSuccessfulReset: () => void;
    className?: string;
}

const JobExperienceItem: FC<JobExperienceItemProps> = ({
    isLoading,
    isSuccessful,
    isEditable = false,
    error,
    jobExperience,
    onEdit,
    onDelete,
    onIsSuccessfulReset,
    className = '',
}): ReactElement => {
    const editDialogRef = useRef<HTMLDialogElement>(null);
    const deleteDialogRef = useRef<HTMLDialogElement>(null);

    const [jobExperienceFormKey, setJobExperienceFormKey] = useState<number>(0);

    const handleOpenEditDialog = (): void => openDialog(editDialogRef);
    const handleCloseEditDialog = (): void => {
        closeDialog(editDialogRef);

        setJobExperienceFormKey(jobExperienceFormKey + 1);
    };

    const handleOpenDeleteDialog = (): void => openDialog(deleteDialogRef);
    const handleCloseDeleteDialog = (): void => closeDialog(deleteDialogRef);

    const handleDeleteJobExperience = (): void => {
        onDelete(jobExperience);
    };

    const getEndDateOrCurrent = (endDate?: Date): string => (endDate
        ? formatDate(endDate, 'MMMM yyyy')
        : trans('common.presentTime'));

    useEffect((): void => {
        if (isSuccessful) {
            handleCloseEditDialog();
            handleCloseDeleteDialog();
            onIsSuccessfulReset();
        }
    }, [isSuccessful]);

    return (
        <li className={`job-experience-item ${className}`}>
            <div className="job-experience-item__content-wrapper">
                <h3 className="job-experience-item__position">
                    {jobExperience.position}
                    <span className="job-experience-item__company">
                        {trans('containers.jobExperiencesCard.atCompany', {
                            company: jobExperience.company,
                        })}
                    </span>
                </h3>
                <p className="job-experience-item__dates">
                    {trans('containers.jobExperiencesCard.dates', {
                        startDate: formatDate(jobExperience.startDate, 'MMMM yyyy'),
                        endDate: getEndDateOrCurrent(jobExperience.endDate),
                    })}
                </p>
                <p className="job-experience-item__description">
                    {jobExperience.description}
                </p>
            </div>

            {isEditable && (
                <div className="job-experience-item__controls-wrapper">
                    <IconButton
                        icon="edit"
                        text={trans('common.edit')}
                        hideLabel
                        onClick={handleOpenEditDialog}
                        className="job-experience-item__control-button"
                    />
                    <IconButton
                        icon="bin"
                        text={trans('common.delete')}
                        hideLabel
                        onClick={handleOpenDeleteDialog}
                        className="job-experience-item__control-button job-experience-item__control-button--is-delete"
                    />
                </div>
            )}

            <Dialog ref={editDialogRef} onClose={handleCloseEditDialog}>
                <DialogContent title={trans('forms.jobExperience.editTitle')}>
                    <JobExperienceForm
                        key={jobExperienceFormKey}
                        isLoading={isLoading}
                        error={error}
                        jobExperience={jobExperience}
                        onSubmit={onEdit}
                        onCancel={handleCloseEditDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                enableBackdropClose
                ref={deleteDialogRef}
                onClose={handleCloseDeleteDialog}
            >
                <ConfirmDialog
                    isLoading={isLoading}
                    error={error}
                    title={trans('containers.jobExperiencesCard.confirmDelete.title')}
                    description={trans('containers.jobExperiencesCard.confirmDelete.paragraph', {
                        company: jobExperience.company,
                    })}
                    submitText={trans('common.delete')}
                    confirmType={ConfirmType.Delete}
                    onConfirm={handleDeleteJobExperience}
                    onCancel={handleCloseDeleteDialog}
                />
            </Dialog>
        </li>
    );
};

export default JobExperienceItem;
