import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Competency } from '../../models/Competencies/Competencies';
import { JobExperience } from '../../models/JobExperience/JobExperience';
import { SchoolExperience } from '../../models/SchoolExperience/SchoolExperience';
import { Skill } from '../../models/Skills/Skills';
import {
    CommunicationStyles,
    defaultCommunicationStyles,
    defaultLearningStyle,
    Incentive,
    LearningStyle,
    Talent,
} from '../../models/SkillsPassport/SkillsPassport';
import { AsyncReduxState } from '../../types';

export type SkillsPassportState = AsyncReduxState<{
    communicationStyles: CommunicationStyles;
    workplacePreferences: string[];
    incentives?: Record<string, Incentive[]>;
    learningStyle: LearningStyle;
    competencies?: Record<string, Competency[]>;
    consistencyScore: number;
    personalityDescription: string;
    talents: Talent[];
    skills: Skill[];
    jobExperiences: JobExperience[];
    schoolExperiences: SchoolExperience[];
    updatedAt: Date;
    isUpToDate: boolean;
}>;

const initialState: SkillsPassportState = {
    ...initialAsyncReduxState,
    communicationStyles: defaultCommunicationStyles(),
    workplacePreferences: [],
    learningStyle: defaultLearningStyle(),
    consistencyScore: 0,
    personalityDescription: '',
    talents: [],
    skills: [],
    jobExperiences: [],
    schoolExperiences: [],
    updatedAt: new Date(),
    isUpToDate: false,
};

export const skillsPassportSlice = createSlice({
    name: 'skillsPassportReducer',
    initialState,
    reducers: {
        clearSkillsPassport(): SkillsPassportState {
            return initialState;
        },
        setIsLoading(state, action: PayloadAction<boolean>): SkillsPassportState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SkillsPassportState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCommunicationStyles(state, action: PayloadAction<CommunicationStyles>): SkillsPassportState {
            return {
                ...state,
                communicationStyles: action.payload,
            };
        },
        setWorkPlacePreferences(state, action: PayloadAction<string[]>): SkillsPassportState {
            return {
                ...state,
                workplacePreferences: action.payload,
            };
        },
        setIncentives(state, action: PayloadAction<Record<string, Incentive[]> | undefined>): SkillsPassportState {
            return {
                ...state,
                incentives: action.payload,
            };
        },
        setLearningStyle(state, action: PayloadAction<LearningStyle>): SkillsPassportState {
            return {
                ...state,
                learningStyle: action.payload,
            };
        },
        setCompetencies(state, action: PayloadAction<Record<string, Competency[]> | undefined>): SkillsPassportState {
            return {
                ...state,
                competencies: action.payload,
            };
        },
        setConsistencyScore(state, action: PayloadAction<number>): SkillsPassportState {
            return {
                ...state,
                consistencyScore: action.payload,
            };
        },
        setPersonalityDescription(state, action: PayloadAction<string>): SkillsPassportState {
            return {
                ...state,
                personalityDescription: action.payload,
            };
        },
        setTalents(state, action: PayloadAction<Talent[]>): SkillsPassportState {
            return {
                ...state,
                talents: action.payload,
            };
        },
        setSkills(state, action: PayloadAction<Skill[]>): SkillsPassportState {
            return {
                ...state,
                skills: action.payload,
            };
        },
        setJobExperiences(state, action: PayloadAction<JobExperience[]>): SkillsPassportState {
            return {
                ...state,
                jobExperiences: action.payload,
            };
        },
        setSchoolExperiences(state, action: PayloadAction<SchoolExperience[]>): SkillsPassportState {
            return {
                ...state,
                schoolExperiences: action.payload,
            };
        },
        setUpdatedAt(state, action: PayloadAction<Date>): SkillsPassportState {
            return {
                ...state,
                updatedAt: action.payload,
            };
        },
        setIsUpToDate(state, action: PayloadAction<boolean>): SkillsPassportState {
            return {
                ...state,
                isUpToDate: action.payload,
            };
        },
    },
});

export const {
    clearSkillsPassport,
    setIsLoading,
    setError,
    setCommunicationStyles,
    setWorkPlacePreferences,
    setIncentives,
    setLearningStyle,
    setCompetencies,
    setConsistencyScore,
    setPersonalityDescription,
    setTalents,
    setSkills,
    setJobExperiences,
    setSchoolExperiences,
    setUpdatedAt,
    setIsUpToDate,
} = skillsPassportSlice.actions;

export default skillsPassportSlice.reducer;
