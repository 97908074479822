export const generateStartList = (pages: number[], visibleMargin: number): number[] => [...pages]
    .splice(0, visibleMargin);

export const generateCenterList = (pages: number[], visibleMargin: number, activePage: number): number[] => {
    const trimmedPages = [...pages].slice(1, -1);
    const length = visibleMargin + 1 + visibleMargin;

    const activePageIndex = trimmedPages.indexOf(activePage);
    const centerListStart = activePageIndex - visibleMargin;
    const centerListStartOrZero = centerListStart > 0 ? centerListStart : 0;

    return [...trimmedPages].splice(centerListStartOrZero, length);
};

export const generateEndList = (pages: number[], visibleMargin: number): number[] => [...pages]
    .reverse()
    .splice(0, visibleMargin)
    .reverse();

export const checkStartForActivePage = (startPages: number[], activePage: number): boolean => {
    const activePageIndex = startPages.indexOf(activePage);
    const isFound = activePageIndex > -1;
    const isNotLast = activePage !== [...startPages].pop();

    return isFound && isNotLast;
};

export const checkEndForActivePage = (endPages: number[], activePage: number): boolean => {
    const activePageIndex = endPages.indexOf(activePage);
    const isFound = activePageIndex > -1;
    const isNotLast = activePage !== [...endPages].reverse().pop();

    return isFound && isNotLast;
};
