import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TabsNavigation } from '../../compositions';
import { userHasRole } from '../../helpers/role';
import trans from '../../helpers/trans';
import { User } from '../../models/User/User';
import { RouteParams, RoutePaths } from '../../Routes';
import { RouteKey, UserRole } from '../../types';
import { Account, PersonalDetails } from './tabContainers';

import './SettingsTabs.scss';

export enum SettingsRouteTab {
    default = ':tab',
    personalDetails = 'persoonlijke-gegevens',
    account = 'account',
}

interface SettingsParams extends RouteParams {
    tab?: SettingsRouteTab;
}

interface SettingsTabsProps {
    user?: User;
    className?: string;
}

const SettingsTabs: FC<SettingsTabsProps> = ({
    user,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const { tab = SettingsRouteTab.default } = useParams<SettingsParams>();

    const isCandidate = userHasRole(UserRole.candidate, user?.roles);

    const tabs = [
        ...(isCandidate ? [
            {
                key: RouteKey.personalDetails,
                to: RoutePaths.settingsTab(SettingsRouteTab.personalDetails),
                label: trans('pages.settings.tabs.personalDetails'),
            },
        ] : []),
        {
            key: RouteKey.account,
            to: RoutePaths.settingsTab(SettingsRouteTab.account),
            label: trans('pages.settings.tabs.account'),
        },
    ];

    const navigateToTab = (to: SettingsRouteTab): void => (
        navigate(RoutePaths.settingsTab(to), { replace: true })
    );

    useEffect((): void => {
        if (tab === SettingsRouteTab.default) {
            if (userHasRole(UserRole.candidate, user?.roles)) {
                navigateToTab(SettingsRouteTab.personalDetails);
                return;
            }

            navigateToTab(SettingsRouteTab.account);
        }
    }, [tab]);

    return (
        <div className={`settings-tabs ${className}`}>
            <TabsNavigation
                navigationLabel={trans('containers.settingsTabs.label')}
                navigationItems={tabs}
                className="settings-tabs__tabs-navigation"
            />

            <div className="settings-tabs__tab">
                {(tab === SettingsRouteTab.personalDetails && isCandidate) && (
                    <PersonalDetails />
                )}

                {tab === SettingsRouteTab.account && (
                    <Account />
                )}
            </div>
        </div>
    );
};

export default SettingsTabs;
