import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { OrganisationOccupation, OrganisationOccupationFormData } from '../../models/OrganisationOccupation/OrganisationOccupation';
import {
    deleteOrganisationOccupationApiCall,
    getOrganisationOccupationApiCall,
    patchOrganisationOccupationApiCall,
    postOrganisationOccupationApiCall,
} from '../../models/OrganisationOccupation/OrganisationOccupationService';
import { transformToAddOrganisationOccupationRequest, transformToEditOrganisationOccupationRequest } from '../../models/OrganisationOccupation/OrganisationOccupationTransformers';
import { TypedDispatch } from '../store';
import {
    setError,
    setFormError,
    setIsDeleted,
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
    setOccupation,
} from './organisationOccupation';

export const fetchOrganisationOccupation = (organisationUuid: string, occupationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const occupationResponse = await getOrganisationOccupationApiCall(organisationUuid, occupationUuid);

        if (!isFetchResultSuccessful(occupationResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setOccupation(occupationResponse.data));
    } catch (error) {
        console.error('[fetchOrganisationOccupation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const createOrganisationOccupation = (formData: OrganisationOccupationFormData, organisationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setFormError(''));

    try {
        const organisationOccupationRequest = transformToAddOrganisationOccupationRequest(formData, organisationUuid);

        const occupationResponse = await postOrganisationOccupationApiCall(organisationOccupationRequest);

        if (!isFetchResultSuccessful(occupationResponse)) {
            dispatch(setFormError(trans('errors.unknownError')));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createOrganisationOccupation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateOrganisationOccupation = (formData: OrganisationOccupationFormData, organisationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setFormError(''));

    try {
        const organisationOccupationRequest = transformToEditOrganisationOccupationRequest(formData, organisationUuid);

        const occupationResponse = await patchOrganisationOccupationApiCall(organisationOccupationRequest);

        if (!isFetchResultSuccessful(occupationResponse)) {
            dispatch(setFormError(trans('errors.unknownError')));
            return;
        }

        dispatch(setOccupation(occupationResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[updateOrganisationOccupation]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const deleteOrganisationOccupation = (occupation: OrganisationOccupation, organisationUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setFormError(''));

    try {
        const occupationResponse = await deleteOrganisationOccupationApiCall(occupation, organisationUuid);

        if (!isFetchResultSuccessful(occupationResponse)) {
            dispatch(setFormError(trans('errors.unknownError')));
            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(setIsDeleted(true));
    } catch (error) {
        console.error('[deleteOrganisationOccupation]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};
