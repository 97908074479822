import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Counselor } from '../../models/Counselor/Counselor';
import { AsyncReduxState } from '../../types';

export type CounselorState = AsyncReduxState<{
    isUpdating: boolean;
    counselor?: Counselor;
}>;

const initialState: CounselorState = {
    ...initialAsyncReduxState,
    isUpdating: false,
};

export const counselorSlice = createSlice({
    name: 'counselorReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CounselorState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): CounselorState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CounselorState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CounselorState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCounselor(state, action: PayloadAction<Counselor>): CounselorState {
            return {
                ...state,
                counselor: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setIsSuccessful,
    setError,
    setCounselor,
} = counselorSlice.actions;

export default counselorSlice.reducer;
