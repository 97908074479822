import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './OccupationTabsSkeletons.scss';

interface OccupationTabsSkeletonsProps {
    amountOfLabels: number;
    className?: string;
}

const OccupationTabsSkeletons: FC<OccupationTabsSkeletonsProps> = ({
    amountOfLabels,
    className = '',
}): ReactElement => {
    const labelSkeletons = generateIdArray(amountOfLabels);

    return (
        <div className={`occupation-tabs-skeletons ${className}`}>
            <div className="occupation-tabs-skeletons__label-wrapper">
                {labelSkeletons.map(label => (
                    <Skeleton key={label} className="occupation-tabs-skeletons__label" />
                ))}
            </div>
        </div>
    );
};

export default OccupationTabsSkeletons;
