import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    BlockRadioList,
    FormActionButtons,
    Notice,
    TextInput,
} from '../../../compositions';
import { aliasSplitCharacter } from '../../../constants';
import { scrollIntoView } from '../../../helpers/scroll';
import { stripCharacterFromString } from '../../../helpers/string';
import trans from '../../../helpers/trans';
import { CandidateInviteFormData } from '../../../models/Candidate/Candidate';
import { DialogFormProps, Gender, NoticeType } from '../../../types';
import { CandidateInviteFormErrors, validateCandidateInviteFormData } from './validations';

import './CandidateInviteForm.scss';

interface CandidateInviteFormProps extends DialogFormProps<CandidateInviteFormData> {
    candidateInviteError: string;
    className?: string;
}

const CandidateInviteForm: FC<CandidateInviteFormProps> = ({
    isLoading,
    candidateInviteError,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CandidateInviteFormErrors>({});

    const [firstName, setFirstName] = useState<string>('');
    const [lastNamePrefix, setLastNamePrefix] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            firstName: stripCharacterFromString(firstName, aliasSplitCharacter),
            lastNamePrefix: stripCharacterFromString(lastNamePrefix, aliasSplitCharacter),
            lastName: stripCharacterFromString(lastName, aliasSplitCharacter),
            gender: gender as Gender,
            email,
        };

        const [errors, hasErrors] = validateCandidateInviteFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const genderOptions = [
        { label: trans('common.gender.male'), value: Gender.male },
        { label: trans('common.gender.female'), value: Gender.female },
    ];

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`candidate-invite-form ${className}`}>
            <TextInput
                label={trans('forms.candidateInvite.input.firstName')}
                required
                value={firstName}
                error={formErrors.firstName}
                onChange={setFirstName}
                className="candidate-invite-form__input"
            />

            <div className="candidate-invite-form__input-wrapper">
                <TextInput
                    label={trans('forms.candidateInvite.input.lastNamePrefix')}
                    value={lastNamePrefix}
                    error={formErrors.lastNamePrefix}
                    onChange={setLastNamePrefix}
                    className="candidate-invite-form__input candidate-invite-form__input--last-name-prefix"
                />

                <TextInput
                    label={trans('forms.candidateInvite.input.lastName')}
                    required
                    value={lastName}
                    error={formErrors.lastName}
                    onChange={setLastName}
                    className="candidate-invite-form__input"
                />
            </div>

            <BlockRadioList
                label={trans('forms.candidateInvite.input.gender')}
                name="gender"
                required
                options={genderOptions}
                value={gender}
                error={formErrors.gender}
                onChange={setGender}
                className="candidate-invite-form__input"
            />

            <TextInput
                label={trans('forms.candidateInvite.input.email')}
                type="email"
                required
                value={email}
                error={formErrors.email}
                onChange={setEmail}
                className="candidate-invite-form__input"
            />

            {candidateInviteError && (
                <Notice
                    type={NoticeType.error}
                    text={candidateInviteError}
                    className="candidate-invite-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="candidate-invite-form__action-buttons"
            />
        </form>
    );
};

export default CandidateInviteForm;
