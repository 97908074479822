import { DevelopmentPlanNoteFormData, DevelopmentPlanNoteType } from '../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import {
    deleteDevelopmentPlanNoteApiCall,
    getDevelopmentPlanNotesApiCall,
    patchDevelopmentPlanNoteApiCall,
    postDevelopmentPlanNoteApiCall,
} from '../../models/DevelopmentPlanNote/DevelopmentPlanNoteService';
import { transformToAddDevelopmentPlanNoteRequest, transformToEditDevelopmentPlanNoteRequest } from '../../models/DevelopmentPlanNote/DevelopmentPlanNoteTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import {
    setDevelopmentPlanPrivateNotes,
    setError,
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
} from './developmentPlanPrivateNotes';

export const getDevelopmentPlanPrivateNotes = (developmentPlanId: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const developmentPlanNotesResponse = await getDevelopmentPlanNotesApiCall(DevelopmentPlanNoteType.private, developmentPlanId, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNotesResponse)) {
            dispatch(setError(developmentPlanNotesResponse.error));
            return;
        }

        dispatch(setDevelopmentPlanPrivateNotes(developmentPlanNotesResponse.data));
    } catch (error) {
        console.error('[getDevelopmentPlanPrivateNotes]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDevelopmentPlanPrivateNote = (formData: DevelopmentPlanNoteFormData, developmentPlanId: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const addDevelopmentPlanNoteRequest = transformToAddDevelopmentPlanNoteRequest(developmentPlanId, formData);

        const developmentPlanNoteResponse = await postDevelopmentPlanNoteApiCall(DevelopmentPlanNoteType.private, addDevelopmentPlanNoteRequest, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNoteResponse)) {
            dispatch(setError(developmentPlanNoteResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlanPrivateNote]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const editDevelopmentPlanPrivateNote = (formData: DevelopmentPlanNoteFormData, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const editDevelopmentPlanNoteRequest = transformToEditDevelopmentPlanNoteRequest(formData);

        const developmentPlanNoteResponse = await patchDevelopmentPlanNoteApiCall(editDevelopmentPlanNoteRequest, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNoteResponse)) {
            dispatch(setError(developmentPlanNoteResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDevelopmentPlanPrivateNote]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const deleteDevelopmentPlanPrivateNote = (noteId: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentPlanNoteResponse = await deleteDevelopmentPlanNoteApiCall(noteId, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNoteResponse)) {
            dispatch(setError(developmentPlanNoteResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDevelopmentPlanPrivateNote]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};
