import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface SectorsResource extends Resource {
    id: string;
    type: 'sectors';
    attributes: {
        slug: string;
        name: string;
    };
}

export interface Sector {
    id: string;
    label: string;
}

export const transformResourceToSector = (
    sectorsResource: SectorsResource,
): Sector => ({
    id: sectorsResource.id,
    label: sectorsResource.attributes.name,
});

export const transformSectorToFormOption = (
    sector: Sector,
): FormOption => ({
    value: sector.id,
    label: sector.label,
});
