import { authenticationApiUrl } from '../constants';
import trans from '../helpers/trans';
import { AuthenticationAccessToken } from '../models/AccessToken';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { AzureAuthProvider } from './AzureAuthService';

export const fetchAuthenticationToken = async (): Promise<FetchResult<AuthenticationAccessToken, string>> => {
    try {
        const azureToken = await AzureAuthProvider.getIdToken();

        const response = await fetch(`${authenticationApiUrl}/api/authentication/authenticate`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${azureToken.idToken.rawIdToken}`,
            },
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchAuthenticationToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const fetchAuthenticationRefreshToken = async (): Promise<FetchResult<AuthenticationAccessToken, string>> => {
    try {
        const response = await fetch(`${authenticationApiUrl}/api/authentication/refresh-token`, {
            method: 'POST',
            credentials: 'include',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchAuthenticationRefreshToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
