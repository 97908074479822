/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconPercentage: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M 88.235 0 L 100 11.765 L 11.765 100 L 0 88.235 L 88.235 0 Z M 20.588 2.941 C 30.353 2.941 38.235 10.824 38.235 20.588 C 38.235 30.353 30.353 38.235 20.588 38.235 C 10.824 38.235 2.941 30.353 2.941 20.588 C 2.941 10.824 10.824 2.941 20.588 2.941 Z M 79.412 61.765 C 89.176 61.765 97.059 69.647 97.059 79.412 C 97.059 89.176 89.176 97.059 79.412 97.059 C 69.647 97.059 61.765 89.176 61.765 79.412 C 61.765 69.647 69.647 61.765 79.412 61.765 Z M 20.588 14.706 C 17.353 14.706 14.706 17.353 14.706 20.588 C 14.706 23.824 17.353 26.471 20.588 26.471 C 23.824 26.471 26.471 23.824 26.471 20.588 C 26.471 17.353 23.824 14.706 20.588 14.706 Z M 79.412 73.529 C 76.176 73.529 73.529 76.176 73.529 79.412 C 73.529 82.647 76.176 85.294 79.412 85.294 C 82.647 85.294 85.294 82.647 85.294 79.412 C 85.294 76.176 82.647 73.529 79.412 73.529 Z" />
    </svg>
);

export default IconPercentage;
/* eslint-enable max-len */
