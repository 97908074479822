import { ChangeEvent, FC, ReactElement } from 'react';

import { ErrorLabel, InputLabel, Textarea as TextareaComponent } from '../../../components';
import { HTMLTextareaProps } from '../../../components/@inputs/Textarea/Textarea';

import './Textarea.scss';

interface TextareaProps extends Omit<HTMLTextareaProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
    textareaClassName?: string;
}

const Textarea: FC<TextareaProps> = ({
    label,
    hideLabel = false,
    required = false,
    error = '',
    onChange,
    className = '',
    textareaClassName = '',
    ...textareaProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`textarea ${className}`}
        >
            {!hideLabel && <InputLabel text={label} required={required} className="textarea__label" />}
            <TextareaComponent
                {...textareaProps}
                required={required}
                error={error}
                onChange={handleChange}
                className={textareaClassName}
            />
            {error && <ErrorLabel text={error} className="textarea__error-label" />}
        </label>
    );
};

export default Textarea;
