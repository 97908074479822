import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FormOption } from '../../types';
import { FetchResult, FetchResultType } from '../FetchResult';
import { CandidateRelations, CandidateRelationsConfig } from './CandidateRelations';
import { transformToCandidateRelationFormOption, transformToCandidateRelations } from './CandidateRelationsTransformers';

export const getCandidateRelationsApiCall = async ({
    pageNumber,
    pageSize,
    sortConfig,
    searchQuery,
}: CandidateRelationsConfig): Promise<FetchResult<CandidateRelations, string>> => {
    try {
        const pageConfig = Object.entries({ pageSize, pageNumber }).map(entry => entry.join('=')).join('&');

        const response = await lloFetch(`/api/candidate/getAll/dashboard?${pageConfig}`, {
            method: 'POST',
            body: JSON.stringify({
                sortConfig,
                searchQuery,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidateRelationsResource = await response.json();
        const candidateRelations = transformToCandidateRelations(candidateRelationsResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: candidateRelations,
        };
    } catch (error) {
        console.error('[getCandidateRelationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getCandidateRelationFormOptionsApiCall = async (): Promise<FetchResult<FormOption[], string>> => {
    try {
        const response = await lloFetch('/api/candidate/getAll');

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const candidateFormOptionsResource = await response.json();
        const formOptions = candidateFormOptionsResource.map(transformToCandidateRelationFormOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: formOptions,
        };
    } catch (error) {
        console.error('[getCandidateRelationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
