import { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../../../components';
import { Dialog, DialogContent } from '../../../../../compositions';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import { Talent } from '../../../../../models/SkillsPassport/SkillsPassport';
import { TalentDescription } from '..';

import './TalentDot.scss';

interface TalentDotProps {
    talent: Talent;
    className?: string;
}

const TalentDot: FC<TalentDotProps> = ({
    talent,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => closeDialog(dialogRef);

    const canBeShown = talent.value <= 3 || talent.value >= 7;

    const dotClassNames = classNames('talent-dot', {
        'talent-dot--is-normal': talent.value === 3 || talent.value === 7,
        'talent-dot--is-high': talent.value === 2 || talent.value === 8,
        'talent-dot--is-excellent': talent.value === 1 || talent.value === 9,
    }, className);

    return (
        <>
            <Button
                hideLabel={!canBeShown}
                disabled={!canBeShown}
                text={talent.name}
                onClick={handleOpenDialog}
                className={dotClassNames}
            >
                {canBeShown && (
                    <p className="talent-dot__label">
                        {talent.name}
                        <Icon name="info" className="talent-dot__icon" />
                    </p>
                )}
            </Button>

            <Dialog
                ref={dialogRef}
                enableBackdropClose
                onClose={handleCloseDialog}
            >
                <DialogContent
                    title={talent.name}
                    description={talent.introduction}
                >
                    <TalentDescription
                        talent={talent}
                        onCloseDialog={handleCloseDialog}
                        className="talent-dot__dialog-description"
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TalentDot;
