import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AsyncReduxState } from '../../types';

export type AuthenticationState = AsyncReduxState;

const initialState: AuthenticationState = {
    ...initialAsyncReduxState,
};

const authenticationSlice = createSlice({
    name: 'authenticationReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AuthenticationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AuthenticationState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
