import { SearchableOption } from '../../types';
import { EscoOccupation, EscoOccupationResource } from './EscoOccupations';

export const transformToEscoOccupation = (
    escoOccupationResource: EscoOccupationResource,
): EscoOccupation => ({
    id: escoOccupationResource.id,
    name: escoOccupationResource.name,
});

export const transformEscoOccupationToSearchableValue = (
    escoOccupation: EscoOccupation,
): SearchableOption => ({
    value: escoOccupation.id,
    label: escoOccupation.name,
});
