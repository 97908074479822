import { DevelopmentPlanWithSkillsFormData } from '../../containers/@forms/DevelopmentPlanWithSkillsForm/DevelopmentPlanWithSkillsForm';
import { DevelopmentPlanFormData } from '../../models/DevelopmentPlan/DevelopmentPlan';
import {
    deleteDevelopmentPlanApiCall,
    getDevelopmentPlanApiCall,
    patchDevelopmentPlanApiCall,
    postDevelopmentPlanApiCall,
    postDevelopmentPlanWithSkillsApiCall,
} from '../../models/DevelopmentPlan/DevelopmentPlanService';
import { transformToDevelopmentPlanRequest, transformToDevelopmentPlanWithSkillsRequest } from '../../models/DevelopmentPlan/DevelopmentPlanTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { setIsSuccessful as setCompetencyIsSuccessful } from '../developmentPlanCompetency/developmentPlanCompetency';
import { setIsSuccessful as setSkillIsSuccessful } from '../developmentPlanSkill/developmentPlanSkill';
import { TypedDispatch } from '../store';
import {
    setDevelopmentPlan,
    setError,
    setIsDeleting,
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
} from './developmentPlan';

export const fetchDevelopmentPlan = (developmentPlanUuid: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setSkillIsSuccessful(false));
    dispatch(setCompetencyIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentsResponse = await getDevelopmentPlanApiCall(developmentPlanUuid, candidateUuid);

        if (!isFetchResultSuccessful(developmentsResponse)) {
            dispatch(setError(developmentsResponse.error));
            return;
        }

        dispatch(setDevelopmentPlan(developmentsResponse.data));
    } catch (error) {
        console.error('[fetchDevelopmentPlan]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDevelopmentPlan = (formData: DevelopmentPlanFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToDevelopmentPlanRequest(formData);

        const developmentsResponse = await postDevelopmentPlanApiCall(requestData, candidateUuid);

        if (!isFetchResultSuccessful(developmentsResponse)) {
            dispatch(setError(developmentsResponse.error));
            return;
        }

        dispatch(setDevelopmentPlan(developmentsResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlan]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDevelopmentPlan = (formData: DevelopmentPlanFormData, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToDevelopmentPlanRequest(formData);
        const developmentsResponse = await patchDevelopmentPlanApiCall(requestData, candidateId);

        if (!isFetchResultSuccessful(developmentsResponse)) {
            dispatch(setError(developmentsResponse.error));
            return;
        }

        dispatch(setDevelopmentPlan(developmentsResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDevelopmentPlan]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const deleteDevelopmentPlan = (developmentPlanUuid: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsDeleting(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentsResponse = await deleteDevelopmentPlanApiCall(developmentPlanUuid, candidateId);

        if (!isFetchResultSuccessful(developmentsResponse)) {
            dispatch(setError(developmentsResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDevelopmentPlan]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsDeleting(false));
    }
};

export const addDevelopmentPlanWithSkills = (formData: DevelopmentPlanWithSkillsFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const requestData = transformToDevelopmentPlanWithSkillsRequest(formData);

        const developmentPlanResponse = await postDevelopmentPlanWithSkillsApiCall(requestData, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanResponse)) {
            dispatch(setError(developmentPlanResponse.error));
            return;
        }

        dispatch(setDevelopmentPlan(developmentPlanResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlanWithSkills]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
