/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconSkillsPassport: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <rect x="22.2" y="22.2" width="55.6" height="11.1" />
        <rect x="22.2" y="44.4" width="55.6" height="11.1" />
        <rect x="22.2" y="66.7" width="38.9" height="11.1" />
        <path d="M89,0H11C4.9,0,0,4.9,0,11v78c0,6.1,4.9,11,11,11h78c6.1,0,11-4.9,11-11V11C100,4.9,95.1,0,89,0z M88.9,88.9H11.1V11.1h77.8V88.9z" />
    </svg>
);

export default IconSkillsPassport;
/* eslint-enable max-len */
