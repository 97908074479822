import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { defaultFormOption } from '../../constants';
import { Page } from '../../containers';
import { PageProps } from '../../containers/Page/Page';
import { gtmEvent } from '../../helpers/analytics';
import { hotjarId, initHotjar } from '../../helpers/hotjar';
import { getCurrentRoute } from '../../helpers/navigation';
import { candidateNeedsToUpdatePersonalData } from '../../helpers/registration';
import { userHasRole } from '../../helpers/role';
import { userForbiddenRoutes, userMainNavigationRoutes } from '../../helpers/user';
import { initZendesk, zendeskId } from '../../helpers/zendesk';
import { defaultCandidateRelationsConfig } from '../../models/CandidateRelations/CandidateRelations';
import { setCandidate } from '../../redux/candidate/candidate';
import { fetchCandidate } from '../../redux/candidate/candidateActions';
import { fetchCandidateRelationFormOptions, fetchCandidateRelations } from '../../redux/candidateRelations/candidateRelationsActions';
import { clearCoachingAdvice } from '../../redux/coachingAdvice/coachingAdvice';
import { clearSkillsPassport } from '../../redux/skillsPassport/skillsPassport';
import { fetchSkillsPassport } from '../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchTmaAnalysisSettings } from '../../redux/tmaAnalysisSettings/tmaAnalysisSettingsActions';
import { navigationRoutes, RoutePaths } from '../../Routes';
import { FormOption, UserRole } from '../../types';
import { ConnectedAzureLogin } from '..';

type ConnectedPageProps = PageProps;

const ConnectedPage: FC<PropsWithChildren<ConnectedPageProps>> = ({ children, ...pageProps }): ReactElement => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams<{ candidateUuid: string }>();

    const user = useTypedSelector(state => state.userReducer.user);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const tmaAnalysisSettingsIsLoading = useTypedSelector(state => state.tmaAnalysisSettingsReducer.isLoading);

    const candidateRelationsFormOptions = useTypedSelector(state => state.candidateRelationsReducer.formOptions);
    const candidateRelationsTotalPages = useTypedSelector(state => state.candidateRelationsReducer.totalPages);

    const isLoading = [
        candidateIsLoading,
        skillsPassportIsLoading,
        tmaAnalysisSettingsIsLoading,
    ].some(Boolean);

    const selectedCandidateOption = useMemo((): FormOption => {
        const foundCandidateOption = candidateRelationsFormOptions.find((candidateSelectOption => candidateSelectOption.value === candidate?.uuid));

        return foundCandidateOption || defaultFormOption();
    }, [candidateRelationsFormOptions, candidate]);


    // Fetch data based on role of user
    useEffect((): void => {
        if (!user) return;

        // Send pageview event to analytics
        gtmEvent({ event: 'pageview' });

        if (userHasRole(UserRole.candidate, user?.roles) && !candidate) {
            dispatch(fetchCandidate(user.uuid));
            dispatch(fetchSkillsPassport(user.uuid));
            dispatch(fetchTmaAnalysisSettings(user.uuid));
        }

        if (userHasRole(UserRole.counselor, user?.roles)) {
            dispatch(fetchCandidateRelations(defaultCandidateRelationsConfig()));
        }
    }, [user, dispatch]);

    useEffect((): void => {
        if (!user || candidateRelationsTotalPages === 1) return;

        if (userHasRole(UserRole.counselor, user?.roles)) {
            dispatch(fetchCandidateRelationFormOptions());
        }
    }, [candidateRelationsTotalPages, dispatch]);

    useEffect((): void => {
        const isCandidate = userHasRole(UserRole.candidate, user?.roles);
        const requestNewCandidate = params.candidateUuid && candidate?.uuid !== params.candidateUuid;

        if (!isCandidate && requestNewCandidate) {
            dispatch(clearSkillsPassport());
            dispatch(clearCoachingAdvice());
            dispatch(fetchCandidate(params.candidateUuid));
            dispatch(fetchTmaAnalysisSettings(params.candidateUuid));
        }
    }, [user, params]);

    // Navigate to registration if the profile of candidate is incomplete
    useEffect((): void => {
        if (userHasRole(UserRole.candidate, user?.roles) && candidate && candidateNeedsToUpdatePersonalData(candidate)) {
            navigate(RoutePaths.registration(), { replace: true });
        }
    }, [user, candidate, navigate]);

    // Navigate to 404-page if user is on a role-specific forbidden route
    useEffect((): void => {
        const currentRoute = getCurrentRoute(location.pathname, params);
        const forbiddenRoleRoutes = user?.roles ? user.roles.map(role => userForbiddenRoutes[role]) : [];
        const forbiddenRoutes = forbiddenRoleRoutes.flat();

        const disabledModules = user?.modules.filter(module => !module.isActive) || [];
        const forbiddenRoutesIncludingModules = navigationRoutes.filter(route => disabledModules.some(module => route.module === module.label));

        if (forbiddenRoutesIncludingModules.length > 0) {
            forbiddenRoutesIncludingModules.map(route => forbiddenRoutes.push(route.key));
        }

        if (!currentRoute) return;

        const currentRouteIsForbidden = forbiddenRoleRoutes.length > 0 && forbiddenRoleRoutes.every(routes => routes.includes(currentRoute.key));

        if (currentRouteIsForbidden) {
            navigate(RoutePaths.notFound(), { replace: true });
        }

        const managingRoles = [UserRole.lloAdmin, UserRole.organisationAdmin, UserRole.tmaExpert, UserRole.counselor];

        managingRoles.forEach(role => {
            if (userHasRole(role, user?.roles) && userMainNavigationRoutes[role].includes(currentRoute.key)) {
                dispatch(setCandidate());
            }
        });
    }, [navigate, location, params, user]);

    // Initialize scripts
    useEffect((): void => {
        const hasInitiatedZendesk = document.getElementById(zendeskId);
        const hasInitiatedHotjar = document.getElementById(hotjarId);

        if (!hasInitiatedZendesk && userHasRole(UserRole.counselor, user?.roles)) {
            initZendesk();
        }

        if (!hasInitiatedHotjar && user?.uuid) {
            initHotjar();
        }
    }, [user]);

    const handleCandidateSelect = (candidateId: string): void => {
        navigate(RoutePaths.candidateDashboard(candidateId));
    };

    return (
        <ConnectedAzureLogin>
            <Page
                {...pageProps}
                candidateIsLoading={candidate?.uuid !== params.candidateUuid && isLoading}
                candidateOptions={candidateRelationsFormOptions}
                candidateUuid={candidate?.uuid}
                selectedCandidateOption={selectedCandidateOption}
                user={user}
                onCandidateSelect={handleCandidateSelect}
            >
                {children}
            </Page>
        </ConnectedAzureLogin>
    );
};

export default ConnectedPage;
