import { FC, MouseEvent, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Button,
    Card,
    TableCell,
    TableRow,
} from '../../../components';
import { formatDate } from '../../../helpers/date';
import { userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { DevelopmentOverview } from '../../../models/DevelopmentOverview/DevelopmentOverview';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';
import { IconButton, NonSortTable } from '../..';

import './DevelopmentPlanCard.scss';

enum DevelopmentPlanDocumentType {
    Plan = 'developmentPlan',
    Evaluation = 'developmentPlanEvaluation'
}

interface DevelopmentPlanCardTableRow {
    id: string;
    type: DevelopmentPlanDocumentType;
    name: string;
    date: string;
    status: string;
    createdByName: string;
    updatedAt: string;
}

interface DevelopmentPlanCardProps {
    developmentPlan: DevelopmentOverview;
    candidateUuid: string;
    userRoles?: UserRole[];
    onEditDevelopmentPlan: (developmentPlan: DevelopmentOverview) => void;
    onDeleteDevelopmentPlan: (developmentPlan: DevelopmentOverview) => void;
    onAddEvaluation: (development: DevelopmentOverview) => void;
    className?: string;
}

const DevelopmentPlanCard: FC<DevelopmentPlanCardProps> = ({
    developmentPlan,
    candidateUuid,
    userRoles = [],
    onEditDevelopmentPlan,
    onDeleteDevelopmentPlan,
    onAddEvaluation,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const tableRows: DevelopmentPlanCardTableRow[] = [];

    const hasDevelopmentPlanEvaluation = !!developmentPlan?.developmentPlanEvaluation;

    tableRows.push({
        id: developmentPlan.id,
        type: DevelopmentPlanDocumentType.Plan,
        name: trans('common.developmentPlan'),
        date: `${formatDate(developmentPlan.start)} - ${developmentPlan.end ? formatDate(developmentPlan.end) : new Date()}`, // TODO: helper function
        createdByName: developmentPlan.createdByName,
        status: developmentPlan.isCompleted
            ? trans('compositions.developmentPlanCard.status.closed')
            : trans('compositions.developmentPlanCard.status.open'),
        updatedAt: formatDate(developmentPlan.updatedAt),
    });

    if (developmentPlan?.developmentPlanEvaluation) {
        tableRows.push({
            id: developmentPlan.developmentPlanEvaluation.id,
            type: DevelopmentPlanDocumentType.Evaluation,
            name: trans('common.evaluation'),
            date: `${formatDate(developmentPlan.developmentPlanEvaluation.createdAt)}`,
            createdByName: developmentPlan.developmentPlanEvaluation.createdByName,
            status: trans('compositions.developmentPlanCard.status.inapplicable'),
            updatedAt: formatDate(developmentPlan.developmentPlanEvaluation.updatedAt),
        });
    }

    const tableHeaders: string[] = [
        trans('tables.developmentPlanCardTable.labels.type'),
        trans('tables.developmentPlanCardTable.labels.date'),
        trans('tables.developmentPlanCardTable.labels.createdBy'),
        trans('tables.developmentPlanCardTable.labels.status'),
        trans('tables.developmentPlanCardTable.labels.updatedAt'),
    ];

    const handleRowClick = (selectedRow: DevelopmentPlanCardTableRow): void => {
        if (selectedRow.type === DevelopmentPlanDocumentType.Plan) {
            navigate(RoutePaths.developmentPlan(candidateUuid, selectedRow.id));
        } else {
            navigate(RoutePaths.developmentPlanEvaluation(candidateUuid, developmentPlan.id, selectedRow.id));
        }
    };

    const handleEditClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        if (developmentPlan) onEditDevelopmentPlan(developmentPlan);
    };

    const handleDeleteClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onDeleteDevelopmentPlan(developmentPlan);
    };

    const handleAddEvaluationClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onAddEvaluation(developmentPlan);
    };

    return (
        <Card className={`development-plan-card ${className}`}>
            <div className="development-plan-card__header">
                <h2 className="development-plan-card__title">{developmentPlan.title}</h2>

                <div className="development-plan-card__actions-wrapper">
                    <IconButton
                        icon="edit"
                        text={trans('common.edit')}
                        hideLabel
                        onClick={handleEditClick}
                        className="development-plan-card__action-button"
                    />
                    <IconButton
                        icon="bin"
                        text={trans('common.delete')}
                        hideLabel
                        onClick={handleDeleteClick}
                        className="development-plan-card__action-button development-plan-card__action-button--is-delete"
                    />
                </div>
            </div>
            <NonSortTable
                hasRowClick
                tableHeaders={tableHeaders}
                rows={tableRows}
                headerRowClassName="development-plan-card__row"
                cellClassName="development-plan-card__cell"
                className="development-plan-card__table"
            >
                {(rows): JSX.Element[] => rows.map(row => {
                    const onRowClick = (): void => handleRowClick(row);

                    return (
                        <TableRow key={`${row.type}-${row.id}`} className="development-plan-card__row">
                            <TableCell className="development-plan-card__cell development-plan-card__cell--name">
                                {row.name}
                            </TableCell>
                            <TableCell className="development-plan-card__cell">
                                {row.date}
                            </TableCell>
                            <TableCell className="development-plan-card__cell">
                                {row.createdByName}
                            </TableCell>
                            <TableCell className="development-plan-card__cell">
                                {row.status}
                            </TableCell>
                            <TableCell className="development-plan-card__cell">
                                {row.updatedAt}
                            </TableCell>
                            <TableCell className="development-plan-card__hidden-cell">
                                <Button
                                    text={trans('common.goToDetail')}
                                    hideLabel
                                    onClick={onRowClick}
                                    className="development-plan-card__redirect-button"
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </NonSortTable>
            {(!hasDevelopmentPlanEvaluation && userHasRole(UserRole.counselor, userRoles)) && (
                <IconButton
                    text={trans('compositions.developmentPlanCard.evaluation.create')}
                    icon="plus"
                    onClick={handleAddEvaluationClick}
                    className="development-plan-card__create-evaluation-button"
                />
            )}
        </Card>
    );
};

export default DevelopmentPlanCard;
