import { FC, ReactElement } from 'react';

import { Card, LoadingSpinner } from '../../../components';
import { Notice } from '../../../compositions';
import trans from '../../../helpers/trans';
import { Module } from '../../../models/Modules/Modules';
import { NoticeType } from '../../../types';
import { OrganisationDetailModulesSkeletons } from './skeletons';
import { EditableModule } from './subcomponents';

import './OrganisationDetailModules.scss';

interface OrganisationDetailModulesProps {
    isLoading: boolean;
    isUpdating: boolean;
    showConfirmation: boolean;
    error: string;
    modules: Module[];
    onToggleChange: (module: Module) => void;
    className?: string;
}

const OrganisationDetailModules: FC<OrganisationDetailModulesProps> = ({
    isLoading,
    isUpdating,
    showConfirmation,
    error,
    modules,
    onToggleChange,
    className = '',
}): ReactElement => (
    <Card className={`organisation-detail-modules ${className}`}>
        {isLoading && <OrganisationDetailModulesSkeletons amount={2} />}

        {!isLoading && error && (
            <Notice
                type={NoticeType.error}
                text={error}
            />
        )}

        {!isLoading && !error && (
            <>
                <header className="organisation-detail-modules__header">
                    <h3 className="organisation-detail-modules__title">Modules beheren</h3>
                    {isUpdating && <LoadingSpinner className="organisation-detail-modules__loader" />}
                    {showConfirmation && (
                        <p className="organisation-detail-modules__confirmation">
                            {trans('containers.organisationDetailModules.confirmation')}
                        </p>
                    )}
                </header>

                <ul className="organisation-detail-modules__list">
                    {modules.map(module => {
                        const handleToggleChange = (isChecked: boolean): void => {
                            onToggleChange({
                                ...module,
                                isActive: isChecked,
                            });
                        };

                        return (
                            <EditableModule
                                key={module.id}
                                isLoading={isUpdating}
                                module={module}
                                onToggleChange={handleToggleChange}
                                className="organisation-detail-modules__list-item"
                            />
                        );
                    })}
                </ul>
            </>
        )}
    </Card>
);

export default OrganisationDetailModules;
