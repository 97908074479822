import { FC, ReactElement } from 'react';

import { ConnectedCommunicationStylesCard, ConnectedPersonalityDescriptionCard } from '../../../../../connectors';

import './Personality.scss';

interface PersonalityProps {
    onRefresh: () => void;
}

const Personality: FC<PersonalityProps> = ({ onRefresh }): ReactElement => (
    <>
        <ConnectedPersonalityDescriptionCard
            onRefresh={onRefresh}
            className="personality-tab__card"
        />

        <ConnectedCommunicationStylesCard
            onRefresh={onRefresh}
            className="personality-tab__card"
        />
    </>
);

export default Personality;
