import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedPage, ConnectedSettingsTabs } from '../../connectors';
import trans from '../../helpers/trans';

import './Settings.scss';

const Settings: FC = (): ReactElement => (
    <ConnectedPage
        hasNavigation
        title={trans('pages.settings.title')}
        className="settings-page"
    >
        <Helmet>
            <title>{trans('pages.settings.title')}</title>
        </Helmet>

        <ConnectedSettingsTabs className="settings-page__tabs" />
    </ConnectedPage>
);

export default Settings;
