import { FC, ReactElement } from 'react';

import { Link, LinkProps } from 'react-router-dom';

import './LinkButton.scss';

export interface LinkButtonProps extends LinkProps {
    text?: string;
    disabled?: boolean;
}

const LinkButton: FC<LinkButtonProps> = ({
    to,
    text,
    className = '',
    children,
    ...linkButtonProps
}): ReactElement => {
    const externalUrl = to as string;
    const isExternal = externalUrl.startsWith('http');

    return (
        <Link
            {...linkButtonProps}
            to={to}
            target={isExternal ? '_blank' : undefined}
            rel={isExternal ? 'noopener noreferrer' : undefined}
            title={text}
            className={`link-button ${className}`}
        >
            {children || text}
        </Link>
    );
};

export default LinkButton;
