import { FC, ReactElement, useState } from 'react';

import { Avatar, Card } from '../../../../../components';
import { IconButton, Theatre } from '../../../../../compositions';
import { formatAgeInDays } from '../../../../../helpers/date';
import trans from '../../../../../helpers/trans';
import { Vacancy } from '../../../../../models/Vacancy';

import './VacancyDetailHeader.scss';

export interface VacancyDetailHeaderProps {
    vacancy: Vacancy;
    className?: string;
}

const VacancyDetailHeader: FC<VacancyDetailHeaderProps> = ({
    vacancy,
    className = '',
}): ReactElement => {
    const [hasImageError, setHasImageError] = useState<boolean>(false);
    const [theatreIsOpen, setTheatreIsOpen] = useState<boolean>(false);

    const openTheatre = (): void => setTheatreIsOpen(true);
    const closeTheatre = (): void => setTheatreIsOpen(false);

    const { companyName, city } = vacancy;

    const handleImageError = (): void => setHasImageError(true);

    return (
        <header className={`vacancy-detail-header ${className}`}>
            <Card className="vacancy-detail-header__card">
                {(vacancy.coverImage || vacancy.coverVideo) && (
                    <div className="vacancy-detail-header__cover-wrapper">
                        {vacancy.coverVideo && (
                            <div className="vacancy-detail-header__play-button-wrapper">
                                <IconButton
                                    hideLabel
                                    icon="play"
                                    text={trans('containers.vacancyDetail.header.coverVideoPlayLabel')}
                                    onClick={openTheatre}
                                    className="vacancy-detail-header__play-button"
                                    iconClassName="vacancy-detail-header__play-icon"
                                />
                            </div>
                        )}

                        {vacancy.coverImage && !hasImageError && (
                            <img
                                src={vacancy.coverImage}
                                alt={trans('containers.vacancyDetail.header.coverImageAlt', { companyName })}
                                onError={handleImageError}
                                className="vacancy-detail-header__cover-image"
                            />
                        )}
                    </div>
                )}

                <div className="vacancy-detail-header__information-wrapper">
                    <Avatar
                        src={vacancy.logo}
                        alt={trans('containers.vacancyDetail.header.logoAlt', { companyName })}
                        fallbackString={companyName}
                        className="vacancy-card__logo"
                    />

                    <div className="vacancy-detail-header__text-wrapper">
                        <h1 className="vacancy-detail-header__title">
                            {vacancy.title}
                        </h1>

                        <p className="vacancy-detail-header__company">
                            {trans('containers.vacancyDetail.header.atCompany', { companyName, city })}
                        </p>

                        <p className="vacancy-detail-header__publish-date">
                            {formatAgeInDays(vacancy.publicationDate)}
                        </p>
                    </div>
                </div>
            </Card>

            {vacancy.coverVideo && theatreIsOpen && (
                <Theatre
                    autoPlay
                    embedId={vacancy.coverVideo}
                    onClose={closeTheatre}
                />
            )}
        </header>
    );
};

export default VacancyDetailHeader;
