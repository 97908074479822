import { FC, ReactElement } from 'react';

import { v4 as generateUuid } from 'uuid';

import { Card, LoadingSpinner } from '../../../components';
import { SelectInput } from '../../../compositions';
import trans from '../../../helpers/trans';
import { CoachingAdvice } from '../../../models/CoachingAdvice/CoachingAdvice';
import { FormOption } from '../../../types';

import './CoachingAdviceCard.scss';

interface CoachingAdviceCardProps {
    isLoading: boolean;
    competencyFormOptions: FormOption[];
    coachingAdvice?: CoachingAdvice;
    onSelectedCompetencyChange: (competencyId: string) => void;
    className?: string;
}

const CoachingAdviceCard: FC<CoachingAdviceCardProps> = ({
    isLoading,
    competencyFormOptions,
    coachingAdvice,
    onSelectedCompetencyChange,
    className = '',
}): ReactElement => {
    const selectedFormOption = coachingAdvice && { value: coachingAdvice.id, label: coachingAdvice.label };

    return (
        <Card className={`coaching-advice-card ${className}`}>
            <header className="coaching-advice-card__header">
                <h3 className="coaching-advice-card__title">
                    {trans('containers.coachingAdviceCard.title')}
                </h3>

                <p className="coaching-advice-card__description">
                    {trans('containers.coachingAdviceCard.description')}
                </p>

                <SelectInput
                    hideLabel
                    isDisabled={isLoading}
                    name="coaching-advice-competency-select"
                    label={trans('containers.coachingAdviceCard.selectLabel')}
                    value={selectedFormOption}
                    options={competencyFormOptions}
                    onChange={onSelectedCompetencyChange}
                    className="coaching-advice-card__competency-select"
                />
            </header>

            <div className="coaching-advice-card__advice-content">
                {isLoading && <LoadingSpinner />}

                {!isLoading && !coachingAdvice && (
                    <p className="coaching-advice-card__null-state">
                        {trans('containers.coachingAdviceCard.nullState')}
                    </p>
                )}

                {!isLoading && coachingAdvice && (
                    <ul className="coaching-advice-card__advice-list">
                        {coachingAdvice.advices.map(advice => {
                            const key = generateUuid();

                            return (
                                <li key={key} className="coaching-advice-card__advice-list-item">
                                    <p className="coaching-advice-card__advice">{advice}</p>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </Card>
    );
};

export default CoachingAdviceCard;
