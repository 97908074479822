import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { SearchableOption } from '../../../../../types';

import './SearchableValueInputOption.scss';

interface SearchableValueInputOptionProps {
    option: SearchableOption;
    onSelect: (fieldOfStudy: SearchableOption) => void;
    className?: string;
}

const SearchableValueInputOption: FC<SearchableValueInputOptionProps> = ({
    option,
    onSelect,
    className = '',
}): ReactElement => {
    const handleClick = (): void => onSelect(option);

    return (
        <li className={`searchable-value-input-option ${className}`}>
            <Button
                text={option.label}
                onClick={handleClick}
                className="searchable-value-input-option__button"
            >
                <p className="searchable-value-input-option__label">
                    {option.label}
                </p>

                {option.secondaryLabel && (
                    <span className="searchable-value-input-option__secondary-label">
                        {option.secondaryLabel}
                    </span>
                )}
            </Button>
        </li>
    );
};

export default SearchableValueInputOption;
