import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { TmaAnalysisSettings } from '../../models/TmaAnalysis/TmaAnalysis';
import { AsyncReduxState } from '../../types';

export type TmaAnalysisSettingsState = AsyncReduxState<{
    tmaAnalysisSettings?: TmaAnalysisSettings;
}>;

const initialState: TmaAnalysisSettingsState = {
    ...initialAsyncReduxState,
    tmaAnalysisSettings: undefined,
};

export const tmaAnalysisSettingsSlice = createSlice({
    name: 'tmaAnalysisSettingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): TmaAnalysisSettingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): TmaAnalysisSettingsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): TmaAnalysisSettingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setTmaAnalysisSettings(state, action: PayloadAction<TmaAnalysisSettings | undefined>): TmaAnalysisSettingsState {
            return {
                ...state,
                tmaAnalysisSettings: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setTmaAnalysisSettings,
} = tmaAnalysisSettingsSlice.actions;

export default tmaAnalysisSettingsSlice.reducer;
