import { OccupationSkill } from '../../../../models/OccupationDetail/OccupationDetail';
import {
    FormErrors,
    FormValidation,
    validateDateString,
    validateEndDate,
    validateForm,
    validateMinimumArrayLength,
    validateRequiredString,
    validateStartDate,
} from '../../../../services/ValidationService';
import { DevelopmentPlanWithSkillsFormData } from '../DevelopmentPlanWithSkillsForm';

export type DevelopmentPlanWithSkillsFormErrors = FormErrors<DevelopmentPlanWithSkillsFormData>;

export const validateDevelopmentPlanWithSkillsFormData = (formData: DevelopmentPlanWithSkillsFormData): FormValidation<DevelopmentPlanWithSkillsFormErrors> => {
    const errors: DevelopmentPlanWithSkillsFormErrors = {
        title: validateRequiredString('title', formData.title),
        start: validateDateString('start', formData.start) || validateStartDate(new Date(formData.start), new Date(formData.end)),
        end: validateDateString('end', formData.end) || validateEndDate(new Date(formData.end), new Date(formData.start)),
        skills: validateMinimumArrayLength<OccupationSkill>('skills', formData.skills, 1),
    };

    return validateForm<DevelopmentPlanWithSkillsFormErrors>(errors);
};
