/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconPlay: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 74.1 100" className={`icon ${className}`}>
        <polygon points="74.1,50 0,0 0,100" />
    </svg>
);

export default IconPlay;
/* eslint-enable max-len */
