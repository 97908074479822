import { maxEmploymentHoursPerWeek } from '../constants';
import { isPostalCode } from '../helpers/address';
import { MinMaxValue } from '../types';
import { maximumAmountOfVacancies } from './Pagination';
import {
    transformToVacancy,
    Vacancy,
    VacancyResource,
    VacancyType,
} from './Vacancy';

export interface MatchingVacanciesResource {
    totalVacancies: number;
    vacancies: VacancyResource[];
}

export interface MatchingVacancies {
    totalAmount: number;
    vacancies: Vacancy[];
}

export const transformToMatchingVacancies = (
    matchingVacanciesResource: MatchingVacanciesResource,
): MatchingVacancies => ({
    totalAmount: matchingVacanciesResource.totalVacancies,
    vacancies: matchingVacanciesResource.vacancies.map(transformToVacancy),
});

export interface VacancyFilterParams {
    category: VacancyType;
    meta: {
        from: number;
        size: number;
    };
    search?: string;
    location?: {
        travelDistance?: number;
        city?: string;
        postalCode?: string;
        province?: string;
    };
    jobType?: string[];
    workRemotePreference?: string[];
    employment?: string;
    employmentHourRange?: MinMaxValue;
    educationLevels?: string[];
    educationGrades?: string[];
    sbbCreboNumber?: string[];
    company?: {
        amountOfEmployees?: string[];
    };
    sector?: string[];
    applyAnonymous?: boolean;
    companySbbApproval?: boolean;
    minimumCompensation?: number;
    skills?: string[];
    skillsToLearn?: string[];
    competences?: string[];
    competencesToLearn?: string[];
    fieldsOfStudy?: string[];
}

export interface VacancyFilterValues {
    pageNumber: number;
    pageSize: number;
    what: string;
    where: string;
    distance: number;
    applyAnonymous: boolean;
    sectors: string[];
    educationLevels: string[];
    workRemotePreference: string[];
    jobTypes: string[];
    employmentHourRange: MinMaxValue;
    amountOfEmployees: string[];
    salary: number;
    skills: string[];
    competencies: string[];
}

export const defaultVacancyFilterValues = (): VacancyFilterValues => ({
    pageNumber: 1,
    pageSize: maximumAmountOfVacancies,
    what: '',
    where: '',
    distance: 0,
    applyAnonymous: false,
    sectors: [],
    educationLevels: [],
    workRemotePreference: [],
    jobTypes: [],
    employmentHourRange: {
        min: 0,
        max: maxEmploymentHoursPerWeek,
    },
    amountOfEmployees: [],
    salary: 0,
    skills: [],
    competencies: [],
});

export const transformFilterValuesToFilterParams = (
    filterValues: VacancyFilterValues,
): VacancyFilterParams => {
    const searchedForPostalCode = isPostalCode(filterValues.where);

    const location = searchedForPostalCode ? {
        travelDistance: filterValues.where ? filterValues.distance : undefined,
        zipCode: filterValues.where,
    } : {
        travelDistance: filterValues.where ? filterValues.distance : undefined,
        city: filterValues.where,
    };

    const company = filterValues.amountOfEmployees.length
        ? { amountOfEmployees: filterValues.amountOfEmployees }
        : undefined;

    return {
        category: VacancyType.job,
        meta: {
            from: filterValues.pageNumber,
            size: filterValues.pageSize,
        },
        search: filterValues.what || undefined,
        location: filterValues.where
            ? location
            : undefined,
        jobType: filterValues.jobTypes.length
            ? filterValues.jobTypes
            : undefined,
        workRemotePreference: filterValues.workRemotePreference.length
            ? filterValues.workRemotePreference
            : undefined,
        employmentHourRange: filterValues.employmentHourRange.max > 0
            ? filterValues.employmentHourRange
            : undefined,
        educationLevels: filterValues.educationLevels.length
            ? filterValues.educationLevels
            : undefined,
        company,
        sector: filterValues.sectors.length
            ? filterValues.sectors
            : undefined,
        applyAnonymous: filterValues.applyAnonymous,
        minimumCompensation: filterValues.salary
            ? filterValues.salary
            : undefined,
        skills: filterValues.skills.length
            ? filterValues.skills
            : undefined,
        competences: filterValues.competencies.length
            ? filterValues.competencies
            : undefined,
    };
};
