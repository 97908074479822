import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import {
    Dialog,
    DialogContent,
    IconButton,
    InviteSuccessMessage,
} from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { Counselor, CounselorFormData } from '../../../models/Counselor/Counselor';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { UserRole } from '../../../types';
import { CounselorForm, CounselorTable, Pagination } from '../..';

import './OrganisationDetailCounselors.scss';

interface OrganisationDetailCounselorsProps {
    isLoading: boolean;
    counselorFormIsLoading: boolean;
    counselorFormIsSuccessful: boolean;
    counselorOverviewError: string;
    counselorFormError: string;
    counselors: Counselor[];
    totalAmount: number;
    pagination?: PaginationModel;
    userRoles?: UserRole[];
    onAddCounselor: (formData: CounselorFormData) => void;
    onEditCounselor: (formData: CounselorFormData) => void;
    onCloseInviteCounselorSuccessMessage: () => void;
    onPaginationChange: (currentPage: number) => void;
    className?: string;
}

const OrganisationDetailCounselors: FC<OrganisationDetailCounselorsProps> = ({
    isLoading,
    counselorFormIsLoading,
    counselorFormIsSuccessful,
    counselorOverviewError,
    counselorFormError,
    counselors,
    totalAmount,
    pagination,
    userRoles,
    onAddCounselor,
    onEditCounselor,
    onCloseInviteCounselorSuccessMessage,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [counselorFirstName, setCounselorFirstName] = useState<string>('');
    const [counselorFormKey, setCounselorFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);
        setCounselorFormKey(counselorFormKey + 1);
    };

    const handleAddCounselor = (formData: CounselorFormData): void => {
        onAddCounselor(formData);

        setCounselorFirstName(formData.firstName);
    };

    const handleCloseInviteSuccessMessage = (): void => {
        handleCloseDialog();
        onCloseInviteCounselorSuccessMessage();
        setCounselorFirstName('');
    };

    return (
        <section className={`organisation-detail-counselors ${className}`}>
            <header className="organisation-detail-counselors__header">
                <IconButton
                    icon="plus"
                    text={trans('containers.organisationDetailCounselors.addCounselor')}
                    onClick={handleOpenDialog}
                    className="organisation-detail-counselors__add-button"
                />
            </header>

            <CounselorTable
                counselorOverviewIsLoading={isLoading}
                counselorFormIsLoading={counselorFormIsLoading}
                counselorFormIsSuccessful={counselorFormIsSuccessful}
                counselorOverviewError={counselorOverviewError}
                counselorFormError={counselorFormError}
                counselors={counselors}
                totalAmount={totalAmount}
                userRoles={userRoles}
                onAddCounselor={onAddCounselor}
                onEditCounselor={onEditCounselor}
                onCloseInviteCounselorSuccessMessage={onCloseInviteCounselorSuccessMessage}
                className="organisation-detail-counselors__table"
            />

            {pagination && (
                <Pagination
                    id="counselor-overview"
                    amountOfPages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    onChange={onPaginationChange}
                    className="organisation-detail-counselors__pagination"
                />
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                {counselorFormIsSuccessful ? (
                    <InviteSuccessMessage
                        firstName={counselorFirstName}
                        onConfirm={handleCloseInviteSuccessMessage}
                    />
                ) : (
                    <DialogContent title={trans('containers.organisationDetailCounselors.addDialog.title')}>
                        <CounselorForm
                            key={counselorFormKey}
                            isLoading={counselorFormIsLoading}
                            error={counselorFormError}
                            userRoles={userRoles}
                            onSubmit={handleAddCounselor}
                            onCancel={handleCloseDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>
        </section>
    );
};

export default OrganisationDetailCounselors;
