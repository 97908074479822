import { FC, ReactElement } from 'react';

import { Loading, SearchInput } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { CounselorOption } from '../../../../../models/Counselor/Counselor';
import { CandidateExchangeFormErrors } from '../../validations';
import CounselorSearchResult from '../CounselorSearchResult/CounselorSearchResult';

import './CounselorStep.scss';

interface CounselorStepProps {
    isLoading: boolean;
    error: string;
    formErrors: CandidateExchangeFormErrors;
    newCounselor: CounselorOption;
    options: CounselorOption[];
    searchQuery: string;
    onSearch: (query: string) => void;
    onCounselorSelect: (counselor: CounselorOption) => void;
    className?: string;
}

const CounselorStep: FC<CounselorStepProps> = ({
    isLoading,
    error,
    formErrors,
    newCounselor,
    options,
    searchQuery,
    onSearch,
    onCounselorSelect,
    className = '',
}): ReactElement => (
    <div className={`counselor-step ${className}`}>
        <SearchInput
            label={trans('forms.candidateExchange.input.newCounselor')}
            value={searchQuery}
            placeholder={trans('forms.candidateExchange.placeholder.newCounselor')}
            error={formErrors.counselorId}
            onChange={onSearch}
            className="counselor-step__input"
        />

        {isLoading && <Loading className="counselor-step__loader" />}

        {options.length > 0 && !error && (
            <ul className="counselor-step__result-list">
                {options.map(option => (
                    <CounselorSearchResult
                        key={option.id}
                        name="new-counselor"
                        counselor={option}
                        checked={option.id === newCounselor.id}
                        onSelect={onCounselorSelect}
                        className="counselor-step__result-item"
                    />
                ))}
            </ul>
        )}
    </div>
);

export default CounselorStep;
