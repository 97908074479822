import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedOrganisationOverview, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './OrganisationOverview.scss';

const OrganisationOverview: FC = (): ReactElement => (
    <ConnectedPage
        hasNavigation
        title={trans('pages.organisationOverview.title')}
        className="organisation-overview-page"
    >
        <Helmet>
            <title>
                {trans('pages.organisationOverview.title')}
            </title>
        </Helmet>

        <ConnectedOrganisationOverview className="organisation-overview-page__overview" />
    </ConnectedPage>
);

export default OrganisationOverview;
