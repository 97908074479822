import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import {
    ConnectedPage,
    ConnectedVacancyFilters,
    ConnectedVacancyMatchingFilter,
    ConnectedVacancyOverview,
} from '../../connectors';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { User } from '../../models/User/User';
import { getSeekVacanciesOverviewTitle } from './helpers';

import './SeekVacancies.scss';

const SeekVacancies: FC = (): ReactElement => {
    const [candidate, setCandidate] = useState<Candidate>();
    const [user, setUser] = useState<User>();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const overviewTitle = getSeekVacanciesOverviewTitle(user?.roles, candidate?.fullName);

    return (
        <ConnectedPage
            hasNavigation
            title={trans('pages.seekVacancies.title')}
            className="seek-vacancies-page"
        >
            <Helmet>
                <title>{trans('pages.seekVacancies.title')}</title>
            </Helmet>

            <ConnectedVacancyFilters currentPage={currentPage} />

            <h2 className="seek-vacancies-page__overview-title">
                {overviewTitle}
            </h2>

            <div className="seek-vacancies-page__content-wrapper">
                <ConnectedVacancyMatchingFilter
                    onCandidateResponse={setCandidate}
                    onUserResponse={setUser}
                    className="seek-vacancies-page__matching-filter"
                />

                <ConnectedVacancyOverview
                    currentPage={currentPage}
                    onPaginationChange={setCurrentPage}
                    className="seek-vacancies-page__vacancy-overview"
                />
            </div>
        </ConnectedPage>
    );
};

export default SeekVacancies;
