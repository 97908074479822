import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Toggle } from '../..';
import { ToggleProps } from '../Toggle/Toggle';

import './BlockToggle.scss';

interface BlockToggleProps extends ToggleProps {
    description?: string;
}

const BlockToggle: FC<BlockToggleProps> = ({
    label,
    description,
    disabled,
    className = '',
    ...toggleProps
}): ReactElement => {
    const blockToggleClassNames = classNames('block-toggle', {
        'block-toggle--is-disabled': disabled,
    }, className);

    return (
        <label className={blockToggleClassNames}>
            <div className="block-toggle__text-wrapper">
                <h2 className="block-toggle__label-title">{label}</h2>
                {description && (
                    <p className="block-toggle__label-description">{description}</p>
                )}
            </div>

            <Toggle
                {...toggleProps}
                label={label}
                hideLabel
                disabled={disabled}
            />
        </label>
    );
};

export default BlockToggle;
