import { FC, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import { SkillsPassportCard } from '../../../compositions';
import { getRoleForTranslation, userHasRole } from '../../../helpers/role';
import trans from '../../../helpers/trans';
import { Incentive as IncentiveModel } from '../../../models/SkillsPassport/SkillsPassport';
import { RoutePaths } from '../../../Routes';
import { UserRole } from '../../../types';
import { Incentive } from './subcomponents';

import './IncentivesCard.scss';

interface IncentivesCardProps {
    isLoading?: boolean;
    isUpToDate: boolean;
    candidateUuid?: string;
    userRoles?: UserRole[];
    incentives?: Record<string, IncentiveModel[]>;
    handleRefresh: () => void;
    className?: string;
}

const IncentivesCard: FC<IncentivesCardProps> = ({
    isLoading,
    isUpToDate,
    candidateUuid,
    userRoles = [],
    incentives,
    handleRefresh,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();

    const handleNullStateCallToActionButtonClick = (): void => {
        navigate(RoutePaths.takeAnalysis(candidateUuid));
    };

    return (
        <SkillsPassportCard
            isLoading={isLoading}
            isUpToDate={isUpToDate}
            showNullState={!incentives}
            showCallToActionButton={userHasRole(UserRole.candidate, userRoles)}
            title={trans('containers.incentivesCard.title')}
            description={userRoles.length > 0 ? trans(`containers.incentivesCard.description.${getRoleForTranslation(userRoles)}`) : ''}
            nullStateText={trans('containers.incentivesCard.nullState')}
            callToActionButtonLabel={trans('containers.incentivesCard.callToActionButtonLabel')}
            callToActionButtonClick={handleNullStateCallToActionButtonClick}
            onRefreshClick={handleRefresh}
            className={`incentives-card ${className}`}
        >
            {(!isLoading && !!incentives) && (
                Object.entries(incentives).map(([dimension, content]) => (
                    <div key={dimension} className="incentives-card__dimension">
                        <h3 className="incentives-card__dimension-title">
                            {dimension}
                        </h3>

                        {content.map(incentive => (
                            <Incentive
                                key={incentive.name}
                                incentive={incentive}
                                className="incentives-card__incentive"
                            />
                        ))}
                    </div>
                ))
            )}
        </SkillsPassportCard>
    );
};

export default IncentivesCard;
