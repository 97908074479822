import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddCounselorRequest,
    Counselor,
    CounselorOption,
    CounselorOptionResource,
    CounselorResource,
    EditCounselorRequest,
} from './Counselor';
import { transformToCounselor, transformToCounselorOption } from './CounselorTransformers';

export const postCounselorApiCall = async (addCounselorRequest: AddCounselorRequest): Promise<FetchResult<Counselor, string>> => {
    try {
        const response = await lloFetch(`/api/user/counselor/organisation/${addCounselorRequest.organisationId}`, {
            method: 'POST',
            body: JSON.stringify(addCounselorRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const counselorResponse: CounselorResource = await response.json();

        const counselor = transformToCounselor(counselorResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: counselor,
        };
    } catch (error) {
        console.error('[postCounselorApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchCounselorApiCall = async (editCounselorRequest: EditCounselorRequest): Promise<FetchResult<Counselor, string>> => {
    try {
        const response = await lloFetch(`/api/user/organisation/${editCounselorRequest.organisationId}/counselor/${editCounselorRequest.id}`, {
            method: 'PATCH',
            body: JSON.stringify(editCounselorRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const counselorResponse: CounselorResource = await response.json();

        const counselor = transformToCounselor(counselorResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: counselor,
        };
    } catch (error) {
        console.error('[patchCounselorApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getCounselorOptionsApiCall = async (organisationUuid: string, searchQuery: string): Promise<FetchResult<CounselorOption[], string>> => {
    try {
        const response = await lloFetch(`/api/counselor/getAll/${organisationUuid}/?searchQuery=${searchQuery}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const counselorOptionsResponse: CounselorOptionResource[] = await response.json();
        const counselorOptions = counselorOptionsResponse.map(transformToCounselorOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: counselorOptions,
        };
    } catch (error) {
        console.error('[getCounselorOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
