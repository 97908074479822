import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { DevelopmentPlanEvaluation, DevelopmentPlanEvaluationRequest, DevelopmentPlanEvaluationResource } from './DevelopmentPlanEvaluation';
import { transformToDevelopmentPlanEvaluation } from './DevelopmentPlanEvaluationTransformer';

export const getDevelopmentPlanEvaluationApiCall = async (developmentPlanEvaluationUuid: string, candidateUuid: string): Promise<FetchResult<DevelopmentPlanEvaluation, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlanEvaluation/${developmentPlanEvaluationUuid}`, {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanEvaluationResource;

        const developmentPlanEvaluation = transformToDevelopmentPlanEvaluation(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanEvaluation,
        };
    } catch (error) {
        console.error('[getDevelopmentPlanEvaluationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postDevelopmentPlanEvaluationApiCall = async (developmentPlanUuid: string, candidateUuid: string): Promise<FetchResult<DevelopmentPlanEvaluation, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlan/${developmentPlanUuid}/evaluation`, {
            candidateUuid,
            method: 'POST',
            body: JSON.stringify({
                developmentPlanId: developmentPlanUuid,
            }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanEvaluationResource;

        const developmentPlanEvaluation = transformToDevelopmentPlanEvaluation(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanEvaluation,
        };
    } catch (error) {
        console.error('[postDevelopmentPlanEvaluationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const editDevelopmentPlanEvaluationApiCall = async (
    requestData: DevelopmentPlanEvaluationRequest,
    developmentPlanEvaluationUuid: string,
    candidateUuid: string,
): Promise<FetchResult<DevelopmentPlanEvaluation, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlanEvaluation/${developmentPlanEvaluationUuid}`, {
            candidateUuid,
            method: 'PATCH',
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentPlanEvaluationResource;

        const developmentPlanEvaluation = transformToDevelopmentPlanEvaluation(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentPlanEvaluation,
        };
    } catch (error) {
        console.error('[editDevelopmentPlanEvaluationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteDevelopmentPlanEvaluationApiCall = async (developmentPlanEvaluationUuid: string, candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/developmentPlanEvaluation/${developmentPlanEvaluationUuid}`, {
            candidateUuid,
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteDevelopmentPlanEvaluationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
