import { ReactElement, ReactNode } from 'react';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';
import { SortConfig, SortDirection, SortField } from '../../../types';
import { SortLabel } from '../..';
import { SortLabelInfo } from '../SortLabel/SortLabel';

import './SortTable.scss';

interface SortTableProps<Interface> {
    caption?: string;
    sortCells: SortLabelInfo[];
    rows?: Interface[];
    sortConfig: SortConfig;
    hasStickyColumn?: boolean;
    onSort: (field: SortField, direction: SortDirection) => void;
    className?: string;
    rowClassName?: string;
    cellClassName?: string;
    children?: (sortedRows: Interface[]) => ReactNode;
}

const SortTable = <Interface, >({
    caption,
    sortConfig,
    sortCells,
    rows = [],
    hasStickyColumn,
    onSort,
    className = '',
    rowClassName = '',
    cellClassName = '',
    children,
}: SortTableProps<Interface>): ReactElement => {
    const { field, direction } = sortConfig;

    return (
        <Table className={`sort-table ${className}`}>
            {caption && <TableCaption title={caption} />}

            <TableHead>
                <TableRow className={`sort-table__header-row ${rowClassName}`}>
                    {sortCells.map(cell => (
                        <SortLabel
                            {...cell}
                            key={cell.label}
                            isActive={cell.field === field}
                            activeDirection={direction}
                            onSort={onSort}
                            className={cellClassName}
                        />
                    ))}
                    {hasStickyColumn && <TableCell className="sort-table__sticky-column" />}
                </TableRow>
            </TableHead>

            <TableBody>
                {children && children(rows)}
            </TableBody>
        </Table>
    );
};

export default SortTable;
