import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon } from '../../../../components';
import { NavigationItemProps } from '../../../../Routes';

import './TabsNavigationItem.scss';

const getNavLinkClassName = (props: { isActive: boolean }) => classNames('tabs-navigation-item__link', {
    'tabs-navigation-item__link--is-active': props.isActive,
});

interface TabsNavigationItemProps extends NavigationItemProps {
    hasIndicator?: boolean;
}

const TabsNavigationItem: FC<TabsNavigationItemProps> = ({
    label,
    icon,
    hasIndicator,
    className = '',
    ...navigationItemProps
}): ReactElement => (
    <li className={`tabs-navigation-item ${className}`}>
        <NavLink
            {...navigationItemProps}
            className={getNavLinkClassName}
        >
            {icon && <Icon name={icon} className="tabs-navigation-item__link-icon" />}
            {label}
            {hasIndicator && <span className="tabs-navigation-item__indicator" />}
        </NavLink>
    </li>
);

export default TabsNavigationItem;
