import { OccupationDetail, OccupationSkill } from '../../../../models/OccupationDetail/OccupationDetail';
import { Skill } from '../../../../models/Skills/Skills';

export interface ComparedOccupationSkills {
    matchedEssential: OccupationSkill[];
    notMatchedEssential: OccupationSkill[];
    matchedOptional: OccupationSkill[];
    notMatchedOptional: OccupationSkill[];
}

const defaultComparedOccupationSkills = (): ComparedOccupationSkills => ({
    matchedEssential: [],
    notMatchedEssential: [],
    matchedOptional: [],
    notMatchedOptional: [],
});

export const getComparedOccupationSkills = (occupation: OccupationDetail, skills: Skill[]): ComparedOccupationSkills => {
    if (skills.length === 0) return defaultComparedOccupationSkills();

    const skillLabels = skills.map(skill => skill.name);

    const matchedEssential = occupation.essentialSkills.filter(skill => skillLabels.includes(skill.label.toLowerCase()));
    const notMatchedEssential = occupation.essentialSkills.filter(skill => !matchedEssential.includes(skill));
    const matchedOptional = occupation.optionalSkills.filter(skill => skillLabels.includes(skill.label.toLowerCase()));
    const notMatchedOptional = occupation.optionalSkills.filter(skill => !matchedOptional.includes(skill));

    return {
        matchedEssential,
        notMatchedEssential,
        matchedOptional,
        notMatchedOptional,
    };
};
