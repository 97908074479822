import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import {
    defaultOrganisationOccupationFilterValues,
    defaultOrganisatioOccupationOverview,
    OrganisationOccupationFilterValues,
    OrganisationOccupationOverview,
} from '../../models/OrganisationOccupationOverview/OrganisationOccupationOverview';
import { Pagination } from '../../models/Pagination';
import { AsyncReduxState } from '../../types';

export type OrganisationOccupationOverviewState = AsyncReduxState<{
    activeFilterValues: OrganisationOccupationFilterValues;
    occupationOverview: OrganisationOccupationOverview;
    pagination?: Pagination;
}>;

const initialState: OrganisationOccupationOverviewState = {
    ...initialAsyncReduxState,
    activeFilterValues: defaultOrganisationOccupationFilterValues(),
    occupationOverview: defaultOrganisatioOccupationOverview(),
};

export const organisationOccupationOverviewSlice = createSlice({
    name: 'organisationOccupationOverviewReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrganisationOccupationOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrganisationOccupationOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveFilterValues(state, action: PayloadAction<OrganisationOccupationFilterValues>): OrganisationOccupationOverviewState {
            return {
                ...state,
                activeFilterValues: action.payload,
            };
        },
        setOccupationOverview(state, action: PayloadAction<OrganisationOccupationOverview>): OrganisationOccupationOverviewState {
            return {
                ...state,
                occupationOverview: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>): OrganisationOccupationOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setActiveFilterValues,
    setOccupationOverview,
    setPagination,
} = organisationOccupationOverviewSlice.actions;

export default organisationOccupationOverviewSlice.reducer;
