import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { MyActions } from '../../containers';
import { DefaultAction } from '../../containers/MyActions/actions';
import { userHasRole } from '../../helpers/role';
import { scrollToTop } from '../../helpers/scroll';
import trans from '../../helpers/trans';
import { userHasModule } from '../../helpers/user';
import { Candidate } from '../../models/Candidate/Candidate';
import { Module } from '../../models/Modules/Modules';
import { User } from '../../models/User/User';
import { fetchCandidateCounselor } from '../../redux/candidate/candidateActions';
import { fetchDevelopmentOverview } from '../../redux/developmentOverview/developmentOverviewActions';
import { fetchSkillsPassport, importSkillsPassport } from '../../redux/skillsPassport/skillsPassportActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { RoutePaths } from '../../Routes';
import { ModuleType, UserRole } from '../../types';
import ConnectedIntroductionAction from '../@actions/ConnectedIntroductionAction/ConnectedIntroductionAction';

export interface Action {
    title: string;
    isCompleted: boolean;
    isOptional?: boolean;
    content: ReactElement;
    module?: Module;
}

interface ConnectedMyActionsProps {
    onCandidateResponse: (candidate: Candidate) => void;
    onUserResponse: (user: User) => void;
    className?: string;
}

const ConnectedMyActions: FC<ConnectedMyActionsProps> = ({
    onCandidateResponse,
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid } = useParams();

    const [introductionIsActive, setIntroductionIsActive] = useState<boolean>(false);

    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);
    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = useTypedSelector(state => state.candidateReducer.isLoading);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateCounselor = useTypedSelector(state => state.candidateReducer.candidateCounselor);

    const tmaAnalysisSettings = useTypedSelector(state => state.tmaAnalysisSettingsReducer.tmaAnalysisSettings);

    const skillsPassport = useTypedSelector(state => state.skillsPassportReducer);
    const developments = useTypedSelector(state => state.developmentOverviewReducer.developments);

    const hasFinishedIntroduction = !candidate?.firstVisit || !introductionIsActive;
    const candidateHasFinishedAnalysis = !!tmaAnalysisSettings?.isFinished;

    const isCandidate = userHasRole(UserRole.candidate, user?.roles);
    const descriptionRole = isCandidate ? UserRole.candidate : UserRole.counselor;

    const skillsPassportProgress = useMemo((): boolean[] => ([
        skillsPassport.skills.length > 0,
        skillsPassport.schoolExperiences.length > 0,
        skillsPassport.jobExperiences.length > 0,
    ]), [skillsPassport]);

    const tmaProgress = useMemo((): boolean[] => ([
        !!skillsPassport.personalityDescription,
        !!skillsPassport.competencies,
        skillsPassport.communicationStyles.effective.length + skillsPassport.communicationStyles.ineffective.length > 0,
        skillsPassport.workplacePreferences.length > 0,
        !!skillsPassport.incentives,
        skillsPassport.learningStyle.styles.length > 0,
    ]), [skillsPassport]);

    useEffect((): void => {
        if (!candidate?.uuid) return;

        dispatch(fetchSkillsPassport(candidate.uuid));
        dispatch(fetchDevelopmentOverview(candidate.uuid));

        if (!candidateCounselor) {
            dispatch(fetchCandidateCounselor(candidate.uuid));
        }
    }, [dispatch, candidate]);

    useEffect((): void => {
        if (user) onUserResponse(user);

        if (candidate) {
            onCandidateResponse(candidate);
            setIntroductionIsActive(!candidate.firstVisit);
        }
    }, [user, candidate]);

    useEffectOnce((): void => {
        if (candidateUuid) dispatch(importSkillsPassport(candidateUuid));
        scrollToTop();
    });

    const actions = useMemo((): Action[] => ([
        {
            title: trans('connectors.connectedMyActions.steps.introduction.title'),
            isCompleted: hasFinishedIntroduction,
            content: (
                <ConnectedIntroductionAction onIntroductionIsActive={setIntroductionIsActive} />
            ),
        },
        {
            title: trans('connectors.connectedMyActions.steps.skillsPassport.title'),
            isCompleted: skillsPassportProgress.every(Boolean),
            content: (
                <DefaultAction
                    description={trans(`connectors.connectedMyActions.steps.skillsPassport.description.${descriptionRole}`)}
                    to={RoutePaths.skillsPassport(candidateUuid)}
                    duration={40}
                    percentage={33}
                />
            ),
        },
        {
            title: trans('connectors.connectedMyActions.steps.analysis.title'),
            isCompleted: candidateHasFinishedAnalysis || tmaProgress.every(Boolean),
            content: (
                <DefaultAction
                    description={trans(`connectors.connectedMyActions.steps.analysis.description.${descriptionRole}`)}
                    to={RoutePaths.takeAnalysis(candidateUuid)}
                    duration={40}
                    percentage={67}
                />
            ),
        },
        {
            title: trans('connectors.connectedMyActions.steps.development.title'),
            isCompleted: developments.length > 0,
            isOptional: true,
            content: (
                <DefaultAction
                    description={trans(`connectors.connectedMyActions.steps.development.description.${descriptionRole}`)}
                    to={RoutePaths.development(candidateUuid)}
                    duration={60}
                />
            ),
        },
        ...(user && userHasModule(user.modules, ModuleType.education) ? [
            {
                title: trans('connectors.connectedMyActions.steps.education.title'),
                isCompleted: false,
                isOptional: true,
                content: (
                    <DefaultAction
                        description={trans(`connectors.connectedMyActions.steps.education.description.${descriptionRole}`)}
                        to={RoutePaths.seekEducation(candidateUuid)}
                        duration={20}
                    />
                ),
            },
        ] : []),
        {
            title: trans('connectors.connectedMyActions.steps.occupation.title'),
            isCompleted: false,
            content: (
                <DefaultAction
                    description={trans(`connectors.connectedMyActions.steps.occupation.description.${descriptionRole}`)}
                    to={RoutePaths.seekOccupation(candidateUuid)}
                    duration={20}
                    percentage={80}
                />
            ),
        },
        ...(user && userHasModule(user.modules, ModuleType.matching) ? [
            {
                title: trans('connectors.connectedMyActions.steps.vacancies.title'),
                isCompleted: false,
                content: (
                    <DefaultAction
                        description={trans(`connectors.connectedMyActions.steps.vacancies.description.${descriptionRole}`)}
                        to={RoutePaths.seekVacancies(candidateUuid)}
                        duration={25}
                        percentage={90}
                    />
                ),
            },
            {
                title: trans('connectors.connectedMyActions.steps.apply.title'),
                isCompleted: false,
                content: (
                    <DefaultAction
                        description={trans(`connectors.connectedMyActions.steps.apply.description.${descriptionRole}`)}
                        to={RoutePaths.seekVacancies(candidateUuid)}
                        duration={25}
                        percentage={100}
                    />
                ),
            },
        ] : []),
    ]), [
        candidate,
        introductionIsActive,
        descriptionRole,
        skillsPassportProgress,
        tmaProgress,
        developments,
    ]);

    return (
        <MyActions
            userIsLoading={userIsLoading}
            isLoading={isLoading}
            isCandidate={isCandidate}
            actions={actions}
            candidateCounselor={candidateCounselor}
            className={className}
        />
    );
};

export default ConnectedMyActions;
