/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconAnalysis: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M19.7,76.1h-1.9c-6.1,0-11,4.9-11,11V89c0,6.1,4.9,11,11,11h1.9c6.1,0,11-4.9,11-11v-1.9C30.7,81.1,25.8,76.1,19.7,76.1z" />
        <path d="M50.9,38H49C43,38,38,43,38,49v40c0,6.1,4.9,11,11,11h1.9c6.1,0,11-4.9,11-11V49C61.9,43,57,38,50.9,38z" />
        <path d="M82.2,0h-1.9c-6.1,0-11,4.9-11,11v78c0,6.1,4.9,11,11,11h1.9c6.1,0,11-4.9,11-11V11C93.2,4.9,88.3,0,82.2,0z" />
    </svg>
);

export default IconAnalysis;
/* eslint-enable max-len */
