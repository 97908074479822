import { FC, ReactElement } from 'react';

import { Textarea } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';

import './DevelopmentPlanCardQuestion.scss';

interface DevelopmentPlanCompetencyProps {
    isEditing: boolean;
    label: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

const DevelopmentPlanCardQuestion: FC<DevelopmentPlanCompetencyProps> = ({
    isEditing,
    label,
    placeholder,
    value,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`development-plan-card-question ${className}`}>
        <h3 className="development-plan-card-question__label">{label}</h3>
        {isEditing ? (
            <Textarea
                label={label}
                hideLabel
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                textareaClassName="development-plan-card-question__input"
            />
        ) : (
            <p className="development-plan-card-question__answer">
                {value || (<span className="development-plan-card-question__empty-field">{trans('common.emptyField')}</span>)}
            </p>
        )}
    </div>
);

export default DevelopmentPlanCardQuestion;
