import { FC, ReactElement, useMemo } from 'react';

import { useEffectOnce } from 'react-use';

import { CoachingAdviceCard } from '../../../containers';
import { fetchCoachingAdvice } from '../../../redux/coachingAdvice/coachingAdviceActions';
import { fetchCompetencies } from '../../../redux/competencies/competenciesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { FormOption } from '../../../types';

interface ConnectedCoachingAdviceCardProps {
    className?: string;
}

const ConnectedCoachingAdviceCard: FC<ConnectedCoachingAdviceCardProps> = ({ className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const competenciesIsLoading = useTypedSelector(state => state.competenciesReducer.isLoading);
    const competencies = useTypedSelector(state => state.competenciesReducer.competencies);

    const coachingAdviceIsLoading = useTypedSelector(state => state.coachingAdviceReducer.isLoading);
    const coachingAdvice = useTypedSelector(state => state.coachingAdviceReducer.coachingAdvice);

    const isLoading = competenciesIsLoading || coachingAdviceIsLoading;

    const competencyFormOptions = useMemo((): FormOption[] => (
        competencies.map(competency => ({
            value: competency.id,
            label: competency.label,
        }))
    ), [competencies]);

    const handleSelectedCompetencyChange = (competencyId: string): void => {
        dispatch(fetchCoachingAdvice(competencyId));
    };

    useEffectOnce((): void => {
        if (competencies.length === 0) dispatch(fetchCompetencies());
    });

    return (
        <CoachingAdviceCard
            isLoading={isLoading}
            competencyFormOptions={competencyFormOptions}
            coachingAdvice={coachingAdvice}
            onSelectedCompetencyChange={handleSelectedCompetencyChange}
            className={className}
        />
    );
};

export default ConnectedCoachingAdviceCard;
