import { isFetchResultSuccessful } from '../../models/FetchResult';
import { getJobdiggerVacancyApiCall, getVacancyApiCall } from '../../services/VacancyService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setVacancy } from './vacancy';

export const fetchVacancy = (vacancyUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyResponse = await getVacancyApiCall(vacancyUuid);

        if (!isFetchResultSuccessful(vacancyResponse)) {
            dispatch(setError(vacancyResponse.error));
            return;
        }

        dispatch(setVacancy(vacancyResponse.data));
    } catch (error) {
        console.error('[fetchVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchJobdiggerVacancy = (vacancyUuid: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const vacancyResponse = await getJobdiggerVacancyApiCall(vacancyUuid, candidateUuid);

        if (!isFetchResultSuccessful(vacancyResponse)) {
            dispatch(setError(vacancyResponse.error));
            return;
        }

        dispatch(setVacancy(vacancyResponse.data));
    } catch (error) {
        console.error('[fetchJobdiggerVacancy]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
