import {
    FC,
    ReactElement,
    UIEvent,
    useEffect,
    useState,
} from 'react';

import { Button } from '../../../../../components';
import trans from '../../../../../helpers/trans';
import { Skill } from '../../../../../models/Skills/Skills';
import { SkillOption } from '../../../..';

import './SkillResults.scss';

interface SkillResultsProps {
    searchResults: Skill[];
    selectedOptions: Skill[];
    onSelectOption: (selectedOption: Skill) => void;
    onClearSearch: () => void;
    className?: string;
}

const SkillResults: FC<SkillResultsProps> = ({
    searchResults,
    selectedOptions,
    onSelectOption,
    onClearSearch,
    className = '',
}): ReactElement => {
    const resultsLimit = 20;

    const [resultsPage, setResultsPage] = useState<number>(1);
    const [limitedSearchResults, setLimitedSearchResults] = useState<Skill[]>([]);

    useEffect((): void => {
        if (searchResults.length === 0) {
            setResultsPage(1);
        }
    }, [searchResults]);

    useEffect((): void => {
        const limitedResults = searchResults.slice(0, resultsLimit * resultsPage);
        setLimitedSearchResults(limitedResults);
    }, [searchResults, resultsPage]);

    const handleListScroll = (event: UIEvent<HTMLUListElement>): void => {
        if (searchResults.length <= resultsLimit) return;

        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        const scrollPosition = scrollTop + clientHeight;

        if (scrollPosition === scrollHeight) {
            setResultsPage(resultsPage + 1);
        }
    };

    return (
        <div className={`skill-results ${className}`}>
            {limitedSearchResults.length > 0 && (
                <ul onScroll={handleListScroll} className="skill-results__list">
                    {limitedSearchResults.map(option => (
                        <SkillOption
                            key={option.id}
                            option={option}
                            selectedOptions={selectedOptions}
                            onSelectOption={onSelectOption}
                            className="skill-results__item"
                        />
                    ))}
                </ul>
            )}

            {limitedSearchResults.length > 0 && (
                <div className="skill-results__button-wrapper">
                    <Button
                        text={trans('compositions.skillsSelector.resetSearch')}
                        onClick={onClearSearch}
                        className="skill-results__reset-button"
                    />
                </div>
            )}
        </div>
    );
};

export default SkillResults;
