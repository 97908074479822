import { DevelopmentPlanNoteFormData } from '../../../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type DevelopmentPlanNoteFormErrors = FormErrors<DevelopmentPlanNoteFormData>;

export const validateDevelopmentPlanNoteFormData = (formData: DevelopmentPlanNoteFormData): FormValidation<DevelopmentPlanNoteFormErrors> => {
    const errors: DevelopmentPlanNoteFormErrors = {
        content: validateRequiredString('content', formData.content),
    };

    return validateForm<DevelopmentPlanNoteFormErrors>(errors);
};
