import { FC, ReactElement } from 'react';

import { SafeHtml } from '../../../../../components';

import './OccupationOverviewNullState.scss';

interface OccupationOverviewNullStateProps {
    description: string;
    callToAction: string;
    className?: string;
}

const OccupationOverviewNullState: FC<OccupationOverviewNullStateProps> = ({
    description,
    callToAction,
    className = '',
}): ReactElement => (
    <div className={`occupation-overview-null-state ${className}`}>
        <SafeHtml html={description} className="occupation-overview-null-state__paragraph" />
        <p className="occupation-overview-null-state__paragraph">
            {callToAction}
        </p>
    </div>
);

export default OccupationOverviewNullState;
