import { FC, ReactElement } from 'react';

import { Loading, SearchInput } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { defaultOrganisation, Organisation } from '../../../../../models/Organisation/Organisation';
import { CandidateExchangeFormErrors } from '../../validations';
import OrganisationSearchResult from '../OrganisationSearchResult/OrganisationSearchResult';

import './OrganisationStep.scss';

interface OrganisationStepProps {
    isLoading: boolean;
    error: string;
    formErrors: CandidateExchangeFormErrors;
    newOrganisation?: Organisation;
    options: Organisation[];
    searchQuery: string;
    onSearch: (query: string) => void;
    onOrganisationSelect: (organisation: Organisation) => void;
    className?: string;
}

const OrganisationStep: FC<OrganisationStepProps> = ({
    isLoading,
    error,
    formErrors,
    newOrganisation = defaultOrganisation(),
    options,
    searchQuery,
    onSearch,
    onOrganisationSelect,
    className = '',
}): ReactElement => (
    <div className={`organisation-step ${className}`}>
        <SearchInput
            label={trans('forms.candidateExchange.input.newOrganisation')}
            value={searchQuery}
            placeholder={trans('forms.candidateExchange.placeholder.newOrganisation')}
            error={formErrors.counselorId}
            onChange={onSearch}
            className="organisation-step__input"
        />

        {isLoading && <Loading className="organisation-step__loader" />}

        {!isLoading && options.length > 0 && !error && (
            <ul className="organisation-step__result-list">
                {options.map(option => (
                    <OrganisationSearchResult
                        key={option.id}
                        name="new-organisation"
                        organisation={option}
                        checked={option.id === newOrganisation.id}
                        onSelect={onOrganisationSelect}
                        className="organisation-step__result-item"
                    />
                ))}
            </ul>
        )}
    </div>
);

export default OrganisationStep;
