import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';

import './Textarea.scss';

interface TextareaProps extends RefAttributes<HTMLTextAreaElement> {
    error?: string;
}

export type HTMLTextareaProps = JSX.IntrinsicElements['textarea'] & TextareaProps;

const Textarea: ForwardRefExoticComponent<HTMLTextareaProps> = forwardRef(({
    error = '',
    className = '',
    ...textareaProps
}, ref: Ref<HTMLTextAreaElement>): ReactElement => {
    const textareaClassNames = classNames('textarea-input', className, {
        'textarea-input--has-error': !!error,
    });

    return (
        <textarea
            {...textareaProps}
            ref={ref}
            className={textareaClassNames}
        />
    );
});

export default Textarea;
