import { ExchangeConfirmationError, ExchangeRequestError } from './CandidateExchange';

export const transformToExchangeRequestError = (
    exchangeErrorResource: string,
): ExchangeRequestError | undefined => {
    const exchangeRequestErrors: Record<string, ExchangeRequestError> = {
        'Request is already made and is still pending': ExchangeRequestError.requestPending,
        'Candidate and advisor already paired': ExchangeRequestError.alreadyPaired,
        'candidate and/or advisor is not a member of organisation': ExchangeRequestError.wrongOrganisation,
    };

    return exchangeRequestErrors[exchangeErrorResource];
};

export const transformToExchangeConfirmationError = (
    exchangeErrorResource: string,
): ExchangeConfirmationError | undefined => {
    const exchangeConfirmationErrors: Record<string, ExchangeConfirmationError> = {
        'No open request for this user combination': ExchangeConfirmationError.noRequest,
    };

    return exchangeConfirmationErrors[exchangeErrorResource];
};
