import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, Notice, TextInput } from '../../../compositions';
import { lloOrganisationName } from '../../../constants';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { Organisation, OrganisationFormData } from '../../../models/Organisation/Organisation';
import { DialogFormProps, NoticeType } from '../../../types';
import { OrganisationFormErrors, validateOrganisationFormData } from './validations';

import './OrganisationForm.scss';

interface OrganisationFormProps extends DialogFormProps<OrganisationFormData> {
    organisation?: Organisation;
    className?: string;
}

const OrganisationForm: FC<OrganisationFormProps> = ({
    isLoading,
    error = '',
    organisation,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<OrganisationFormErrors>({});

    const [name, setName] = useState<string>('');
    const [location, setLocation] = useState<string>('');

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (organisation?.name) setName(organisation.name);
        if (organisation?.location) setLocation(organisation.location);
    }, [organisation]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = {
            id: organisation?.id || '',
            name,
            location,
        };

        const [errors, hasErrors] = validateOrganisationFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`organisation-form ${className}`}>
            <TextInput
                required
                disabled={organisation?.name === lloOrganisationName}
                label={trans('forms.organisationForm.input.name')}
                value={name}
                error={formErrors.name}
                onChange={setName}
                className="organisation-form__input"
            />

            <TextInput
                required
                label={trans('forms.organisationForm.input.location')}
                value={location}
                error={formErrors.location}
                onChange={setLocation}
                className="organisation-form__input"
            />

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="organisation-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="organisation-form__action-buttons"
            />
        </form>
    );
};

export default OrganisationForm;
