import { FC, ReactElement } from 'react';

import {
    IconAnalysis,
    IconArchive,
    IconArrowLeft,
    IconArrowRight,
    IconBin,
    IconCheck,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconCross,
    IconDashboard,
    IconDevelopment,
    IconDisk,
    IconDownload,
    IconEdit,
    IconEducation,
    IconEmotionHappy,
    IconEmotionSatisfied,
    IconExchange,
    IconExclamation,
    IconEye,
    IconFilter,
    IconInfo,
    IconJobExperience,
    IconLocale,
    IconLogout,
    IconMenu,
    IconMessage,
    IconMessageOpen,
    IconNoticeError,
    IconNoticeRegular,
    IconNoticeWarning,
    IconNumerical,
    IconOccupation,
    IconOrganisation,
    IconPercentage,
    IconPlay,
    IconPlus,
    IconRefresh,
    IconSearch,
    IconSettings,
    IconSkillsPassport,
    IconSortDown,
    IconSortUp,
    IconStarCheck,
    IconStep,
    IconVacancySearch,
    IconVerticalEllipsis,
    IconViewCompact,
    IconViewExtensive,
    IconVisibilityOff,
    IconVisibilityOn,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
    accentClassName?: string;
}

const icons = {
    analysis: IconAnalysis,
    archive: IconArchive,
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    bin: IconBin,
    check: IconCheck,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'chevron-up': IconChevronUp,
    cross: IconCross,
    dashboard: IconDashboard,
    development: IconDevelopment,
    disk: IconDisk,
    download: IconDownload,
    edit: IconEdit,
    education: IconEducation,
    'emotion-happy': IconEmotionHappy,
    'emotion-satisfied': IconEmotionSatisfied,
    exchange: IconExchange,
    exclamation: IconExclamation,
    eye: IconEye,
    filter: IconFilter,
    info: IconInfo,
    'job-experience': IconJobExperience,
    locale: IconLocale,
    logout: IconLogout,
    menu: IconMenu,
    message: IconMessage,
    'message-open': IconMessageOpen,
    'notice-error': IconNoticeError,
    'notice-regular': IconNoticeRegular,
    'notice-warning': IconNoticeWarning,
    numerical: IconNumerical,
    occupation: IconOccupation,
    organisation: IconOrganisation,
    percentage: IconPercentage,
    play: IconPlay,
    plus: IconPlus,
    refresh: IconRefresh,
    search: IconSearch,
    settings: IconSettings,
    'skills-passport': IconSkillsPassport,
    'sort-down': IconSortDown,
    'sort-up': IconSortUp,
    'star-check': IconStarCheck,
    step: IconStep,
    'vacancy-search': IconVacancySearch,
    'vertical-ellipsis': IconVerticalEllipsis,
    'view-compact': IconViewCompact,
    'view-extensive': IconViewExtensive,
    'visibility-off': IconVisibilityOff,
    'visibility-on': IconVisibilityOn,
};

export type IconName = keyof typeof icons;

interface IconProps extends SvgIconProps {
    name: IconName;
}

const Icon: FC<IconProps> = ({
    name,
    className = '',
    accentClassName = '',
}): ReactElement | null => {
    const IconComponent = icons[name] || null;

    return IconComponent ? <IconComponent className={className} accentClassName={accentClassName} /> : null;
};

export default Icon;
