export interface CandidateExchangeFormData {
    candidateId: string;
    organisationId?: string;
    counselorId: string;
}

export enum ExchangeRequestError {
    requestPending = 'requestPending',
    alreadyPaired = 'alreadyPaired',
    wrongOrganisation = 'wrongOrganisation',
}

export enum ExchangeConfirmationError {
    noRequest = 'noRequest',
}
