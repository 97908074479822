import { OrganisationOccupationFormData } from '../../../../models/OrganisationOccupation/OrganisationOccupation';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type OrganisationOccupationFormErrors = FormErrors<OrganisationOccupationFormData>;

export const validateOrganisationOccupationFormData = (formData: OrganisationOccupationFormData): FormValidation<OrganisationOccupationFormErrors> => {
    const errors: OrganisationOccupationFormErrors = {
        title: validateRequiredString('title', formData.title),
        description: validateRequiredString('description', formData.description),
    };

    return validateForm<OrganisationOccupationFormErrors>(errors);
};
