import {
    AddDevelopmentPlanSkillFormData,
    AddDevelopmentPlanSkillRequest,
    DevelopmentPlanSkill,
    DevelopmentPlanSkillResource,
    EditDevelopmentPlanSkillFormData,
    EditDevelopmentPlanSkillRequest,
} from './DevelopmentPlanSkill';

export const transformToDevelopmentPlanSkill = (resource: DevelopmentPlanSkillResource): DevelopmentPlanSkill => ({
    id: resource.id,
    name: resource.name,
    remarks: resource.remarks || '',
    escoSkillId: resource.escoSkillId,
    isCompleted: resource.isCompleted,
});

export const transformToAddDevelopmentPlanSkillRequest = (formData: AddDevelopmentPlanSkillFormData): AddDevelopmentPlanSkillRequest => ({
    name: formData.name,
    escoSkillId: formData.escoSkillId,
    developmentPlanId: formData.developmentPlanId,
});

export const transformToEditDevelopmentPlanSkillRequest = (formData: EditDevelopmentPlanSkillFormData): EditDevelopmentPlanSkillRequest => ({
    remarks: formData.remarks,
    isCompleted: formData.isCompleted,
});
