import { Competency, CompetencyResource } from '../Competencies/Competencies';
import { JobExperience, JobExperienceResource } from '../JobExperience/JobExperience';
import { SchoolExperience, SchoolExperienceResource } from '../SchoolExperience/SchoolExperience';
import { Skill, SkillResource } from '../Skills/Skills';

export interface IncentiveResource {
    dimension: string;
    name: string;
    definition: string;
    talentLow: string;
    talentHigh: string;
    value: number;
}

export interface LearningStyleResource {
    learningStyles: string[];
    learningStyleAxes: {
        horizontal: {
            low: string;
            high: string;
            normScore: number;
        };
        vertical: {
            low: string;
            high: string;
            normScore: number;
        };
    };
}

export interface TalentResource {
    name: string;
    value: number;
    intro: string;
    descriptions: {
        title: string;
        text: string;
    }[];
}

export interface SkillsPassportResource {
    communicationStyle?: {
        effectiveCommunicationBlocks: string[];
        inEffectiveCommunicationBlocks: string[];
    };
    workingEnvironment: string[];
    drives: IncentiveResource[];
    learningStyle?: LearningStyleResource;
    competences: CompetencyResource[];
    consistencyScore: number;
    summary?: string;
    talents: TalentResource[];
    skills: SkillResource[];
    workingExperiences: JobExperienceResource[];
    schoolExperiences: SchoolExperienceResource[];
}

export interface CommunicationStyles {
    effective: string[];
    ineffective: string[];
}

export interface Incentive {
    dimension: string;
    name: string;
    description: string;
    startLabel: string;
    endLabel: string;
    value: number;
}

export interface LearningStyle {
    styles: string[];
    graph: {
        xAxis: {
            leftLabel: string;
            rightLabel: string;
            value: number;
        };
        yAxis: {
            topLabel: string;
            bottomLabel: string;
            value: number;
        };
    };
}

export interface Talent {
    name: string;
    value: number;
    introduction: string;
    descriptions: {
        title: string;
        description: string;
    }[];
}

export interface SkillsPassport {
    communicationStyles: CommunicationStyles;
    workplacePreferences: string[];
    incentives?: Record<string, Incentive[]>;
    learningStyle: LearningStyle;
    competencies?: Record<string, Competency[]>;
    consistencyScore: number;
    personalityDescription: string;
    talents: Talent[];
    skills: Skill[];
    jobExperiences: JobExperience[];
    schoolExperiences: SchoolExperience[];
    updatedAt: Date;
    isUpToDate: boolean;
}

export const defaultCommunicationStyles = (): CommunicationStyles => ({
    effective: [],
    ineffective: [],
});

export const defaultLearningStyle = (): LearningStyle => ({
    styles: [],
    graph: {
        xAxis: {
            leftLabel: '',
            rightLabel: '',
            value: 0,
        },
        yAxis: {
            topLabel: '',
            bottomLabel: '',
            value: 0,
        },
    },
});
