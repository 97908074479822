import { v4 as generateUuid } from 'uuid';

import { Competency } from '../models/Competencies/Competencies';
import { CompetencyLevel } from '../types';

export const defaultCompetencyList: Competency[] = [
    'Aandacht voor details',
    'Aanpassingsvermogen',
    'Ambitie',
    'Assertiviteit',
    'Besluitvaardigheid',
    'Coachen',
    'Commercieel vermogen',
    'Conflicthantering',
    'Creativiteit',
    'Delegeren',
    'Discipline',
    'Durf',
    'Energie',
    'Flexibel gedrag',
    'Groepsgericht leidinggeven',
    'Initiatief',
    'Inlevingsvermogen',
    'Innoverend vermogen',
    'Klantgerichtheid',
    'Kwaliteitsgerichtheid',
    'Leervermogen',
    'Leidinggeven',
    'Luisteren',
    'Managementidentificatie',
    'Netwerken',
    'Omgevingsbewustzijn',
    'Onafhankelijkheid',
    'Onderhandelen',
    'Ondernemerschap',
    'Ontwikkelen van medewerkers',
    'Oordeelsvorming',
    'Optreden',
    'Organisatiesensitiviteit',
    'Overtuigingskracht',
    'Plannen & organiseren',
    'Presenteren',
    'Prestatiemotivatie',
    'Resultaatgerichtheid',
    'Samenwerken',
    'Sociabiliteit',
    'Stressbestendigheid',
    'Vasthoudendheid',
    'Verantwoordelijkheid',
    'Visie',
    'Voortgangscontrole',
].map(competency => ({
    id: generateUuid(),
    label: competency,
    description: '',
    level: CompetencyLevel.noLevel,
    developmentTips: [],
}));
