import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Action } from '../../connectors/ConnectedMyActions/ConnectedMyActions';
import { CandidateCounselor } from '../../models/Candidate/Candidate';
import { ActionItem, CounselorContact } from './subcomponents';

import './MyActions.scss';

interface MyActionsProps {
    userIsLoading: boolean;
    isLoading: boolean;
    isCandidate: boolean;
    actions: Action[];
    candidateCounselor?: CandidateCounselor;
    className?: string;
}

const MyActions: FC<MyActionsProps> = ({
    userIsLoading,
    isLoading,
    isCandidate,
    actions,
    candidateCounselor,
    className = '',
}): ReactElement => {
    const [expandedAction, setExpandedAction] = useState<Action>();

    useEffect((): void => {
        if (userIsLoading) {
            setExpandedAction(undefined);
            return;
        }

        const activeAction = actions.find(action => !action.isCompleted && !action.isOptional);

        setExpandedAction(activeAction);
    }, [userIsLoading, actions]);

    return (
        <div className={`my-actions ${className}`}>
            {isCandidate && (
                <CounselorContact
                    isLoading={isLoading}
                    candidateCounselor={candidateCounselor}
                />
            )}

            <ul className="my-actions__list">
                {actions.map((action, index) => (
                    <ActionItem
                        key={action.title}
                        isLoading={isLoading}
                        showContent={action.title === expandedAction?.title}
                        index={index}
                        action={action}
                        totalAmountOfActions={actions.length}
                        onToggleActionItem={setExpandedAction}
                        className="my-actions__list-item"
                    />
                ))}
            </ul>
        </div>
    );
};

export default MyActions;
