import { Action } from 'redux';

import trans from '../../helpers/trans';
import { CandidateExchangeFormData } from '../../models/CandidateExchange/CandidateExchange';
import {
    postArchiveCandidateApiCall,
    postCandidateExchangeRequestApiCall,
    postConfirmCandidateExchangeApiCall,
    postTransferCandidateApiCall,
} from '../../models/CandidateExchange/CandidateExchangeService';
import { getCounselorOptionsApiCall } from '../../models/Counselor/CounselorService';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import {
    setCounselorOptions,
    setError,
    setIsLoading,
    setIsSuccessful,
    setNewCounselorFullName,
} from './candidateExchange';

export const updateCandidateExchangeSuccess = (isSuccessful: boolean) => (dispatch: TypedDispatch): Action => (
    dispatch(setIsSuccessful(isSuccessful))
);

export const requestCandidateExchange = (organisationId: string, candidateExchangeFormData: CandidateExchangeFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const candidateExchangeRequestResponse = await postCandidateExchangeRequestApiCall(organisationId, candidateExchangeFormData);

        if (!isFetchResultSuccessful(candidateExchangeRequestResponse)) {
            dispatch(setError(candidateExchangeRequestResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[requestCandidateExchange]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const confirmCandidateExchange = (candidateUuid: string, counselorUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const confirmCandidateExchangeResponse = await postConfirmCandidateExchangeApiCall(candidateUuid, counselorUuid);

        if (!isFetchResultSuccessful(confirmCandidateExchangeResponse)) {
            dispatch(setError(confirmCandidateExchangeResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(setNewCounselorFullName(confirmCandidateExchangeResponse.data));
    } catch (error) {
        console.error('[confirmCandidateExchange]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const archiveCandidate = (candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const archiveCandidateResponse = await postArchiveCandidateApiCall(candidateUuid);

        if (!isFetchResultSuccessful(archiveCandidateResponse)) {
            dispatch(setError(archiveCandidateResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[archiveCandidate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const transferCandidate = (candidateExchangeFormData: CandidateExchangeFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const candidateExchangeRequestResponse = await postTransferCandidateApiCall(candidateExchangeFormData);

        if (!isFetchResultSuccessful(candidateExchangeRequestResponse)) {
            dispatch(setError(candidateExchangeRequestResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[transferCandidate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchExchangeCounselorOptions = (organisationUuid: string, searchQuery: string = '') => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const exchangeCounselorOptionsResponse = await getCounselorOptionsApiCall(organisationUuid, searchQuery);

        if (!isFetchResultSuccessful(exchangeCounselorOptionsResponse)) {
            dispatch(setError(exchangeCounselorOptionsResponse.error));
            return;
        }

        const counselorOptions = exchangeCounselorOptionsResponse.data;

        if (counselorOptions.length === 0) {
            dispatch(setError(trans('errors.noResultsFoundFor', { searchQuery })));
            return;
        }

        dispatch(setCounselorOptions(counselorOptions));
    } catch (error) {
        console.error('[fetchExchangeCounselorOptions]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
