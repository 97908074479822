export const generateIdArray = (amount: number): number[] => {
    const idArray = [];

    for (let i = 0; i < amount; i += 1) {
        idArray[i] = i;
    }

    return idArray;
};

export const shuffleArray = <T>(arrayToShuffle: T[]): T[] => {
    const array = arrayToShuffle;
    let currentIndex = array.length;
    let temporaryValue = null;
    let randomIndex = null;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const addArrayEntry = <T>(array: T[], entry: T): T[] => [...array, entry];

export const editArrayEntry = <T extends { id: string }>(array: T[], entry: T): T[] => {
    const entryToEdit = array.findIndex(arrayItem => arrayItem.id === entry.id);
    const arrayCopy = [...array];

    arrayCopy[entryToEdit] = entry;

    return arrayCopy;
};

export const deleteArrayEntry = <T extends { id: string }>(array: T[], entry: T): T[] => (
    array.filter(arrayItem => arrayItem.id !== entry.id)
);

export const convertArrayToMatrix = <T = unknown>(array: T[], matrixSize: number): T[][] => array.reduce((rows: T[][], key, index) => {
    const size = matrixSize > 0 ? matrixSize : 1;

    if (index % size === 0) {
        rows.push([key]);
    } else {
        rows[rows.length - 1].push(key);
    }

    return rows;
}, []);

export const convertStringArrayToSizedMatrix = (array: string[], maxLengthPerMatrix: number): string[][] => {
    let pageIndex = -1;

    return array.reduce((rows: string[][], key: string) => {
        const currentRow = rows[pageIndex];
        const willExceedMaxLength = currentRow && (currentRow.join('').length + key.length) >= maxLengthPerMatrix;

        if (!currentRow || willExceedMaxLength) {
            rows.push([key]);
            pageIndex += 1;

            return rows;
        }

        // Push to existing row
        rows[pageIndex].push(key);

        return rows;
    }, []);
};

export const alternateArrays = <T>(array: T[], arrayToAlternate: T[]): T[] => {
    const resultArray = [];
    const smallestArrayLength = Math.min(array.length, arrayToAlternate.length);

    for (let i = 0; i < smallestArrayLength; i += 1) {
        resultArray.push(array[i], arrayToAlternate[i]);
    }

    resultArray.push(...array.splice(smallestArrayLength), ...arrayToAlternate.splice(smallestArrayLength));

    return resultArray;
};

export const getDuplicatesFromArray = <T = unknown>(array: T[]): T[] => array.filter((item, index) => array.indexOf(item) !== index);

export const groupArrayByValueOfKey = <T, K extends keyof T>(array: T[], groupKey: K): Record<string, T[]> => (
    array.reduce((group: Record<string, T[]>, item) => {
        const groupCopy = group;
        const keyValue = String(item[groupKey]);

        if (!group[keyValue]) {
            groupCopy[keyValue] = [];
        }

        groupCopy[keyValue].push(item);

        return groupCopy;
    }, {})
);
