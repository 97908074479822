import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './VacancyDetailSkeletons.scss';

export interface VacancyDetailSkeletonsProps {
    className?: string;
}

const VacancyDetailSkeletons: FC<VacancyDetailSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const aboutDescriptionParagraphAmount = randomInBetweenValue(3, 6);
    const aboutDescriptionSkeletons = generateIdArray(aboutDescriptionParagraphAmount);

    const skillsAmount = randomInBetweenValue(9, 13);
    const skillsSkeletons = generateIdArray(skillsAmount);

    const competenciesAmount = randomInBetweenValue(5, 10);
    const competenciesSkeletons = generateIdArray(competenciesAmount);

    const propertyAmount = randomInBetweenValue(5, 7);
    const propertySkeletons = generateIdArray(propertyAmount);

    return (
        <div className={`vacancy-detail-skeletons ${className}`}>
            <Card className="vacancy-detail-skeletons__header-card">
                <div className="vacancy-detail-skeletons__header-cover" />
                <div className="vacancy-detail-skeletons__header-information">
                    <div className="vacancy-detail-skeletons__avatar" />

                    <div className="vacancy-detail-skeletons__header-text-wrapper">
                        <Skeleton className="vacancy-detail-skeletons__title" />
                        <Skeleton className="vacancy-detail-skeletons__company" />
                    </div>
                </div>
            </Card>

            <div className="vacancy-detail__content-wrapper">
                <div className="vacancy-detail-skeletons__body">
                    <Card className="vacancy-detail-skeletons__card">
                        <Skeleton className="vacancy-detail-skeletons__title" />

                        <div className="vacancy-detail-skeletons__about-description">
                            {aboutDescriptionSkeletons.map(paragraph => {
                                const lineAmount = randomInBetweenValue(4, 8);
                                const lineSkeletons = generateIdArray(lineAmount);

                                return (
                                    <div key={paragraph} className="vacancy-detail-skeletons__paragraph">
                                        {lineSkeletons.map(line => (
                                            <Skeleton key={line} className="vacancy-detail-skeletons__line" />
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </Card>

                    <Card className="vacancy-detail-skeletons__card">
                        <Skeleton className="vacancy-detail-skeletons__title" />

                        <div className="vacancy-detail-skeletons__skills-wrapper">
                            <Skeleton className="vacancy-detail-skeletons__sub-title" />

                            <ul className="vacancy-detail-skeletons__tag-list">
                                {skillsSkeletons.map(skill => (
                                    <div key={skill} className="vacancy-detail-skeletons__tag" />
                                ))}
                            </ul>
                        </div>

                        <div className="vacancy-detail-skeletons__competencies-wrapper">
                            <Skeleton className="vacancy-detail-skeletons__sub-title" />

                            <ul className="vacancy-detail-skeletons__tag-list">
                                {competenciesSkeletons.map(competency => (
                                    <div key={competency} className="vacancy-detail-skeletons__tag" />
                                ))}
                            </ul>
                        </div>
                    </Card>
                </div>

                <div className="vacancy-detail-skeletons__aside">
                    <Card className="vacancy-detail-skeletons__card">
                        <Skeleton className="vacancy-detail-skeletons__title" />

                        <div className="vacancy-detail-skeletons__property-wrapper">
                            {propertySkeletons.map(property => (
                                <div key={property} className="vacancy-detail-skeletons__property">
                                    <Skeleton className="vacancy-detail-skeletons__sub-title" />

                                    <div className="vacancy-detail-skeletons__tag-list">
                                        <div className="vacancy-detail-skeletons__tag" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default VacancyDetailSkeletons;
