import { addArrayEntry, deleteArrayEntry, editArrayEntry } from '../../helpers/array';
import { sortByStartDateAsc } from '../../helpers/sort';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { JobExperience, JobExperienceFormData } from '../../models/JobExperience/JobExperience';
import { deleteJobExperienceApiCall, patchJobExperienceApiCall, postJobExperienceApiCall } from '../../models/JobExperience/JobExperienceService';
import { transformToJobExperienceRequest } from '../../models/JobExperience/JobExperienceTransformers';
import { setJobExperiences } from '../skillsPassport/skillsPassport';
import { ReducerGetter, TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSuccessful } from './jobExperience';

export const addJobExperience = (jobExperienceFormData: JobExperienceFormData, candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { jobExperiences } = state.skillsPassportReducer;

    try {
        const jobExperienceRequest = transformToJobExperienceRequest(jobExperienceFormData);

        const jobExperienceResponse = await postJobExperienceApiCall(jobExperienceRequest, candidateUuid);

        if (!isFetchResultSuccessful(jobExperienceResponse)) {
            dispatch(setError(jobExperienceResponse.error));
            return;
        }

        const jobExperience = jobExperienceResponse.data;
        const newJobExperienceArray = addArrayEntry(jobExperiences, jobExperience);
        const sortedJobExperiences = [...newJobExperienceArray].sort(sortByStartDateAsc);

        dispatch(setJobExperiences(sortedJobExperiences));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addJobExperience]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editJobExperience = (jobExperienceFormData: JobExperienceFormData, candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { jobExperiences } = state.skillsPassportReducer;

    try {
        const jobExperienceRequest = transformToJobExperienceRequest(jobExperienceFormData);

        const jobExperienceResponse = await patchJobExperienceApiCall(jobExperienceRequest, candidateUuid);

        if (!isFetchResultSuccessful(jobExperienceResponse)) {
            dispatch(setError(jobExperienceResponse.error));
            return;
        }

        const jobExperience = jobExperienceResponse.data;
        const newJobExperienceArray = editArrayEntry(jobExperiences, jobExperience);
        const sortedJobExperiences = [...newJobExperienceArray].sort(sortByStartDateAsc);

        dispatch(setJobExperiences(sortedJobExperiences));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editJobExperience]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteJobExperience = (jobExperience: JobExperience, candidateUuid: string) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    const state = getState();
    const { jobExperiences } = state.skillsPassportReducer;

    try {
        const jobExperienceResponse = await deleteJobExperienceApiCall(jobExperience.id, candidateUuid);

        if (!isFetchResultSuccessful(jobExperienceResponse)) {
            dispatch(setError(jobExperienceResponse.error));
            return;
        }

        const newJobExperienceArray = deleteArrayEntry(jobExperiences, jobExperience);

        dispatch(setJobExperiences(newJobExperienceArray));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteJobExperience]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};
