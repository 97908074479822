import { defaultPageSize } from '../Pagination';

export interface OrganisationOccupationOverviewItemResource {
    id: string;
    title: string;
    description: string;
}

export interface OrganisationOccupationOverviewItem {
    id: string;
    title: string;
    description: string;
}

export interface OrganisationOccupationOverviewResource {
    totalAmount: number;
    occupations: OrganisationOccupationOverviewItemResource[];
}

export interface OrganisationOccupationOverview {
    totalAmount: number;
    occupations: OrganisationOccupationOverviewItem[];
}

export interface OrganisationOccupationFilterValues {
    pageNumber: number;
    pageSize: number;
}

export const defaultOrganisationOccupationFilterValues = (): OrganisationOccupationFilterValues => ({
    pageNumber: 1,
    pageSize: defaultPageSize,
});

export const defaultOrganisatioOccupationOverview = (): OrganisationOccupationOverview => ({
    totalAmount: 0,
    occupations: [],
});
