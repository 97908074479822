import { DevelopmentPlanNoteFormData, DevelopmentPlanNoteType } from '../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import {
    deleteDevelopmentPlanNoteApiCall,
    getDevelopmentPlanNotesApiCall,
    patchDevelopmentPlanNoteApiCall,
    postDevelopmentPlanNoteApiCall,
} from '../../models/DevelopmentPlanNote/DevelopmentPlanNoteService';
import { transformToAddDevelopmentPlanNoteRequest, transformToEditDevelopmentPlanNoteRequest } from '../../models/DevelopmentPlanNote/DevelopmentPlanNoteTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { TypedDispatch } from '../store';
import {
    setDevelopmentPlanPublicNotes,
    setError,
    setIsLoading,
    setIsSuccessful,
    setIsUpdating,
} from './developmentPlanPublicNotes';

export const getDevelopmentPlanPublicNotes = (developmentPlanId: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const developmentPlanNotesResponse = await getDevelopmentPlanNotesApiCall(DevelopmentPlanNoteType.public, developmentPlanId, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNotesResponse)) {
            dispatch(setError(developmentPlanNotesResponse.error));
            return;
        }

        dispatch(setDevelopmentPlanPublicNotes(developmentPlanNotesResponse.data));
    } catch (error) {
        console.error('[getDevelopmentPlanPublicNotes]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDevelopmentPlanPublicNote = (formData: DevelopmentPlanNoteFormData, developmentPlanId: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const addDevelopmentPlanNoteRequest = transformToAddDevelopmentPlanNoteRequest(developmentPlanId, formData);

        const developmentPlanNoteResponse = await postDevelopmentPlanNoteApiCall(DevelopmentPlanNoteType.public, addDevelopmentPlanNoteRequest, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNoteResponse)) {
            dispatch(setError(developmentPlanNoteResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlanPublicNote]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const editDevelopmentPlanPublicNote = (formData: DevelopmentPlanNoteFormData, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const editDevelopmentPlanNoteRequest = transformToEditDevelopmentPlanNoteRequest(formData);

        const developmentPlanNoteResponse = await patchDevelopmentPlanNoteApiCall(editDevelopmentPlanNoteRequest, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNoteResponse)) {
            dispatch(setError(developmentPlanNoteResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDevelopmentPlanPublicNote]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const deleteDevelopmentPlanPublicNote = (noteId: string, candidateId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentPlanNoteResponse = await deleteDevelopmentPlanNoteApiCall(noteId, candidateId);

        if (!isFetchResultSuccessful(developmentPlanNoteResponse)) {
            dispatch(setError(developmentPlanNoteResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDevelopmentPlanPublicNote]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsUpdating(false));
    }
};
