import { ReactElement, ReactNode } from 'react';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';

import './NonSortTable.scss';

interface NonSortTableProps<Interface> {
    caption?: string;
    tableHeaders?: string[];
    rows?: Interface[];
    hasStickyColumn?: boolean;
    hasRowClick?: boolean;
    headerRowClassName?: string;
    cellClassName?: string;
    className?: string;
    children?: (rows: Interface[]) => ReactNode;
}

const NonSortTable = <Interface, >({
    caption,
    tableHeaders,
    rows = [],
    hasStickyColumn,
    hasRowClick,
    headerRowClassName = '',
    cellClassName = '',
    className = '',
    children,
    // eslint-disable-next-line
}: NonSortTableProps<Interface>): ReactElement => {
    return (
        <Table className={`non-sort-table ${className}`}>
            {caption && <TableCaption title={caption} />}

            {tableHeaders && (
                <TableHead>
                    <TableRow className={`non-sort-table__header-row ${headerRowClassName}`}>
                        {tableHeaders.map(header => (
                            <TableCell
                                key={header}
                                className={cellClassName}
                            >
                                {header}
                            </TableCell>
                        ))}
                        {hasStickyColumn && <TableCell className="non-sort-table__sticky-column" />}
                        {hasRowClick && <TableCell className="non-sort-table__hidden-cell" />}
                    </TableRow>

                </TableHead>
            )}

            <TableBody>
                {children && children(rows)}
            </TableBody>
        </Table>
    );
};

export default NonSortTable;
