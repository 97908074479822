import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { DevelopmentPlanNotes } from '../../../containers';
import { DevelopmentPlanNoteFormData, DevelopmentPlanNoteType } from '../../../models/DevelopmentPlanNote/DevelopmentPlanNote';
import {
    addDevelopmentPlanPrivateNote,
    deleteDevelopmentPlanPrivateNote,
    editDevelopmentPlanPrivateNote,
    getDevelopmentPlanPrivateNotes,
} from '../../../redux/developmentPlanPrivateNotes/developmentPlanPrivateNotesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedDevelopmentPlanPrivateNotesProps {
    className?: string;
}

const ConnectedDevelopmentPlanPrivateNotes: FC<ConnectedDevelopmentPlanPrivateNotesProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { candidateUuid, planUuid: developmentPlanUuid } = useParams<{ candidateUuid: string; planUuid: string }>();

    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = useTypedSelector(state => state.developmentPlanPrivateNotesReducer.isLoading);
    const isUpdating = useTypedSelector(state => state.developmentPlanPrivateNotesReducer.isUpdating);
    const isSuccessful = useTypedSelector(state => state.developmentPlanPrivateNotesReducer.isSuccessful);
    const notes = useTypedSelector(state => state.developmentPlanPrivateNotesReducer.notes);

    const handleAddNoteSubmit = (formData: DevelopmentPlanNoteFormData): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(addDevelopmentPlanPrivateNote(formData, developmentPlanUuid, candidateUuid));
        }
    };

    const handleEditNoteSubmit = (formData: DevelopmentPlanNoteFormData): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(editDevelopmentPlanPrivateNote(formData, candidateUuid));
        }
    };

    const handleDeleteNoteSubmit = (noteId: string): void => {
        if (candidateUuid) {
            dispatch(deleteDevelopmentPlanPrivateNote(noteId, candidateUuid));
        }
    };

    useEffect((): void => {
        if (developmentPlanUuid && candidateUuid) {
            dispatch(getDevelopmentPlanPrivateNotes(developmentPlanUuid, candidateUuid));
        }
    }, [developmentPlanUuid, candidateUuid]);

    useEffect((): void => {
        if (isSuccessful && developmentPlanUuid && candidateUuid) {
            dispatch(getDevelopmentPlanPrivateNotes(developmentPlanUuid, candidateUuid));
        }
    }, [isSuccessful]);

    return (
        <DevelopmentPlanNotes
            isLoading={isLoading}
            isUpdating={isUpdating}
            isSuccessful={isSuccessful}
            user={user}
            noteType={DevelopmentPlanNoteType.private}
            notes={notes}
            onAddNoteSubmit={handleAddNoteSubmit}
            onEditNoteSubmit={handleEditNoteSubmit}
            onDeleteNoteSubmit={handleDeleteNoteSubmit}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanPrivateNotes;
