import { AzureLoginData } from '../../models/Azure';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { User } from '../../models/User/User';
import { getUserProfileApiCall } from '../../models/User/UserService';
import { doesLLOAccountExistInTalentz } from '../../services/TalentzService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setLogout,
    setUser,
} from './user';

export const updateUser = (user: User) => (dispatch: TypedDispatch): void => {
    dispatch(setUser(user));
};

export const fetchUser = (azureUser: AzureLoginData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setLogout(azureUser.logout));

    try {
        const userResponse = await getUserProfileApiCall();

        if (!isFetchResultSuccessful(userResponse)) {
            dispatch(setError(userResponse.error));
            return;
        }

        dispatch(setUser(userResponse.data));
    } catch (error) {
        console.error('[fetchUser]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const checkAccountInTalentz = (email: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const validation = await doesLLOAccountExistInTalentz(email);

        if (!isFetchResultSuccessful(validation)) {
            dispatch(setError(validation.error));
        }
    } catch (error) {
        console.error('[checkAccountInTalentz]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
