import { InternalOccupationResource } from '../InternalOccupations/InternalOccupations';
import { Occupation } from '../Occupations/Occupations';

export interface InternalOccupationSkill {
    id: string;
    label: string;
    isMatch: boolean;
}

export interface InternalOccupationCompetency {
    id: string;
    label: string;
    isMatch: boolean;
}

export interface InternalOccupationDetailResource extends InternalOccupationResource {
    skills: InternalOccupationSkill[];
    competencies: InternalOccupationCompetency[];
}

export interface InternalOccupationDetail extends Occupation {
    totalCompetencies: number;
    matchedCompetencies: number;
    skills: InternalOccupationSkill[];
    competencies: InternalOccupationCompetency[];
}

export interface InternalOccupationDetailRequest {
    occupationUuid: string;
    candidateUuid: string;
}

export const defaultInternalOccupationDetail = (): InternalOccupationDetail => ({
    id: '',
    name: '',
    description: '',
    totalSkills: 0,
    matchedSkills: 0,
    totalCompetencies: 0,
    matchedCompetencies: 0,
    matchedPercentage: 0,
    skills: [],
    competencies: [],
});
