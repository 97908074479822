import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { DevelopmentOverview, DevelopmentOverviewResource } from './DevelopmentOverview';
import { transformToDevelopmentOverview } from './DevelopmentOverviewTransformer';

export const fetchDevelopmentOverviewApiCall = async (candidateUuid: string): Promise<FetchResult<DevelopmentOverview[], string>> => {
    try {
        const response = await lloFetch('/api/development', {
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = await response.json() as DevelopmentOverviewResource[];

        const developmentOverview = data.map(transformToDevelopmentOverview);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: developmentOverview,
        };
    } catch (error) {
        console.error('[fetchDevelopmentOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
