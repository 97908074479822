import { UserRole } from '../../types';
import { UserRoleResource } from '../User/User';

export interface CounselorResource {
    id: string;
    displayName: string;
    email: string;
    roles: UserRoleResource[];
}

export interface Counselor {
    id: string;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    email: string;
    roles: UserRole[];
}

export interface AddCounselorRequest {
    organisationId: string;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    email: string;
    roles: string[];
}

export interface EditCounselorRequest {
    id: string;
    organisationId: string;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    roles: string[];
}

export interface CounselorFormData {
    id?: string;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    email: string;
    isOrganisationAdmin: boolean;
    isTmaExpert: boolean;
}

export interface CounselorOptionResource {
    id: string;
    firstName: string;
    lastName: string;
    lastNamePrefix?: string;
    email: string;
    roles: UserRoleResource[];
}

export interface CounselorOption {
    id: string;
    fullName: string;
    email: string;
    roles: UserRole[];
}

export const defaultCounselorOption = (): CounselorOption => ({
    id: '',
    fullName: '',
    email: '',
    roles: [],
});
