import { FC, ReactElement } from 'react';

import { Card } from '../../../../components';
import { ConnectedCandidatePersonalDataForm } from '../../../../connectors';
import trans from '../../../../helpers/trans';

import './PersonalDetails.scss';

interface PersonalDetailsProps {
    className?: string;
}

const PersonalDetails: FC<PersonalDetailsProps> = ({
    className = '',
}): ReactElement => (
    <Card className={`personal-details-tab ${className}`}>
        <h1 className="personal-details-tab__title">
            {trans('pages.settings.personalData.title')}
        </h1>

        <ConnectedCandidatePersonalDataForm className="personal-details-tab__form" />
    </Card>
);

export default PersonalDetails;
