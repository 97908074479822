import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
} from 'react';

import { FormActionButtons, Notice } from '../../../compositions';
import trans from '../../../helpers/trans';
import { OrganisationCandidate } from '../../../models/CandidateOverview/CandidateOverview';
import { DialogFormProps, NoticeType } from '../../../types';

import './ArchiveCandidateForm.scss';

interface ArchiveCandidateFormProps extends DialogFormProps<string> {
    candidate?: OrganisationCandidate;
    className?: string;
}

const ArchiveCandidateForm: FC<ArchiveCandidateFormProps> = ({
    isLoading,
    error,
    candidate,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!candidate) return;

        onSubmit(candidate.id);
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`archive-candidate-form ${className}`}>
            <p className="archive-candidate-form__description">
                {trans('forms.archiveCandidate.description', {
                    fullName: candidate?.fullName || '',
                })}
            </p>

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="archive-candidate-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                submitIcon="arrow-right"
                submitText={trans('forms.archiveCandidate.submitButtonLabel')}
                onCancelClick={onCancel}
                className="archive-candidate-form__action-buttons"
            />
        </form>
    );
};

export default ArchiveCandidateForm;
