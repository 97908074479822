import { FC, ReactElement } from 'react';

import { ConnectedCoachingAdviceCard } from '../../../../../connectors';

import './CoachingAdvice.scss';

const CoachingAdvice: FC = (): ReactElement => (
    <ConnectedCoachingAdviceCard className="coaching-advice-tab__card" />
);

export default CoachingAdvice;
