import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { OrganisationOccupationFilterValues } from '../../models/OrganisationOccupationOverview/OrganisationOccupationOverview';
import { getOrganisationOccupationOverviewApiCall } from '../../models/OrganisationOccupationOverview/OrganisationOccupationOverviewService';
import { transformToPagination } from '../../models/Pagination';
import { setIsSuccessful } from '../organisationOccupation/organisationOccupation';
import { ReducerGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setOccupationOverview,
    setPagination,
} from './organisationOccupationOverview';

export const fetchOrganisationOccupationOverview = (organisationUuid: string, activeFilterValues: OrganisationOccupationFilterValues) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const occupationOverviewResponse = await getOrganisationOccupationOverviewApiCall(organisationUuid, activeFilterValues);

        if (!isFetchResultSuccessful(occupationOverviewResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        const occupationOverview = occupationOverviewResponse.data;

        const currentPage = activeFilterValues.pageNumber;
        const pagination = transformToPagination(occupationOverview.totalAmount, currentPage);

        dispatch(setOccupationOverview(occupationOverviewResponse.data));
        dispatch(setPagination(pagination));
    } catch (error) {
        console.error('[fetchOrganisationOccupationOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const setOrganisationOccupationOverviewPagination = (currentPage: number) => (dispatch: TypedDispatch, getState: ReducerGetter): void => {
    const state = getState();
    const { pagination } = state.organisationOccupationOverviewReducer;

    if (pagination) {
        dispatch(setPagination({
            ...pagination,
            currentPage,
        }));
    }
};
