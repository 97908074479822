import { FC, ReactElement } from 'react';

import { ConnectedTroubleshootDetailsCard } from '../../../../connectors';

import './Account.scss';

interface AccountProps {
    className?: string;
}

const Account: FC<AccountProps> = ({
    className = '',
}): ReactElement => (
    <div className={`account-tab ${className}`}>
        <ConnectedTroubleshootDetailsCard className="account-tab__card" />
        {/* TODO: enable when our API can handle account deletion */}
        {/* <ConnectedDeleteAccountFormCard className="connected-settings__card" /> */}
    </div>
);

export default Account;
