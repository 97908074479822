import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormActionButtons, Notice } from '../../../compositions';
import { userHasRole } from '../../../helpers/role';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { CandidateExchangeFormData } from '../../../models/CandidateExchange/CandidateExchange';
import { OrganisationCandidate } from '../../../models/CandidateOverview/CandidateOverview';
import { CounselorOption, defaultCounselorOption } from '../../../models/Counselor/Counselor';
import { Organisation } from '../../../models/Organisation/Organisation';
import { DialogFormProps, NoticeType, UserRole } from '../../../types';
import { CounselorStep, OrganisationStep } from './subcomponents';
import { CandidateExchangeFormErrors, validateCandidateExchangeFormData } from './validations';

import './CandidateExchangeForm.scss';

interface CandidateExchangeFormProps extends DialogFormProps<CandidateExchangeFormData> {
    organisationOptionsIsLoading: boolean;
    isLloAdmin: boolean;
    userRoles?: UserRole[];
    candidate?: OrganisationCandidate;
    organisationOptionsError: string;
    counselorOptions: CounselorOption[];
    organisationOptions: Organisation[];
    counselorSearchQuery: string;
    organisationSearchQuery: string;
    onSearchCounselorOptions: (query: string) => void;
    onSearchOrganisationOptions: (query: string) => void;
    onOrganisationSelect: (organisation: Organisation) => void;
    className?: string;
}

const CandidateExchangeForm: FC<CandidateExchangeFormProps> = ({
    isLoading = false,
    organisationOptionsIsLoading,
    isLloAdmin,
    error = '',
    userRoles,
    candidate,
    organisationOptionsError,
    counselorOptions,
    organisationOptions,
    counselorSearchQuery,
    organisationSearchQuery,
    onSearchCounselorOptions,
    onSearchOrganisationOptions,
    onOrganisationSelect,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<CandidateExchangeFormErrors>({});

    const [newOrganisation, setNewOrganisation] = useState<Organisation | undefined>();
    const [newCounselor, setNewCounselor] = useState<CounselorOption>(defaultCounselorOption());

    const translationRole = userHasRole(UserRole.lloAdmin, userRoles) ? UserRole.lloAdmin : UserRole.counselor;

    const formRef = useRef<HTMLFormElement>(null);

    const handleOrganisationSelect = (organisation: Organisation): void => {
        setNewOrganisation(organisation);
        onOrganisationSelect(organisation);
        onSearchCounselorOptions('');
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!candidate) return;

        const formData = {
            candidateId: candidate.id,
            organisationId: newOrganisation?.id || '',
            counselorId: newCounselor.id,
        };

        const [errors, hasErrors] = validateCandidateExchangeFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`candidate-exchange-form ${className}`}>
            <p className="candidate-exchange-form__description">
                {trans(`forms.candidateExchange.description.${translationRole}`, {
                    fullName: candidate?.fullName || '',
                })}
            </p>

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="candidate-exchange-form__error-message"
                />
            )}

            {organisationOptionsError && (
                <Notice
                    type={NoticeType.error}
                    text={organisationOptionsError}
                    className="candidate-exchange-form__error-message"
                />
            )}

            {isLloAdmin && (
                <OrganisationStep
                    isLoading={organisationOptionsIsLoading}
                    error={organisationOptionsError}
                    formErrors={formErrors}
                    newOrganisation={newOrganisation}
                    options={organisationOptions}
                    searchQuery={organisationSearchQuery}
                    onSearch={onSearchOrganisationOptions}
                    onOrganisationSelect={handleOrganisationSelect}
                    className="candidate-exchange-form__step"
                />
            )}

            {(newOrganisation || !isLloAdmin) && (
                <CounselorStep
                    isLoading={isLoading}
                    error={error}
                    formErrors={formErrors}
                    newCounselor={newCounselor}
                    options={counselorOptions}
                    searchQuery={counselorSearchQuery}
                    onSearch={onSearchCounselorOptions}
                    onCounselorSelect={setNewCounselor}
                    className="candidate-exchange-form__step"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                submitIcon="arrow-right"
                submitText={trans('forms.candidateExchange.submitButtonLabel')}
                onCancelClick={onCancel}
                className="candidate-exchange-form__action-buttons"
            />
        </form>
    );
};

export default CandidateExchangeForm;
