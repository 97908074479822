import { FC, ReactElement } from 'react';

import { Incentive as IncentiveModel } from '../../../../../models/SkillsPassport/SkillsPassport';
import { IncentiveSlider } from '..';

import './Incentive.scss';

interface IncentiveProps {
    incentive: IncentiveModel;
    className?: string;
}

const Incentive: FC<IncentiveProps> = ({
    incentive,
    className = '',
}): ReactElement => (
    <div className={`incentive ${className}`}>
        <div className="incentive__context">
            <h4 className="incentive__name">{incentive.name}</h4>
            <p className="incentive__description">{incentive.description}</p>
        </div>

        <IncentiveSlider
            incentive={incentive}
            className="incentive__slider"
        />
    </div>
);

export default Incentive;
