import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { JobExperience, JobExperienceRequest } from './JobExperience';
import { transformToJobExperience } from './JobExperienceTransformers';

export const postJobExperienceApiCall = async (jobExperienceRequest: JobExperienceRequest, candidateUuid: string): Promise<FetchResult<JobExperience, string>> => {
    try {
        const response = await lloFetch(`/api/workingExperience/${candidateUuid}`, {
            method: 'POST',
            candidateUuid,
            body: JSON.stringify(jobExperienceRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const jobExperienceResponse = await response.json();
        const transformedJobExperience = transformToJobExperience(jobExperienceResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: transformedJobExperience,
        };
    } catch (error) {
        console.error('[postJobExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchJobExperienceApiCall = async (jobExperienceRequest: JobExperienceRequest, candidateUuid: string): Promise<FetchResult<JobExperience, string>> => {
    try {
        const response = await lloFetch(`/api/workingExperience/${jobExperienceRequest.id}`, {
            method: 'PATCH',
            candidateUuid,
            body: JSON.stringify(jobExperienceRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const jobExperienceResponse = await response.json();
        const transformedJobExperience = transformToJobExperience(jobExperienceResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: transformedJobExperience,
        };
    } catch (error) {
        console.error('[patchJobExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteJobExperienceApiCall = async (jobExperienceId: string, candidateUuid: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/workingExperience/${jobExperienceId}`, {
            method: 'DELETE',
            candidateUuid,
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteJobExperienceApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
