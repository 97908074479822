import trans from '../../../../helpers/trans';

export const getEducationLevelDescription = (educationLevel: string, learningPath?: string): string => {
    if (learningPath) {
        return trans('containers.schoolExperiencesCard.educationLevelLeaningPath', {
            educationLevel,
            learningPath,
        });
    }

    return trans('containers.schoolExperiencesCard.educationLevel', {
        educationLevel,
    });
};
