import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedDevelopmentPlanBreadcrumbs, ConnectedDevelopmentPlanHeader, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { User } from '../../models/User/User';
import { getDevelopmentTitle } from '../Development/helpers';

import './DevelopmentPlan.scss';

const DevelopmentPlan: FC = (): ReactElement => {
    const [candidate, setCandidate] = useState<Candidate>();
    const [user, setUser] = useState<User>();

    const candidateFullName = candidate?.fullName;
    const pageTitle = getDevelopmentTitle(user?.roles, candidateFullName);

    const helmetTitle = candidateFullName
        ? trans('pages.developmentPlan.helmetTitle', { name: candidateFullName })
        : '';

    return (
        <ConnectedPage
            hasNavigation
            title={pageTitle}
            className="development-plan-page"
        >
            <Helmet>
                <title>{helmetTitle}</title>
            </Helmet>

            <ConnectedDevelopmentPlanBreadcrumbs />

            <ConnectedDevelopmentPlanHeader
                onCandidateResponse={setCandidate}
                onUserResponse={setUser}
                className="development-plan-page__header"
            />
        </ConnectedPage>
    );
};

export default DevelopmentPlan;
