import { FC, ReactElement } from 'react';

import { CircleProgress } from '../../../../components';
import { LinkIconButton } from '../../../../compositions';
import trans from '../../../../helpers/trans';

import './DefaultAction.scss';

interface DefaultActionProps {
    description: string;
    to: string;
    duration: number;
    percentage?: number;
    className?: string;
}

const DefaultAction: FC<DefaultActionProps> = ({
    description,
    to,
    duration,
    percentage,
    className = '',
}): ReactElement => (
    <div className={`default-action ${className}`}>
        <div className="default-action__content">
            <p className="default-action__description">{description}</p>

            <div className="default-action__actions">
                <LinkIconButton
                    icon="arrow-right"
                    iconPos="right"
                    text={trans('containers.myActions.defaultAction.goToStep')}
                    to={to}
                    className="default-action__action-button"
                />

                <p className="default-action__duration">
                    {trans('containers.myActions.defaultAction.duration', {
                        duration,
                    })}
                </p>
            </div>
        </div>

        {percentage && (
            <div className="default-action__progress-wrapper">
                <CircleProgress
                    showPercentage
                    icon={percentage === 100 ? 'emotion-satisfied' : 'emotion-happy'}
                    totalSteps={100}
                    finishedSteps={percentage}
                    positiveThreshold={1}
                    className="default-action__percentage"
                />
                <p>{trans('containers.myActions.defaultAction.skillsPassport')}</p>
            </div>
        )}
    </div>
);

export default DefaultAction;
