import { FC, ReactElement } from 'react';

import { CandidateIllustration, IconButton } from '../../../../compositions';
import { CandidateIllustrationNotification } from '../../../../compositions/CandidateIllustration/CandidateIllustration';
import trans from '../../../../helpers/trans';
import { Gender, UserRole } from '../../../../types';

import './IntroductionAction.scss';

interface IntroductionActionProps {
    isCandidate: boolean;
    gender?: Gender;
    onFinish: () => void;
    className?: string;
}

const IntroductionAction: FC<IntroductionActionProps> = ({
    isCandidate,
    gender = Gender.female,
    onFinish,
    className = '',
}): ReactElement => {
    const descriptionRole = isCandidate ? UserRole.candidate : UserRole.counselor;

    return (
        <div className={`introduction-action ${className}`}>
            <div className="introduction-action__items">
                <div className="introduction-action__item">
                    <CandidateIllustration
                        type={CandidateIllustrationNotification.skillsPassport}
                        gender={gender}
                    />
                    <h3 className="introduction-action__title">
                        {trans(`containers.myActions.introductionAction.steps.skillsPassport.title.${descriptionRole}`)}
                    </h3>
                    <p className="introduction-action__description">
                        {trans(`containers.myActions.introductionAction.steps.skillsPassport.description.${descriptionRole}`)}
                    </p>
                </div>

                <div className="introduction-action__item">
                    <CandidateIllustration
                        type={CandidateIllustrationNotification.education}
                        gender={gender}
                    />
                    <h3 className="introduction-action__title">
                        {trans(`containers.myActions.introductionAction.steps.development.title.${descriptionRole}`)}
                    </h3>
                    <p className="introduction-action__description">
                        {trans(`containers.myActions.introductionAction.steps.development.description.${descriptionRole}`)}
                    </p>
                </div>

                <div className="introduction-action__item">
                    <CandidateIllustration
                        type={CandidateIllustrationNotification.matching}
                        gender={gender}
                    />
                    <h3 className="introduction-action__title">
                        {trans(`containers.myActions.introductionAction.steps.job.title.${descriptionRole}`)}
                    </h3>
                    <p className="introduction-action__description">
                        {trans(`containers.myActions.introductionAction.steps.job.description.${descriptionRole}`)}
                    </p>
                </div>
            </div>

            <IconButton
                icon="arrow-right"
                iconPos="right"
                text={trans('containers.myActions.introductionAction.nextStep')}
                onClick={onFinish}
                className="introduction-action__action-button"
            />
        </div>
    );
};

export default IntroductionAction;
