import { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';

import { Dialog, DialogContent, IconButton } from '../../../../../compositions';
import { closeDialog, openDialog } from '../../../../../helpers/dialog';
import trans from '../../../../../helpers/trans';
import { Competency } from '../../../../../models/Competencies/Competencies';

import './CompetencyTag.scss';

interface CompetencyTagProps {
    competency: Competency;
    className?: string;
}

const CompetencyTag: FC<CompetencyTagProps> = ({
    competency,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => closeDialog(dialogRef);

    const tagButtonClassNames = classNames('competency-tag__button', `competency-tag__button--is-${competency.level}-level`, className);

    return (
        <li className={`competency-tag ${className}`}>
            <IconButton
                icon="info"
                iconPos="right"
                text={competency.label}
                onClick={handleOpenDialog}
                className={tagButtonClassNames}
            />

            <Dialog
                ref={dialogRef}
                enableBackdropClose
                onClose={handleCloseDialog}
            >
                <DialogContent
                    title={competency.label}
                    description={competency.description}
                >
                    <h3 className="competency-tag__tips-title">
                        {trans('containers.competenciesCard.tipsDialog.developmentTips')}
                    </h3>

                    {competency.developmentTips.map(tip => (
                        <p key={tip} className="competency-tag__dialog-tip">{tip}</p>
                    ))}

                    <IconButton
                        icon="chevron-left"
                        text={trans('containers.competenciesCard.tipsDialog.closeButton')}
                        onClick={handleCloseDialog}
                        className="competency-tag__close-dialog-button"
                    />
                </DialogContent>
            </Dialog>
        </li>
    );
};

export default CompetencyTag;
