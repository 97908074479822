import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel } from '../../../components';
import { FormOption } from '../../../types';
import { DescriptiveRadioItem } from './subcomponents';

import './DescriptiveRadioList.scss';

interface DescriptiveFormOption extends FormOption {
    description?: string;
}

interface DescriptiveRadioListProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    label: string;
    hideLabel?: boolean;
    options: DescriptiveFormOption[];
    error?: string;
    onChange: (value: string) => void;
    className?: string;
}

const DescriptiveRadioList: FC<DescriptiveRadioListProps> = ({
    label,
    hideLabel = false,
    options,
    value,
    required = false,
    error = '',
    className = '',
    ...descriptiveRadioItemProps
}): ReactElement => {
    const descriptiveRadioListClassNames = classNames('descriptive-radio-list__options-wrapper', {
        'descriptive-radio-list__options-wrapper--has-error': !!error,
    });

    return (
        <div className={`descriptive-radio-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="descriptive-radio-list__label"
                />
            )}

            <div className={descriptiveRadioListClassNames}>
                {options.map(option => (
                    <DescriptiveRadioItem
                        {...descriptiveRadioItemProps}
                        key={option.value}
                        label={option.label}
                        description={option.description}
                        value={option.value}
                        checked={value === option.value}
                        className="descriptive-radio-list__option"
                    />
                ))}
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="descriptive-radio-list__error-label"
                />
            )}
        </div>
    );
};

export default DescriptiveRadioList;
