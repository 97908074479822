import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { addMonths } from 'date-fns';

import {
    CheckboxList,
    FormActionButtons,
    Notice,
    TextInput,
} from '../../../compositions';
import { formatDate } from '../../../helpers/date';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { OccupationSkill } from '../../../models/OccupationDetail/OccupationDetail';
import { transformOccupationSkillToFormOptions } from '../../../models/OccupationDetail/OccupationDetailTransformers';
import { NoticeType } from '../../../types';
import { DevelopmentPlanWithSkillsFormErrors, validateDevelopmentPlanWithSkillsFormData } from './validations';

import './DevelopmentPlanWithSkillsForm.scss';

export interface DevelopmentPlanWithSkillsFormData {
    title: string;
    start: string;
    end: string;
    skills: OccupationSkill[];
}

interface DevelopmentPlanWithSkillsFormProps {
    isLoading: boolean;
    error: string;
    essentialSkillsOptions: OccupationSkill[];
    optionalSkillsOptions: OccupationSkill[];
    onSubmit: (formData: DevelopmentPlanWithSkillsFormData) => void;
    onCancel: () => void;
    className?: string;
}

const DevelopmentPlanWithSkillsForm: FC<DevelopmentPlanWithSkillsFormProps> = ({
    isLoading,
    error,
    essentialSkillsOptions,
    optionalSkillsOptions,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<DevelopmentPlanWithSkillsFormErrors>({});

    const [title, setTitle] = useState<string>('');
    const [startDate, setStartDate] = useState<string>(formatDate(new Date(), 'y-MM-dd'));
    const [endDate, setEndDate] = useState<string>(formatDate(addMonths(new Date(), 3), 'y-MM-dd'));
    const [essentialSkills, setEssentialSkills] = useState<string[]>([]);
    const [optionalSkills, setOptionalSkills] = useState<string[]>([]);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const selectedEssentialSkills = essentialSkillsOptions.filter(skill => essentialSkills.includes(skill.id));
        const selectedOptionalSkills = optionalSkillsOptions.filter(skill => optionalSkills.includes(skill.id));

        const formData = {
            title,
            start: startDate,
            end: endDate,
            skills: [...selectedEssentialSkills, ...selectedOptionalSkills],
        };

        const [errors, hasErrors] = validateDevelopmentPlanWithSkillsFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`development-plan-with-skills-form ${className}`}>
            <div className="development-plan-with-skills-form__row">
                <TextInput
                    label={trans('forms.developmentPlanWithSkills.input.title')}
                    required
                    value={title}
                    error={formErrors.title}
                    onChange={setTitle}
                    className="development-plan-with-skills-form__column"
                />
            </div>

            <div className="development-plan-with-skills-form__row">
                <TextInput
                    label={trans('forms.developmentPlanWithSkills.input.startDate')}
                    type="date"
                    required
                    value={startDate}
                    error={formErrors.start}
                    onChange={setStartDate}
                    className="development-plan-with-skills-form__column"
                />

                <TextInput
                    label={trans('forms.developmentPlanWithSkills.input.endDate')}
                    type="date"
                    required
                    value={endDate}
                    error={formErrors.end}
                    onChange={setEndDate}
                    className="development-plan-with-skills-form__column"
                />
            </div>

            <div className="development-plan-with-skills-form__row">
                <CheckboxList
                    label={trans('forms.developmentPlanWithSkills.input.essentialSkills')}
                    name="essential-skills"
                    options={essentialSkillsOptions.map(transformOccupationSkillToFormOptions)}
                    value={essentialSkills}
                    onChange={setEssentialSkills}
                    className="development-plan-with-skills-form__column development-plan-with-skills-form__checkbox-list"
                />
            </div>

            <div className="development-plan-with-skills-form__row">
                <CheckboxList
                    label={trans('forms.developmentPlanWithSkills.input.optionalSkills')}
                    name="optional-skills"
                    options={optionalSkillsOptions.map(transformOccupationSkillToFormOptions)}
                    value={optionalSkills}
                    onChange={setOptionalSkills}
                    className="development-plan-with-skills-form__column development-plan-with-skills-form__checkbox-list"
                />
            </div>

            {error && (
                <Notice
                    type={NoticeType.error}
                    text={error}
                    className="development-plan-form__error-message"
                />
            )}

            <FormActionButtons
                isLoading={isLoading}
                submitText={trans('common.create')}
                onCancelClick={onCancel}
                className="development-plan-with-skills-form__action-buttons"
            />
        </form>
    );
};

export default DevelopmentPlanWithSkillsForm;
