import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './DevelopmentPlanOverviewSkeletons.scss';

interface DevelopmentPlanOverviewSkeletonsProps {
    amount?: number;
    className?: string;
}

const DevelopmentPlanOverviewSkeletons: FC<DevelopmentPlanOverviewSkeletonsProps> = ({
    amount = 3,
    className = '',
}): ReactElement => {
    const propertySkeletons = generateIdArray(amount);

    return (
        <section className={`developmentplan-overview-skeletons ${className}`}>
            <ul className="developmentplan-overview-skeletons__item-list">
                {propertySkeletons.map(property => (
                    <li key={property} className="developmentplan-overview-skeletons__item">
                        <Skeleton className="developmentplan-overview-skeletons__item-title" />
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default DevelopmentPlanOverviewSkeletons;
