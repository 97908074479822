import { FC, ReactElement } from 'react';

import { maxGrossMonthlySalary } from '../../../constants';
import { convertNumberToCurrency } from '../../../helpers/number';
import { SingleRangeInput } from '../..';

interface GrossSalaryFilterProps {
    label: string;
    hideLabel?: boolean;
    value: number;
    tabIndex?: number;
    onChange: (value: number) => void;
    className?: string;
}

const GrossSalaryFilter: FC<GrossSalaryFilterProps> = ({
    label,
    hideLabel,
    value,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`gross-salary-filter ${className}`}>
        <SingleRangeInput
            isInverted
            label={label}
            hideLabel={hideLabel}
            max={maxGrossMonthlySalary}
            step={100}
            value={value}
            marks={5}
            valueFormat={convertNumberToCurrency}
            tabIndex={tabIndex}
            onChange={onChange}
        />
    </div>
);

export default GrossSalaryFilter;
