/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconBin: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 36 100" className={`icon ${className}`}>
        <circle cx="18" cy="86" r="14" />
        <polygon points="36,0 0,0 4.8,59.7 31.2,59.7" />
    </svg>
);

export default IconBin;
/* eslint-enable max-len */
