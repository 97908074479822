import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon, LinkButton } from '../../../components';
import { LinkButtonProps } from '../../../components/@buttons/LinkButton/LinkButton';
import { IconName } from '../../../components/Icon/Icon';

import './LinkIconButton.scss';

interface LinkIconButtonProps extends LinkButtonProps {
    icon: IconName;
    iconPos?: 'left' | 'right';
    hideLabel?: boolean;
}

const LinkIconButton: FC<LinkIconButtonProps> = ({
    icon,
    iconPos = 'left',
    text,
    tabIndex,
    hideLabel,
    disabled,
    className = '',
    ...iconButtonProps
}): ReactElement => {
    const linkIconButtonClassNames = classNames('link-icon-button', {
        [`link-icon-button--align-${iconPos}`]: iconPos,
        'link-icon-button--is-disabled': disabled,
        'link-icon-button--hidden-label': hideLabel,
    }, className);

    return (
        <LinkButton
            {...iconButtonProps}
            text={text}
            tabIndex={disabled ? -1 : tabIndex}
            className={linkIconButtonClassNames}
        >
            <Icon name={icon} className="link-icon-button__icon" />
            {!hideLabel && text}
        </LinkButton>
    );
};

export default LinkIconButton;
