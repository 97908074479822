import { capitalizeFirstCharacter } from '../../helpers/string';
import { Occupation } from '../Occupations/Occupations';
import { InternalOccupationResource } from './InternalOccupations';

export const transformToInternalOccupation = (
    internalOccupationResource: InternalOccupationResource,
): Occupation => ({
    id: internalOccupationResource.id,
    name: capitalizeFirstCharacter(internalOccupationResource.title),
    description: internalOccupationResource.description,
    totalSkills: internalOccupationResource.amountOfSkills,
    matchedSkills: internalOccupationResource.amountOfMatchedSkills,
    matchedPercentage: internalOccupationResource.totalMatchedPercentage,
});
