import { FC, ReactElement, useMemo } from 'react';

import { Logo } from '../../../../components';
import trans from '../../../../helpers/trans';
import { UserRole } from '../../../../types';

import './EnvironmentLogo.scss';

interface EnvironmentLogoProps {
    userRoles: UserRole[];
    className?: string;
}

const EnvironmentLogo: FC<EnvironmentLogoProps> = ({
    userRoles,
    className = '',
}): ReactElement => {
    const activeEnvironment = process.env.REACT_APP_APP_ENV;

    const environmentStringWithRole = (environment: string): string => (
        trans(`containers.menu.environmentLogo.${environment}`, {
            userRoles: userRoles.join(', '),
        })
    );

    const environmentLabel = useMemo<string>(() => {
        switch (activeEnvironment) {
            case 'local':
                return environmentStringWithRole('local');
            case 'development':
                return environmentStringWithRole('staging');
            case 'acceptance':
                return environmentStringWithRole('acceptance');
            case 'production':
            default:
                return '';
        }
    }, [activeEnvironment, userRoles]);

    return (
        <div className={`environment-logo ${className}`}>
            <Logo name="llo" className="environment-logo__logo" />

            {environmentLabel && (
                <span className="environment-logo__underline">
                    {environmentLabel}
                </span>
            )}
        </div>
    );
};

export default EnvironmentLogo;
