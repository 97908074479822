/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconLocale: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M50,100C22.4,100,0,77.6,0,50C0,22.4,22.4,0,50,0c27.6,0,50,22.4,50,50C100,77.6,77.6,100,50,100z M50,6.3C25.9,6.3,6.3,25.9,6.3,50c0,24.1,19.6,43.7,43.7,43.7c24.1,0,43.7-19.6,43.7-43.7C93.7,25.9,74.1,6.3,50,6.3z" />
        <path d="M50,100c-14.9,0-26.6-22-26.6-50S35.1,0,50,0c14.9,0,26.6,22,26.6,50S64.9,100,50,100z M50,6.3c-11,0-20.3,20-20.3,43.7c0,23.7,9.3,43.7,20.3,43.7c11,0,20.3-20,20.3-43.7C70.3,26.3,61,6.3,50,6.3z" />
        <rect x="3.2" y="46.8" width="93.7" height="6.3" />
        <rect x="46.8" y="3.2" width="6.3" height="93.7" />
    </svg>
);

export default IconLocale;
/* eslint-enable max-len */
