import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import trans from '../../../../../helpers/trans';

import './EducationOverviewNullState.scss';

interface EducationOverviewNullStateProps {
    onResetClick: () => void;
    className?: string;
}

const EducationOverviewNullState: FC<EducationOverviewNullStateProps> = ({
    onResetClick,
    className = '',
}): ReactElement => (
    <div className={`education-overview-null-state ${className}`}>
        <p className="education-overview-null-state__paragraph">
            {trans('containers.educationOverview.nullState.noResults')}
        </p>
        <p className="education-overview-null-state__paragraph">
            {trans('containers.educationOverview.nullState.adjustYourPreferences')}
        </p>

        <Button
            text={trans('containers.educationOverview.nullState.buttonLabel')}
            onClick={onResetClick}
            className="education-overview-null-state__button"
        />
    </div>
);

export default EducationOverviewNullState;
