import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useToggle } from 'react-use';

import {
    FormActionButtons,
    IconButton,
    SearchableValueInput,
    SkillSelectedOptions,
    SkillsSelector,
} from '../../../compositions';
import { scrollIntoView } from '../../../helpers/scroll';
import trans from '../../../helpers/trans';
import { EscoOccupation } from '../../../models/EscoOccupations/EscoOccupations';
import { transformEscoOccupationToSearchableValue } from '../../../models/EscoOccupations/EscoOccupationsTransformers';
import { Skill, SkillsFormData } from '../../../models/Skills/Skills';
import { DialogFormProps, SearchableOption } from '../../../types';
import { SkillsFormErrors, validateSkillsFormData } from './validations';

import './SkillsForm.scss';

interface SkillsFormProps extends DialogFormProps<SkillsFormData> {
    suggestionIsLoading?: boolean;
    activeSkills: Skill[];
    suggestedOccupationSkills?: Skill[];
    skillOptions: Skill[];
    occupationOptions: EscoOccupation[];
    skillsTitle: string;
    skillsDescription: string;
    occupationTitle: string;
    occupationDescription: string;
    onSelectOccupation: (occupation: EscoOccupation) => void;
    className?: string;
}

const SkillsForm: FC<SkillsFormProps> = ({
    isLoading,
    suggestionIsLoading,
    activeSkills,
    suggestedOccupationSkills = [],
    skillOptions,
    occupationOptions,
    error,
    skillsTitle,
    skillsDescription,
    occupationTitle,
    occupationDescription,
    onSelectOccupation,
    onSubmit,
    onCancel,
    className = '',
}): ReactElement => {
    const [formErrors, setFormErrors] = useState<SkillsFormErrors>({});

    const [skills, setSkills] = useState<Skill[]>([]);
    const [occupation, setOccupation] = useState<string>('');
    const [suggestedSkills, setSuggestedSkills] = useState<Skill[]>([]);

    const [showOccupationView, toggleShowOccupationView] = useToggle(false);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect((): void => {
        if (activeSkills.length > 0) setSkills(activeSkills);
    }, [activeSkills]);

    useEffect((): void => {
        setSuggestedSkills(suggestedOccupationSkills.filter(suggestedSkill => !skills.some(skill => skill.id === suggestedSkill.id)));
    }, [suggestedOccupationSkills]);

    const handleChangeOccupation = (selectedOption: SearchableOption): void => {
        if (!selectedOption.value) {
            setSuggestedSkills([]);
        }

        setOccupation(selectedOption.label);

        const selectedOccupation = selectedOption.value
            ? occupationOptions.find(option => option.id === selectedOption.value)
            : undefined;

        if (selectedOccupation) {
            onSelectOccupation(selectedOccupation);
        }
    };

    const handleSuggestedSkillClick = (selectedSkill: Skill): void => {
        setSkills([...skills, selectedSkill]);
        setSuggestedSkills(suggestedSkills.filter(skill => selectedSkill.id !== skill.id));
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = { skills };
        const [errors, hasErrors] = validateSkillsFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={`skills-form ${className}`}>
            <div className="skills-form__input-wrapper">
                <h2 className="skills-form__input-title">
                    {skillsTitle}
                </h2>

                <p className="skills-form__input-description">
                    {skillsDescription}
                </p>

                <SkillsSelector
                    isLoading={suggestionIsLoading}
                    value={skills}
                    options={skillOptions}
                    error={error}
                    validationError={formErrors.skills}
                    onChange={setSkills}
                    className="skills-form__input"
                />
            </div>

            <div className="skills-form__input-wrapper">
                <IconButton
                    icon={showOccupationView ? 'chevron-up' : 'chevron-down'}
                    iconPos="right"
                    text={occupationTitle}
                    onClick={toggleShowOccupationView}
                    className="skills-form__collapse-button"
                />

                {showOccupationView && (
                    <>
                        <p className="skills-form__input-description">
                            {occupationDescription}
                        </p>

                        <SearchableValueInput
                            isSearchable
                            label={trans('forms.skills.input.occupation')}
                            value={occupation}
                            options={occupationOptions.map(transformEscoOccupationToSearchableValue)}
                            disabled={isLoading}
                            onChange={handleChangeOccupation}
                            className="skills-form__input"
                        />

                        {suggestedSkills.length > 0 && (
                            <SkillSelectedOptions
                                title={trans('forms.skills.title.suggestedSkills')}
                                selectedOptions={suggestedSkills}
                                onSelectOption={handleSuggestedSkillClick}
                                className="skills-form__suggested-skills"
                            />
                        )}
                    </>
                )}
            </div>

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancel}
                className="skills-form__action-buttons"
            />
        </form>
    );
};

export default SkillsForm;
