import { FC, ReactElement } from 'react';

import { FormOption } from '../../../types';
import { CheckboxList } from '../..';

interface WorkRemoteFilterProps {
    label: string;
    hideLabel?: boolean;
    value: string[];
    workRemoteOptions: FormOption[];
    tabIndex?: number;
    onChange: (value: string[]) => void;
    className?: string;
}

const WorkRemoteFilter: FC<WorkRemoteFilterProps> = ({
    label,
    hideLabel,
    value,
    workRemoteOptions,
    tabIndex,
    onChange,
    className = '',
}): ReactElement => {
    const noneOption = 'working-from-irrelevant';
    const options = workRemoteOptions.filter(option => option.value !== noneOption);

    return (
        <div className={`work-remote-filter ${className}`}>
            <CheckboxList
                label={label}
                hideLabel={hideLabel}
                name="work-remote"
                options={options}
                value={value}
                tabIndex={tabIndex}
                onChange={onChange}
            />
        </div>
    );
};

export default WorkRemoteFilter;
