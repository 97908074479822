import { FC, ReactElement } from 'react';

import { CompetenciesCard } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedCompetenciesCardProps {
    onRefresh: () => void;
    className?: string;
}

const ConnectedCompetenciesCard: FC<ConnectedCompetenciesCardProps> = ({
    onRefresh,
    className = '',
}): ReactElement => {
    const user = useTypedSelector(state => state.userReducer.user);
    const candidate = useTypedSelector(state => state.candidateReducer.candidate);

    const skillsPassportIsLoading = useTypedSelector(state => state.skillsPassportReducer.isLoading);
    const competencies = useTypedSelector(state => state.skillsPassportReducer.competencies);
    const isUpToDate = useTypedSelector(state => state.skillsPassportReducer.isUpToDate);

    return (
        <CompetenciesCard
            isLoading={skillsPassportIsLoading}
            isUpToDate={isUpToDate}
            candidate={candidate}
            competencies={competencies}
            userRoles={user?.roles}
            handleRefresh={onRefresh}
            className={className}
        />
    );
};

export default ConnectedCompetenciesCard;
