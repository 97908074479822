import { OrganisationFormData } from '../../../../models/Organisation/Organisation';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../services/ValidationService';

export type OrganisationFormErrors = FormErrors<OrganisationFormData>;

export const validateOrganisationFormData = (formData: OrganisationFormData): FormValidation<OrganisationFormErrors> => {
    const errors: OrganisationFormErrors = {
        name: validateRequiredString('name', formData.name),
        location: validateRequiredString('location', formData.location),
    };

    return validateForm<OrganisationFormErrors>(errors);
};
