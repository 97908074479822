/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconPlus: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <path d="M44 12.5L56 12.5L56 87.5L44 87.5L44 12.5Z" />
        <path d="M87.5 44L87.5 56L12.5 56L12.5 44L87.5 44Z" />
    </svg>
);

export default IconPlus;
/* eslint-enable max-len */
