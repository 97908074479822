import { talentzFetch } from '../../helpers/talentzFetch';
import trans from '../../helpers/trans';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Address, AddressResource, defaultAddress } from './Address';
import { transformToAddress } from './AddressTransformers';

export const getAddressApiCall = async (postalCode: string, houseNumber: string): Promise<FetchResult<Address, string>> => {
    try {
        const response = await talentzFetch(`/api/pro6pp/postal-code/${postalCode}/${houseNumber}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownAddress'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<AddressResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const transformedAddress = doc.data
            ? transformToAddress(doc.data)
            : defaultAddress();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: transformedAddress,
        };
    } catch (error) {
        console.error('[getAddressApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
