import { DevelopmentPlanCompetencyEvaluationFormData } from '../../models/DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluation';
import {
    transformAddDevelopmentPlanCompetencyEvaluationRequest,
    transformEditDevelopmentPlanCompetencyEvaluationRequest,
} from '../../models/DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluationTransformer';
import { DevelopmentPlanEvaluation, DevelopmentPlanEvaluationRequest } from '../../models/DevelopmentPlanEvaluation/DevelopmentPlanEvaluation';
import {
    deleteDevelopmentPlanEvaluationApiCall,
    editDevelopmentPlanEvaluationApiCall,
    getDevelopmentPlanEvaluationApiCall,
    postDevelopmentPlanEvaluationApiCall,
} from '../../models/DevelopmentPlanEvaluation/DevelopmentPlanEvaluationService';
import { DevelopmentPlanSkillEvaluationFormData } from '../../models/DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluation';
import {
    transformAddDevelopmentPlanSkillEvaluationRequest,
    transformEditDevelopmentPlanSkillEvaluationRequest,
} from '../../models/DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluationTransformer';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { setError } from '../app/app';
import { TypedDispatch } from '../store';
import { setDevelopmentPlanEvaluation, setIsLoading, setIsSuccessful } from './developmentPlanEvaluation';

export const fetchDevelopmentPlanEvaluation = (developmentPlanEvaluationUuid: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const developmentPlanEvaluationResponse = await getDevelopmentPlanEvaluationApiCall(developmentPlanEvaluationUuid, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanEvaluationResponse)) {
            dispatch(setError(developmentPlanEvaluationResponse.error));
            return;
        }

        dispatch(setDevelopmentPlanEvaluation(developmentPlanEvaluationResponse.data));
    } catch (error) {
        console.error('[fetchDevelopmentPlanEvaluation]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addDevelopmentPlanEvaluation = (developmentPlanUuid: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentPlanEvaluationResponse = await postDevelopmentPlanEvaluationApiCall(developmentPlanUuid, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanEvaluationResponse)) {
            dispatch(setError(developmentPlanEvaluationResponse.error));
            return;
        }

        dispatch(setDevelopmentPlanEvaluation(developmentPlanEvaluationResponse.data));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addDevelopmentPlanEvaluation]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const editDevelopmentPlanEvaluation = (
    developmentPlanEvaluation: DevelopmentPlanEvaluation,
    competencyEvaluations: DevelopmentPlanCompetencyEvaluationFormData[],
    skillEvaluations: DevelopmentPlanSkillEvaluationFormData[],
    candidateUuid: string,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const competencies = competencyEvaluations.map(evaluation => (evaluation.id
            ? transformEditDevelopmentPlanCompetencyEvaluationRequest(evaluation)
            : transformAddDevelopmentPlanCompetencyEvaluationRequest(evaluation)));

        const skills = skillEvaluations.map(evaluation => (evaluation.id
            ? transformEditDevelopmentPlanSkillEvaluationRequest(evaluation)
            : transformAddDevelopmentPlanSkillEvaluationRequest(evaluation)));

        const evaluationRequest: DevelopmentPlanEvaluationRequest = {
            ...developmentPlanEvaluation,
            competencyEvaluations: competencies,
            skillEvaluations: skills,
        };

        const evaluationResponse = await editDevelopmentPlanEvaluationApiCall(evaluationRequest, developmentPlanEvaluation.id, candidateUuid);

        if (!isFetchResultSuccessful(evaluationResponse)) {
            dispatch(setError(evaluationResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[editDevelopmentPlanEvaluation]');
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const deleteDevelopmentPlanEvaluation = (developmentPlanEvaluationUuid: string, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const developmentPlanEvaluationResponse = await deleteDevelopmentPlanEvaluationApiCall(developmentPlanEvaluationUuid, candidateUuid);

        if (!isFetchResultSuccessful(developmentPlanEvaluationResponse)) {
            dispatch(setError(developmentPlanEvaluationResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[deleteDevelopmentPlanEvaluation]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setIsSuccessful(false));
    }
};
