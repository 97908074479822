import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { EscoOccupation } from '../../models/EscoOccupations/EscoOccupations';
import { Skill } from '../../models/Skills/Skills';
import { AsyncReduxState } from '../../types';

export type EscoOccupationsState = AsyncReduxState<{
    escoOccupations: EscoOccupation[];
    suggestedSkills: Skill[];
}>;

const initialState: EscoOccupationsState = {
    ...initialAsyncReduxState,
    escoOccupations: [],
    suggestedSkills: [],
};

export const escoOccupationsSlice = createSlice({
    name: 'escoOccupationsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): EscoOccupationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): EscoOccupationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setEscoOccupations(state, action: PayloadAction<EscoOccupation[]>): EscoOccupationsState {
            return {
                ...state,
                escoOccupations: action.payload,
            };
        },
        setSuggestedSkills(state, action: PayloadAction<Skill[]>): EscoOccupationsState {
            return {
                ...state,
                suggestedSkills: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setEscoOccupations,
    setSuggestedSkills,
} = escoOccupationsSlice.actions;

export default escoOccupationsSlice.reducer;
