import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { AmountOfEmployeesOption } from '../../models/AmountOfEmployees';
import { AsyncReduxState } from '../../types';

export type AmountOfEmployeesState = AsyncReduxState<{
    amountOfEmployeesOptions: AmountOfEmployeesOption[];
}>;

const initialState: AmountOfEmployeesState = {
    ...initialAsyncReduxState,
    amountOfEmployeesOptions: [],
};

export const amountOfEmployeesSlice = createSlice({
    name: 'amountOfEmployeesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AmountOfEmployeesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AmountOfEmployeesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setAmountOfEmployeesOptions(state, action: PayloadAction<AmountOfEmployeesOption[]>): AmountOfEmployeesState {
            return {
                ...state,
                amountOfEmployeesOptions: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setAmountOfEmployeesOptions,
} = amountOfEmployeesSlice.actions;

export default amountOfEmployeesSlice.reducer;
