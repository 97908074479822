import { FormOption } from '../../types';

export interface EducationFilterOptions {
    educationLevels: FormOption[];
    fieldsOfStudy: FormOption[];
    educators: FormOption[];
    startMoments: FormOption[];
    dayParts: FormOption[];
    subsidies: FormOption[];
    locations: FormOption[];
    durations: FormOption[];
}

export const defaultEducationFilterOptions = (): EducationFilterOptions => ({
    educationLevels: [],
    fieldsOfStudy: [],
    educators: [],
    startMoments: [],
    dayParts: [],
    subsidies: [],
    locations: [],
    durations: [],
});
