import {
    AddDevelopmentPlanCompetencyEvaluationRequest,
    DevelopmentPlanCompetencyEvaluation,
    DevelopmentPlanCompetencyEvaluationResource,
    EditDevelopmentPlanCompetencyEvaluationRequest,
} from '../DevelopmentPlanCompetencyEvaluation/DevelopmentPlanCompetencyEvaluation';
import {
    AddDevelopmentPlanSkillEvaluationRequest,
    DevelopmentPlanSkillEvaluation,
    DevelopmentPlanSkillEvaluationResource,
    EditDevelopmentPlanSkillEvaluationRequest,
} from '../DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluation';

export interface DevelopmentPlanEvaluationResource {
    id: string;
    createdAt: string;
    updatedAt: string;
    createdByName: string;
    competencyEvaluations?: DevelopmentPlanCompetencyEvaluationResource[];
    skillEvaluations?: DevelopmentPlanSkillEvaluationResource[];
}

export interface DevelopmentPlanEvaluation {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    createdByName: string;
    competencyEvaluations: DevelopmentPlanCompetencyEvaluation[];
    skillEvaluations: DevelopmentPlanSkillEvaluation[];
}

export interface DevelopmentPlanEvaluationRequest {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    createdByName: string;
    competencyEvaluations: (EditDevelopmentPlanCompetencyEvaluationRequest | AddDevelopmentPlanCompetencyEvaluationRequest)[];
    skillEvaluations: (EditDevelopmentPlanSkillEvaluationRequest | AddDevelopmentPlanSkillEvaluationRequest)[];
}

export const defaultDevelopmentPlanEvaluation = (): DevelopmentPlanEvaluation => ({
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdByName: '',
    competencyEvaluations: [],
    skillEvaluations: [],
});
