import { FC, ReactElement } from 'react';

import { Button } from '../../../../../components';
import { userHasRole } from '../../../../../helpers/role';
import trans from '../../../../../helpers/trans';
import { UserRole } from '../../../../../types';

import './OccupationOverviewNoSkills.scss';

interface OccupationOverviewNoSkillsProps {
    userRoles?: UserRole[];
    nullState: string;
    callToAction: string;
    onButtonClick: () => void;
    className?: string;
}

const OccupationOverviewNoSkills: FC<OccupationOverviewNoSkillsProps> = ({
    userRoles = [],
    nullState,
    callToAction,
    onButtonClick,
    className = '',
}): ReactElement => (
    <div className={`occupation-overview-no-skills ${className}`}>
        <p className="occupation-overview-no-skills__paragraph">
            {nullState}
        </p>
        <p className="occupation-overview-no-skills__paragraph">
            {callToAction}
        </p>

        {userHasRole(UserRole.candidate, userRoles) && (
            <Button
                text={trans('containers.occupationOverview.noSkills.buttonLabel')}
                onClick={onButtonClick}
                className="occupation-overview-no-skills__button"
            />
        )}
    </div>
);

export default OccupationOverviewNoSkills;
