import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './OrganisationDetailTabsSkeletons.scss';

interface OrganisationDetailTabsSkeletonsProps {
    amountOfLabels: number;
    className?: string;
}

const OrganisationDetailTabsSkeletons: FC<OrganisationDetailTabsSkeletonsProps> = ({
    amountOfLabels,
    className = '',
}): ReactElement => {
    const labelSkeletons = generateIdArray(amountOfLabels);

    return (
        <div className={`organisation-detail-tabs-skeletons ${className}`}>
            <div className="organisation-detail-tabs-skeletons__label-wrapper">
                {labelSkeletons.map(label => (
                    <Skeleton key={label} className="organisation-detail-tabs-skeletons__label" />
                ))}
            </div>
        </div>
    );
};

export default OrganisationDetailTabsSkeletons;
