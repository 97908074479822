import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface WorkRemoteResource extends Resource {
    type: 'remote-working';
    id: string;
    attributes: {
        slug: string;
        name: string;
    };
}

export type WorkRemoteOption = FormOption;

export const transformResourceToWorkRemoteOption = (
    workRemoteResource: WorkRemoteResource,
): WorkRemoteOption => ({
    value: workRemoteResource.attributes.slug,
    label: workRemoteResource.attributes.name,
});
