/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Gender } from '../../types';

import './CandidateIllustration.scss';

export enum CandidateIllustrationNotification {
    regular = 'regular',
    tma = 'tma',
    talentz = 'talentz',
    skillsPassport = 'skills-passport',
    education = 'education',
    matching = 'matching',
    personalityDescription = 'personality-description',
    competency = 'competency',
    workplacePreferences = 'workplace-preferences',
}

interface CandidateIllustrationProps {
    type?: CandidateIllustrationNotification;
    gender?: Gender;
    className?: string;
}

const CandidateIllustration: FC<CandidateIllustrationProps> = ({
    type,
    gender = Gender.female,
    className = '',
}): ReactElement => {
    const isFemale = gender === Gender.female;

    const skillsPassportIconClassNames = classNames('candidate-illustration__skills-passport-icon', {
        'candidate-illustration__skills-passport-icon--is-active': type === CandidateIllustrationNotification.skillsPassport,
    });

    const educationIconClassNames = classNames('candidate-illustration__education-icon', {
        'candidate-illustration__education-icon--is-active': type === CandidateIllustrationNotification.education,
    });

    const matchingIconClassNames = classNames('candidate-illustration__matching-icon', {
        'candidate-illustration__matching-icon--is-active': type === CandidateIllustrationNotification.matching,
    });

    const personalityDescriptionIconClassNames = classNames('candidate-illustration__personality-description-icon', {
        'candidate-illustration__personality-description-icon--is-active': type === CandidateIllustrationNotification.personalityDescription,
    });

    const competencyIconClassNames = classNames('candidate-illustration__competency-icon', {
        'candidate-illustration__competency-icon--is-active': type === CandidateIllustrationNotification.competency,
    });

    const workplacePreferencesIconClassNames = classNames('candidate-illustration__workplace-preferences-icon', {
        'candidate-illustration__workplace-preferences-icon--is-active': type === CandidateIllustrationNotification.workplacePreferences,
    });

    const notificationBalloonClassNames = classNames('candidate-illustration__notification-balloon', {
        'candidate-illustration__notification-balloon--is-skills-passport': type === CandidateIllustrationNotification.skillsPassport,
        'candidate-illustration__notification-balloon--is-matching': type === CandidateIllustrationNotification.matching,
    });

    const regularNotificationClassNames = classNames('candidate-illustration__regular-notification', {
        'candidate-illustration__regular-notification--is-active': !!type,
    });

    const tmaNotificationClassNames = classNames('candidate-illustration__tma-notification', {
        'candidate-illustration__tma-notification--is-active': type === CandidateIllustrationNotification.tma,
    });

    const talentzNotificationClassNames = classNames('candidate-illustration__talentz-notification', {
        'candidate-illustration__talentz-notification--is-active': type === CandidateIllustrationNotification.talentz,
    });

    return (
        <div className={`candidate-illustration ${className}`}>
            <svg className="candidate-illustration__character" viewBox="0 0 1200 1000">
                <circle className="candidate-illustration__background" cx="599.8" cy="582.9" r="416.7" />

                {gender && (
                    <g>
                        {isFemale && (
                            <path className="candidate-illustration__hair" d="M611.2,17.6C694.8,2.2,779,79,797.8,180.1c4.4,23.9,7.5,77.9,17.3,110.4c19.1,63.7,123.9,145.8,137.5,219.4c25,135.2-155.8,178.6-215.5,189.6s-221.9-98.9-230.6-145.9c-8.7-47,12.8-184.5,8.1-210C509.9,318,523.3,33.8,611.2,17.6z" />
                        )}

                        <g className="candidate-illustration__body">
                            <path className="candidate-illustration__right-arm" d="M506.2,614.7c-4.1,20.3,0.4,165.5-5.6,188.4c-3.2,12.1-23.1,95.8-41.2,172.1c33.1,11.9,68.1,19.7,104.5,22.9c7.9-24,15.5-50.9,20.2-76.7C595.8,856.5,594.2,615.8,506.2,614.7z" />
                            <path className="candidate-illustration__right-sleeve" d="M602.2,464.7c-58.7-29.2-113,130.9-107.7,149.2c5.2,18.3,59.1,23.6,72.7,23.6S602.2,464.7,602.2,464.7z" />
                            <path className="candidate-illustration__shirt" d="M941.7,822c-14.6-159.2-66.1-449.2-227-449.2c-190,0-251.2,334-142.4,625.8c9.3,0.6,18.6,1,28.1,1C741.5,999.6,866.2,929.4,941.7,822z" />
                        </g>

                        <path className="candidate-illustration__neck" d="M720.8,279.3c-1,13,6.8,85.4,19.1,109c-5.4,10.7-61.2,41.9-87.9,35.3c-3.6-20.1-13.3-74-21.2-90.4C645.1,325.7,720.8,279.3,720.8,279.3z" />

                        <g className="candidate-illustration__neck-joint">
                            <path className="candidate-illustration__face" d="M652.2,342.2c64.9-17.3,100.1-97.8,78.5-179.7C709,80.6,639,28.3,574.1,45.6S474,143.4,495.6,225.3S587.2,359.5,652.2,342.2z" />
                            {isFemale ? (
                                <path className="candidate-illustration__hair" d="M575.2,26.3c-59.7,24.6-112.7,93.4-88.9,173.5c33.4-8,54.2-49.4,74-94.7c26.9,44.2,87.4,109,159.7,95.7C879.9,165.7,669.8-45.5,575.2,26.3z" />
                            ) : (
                                <path className="candidate-illustration__hair" d="M703,33.2c-16.2,0-24.7,6.5-31.4,9.9C657.3,25,591.1,0,530.4,0c-39.3,0-61.7,14.9-61.7,30.6c0,9.2,15.1,18.6,28.4,28.3c-56.8-19.4-89.4,24.9-89.4,39.3c0,10.9,22,25.6,45.7,26.1c-28.1,1.5-37.8,23.4-37.8,46c0,8.2,9.6,20.2,21,20.2c24,0,36.8-5.1,52.7-11.2c42.8-33.9,124.4-42.7,142.9-39.6c5.7,39.5,31.3,52.7,38.1,52.3c2.1-4.5,5.3-8.5,9.4-11.5c4.9-3.6,10.8-5.6,16.8-5.8c6.1-0.2,12,1.5,17.1,4.9c5.1,3.3,9,8.1,11.3,13.8c2.3,5.6,2.9,11.9,1.6,17.8c-0.3,1.3-0.7,2.6-1.1,3.8c5.1-0.9,9.9-2.8,14.2-5.6C757.1,165.1,743.5,33.2,703,33.2z" />
                            )}

                            <circle className="candidate-illustration__joint-handle" cx="671.5" cy="309.3" r="438.7" />
                        </g>

                        <g className="candidate-illustration__left-shoulder-joint">
                            <path className="candidate-illustration__left-upper-arm" d="M928,626.3c12,20.5,37.8,159.4,35.2,176.9c-7.1,43.8-42.8,54.9-57,55.9c-20.7-7.5-70.7-77.2-86.9-107.8c-10-18.9-29.6-72.2-30-80.3C789,662.8,928,626.3,928,626.3z" />
                            <path className="candidate-illustration__left-sleeve" d="M776.2,413.8c92.7-34.2,166.1,181.4,163.1,208.8c-3,27.3-151.5,75.2-168.2,65.3C754.4,678,684.3,447.7,776.2,413.8z" />

                            <g className="candidate-illustration__left-elbow-joint">
                                <path className="candidate-illustration__left-lower-arm" d="M908.4,750.8c-12,0-23,3.8-32.1,10.3c-18.6-4.5-38.3-8.8-57-12.8c-56.3-12-300-26.6-369.1-39.1c-8.8-1.6-51.9,30.3-4.6,61.4c40.9,22.8,259.4,103.8,460.9,90c0.1,0,0.2,0,0.4,0c0.5,0,1,0,1.4,0c30.4,0,55-24.6,55-55S938.8,750.8,908.4,750.8z" />

                                <g className="candidate-illustration__left-wrist-joint">
                                    <path className="candidate-illustration__phone" d="M404,678.8h-68.4l-39.1-149.4h68.4L404,678.8z" />
                                    <path className="candidate-illustration__left-hand" d="M451.1,709.1H451c-0.2,0-0.4-0.1-0.6-0.1s-0.3-0.1-0.5-0.1c-6.5-1-10.7-0.4-16.8-4.2c-21.1-13.3-59.9-67.9-64.7-67.9c-16.8,0,2.8,24.1,9.9,38.1c-4.9-8.9-30.9-33.7-45.7-33.7c-8.1,0-22.9,19.3-22.9,46.1c0,44.7,47.7,75.3,130.6,82.9c1.7,0.3,3.4,0.4,5.1,0.4c17,0,30.8-13.9,30.8-31.1C476.2,724.4,465.4,711.8,451.1,709.1z" />

                                    <g className="candidate-illustration__notification-joint">
                                        <g className={regularNotificationClassNames}>
                                            <path className={notificationBalloonClassNames} d="M383.9,419.1l-22.7-114.7c-2.5-12.7-14.8-20.9-27.4-18.4l-114,22.8c-12.6,2.5-20.8,14.8-18.3,27.5L224.3,451c2.5,12.7,14.8,20.9,27.4,18.4l35.4-7.1l29.1,33.3l14.2-42l35.4-7.1C378.2,444.1,386.4,431.8,383.9,419.1z" />

                                            <path className={skillsPassportIconClassNames} d="M328.6,349c1.4-2.1,0.8-5-1.3-6.3l-12.5-8.3c-2-1.4-4.9-0.8-6.3,1.3l-6.5,9.8l20,13.3L328.6,349z M259.2,410.3l3.4,16.7l16.7-3.4l39.2-59.1l-20-13.3L259.2,410.3z" />
                                            <path className={educationIconClassNames} d="M260.2,440.9l-13.8-68.2l19.5-3.9l13.8,68.2L260.2,440.9z M294.4,434l-19.7-97.4l19.5-3.9l19.7,97.4L294.4,434z M328.5,427.1l-7.9-39l19.5-3.9l7.9,39L328.5,427.1z" />
                                            <path className={matchingIconClassNames} d="M274.4,340.8c7.5-1.5,15.3,0,21.7,4.2s10.9,10.8,12.4,18.3c1.4,7,0.2,14-3,19.8l1.4,0.9l3.4-0.7l26.2,17.3l-5.2,7.9l-26.2-17.3l-0.7-3.4l-1.4-0.9c-4.1,5.2-10,9.1-17,10.6c-7.5,1.5-15.3,0-21.7-4.2s-10.9-10.8-12.4-18.3s0-15.3,4.2-21.7C260.2,346.8,266.9,342.3,274.4,340.8z M276.2,349.5c-10.9,2.2-17.8,12.7-15.6,23.6c2.2,10.9,12.7,17.8,23.6,15.6c10.9-2.2,17.8-12.7,15.6-23.6C297.5,354.2,287.1,347.3,276.2,349.5z" />
                                            <path className={personalityDescriptionIconClassNames} d="M270.3,402.2c-1.7-8.8,14.9-17,23.7-18.7c8.8-1.7,27.2-0.3,28.9,8.5l0.9,4.4l-52.6,10.2 M302.9,359.5c0.7,3.5-0.1,7.1-2,10c-2,2.9-5.1,5-8.5,5.7c-3.5,0.7-7.1-0.1-10-2c-2.9-2-5-5.1-5.7-8.5c-0.7-3.5,0.1-7.1,2-10c2-2.9,5.1-5,8.5-5.7c3.5-0.7,7.1,0.1,10,2C300.2,352.9,302.2,356,302.9,359.5z M246.9,352.2l11.9,61.4c0.5,2.3,1.8,4.4,3.8,5.7c2,1.3,4.4,1.8,6.7,1.4l61.4-11.9c2.3-0.5,4.4-1.8,5.7-3.8c1.3-2,1.8-4.4,1.4-6.7l-11.9-61.4c-0.5-2.3-1.8-4.4-3.8-5.7c-2-1.3-4.4-1.8-6.7-1.4L254,341.7C249.1,342.6,246,347.3,246.9,352.2z" />
                                            <path className={competencyIconClassNames} d="M315.3,329.8c2.3-0.5,4.7,0,6.7,1.4c2,1.3,3.3,3.4,3.8,5.7l11.9,61.4c0.9,4.9-2.2,9.5-7.1,10.5l-61.4,11.9c-2.3,0.5-4.7,0-6.7-1.4c-2-1.3-3.3-3.4-3.8-5.7l-11.9-61.4c-0.5-2.3,0-4.7,1.4-6.7c1.3-2,3.4-3.3,5.7-3.8L315.3,329.8zM312.3,394.1l-7.6-17.1l11.5-14.7l-18.6,2l-10.4-15.4l-3.8,18.2l-18,5.1l16.2,9.3l-0.7,18.7l13.9-12.5L312.3,394.1z" />
                                            <path className={workplacePreferencesIconClassNames} d="M269.3,420.6c-4.8,0.9-9.5-2.2-10.5-7.1l-7.8-40.2c-0.5-2.3,0-4.7,1.4-6.7l24.7-36.6c2.8-4.1,8.3-5.2,12.4-2.4l36.6,24.7c2,1.3,3.3,3.4,3.8,5.7l7.8,40.2c0.9,4.8-2.2,9.5-7.1,10.5L269.3,420.6z M284.5,335l-24.7,36.6l7.8,40.2l17.5-3.4l-5.1-26.3c-0.9-4.8,2.2-9.5,7.1-10.5l8.8-1.7c4.8-0.9,9.5,2.2,10.5,7.1l5.1,26.3l17.5-3.4l-7.8-40.2L284.5,335z M297.6,378.7l-8.8,1.7l5.1,26.3l8.8-1.7L297.6,378.7z" />
                                        </g>

                                        <g className={tmaNotificationClassNames}>
                                            <path className="candidate-illustration__tma-balloon" d="M142.8,285.4c-21.6,4.4-35.6,25.6-31.1,47.2l24.6,120c4.4,21.6,25.6,35.6,47.2,31.1l106-21.8l26,29.8l12.7-37.8l112.6-23.1c21.6-4.4,35.6-25.6,31.1-47.2l-24.6-120c-4.4-21.6-25.6-35.6-47.2-31.1L142.8,285.4z" />
                                            <g>
                                                <circle className="candidate-illustration__tma-circle-lime" cx="188.2" cy="325.2" r="5.2" />
                                                <circle className="candidate-illustration__tma-circle-red" cx="177.4" cy="358.3" r="6.1" />
                                                <circle className="candidate-illustration__tma-circle-yellow" cx="166.4" cy="391.6" r="9.5" />
                                                <circle className="candidate-illustration__tma-circle-purple" cx="200.8" cy="384.3" r="6.9" />
                                                <circle className="candidate-illustration__tma-circle-blue" cx="190.3" cy="417.5" r="7.6" />
                                                <circle className="candidate-illustration__tma-circle-green" cx="418.1" cy="307.2" r="8.7" />
                                                <path className="candidate-illustration__tma-text" d="M256.1,378c-1.1,0.2-1.8,1.2-1.5,2.3c0.4,4.8-0.8,8.2-5.5,9.2c-5.2,1.1-7.4-2.5-8.7-8.2l-3.9-18.5c-0.2-1.1,0.4-2.1,1.5-2.3l19.6-4.2c1.1-0.2,1.8-1.2,1.5-2.3l-2.1-9.9c-0.2-1.1-1.2-1.8-2.3-1.5l-19.6,4.2c-1.1,0.2-2.2-0.7-2.4-1.8l-3.7-17.4c-0.2-1.1-1.5-1.7-2.6-1.5l-11,2.3c-1.1,0.2-2,1.3-1.8,2.4l3.7,17.4c0.2,1.1-0.4,2.4-1.5,2.6l-10.5,2.2c-1.1,0.2-1.8,1.2-1.5,2.3l2.1,9.9c0.2,1.1,1.2,1.8,2.3,1.5l10.5-2.2c1.1-0.2,2.1,0.4,2.3,1.5l4.6,21.8c2.1,9.7,12.8,19.7,26.6,16.8C266,401.7,272,388,270,378.3l-0.3-1.4c-0.2-1.1-1.2-1.8-2.3-1.5L256.1,378z M349.3,343.2l7.7,36.1c0.2,1.1-0.7,2.2-1.8,2.4l-11,2.3c-1.1,0.2-2.4-0.4-2.6-1.5l-7.7-36.1c-0.4-1.7-3.2-6.8-9.2-5.5c-5.8,1.2-6.5,7.2-6.2,8.8l7.7,36.1c0.2,1.1-0.7,2.2-1.8,2.4l-11,2.3c-1.1,0.2-2.1-0.4-2.3-1.5l-7.7-36.1c-0.4-1.7-3.5-6.8-9.5-5.5c-5.8,1.2-6.5,7.2-6.2,8.8l7.7,36.1c0.2,1.1-0.4,2.1-1.5,2.3l-11.3,2.4c-1.1,0.2-2.1-0.4-2.3-1.5l-11.5-54c-0.2-1.1,0.4-2.1,1.5-2.3l6.6-1.4c1.9-0.4,3.4,2.4,5.6,2s4.6-5.6,11.7-7.1c7.4-1.6,13.2,2.7,14.6,2.4c3.9-0.8,5-6.5,14.3-8.5C335.7,323.9,346.8,331.7,349.3,343.2z M400.4,311l-6.6,1.4c-0.8,0.2-1.4,4.1-2.5,4.3c-2.2,0.5-4.2-3.7-16.1-1.2s-23.3,11.3-18,36.1s21.2,26.6,30.9,24.6c9.7-2.1,12.8-7.6,14.2-7.9c1.4-0.3,2.9,2.8,3.7,2.7l6.9-1.5c1.1-0.2,2-1.3,1.8-2.4l-11.5-54.3C402.8,311.7,401.5,310.8,400.4,311z M392.3,343.6c3.3,15.7-3.8,17.2-6.9,17.9c-2.5,0.5-9.9,2.1-13.3-13.6c-3.5-16.3,4-17.9,6.5-18.4C381.4,328.9,388.9,327.3,392.3,343.6z" />
                                            </g>
                                        </g>

                                        <g className={talentzNotificationClassNames}>
                                            <path className="candidate-illustration__talentz-balloon" d="M472,383.7l-24.6-120c-4.4-21.6-25.6-35.6-47.2-31.1l-257.4,52.8c-21.6,4.4-35.6,25.6-31.1,47.2l24.6,120c4.4,21.6,25.6,35.6,47.2,31.1l106-21.8l26,29.8l12.7-37.8l112.6-23.1C462.5,426.5,476.5,405.3,472,383.7z" />
                                            <g className="candidate-illustration__talentz-text">
                                                <polygon points="185,352 183.5,344.6 149.9,351.3 151.4,358.8 163.4,356.4 176.4,421.1 186.3,419.1 173.3,354.4" />
                                                <path d="M198.5,341.5l-0.6,75.2l9.3-1.9L207,396l14.5-2.9l6.9,17.5l9.4-1.9L208,339.6L198.5,341.5z M206.9,388.5 l-0.7-33.7l12.5,31.3L206.9,388.5z" />
                                                <polygon points="253.4,398.1 240.4,333.1 230.5,335.1 245,407.3 273,401.6 271.6,394.4" />
                                                <polygon points="288.3,391 283,364.6 297.3,361.7 295.8,354.6 281.5,357.5 276.7,333.6 294.4,330 292.8,322.6 265.3,328.1 279.8,400.3 307.5,394.7 306.1,387.5" />
                                                <polygon points="327.9,315.5 337.6,363.4 307.8,319.6 301,320.9 315.5,393.1 323.9,391.4 314,342.1 344.4,387.3 350.7,386 336.2,313.8" />
                                                <polygon points="378.2,313.2 376.7,305.7 343.1,312.4 344.6,319.9 356.6,317.5 369.7,382.2 379.6,380.2 366.6,315.5" />
                                                <polygon points="427.3,342 416.1,354.4 421.4,355.1 420.1,364.5 403.8,367.8 413.7,302.8 412.8,298.4 382.7,304.5 384.1,311.7 403.7,307.8 394.4,369.7 395.9,376.9 427.3,370.6 427.3,370.6 427.3,370.6 429.4,356.3 434.7,357" />
                                            </g>
                                        </g>

                                        <circle className="candidate-illustration__joint-handle" cx="323.6" cy="529.3" r="326.7" />
                                    </g>

                                    <circle className="candidate-illustration__joint-handle" cx="445.4" cy="739.6" r="580" />
                                </g>

                                <circle className="candidate-illustration__joint-handle" cx="908.4" cy="805.7" r="1062.6" />
                            </g>

                            <circle className="candidate-illustration__joint-handle" cx="794.8" cy="461.7" r="1436.5" />
                        </g>
                    </g>
                )}
            </svg>
        </div>
    );
};

export default CandidateIllustration;
/* eslint-enable max-len */
