import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Avatar, Tag } from '../../../components';
import { educationSearchUrl } from '../../../constants';
import trans from '../../../helpers/trans';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import { Education } from '../../../models/Educations/Educations';
import { FloatingCard, LinkIconButton } from '../..';
import { StapLabel } from './subcomponents';

import './EducationCard.scss';

interface EducationCardProps {
    isCompactView?: boolean;
    education: Education;
    className?: string;
}

const EducationCard: FC<EducationCardProps> = ({
    isCompactView,
    education,
    className = '',
}): ReactElement => {
    const { isMobile } = useDeviceWidth();

    const educationLink = `${educationSearchUrl}?q=${encodeURIComponent(education.title)}`;

    const informationWrapperClassNames = classNames('education-card__information-wrapper', {
        'education-card__information-wrapper--is-compact': isCompactView,
    });

    const linkClassNames = classNames('education-card__link', {
        'education-card__link--is-compact': isCompactView,
    });

    return (
        <FloatingCard
            className={`education-card ${className}`}
            cardClassName="education-card__card"
        >
            <div className="education-card__logo-wrapper">
                <Avatar
                    src={education.logo}
                    alt={trans('compositions.educationCard.logoAlt', { educator: education.educator })}
                    fallbackString={education.educator}
                    className="education-card__logo"
                />

                {isMobile && (
                    <div className="education-card__meta-wrapper">
                        {education.minimumPrice && (
                            <p className="education-card__price">
                                {education.minimumPrice}
                            </p>
                        )}
                        {education.isQualifiedForSTAP && (
                            <StapLabel className="education-card__stap-label" />
                        )}
                    </div>
                )}
            </div>

            <div className="education-card__wrapper">
                <header className="education-card__header">
                    <div>
                        <h2 className="education-card__title">
                            {education.title}
                        </h2>

                        <p className="education-card__educator">
                            {education.educator}
                        </p>
                    </div>

                    {!isMobile && (
                        <div className="education-card__meta-wrapper">
                            {education.minimumPrice && (
                                <p className="education-card__price">
                                    {education.minimumPrice}
                                </p>
                            )}
                            {education.isQualifiedForSTAP && (
                                <StapLabel className="education-card__stap-label" />
                            )}
                        </div>
                    )}
                </header>

                <div className={informationWrapperClassNames}>
                    <ul className="education-card__property-list">
                        {education.properties.map((property: string) => (
                            <li key={property} className="education-card__property">
                                <Tag text={property} />
                            </li>
                        ))}
                    </ul>

                    {!isCompactView && (
                        <p className="education-card__description">
                            {education.description}
                        </p>
                    )}

                    <LinkIconButton
                        icon="arrow-right"
                        iconPos="right"
                        to={educationLink}
                        text={trans('compositions.educationCard.link')}
                        className={linkClassNames}
                    />
                </div>
            </div>
        </FloatingCard>
    );
};

export default EducationCard;
