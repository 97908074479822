import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';

import {
    ErrorLabel,
    Icon,
    Input,
    InputLabel,
} from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import { IconName } from '../../../components/Icon/Icon';

import './SearchInput.scss';

export interface SearchInputProps extends Omit<HTMLInputProps, 'onChange'> {
    label: string;
    hideLabel?: boolean;
    icon?: IconName;
    hideIcon?: boolean;
    error?: string;
    onChange: (value: string) => void;
    className?: string;
    inputWrapperClassName?: string;
}

type SearchInputWithRefProps = SearchInputProps & RefAttributes<HTMLInputElement>;

const SearchInput: ForwardRefExoticComponent<SearchInputWithRefProps> = forwardRef(({
    label,
    hideLabel,
    icon = 'search',
    hideIcon,
    required,
    error,
    onChange,
    className = '',
    inputWrapperClassName = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    const searchInputClassNames = classNames('search-input', {
        'search-input--hidden-icon': hideIcon,
    }, className);

    const inputWrapperClassNames = classNames('search-input__wrapper', {
        'search-input__wrapper--has-error': !!error,
    }, inputWrapperClassName);

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={searchInputClassNames}
        >
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="search-input__label"
                />
            )}

            <div className={inputWrapperClassNames}>
                {!hideIcon && (
                    <div className="search-input__icon-wrapper">
                        <Icon name={icon} className="search-input__icon" />
                    </div>
                )}

                <Input
                    {...inputProps}
                    ref={ref}
                    type="search"
                    error={error}
                    onChange={handleChange}
                    className="search-input__input"
                />
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="search-input__error-label"
                />
            )}
        </label>
    );
});

export default SearchInput;
