import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Candidate, CandidateCounselor } from '../../models/Candidate/Candidate';
import { AsyncReduxState } from '../../types';

export type CandidateState = AsyncReduxState<{
    candidate?: Candidate;
    candidateCounselor?: CandidateCounselor;
}>;

const initialState: CandidateState = {
    ...initialAsyncReduxState,
};

export const candidateSlice = createSlice({
    name: 'candidateReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CandidateState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CandidateState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCandidate(state, action: PayloadAction<Candidate | undefined>): CandidateState {
            return {
                ...state,
                candidate: action.payload,
            };
        },
        setCandidateCounselor(state, action: PayloadAction<CandidateCounselor | undefined>): CandidateState {
            return {
                ...state,
                candidateCounselor: action.payload,
            };
        },
    },
});

export const {
    setError,
    setIsLoading,
    setCandidate,
    setCandidateCounselor,
} = candidateSlice.actions;

export default candidateSlice.reducer;
