import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Dialog, DialogContent, Notice } from '../../../compositions';
import { ExpandableNavigationItem } from '../../../compositions/ExpandableNavigation/ExpandableNavigation';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import { showNotification } from '../../../helpers/notification';
import trans from '../../../helpers/trans';
import { Candidate } from '../../../models/Candidate/Candidate';
import { SkillsPassport } from '../../../models/SkillsPassport/SkillsPassport';
import { TmaAnalysisSettings, TmaAnalysisSettingsFormData, TmaAnalysisType } from '../../../models/TmaAnalysis/TmaAnalysis';
import { User } from '../../../models/User/User';
import { NoticeType, SkillsPassportAction } from '../../../types';
import { TmaAnalysisSettingsForm } from '../..';
import { downloadSkillsPassport } from './helpers';
import {
    ProfileInformationCard,
    SkillsPassportActionsCard,
    TmaAnalysisSuccessMessage,
    UpdateSkillsPassportCard,
} from './subcomponents';

import './SkillsPassportHeader.scss';

interface SkillsPassportHeaderProps {
    user?: User;
    candidate?: Candidate;
    handleRefresh: () => void;
    resetIsUpToDate: () => void;
    //
    skillsPassportIsLoading: boolean;
    skillsPassportError: string;
    skillsPassport: SkillsPassport;
    //
    tmaAnalysisSettingsIsLoading: boolean;
    tmaAnalysisSettingsIsSuccessful: boolean;
    tmaAnalysisSettings?: TmaAnalysisSettings;
    tmaAnalysisSettingsError: string;
    onSubmitTmaAnalysisSettings: (tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData) => void;
    onFinishTmaAnalysisSettings: () => void;
    className?: string;
}

const SkillsPassportHeader: FC<SkillsPassportHeaderProps> = ({
    user,
    candidate,
    handleRefresh,
    resetIsUpToDate,
    //
    skillsPassportIsLoading,
    skillsPassportError,
    skillsPassport,
    //
    tmaAnalysisSettingsIsLoading,
    tmaAnalysisSettingsIsSuccessful,
    tmaAnalysisSettings,
    tmaAnalysisSettingsError,
    onSubmitTmaAnalysisSettings,
    onFinishTmaAnalysisSettings,
    className = '',
}): ReactElement => {
    const analysisSettingsDialogRef = useRef<HTMLDialogElement>(null);

    const [chosenTmaAnalysisType, setChosenTmaAnalysisType] = useState<TmaAnalysisType>(TmaAnalysisType.TMA170);
    const [tmaAnalysisSettingsFormKey, setTmaAnalysisSettingsFormKey] = useState<number>(0);

    const handleOpenAnalysisSettingsDialog = (): void => openDialog(analysisSettingsDialogRef);
    const handleCloseAnalysisSettingsDialog = (): void => {
        onFinishTmaAnalysisSettings();
        setChosenTmaAnalysisType(TmaAnalysisType.TMA170);

        closeDialog(analysisSettingsDialogRef);

        setTmaAnalysisSettingsFormKey(tmaAnalysisSettingsFormKey + 1);
    };

    const handleSubmitTmaAnalysisSettings = (tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData): void => {
        setChosenTmaAnalysisType(tmaAnalysisSettingsFormData.type);
        onSubmitTmaAnalysisSettings(tmaAnalysisSettingsFormData);
    };

    const handleDownloadClick = (): void => {
        if (candidate) {
            downloadSkillsPassport(candidate, skillsPassport).then((): void => {
                showNotification({
                    title: trans('containers.skillsPassportHeader.downloadNotification.successTitle'),
                    body: trans('containers.skillsPassportHeader.downloadNotification.successBody'),
                });
            });
        }
    };

    const skillsPassportActions: ExpandableNavigationItem<SkillsPassportAction>[] = [
        {
            key: SkillsPassportAction.tmaAnalysisSettings,
            label: trans('containers.skillsPassportHeader.actions.tmaAnalysisSettings'),
            icon: 'settings',
            onClick: handleOpenAnalysisSettingsDialog,
        },
        {
            key: SkillsPassportAction.download,
            label: trans('containers.skillsPassportHeader.actions.downloadSkillsPassport'),
            icon: 'download',
            onClick: handleDownloadClick,
        },
    ];

    return (
        <header className={`skills-passport-header ${className}`}>
            <div className="skills-passport-header__title-wrapper">
                {skillsPassportError && (
                    <Notice
                        type={NoticeType.error}
                        text={skillsPassportError}
                        className="skills-passport-header__error-message"
                    />
                )}
            </div>

            <div className="skills-passport-header__profile-cards">
                <ProfileInformationCard
                    isLoading={skillsPassportIsLoading}
                    userName={candidate?.fullName}
                    location={candidate?.address?.city}
                    className="skills-passport-header__card skills-passport-profile-cards__profile-card"
                />
                <UpdateSkillsPassportCard
                    isLoading={skillsPassportIsLoading}
                    isUpToDate={skillsPassport.isUpToDate}
                    updatedAt={skillsPassport.updatedAt}
                    onRefreshClick={handleRefresh}
                    resetIsUpToDate={resetIsUpToDate}
                    className="skills-passport-header__card skills-passport-profile-cards__skills-passport-card"
                />
                <SkillsPassportActionsCard
                    isDisabled={skillsPassportIsLoading || !!skillsPassportError}
                    user={user}
                    navigationItems={skillsPassportActions}
                    onDownloadClick={handleDownloadClick}
                    className="skills-passport-header__card skills-passport-profile-cards__download-card"
                />
            </div>

            <Dialog
                ref={analysisSettingsDialogRef}
                enableBackdropClose={tmaAnalysisSettingsIsSuccessful}
                onClose={handleCloseAnalysisSettingsDialog}
            >
                {tmaAnalysisSettingsIsSuccessful ? (
                    <TmaAnalysisSuccessMessage
                        candidate={candidate}
                        tmaAnalysisType={chosenTmaAnalysisType}
                        onConfirm={handleCloseAnalysisSettingsDialog}
                    />
                ) : (
                    <DialogContent title={trans('containers.skillsPassportHeader.tmaAnalysisSettings.title')}>
                        <TmaAnalysisSettingsForm
                            key={tmaAnalysisSettingsFormKey}
                            isLoading={tmaAnalysisSettingsIsLoading}
                            tmaAnalysisSettings={tmaAnalysisSettings}
                            error={tmaAnalysisSettingsError}
                            onSubmit={handleSubmitTmaAnalysisSettings}
                            onCancel={handleCloseAnalysisSettingsDialog}
                        />
                    </DialogContent>
                )}
            </Dialog>
        </header>
    );
};

export default SkillsPassportHeader;
