import { Resource } from '../japi/types/Resource';

export const retrieveRelationshipIds = <R extends Resource>(resource: R, property: string): string[] => {
    const { relationships } = resource;

    if (!relationships || Array.isArray(relationships) || !relationships[property]) {
        return [];
    }

    const iterableRelationshipValues = [relationships[property].data].flat();

    return iterableRelationshipValues.flat()
        .map(item => item?.id || '')
        .filter(Boolean);
};
