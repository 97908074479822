import { Action } from 'redux';

import { CandidateInviteFormData } from '../../models/Candidate/Candidate';
import { postCandidateInviteApiCall } from '../../models/Candidate/CandidateService';
import { transformToCreateCandidateRequest } from '../../models/Candidate/CandidateTransformers';
import { FetchResultType, isFetchResultSuccessful } from '../../models/FetchResult';
import { doesEmailExistInTalentz } from '../../services/TalentzService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSuccessful } from './candidateInvite';

export const createCandidate = (formData: CandidateInviteFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const validation = await doesEmailExistInTalentz(formData.email);

        if (validation.type === FetchResultType.Error) {
            dispatch(setError(validation.error));
            return;
        }

        const createCandidateRequest = transformToCreateCandidateRequest(formData);

        const response = await postCandidateInviteApiCall(createCandidateRequest);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[createCandidate]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const finishCreateCandidate = () => (dispatch: TypedDispatch): Action => (
    dispatch(setIsSuccessful(false))
);
