import trans from '../../helpers/trans';
import { isFetchResultSuccessful } from '../../models/FetchResult';
import { SkillsFormData } from '../../models/Skills/Skills';
import { getSkillsApiCall, postCandidateSkillsApiCall } from '../../models/Skills/SkillsService';
import { setSkills as setSkillsPassportSkills } from '../skillsPassport/skillsPassport';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsUpdating,
    setSkills,
} from './skills';

export const fetchSkills = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const skillsResponse = await getSkillsApiCall();

        if (!isFetchResultSuccessful(skillsResponse)) {
            dispatch(setError(skillsResponse.error));
            return;
        }

        dispatch(setSkills(skillsResponse.data));
    } catch (error) {
        console.error('[fetchSkills]', error);
        dispatch(setError(error as string));
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateCandidateSkills = (formData: SkillsFormData, candidateUuid: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setError(''));

    try {
        const skillIds = formData.skills.map(skill => skill.id);

        const skillsPassportResponse = await postCandidateSkillsApiCall(skillIds, candidateUuid);

        if (!isFetchResultSuccessful(skillsPassportResponse)) {
            dispatch(setError(trans('errors.unknownError')));
            return;
        }

        dispatch(setSkillsPassportSkills(skillsPassportResponse.data));
    } catch (error) {
        console.error('[updateCandidateSkills]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};
