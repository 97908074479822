import { FC, ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import { generateIdArray } from '../../../helpers/array';
import { HorizontalAlignment } from '../../../types';

import './ButtonList.scss';

interface ButtonListProps {
    align?: HorizontalAlignment;
    className?: string;
    children: ReactNode;
}

const ButtonList: FC<ButtonListProps> = ({
    align = HorizontalAlignment.right,
    className = '',
    children,
}): ReactElement => {
    const buttons = [children].flat();
    const keys = generateIdArray(buttons.length);

    const buttonListClassNames = classNames('button-list', {
        [`button-list--align-${align}`]: align,
    }, className);

    return (
        <ul className={buttonListClassNames}>
            {buttons.map((button, index) => (
                <li key={keys[index]} className="button-list__button">
                    {button}
                </li>
            ))}
        </ul>
    );
};

export default ButtonList;
