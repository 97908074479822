import { convertToKebabCase } from '../../helpers/string';
import { ModuleType } from '../../types';
import {
    Module,
    ModuleResource,
    UserModule,
    UserModuleResource,
} from './Modules';

export const transformToModule = (
    moduleResource: ModuleResource,
): Module => ({
    id: moduleResource.id,
    label: moduleResource.label,
    description: moduleResource.description,
    isActive: moduleResource.isActive,
});

export const transformToUserModule = (
    userModuleResourece: UserModuleResource,
): UserModule => ({
    id: userModuleResourece.id,
    label: convertToKebabCase(userModuleResourece.label) as ModuleType,
    isActive: userModuleResourece.isActive,
});
