import { v4 as generateUuid } from 'uuid';

import { endOfCurrentYear, startOfCurrentYear } from '../../helpers/date';

export interface JobExperienceResource {
    id: string;
    position: string;
    company: string;
    description: string;
    isCurrentJob?: boolean;
    startDate: Date;
    endDate?: Date;
}

export interface JobExperienceRequest {
    id: string;
    position: string;
    company: string;
    description: string;
    isCurrentJob?: boolean;
    startDate: string;
    endDate?: string;
}

export interface JobExperience {
    id: string;
    position: string;
    company: string;
    description: string;
    isCurrentJob?: boolean;
    startDate: Date;
    endDate?: Date;
}

export type JobExperienceFormData = JobExperience;

export const defaultJobExperience = (): JobExperience => ({
    id: generateUuid(),
    position: '',
    company: '',
    description: '',
    isCurrentJob: false,
    startDate: startOfCurrentYear,
    endDate: endOfCurrentYear,
});
