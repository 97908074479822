import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { FilterButton } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import VacancyMatchingCompetenciesSkeletons from '../VacancyMatchingCompetenciesSkeletons/VacancyMatchingCompetenciesSkeletons';

import './VacancyMatchingFilterSkeletons.scss';

export interface VacancyMatchingFilterSkeletonsProps {
    className?: string;
}

const VacancyMatchingFilterSkeletons: FC<VacancyMatchingFilterSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <Card className={`vacancy-matching-filter-skeletons ${className}`}>
        <Skeleton className="vacancy-matching-filter-skeletons__title" />

        <div className="vacancy-matching-filter-skeletons__description">
            <Skeleton className="vacancy-matching-filter-skeletons__line" />
            <Skeleton className="vacancy-matching-filter-skeletons__line" />
            <Skeleton className="vacancy-matching-filter-skeletons__line" />
        </div>

        <div className="vacancy-matching-filter-skeletons__skills-wrapper">
            <h3 className="vacancy-matching-filter-skeletons__title">
                <Skeleton className="vacancy-matching-filter-skeletons__title" />
            </h3>

            <FilterButton
                disabled
                text={trans('containers.vacancyMatchingFilter.skills.buttonLabel')}
                className="vacancy-matching-filter-skeletons__filter-button"
            />
        </div>

        <div className="vacancy-matching-filter-skeletons__competencies-wrapper">
            <VacancyMatchingCompetenciesSkeletons className="vacancy-matching-filter-skeletons__competencies-skeletons" />
        </div>
    </Card>
);

export default VacancyMatchingFilterSkeletons;
