import { FC, ReactElement, useEffect } from 'react';

import { useToggle } from 'react-use';

import { User } from '../../models/User/User';
import { FormOption } from '../../types';
import { Menu, TopBar } from '..';

import './NavigationHeader.scss';

interface NavigationHeaderProps {
    candidateIsLoading: boolean;
    candidateOptions: FormOption[];
    candidateUuid?: string;
    selectedCandidateOption?: FormOption;
    title?: string;
    user?: User;
    onCandidateSelect: (candidateId: string) => void;
    className?: string;
}

const NavigationHeader: FC<NavigationHeaderProps> = ({
    candidateIsLoading,
    candidateOptions,
    candidateUuid,
    selectedCandidateOption,
    title,
    user,
    onCandidateSelect,
    className = '',
}): ReactElement => {
    const [menuIsOpen, toggleMenuIsOpen] = useToggle(false);

    useEffect((): void => {
        if (menuIsOpen) {
            document.body.classList.add('has-open-menu');
        } else {
            document.body.classList.remove('has-open-menu');
        }
    }, [menuIsOpen]);

    return (
        <header className={`navigation-header ${className}`}>
            <TopBar
                title={title}
                userRoles={user?.roles}
                userFullName={user?.fullName}
                menuIsOpen={menuIsOpen}
                onMenuToggle={toggleMenuIsOpen}
                className="navigation-header__top-bar"
            />

            <Menu
                isLoading={candidateIsLoading}
                menuIsOpen={menuIsOpen}
                candidateOptions={candidateOptions}
                selectedCandidateOption={selectedCandidateOption}
                candidateUuid={candidateUuid}
                userUuid={user?.uuid}
                userRoles={user?.roles}
                userModules={user?.modules}
                organisationUuid={user?.organisation.id}
                onCandidateSelect={onCandidateSelect}
                className="navigation-header__menu"
            />
        </header>
    );
};

export default NavigationHeader;
