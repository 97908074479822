import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Organisation } from '../../models/Organisation/Organisation';
import { AsyncReduxState } from '../../types';

export type OrganisationState = AsyncReduxState<{
    isUpdating: boolean;
    optionsError: string;
    organisation?: Organisation;
    options: Organisation[];
}>;

const initialState: OrganisationState = {
    ...initialAsyncReduxState,
    isUpdating: false,
    optionsError: '',
    options: [],
};

export const organisationSlice = createSlice({
    name: 'organisationReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrganisationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): OrganisationState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OrganisationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrganisationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOptionsError(state, action: PayloadAction<string>): OrganisationState {
            return {
                ...state,
                optionsError: action.payload,
            };
        },
        setOrganisation(state, action: PayloadAction<Organisation | undefined>): OrganisationState {
            return {
                ...state,
                organisation: action.payload,
            };
        },
        setOptions(state, action: PayloadAction<Organisation[]>): OrganisationState {
            return {
                ...state,
                options: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setIsSuccessful,
    setError,
    setOptionsError,
    setOrganisation,
    setOptions,
} = organisationSlice.actions;

export default organisationSlice.reducer;
