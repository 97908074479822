import 'regenerator-runtime';

import { LoginType, MsalAuthProvider } from 'react-aad-msal';

export const AzureAuthProvider = new MsalAuthProvider(
    {
        auth: {
            authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
            clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: true,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
        },
    },
    {
        scopes: ['openid'],
    },
    {
        loginType: LoginType.Redirect,
    },
);
