import {
    FC,
    PropsWithChildren,
    ReactElement,
    TransitionEvent,
    useRef,
} from 'react';

import classNames from 'classnames';

import { Button, Icon, LoadingSpinner } from '../../components';
import { IconName } from '../../components/Icon/Icon';
import trans from '../../helpers/trans';

import './Sidebar.scss';

interface SidebarProps {
    isLoading?: boolean;
    isExpanded: boolean;
    title: string;
    icon?: IconName;
    onClose: () => void;
    className?: string;
}

const Sidebar: FC<PropsWithChildren<SidebarProps>> = ({
    isLoading,
    isExpanded,
    title,
    icon,
    onClose,
    className = '',
    children,
}): ReactElement => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const handleEntryFocus = (event: TransitionEvent<HTMLDivElement>): void => {
        const targetsWrapper = event.target === wrapperRef.current;

        if (closeButtonRef.current && targetsWrapper && isExpanded) {
            closeButtonRef.current.focus();
        }
    };

    const sidebarClassNames = classNames('sidebar', {
        'sidebar--is-expanded': isExpanded,
    }, className);

    return (
        <section className={sidebarClassNames}>
            <div
                ref={wrapperRef}
                className="sidebar__wrapper"
                onTransitionEnd={handleEntryFocus}
            >
                <header className="sidebar__header">
                    {icon && (
                        <Icon name={icon} className="sidebar__icon" />
                    )}

                    <h1 className="sidebar__title">
                        {title}
                    </h1>

                    {isLoading && (
                        <LoadingSpinner className="sidebar__loader" />
                    )}

                    <Button
                        ref={closeButtonRef}
                        text={trans('compositions.sidebar.closeButtonLabel')}
                        tabIndex={isExpanded ? 0 : -1}
                        onClick={onClose}
                        className="sidebar__close-button"
                    >
                        <Icon name="cross" className="sidebar__close-button-icon" />
                    </Button>
                </header>

                <div className="sidebar__child-wrapper">
                    {isExpanded && children}
                </div>
            </div>
        </section>
    );
};

export default Sidebar;
