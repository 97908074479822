import { FC, ReactElement } from 'react';

import { ExpandableNavigationItem } from '../../compositions/ExpandableNavigation/ExpandableNavigation';
import trans from '../../helpers/trans';
import { userProfileNavigationItems } from '../../helpers/user';
import { RoutePaths } from '../../Routes';
import { RouteKey, UserRole } from '../../types';
import { ProfileNavigation } from '..';
import { HamburgerButton } from './subcomponents';

import './TopBar.scss';

interface TopBarProps {
    title?: string;
    userRoles?: UserRole[];
    userFullName?: string;
    menuIsOpen: boolean;
    onMenuToggle: () => void;
    className?: string;
}

const TopBar: FC<TopBarProps> = ({
    title,
    userRoles = [],
    userFullName,
    menuIsOpen,
    onMenuToggle,
    className = '',
}): ReactElement => {
    const profileNavigationItems: ExpandableNavigationItem<RouteKey>[] = [
        {
            key: RouteKey.settings,
            label: trans('routes.settings'),
            icon: 'settings',
            to: RoutePaths.settings(),
        },
        {
            key: RouteKey.logout,
            label: trans('routes.logout'),
            icon: 'logout',
            to: RoutePaths.logout(),
        },
    ];

    const allowedItems = userRoles.length > 0
        ? userRoles.map(role => userProfileNavigationItems[role]).flat()
        : [];
    const userProfileNavigationItemList = profileNavigationItems.filter(item => allowedItems.includes(item.key));

    return (
        <section className={`top-bar ${className}`}>
            <div className="top-bar__wrapper">
                <ProfileNavigation
                    userName={userFullName}
                    navigationItems={userProfileNavigationItemList}
                    className="top-bar__profile-navigation"
                />

                <div className="top-bar__hamburger-wrapper">
                    <HamburgerButton
                        isActive={menuIsOpen}
                        onToggle={onMenuToggle}
                    />
                </div>
            </div>

            {title && (
                <h1 className="top-bar__page-title">
                    {title}
                </h1>
            )}
        </section>
    );
};

export default TopBar;
