import { FC, ReactElement, useRef } from 'react';

import { Button, Icon } from '../../../../components';
import {
    CopyableValue,
    Dialog,
    DialogContent,
    IconButton,
} from '../../../../compositions';
import { closeDialog, openDialog } from '../../../../helpers/dialog';
import trans from '../../../../helpers/trans';
import { CandidateCounselor } from '../../../../models/Candidate/Candidate';

import './CounselorContact.scss';

interface CounselorContactProps {
    isLoading: boolean;
    candidateCounselor?: CandidateCounselor;
    className?: string;
}

const CounselorContact: FC<CounselorContactProps> = ({
    isLoading,
    candidateCounselor,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => closeDialog(dialogRef);

    return (
        <div className={`counselor-contact ${className}`}>
            {!candidateCounselor?.email && (
                <p className="counselor-contact__paragraph">
                    {trans('containers.myActions.counselorContact.name', {
                        fullName: !candidateCounselor ? '…' : candidateCounselor.fullName,
                    })}
                </p>
            )}

            {!isLoading && candidateCounselor?.email && (
                <p className="counselor-contact__paragraph">
                    {trans('containers.myActions.counselorContact.hasEmail.start', {
                        fullName: candidateCounselor.fullName,
                    })}

                    <Button
                        text={trans('containers.myActions.counselorContact.hasEmail.middle')}
                        onClick={handleOpenDialog}
                        className="counselor-contact__contact-button"
                    />

                    {trans('containers.myActions.counselorContact.hasEmail.end')}
                </p>
            )}

            {candidateCounselor && (
                <Dialog
                    enableBackdropClose
                    ref={dialogRef}
                    onClose={handleCloseDialog}
                    className="counselor-contact__dialog"
                >
                    <DialogContent
                        title={candidateCounselor.fullName}
                        tag={trans('containers.myActions.counselorContact.dialog.tag')}
                        description={trans('containers.myActions.counselorContact.dialog.description')}
                    >
                        <div className="counselor-contact__email-wrapper">
                            <a href={`mailto:${candidateCounselor.email}`} className="counselor-contact__email-link">
                                <Icon name="message-open" className="counselor-contact__email-icon" />

                                {trans('containers.myActions.counselorContact.dialog.setUpEmail')}
                            </a>

                            <CopyableValue
                                value={candidateCounselor.email}
                                className="counselor-contact__copyable-value"
                            />
                        </div>

                        <IconButton
                            icon="chevron-left"
                            text={trans('containers.myActions.counselorContact.dialog.closeButton')}
                            onClick={handleCloseDialog}
                            className="counselor-contact__close-dialog-button"
                        />
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

export default CounselorContact;
