import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel } from '../../../components';
import { FormOption } from '../../../types';
import { BlockRadioItem } from './subcomponents';

import './BlockRadioList.scss';

interface BlockRadioListProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    label: string;
    hideLabel?: boolean;
    options: FormOption[];
    error?: string;
    onChange: (value: string) => void;
    className?: string;
    itemClassName?: string;
}

const BlockRadioList: FC<BlockRadioListProps> = ({
    label,
    hideLabel = false,
    options,
    value,
    required = false,
    error = '',
    className = '',
    itemClassName,
    ...blockRadioProps
}): ReactElement => {
    const blockRadioListClassName = classNames('block-radio-list__items', {
        'block-radio-list__items--has-error': !!error,
    });

    return (
        <div className={`block-radio-list ${className}`}>
            {!hideLabel && (
                <InputLabel
                    text={label}
                    required={required}
                    className="block-radio-list__label"
                />
            )}

            <div className={blockRadioListClassName}>
                {options.map(option => (
                    <BlockRadioItem
                        {...blockRadioProps}
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        checked={value === option.value}
                        className={itemClassName}
                    />
                ))}
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="block-radio-list__error-label"
                />
            )}
        </div>
    );
};

export default BlockRadioList;
