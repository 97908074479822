/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconViewExtensive: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 76.5" className={`icon ${className}`}>
        <rect width="100" height="35.3" />
        <rect y="41.2" width="100" height="35.3" />
    </svg>
);

export default IconViewExtensive;
/* eslint-enable max-len */
