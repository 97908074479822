import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Store, UnknownAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { isNotProduction, isNotSSR } from '../helpers';
import reducers, { Reducers } from './reducers';

export type TypedDispatch = ThunkDispatch<Reducers, unknown, UnknownAction>;
export type ReducerGetter = () => Reducers;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Reducers> = useSelector;

export const configureStore = async (preloadedState: Record<string, unknown> = {}): Promise<Store<Reducers>> => configureReduxStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
    preloadedState,
    devTools: isNotSSR && isNotProduction,
});
