import { FC, ReactElement } from 'react';

import { OccupationTabs } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedOccupationTabsProps {
    className?: string;
}

const ConnectedOccupationTabs: FC<ConnectedOccupationTabsProps> = ({
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.userReducer.isLoading);
    const user = useTypedSelector(state => state.userReducer.user);

    return (
        <OccupationTabs
            isLoading={isLoading}
            userModules={user?.modules}
            className={className}
        />
    );
};

export default ConnectedOccupationTabs;
