import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { School } from '../../models/Schools';
import { AsyncReduxState } from '../../types';

export type SchoolsState = AsyncReduxState<{
    schools: School[];
}>;

const initialState: SchoolsState = {
    ...initialAsyncReduxState,
    schools: [],
};

export const schoolsSlice = createSlice({
    name: 'schoolsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SchoolsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SchoolsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSchools(state, action: PayloadAction<School[]>): SchoolsState {
            return {
                ...state,
                schools: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setSchools,
} = schoolsSlice.actions;

export default schoolsSlice.reducer;
