import { FC, ReactElement } from 'react';

import { IconButton } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { Talent } from '../../../../../models/SkillsPassport/SkillsPassport';

import './TalentDescription.scss';

interface TalentDescriptionProps {
    talent: Talent;
    onCloseDialog: () => void;
    className?: string;
}

const TalentDescription: FC<TalentDescriptionProps> = ({
    talent,
    onCloseDialog,
    className = '',
}): ReactElement => (
    <div className={`talent-description ${className}`}>
        {talent.descriptions.map(({ title, description }) => (
            <div key={title} className="talent-description__content">
                <h4 className="talent-description__title">{title}</h4>
                <p className="talent-description__description">{description}</p>
            </div>
        ))}

        <IconButton
            icon="chevron-left"
            text={trans('containers.talentsCard.closeButton')}
            onClick={onCloseDialog}
            className="talent-description__close-dialog-button"
        />
    </div>
);

export default TalentDescription;
