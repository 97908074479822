import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { DevelopmentPlanOverview } from '../../../containers';
import { EditDevelopmentPlanCompetencyFormData } from '../../../models/DevelopmentPlanCompetency/DevelopmentPlanCompetency';
import { EditDevelopmentPlanSkillFormData } from '../../../models/DevelopmentPlanSkill/DevelopmentPlanSkill';
import { fetchDevelopmentPlan } from '../../../redux/developmentPlan/developmentPlanActions';
import { deleteDevelopmentPlanCompetency, editDevelopmentPlanCompetency } from '../../../redux/developmentPlanCompetency/developmentPlanCompetencyActions';
import { deleteDevelopmentPlanSkill, editDevelopmentPlanSkill } from '../../../redux/developmentPlanSkill/developmentPlanSkillActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedDevelopmentOverviewProps {
    className?: string;
}

const ConnectedDevelopmentPlanOverview: FC<ConnectedDevelopmentOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { candidateUuid, planUuid: developmentPlanUuid } = useParams<{ 'candidateUuid': string; planUuid: string }>();

    const developmentPlanIsLoading = useTypedSelector(state => state.developmentPlanReducer.isLoading);
    const developmentPlan = useTypedSelector(state => state.developmentPlanReducer.developmentPlan);

    const user = useTypedSelector(state => state.userReducer.user);
    const updatingCompetencyUuid = useTypedSelector(state => state.developmentPlanCompetencyReducer.updatingCompetencyUuid);
    const competencyIsSuccessful = useTypedSelector(state => state.developmentPlanCompetencyReducer.isSuccessful);

    const updatingSkillUuid = useTypedSelector(state => state.developmentPlanSkillReducer.updatingSkillUuid);
    const skillIsSuccessful = useTypedSelector(state => state.developmentPlanSkillReducer.isSuccessful);

    const handleEditCompetencySubmit = (formData: EditDevelopmentPlanCompetencyFormData): void => {
        if (candidateUuid) dispatch(editDevelopmentPlanCompetency(formData, candidateUuid));
    };

    const handleDeleteCompetency = (competencyUuid: string): void => {
        if (candidateUuid) dispatch(deleteDevelopmentPlanCompetency(competencyUuid, candidateUuid));
    };

    const handleEditSkillSubmit = (formData: EditDevelopmentPlanSkillFormData): void => {
        if (candidateUuid) dispatch(editDevelopmentPlanSkill(formData, candidateUuid));
    };

    const handleDeleteSkill = (skillUuid: string): void => {
        if (candidateUuid) dispatch(deleteDevelopmentPlanSkill(skillUuid, candidateUuid));
    };

    useEffect((): void => {
        if ((competencyIsSuccessful || skillIsSuccessful) && developmentPlanUuid && candidateUuid) {
            dispatch(fetchDevelopmentPlan(developmentPlanUuid, candidateUuid));
        }
    }, [competencyIsSuccessful, skillIsSuccessful]);

    return (
        <DevelopmentPlanOverview
            isLoading={developmentPlanIsLoading}
            competencyIsSuccessful={competencyIsSuccessful}
            skillIsSuccessful={skillIsSuccessful}
            updatingCompetencyUuid={updatingCompetencyUuid}
            updatingSkillUuid={updatingSkillUuid}
            competencies={developmentPlan?.competencies || []}
            skills={developmentPlan?.skills || []}
            userRoles={user?.roles}
            onEditCompetencySubmit={handleEditCompetencySubmit}
            onEditSkillSubmit={handleEditSkillSubmit}
            onDeleteCompetency={handleDeleteCompetency}
            onDeleteSkill={handleDeleteSkill}
            className={className}
        />
    );
};

export default ConnectedDevelopmentPlanOverview;
