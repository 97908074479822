import sanitizeHtml from 'sanitize-html';

import trans from './trans';

export const capitalizeFirstCharacter = (text: string): string => (
    text.trim().charAt(0).toUpperCase() + text.trim().substring(1)
);

export const convertToKebabCase = (text: string): string => (
    text.replace(/_| /g, '-').toLowerCase()
);

export const splitString = (string: string, index: number): string[] => (
    [string.slice(0, index), string.slice(index)]
);

export const stripCharacterFromString = (text: string, characterToStrip: string): string => {
    const splittedText = text.split(characterToStrip);
    return splittedText.join('');
};

export const trimToMaxLength = (string: string, maxLength = 100): string => {
    if (string.length <= maxLength) return string;

    const words = string.substring(0, maxLength).split(' ');
    words.pop();

    const output = words.join(' ').trim();

    return `${output}…`;
};

export const convertHtmlParagraphStringToArray = (text: string): string[] => text
    .split('</p><p>')
    .map(paragraph => sanitizeHtml(paragraph, { allowedTags: [] }));

export const addHourUnit = (hour: string | number): string => trans('common.format.hour', {
    hour: String(hour),
});

export const addKmUnit = (distance: string | number): string => trans('common.format.km', {
    distance: String(distance),
});

export const getInitials = (name: string): string => {
    const cleanName = name.replace(/[^a-zA-Z ]/g, ' ');

    const capitals = cleanName
        .split('')
        .filter(letter => letter === letter.toUpperCase())
        .filter(letter => letter !== ' ')
        .join('');

    if (capitals.length >= 2) {
        return capitals.substring(0, 3);
    }

    const words = cleanName.split(' ');
    const firstLetters = words.map(word => word[0]).filter(Boolean);

    return firstLetters.length === 1
        ? cleanName.substring(0, 3)
        : firstLetters.join('').substring(0, 3);
};
