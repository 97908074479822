import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { OrganisationDetailHeader } from '../../../containers';
import useUnmount from '../../../hooks/useUnmount';
import { Organisation, OrganisationFormData } from '../../../models/Organisation/Organisation';
import { User } from '../../../models/User/User';
import { setOrganisation } from '../../../redux/organisation/organisation';
import { fetchOrganisation, updateOrganisation } from '../../../redux/organisation/organisationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrganisationDetailHeaderProps {
    onOrganisationResponse: (organisation?: Organisation) => void;
    onUserResponse: (user?: User) => void;
    className?: string;
}

const ConnectedOrganisationDetailHeader: FC<ConnectedOrganisationDetailHeaderProps> = ({
    onOrganisationResponse,
    onUserResponse,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { organisationUuid } = useParams();

    const organisationIsLoading = useTypedSelector(state => state.organisationReducer.isLoading);
    const isUpdating = useTypedSelector(state => state.organisationReducer.isUpdating);
    const isSuccessful = useTypedSelector(state => state.organisationReducer.isSuccessful);
    const error = useTypedSelector(state => state.organisationReducer.error);
    const organisation = useTypedSelector(state => state.organisationReducer.organisation);

    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);
    const user = useTypedSelector(state => state.userReducer.user);

    const isLoading = [organisationIsLoading, userIsLoading].some(Boolean);

    const handleEditOrganisationSubmit = (formData: OrganisationFormData): void => {
        dispatch(updateOrganisation(formData));
    };

    useUnmount((): void => {
        dispatch(setOrganisation(undefined));
    });

    useEffect((): void => {
        if (organisationUuid) dispatch(fetchOrganisation(organisationUuid));
    }, [organisationUuid]);

    useEffect((): void => {
        onOrganisationResponse(organisation);
        onUserResponse(user);
    }, [organisation, user]);

    return (
        <OrganisationDetailHeader
            isLoading={isLoading}
            isUpdating={isUpdating}
            isSuccessful={isSuccessful}
            error={error}
            organisation={organisation}
            onEditOrganisationSubmit={handleEditOrganisationSubmit}
            className={className}
        />
    );
};

export default ConnectedOrganisationDetailHeader;
