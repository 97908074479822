import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Skill } from '../../models/Skills/Skills';
import { AsyncReduxState } from '../../types';

export type SkillsState = AsyncReduxState<{
    isUpdating: boolean;
    skills: Skill[];
}>;

const initialState: SkillsState = {
    ...initialAsyncReduxState,
    isUpdating: false,
    skills: [],
};

export const skillsSlice = createSlice({
    name: 'skillsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SkillsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsUpdating(state, action: PayloadAction<boolean>): SkillsState {
            return {
                ...state,
                isUpdating: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SkillsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSkills(state, action: PayloadAction<Skill[]>): SkillsState {
            return {
                ...state,
                skills: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsUpdating,
    setError,
    setSkills,
} = skillsSlice.actions;

export default skillsSlice.reducer;
