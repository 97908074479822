import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { Module, ModuleRequest, ModuleResource } from './Modules';
import { transformToModule } from './ModulesTransformers';

export const getModulesForOrganisationApiCall = async (organisationId: string): Promise<FetchResult<Module[], string>> => {
    try {
        const response = await lloFetch(`/api/module/${organisationId}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const modulesResponse: ModuleResource[] = await response.json();

        const modules = modulesResponse.map(transformToModule);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: modules,
        };
    } catch (error) {
        console.error('[getModulesForOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchModulesForOrganisationApiCall = async (organisationId: string, moduleRequest: ModuleRequest): Promise<FetchResult<Module, string>> => {
    try {
        const response = await lloFetch(`/api/module/${organisationId}`, {
            method: 'PATCH',
            body: JSON.stringify(moduleRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const modulesResponse: ModuleResource = await response.json();

        const module = transformToModule(modulesResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: module,
        };
    } catch (error) {
        console.error('[patchModulesForOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
