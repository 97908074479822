import { FC, ReactElement, useEffect } from 'react';

import { TakeAnalysis } from '../../containers';
import { TmaAnalysisSettingsFormData } from '../../models/TmaAnalysis/TmaAnalysis';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchTmaAnalysisSettings, postTmaAnalysisSettings, updateTmaAnalysisSettingsSuccess } from '../../redux/tmaAnalysisSettings/tmaAnalysisSettingsActions';

const ConnectedTakeAnalysis: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const user = useTypedSelector(state => state.userReducer.user);
    const userIsLoading = useTypedSelector(state => state.userReducer.isLoading);

    const candidate = useTypedSelector(state => state.candidateReducer.candidate);
    const candidateIsLoading = useTypedSelector(state => state.candidateReducer.isLoading);

    const tmaAnalysisSettingsIsLoading = useTypedSelector(state => state.tmaAnalysisSettingsReducer.isLoading);
    const tmaAnalysisSettingsIsSuccessful = useTypedSelector(state => state.tmaAnalysisSettingsReducer.isSuccessful);
    const tmaAnalysisSettingsError = useTypedSelector(state => state.tmaAnalysisSettingsReducer.error);
    const tmaAnalysisSettings = useTypedSelector(state => state.tmaAnalysisSettingsReducer.tmaAnalysisSettings);

    const isLoading = [
        userIsLoading,
        candidateIsLoading,
        tmaAnalysisSettingsIsLoading,
    ].some(Boolean);

    const handleSubmitTmaAnalysisSettings = (tmaAnalysisSettingsFormData: TmaAnalysisSettingsFormData): void => {
        if (candidate) {
            dispatch(postTmaAnalysisSettings(candidate.uuid, tmaAnalysisSettingsFormData));
        }
    };

    const handleFinishTmaAnalysisSettings = (): void => {
        dispatch(updateTmaAnalysisSettingsSuccess(false));
    };

    useEffect((): void => {
        if (candidate?.uuid) dispatch(fetchTmaAnalysisSettings(candidate.uuid));
    }, [candidate]);

    return (
        <TakeAnalysis
            isLoading={isLoading}
            tmaAnalysisSettingsIsLoading={tmaAnalysisSettingsIsLoading}
            tmaAnalysisSettingsIsSuccessful={tmaAnalysisSettingsIsSuccessful}
            user={user}
            candidate={candidate}
            tmaAnalysisSettings={tmaAnalysisSettings}
            tmaAnalysisSettingsError={tmaAnalysisSettingsError}
            onSubmitTmaAnalysisSettings={handleSubmitTmaAnalysisSettings}
            onFinishTmaAnalysisSettings={handleFinishTmaAnalysisSettings}
        />
    );
};

export default ConnectedTakeAnalysis;
