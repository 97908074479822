import { lloFetch } from '../../helpers/lloFetch';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import {
    AddOrganisationRequest,
    EditOrganisationRequest,
    Organisation,
    OrganisationResource,
} from './Organisation';
import { transformToOrganisation } from './OrganisationTransformers';

export const getOrganisationApiCall = async (organisationId: string): Promise<FetchResult<Organisation, string>> => {
    try {
        const response = await lloFetch(`/api/organisation/${organisationId}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationResponse: OrganisationResource = await response.json();

        const organisation = transformToOrganisation(organisationResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisation,
        };
    } catch (error) {
        console.error('[getOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postOrganisationApiCall = async (addOrganisationRequest: AddOrganisationRequest): Promise<FetchResult<Organisation, string>> => {
    try {
        const response = await lloFetch('/api/organisation', {
            method: 'POST',
            body: JSON.stringify(addOrganisationRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationResponse: OrganisationResource = await response.json();

        const organisation = transformToOrganisation(organisationResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisation,
        };
    } catch (error) {
        console.error('[postOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchOrganisationApiCall = async (editOrganisationRequest: EditOrganisationRequest): Promise<FetchResult<Organisation, string>> => {
    try {
        const response = await lloFetch(`/api/organisation/${editOrganisationRequest.id}`, {
            method: 'PATCH',
            body: JSON.stringify(editOrganisationRequest),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationResponse: OrganisationResource = await response.json();

        const organisation = transformToOrganisation(organisationResponse);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisation,
        };
    } catch (error) {
        console.error('[patchOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const deleteOrganisationApiCall = async (organisationId: string): Promise<FetchResult<undefined, string>> => {
    try {
        const response = await lloFetch(`/api/organisation/${organisationId}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[deleteOrganisationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getOrganisationOptionsApiCall = async (searchQuery: string): Promise<FetchResult<Organisation[], string>> => {
    try {
        const response = await lloFetch(`/api/organisation/exchangeOverview/?searchQuery=${searchQuery}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const organisationOptionsResponse: OrganisationResource[] = await response.json();
        const organisationOptions = organisationOptionsResponse.map(transformToOrganisation);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: organisationOptions,
        };
    } catch (error) {
        console.error('[getOrganisationOptionsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
