/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconEdit: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 100" className={`icon ${className}`}>
        <polygon points="0,79 0,99.8 20.7,99.8 81.8,38.6 61.1,17.9" />
        <path d="M97.9,14.8L84.9,1.8c-1.1-1.1-2.5-1.6-3.9-1.6c-1.4,0-2.8,0.6-3.9,1.6L67,12l20.7,20.7l10.1-10.1C100,20.4,100,16.8,97.9,14.8z" />
    </svg>
);

export default IconEdit;
/* eslint-enable max-len */
