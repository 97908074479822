import { FC, ReactElement } from 'react';

import { v4 as generateUuid } from 'uuid';

import { Tag } from '../../components';

import './TagList.scss';

interface TagListProps {
    title?: string;
    description?: string;
    tags: string[];
    compareTags?: string[];
    className?: string;
}

const TagList: FC<TagListProps> = ({
    title,
    description,
    tags,
    compareTags,
    className = '',
}): ReactElement => (
    <section className={`tag-list ${className}`}>
        {title && <h2 className="tag-list__title">{title}</h2>}

        {description && (
            <p className="tag-list__description">{description}</p>
        )}

        <ul className="tag-list__list">
            {tags.map(tag => {
                const keyUuid = generateUuid();
                const isActive = compareTags?.includes(tag);

                return (
                    <li key={keyUuid} className="tag-list__list-item">
                        <Tag isActive={isActive} text={tag} />
                    </li>
                );
            })}
        </ul>
    </section>
);

export default TagList;
