import { retrieveRelationshipIds } from '../helpers/japi';
import { Resource } from '../japi/types/Resource';
import { SearchableOption } from '../types';

export interface FieldsOfStudyResource extends Resource {
    id: string;
    type: 'fields-of-study';
    attributes: {
        name: string;
        slug: string;
        manual: boolean;
        createdAt: string;
        updatedAt: string;
    };
}

export interface FieldOfStudy {
    id: string;
    name: string;
    relatedEducationLevelIds?: string[];
}

export const transformResourceToFieldOfStudy = (
    fieldOfStudyResource: FieldsOfStudyResource,
): FieldOfStudy => {
    const relatedEducationLevelIds = retrieveRelationshipIds<FieldsOfStudyResource>(fieldOfStudyResource, 'educationLevel');

    return {
        id: fieldOfStudyResource.id,
        name: fieldOfStudyResource.attributes.name,
        relatedEducationLevelIds,
    };
};

export const transformFieldOfStudyToSearchableOption = (
    fieldOfStudy: FieldOfStudy,
): SearchableOption => ({
    value: fieldOfStudy.id,
    label: fieldOfStudy.name,
});
