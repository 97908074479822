import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import ReactSelect, { OnChangeValue } from 'react-select';

import trans from '../../../helpers/trans';
import { FormOption } from '../../../types';

import './Select.scss';

interface SelectProps {
    defaultMenuIsOpen?: boolean;
    hasAutoFocus?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isClearable?: boolean;
    error?: string;
    name: string;
    options: FormOption[];
    placeholder?: string;
    value?: OnChangeValue<FormOption, false>;
    onChange: (selected: FormOption) => void;
    className?: string;
}

const Select: FC<SelectProps> = ({
    defaultMenuIsOpen,
    hasAutoFocus,
    isDisabled,
    isLoading,
    isClearable,
    error = '',
    name,
    options,
    placeholder = trans('components.select.placeholder'),
    value,
    onChange,
    className = '',
}): ReactElement => {
    const selectClassName = classNames('select', {
        'select--is-disabled': isDisabled,
        'select--has-error': !!error,
    }, className);

    const noOptionsMessage = (): string => trans('components.select.nullState');

    const handleChange = (selectValue: OnChangeValue<FormOption, false> | null): void => {
        onChange(selectValue as FormOption);
    };

    return (
        <ReactSelect
            autoFocus={hasAutoFocus}
            defaultMenuIsOpen={defaultMenuIsOpen}
            isLoading={isLoading}
            isSearchable
            isClearable={isClearable}
            isDisabled={isDisabled}
            name={name}
            noOptionsMessage={noOptionsMessage}
            options={options}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            classNamePrefix="select"
            className={selectClassName}
        />
    );
};

export default Select;
