import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { BlockToggle } from '../../../../../compositions';
import { Module } from '../../../../../models/Modules/Modules';

interface EditableModuleProps {
    isLoading: boolean;
    module: Module;
    onToggleChange: (isChecked: boolean) => void;
    className?: string;
}

const EditableModule: FC<EditableModuleProps> = ({
    isLoading,
    module,
    onToggleChange,
    className = '',
}): ReactElement => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect((): void => {
        setIsChecked(module.isActive);
    }, [module]);

    return (
        <li className={`editable-module ${className}`}>
            <BlockToggle
                disabled={isLoading}
                checked={isChecked}
                id={module.id}
                label={module.label}
                description={module.description}
                onChange={onToggleChange}
            />
        </li>
    );
};

export default EditableModule;
