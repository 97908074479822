import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { Breadcrumbs } from '../../compositions';
import { ConnectedOccupationDetailHeader, ConnectedOccupationDetailSkills, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';
import { Candidate } from '../../models/Candidate/Candidate';
import { OccupationDetail as OccupationDetailModel } from '../../models/OccupationDetail/OccupationDetail';
import { RoutePaths } from '../../Routes';

import './OccupationDetail.scss';

const OccupationDetail: FC = (): ReactElement => {
    const [candidate, setCandidate] = useState<Candidate>();
    const [occupation, setOccupation] = useState<OccupationDetailModel>();

    const breadcrumbs = [
        { label: trans('pages.occupationDetail.breadcrumb'), to: RoutePaths.seekOccupation(candidate?.uuid) },
        { label: occupation?.name || '' },
    ];

    return (
        <ConnectedPage
            hasNavigation
            title={trans('pages.occupationDetail.title')}
            className="occupation-detail-page"
        >
            <Helmet>
                <title>
                    {trans('pages.occupationDetail.metaTitle', {
                        name: occupation?.name || '',
                    })}
                </title>
            </Helmet>

            <Breadcrumbs crumbs={breadcrumbs} />

            <ConnectedOccupationDetailHeader
                onCandidateResponse={setCandidate}
                onOccupationDetailResponse={setOccupation}
                className="occupation-detail-page__overview"
            />

            <ConnectedOccupationDetailSkills
                className="occupation-detail-page__overview"
            />
        </ConnectedPage>
    );
};

export default OccupationDetail;
