import { FC, ReactElement } from 'react';

import { CopyableValue } from '../../../../compositions';

import './TroubleshootDetailItem.scss';

export interface TroubleshootDetail {
    title: string;
    description: string;
    value?: string;
}

interface TroubleshootDetailItemProps extends TroubleshootDetail {
    className?: string;
}

const TroubleshootDetailItem: FC<TroubleshootDetailItemProps> = ({
    title,
    description,
    value = '',
    className = '',
}): ReactElement => (
    <div className={`troubleshoot-detail-item ${className}`}>
        <h2 className="troubleshoot-detail-item__title">
            {title}
        </h2>
        <p className="troubleshoot-detail-item__description">
            {description}
        </p>
        <CopyableValue
            value={value}
            className="troubleshoot-detail-item__value"
        />
    </div>
);

export default TroubleshootDetailItem;
