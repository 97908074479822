import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Card } from '../../components';
import { IconButton } from '../../compositions';
import { sessionDurationInMinutes } from '../../constants';
import trans from '../../helpers/trans';

import './LogoutMessage.scss';

interface LogoutMessageProps {
    onConfirm: () => void;
    className?: string;
}

const LogoutMessage: FC<LogoutMessageProps> = ({
    onConfirm,
    className = '',
}): ReactElement => {
    const [counter, setCounter] = useState<number>(20);

    useEffect((): () => void => {
        if (counter === 0) return (): void => undefined;

        const timer = setTimeout((): void => {
            const newCount = counter - 1;

            setCounter(newCount);
            if (newCount === 0) onConfirm();
        }, 1000);

        return (): void => clearTimeout(timer);
    }, [counter]);

    return (
        <Card className={`logout-message ${className}`}>
            <h1 className="logout-message__title">
                {trans('containers.logoutMessage.title')}
            </h1>

            <p className="logout-message__description">
                {trans('containers.logoutMessage.description', {
                    minutes: String(sessionDurationInMinutes),
                })}
            </p>

            <p className="logout-message__countdown-message">
                {trans('containers.logoutMessage.countdownMessage', {
                    seconds: String(counter),
                })}
            </p>

            <IconButton
                icon="logout"
                text={trans('containers.logoutMessage.confirmButtonLabel')}
                onClick={onConfirm}
                className="logout-message__confirm-button"
            />
        </Card>
    );
};

export default LogoutMessage;
