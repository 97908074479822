/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconSortDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 100 70" className={`icon ${className}`}>
        <rect width="20" height="10" />
        <rect y="30" width="35" height="10" />
        <rect y="60" width="50" height="10" />
        <polygon points="85,50 85,0 75,0 75,50 60,50 80,70 100,50" />
    </svg>
);

export default IconSortDown;
/* eslint-enable max-len */
