import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Dialog, DialogContent, IconButton } from '../../../compositions';
import { closeDialog, openDialog } from '../../../helpers/dialog';
import trans from '../../../helpers/trans';
import { OrganisationFormData } from '../../../models/Organisation/Organisation';
import { OrganisationOverviewItem } from '../../../models/OrganisationOverviewItem/OrganisationOverviewItem';
import { Pagination as PaginationModel } from '../../../models/Pagination';
import { OrganisationForm, OrganisationTable, Pagination } from '../..';

import './OrganisationOverview.scss';

interface OrganisationOverviewProps {
    organisationOverviewIsLoading: boolean;
    organisationIsUpdating: boolean;
    organisationIsSuccessful: boolean;
    organisationOverviewError: string;
    organisationError: string;
    totalAmount: number;
    organisations: OrganisationOverviewItem[];
    pagination?: PaginationModel;
    onAddOrganisationSubmit: (formData: OrganisationFormData) => void;
    onEditOrganisationSubmit: (formData: OrganisationFormData) => void;
    onDeleteOrganisationSubmit: (organisationId: string) => void;
    onPaginationChange: (currentPage: number) => void;
    className?: string;
}

const OrganisationOverview: FC<OrganisationOverviewProps> = ({
    organisationOverviewIsLoading,
    organisationIsUpdating,
    organisationIsSuccessful,
    organisationOverviewError,
    organisationError,
    totalAmount,
    organisations,
    pagination,
    onAddOrganisationSubmit,
    onEditOrganisationSubmit,
    onDeleteOrganisationSubmit,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [organisationFormKey, setOrganisationFormKey] = useState<number>(0);

    const handleOpenDialog = (): void => openDialog(dialogRef);
    const handleCloseDialog = (): void => {
        closeDialog(dialogRef);

        setOrganisationFormKey(organisationFormKey + 1);
    };

    useEffect((): void => {
        if (organisationIsSuccessful) handleCloseDialog();
    }, [organisationIsSuccessful]);

    return (
        <section className={`organisation-overview ${className}`}>
            <header className="organisation-overview__header">
                <IconButton
                    icon="plus"
                    text={trans('containers.organisationOverview.addOrganisation')}
                    onClick={handleOpenDialog}
                    className="organisation-overview__add-button"
                />
            </header>

            <OrganisationTable
                organisationOverviewIsLoading={organisationOverviewIsLoading}
                organisationIsUpdating={organisationIsUpdating}
                organisationIsSuccessful={organisationIsSuccessful}
                organisationOverviewError={organisationOverviewError}
                organisationError={organisationError}
                totalAmount={totalAmount}
                organisations={organisations}
                onAddOrganisationSubmit={onAddOrganisationSubmit}
                onEditOrganisationSubmit={onEditOrganisationSubmit}
                onDeleteOrganisationSubmit={onDeleteOrganisationSubmit}
                className="organisation-overview__table"
            />

            {pagination && (
                <Pagination
                    id="organisation-overview"
                    amountOfPages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    onChange={onPaginationChange}
                    className="organisation-overview__pagination"
                />
            )}

            <Dialog ref={dialogRef} onClose={handleCloseDialog}>
                <DialogContent title={trans('containers.organisationOverview.addDialog.title')}>
                    <OrganisationForm
                        key={organisationFormKey}
                        isLoading={organisationIsUpdating}
                        isSuccessful={organisationIsSuccessful}
                        error={organisationError}
                        onSubmit={onAddOrganisationSubmit}
                        onCancel={handleCloseDialog}
                        className="organisation-overview__form"
                    />
                </DialogContent>
            </Dialog>
        </section>
    );
};

export default OrganisationOverview;
