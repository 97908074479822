import { talentzFetch } from '../helpers/talentzFetch';
import trans from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { FieldOfStudy, FieldsOfStudyResource, transformResourceToFieldOfStudy } from '../models/FieldsOfStudy';

export const getFieldsOfStudyApiCall = async (): Promise<FetchResult<FieldOfStudy[], string>> => {
    try {
        const includes = ['educationLevel'].join(',');
        const response = await talentzFetch(`/api/fields-of-study?include=${includes}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<FieldsOfStudyResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const existingFieldsOfStudy = doc.data.filter(fieldOfStudy => !fieldOfStudy.attributes.manual);
        const fieldsOfStudy = existingFieldsOfStudy.map(transformResourceToFieldOfStudy);
        const sortedFieldsOfStudy = fieldsOfStudy.sort((fieldOfStudy, comparisonFieldOfStudy) => fieldOfStudy.name.localeCompare(comparisonFieldOfStudy.name));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sortedFieldsOfStudy,
        };
    } catch (error) {
        console.error('[getFieldsOfStudyApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
