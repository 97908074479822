import { FC, ReactElement } from 'react';

import { Card, Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './OrganisationDetailOccupationSkeletons.scss';

interface OrganisationDetailOccupationSkeletonsProps {
    className?: string;
}

const OrganisationDetailOccupationSkeletons: FC<OrganisationDetailOccupationSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const amountOfDescriptionLines = randomInBetweenValue(2, 4);
    const descriptionLines = generateIdArray(amountOfDescriptionLines);

    const amountOfSkills = randomInBetweenValue(3, 12);
    const skills = generateIdArray(amountOfSkills);

    const amountOfCompetencies = randomInBetweenValue(3, 12);
    const competencies = generateIdArray(amountOfCompetencies);

    return (
        <div className={`organisation-detail-occupation-skeletons ${className}`}>
            <Skeleton className="organisation-detail-occupation-skeletons__breadcrumbs" />

            <Card className="organisation-detail-occupation-skeletons__header-card">
                <div className="organisation-detail-occupation-skeletons__content-wrapper">
                    <Skeleton className="organisation-detail-occupation-skeletons__title" />

                    <ul className="organisation-detail-occupation-skeletons__list">
                        {descriptionLines.map(line => (
                            <li key={line} className="organisation-detail-occupation-skeletons__list-item">
                                <Skeleton className="organisation-detail-occupation-skeletons__description" />
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="organisation-detail-occupation-skeletons__action-buttons">
                    <div className="organisation-detail-occupation-skeletons__action-button" />
                    <div className="organisation-detail-occupation-skeletons__action-button" />
                </div>
            </Card>

            <Card className="organisation-detail-occupation-skeletons__card">
                <div className="organisation-detail-occupation-skeletons__header">
                    <Skeleton className="organisation-detail-occupation-skeletons__small-title" />
                    <div className="organisation-detail-occupation-skeletons__add-button" />
                </div>

                <ul className="organisation-detail-occupation-skeletons__skill-list">
                    {skills.map(skill => (
                        <li key={skill} className="organisation-detail-occupation-skeletons__skill-list-item">
                            <div className="organisation-detail-occupation-skeletons__skill" />
                        </li>
                    ))}
                </ul>
            </Card>

            <Card className="organisation-detail-occupation-skeletons__card">
                <div className="organisation-detail-occupation-skeletons__header">
                    <Skeleton className="organisation-detail-occupation-skeletons__small-title" />
                    <div className="organisation-detail-occupation-skeletons__add-button" />
                </div>

                <ul className="organisation-detail-occupation-skeletons__skill-list">
                    {competencies.map(competency => (
                        <li key={competency} className="organisation-detail-occupation-skeletons__skill-list-item">
                            <div className="organisation-detail-occupation-skeletons__skill" />
                        </li>
                    ))}
                </ul>
            </Card>
        </div>
    );
};

export default OrganisationDetailOccupationSkeletons;
