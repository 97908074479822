import {
    addMonths,
    differenceInDays,
    endOfYear,
    format,
    isFuture,
    isValid,
    startOfMonth,
    startOfYear,
} from 'date-fns';
import { nl } from 'date-fns/locale';

import trans from './trans';

export const isDateInputSupported = (): boolean => {
    const input = document.createElement('input');
    const value = 'a';

    input.setAttribute('type', 'date');
    input.setAttribute('value', value);

    return input.value !== value;
};

export const isValidDateString = (dateString?: string): boolean => {
    if (!dateString) return false;

    return isValid(new Date(dateString));
};

export const startOfCurrentYear = startOfYear(new Date());
export const endOfCurrentYear = endOfYear(new Date());
export const startOfGivenMonth = (date: Date): Date => startOfMonth(date);
export const addMonthsToGivenDate = (date: Date, months: number): Date => addMonths(date, months);

export const isFutureDate = (date: Date): boolean => isFuture(date);

export const formatDate = (date: Date, notation = 'dd-MM-y'): string => format(date, notation, {
    locale: nl,
});

export const convertDateToReadableDate = (date: Date = new Date()): string => formatDate(date, 'd MMM yyyy');

export const convertDateStringToDDMMYYYY = (date: string): string => {
    const splitDate = date.split('-');

    return splitDate[0].length >= 4
        ? splitDate.reverse().join('-')
        : splitDate.join('-');
};

export const convertDateStringToYYYYMMDD = (date: string): string => {
    const splitDate = date.split('-');

    return splitDate[0].length >= 4
        ? splitDate.join('-')
        : splitDate.reverse().join('-');
};

export const getAgeInDays = (date: Date): number => {
    const now = new Date();
    return differenceInDays(now, date);
};

export const formatAgeInDays = (date: Date): string => {
    const ageInDays = getAgeInDays(date);

    if (ageInDays < 1) return trans('common.dates.today');
    if (ageInDays === 1) return trans('common.dates.oneDayOld');
    if (ageInDays <= 30) return trans('common.dates.ageInDays', { days: `${ageInDays}` });

    return trans('common.dates.ageExceededMonth');
};
