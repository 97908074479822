import { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Card } from '../../../../../components';
import { IconButton } from '../../../../../compositions';
import trans from '../../../../../helpers/trans';
import { DevelopmentPlanSkill } from '../../../../../models/DevelopmentPlanSkill/DevelopmentPlanSkill';
import { DevelopmentPlanSkillEvaluation, DevelopmentPlanSkillEvaluationFormData } from '../../../../../models/DevelopmentPlanSkillEvaluation/DevelopmentPlanSkillEvaluation';
import { DevelopmentPlanCardInput, DevelopmentPlanEvaluationCardQuestion } from '..';

import './DevelopmentPlanEvaluationSkillCard.scss';

interface DevelopmentPlanEvaluationSkillCardProps {
    isEditing: boolean;
    skill: DevelopmentPlanSkill;
    skillEvaluation?: DevelopmentPlanSkillEvaluation;
    onChange: (formData: DevelopmentPlanSkillEvaluationFormData) => void;
    className?: string;
}

const DevelopmentPlanEvaluationSkillCard: FC<DevelopmentPlanEvaluationSkillCardProps> = ({
    isEditing,
    skill,
    skillEvaluation,
    onChange,
    className = '',
}): ReactElement => {
    const [isExpanded, toggleIsExpanded] = useToggle(false);
    const [isCompleted, toggleIsCompleted] = useToggle(skillEvaluation?.isCompleted || false);
    const [feedback, setFeedback] = useState<string>(skillEvaluation?.feedback || '');

    const expandableSectionClasses = classNames('development-plan-evaluation-skill-card__expandable-section', {
        'development-plan-evaluation-skill-card__expandable-section--is-expanded': isExpanded,
    });
    const toggleIconClasses = classNames('development-plan-evaluation-skill-card__toggle-icon', {
        'development-plan-evaluation-skill-card__toggle-icon--is-expanded': isExpanded,
    });

    const handleIsCompletedChange = (): void => {
        const formData: DevelopmentPlanSkillEvaluationFormData = {
            id: skillEvaluation?.id,
            isCompleted: !isCompleted,
            feedback,
            developmentPlanSkillId: skill.id,
        };

        toggleIsCompleted();

        onChange(formData);
    };

    const handleFeedbackChange = (value: string): void => {
        const formData: DevelopmentPlanSkillEvaluationFormData = {
            id: skillEvaluation?.id,
            isCompleted,
            feedback: value,
            developmentPlanSkillId: skill.id,
        };

        setFeedback(value);

        onChange(formData);
    };

    const completeButtonClasses = classNames('development-plan-evaluation-skill-card__complete-button', {
        'development-plan-evaluation-skill-card__complete-button--is-completed': isCompleted,
    });

    return (
        <Card className={`development-plan-evaluation-skill-card ${className}`}>
            <form>
                <div className="development-plan-evaluation-skill-card__header">
                    <h2 className="development-plan-evaluation-skill-card__title">
                        {`${trans('common.skill')}: ${skill.name}`}
                    </h2>
                    <div className="development-plan-evaluation-skill-card__action-buttons">
                        <IconButton
                            disabled={!isEditing}
                            text={trans(`containers.developmentPlanOverview.developmentPlanSkillCard.${isCompleted ? 'isCompleted' : 'complete'}`)}
                            icon="check"
                            onClick={handleIsCompletedChange}
                            className={completeButtonClasses}
                        />
                    </div>
                </div>
                <DevelopmentPlanCardInput
                    isEditing={isEditing}
                    label={trans('containers.developmentPlanEvaluation.developmentPlanSkillEvaluationCard.feedback.label')}
                    value={feedback || ''}
                    onChange={handleFeedbackChange}
                    placeholder={trans('containers.developmentPlanEvaluation.developmentPlanSkillEvaluationCard.feedback.placeholder')}
                    className="development-plan-evaluation-skill-card__input"
                />
                <IconButton
                    text={trans(`containers.developmentPlanEvaluation.developmentPlan.${isExpanded ? 'hide' : 'show'}`)}
                    icon="chevron-down"
                    onClick={toggleIsExpanded}
                    className="development-plan-evaluation-skill-card__toggle-button"
                    iconClassName={toggleIconClasses}
                />
                <div className={expandableSectionClasses}>
                    <DevelopmentPlanEvaluationCardQuestion
                        label={trans('containers.developmentPlanOverview.developmentPlanSkillCard.description')}
                        value={skill.remarks}
                        className="development-plan-evaluation-skill-card__question"
                    />
                </div>
            </form>
        </Card>
    );
};

export default DevelopmentPlanEvaluationSkillCard;
