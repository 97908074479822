import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../components';
import { NoticeType } from '../../types';

import './Notice.scss';

interface NoticeProps {
    type?: NoticeType;
    text: string;
    buttonLabel?: string;
    buttonAction?: () => void;
    className?: string;
}

const Notice: FC<NoticeProps> = ({
    type = NoticeType.regular,
    text,
    buttonLabel = '',
    buttonAction,
    className = '',
}): ReactElement => {
    const noticeClassName = classNames('notice', className, {
        [`notice--is-${type}`]: type,
    });

    return (
        <div className={noticeClassName}>
            <Icon name={`notice-${type}`} className="notice__icon" />
            <div className="notice__content-wrapper">
                <span className="notice__text">{text}</span>

                {buttonLabel && (
                    <Button
                        text={buttonLabel}
                        onClick={buttonAction}
                        className="notice__action-button"
                    />
                )}
            </div>
        </div>
    );
};

export default Notice;
