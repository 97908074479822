import { retrieveRelationshipIds } from '../helpers/japi';
import { Resource } from '../japi/types/Resource';
import { FormOption } from '../types';

export interface LearningPathResource extends Resource {
    id: string;
    type: 'learning-paths';
    attributes: {
        name: string;
        slug: string;
        manual: boolean;
        createdAt: string;
        updatedAt: string;
    };
}

export interface LearningPath {
    id: string;
    label: string;
    relatedEducationLevelIds?: string[];
}

export const transformResourceToLearningPath = (
    learningPathResource: LearningPathResource,
): LearningPath => {
    const relatedEducationLevelIds = retrieveRelationshipIds<LearningPathResource>(learningPathResource, 'educationLevel');

    return {
        id: learningPathResource.id,
        label: learningPathResource.attributes.name,
        relatedEducationLevelIds,
    };
};

export const transformLearningPathToFormOption = (
    learningPath: LearningPath,
): FormOption => ({
    value: learningPath.id,
    label: learningPath.label,
});
