import { FC, FormEvent, ReactElement } from 'react';

import classNames from 'classnames';

import './DescriptiveRadioItem.scss';

interface DescriptiveRadioItemProps extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
    label: string;
    description?: string;
    onChange: (value: string) => void;
    className?: string;
}

const DescriptiveRadioItem: FC<DescriptiveRadioItemProps> = ({
    label,
    description,
    disabled,
    onChange,
    className = '',
    ...radioProps
}): ReactElement => {
    const handleChange = (event: FormEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    const descriptiveRadioItemClassNames = classNames('descriptive-radio-item', {
        'descriptive-radio-item--is-disabled': disabled,
    }, className);

    return (
        <label data-label={label} className={descriptiveRadioItemClassNames}>
            <div className="descriptive-radio-item__label-wrapper">
                <input
                    {...radioProps}
                    id={label}
                    type="radio"
                    disabled={disabled}
                    onChange={handleChange}
                    className="descriptive-radio-item__input"
                />
                <div className="descriptive-radio-item__box" />

                <span className="descriptive-radio-item__label">
                    {label}
                </span>
            </div>

            {description && (
                <p className="descriptive-radio-item__description">
                    {description}
                </p>
            )}
        </label>
    );
};

export default DescriptiveRadioItem;
