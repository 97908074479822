/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

import '../Icon.scss';

const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 61.8 100" className={`icon ${className}`}>
        <path d="M0,11.8L38.2,50L0,88.2L11.7,100l50-50l-50-50L0,11.8z" />
    </svg>
);

export default IconChevronRight;
/* eslint-enable max-len */
