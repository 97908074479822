import { FC, ReactElement, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { ConnectedEducationFilters, ConnectedEducationOverview, ConnectedPage } from '../../connectors';
import trans from '../../helpers/trans';

import './SeekEducation.scss';

const SeekEducation: FC = (): ReactElement => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    return (
        <ConnectedPage
            hasNavigation
            title={trans('pages.seekEducation.title')}
            className="seek-education-page"
        >
            <Helmet>
                <title>{trans('pages.seekEducation.title')}</title>
            </Helmet>

            <ConnectedEducationFilters currentPage={currentPage} />
            <ConnectedEducationOverview
                onPaginationChange={setCurrentPage}
                className="seek-education-page__overview"
            />
        </ConnectedPage>
    );
};

export default SeekEducation;
